/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-no-undef */
import { Icon, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { IInputSearch } from './interfaces'

const InputSearch = ({ searchValue, setSearchValue, placeholder }: IInputSearch) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const inputLeftElementToggle = () => {
    if (locale === 'he') {
      if (!searchValue) {
        return
      } else {
        return <Icon name="small-close" cursor="pointer" />
      }
    }
    return <Icon name="search" />
  }

  const inputRightElementToggle = () => {
    if (locale === 'he') {
      return <Icon name="search" />
    } else if (!searchValue) {
      return
    }
    return <Icon name="small-close" cursor="pointer" />
  }

  return (
    <InputGroup width="100%">
      <InputLeftElement
        fontSize="1em"
        color={colors.blackLight}
        onClick={() => (locale !== 'he' ? '' : setSearchValue(''))}
        children={inputLeftElementToggle()}
      />
      <Input
        placeholder={placeholder}
        backgroundColor={colors.greyMedium}
        _focus={{ color: colors.blackLight }}
        borderRadius="20px"
        value={searchValue}
        onChange={handleChange}
        fontWeight="700"
        paddingRight="2.5rem"
        paddingLeft="2.5rem"
      />
      <InputRightElement
        fontSize="1em"
        children={inputRightElementToggle()}
        color={colors.blackLight}
        onClick={() => (locale === 'he' ? '' : setSearchValue(''))}
      />
    </InputGroup>
  )
}

export default memo(InputSearch)
