import React from 'react'
import colors from 'constants/colors'
import { Button, Text } from '@chakra-ui/core'
import { ControlProps } from 'react-select'
import { IOption, RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'

interface IProps extends ControlProps<IOption, false> {
  onCloseDatePicker?: VoidFunction
}

export const SelectControl = ({ innerRef, onCloseDatePicker, ...props }: IProps) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  const onToggleMenu = () => {
    if (props.selectProps.menuIsOpen && props?.selectProps?.onMenuClose) {
      props?.selectProps?.onMenuClose()
    }
    if (!props.selectProps.menuIsOpen && props?.selectProps?.onMenuOpen) {
      props?.selectProps?.onMenuOpen()
      if (onCloseDatePicker) onCloseDatePicker()
    }
  }

  const getBorderColor = () => {
    return props.options.some((option) => option.isSelected) ? theme?.elementsColor : colors.blackLight
  }

  const getColor = () => (props.options.some((option) => option.isSelected) ? colors.blackLight : colors.blackLight)

  return (
    <Button
      ref={innerRef}
      onClick={onToggleMenu}
      height="30px"
      borderRadius="25px"
      border="1px solid"
      borderColor={getBorderColor()}
      alignItems="center"
      justifyContent="space-between"
      color={getColor()}
      cursor="pointer"
      backgroundColor="transparent"
      rightIcon="chevron-down"
      overflow="hidden"
      maxWidth="255px"
      isTruncated
      px="10px"
      minWidth="135px"
      {...props}
    >
      <Text maxW="200px" isTruncated mx="5px">
        {props.children}
      </Text>
    </Button>
  )
}
