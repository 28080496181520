import { Box, Button, Flex } from '@chakra-ui/core'
import React, { memo } from 'react'
import { FaRegCommentAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { CustomBadge } from 'components/helperStyles'
import { RootState } from 'constants/interfaces'

import { isRtlSelector } from 'redux/selectors/general'

export const CommentButton = memo(
  ({
    showComments,
    showCommentsSection,
    commentsCounter,
    postId,
    isViewed,
    isDoublePost,
    isAllPostCollapsed,
  }: {
    commentsCounterLoading: boolean
    showComments?: (nextValue: boolean) => void
    showCommentsSection: boolean
    commentsCounter: number
    postId: string
    isViewed: boolean
    isDoublePost?: boolean
    isAllPostCollapsed?: boolean
  }) => {
    const { theme } = useSelector((state: RootState) => state.general)
    const viewedCommentsIds = useSelector((state: RootState) => state.comments.viewedCommentsIds[postId])
    const unredCommentsCounter =
      commentsCounter - viewedCommentsIds?.length >= 0 ? commentsCounter - viewedCommentsIds?.length : 0
    const isRtl = useSelector(isRtlSelector)

    return (
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        w={isAllPostCollapsed && !isDoublePost ? '23%' : '8%'}
        onClick={() => {
          showComments?.(!showCommentsSection)
        }}
      >
        {!isViewed && !isDoublePost && isAllPostCollapsed ? (
          <Box w="100%" h="10px" />
        ) : (
          <>
            <Box minH="50%" maxW="70%" textAlign="end" fontSize="13px">
              {commentsCounter}
            </Box>
            <Button
              style={{ columnGap: '7px' }}
              variant="ghost"
              fontWeight="400"
              minW="20px"
              px="0.3rem"
              h="20px"
              fontSize="13px"
              _active={{ bg: 'transparent' }}
              _hover={{ bg: 'transparent' }}
              _focus={{ boxShadow: 'none' }}
            >
              <FaRegCommentAlt style={{ marginLeft: '0', marginRight: '0' }} />
              {unredCommentsCounter > 0 && (
                <CustomBadge
                  pos="absolute"
                  top="-7px"
                  left={isRtl ? '-3px' : '9px'}
                  height="16px"
                  minW="16px"
                  padding="0"
                  fontSize="9px"
                  bg={theme?.elementsColor}
                >
                  {unredCommentsCounter}
                </CustomBadge>
              )}
            </Button>
          </>
        )}
      </Flex>
    )
  }
)
