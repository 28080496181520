import { Box, Flex, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import { PasswordEyeToggle } from 'components/Login/PasswordEyeToggle'
import React, { memo, useMemo, useState } from 'react'
import { GeneratePasswordButton, ResetFieldButton } from '../UI/ActionButtons'
import { ErrorMessage } from '../UI/ErrorMessage'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { FormInputWrapper } from './FormInputWrapper'

interface FormControlBlock {
  id: string
  placeholder?: string
  requiredPlaceholder?: string
  errorMessage?: string
  regEx?: RegExp
  error: any
  handleResetField?: (field: string) => void
  field: string
  register: any
  handleSetValue?: (field: string, val: string | number) => void
  icon?: JSX.Element
  label: string
  inInvalid: boolean
  tooltip?: string
}
export const FormInput = memo((props: FormControlBlock) => {
  const {
    field,
    requiredPlaceholder,
    id,
    placeholder,
    icon,
    regEx,
    errorMessage,
    handleSetValue,
    handleResetField,
    error,
    register,
    label,
    inInvalid,
    tooltip,
  } = props

  const { isFetching, detailedUser } = useSelector((state: RootState) => state.admin.userTable)
  const isRTL = useSelector(isRtlSelector)
  const [isShow, setShow] = useState(false)
  const theme = useSelector((state: RootState) => state.general.theme)

  const getInputType = useMemo(() => {
    if (field === 'phone_number') {
      return 'number'
    }
    if (field === 'password' && !isShow) {
      return 'password'
    }
    return 'text'
  }, [field, isShow])

  return (
    <FormInputWrapper
      field={field}
      id={id}
      tooltip={tooltip}
      label={label}
      requiredPlaceholder={requiredPlaceholder}
      inInvalid={inInvalid}
    >
      <>
        <SkeletonText isLoaded={!isFetching} height="40px">
          <Flex flexDir="row" alignItems="center">
            <InputGroup>
              <Input
                isDisabled={field === 'uid' && detailedUser !== null}
                borderRadius="10px"
                _focus={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}`, border: `1px solid ${theme?.elementsColor}` }}
                _hover={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
                id={id}
                name={id}
                w={
                  field !== 'phone_number'
                    ? { base: '350px', md: '350px', lg: '350px', xl: '500px' }
                    : { base: '180px', md: '180px', lg: '180px', xl: '330px' }
                }
                isReadOnly={field === 'password'}
                onFocus={(e) => e.target.removeAttribute('readonly')}
                type={getInputType}
                placeholder={placeholder}
                ref={register({
                  required: requiredPlaceholder ? requiredPlaceholder : false,

                  pattern: {
                    value: regEx,
                    message: errorMessage,
                  },
                })}
              />

              {icon && (
                <>
                  {isRTL ? (
                    <InputLeftElement>
                      <Box p="10px" color={theme?.backgroundMedium}>
                        {icon}
                      </Box>
                    </InputLeftElement>
                  ) : (
                    <InputRightElement>
                      <Box p="10px" color={theme?.backgroundMedium}>
                        {icon}
                      </Box>
                    </InputRightElement>
                  )}
                </>
              )}
              {field === 'password' && (
                <PasswordEyeToggle isShow={isShow} setShow={setShow} color={theme?.backgroundMedium} />
              )}
            </InputGroup>
            {detailedUser && field === 'uid' ? (
              <></>
            ) : field === 'password' && !requiredPlaceholder ? (
              <GeneratePasswordButton handleSetValue={handleSetValue} field={field} />
            ) : (
              <ResetFieldButton handleResetField={handleResetField} field={field} />
            )}
          </Flex>
        </SkeletonText>

        <ErrorMessage error={error} id={id} />
      </>
    </FormInputWrapper>
  )
})
