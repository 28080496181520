import { Button } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import React from 'react'

interface IProps {
  handleClearCheckedItems: () => void
  disabled: boolean
}
export const ClearSelectedItems = ({ handleClearCheckedItems, disabled }: IProps) => {
  return (
    <Button
      isDisabled={disabled}
      onClick={handleClearCheckedItems}
      bg="none"
      color={colors.greyDark}
      fontWeight="bold"
      justifyContent="flex-start"
      _hover={{ background: 'none' }}
      _active={{ background: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <LocaleText text="clear_filter" />
    </Button>
  )
}
