import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsFetching, updateGroup } from 'redux/actions/admin'
import useLocaleText from 'components/useLocaleText'
import { StyledConfirmButton } from '../UI/CustomComponents'
import { BsExclamationCircle } from 'react-icons/bs'

interface IDeleteGroupContent {
  group?: string
  onClose: () => void
  selectedIds?: string[]
  setGroupToDelete?: (group: string) => void
}
export const DeleteGroupContent = ({ group, onClose, selectedIds, setGroupToDelete }: IDeleteGroupContent) => {
  const { isFetching, detailedUser, registeredUser } = useSelector((state: RootState) => state.admin.userTable)
  const { groups } = useSelector((state: RootState) => state.config.config)
  const theme = useSelector((state: RootState) => state.general.theme)
  const dispatch = useDispatch()
  const admin_delete_group_confirm = useLocaleText('admin_delete_group_confirm')
  const admin_delete_group_button = useLocaleText('admin_delete_group_button')
  const admin_no_cancel = useLocaleText('admin_no_cancel')

  const handleDeleteGroup = (groupId: string) => {
    if (selectedIds && (detailedUser !== null || registeredUser !== null)) {
      dispatch(setIsFetching(true))
      dispatch(updateGroup({ groupId, deleted_users_ids: registeredUser ? [registeredUser.uid] : [detailedUser!.uid] }))
    }
  }
  const handleCancelDeleteGroup = () => {
    setGroupToDelete?.('')
    onClose()
  }

  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      {isFetching ? (
        <Spinner
          thickness="8px"
          speed="0.65s"
          emptyColor="gray.200"
          color={theme?.backgroundMedium}
          w="100px"
          h="100px"
        />
      ) : (
        <Box as={BsExclamationCircle} size="100px" color={theme?.backgroundMedium} />
      )}
      <Text my="18px" fontWeight="600" color="#a2a2b6" fontSize="22px" textAlign="center">
        {admin_delete_group_confirm}
        {` ${groups[group!]?.name}`}
      </Text>
      <Flex flexDir="row" mt="40px" width="70%" justifyContent="space-around" alignItems="center">
        <Button
          _hover={{ backgroundColor: 'none', color: '#4299e199' }}
          _focus={{ boxShadow: 'none' }}
          backgroundColor="none"
          color="#a2a2b6"
          onClick={() => handleCancelDeleteGroup()}
        >
          {admin_no_cancel}
        </Button>
        <StyledConfirmButton
          isDisabled={isFetching}
          onClick={() => handleDeleteGroup(group!)}
          themeColor={theme?.elementsColor}
        >
          {admin_delete_group_button}
        </StyledConfirmButton>
      </Flex>
    </Flex>
  )
}
