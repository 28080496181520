import { Box, Flex, FormControl, FormLabel, IconButton, Input } from '@chakra-ui/core'
import React, { useRef, useCallback, useState } from 'react'
import { DateSelection } from './DateSelection'
import { PostModalSwitcher } from '../PostModalSwitcher'
import { IoClose } from 'react-icons/io5'
import useLocaleText from 'components/useLocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { CustomTextareaAutosize } from 'components/helperStyles'
import { TaskViewsMenu } from './TaskViewsMenu'

interface IPollTaskCreation {
  isSwitchMultipleAnswersActive: boolean
  setIsSwitchMultipleAnswersActive: (value: boolean) => void
  setFromTs: (value: string) => void
  setDeadline: (value: string) => void
  questionText: string
  setQuestionText: (value: string) => void
  pollOptions: string[]
  setPollOptions: React.Dispatch<React.SetStateAction<string[]>>
  setIsRecurring: (value: boolean) => void
  setRecurrenceInterval: (value: number | null) => void
  setRecurrenceFrequency: (value: number | null) => void
  setPollDescription: (value: string) => void
  recurrenceInterval: number | null
  recurrenceFrequency: number | null
  setTaskView: (value: number) => void
  taskView: number
}

const PollTaskCreation: React.FC<IPollTaskCreation> = ({
  isSwitchMultipleAnswersActive,
  setIsSwitchMultipleAnswersActive,
  setFromTs,
  setDeadline,
  questionText,
  setQuestionText,
  pollOptions,
  setPollOptions,
  setIsRecurring,
  setRecurrenceInterval,
  setRecurrenceFrequency,
  recurrenceInterval,
  recurrenceFrequency,
  setPollDescription,
  setTaskView,
  taskView,
}) => {
  const t_multipleAnswers = useLocaleText('t_multipleAnswers')
  const t_questionLabel = useLocaleText('t_questionLabel')
  const t_optionLabel = useLocaleText('t_optionLabel')
  const t_questionPlaceholder = useLocaleText('t_questionPlaceholder')
  const t_optionPlaceholder = useLocaleText('t_optionPlaceholder')
  const t_say_something = useLocaleText('t_say_something')
  const [description, setDescription] = useState('')

  const inputRefs = useRef<(HTMLInputElement | null)[]>([])
  const { isLoading } = useSelector((state: RootState) => state.tasks_v2)

  const onToggleMultipleAnswersSwitch = useCallback(() => {
    setIsSwitchMultipleAnswersActive(!isSwitchMultipleAnswersActive)
  }, [isSwitchMultipleAnswersActive, setIsSwitchMultipleAnswersActive])

  const handleChangeQuestionText = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setQuestionText(e.target.value)
    },
    [setQuestionText]
  )

  const handleRemoveOption = useCallback(
    (index: number) => {
      if (pollOptions.length > 1) {
        const newOptions = pollOptions.filter((_, i) => i !== index)
        setPollOptions(newOptions)
      }
    },
    [pollOptions, setPollOptions]
  )

  const handleOptionChange = useCallback(
    (index: number, value: string) => {
      if (value === '' && pollOptions.length > 2) {
        handleRemoveOption(index)
      } else {
        const newOptions = [...pollOptions]
        newOptions[index] = value
        setPollOptions(newOptions)
      }
    },
    [handleRemoveOption, pollOptions, setPollOptions]
  )

  const handleOptionBlur = useCallback(
    (index: number) => {
      if (index === pollOptions.length - 1 && pollOptions[index] !== '' && pollOptions.length < 10) {
        const nonEmptyOptions = pollOptions.filter((option) => option !== '')
        if (nonEmptyOptions.length === pollOptions.length) {
          setPollOptions([...pollOptions, ''])
        }
      }
    },
    [pollOptions, setPollOptions]
  )

  const handleOptionKeyDown = useCallback(
    (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && pollOptions[index] !== '' && pollOptions.length < 10) {
        const nonEmptyOptions = pollOptions.filter((option) => option !== '')
        if (nonEmptyOptions.length === pollOptions.length) {
          setPollOptions((prevOptions: string[]): string[] => {
            const newOptions = [...prevOptions, '']
            setTimeout(() => {
              inputRefs.current[index + 1]?.focus()
            }, 0)
            return newOptions
          })
        }
      }
    },
    [pollOptions, setPollOptions]
  )
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const maxLength = 1000
    const inputText = event.target.value

    if (inputText.length <= maxLength) {
      setDescription(inputText)
    } else {
      setDescription(inputText.slice(0, maxLength))
    }
  }

  return (
    <Flex flexDir="column" mb="10px">
      <Box mt="10px" mb="22px">
        <TaskViewsMenu setTaskView={setTaskView} taskView={taskView} />
      </Box>
      <DateSelection
        setFromTs={setFromTs}
        setDeadline={setDeadline}
        isDisabled={isLoading}
        setIsRecurring={setIsRecurring}
        setRecurrenceInterval={setRecurrenceInterval}
        setRecurrenceFrequency={setRecurrenceFrequency}
        recurrenceInterval={recurrenceInterval}
        recurrenceFrequency={recurrenceFrequency}
      />

      <FormControl mt="10px">
        <Flex w="100%" justifyContent="space-between" alignItems="center">
          <FormLabel fontSize="14px" pb="0px" fontWeight="600" px="10px" style={{ marginBottom: '0px !important' }}>
            {t_questionLabel}
          </FormLabel>
          <Box h="30px" pb="5px">
            <PostModalSwitcher
              isSwitchActive={isSwitchMultipleAnswersActive}
              onToggleSwitch={onToggleMultipleAnswersSwitch}
              literal={t_multipleAnswers}
              isDisabled={isLoading}
              size="sm"
              {...{ pb: '5px' }}
            />
          </Box>
        </Flex>

        <Input
          isDisabled={isLoading}
          placeholder={t_questionPlaceholder}
          type="text"
          aria-describedby="question-helper-text"
          borderRadius="10px"
          px="10px"
          value={questionText}
          onChange={handleChangeQuestionText}
          mb="10px"
          maxLength={100}
        />
        <FormLabel
          fontSize="14px"
          pb="0px"
          fontWeight="600"
          px="10px"
          style={{ marginBottom: '0px !important' }}
          mt="20px"
        >
          {t_optionLabel}
        </FormLabel>
        {pollOptions.map((option, index) => (
          <Flex key={index} alignItems="center" mt={index === 0 ? 0 : '10px'}>
            <Input
              ref={(el: HTMLInputElement | null) => (inputRefs.current[index] = el)}
              placeholder={t_optionPlaceholder}
              type="text"
              px="10px"
              aria-describedby="option-helper-text"
              borderRadius="10px"
              isDisabled={isLoading}
              value={option}
              onChange={(e) => handleOptionChange(index, (e.target as HTMLInputElement).value)}
              onBlur={() => handleOptionBlur(index)}
              onKeyDown={(e) => handleOptionKeyDown(index, e)}
            />
            {pollOptions.length > 1 && (
              <IconButton
                borderRadius="full"
                aria-label="Remove option"
                icon={IoClose}
                mx="10px"
                isDisabled={isLoading}
                onClick={() => handleRemoveOption(index)}
              />
            )}
          </Flex>
        ))}
        <FormLabel
          fontSize="14px"
          pb="0px"
          fontWeight="600"
          px="10px"
          style={{ marginBottom: '0px !important' }}
          mt="20px"
        >
          {t_say_something}
        </FormLabel>
        <CustomTextareaAutosize
          minHeight="70px"
          value={description}
          disabled={isLoading}
          onChange={handleChange}
          onBlur={() => setPollDescription(description.trim())}
          placeholder={t_say_something}
          style={{ paddingLeft: '10px', paddingRight: '10px', borderRadius: '10px', border: '1px solid #E2E8F0' }}
          boxShadow="none"
        />
      </FormControl>
    </Flex>
  )
}

export default PollTaskCreation
