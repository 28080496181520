import { Flex } from '@chakra-ui/core'
import { AnnouncementChatBody } from 'components/AnnouncementChat/AnnouncementChatBody'
import { AnnouncementChatHeader } from 'components/AnnouncementChat/AnnouncementChatHeader'
import { CommentInput } from 'components/Comments'
import { IHandleCreateComment, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import useComments from 'hooks/useComments'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { setCommentsCounter } from 'redux/actions/comments'

export const GroupChatCommentPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, commentId } = useParams<Params<string>>()
  const query = new URLSearchParams(useLocation().search)
  const isPrefetched = query.get('prefetched')
  const comments = useSelector((state: RootState) => state.comments.commentsState?.[id!]?.items ?? [])
  const replies = useSelector((state: RootState) => state.comments.repliesState?.[commentId!] ?? {})

  const {
    sendComment: sendReply,
    commentsCounter: repliesCounter,
    sendCommentLoading: sendReplyLoading,
    commentsCounterLoading: repliesCounterLoading,
    fetchComments: fetchReplies,
    fetchCommentsLoading: fetchRepliesLoading,
  } = useComments(commentId!, true)

  const chats = useSelector((state: RootState) => (!isPrefetched ? state.feed.chats : state.feed.prefetchedChats))
  const currentChat = chats.find((chat) => chat.post_id === id)
  const currentComment = comments?.find((comment) => comment.cid === commentId)!
  const { likes, views } = useSelector(
    (state: RootState) => state.comments.commentsActivity?.[currentComment?.cid] ?? {}
  )
  const { isConfigReady } = useSelector((state: RootState) => state.config)

  const handleCreateReply = async ({ comment, images, videos, audios, file }: IHandleCreateComment) => {
    const replyType = keys.COMMENT_OBJECT_TYPE.COMMENT_ON_ANNOUNCEMENT_GROUP_MESSAGE
    if (!currentChat || !currentComment) return
    try {
      const isResponseOk = await sendReply({
        id: id!,
        comment,
        rasID: currentChat?.ras_id,
        objectType: replyType,
        parentCID: currentComment.cid,
        replyCID: currentComment.cid,
        createdByUserId: currentChat.created_by,
        images,
        videos,
        audios,
        file,
        isPostCommnet: false,
        isChat: true,
      })
      if (isResponseOk) {
        dispatch(setCommentsCounter({ count: repliesCounter + 1, key: currentComment.cid, isReply: true }))
      }
    } catch (error) {
      console.log(error)
    }
  }
  if (!currentChat && !fetchRepliesLoading && !isConfigReady) {
    navigate(keys.ROUTE_NAMES.NEWS_FEED)
  }

  return (
    <Flex
      direction="column"
      alignItems="center"
      pos="relative"
      m="auto"
      h={'calc(95vh - 94px)'}
      width="100%"
      maxW={{ base: '80%', md: '80%', lg: 'calc(60% - 8vw)', xl: 'calc(60% - 8vw)' }}
      mt="20px"
      bg="white"
    >
      {currentChat && currentComment && (
        <AnnouncementChatHeader
          currentChat={currentChat}
          message={currentComment}
          repliesCounter={repliesCounter}
          repliesCounterLoading={repliesCounterLoading}
          likes={likes}
          views={views}
        />
      )}

      {currentChat && (
        <AnnouncementChatBody
          fetchCommentsLoading={fetchRepliesLoading}
          chatId={id!}
          fetchReplies={fetchReplies}
          replies={replies?.items ?? []}
          replyObject={replies}
          isReply={true}
          parentCID={commentId!}
          repliesCounter={repliesCounter}
        />
      )}
      {currentChat && (
        <CommentInput
          isFocused={true}
          postID={currentChat.post_id}
          isChat={true}
          handleCreateComment={handleCreateReply}
          sendCommentLoading={sendReplyLoading}
        />
      )}
    </Flex>
  )
}
