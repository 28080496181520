import React, { forwardRef } from 'react'
import { useSelector } from 'react-redux'
import { Button, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import { MenuListComponentProps } from 'react-select'
import { BsSearch } from 'react-icons/all'

import useLocaleText from 'components/useLocaleText'
import { IOption, RootState } from 'constants/interfaces'
import { CustomOverflow } from 'components/helperStyles'
import LocaleText from 'components/LocaleText'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps extends Omit<MenuListComponentProps<IOption, false>, 'innerRef'> {
  onDeleteSelection: VoidFunction
  isShowSearch?: boolean
}

export const SelectMenuList = forwardRef<HTMLDivElement, IProps>(
  ({ onDeleteSelection, isShowSearch = false, children, ...props }, ref) => {
    const search_feed_filter_search = useLocaleText('search_feed_filter_search')
    const locale = useSelector((state: RootState) => state.general.locale)
    const isRTL = useSelector(isRtlSelector)

    const handleNameCreator = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!props.selectProps.onInputChange) return
      props.selectProps.onInputChange(e.target.value, { action: 'input-change' })
    }

    return (
      <CustomOverflow maxHeight="300px" overflow="auto" px="5px" py="10px" showScroll={false} locale={locale} ref={ref}>
        {isShowSearch && (
          <InputGroup border="none">
            {isRTL ? (
              <InputRightElement>
                <BsSearch />
              </InputRightElement>
            ) : (
              <InputLeftElement>
                <BsSearch />
              </InputLeftElement>
            )}

            <Input
              placeholder={search_feed_filter_search}
              autoFocus
              type="text"
              value={props.selectProps.inputValue}
              onChange={handleNameCreator}
              border="none"
              _focus={{ outline: 'none' }}
            />
          </InputGroup>
        )}
        {!!props?.selectProps?.options?.some((option) => option.isSelected) && (
          <Button width="100%" onClick={onDeleteSelection} variant="outline" justifyContent="flex-start" border="none">
            <LocaleText text="search_feed_clear_filter_web" />
          </Button>
        )}
        {children}
      </CustomOverflow>
    )
  }
)
