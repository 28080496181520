import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box, Flex, Text, Avatar, Menu, MenuButton, MenuList, MenuItem, Spinner, Button, Image } from '@chakra-ui/core'
import { ReactComponent as OverflowMenu } from 'assets/overflow-menu.svg'
import LocaleText from 'components/LocaleText'
import { DateText } from 'components/Elements'
import colors from 'constants/colors'
import { convertEpocTimeStampToDate } from 'utils'
import useAvatar from 'hooks/useAvatar'
import { AudioPlayer } from 'components/AudioPlayer'
import { UploadedFileList } from 'components/UploadedFileList'
import { isRtlSelector } from 'redux/selectors/general'
import { RootState } from 'constants/interfaces'
import FbImages from 'react-fb-image-grid'
import keys from 'constants/keys'
import { renderTextWithLineBreaks } from 'components/Post/PostTitle'

interface IProps {
  deleteCommentHandler?: (cid: string) => Promise<void>
  deleteReplyHandler?: (cid: string) => Promise<void>
  handleReplyInput: (createdBy: string, cid: string) => void
  isCurrentUser: boolean
  userName: string
  message: string
  createdAt: number
  createdBy: string
  cid: string
  isReply: boolean
  isLoading?: boolean
  replyTo?: string
  images?: string[]
  videos?: string[]
  audios?: string[]
  files?: string[]
  replyCid?: string
  postType?: number
}

const CommentItem = ({
  deleteCommentHandler,
  deleteReplyHandler,
  isReply,
  handleReplyInput,
  isCurrentUser,
  isLoading,
  userName,
  message,
  images,
  createdAt,
  replyTo = '',
  createdBy,
  cid,
  videos,
  audios,
  files,
  postType,
}: IProps) => {
  const avatar = useAvatar(createdBy)
  const isRtl = useSelector(isRtlSelector)
  const retailUsersObject = useSelector((state: RootState) => state.config.config.retailUsersObject)
  const { permission } = useSelector((state: RootState) => state.config.config)

  const isAdminCommentsPermission = permission?.comments === keys.USER_ACTIONS_MAP.APP_ADMIN

  const replyToUser = useMemo(() => {
    const user = retailUsersObject?.[replyTo]
    return `${user?.first_name} ${user?.last_name ? user?.last_name : ''}`
  }, [retailUsersObject, replyTo])

  const _FBImageDontUse = () => {
    return (
      <Box>
        <FbImages />
      </Box>
    )
  }

  return (
    <>
      {/* NEW START */}
      <Flex
        flex={1}
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        mt={isReply ? '10px' : '0'}
      >
        <Flex alignItems="center" flexDirection="row" justifyContent="space-between" width="100%">
          <Flex flexDirection="row" style={{ columnGap: '10px' }} alignItems="center">
            <Avatar src={avatar} size="sm" />
            <Flex flexDirection="column" w="100%">
              <Text fontSize="14px" fontWeight="600" color="black">
                {userName}
              </Text>
              {replyTo && (
                <Text
                  as="span"
                  fontWeight={700}
                  minWidth="fit-content"
                  pb="5px"
                  color={colors.bluePurple}
                  dir={isRtl ? 'rtl' : 'ltr'}
                >
                  @{replyToUser}
                </Text>
              )}
            </Flex>
          </Flex>
          {(isCurrentUser || isAdminCommentsPermission) && (
            <Menu>
              <MenuButton outline="none">
                {isLoading ? <Spinner size="sm" color={colors.greyDark} /> : <OverflowMenu />}
              </MenuButton>
              <MenuList placement={isRtl ? 'right' : 'left'}>
                <MenuItem
                  onClick={() => (isReply ? deleteReplyHandler?.(cid) : deleteCommentHandler?.(cid))}
                  color={colors.red}
                >
                  <LocaleText text="delete" />
                </MenuItem>
              </MenuList>
            </Menu>
          )}
        </Flex>
        <Flex flex={1} fontSize="14px" color={colors.blackLight} flexDirection="row" width="100%" px="43px">
          <Box width="100%" wordBreak="break-word">
            {message !== '' && renderTextWithLineBreaks({ text: message })}
            {/* TODO: Set dynamical files */}
            {videos &&
              videos.length > 0 &&
              videos.map((video) => {
                return (
                  <Flex key={video} my="3" maxHeight="200px" objectFit="contain">
                    <video src={video} controls />
                  </Flex>
                )
              })}
            {/* TODO: Set dynamical files */}
            {images && images.length > 0 && (
              <Box px="5px" py="5px">
                <Image src={images[0]} w="100%" m="0 auto" objectFit="cover" />
              </Box>
            )}
            {audios && audios.map((audioURI) => <AudioPlayer key={audioURI} src={audioURI} />)}
            {files && <UploadedFileList fileList={files} />}

            <Flex fontWeight={400} alignItems="center">
              <DateText>{convertEpocTimeStampToDate(createdAt)}</DateText>
              {postType !== keys.POST_TYPE.GROUP_POST && (
                <Button
                  onClick={() => handleReplyInput(createdBy, cid)}
                  mx="15px"
                  fontSize="14px"
                  color={colors.blackLight}
                  cursor="pointer"
                  variant="unstyled"
                  height="20px"
                  fontWeight="400"
                >
                  <LocaleText text="replay_button" />
                </Button>
              )}
            </Flex>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default CommentItem
