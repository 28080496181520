import React from 'react'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/core'
import LocaleText from '../LocaleText'

interface IProps {
  isOpen: boolean
  onClose: VoidFunction
  children: React.ReactNodeArray | React.ReactNode
}

export const MediaPostModal = ({ isOpen, onClose, children }: IProps) => {
  return (
    <Modal size="2xl" scrollBehavior="inside" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent zIndex={5500}>
        <ModalHeader>
          <LocaleText text="post_modal_all_photos_title" />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </Modal>
  )
}
