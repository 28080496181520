import { Avatar, Button, Collapse, Flex, Text } from '@chakra-ui/core'
import React, { useState } from 'react'
import colors from 'constants/colors'
import { CustomCheckBox } from '../GroupsTree/style'
import { Item } from './Control'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface IItem {
  item: Item
  handleCheckIds: (id: string) => void
  checkedIds: string[]
  mode: string
}

export const ListNode = ({ item, handleCheckIds, checkedIds, mode }: IItem) => {
  const [show, setShow] = useState<boolean>(false)
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <Flex flexDir="row" px="15px" pt="15px" alignItems={item?.image ? 'flex-start' : 'center'}>
      <CustomCheckBox
        onChange={() => handleCheckIds(item.id)}
        isChecked={checkedIds.includes(item.id)}
        mt={item?.image ? '14px' : '0'}
        mode={mode}
        color={theme?.elementsColor}
        hoverColor={theme?.backgroundLight}
      />
      {item?.image && <Avatar src={item?.image} mx="10px" w="40px" h="40px" />}
      <Flex flexDir="column" mx="10px" maxW="200px">
        <Text>
          <LocaleText text={item?.name} />
        </Text>
        {item?.groups && (
          <Collapse startingHeight={20} isOpen={show}>
            <Text color={colors.greyDark}>{item?.groups.join(',')}</Text>
          </Collapse>
        )}
      </Flex>
      {item?.groups && item?.groups?.join(',').length > 20 && (
        <Button
          bg={'none'}
          _hover={{ background: 'none' }}
          _active={{ background: 'none' }}
          _focus={{ boxShadow: 'none' }}
          onClick={() => setShow(!show)}
          color={colors.greyDark}
        >
          <LocaleText text={show ? 'see_less_sidebar' : 'see_more_sidebar'} />
        </Button>
      )}
    </Flex>
  )
}
