import React, { useState, useRef } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { Box, Flex, IconButton } from '@chakra-ui/core'

interface IProps {
  onEmojiSelect: (emoji: string) => void
}
export const ReactionsPicker = ({ onEmojiSelect }: IProps) => {
  const [showFullPicker, setShowFullPicker] = useState(false)
  const reactionEmojis = ['😀', '😂', '😍', '😢', '😡', '👍']
  const pickerRef = useRef<HTMLDivElement | null>(null)

  const handleEmojiClick = (emojiObject: any) => {
    onEmojiSelect(emojiObject.emoji)
  }

  const handleReactionClick = (emoji: string) => {
    onEmojiSelect(emoji)
  }

  return (
    <Flex
      ref={pickerRef}
      pos={'fixed'}
      right={showFullPicker ? '-390px' : '-50px'}
      top={showFullPicker ? '0px' : 'unset'}
    >
      {!showFullPicker && (
        <Flex
          backgroundColor="#e8e8e8"
          padding="5px"
          border="1px solid rgba(255, 255, 255, 0.3)"
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          borderRadius={'20px'}
          fontSize={'25px'}
          lineHeight={'normal'}
          style={{ columnGap: '10px', backdropFilter: 'blur(8px)' }}
        >
          {reactionEmojis.map((emoji) => (
            <Box
              key={emoji}
              cursor="pointer"
              fontSize="1.5rem"
              transition="transform 0.3s ease-in-out"
              style={{ border: 'none', background: 'none' }}
              css={{ '&:hover': { transform: 'scale(1.3)' } }}
              onClick={() => handleReactionClick(emoji)}
            >
              {emoji}
            </Box>
          ))}
          <Box
            cursor="pointer"
            fontSize="1.5rem"
            transition="transform 0.3s ease-in-out"
            style={{ border: 'none', background: 'none', fontSize: '15px' }}
            css={{ '&:hover': { transform: 'scale(1.3)' } }}
            onClick={() => setShowFullPicker(!showFullPicker)}
          >
            ➕
          </Box>
        </Flex>
      )}

      {showFullPicker && (
        <>
          <EmojiPicker
            onEmojiClick={handleEmojiClick}
            lazyLoadEmojis={true}
            style={{ zIndex: 999999, borderRadius: '25px' }}
          />
          <IconButton
            bg="transparent"
            onClick={() => setShowFullPicker(!showFullPicker)}
            icon="close"
            aria-label={'close'}
            w="25px"
            h="25px"
            minW="unser"
            fontSize="14px"
          />
        </>
      )}
    </Flex>
  )
}
