/* eslint-disable no-extra-boolean-cast */
import React, { useMemo } from 'react'
import { Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import permanentData from '../permanentData'
import { isNumber } from 'lodash'

export const CellRoleBlock = ({ row }: any) => {
  const role = useMemo(() => {
    if (isNumber(row?.original?.user_group_role)) {
      return permanentData.ROLES.find((role) => role.id === row.original.user_group_role)?.name
    }
  }, [row])
  return (
    <Text>
      <LocaleText text={role ? role : ''} />
    </Text>
  )
}
