import { CreateTaskState, ITaskOwners, ITimezone } from './../../constants/interfaces'
import { CleanTask, ISubtask } from './../../config/tasksTypes'
import { ITaskOwner, RootState } from 'constants/interfaces'
import produce from 'immer'
import { getTimestampAsString } from 'utils'
import { v1 as uuidv1 } from 'uuid'
import { ITaskView } from 'config/tasksTypes'
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import keys from 'constants/keys'
import dayjs from 'dayjs'

interface IEditTask {
  task: CleanTask
  imageSubTasks: { [key: string]: ISubtask }
  uploadFileSubTasks: { [key: string]: ISubtask }
  videoSubTasks: { [key: string]: ISubtask }
  textSubTasks: { [key: string]: ISubtask }
  linkSubTasks: { [key: string]: ISubtask }
  productSubTasks: { [key: string]: ISubtask }
  subTasks: { [key: string]: ISubtask }
  tasksData: any
}

interface DataItem {
  title: string
  image?: string | null
}

interface ITaskDataObj {
  data: { [key: string]: DataItem }
  oid: string
  rs_id: string
}
export const actionTypes = {
  START_CREATE_TASK: 'START_CREATE_TASK',
  SET_TIMEZONE: '[CREATE TASK] SET_TIMEZONE',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_INITIAL_TASK: 'SET_INITIAL_TASK',
  SET_TASK_NAME: 'SET_TASK_NAME',
  SET_TASK_VIEW: 'SET_TASK_VIEW',
  SET_TASK_OWNERS: 'SET_TASK_OWNERS',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  SET_DEADLINE_DATE: 'SET_DEADLINE_DATE',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_CATEGORY_NAME: 'SET_CATEGORY_NAME',
  SET_CATEGORY_IMAGE: 'SET_CATEGORY_IMAGE',
  SET_CATEGORY_DESCRIPTION: 'SET_CATEGORY_DESCRIPTION',
  DELETE_CATEGORY_IMAGE: 'DELETE_CATEGORY_IMAGE',
  SET_ACTIVE: 'SET_ACTIVE',
  SET_ROLL: 'SET_ROLL',
  SET_IS_PRODUCT_NUMBERED: 'SET_IS_PRODUCT_NUMBERED',
  SET_IS_PRODUCT_CAMERA_ENABLED: 'SET_IS_PRODUCT_CAMERA_ENABLED',
  SET_SUBTASK: 'SET_SUBTASK',
  SET_SUBTASK_IMAGE: 'SET_SUBTASK_IMAGE',
  SET_SUBTASK_TEXT: 'SET_SUBTASK_TEXT',
  SET_SUBTASK_PRODUCT: 'SET_SUBTASK_PRODUCT',
  SET_SUBTASK_LINK: 'SET_SUBTASK_LINK',
  SET_SUBTASK_TO_CATEGORY: 'SET_SUBTASK_TO_CATEGORY',
  SET_TID: 'SET_TID',
  SET_CREATE_TASKS_DATA: 'SET_CREATE_TASKS_DATA',
  SEND_TASK: 'SEND_TASK',
  SET_PUBLISH_TASK_IS_LOADING: 'SET_PUBLISH_TASK_IS_LOADING',
  RESET_TO_INITIAL_STATE: 'RESET_TO_INITIAL_STATE',
  SET_INITIAL_CATEGORY: '[CREATE TASK] SET_INITIAL_CATEGORY',
  SET_IS_NOTIFY_MEMBERS: 'SET_IS_NOTIFY_MEMBERS',
  SET_NOTIFY_GROUP: 'SET_NOTIFY_GROUP',
  SET_IS_SEND_IMMEDIATE_PUSH: 'SET_IS_SEND_IMMEDIATE_PUSH',
  SET_IS_SEND_SCHEDULE_PUSH: 'SET_IS_SEND_SCHEDULE_PUSH',
  SET_AUTO_REMOVAL_TASK: 'SET_AUTO_REMOVAL_TASK',
  SET_EDITED_TASK: '[EDIT_TASK] SET_EDITED_TASK',
  SET_IS_NEW_TASK: 'SET_IS_NEW_TASK',
}
export const setTaskData = (data: any) => ({
  type: actionTypes.SET_CREATE_TASKS_DATA,
  payload: data,
})
export const setIsNewTask = (isNew: boolean) => ({
  type: actionTypes.SET_IS_NEW_TASK,
  payload: isNew,
})
export const setEditedTask = ({
  task,
  imageSubTasks,
  uploadFileSubTasks,
  videoSubTasks,
  textSubTasks,
  linkSubTasks,
  productSubTasks,
  subTasks,
  tasksData,
}: IEditTask) => ({
  type: actionTypes.SET_EDITED_TASK,
  payload: {
    task,
    imageSubTasks,
    uploadFileSubTasks,
    videoSubTasks,
    textSubTasks,
    linkSubTasks,
    productSubTasks,
    subTasks,
    tasksData,
  },
})
export const setTid = (tid: string) => ({
  type: actionTypes.SET_TID,
  payload: tid,
})
export const setAutoRemovalTask = (isAutoRemoval: boolean) => ({
  type: actionTypes.SET_AUTO_REMOVAL_TASK,
  payload: isAutoRemoval,
})

export const setTimezone = (timezone: ITimezone) => ({
  type: actionTypes.SET_TIMEZONE,
  payload: timezone,
})

export const setTaskName = (taskName: string) => ({
  type: actionTypes.SET_TASK_NAME,
  payload: taskName,
})

export const setOwner = (owner: ITaskOwner[]) => ({
  type: actionTypes.SET_TASK_OWNERS,
  payload: owner,
})

export const setIsActiveTask = (isActive: boolean) => ({
  type: actionTypes.SET_ACTIVE,
  payload: isActive,
})

export const setIsRoll = (isRoll: boolean) => ({
  type: actionTypes.SET_ROLL,
  payload: isRoll,
})

export const setIsProductNumbered = (isNumbered: boolean) => ({
  type: actionTypes.SET_IS_PRODUCT_NUMBERED,
  payload: isNumbered,
})

export const setIsProductCamera = (isCamera: boolean) => ({
  type: actionTypes.SET_IS_PRODUCT_CAMERA_ENABLED,
  payload: isCamera,
})

export const setStartDate = (date: Date | null) => ({
  type: actionTypes.SET_START_DATE,
  payload: date,
})

export const setEndDate = (date: Date | null) => ({
  type: actionTypes.SET_END_DATE,
  payload: date,
})

export const setDeadLineDate = (date: Date | null) => ({
  type: actionTypes.SET_DEADLINE_DATE,
  payload: date,
})

export const resetCreateTaskState = () => ({
  type: actionTypes.RESET_TO_INITIAL_STATE,
})

export const setSelectedCategory = (categoryName: string) => ({
  type: actionTypes.SET_SELECTED_CATEGORY,
  payload: categoryName,
})

export const startCreateTask = (isStart: boolean = true) => ({
  type: actionTypes.START_CREATE_TASK,
  payload: isStart,
})

export const setTaskView = (view: string) => ({
  type: actionTypes.SET_TASK_VIEW,
  payload: view,
})

export const setTaskOwners = (owner: ITaskOwners) => ({
  type: actionTypes.SET_TASK_OWNERS,
  payload: owner,
})

export const setInitialCategory = (categoryName: string) => ({
  type: actionTypes.SET_INITIAL_CATEGORY,
  payload: categoryName,
})

export const setIsNotifyMembers = (isNotify: boolean) => ({
  type: actionTypes.SET_IS_NOTIFY_MEMBERS,
  payload: isNotify,
})

export const setNotifyGroup = (groupId: ITaskView[]) => ({
  type: actionTypes.SET_NOTIFY_GROUP,
  payload: groupId,
})

export const setIsSendImmediatePush = (isSend: boolean) => ({
  type: actionTypes.SET_IS_SEND_IMMEDIATE_PUSH,
  payload: isSend,
})

export const setIsSendSchedulePush = (isSend: boolean) => ({
  type: actionTypes.SET_IS_SEND_SCHEDULE_PUSH,
  payload: isSend,
})

export const deleteCategoryImage =
  (imageURL: string, selectedCategory: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState: () => RootState) => {
    const {
      createTask: { categories },
    } = getState()
    const filteredImages = categories[selectedCategory].images?.filter((image: string) => image !== imageURL)

    const updatedCategories = {
      ...categories,
      [selectedCategory]: {
        ...categories[selectedCategory],
        images: filteredImages,
      },
    }

    dispatch({
      type: actionTypes.DELETE_CATEGORY_IMAGE,
      payload: updatedCategories,
    })
  }

export const setCategoryDescription =
  (text: string, selectedCategory: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState: () => RootState) => {
    const {
      createTask: { categories },
    } = getState()

    const updatedCategories = {
      ...categories,
      [selectedCategory]: {
        ...categories[selectedCategory],
        dialog_box: text,
      },
    }

    dispatch({
      type: actionTypes.SET_CATEGORY_DESCRIPTION,
      payload: updatedCategories,
    })
  }

export const handleSetSubtask =
  (subTask: ISubtask, isNew: boolean): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState: () => RootState) => {
    const { createTask } = getState()
    const uid = subTask.st_id
    const subtaskType = subTask.type
    const { selectedCategory } = createTask

    const nextState = produce(createTask, (draftState: any) => {
      draftState.subTasks[uid] = { ...subTask }

      if (subtaskType === keys.SUBTASK_TYPES.IMAGE_SUBTASK) {
        draftState.imageSubTasks[uid] = { ...subTask }
      } else if (subtaskType === keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK) {
        draftState.uploadFileSubTasks[uid] = { ...subTask }
      } else if (subtaskType === keys.SUBTASK_TYPES.TEXT_SUBTASK) {
        draftState.textSubTasks[uid] = { ...subTask }
      } else if (subtaskType === keys.SUBTASK_TYPES.LINK_SUBTASK) {
        draftState.linkSubTasks[uid] = { ...subTask }
      } else if (subtaskType === keys.SUBTASK_TYPES.PRODUCT_SUBTASK) {
        draftState.productSubTasks[uid] = { ...subTask }
      } else if (subtaskType === keys.SUBTASK_TYPES.VIDEO_SUBTASKS) {
        draftState.videoSubTasks[uid] = { ...subTask }
      }
      if (isNew) {
        draftState.categories[selectedCategory]['sub_tasks_order'].push(uid)
      }
    })
    dispatch(setSubtaskAction(nextState))
  }

export const handleRemoveSubtask =
  (subTasks: { st_id: string; subtaskType: number }[]): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState: () => RootState) => {
    const { createTask } = getState()
    const { selectedCategory } = createTask
    const nextState = produce(createTask, (draftState: any) => {
      subTasks.forEach((subtask) => {
        const { [subtask.st_id]: excludeProp, ...subtasks } = createTask.subTasks
        draftState.subTasks = subtasks
        if (subtask.subtaskType === keys.SUBTASK_TYPES.IMAGE_SUBTASK) {
          const { [subtask.st_id]: excludeProp, ...imageSubTasks } = createTask.imageSubTasks
          draftState.imageSubTasks = imageSubTasks
        } else if (subtask.subtaskType === keys.SUBTASK_TYPES.TEXT_SUBTASK) {
          const { [subtask.st_id]: excludeProp, ...textSubTasks } = createTask.textSubTasks
          draftState.textSubTasks = textSubTasks
        } else if (subtask.subtaskType === keys.SUBTASK_TYPES.LINK_SUBTASK) {
          const { [subtask.st_id]: excludeProp, ...linkSubTasks } = createTask.linkSubTasks
          draftState.linkSubTasks = linkSubTasks
        } else if (subtask.subtaskType === keys.SUBTASK_TYPES.PRODUCT_SUBTASK) {
          const { [subtask.st_id]: excludeProp, ...productSubtasks } = createTask.productSubTasks
          draftState.productSubTasks = productSubtasks
        } else if (subtask.subtaskType === keys.SUBTASK_TYPES.VIDEO_SUBTASKS) {
          const { [subtask.st_id]: excludeProp, ...videoSubtasks } = createTask.videoSubTasks
          draftState.videoSubTasks = videoSubtasks
        }
        draftState.categories[selectedCategory][keys.SUB_TASKS_ORDER] = createTask.categories[selectedCategory][
          keys.SUB_TASKS_ORDER
        ].filter((st_id: string) => st_id !== subtask.st_id)
      })
    })

    dispatch(setSubtaskAction(nextState))
  }

export const handleRemoveCategory =
  (categoryId: string): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch, getState: () => RootState) => {
    const { categories, subTasks, selectedCategory } = getState().createTask
    if (selectedCategory === categoryId) {
      const indexOfRemoveCategory = Object.keys(categories).findIndex((category) => category === categoryId) || 0
      const nextCategory =
        Object.keys(categories).length === +indexOfRemoveCategory + 1
          ? Object.keys(categories)[0]
          : Object.keys(categories)[+indexOfRemoveCategory + 1]
      dispatch(setSelectedCategory(nextCategory))
    }
    const { sub_tasks_order } = categories[categoryId]
    const candidatesToRemove = sub_tasks_order.map((subtaskId) => {
      const subtask = subTasks[subtaskId]
      return { st_id: subtask.st_id, subtaskType: subtask.type }
    })
    dispatch(handleRemoveSubtask(candidatesToRemove))
    const { [categoryId]: excludeProp, ...newCategoryList } = categories
    dispatch({ type: actionTypes.SET_CATEGORY, payload: newCategoryList })
  }

export const setSubtaskAction = (updatedState: CreateTaskState) => ({
  type: actionTypes.SET_SUBTASK,
  payload: updatedState,
})

export const setIsPublishLoading =
  (value: boolean): ThunkAction<void, RootState, unknown, Action<string>> =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_PUBLISH_TASK_IS_LOADING,
      payload: value,
    })
  }

export const getTaskBodyForCreatingTask = (newTaskBody: CreateTaskState, rid: string) => {
  const {
    taskOwners,
    tid,
    from_ts,
    to_ts,
    title,
    type,
    categories,
    view,
    order,
    active,
    roll,
    is_st_product_numbered,
    is_st_product_camera_enabled,
    is_opened,
    subTasks,
    tasks_data,
    timezone,
    notifyGroup,
    isSendSchedulePush,
    isSendImmediatePush,
    deadlineDate,
  } = newTaskBody

  const generateOID = uuidv1()

  const groups = taskOwners?.groups
  const users = taskOwners?.users
  const targetValueForRsId =
    groups && groups.length > 0 ? groups[0].value : users && users.length > 0 ? users[0].value : null

  const rsID = `${rid}-${taskOwners && targetValueForRsId}`
  const data = {}
  for (let subTaskKey of Object.keys(subTasks)) {
    if (subTasks[subTaskKey].pid) {
      subTasks[subTaskKey].pid.forEach((id) => {
        data[id] = {
          title: subTasks[subTaskKey].title,
          image: subTasks[subTaskKey].image || null,
        }
      })
    }
  }
  const tasksData = produce(tasks_data, (draftState: any) => {
    draftState.oid = generateOID
    draftState.rs_id = rsID
    draftState.data = data
  })
  const currentTimezone = timezone?.value || timezone + ''
  const tasks = {
    done: false,
    active,
    tag: '',
    rs_id: rsID,
    tid,
    data_oid: generateOID,
    from_ts: getTimestampAsString(from_ts, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone),
    to_ts: getTimestampAsString(to_ts, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone),
    utc_offset: dayjs().utcOffset() / 60,
    title,
    type,
    categories,
    categories_order: Object.keys(categories),
    view: view ?? 0,
    order,
    ref_id: uuidv1(),
    roll,
    is_st_product_numbered,
    is_st_product_camera_enabled,
    is_opened,
    notify_groups_ids: notifyGroup?.map((group) => group.value),
    send_schedule_push: isSendSchedulePush,
    send_campaign_start_push: isSendImmediatePush,
    ...(deadlineDate
      ? { deadline: getTimestampAsString(deadlineDate, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone) }
      : {}),
  }

  return {
    rid,
    groups: taskOwners && taskOwners.groups.map((item: ITaskOwner) => item.value),
    users: taskOwners && taskOwners?.users?.map((item: ITaskOwner) => item.value),
    tasks,
    sub_tasks: subTasks,
    tasks_data: tasksData,
  }
}

export const getTaskBodyForEditingTask = (taskBody: CreateTaskState, rid: string, groups: string[]) => {
  const {
    data_oid,
    tid,
    from_ts,
    to_ts,
    deadlineDate,
    title,
    type,
    categories,
    view,
    order,
    active,
    roll,
    is_st_product_numbered,
    is_st_product_camera_enabled,
    is_opened,
    rs_id,
    ref_id,
    isSendSchedulePush,
    isSendImmediatePush,
    subTasks,
    tasks_data,
    notifyGroup,
  } = taskBody
  try {
    let currentTimezone = dayjs.tz.guess()

    const tasks = {
      done: false,
      active,
      tag: '',
      rs_id,
      tid,
      data_oid,
      from_ts: getTimestampAsString(from_ts, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone),
      to_ts: getTimestampAsString(to_ts, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone),
      utc_offset: dayjs().utcOffset() / 60,
      title,
      type,
      categories,
      categories_order: Object.keys(categories),
      view: view ?? 0,
      order,
      ref_id,
      roll,
      is_st_product_numbered,
      is_st_product_camera_enabled,
      is_opened,
      notify_groups_ids: notifyGroup?.map((group) => group.value),
      send_schedule_push: isSendSchedulePush,
      send_campaign_start_push: isSendImmediatePush,
      ...(deadlineDate
        ? { deadline: getTimestampAsString(deadlineDate, keys.DEFAULT_STRING_FORMAT_TIMESTAMP, currentTimezone) }
        : {}),
    }
    const updateObject = (obj: ITaskDataObj): ITaskDataObj => {
      return produce(obj, (draftState) => {
        Object.keys(draftState.data).forEach((key) => {
          if (!draftState.data[key].image) {
            draftState.data[key].image = null
          }
        })
      })
    }

    const taskData = updateObject(tasks_data)

    return {
      rid,
      groups,
      tasks,
      sub_tasks: subTasks,
      tasks_data: taskData,
    }
  } catch (error) {
    console.log(error)
  }
}
