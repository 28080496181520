import { Box, FormControl, Input, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { OptionType } from './constants'

interface TitleInput {
  title: string
  setTitle: (value: string) => void
  selectedOptionType: string
}

export const TitleInput = ({ title, setTitle, selectedOptionType }: TitleInput) => {
  const [textValue, setTextValue] = useState(title ?? '')
  const handleChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextValue(e.target.value)
    setTitle(e.target.value)
  }

  const isRtl = useSelector(isRtlSelector)
  const create_pop_title_placeholder = useLocaleText('create_pop_title_placeholder')
  const create_group_title_placeholder = useLocaleText('create_group_title_placeholder')

  return (
    <FormControl position="relative" w="100%">
      <Input
        variant="flushed"
        placeholder={
          selectedOptionType === OptionType.CHAT ? create_group_title_placeholder : create_pop_title_placeholder
        }
        value={textValue}
        maxLength={keys.POP_TITLE_MAX_LENGTH}
        onChange={handleChangeText}
        onBlur={() => setTitle(textValue)}
        _focus={{
          borderBottom: '1px solid #e0e0e0',
        }}
      />

      <Box position="absolute" zIndex={2} top="10px" right={isRtl ? 'auto' : '5px'} left={isRtl ? '5px' : 'auto'}>
        <Text>
          {textValue.length}/{keys.POP_TITLE_MAX_LENGTH}
        </Text>
      </Box>
    </FormControl>
  )
}
