export enum PopType {
  DEFAULT_POP = 1,
  RE_POP = 2,
  CHAT_GPT_POP = 3,
  FAKE_POP = 4,
  DUMMY_POP = 5,
}

export enum PopInitiator {
  ADMIN = 0,
  RANKING_FEEDBACK = 1,
  USER_REPOP = 2,
  APP_USER = 3,
}
