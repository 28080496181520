import { Box, Button, Divider, Flex, IconButton, RadioGroup, Text, useDisclosure, Tag } from '@chakra-ui/core'
import { CustomRadio } from 'components/CommonComponents/AudiencePublicationModal/AudienceOptionItem'
import BaseModal from 'components/CommonComponents/BaseModal'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import React, { memo, useMemo, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { BiTask } from 'react-icons/bi'
import { BsChatDots } from 'react-icons/bs'
import { RootState } from 'constants/interfaces'
import { TasksLinksModal } from './TasksLinksModal'
import { ChatsLinksModal } from './ChatsLinksModal'
import { getInitialRadioValue, splitString } from './utils'
import { SmartAvatar } from 'components/CommonComponents/SmartAvatar'
import { useLinkedChatPost } from 'components/Post/postHooks/useLinkedChatPost'
import { allTasksSelector } from 'redux/selectors/tasks'

interface IProps {
  isOpen: boolean
  closeModal: () => void
  setAttachedLink: (value: string) => void
  attachedLink: string | null
}
export const LinkAttachmentModal = memo(({ isOpen, closeModal, setAttachedLink, attachedLink }: IProps) => {
  const splitedAttachedLink = attachedLink ? splitString(attachedLink) : null

  const { isOpen: isTaskModalOpen, onOpen: onOpenTaskModal, onClose: onCloseTaskModal } = useDisclosure()
  const { isOpen: isChatModalOpen, onOpen: onOpenChatModal, onClose: onCloseChatModal } = useDisclosure()

  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { dynamic_task_views, default_task_views } = useSelector((state: RootState) => state.config.retailConfig!)
  const { chats } = useSelector((state: RootState) => state.feed)
  const tasks = useSelector(allTasksSelector)

  const [selectedTaskLink, setSelectedTaskLink] = useState<string | null>(
    splitedAttachedLink?.key === 'tasks' || splitedAttachedLink?.key === '' ? splitedAttachedLink.value : null
  )
  const [selectedChatLink, setSelectedChatLink] = useState<string | null>(
    splitedAttachedLink?.key === 'posts' ? splitedAttachedLink.value : null
  )
  const [value, setValue] = useState(() => getInitialRadioValue(selectedTaskLink, selectedChatLink))

  const { linkedChat } = useLinkedChatPost({
    ref_id: selectedChatLink ? `posts-${selectedChatLink}` : null,
  })

  const allTasks = useMemo(() => {
    const mutatedTasks = tasks.map((task) => {
      return {
        task_view_id: task.ref_id,
        task_view_name: task.title,
        literal: '',
      }
    })
    return [...(mutatedTasks ?? []), ...(dynamic_task_views ?? []), ...(default_task_views ?? [])]
  }, [default_task_views, dynamic_task_views, tasks])

  const selectedTaskLinkTitle = useMemo(() => {
    if (selectedTaskLink) {
      const task = allTasks.find((t) => t.task_view_id == selectedTaskLink)
      return task?.task_view_name || task?.literal
    }
    return ''
  }, [allTasks, selectedTaskLink])

  const selectedChatLinkDetails = useMemo(() => {
    if (selectedChatLink && linkedChat) {
      return { title: linkedChat?.title, avatar: linkedChat?.images?.[0] }
    }
    return null
  }, [linkedChat, selectedChatLink])

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const handleSubmit = () => {
    if (selectedTaskLink) {
      const selectedTaskInTasks = tasks.some((task) => task.ref_id == selectedTaskLink)
      setAttachedLink(selectedTaskInTasks ? selectedTaskLink : `tasks-${selectedTaskLink}`)
    }
    if (selectedChatLink) {
      setAttachedLink(`posts-${selectedChatLink}`)
    }
    if (!selectedChatLink && !selectedTaskLink) {
      setAttachedLink('')
    }
    closeModal()
  }

  return (
    <>
      <BaseModal
        display={isTaskModalOpen || isChatModalOpen ? 'none' : 'block'}
        isShowAnimation={true}
        isOpen={isOpen}
        onClose={closeModal}
        hideCloseButton={true}
        headerContent={
          <>
            <Flex alignItems="center" justifyContent="space-between" dir={isRtl ? 'rtl' : 'ltr'}>
              <Box onClick={closeModal} mx="4" position="absolute">
                <IconButton
                  aria-label="close groups"
                  icon={BsArrowLeftShort}
                  borderRadius="50%"
                  style={{ color: colors.greyMain }}
                  fontSize="32px"
                  transform={`rotate(${+isRtl * 180}deg)`}
                />
              </Box>

              <Text mx="10px" textAlign="center" flexGrow={1}>
                <LocaleText text="Choose attachment" />
              </Text>
            </Flex>

            <Divider pt="5px" />
          </>
        }
        bodyContent={
          <RadioGroup onChange={handleSelect} value={value} px="25px" mt="20px">
            <CustomRadio
              onClick={onOpenTaskModal}
              w="100%"
              justifyContent="space-between"
              flexDir="row-reverse"
              value="1"
              size="lg"
              color={theme?.elementsColor}
            >
              <Flex flexDir="row" alignItems="center" color={colors.blackLight}>
                <Box as={BiTask} h="48px" w="48px" p="10px" borderRadius="full" bg={colors.greyLight4} />

                <Flex flexDir="column">
                  <Text mx="10px">
                    <LocaleText text="link_task" />
                  </Text>
                  {selectedTaskLinkTitle && (
                    <Tag
                      mx="10px"
                      w="max-content"
                      background={colors.tealLight}
                      fontWeight="bold"
                      border="1px solid"
                      fontSize="14px"
                      maxWidth="300px"
                    >
                      <Text isTruncated>
                        <LocaleText text={selectedTaskLinkTitle} />
                      </Text>
                    </Tag>
                  )}
                </Flex>
              </Flex>
            </CustomRadio>
            <Divider pt="5px" />

            <CustomRadio
              onClick={onOpenChatModal}
              value="2"
              w="100%"
              justifyContent="space-between"
              flexDir="row-reverse"
              size="lg"
              color={theme?.elementsColor}
            >
              <Flex flexDir="row" alignItems="center" color={colors.blackLight}>
                <Box as={BsChatDots} w="48px" h="48px" p="10px" borderRadius="full" bg={colors.greyLight4} />

                <Flex flexDir="column">
                  <Text mx="10px">
                    <LocaleText text="link_chat" />
                  </Text>
                  {selectedChatLinkDetails && (
                    <Tag
                      mx="10px"
                      maxWidth="300px"
                      fontSize="12px"
                      w="max-content"
                      background={colors.tealLight}
                      border="1px solid"
                    >
                      <Flex
                        flexDir="row"
                        alignItems="center"
                        color={colors.blackLight}
                        p="5px"
                        fontWeight="bold"
                        fontSize="14px"
                      >
                        <SmartAvatar
                          image={selectedChatLinkDetails.avatar}
                          size="40px"
                          backgroundColor={colors.greyLight4}
                          iconColor={colors.tealLight}
                        />
                        <Text isTruncated mx="10px">
                          {selectedChatLinkDetails.title}
                        </Text>
                      </Flex>
                    </Tag>
                  )}
                </Flex>
              </Flex>
            </CustomRadio>
            <Divider pt="5px" />
          </RadioGroup>
        }
        footerContent={
          <>
            <Flex justifyContent="flex-end" background="white" px="6" py={5}>
              <Button
                borderRadius="20px"
                onClick={handleSubmit}
                color="white"
                textTransform="uppercase"
                background={theme?.elementsColor}
                _hover={{ backgroundColor: 'none' }}
              >
                <LocaleText text="post_audience_done" />
              </Button>
            </Flex>
          </>
        }
      />
      {isTaskModalOpen && (
        <TasksLinksModal
          isOpen={isTaskModalOpen}
          closeModal={onCloseTaskModal}
          allTasks={allTasks}
          selectedTaskLink={selectedTaskLink}
          setSelectedTaskLink={setSelectedTaskLink}
          setSelectedChatLink={setSelectedChatLink}
        />
      )}
      {isChatModalOpen && (
        <ChatsLinksModal
          isOpen={isChatModalOpen}
          closeModal={onCloseChatModal}
          selectedChatLink={selectedChatLink}
          setSelectedChatLink={setSelectedChatLink}
          setSelectedTaskLink={setSelectedTaskLink}
          chats={chats}
        />
      )}
    </>
  )
})
