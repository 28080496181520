import React, { memo } from 'react'
import { Button, Flex } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'

type Option = {
  type: string
  label: string
}

interface SwitcherProps {
  options: Option[]
  selectedOption: string
  onOptionChange: (optionType: string) => void
}

export const Switcher = memo(({ options, selectedOption, onOptionChange }: SwitcherProps) => {
  return (
    <Flex borderRadius="10px" bg={colors.greyLight} p="3px" justifyContent="space-between">
      {options.map((option) => (
        <Button
          onClick={() => onOptionChange(option.type)}
          key={option.type}
          w="120px"
          borderRadius="8px"
          fontSize="20px"
          mr="0"
          color={option.type === selectedOption ? 'black' : 'gray'}
          fontWeight={option.type === selectedOption ? 'bold' : 'normal'}
          bg={option.type === selectedOption ? 'white' : 'transparent'}
          _hover={{ bg: 'none' }}
          _focus={{ boxShadow: 'none' }}
        >
          <LocaleText text={option.label} />
        </Button>
      ))}
    </Flex>
  )
})
