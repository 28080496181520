import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import React, { memo, useCallback, useEffect, useState } from 'react'
import Select from './Select/index'
import { ErrorMessage } from '../UI/ErrorMessage'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { IOption } from './Select/SelectOption'
import { FormInputWrapper } from './FormInputWrapper'

interface FormSelectProps {
  field: string
  inInvalid: boolean
  placeholder: string
  requiredPlaceholder: string
  register: any
  watch: any
  error: any
  id: string
  handleSetValue: (field: string, val: string | number) => void
  clearError: any
  options: IOption[]
  disabled: boolean
  label: string
  defaultValue?: number
}
export const FormSelect = memo(
  ({
    field,
    inInvalid,
    placeholder,
    requiredPlaceholder,
    register,
    watch,
    error,
    id,
    handleSetValue,
    clearError,
    options,
    disabled,
    label,
    defaultValue,
  }: FormSelectProps) => {
    const { isFetching, detailedUser } = useSelector((state: RootState) => state.admin.userTable)
    const [selectedOption, setSelectedOption] = useState<any | null>(
      defaultValue ? options.find((option) => option.id === defaultValue) : null
    )

    const onChangeOption = useCallback(
      (object: any) => {
        setSelectedOption(object)
        handleSetValue?.(field, object.id)
        clearError(field)
      },
      [clearError, field, handleSetValue]
    )

    const onDeleteSelection = useCallback(() => {
      setSelectedOption(null)
      handleSetValue?.(field, '')
    }, [field, handleSetValue])

    useEffect(() => {
      if (!isFetching && detailedUser && selectedOption === null) {
        setSelectedOption(options.find((option) => option.id === detailedUser[field]))
      }
    }, [defaultValue, detailedUser, field, handleSetValue, isFetching, options, selectedOption, watch])

    return (
      <FormInputWrapper
        field={field}
        id={id}
        label={label}
        requiredPlaceholder={requiredPlaceholder}
        inInvalid={inInvalid}
      >
        <>
          <SkeletonText isLoaded={!isFetching} width="155px" height="40px">
            <Select
              disabled={disabled}
              isShowSearch={field !== 'permission' && field !== 'user_group_role'}
              inInvalid={inInvalid}
              placeholder={placeholder}
              field={field}
              onChange={onChangeOption}
              options={options}
              requiredPlaceholder={requiredPlaceholder}
              value={selectedOption}
              register={register}
              watch={watch}
              onDeleteSelection={field === 'country_code' ? onDeleteSelection : undefined}
            />
          </SkeletonText>
          <ErrorMessage error={error} id={id} />
        </>
      </FormInputWrapper>
    )
  }
)
