import { IconButton, Image } from '@chakra-ui/core'
import React, { useRef } from 'react'
import Slider from 'react-slick'

interface IProps {
  images: string[]
  callback?: () => void
  height?: string
  objectFit?: 'cover' | 'contain'
  onSlideChange?: (index: number) => void
}
const arrowStyles = {
  borderRadius: 'full',
  w: '30px',
  h: '30px',
  color: 'black',
  display: 'flex',
  minHeight: 'unset',
  minWidth: 'unset',
  background: 'white',
  fontSize: '19px',
  border: '1px solid #d1d1d1',
  transform: 'translateY(-50%)',
  lineHeight: 'unset',
  zIndex: 9999,
}
const CustomPrevArrow = (props: any) => {
  const { onClick } = props
  return (
    <IconButton
      onClick={onClick}
      icon="chevron-left"
      aria-label="Previous"
      pos="absolute"
      top="50%"
      left="5px"
      onMouseDown={(e) => e.preventDefault()}
      _focus={{ outline: 'none' }}
      {...arrowStyles}
    />
  )
}

const CustomNextArrow = (props: any) => {
  const { onClick } = props

  return (
    <IconButton
      onClick={onClick}
      icon="chevron-right"
      pos="absolute"
      top="50%"
      right="5px"
      aria-label="Next"
      onMouseDown={(e) => e.preventDefault()}
      _focus={{ outline: 'none' }}
      {...arrowStyles}
    />
  )
}
export const ImageSlider = ({ images, height = '300px', objectFit = 'cover', callback, onSlideChange }: IProps) => {
  const sliderRef = useRef<any>(null)
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    afterChange: (current: number) => onSlideChange && onSlideChange(current),
  }

  return (
    <Slider ref={sliderRef} {...settings}>
      {images?.map((image, index) => (
        <Image
          key={index}
          src={image}
          onClick={callback}
          cursor="pointer"
          height={height}
          w="auto"
          objectFit={objectFit}
          alt={`image ${index + 1}`}
        />
      ))}
    </Slider>
  )
}
