import { Button, Flex } from '@chakra-ui/core'
import React, { memo, useCallback, useMemo } from 'react'
import { SelectDropdown } from 'components/CommonComponents/Select/Control'
import { MdFilterList } from 'react-icons/md'
import { ReactComponent as ArrowsIcon } from 'assets/arrows-down-up.svg'
import { useDispatch, useSelector } from 'react-redux'
import { handleGetPosts, setConsolidationFilter, setIsShowAllMyPosts, setPosts } from 'redux/actions/feed'
import keys from 'constants/keys'
import { RootState, UserWritePermittedContext } from 'constants/interfaces'
import { getPostsIdsFromLocalStorage } from 'components/Post/utils'
import { BiSearch } from 'react-icons/bi'

interface PostFiltersProps {
  user_groups: string[] | null
  consolidationFilterOptions: string[]
  isDisabled: boolean
  userWritePermittedContext: UserWritePermittedContext
  uid: string

  openSearchModalHandler: () => void
  isShowAllMyPosts: boolean
}
export const PostFilters = memo(
  ({
    user_groups,
    consolidationFilterOptions,
    isDisabled,
    userWritePermittedContext,
    uid,
    isShowAllMyPosts,
    openSearchModalHandler,
  }: PostFiltersProps) => {
    const dispatch = useDispatch()
    const { theme } = useSelector((state: RootState) => state.general)

    const onFilterSubmit = useCallback(() => {
      dispatch(setIsShowAllMyPosts(!isShowAllMyPosts))
    }, [dispatch, isShowAllMyPosts])

    const filterConsolidationOptions = useMemo(
      () => [
        { id: '1', name: 'context_post_consolidation_switch' },
        ...(user_groups !== null
          ? user_groups?.map((id) => ({ id: id, name: userWritePermittedContext.groups?.[id]?.name }))
          : []),
      ],
      [userWritePermittedContext.groups, user_groups]
    )
    const getFilteredPosts = useCallback(
      (ids: string[]) => {
        const currentEpochTimeUTC = Math.floor(new Date().getTime() / 1000).toString()
        const pinedPostsObject = getPostsIdsFromLocalStorage()
        const handleGetPostsParams = {
          ts: currentEpochTimeUTC,
          is_init: true,
          isRefresh: true,
          ...(pinedPostsObject[uid!] ? { postIds: pinedPostsObject[uid!] } : {}),
          ...(ids.includes('1') ? {} : { groups: ids }),
        }
        dispatch(handleGetPosts(handleGetPostsParams))
      },
      [dispatch, uid]
    )

    const onFilterConsolidationSubmit = useCallback(
      (ids: string[]) => {
        if (
          ids.every((value, index) => value === consolidationFilterOptions[index]) &&
          ids.length === consolidationFilterOptions.length
        ) {
          return
        }
        dispatch(setPosts({ posts: [], isRefresh: true }))
        dispatch(setConsolidationFilter(ids))
        getFilteredPosts(ids)
      },
      [consolidationFilterOptions, dispatch, getFilteredPosts]
    )

    return (
      <Flex alignItems="center" style={{ columnGap: '10px' }}>
        <Button
          isDisabled={isDisabled}
          dir="ltr"
          bg="white"
          fontSize="14px"
          borderRadius="20px"
          minW="unset"
          w="40px"
          px="0"
          height="40px"
          onClick={openSearchModalHandler}
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: theme?.backgroundLight }}
          _active={{ bg: theme?.backgroundLight }}
        >
          <BiSearch height="20px" />
        </Button>
        <Button
          isDisabled={isDisabled}
          dir="ltr"
          bg={!isShowAllMyPosts ? theme?.backgroundMedium : 'white'}
          fontSize="14px"
          borderRadius="20px"
          minW="unset"
          w="40px"
          px="0"
          height="40px"
          onClick={onFilterSubmit}
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: theme?.backgroundLight }}
          _active={{ bg: theme?.backgroundLight }}
        >
          <ArrowsIcon height="20px" />
        </Button>

        <SelectDropdown
          isLoading={isDisabled}
          itemsDataArray={filterConsolidationOptions}
          mode={keys.SELECT_MODES.MULTIPLE}
          minW="max-content"
          isShowClear={false}
          selectedIds={consolidationFilterOptions}
          onSubmit={onFilterConsolidationSubmit}
          actionButtonIcon={<MdFilterList height="20px" />}
          left="-175px"
          resettingId="1"
          btnH="40px"
          btnW="40px"
        />
      </Flex>
    )
  }
)
