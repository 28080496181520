import React from 'react'
import { buildPollOptions } from '../utils'
import { RootState, Subtask } from 'constants/interfaces'
import { Divider, Flex, Icon, Stack, Text } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { Title } from '../ui/Title'
import useLocaleText from 'components/useLocaleText'
import { MdOutlineStar } from 'react-icons/md'
import { VotedParticipants } from './VotedParticipicans'
import { extractedUidsFromGroups } from 'utils'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  pollSubtask: Subtask
  contextId: string
  handleGetSpecificTaskResult: (contextId: string) => void
}
export const PollVotersList = ({ pollSubtask, contextId, handleGetSpecificTaskResult }: IProps) => {
  const { options, tid, st_id } = pollSubtask

  const t_votes = useLocaleText('t_votes')
  const t_do_not_voted = useLocaleText('t_do_not_voted')
  const isRtl = useSelector(isRtlSelector)
  const { allResults, tasks, insights } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { groups, tags, retailUsersObject },
    userWriteTaskPermissions: { users: permittedUsers, groups: permittedGroups },
  } = useSelector((state: RootState) => state.config)
  const currentTask = tasks.find((task) => task.tid === tid)

  if (!options) return null

  const taskTags = tags.filter((tag) => currentTask?.tags?.includes(tag.sk))
  const taskTagUsers = taskTags.map((tag) => tag?.users ?? []).flat()
  const taskTagGroups = taskTags.map((tag) => tag?.groups ?? []).flat()

  const possibleVoters = !currentTask?.is_individual_results
    ? Array.from(
        new Set([...(currentTask?.groups ?? []), ...(currentTask?.users ?? []), ...taskTagGroups, ...taskTagUsers])
      ).filter((id) => Object.keys(retailUsersObject!).includes(id) || Object.keys(groups).includes(id))
    : (
        Array.from(
          new Set([
            ...currentTask?.users,
            ...taskTagUsers,
            ...extractedUidsFromGroups(currentTask?.groups ?? [], groups),
            ...extractedUidsFromGroups(taskTagGroups, groups),
          ])
        ) as string[]
      ).filter((id) => Object.keys(retailUsersObject!).includes(id) || Object.keys(groups).includes(id))

  const votedParticipants = allResults[tid]
    ?.filter((result) => result.st_id === st_id && result?.poll_selections && result?.poll_selections?.length > 0)
    .map((result) => result.context_id)

  const notVotedParticipants = possibleVoters.filter((participant) => !votedParticipants?.includes(participant))
  const currentPollResults = allResults[tid]?.filter((result) => result.st_id === st_id) ?? []
  const optionsArray = buildPollOptions({
    options,
    pollResults: currentPollResults,
    permittedUsers,
    permittedGroups,
    insights,
    groups,
    retailUsersObject: retailUsersObject!,
  })

  return (
    <Stack direction="column" spacing={4}>
      {optionsArray.map(({ label, count, currentQuestionParticipants }, index) => (
        <Flex flexDir="column" key={index} bg="white" border="1px solid #efefef" borderRadius="10px" p="10px">
          <Flex
            flexDir="row"
            justifyContent="space-between"
            fontWeight="500"
            alignItems="center"
            style={{ columnGap: '10px' }}
          >
            <Title title={label} w="max-context" fontWeight="500" fontSize="18px" fontFamily="Arial" isTruncated />

            <Flex
              flexDir={isRtl ? 'row-reverse' : 'row'}
              style={{ columnGap: '5px' }}
              alignItems="center"
              fontSize="18px"
              fontFamily="Arial"
              w="max-context"
              justifyContent="flex-end"
            >
              {currentQuestionParticipants.find((participant) => participant.id === contextId) && (
                <Icon as={MdOutlineStar} />
              )}
              <Text fontFamily="Arial" ml={0}>
                {count}/{possibleVoters.length}
              </Text>
              <Text fontFamily="Arial">{t_votes}</Text>
            </Flex>
          </Flex>

          {currentQuestionParticipants.length > 0 && (
            <>
              <Divider />
              <VotedParticipants
                participants={currentQuestionParticipants}
                handleGetSpecificTaskResult={handleGetSpecificTaskResult}
              />
            </>
          )}
        </Flex>
      ))}
      {notVotedParticipants.length > 0 && (
        <Flex flexDir="column" bg="white" border="1px solid #efefef" borderRadius="10px" p="10px">
          <Flex flexDir="row" justifyContent="space-between" fontWeight="500" fontSize="18px" fontFamily="Arial">
            <Title
              title={t_do_not_voted}
              w="max-context"
              isTruncated
              fontWeight="500"
              fontFamily="Arial"
              fontSize="18px"
            />
            <Text fontWeight="500" fontSize="18px" fontFamily="Arial">
              {notVotedParticipants.length}
            </Text>
          </Flex>

          {notVotedParticipants.length > 0 && (
            <>
              <Divider />
              <VotedParticipants
                participants={notVotedParticipants.map((id) => ({
                  id,
                  isClickable: permittedUsers.includes(id) || Object.keys(permittedGroups).includes(id),
                }))}
                handleGetSpecificTaskResult={handleGetSpecificTaskResult}
              />
            </>
          )}
        </Flex>
      )}
    </Stack>
  )
}
