import { RootState } from 'constants/interfaces'
import { createSelector } from 'reselect'

export const isLoggedInSelector = createSelector(
  (state: RootState) => state.auth.isLoggedIn,
  (isLoggedIn) => isLoggedIn
)

export const isTryToLoginByLinkSelector = createSelector(
  (state: RootState) => state.auth.isLoginByLink,
  (isLoginByLink) => isLoginByLink
)

export const candidateToLoginSelector = createSelector(
  (state: RootState) => state.auth.candidateToLogin,
  (candidate) => candidate
)
