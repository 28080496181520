import { Divider, Flex, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import { IAllUserTaskPermittedGroup, IConfigStateGroups } from 'constants/interfaces'
import React from 'react'
import { getTreeNodesIds } from './utils'

interface ISearchList {
  result: any
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  setGroupElementId: React.Dispatch<React.SetStateAction<string>>
  locale: string
  handleTaskOwnerGroupUI: (currentId: string, isOpen?: boolean) => void
  treeGroups: IConfigStateGroups | IAllUserTaskPermittedGroup
}

export const SearchList = ({
  result,
  setSearchValue,
  setGroupElementId,
  locale,
  handleTaskOwnerGroupUI,
  treeGroups,
}: ISearchList) => {
  const path = locale !== 'he' ? result.path : result.path.split(' → ').join(' ← ')

  return (
    <>
      <Flex key={result.id} width="100%" justifyContent="space-between">
        <Flex
          alignItems="flex-start"
          flexDirection="column"
          onClick={() => {
            setSearchValue('')
            setGroupElementId(result.id)
            handleTaskOwnerGroupUI!(result.id)
          }}
          cursor="pointer"
        >
          <Flex flexDirection="row">
            <Text lineHeight="2.5" fontSize="1rem" color={colors.blackLight} fontWeight="700" mr="5px">
              {result.title}
            </Text>
            <Text lineHeight="2.5" fontSize="1rem" color={colors.blackLight} fontWeight="700" mr="5px">
              {getTreeNodesIds(treeGroups, result.id).length > 1
                ? `(${getTreeNodesIds(treeGroups, result.id).length})`
                : ''}
            </Text>
          </Flex>
          <Text fontSize="0.8rem" color={colors.greyMain} fontWeight="700">
            {path}
          </Text>
        </Flex>
      </Flex>
      <Divider my="2px" color={colors.greyMain} />
    </>
  )
}
