/* eslint-disable quotes */
// LAST UPDATED 03.03.2021
const translations = // 20210217183723
// https://sales-media.checkout-apps.com/translations_1613576941.json

// 20210302145129
// https://sales-media.checkout-apps.com/translations_1614577484.json
{
  base: {
    settings: "settings",
    logout: "logout",
    done: "done",
    yes: "yes",
    no: "no",
    undone: "undone",
    weeklyTasksTitle: "weeklyTasksTitle",
    weekly_report: "weekly_report",
    noItems: "noItems",
    optimized: "optimized",
    out_of_stock: "out_of_stock",
    in_order: "in_order",
    comment: "comment",
    cancel: "cancel",
    new: "new",
    best_seller: "best_seller",
    unitsChange: "unitsChange",
    status: "status",
    tasks: "tasks",
    total_items: "total_items",
    improved_location: "improved_location",
    sales_status: "sales_status",
    increase_in_units: "increase_in_units",
    already_optimized: "already_optimized",
    new_location: "new_location",
    card_confirm_text: "card_confirm_text",
    return1: "return1",
    return2: "return2",
    return3: "return3",
    return4: "return4",
    in_stock: "in_stock",
    manager_guidelines: "manager_guidelines",
    menu_title: "menu_title",
    category_images_title: "category_images_title",
    alert_success: "alert_success",
    alert_error: "alert_error",
    choose: "choose",
    description: "description",
    tags: "tags",
    send: "send",
    save: "save",
    write_a_comment: "write_a_comment",
    files: "files",
    comments: "comments",
    reply: "reply",
    info: "info",
    attached_files: "attached_files",
    cover_name: "cover_name",
    please_take_an_item_photo: "please_take_an_item_photo",
    hello: "hello",
    menu_in_stock_smaller_then_5: "menu_in_stock_smaller_then_5",
    menu_done: "menu_done",
    menu_undone: "menu_undone",
    menu_yes: "menu_yes",
    menu_no: "menu_no",
    menu_irrelevant: "menu_irrelevant",
    menu_out_of_stock: "menu_out_of_stock",
    menu_in_stock: "menu_in_stock",
    menu_not_carried_out: "menu_not_carried_out",
    menu_there_are_shortcomings: "menu_there_are_shortcomings",
    menu_there_are_no_shortcomings: "menu_there_are_no_shortcomings",
    menu_read: "menu_read",
    menu_different_location: "menu_different_location",
    menu_missing_dimensions: "menu_missing_dimensions",
    menu_missing_colors: "menu_missing_colors",
    menu_I_didnt_change_anything: "menu_I_didnt_change_anything",
    menu_located_right: "menu_located_right",
    menu_location_changed: "menu_location_changed",
    menu_switched: "menu_switched",
    menu_in_order: "menu_in_order",
    menu_signed_storage: "menu_signed_storage",
    menu_pasted_storage: "menu_pasted_storage",
    menu_signed_pasted_storage: "menu_signed_pasted_storage",
    menu_missing_size: "menu_missing_size",
    menu_optimized_location: "menu_optimized_location",
    menu_driver: "menu_driver",
    menu_driver_no_no: "menu_driver_no_no",
    menu_fixed: "menu_fixed",
    menu_no_fixed: "menu_no_fixed",
    no_status_taskoverview: "no_status_taskoverview",
    no_status: "no_status",
    choose_status: "choose_status",
    menu: "menu",
    menu_improved_location: "menu_improved_location",
    False: "False",
    True: "True",
    menu_size_or_color_missing: "menu_size_or_color_missing",
    err_page_title: "err_page_title",
    err_page_homepgae_btn: "err_page_homepgae_btn",
    search_branch: "search_branch",
    user_search_placeholder: "user_search_placeholder",
    login_username: "login_username",
    login_password: "login_password",
    forgot_password: "forgot_password",
    login_header: "login_header",
    login: "login",
    change_role: "change_role",
    you_are: "you_are",
    store_manager: "store_manager",
    district_manager: "district_manager",
    chain_store_manager: "chain_store_manager",
    choose_store: "choose_store",
    choose_store_search: "choose_store_search",
    data_analytics_title: "data_analytics_title",
    data_tasks: "data_tasks",
    ops_tasks: "ops_tasks",
    history_tasks: "history_tasks",
    training_tasks: "training_tasks",
    vm_tasks: "vm_tasks",
    tasks_search: "tasks_search",
    view_subtasks: "view_subtasks",
    delete: "delete",
    view_more_comments: "view_more_comments",
    unathorized: "unathorized",
    permission: "permission",
    wrong: "wrong",
    no_internet: "no_internet",
    language: "language",
    tasks_dashboard: "tasks_dashboard",
    language_name: "language_name",
    monthly_rate_header: "monthly_rate_header",
    monthly_rate_current: "monthly_rate_current",
    monthly_rate_gap: "monthly_rate_gap",
    card_data_1: "card_data_1",
    card_data_2: "card_data_2",
    card_data_3: "card_data_3",
    card_data_4: "card_data_4",
    monthly_sales_goal_rate: "monthly_sales_goal_rate",
    main_clock_sub_title: "main_clock_sub_title",
    main_clock_title: "main_clock_title",
    clocks_header: "clocks_header",
    data_tasks_header: "data_tasks_header",
    data_tasks_sub_header: "data_tasks_sub_header",
    opt_tasks_header: "opt_tasks_header",
    opt_tasks_sub_header: "opt_tasks_sub_header",
    default_filter_header: "default_filter_header",
    area_filters: "area_filters",
    questions: "questions",
    stores: "stores",
    current_status: "current_status",
    no_tasks_to_present: "no_tasks_to_present",
    attach_file_btn: "attach_file_btn",
    prod_id: "prod_id",
    image_sub_header: "image_sub_header",
    subtask_view: "subtask_view",
    legal_privacy: "legal_privacy",
    legal_terms: "legal_terms",
    no_reports: "no_reports",
    goal: "goal",
    enable_notification: "enable_notification",
    on_boarding_header: "on_boarding_header",
    on_boarding_sub_header: "on_boarding_sub_header",
    on_boarding_open_camera_btn: "on_boarding_open_camera_btn",
    on_boarding_upload_image_btn: "on_boarding_upload_image_btn",
    on_boarding_image_added: "on_boarding_image_added",
    nothing_found_massage: "nothing_found_massage",
    no_camera_premision_title: "no_camera_premision_title",
    no_camera_premision_body: "no_camera_premision_body",
    no_camera_premision_cancel: "no_camera_premision_cancel",
    no_camera_premision_confirm: "no_camera_premision_confirm",
    read_less: "read_less",
    read_more: "read_more",
    morning_icon: "morning_icon",
    noon_icon: "noon_icon",
    evening_icon: "evening_icon",
    night_icon: "night_icon",
    morning_greeting: "morning_greeting",
    noon_greeting: "noon_greeting",
    evening_greeting: "evening_greeting",
    night_greeting: "night_greeting",
    user_liked: "user_liked",
    user_not_liked: "user_not_liked",
    newsfeed_like_btn: "newsfeed_like_btn",
    newsfeed_like_btn_1: "newsfeed_like_btn_1",
    newsfeed_comment_btn: "newsfeed_comment_btn",
    view: "view",
    caming_soon: "caming_soon",
    action_button_1: "action_button_1",
    action_button_2: "action_button_2",
    action_button_3: "action_button_3",
    action_button_4: "action_button_4",
    action_button_5: "action_button_5",
    action_button_6: "action_button_6",
    action_button_7: "action_button_7",
    action_copy_to_clickbourd_alert: "action_copy_to_clickbourd_alert",
    action_copy_to_clickbourd_alert_title: "action_copy_to_clickbourd_alert_title",
    control_panels_title: "control_panels_title",
    pinged: "pinged",
    ping_all: "ping_all",
    ping: "ping",
    ping_users_got_it: "ping_users_got_it",
    create_post_placeholder: "create_post_placeholder",
    post_entry_placeholder: "post_entry_placeholder",
    link_task: "link_task",
    user_blessing: "user_blessing",
    delete_post_title: "delete_post_title",
    delete_post_approval: "delete_post_approval",
    edit: "edit",
    post: "post",
    crash_go_back: "crash_go_back",
    crash_title: "crash_title",
    sub_task_search: "sub_task_search",
    skip: "skip",
    show: "show",
    actions: "actions",
    legal: "legal",
    account_settings: "account_settings",
    on_boarding_change_image_btn: "on_boarding_change_image_btn",
    related_files: "related_files",
    card_1_time_1: "card_1_time_1",
    card_2_time_1: "card_2_time_1",
    clock_1_title: "clock_1_title",
    clock_1_sub_title: "clock_1_sub_title",
    clock_1_info1: "clock_1_info1",
    clock_1_info2: "clock_1_info2",
    card_1_time_2: "card_1_time_2",
    card_2_time_2: "card_2_time_2",
    card_1_time_3: "card_1_time_3",
    card_2_time_3: "card_2_time_3",
    card_1_time_4: "card_1_time_4",
    card_2_time_4: "card_2_time_4",
    time_1: "time_1",
    time_2: "time_2",
    time_3: "time_3",
    time_4: "time_4",
    report_sub_title_1: "report_sub_title_1",
    report_sub_title_2: "report_sub_title_2",
    report_sub_title_3: "report_sub_title_3",
    report_sub_title_4: "report_sub_title_4",
    report_sub_title_5: "report_sub_title_5",
    report_sub_title_6: "report_sub_title_6",
    clock_2_title: "clock_2_title",
    clock_2_sub_title: "clock_2_sub_title",
    clock_2_info1: "clock_2_info1",
    clock_2_info2: "clock_2_info2",
    card_title_1: "card_title_1",
    card_title_2: "card_title_2",
    card_title_3: "card_title_3",
    card_title_4: "card_title_4",
    clock_3_title: "clock_3_title",
    clock_3_sub_title: "clock_3_sub_title",
    clock_4_title: "clock_4_title",
    clock_4_sub_title: "clock_4_sub_title",
    card_3_title: "card_3_title",
    card_4_title: "card_4_title",
    category_1: "category_1",
    category_2: "category_2",
    category_3: "category_3",
    category_4: "category_4",
    category_type_1: "category_type_1",
    category_type_2: "category_type_2",
    time_1_period_start: "time_1_period_start",
    time_1_period_end: "time_1_period_end",
    time_2_period_start: "time_2_period_start",
    time_2_period_end: "time_2_period_end",
    time_3_period_start: "time_3_period_start",
    time_3_period_end: "time_3_period_end",
    time_4_period_start: "time_4_period_start",
    time_4_period_end: "time_4_period_end",
    report_units: "report_units",
    report_currency: "report_currency",
    prod_1: "prod_1",
    prod_2: "prod_2",
    prod_3: "prod_3",
    prod_4: "prod_4",
    prod_5: "prod_5",
    prod_6: "prod_6",
    prod_7: "prod_7",
    prod_8: "prod_8",
    prod_9: "prod_9",
    prod_10: "prod_10",
    real_report: "real_report",
    force_update_title: "force_update_title",
    tasks_done: "tasks_done",
    tasks_progress: "tasks_progress",
    tasks_open: "tasks_open",
    validation_rule_is_required: "validation_rule_is_required",
    reset_password_success_text: "reset_password_success_text",
    reset_password_success_title: "reset_password_success_title",
    reset_password_submit_button: "reset_password_submit_button",
    reset_password_title: "reset_password_title",
    reset_password_validation_rule: "reset_password_validation_rule",
    reset_password_input1: "reset_password_input1",
    reset_password_input2: "reset_password_input2",
    forgot_password_title: "forgot_password_title",
    forgot_password_subtitle: "forgot_password_subtitle",
    forgot_password_alert_succes_body: "forgot_password_alert_succes_body",
    forgot_password_alert_error_body: "forgot_password_alert_error_body",
    enter_password_alert_not_valid_body: "enter_password_alert_not_valid_body",
    enter_password_alert_not_matching_body: "enter_password_alert_not_matching_body",
    is_st_product_camera_enabled: "is_st_product_camera_enabled",
    is_st_product_numbered: "is_st_product_numbered",
    header_button_back: "header_button_back",
    header_button_next: "header_button_next",
    header_button_publish: "header_button_publish",
    publish_task_btn: "publish_task_btn",
    placeholder_task_name: "placeholder_task_name",
    date_picker_start: "date_picker_start",
    date_picker_end: "date_picker_end",
    placeholder_category_name: "placeholder_category_name",
    category_default_name: "category_default_name",
    creat_task_sidebar_task_title: "creat_task_sidebar_task_title",
    creat_task_dialog_box_placeholder: "creat_task_dialog_box_placeholder",
    add_category_button: "add_category_button",
    add_dailog_box_button: "add_dailog_box_button",
    add_inspiration_image_button: "add_inspiration_image_button",
    placeholder_st_text_title: "placeholder_st_text_title",
    placeholder_st_product_title: "placeholder_st_product_title",
    placeholder_st_product_id: "placeholder_st_product_id",
    placeholder_st_image_title: "placeholder_st_image_title",
    subtask_text: "subtask_text",
    subtask_product: "subtask_product",
    subtask_image: "subtask_image",
    subtask_link: "subtask_link",
    menu_add_button: "menu_add_button",
    menu_edit_button: "menu_edit_button",
    creat_task_status_menu_modal_open: "creat_task_status_menu_modal_open",
    create_task_status_menu_modal_header: "create_task_status_menu_modal_header ",
    menu_select_button: "menu_select_button",
    deselect_all_stores_button: "deselect_all_stores_button",
    select_all_stores_button: "select_all_stores_button",
    search_stores: "search_stores",
    select_counter_1: "select_counter_1",
    select_counter_2: "select_counter_2",
    placeholder_st_link_title: "placeholder_st_link_title",
    more_options: "more_options",
    placeholder_st_link_link: "placeholder_st_link_link",
    report_title_sales: "report_title_sales",
    report_same_date_last_year: "report_same_date_last_year",
    report_compared_period_sales: "report_compared_period_sales",
    report_target: "report_target",
    report_sales: "report_sales",
    report_monthly_target: "report_monthly_target",
    report_from_begging_of_month: "report_from_begging_of_month",
    report_last_week: "report_last_week",
    report_weekly_target: "report_weekly_target",
    report_required_target: "report_required_target",
    report_sales_rate_vs_target: "report_sales_rate_vs_target",
    report_store_area_rank: "report_store_area_rank",
    report_store_retail_rank: "report_store_retail_rank",
    report_title_traffic: "report_title_traffic",
    report_traffic_visitors: "report_traffic_visitors",
    report_traffic_transactions: "report_traffic_transactions",
    report_conversion_rate: "report_conversion_rate",
    general_target: "general_target",
    report_title_basket_size: "report_title_basket_size",
    general_units: "general_units",
    report_basket_size_sales: "report_basket_size_sales",
    report_basket_size_qty: "report_basket_size_qty",
    report_title_labor: "report_title_labor",
    report_labor_used: "report_labor_used",
    report_labor_remainder: "report_labor_remainder",
    report_labor_unit: "report_labor_unit",
    report_title_loyalty_program: "report_title_loyalty_program",
    report_loyalty_program_new_members: "report_loyalty_program_new_members",
    report_loyalty_program_new_leads: "report_loyalty_program_new_leads",
    report_title_department: "report_title_department",
    report_title_general: "report_title_general",
    report_general_best_sellers: "report_general_best_sellers",
    report_general_best_categories: "report_general_best_categories",
    report_title_stores_performance: "report_title_stores_performance",
    report_series0: "report_series0",
    report_series1: "report_series1",
    report_hide: "report_hide",
    report_yesterday: "report_yesterday",
    report_relative_target: "report_relative_target",
    report_todays_target: "report_todays_target",
    report_title_brands: "report_title_brands",
    delete_task_options_title: "delete_task_options_title",
    delete_task_options_subtitle: "delete_task_options_subtitle",
    delete_all_stores: "delete_all_stores",
    delete_this_store: "delete_this_store",
    delete_category: "delete_category",
    same_category_alert_title: "same_category_alert_title",
    uploading_post_media_loading: "uploading_post_media_loading",
    report_the_product_sold_units: "report_the_product_sold_units",
    report_the_product_sold_money: "report_the_product_sold_money",
    report_best_sellers_man: "report_best_sellers_man",
    report_best_categories_man: "report_best_categories_man",
    report_best_sellers_women: "report_best_sellers_women",
    report_best_categories_women: "report_best_categories_women",
    report_general_best_sellers_man: "report_general_best_sellers_man",
    report_general_best_categories_man: "report_general_best_categories_man",
    report_general_best_sellers_women: "report_general_best_sellers_women",
    report_general_best_categories_women: "report_general_best_categories_women",
    Manager_goal: "Manager_goal",
    groups_title_by_income: "groups_title_by_income",
    groups_title_by_qty: "groups_title_by_qty",
    show_all: "show_all",
    view_linked_task: "view_linked_task",
    linked_tasks_title: "linked_tasks_title",
    subtask_comments: "subtask_comments",
    subtask_files: "subtask_files",
    newsfeed_sidebar: "newsfeed_sidebar",
    reports_sidebar: "reports_sidebar",
    store_filter_sidebar: "store_filter_sidebar",
    see_less_sidebar: "see_less_sidebar",
    see_more_sidebar: "see_more_sidebar",
    area_filter_sidebar: "area_filter_sidebar",
    like_counter_of: "like_counter_of",
    like_counter_users: "like_counter_users",
    title_header: "title_header",
    priority_header: "priority_header",
    timeline_header: "timeline_header",
    status_header: "status_header",
    title_analytic_header: "title_analytic_header",
    inspiration_image_header: "inspiration_image_header",
    st_image_header: "st_image_header",
    st_text_header: "st_text_header",
    st_link_header: "st_link_header",
    st_product_header: "st_product_header",
    task_data_subtitle: "task_data_subtitle",
    task_opt_subtitle: "task_opt_subtitle",
    add: "add",
    rotate: "rotate",
    sub_task_hover_details: "sub_task_hover_details",
    uploaded_tag: "uploaded_tag",
    photo: "photo",
    video: "video",
    post_header: "post_header",
    view_all: "view_all",
    high_priority: "high_priority",
    temp_share_to: "temp_share_to",
    fetch_more_comments_post_button: "fetch_more_comments_post_button",
    hide_comments_post_button: "hide_comments_post_button",
    is_roll_toggale: "is_roll_toggale",
    traning_task_name: "traning_task_name",
    reply_button: "reply_button",
    replay_button: "replay_button",
    newer_comment_button: "newer_comment_button",
    older_comment_button: "older_comment_button",
    reply_to_title: "reply_to_title",
    view_more_reply: "view_more_reply",
    is_st_required: "is_st_required",
    create_task_name_placeholder: "create_task_name_placeholder",
    create_task_view_placeholder: "create_task_view_placeholder",
    create_task_view_op_0: "create_task_view_op_0",
    create_task_view_op_1: "create_task_view_op_1",
    create_task_view_op_3: "create_task_view_op_3",
    create_task_view_op_2: "create_task_view_op_2",
    create_task_assignee_placeholder: "create_task_assignee_placeholder",
    create_task_assignee_selected: "create_task_assignee_selected",
    create_task_start_date_placeholder: "create_task_start_date_placeholder",
    create_task_end_date_placeholder: "create_task_end_date_placeholder",
    create_task_st_title_placeholder: "create_task_st_title_placeholder",
    create_task_st_pid_placeholder: "create_task_st_pid_placeholder",
    create_task_status_menu_select_btn: "create_task_status_menu_select_btn",
    create_task_add_img_btn: "create_task_add_img_btn",
    create_task_st_create_btn: "create_task_st_create_btn",
    create_task_st_cancel_btn: "create_task_st_cancel_btn",
    create_task_st_save_btn: "create_task_st_save_btn",
    create_task_add_cat_btn: "create_task_add_cat_btn",
    create_task_name_title: "create_task_name_title",
    create_task_view_title: "create_task_view_title",
    create_task_assignee_title: "create_task_assignee_title",
    create_task_dialog_box_title: "create_task_dialog_box_title",
    create_task_dialog_box_placeholder: "create_task_dialog_box_placeholder",
    create_task_st_image_title: "create_task_st_image_title",
    create_task_st_text_title: "create_task_st_text_title",
    create_task_st_link_title: "create_task_st_link_title",
    create_task_st_product_title: "create_task_st_product_title",
    create_task_st_image_btn: "create_task_st_image_btn",
    create_task_st_text_btn: "create_task_st_text_btn",
    create_task_st_link_btn: "create_task_st_link_btn",
    create_task_st_product_btn: "create_task_st_product_btn",
    create_task_sidebar_task_title: "create_task_sidebar_task_title",
    forget_password_alert_success_body: "forget_password_alert_success_body",
    create_task_modal_title: "create_task_modal_title",
    create_task_btn: "create_task_btn",
    create_task_assignee_header: "create_task_assignee_header",
    choose_all_groups_btn: "choose_all_groups_btn",
    submit_btn: "submit_btn",
    footer_button_return: "footer_button_return",
    filter_btn: "filter_btn",
    task_overview_graph_title: "task_overview_graph_title",
    task_overview_card_r_title: "task_overview_card_r_title",
    task_overview_card_c_title: "task_overview_card_c_title",
    task_overview_card_l_title: "task_overview_card_l_title",
    task_overview_naxt_page_button: "task_overview_naxt_page_button",
    task_overview_see_task_button: "task_overview_see_task_button",
    filter_header: "filter_header",
    assigned_by_filter: "assigned_by_filter",
    assigned_by_me_filter: "assigned_by_me_filter",
    assigned_by_all_filter: "assigned_by_all_filter",
    group_filter: "group_filter",
    group_selecte_all_filter: "group_selecte_all_filter",
    group_unselecte_filter: "group_unselecte_filter",
    group_back_filter: "group_back_filter",
    group_filter_header: "group_filter_header",
    done_filter_btn: "done_filter_btn",
    apply_all_filters_btn: "apply_all_filters_btn",
    level_filter: "level_filter",
    all_tasks_group_list: "all_tasks_group_list",
    stores_all_tasks_group_list: "stores_all_tasks_group_list",
    areas_all_tasks_group_list: "areas_all_tasks_group_list",
    task_overview_title: "task_overview_title",
    filter_by_retail: "filter_by_retail",
    analytical_done: "analytical_done",
    analytical_inprogress: "analytical_inprogress",
    analytical_todo: "analytical_todo",
    task_overview_filter_title: "task_overview_filter_title",
    filter_by_area: "filter_by_area",
    filter_by_store: "filter_by_store",
    task_overview_category_status_title: "task_overview_category_status_title",
    filter_status_done: "filter_status_done",
    filter_status_inprogress: "filter_status_inprogress",
    filter_status_todo: "filter_status_todo",
    task_overview_status_title: "task_overview_status_title",
    sort_by_status: "sort_by_status",
    task_overview_task_view_0: "task_overview_task_view_0",
    task_overview_task_view_1: "task_overview_task_view_1",
    task_overview_task_view_3: "task_overview_task_view_3",
    task_overview_task_view_2: "task_overview_task_view_2",
    back_button: "back_button",
    task_overview_filter_status: "task_overview_filter_status",
    task_overview_filter_status_all: "task_overview_filter_status_all",
    task_overview_export_btn: "task_overview_export_btn",
    task_overview_select_all_btn: "task_overview_select_all_btn",
    task_overview_choose_group_btn: "task_overview_choose_group_btn",
    delete_task_btn: "delete_task_btn",
    delete_task_cluster_btn: "delete_task_cluster_btn",
    delete_task_instance_btn: "delete_task_instance_btn",
    delete_task_modal_body: "delete_task_modal_body",
    delete_task_modal_header: "delete_task_modal_header",
    search_context_placeholder: "search_context_placeholder",
    search_context_empty_results: "search_context_empty_results",
    task_status_done: "task_status_done",
    task_status_inprogress: "task_status_inprogress",
    task_status_todo: "task_status_todo",
    mobile_menu_header: "mobile_menu_header",
    task_overview_expand_close_button: "task_overview_expand_close_button",
    tasks_overview_select_all: "tasks_overview_select_all"
  },
  en: {
    settings: "Settings",
    logout: "Logout",
    done: "Done",
    yes: "Yes",
    no: "No",
    undone: "Not Done",
    weeklyTasksTitle: "Let’s complete the weekly tasks to improve performance",
    weekly_report: "Weekly Report",
    noItems: "Loading data",
    optimized: "Optimized",
    out_of_stock: "Out of stock",
    in_order: "In Order",
    comment: "Comment",
    cancel: "Cancel",
    new: "New",
    best_seller: "Best Seller",
    unitsChange: "Units change",
    status: "Status",
    tasks: "Tasks ",
    total_items: "Total items",
    improved_location: "Improved display",
    sales_status: "Sales status (units)",
    increase_in_units: "Increase in units",
    already_optimized: "Already optimized",
    new_location: "New location",
    card_confirm_text: "Are you sure?",
    return1: "Return 1",
    return2: "Return 2",
    return3: "Return 3",
    return4: "Return 4",
    in_stock: "In stock",
    manager_guidelines: "Manager guidelines",
    menu_title: "Menu",
    category_images_title: "Images",
    alert_success: "Got It, Thanks !",
    alert_error: "Error !",
    choose: "Choose",
    description: "Description",
    tags: "Tags",
    send: "Send",
    save: "Save",
    write_a_comment: "Write a comment...",
    files: "Files",
    comments: "Comments",
    reply: "Reply",
    info: "Info",
    attached_files: "Attached files",
    cover_name: "Upload image",
    please_take_an_item_photo: "Please take a photo of the item",
    hello: "Hello",
    menu_in_stock_smaller_then_5: " In Stock<5",
    menu_done: "Done",
    menu_undone: "Not Done",
    menu_yes: "Yes",
    menu_no: "no",
    menu_irrelevant: "Irrelevant",
    menu_out_of_stock: "Out of stock",
    menu_in_stock: "in stock",
    menu_not_carried_out: "Not carried out",
    menu_there_are_shortcomings: "There are shortcomings",
    menu_there_are_no_shortcomings: "There are no shortcomings",
    menu_read: "Read",
    menu_different_location: "Different location",
    menu_missing_dimensions: "Missing dimensions",
    menu_missing_colors: "Missing Colors",
    menu_I_didnt_change_anything: "I didn't change anything",
    menu_located_right: "Optimized location",
    menu_location_changed: "Location changed",
    menu_switched: "Switched",
    menu_in_order: "In Order",
    menu_missing_size: "Missing size",
    menu_optimized_location: "Optimized location",
    menu_driver: "No, there was a driver ",
    menu_driver_no_no: "No, there was no driver ",
    menu_fixed: "fixed",
    menu_no_fixed: "not fixed",
    no_status_taskoverview: "No status",
    no_status: "Choose status",
    choose_status: "Choose status",
    menu: "Menu",
    menu_improved_location: "Improved Location",
    False: "False",
    True: "True",
    menu_size_or_color_missing: "Sizes / Colors Are Missing",
    err_page_title: "Slow Loading",
    err_page_homepgae_btn: "Go To Homepage",
    search_branch: "חיפוש סניף",
    login_username: "Username",
    login_password: "Password",
    forgot_password: "Forgot Password",
    login_header: "Login into your account",
    login: "Login",
    change_role: "Change Role",
    you_are: "You Are",
    store_manager: "Store Manager",
    district_manager: "District Manager",
    chain_store_manager: "Chain Store Manager",
    choose_store: "Choose Store",
    choose_store_search: "Which Store Are You Looking For?",
    data_analytics_title: "Data Analytics",
    data_tasks: "Improving sales",
    ops_tasks: "Ops Tasks",
    history_tasks: "VM",
    training_tasks: "Collaborative Learning",
    vm_tasks: "VM",
    tasks_search: "Tasks Search",
    view_subtasks: "View Subtasks",
    delete: "Delete",
    view_more_comments: "View more comments...",
    unathorized: "Unathorized",
    permission: "Sorry, you don't have permission",
    wrong: "Sorry, something went wrong",
    no_internet: "No internet connection",
    language: "Language",
    tasks_dashboard: "Tasks Dashboard",
    language_name: "English",
    monthly_rate_header: "Required Daily Rate",
    monthly_rate_current: "Current",
    monthly_rate_gap: "Gap",
    card_data_1: "Monthly Target",
    card_data_2: "MTD Sales",
    card_data_3: "Yesterday's Sales",
    card_data_4: "Daily Target",
    monthly_sales_goal_rate: "Monthly sales goal performens",
    main_clock_sub_title: "MTD vs Target (%)",
    main_clock_title: "Target",
    clocks_header: "Goals",
    data_tasks_header: "Data tasks",
    data_tasks_sub_header: "AI generated tasks based on data",
    opt_tasks_header: "Operational Tasks",
    opt_tasks_sub_header: "Tasks created manually",
    default_filter_header: "Show All Areas",
    area_filters: "Show All Areas",
    questions: "Questions",
    stores: "Stores",
    current_status: "Current status",
    no_tasks_to_present: "No tasks to present at this time",
    attach_file_btn: "Add File",
    prod_id: "ID",
    image_sub_header: "Snap a pic!",
    subtask_view: "View",
    legal_privacy: "Privacy Policy",
    legal_terms: "Terms Of Use",
    no_reports: "No reports",
    goal: "Goal",
    enable_notification: "Enable Notification",
    on_boarding_header: "Welcome",
    on_boarding_sub_header: "To Get Started Please Add Your Photo",
    on_boarding_open_camera_btn: "Take a Selfie",
    on_boarding_upload_image_btn: "Upload From Gallery",
    on_boarding_image_added: "Looks good :)",
    nothing_found_massage: " Nothing to overview. See the next category.",
    no_camera_premision_title: "No Camera Permission",
    no_camera_premision_body: "Please Allow Camera Permission In Settings",
    no_camera_premision_cancel: "Cancel",
    no_camera_premision_confirm: "Go to Settings",
    read_less: "Read Less",
    read_more: "Read More",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟 ",
    morning_greeting: "Good Morning",
    noon_greeting: "Good Afternoon",
    evening_greeting: "Good Evening",
    night_greeting: "Good Night",
    user_liked: "You got it",
    user_not_liked: "I got it",
    newsfeed_like_btn: "You got it",
    newsfeed_like_btn_1: "I got it",
    newsfeed_comment_btn: "Comment",
    view: "View",
    caming_soon: "Coming soon",
    action_button_1: "Tasks Overview",
    action_button_2: "Collaborative Learning",
    action_button_3: "Inventory Balance",
    action_button_4: "Human Resources",
    action_button_5: "Cash management",
    action_button_6: "Employee Scheduling",
    action_button_7: "Master Key",
    action_copy_to_clickbourd_alert: "the link is valid for 7 days",
    action_copy_to_clickbourd_alert_title: "link was copyed to clipboard",
    control_panels_title: "Control Panel",
    pinged: "Pinged",
    ping_all: "Ping all",
    ping: "Ping",
    ping_users_got_it: "People Who Got It",
    create_post_placeholder: "What’s on your mind?",
    post_entry_placeholder: "Want to message your team?",
    link_task: "Link Task",
    user_blessing: "Good Morning,",
    delete_post_title: "Delete Post",
    delete_post_approval: "Are You Sure You Want To Delete This Post?",
    edit: "Edit",
    post: "Next",
    crash_go_back: "Go Back",
    crash_title: "Connection lost or the server is busy",
    sub_task_search: "Search subtasks",
    skip: "Skip",
    show: "Show",
    actions: "Actions",
    legal: "Legal",
    account_settings: "Account Settings",
    on_boarding_change_image_btn: "Change Image",
    related_files: "Files",
    card_1_time_1: "Target",
    card_2_time_1: "Today",
    clock_1_title: "Sales vs Target",
    clock_1_sub_title: "Required Rate",
    clock_1_info1: "Rank/District",
    clock_1_info2: "Rank/Chain",
    card_1_time_2: "Target",
    card_2_time_2: "Week To Date",
    card_1_time_3: "Weekly Target",
    card_2_time_3: "Week To Date",
    card_1_time_4: "Monthly Target",
    card_2_time_4: "Month To Date",
    time_1: "Today",
    time_2: "Yesterday",
    time_3: "Week To Date",
    time_4: "Month To Date",
    report_sub_title_1: "Sales",
    report_sub_title_2: "Traffic",
    report_sub_title_3: "Labor",
    report_sub_title_4: "Basket Size",
    report_sub_title_5: "Loyalty Program",
    report_sub_title_6: "Store Performance",
    clock_2_title: "Conversion Rate",
    clock_2_sub_title: "Target",
    clock_2_info1: "Traffic",
    clock_2_info2: "Transactions",
    card_title_1: "Labor Hours vs. Target",
    card_title_2: "Remainder",
    card_title_3: "ATV",
    card_title_4: "UPT",
    clock_3_title: "New Members",
    clock_3_sub_title: "Target",
    clock_4_title: "New Leads",
    clock_4_sub_title: "Target",
    card_3_title: "Store's Best Sellers",
    card_4_title: "Category Report",
    category_1: "category A",
    category_2: "category B",
    category_3: "category C",
    category_4: "category D",
    category_type_1: "Chain",
    category_type_2: "Store",
    time_1_period_start: "June 30, 2020",
    time_1_period_end: "vs June 30, 2019",
    time_2_period_start: "March 28, 2020",
    time_2_period_end: "vs March 28, 2019",
    time_3_period_start: "March 29, 2020",
    time_3_period_end: "vs March 24- March 31, 2019",
    time_4_period_start: "March 1 - March 31, 2020",
    time_4_period_end: "vs March 1- March 31, 2019",
    report_units: "Items",
    report_currency: "€",
    prod_1: "Product 1",
    prod_2: "Product 2",
    prod_3: "Product 3",
    prod_4: "Product 4",
    prod_5: "Product 5",
    prod_6: "Product 6",
    prod_7: "Product 7",
    prod_8: "Product 8",
    prod_9: "Product 9",
    prod_10: "Product 10",
    real_report: "Waiting for HQ approval.",
    force_update_title: "We've improved the system for you. Click to update! ",
    tasks_done: "Done",
    tasks_progress: "In progress",
    tasks_open: "To do",
    validation_rule_is_required: "This field is required",
    reset_password_success_text: "Now you can login into your account using the new password ",
    reset_password_success_title: "Password was successfully changed",
    reset_password_submit_button: "Submit",
    reset_password_title: "Reset Password",
    reset_password_validation_rule: "1) Your password must be between 8 and 30 characters. 2) Your password must contain at least one uppercase, or capital, letter (ex: A, B, etc.) 3) Your password must contain at least one lowercase letter. 4) Your password must contain at least one number digit (ex: 0, 1, 2, 3, etc.)",
    reset_password_input1: "Enter new password",
    reset_password_input2: "Re-enter new password",
    forgot_password_title: "Enter the phone number associated with your account.",
    forgot_password_subtitle: "We will send a link via SMS to this number",
    forgot_password_alert_succes_body: "Great! a reset password link was sent to your phone",
    forgot_password_alert_error_body: "Sorry, we can't idenify this number.",
    enter_password_alert_not_valid_body: "This password is note valide or week, the password should be at least 8 characters long. A strong password is combination of upper and lower case letters and numbers",
    enter_password_alert_not_matching_body: "You re-typed the password wrong",
    is_st_product_camera_enabled: "Enable camera in product cards",
    is_st_product_numbered: "Number the product Cards",
    header_button_back: "Go Back",
    header_button_next: "Next",
    header_button_publish: "Publish",
    publish_task_btn: "Publish",
    placeholder_task_name: "Task Name",
    date_picker_start: "Pick Starting Date",
    date_picker_end: "Pick Ending Date",
    placeholder_category_name: "Untitled Category",
    category_default_name: "Untitled Category",
    creat_task_sidebar_task_title: "Task Name",
    creat_task_dialog_box_placeholder: "Add Description...",
    add_category_button: "Add Category",
    add_dailog_box_button: "Add Description",
    add_inspiration_image_button: "Add Images",
    placeholder_st_text_title: "What you want to ask?",
    placeholder_st_product_title: "Fill the product's title",
    placeholder_st_product_id: "Fill the product's ID",
    placeholder_st_image_title: "Take A Picture Of...",
    subtask_text: "Ask a quastion",
    subtask_product: "Show a product",
    subtask_image: "Ask for an image",
    subtask_link: "Add a link",
    menu_add_button: "Add menus",
    menu_edit_button: "Change menus",
    creat_task_status_menu_modal_open: "Change menus",
    create_task_status_menu_modal_header: "Change menus",
    menu_select_button: "Select",
    deselect_all_stores_button: "Unselect All",
    select_all_stores_button: "Select All",
    search_stores: "Search Stores",
    select_counter_1: "Selected",
    select_counter_2: "of",
    placeholder_st_link_title: "Link description",
    more_options: "More options",
    placeholder_st_link_link: "Add Link",
    report_title_sales: "Sales",
    report_same_date_last_year: "MTD LY",
    report_compared_period_sales: "LLY",
    report_target: "Target",
    report_sales: "Sales",
    report_monthly_target: "Monthly Target",
    report_from_begging_of_month: "Month To Date",
    report_last_week: "Last Week",
    report_weekly_target: "Weekly Traget",
    report_required_target: "Required Target",
    report_sales_rate_vs_target: "Sales rate VS Target",
    report_store_area_rank: "Rank/District",
    report_store_retail_rank: "Rank/Chain",
    report_title_traffic: "Traffic",
    report_traffic_visitors: "Traffic",
    report_traffic_transactions: "Transactions",
    report_conversion_rate: "Conversion Rate",
    general_target: "Target",
    report_title_basket_size: "Basket Size",
    general_units: "Items",
    report_basket_size_sales: "ATV",
    report_basket_size_qty: "UPT",
    report_title_labor: "Labor",
    report_labor_used: "Labor Hours vs. Target",
    report_labor_remainder: "Remainder",
    report_labor_unit: "h.",
    report_title_loyalty_program: "Loyalty Program",
    report_loyalty_program_new_members: "New Members",
    report_loyalty_program_new_leads: "New Leads",
    report_title_department: "Departments",
    report_title_general: "Store Performance",
    report_general_best_sellers: "Store's Best Sellers By Income",
    report_general_best_categories: "Category Report",
    report_title_stores_performance: "Stores Performance",
    report_series0: "Store",
    report_series1: "Chain",
    report_yesterday: "Yesterday Sales",
    report_relative_target: "Relative Target",
    report_todays_target: "Today's Sales Target",
    report_title_brands: "Brands",
    delete_task_options_title: "Delete the task ",
    delete_task_options_subtitle: "Choose one of the options",
    delete_all_stores: "Delete from all the stores",
    delete_this_store: "Delete from this store",
    delete_category: "delete category",
    same_category_alert_title: "the category currently exist in the task please choose a different name",
    uploading_post_media_loading: "Uploading...",
    report_the_product_sold_units: "The product sold",
    report_the_product_sold_money: "Summing to",
    report_best_sellers_man: "Store's Best Sellers - Man",
    report_best_categories_man: "Category Report - Man",
    report_best_sellers_women: "Store's Best Sellers - Women",
    report_best_categories_women: "Category Report - Women",
    report_general_best_sellers_man: "Store's Best Sellers - Man",
    report_general_best_categories_man: "Category Report - Man",
    report_general_best_sellers_women: "Store's Best Sellers - Women",
    report_general_best_categories_women: "Category Report - Women",
    Manager_goal: "Manager's Goal",
    groups_title_by_income: "Groups by income",
    groups_title_by_qty: "Groups by quantity",
    show_all: "Show all ",
    view_linked_task: "Learn More",
    linked_tasks_title: "Linked with task",
    subtask_comments: "comments",
    subtask_files: "files",
    newsfeed_sidebar: "Newsfeed",
    reports_sidebar: "Reports",
    store_filter_sidebar: "Filter by store",
    see_less_sidebar: "See Less",
    see_more_sidebar: "See More",
    area_filter_sidebar: "Filter by area",
    like_counter_of: "of",
    like_counter_users: "users",
    title_header: "Title",
    priority_header: "Priority",
    timeline_header: "Timeline",
    status_header: "status",
    title_analytic_header: "Task Status",
    inspiration_image_header: "Inspiration Images",
    st_image_header: "Capture a photo sub-tasks",
    st_text_header: "Question sub-tasks",
    st_link_header: "External Links",
    st_product_header: "Product Cards sub-tasks",
    task_data_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    task_opt_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    add: "Add",
    rotate: "Rotate",
    sub_task_hover_details: "Click for Details",
    uploaded_tag: "UPLOADED",
    photo: "Photo",
    video: "Video",
    post_header: "Create Post",
    view_all: "View all",
    high_priority: "High",
    temp_share_to: "Share to all Stores",
    fetch_more_comments_post_button: "More comments",
    hide_comments_post_button: "Hide comments",
    is_roll_toggale: "STORE-E Highlights",
    traning_task_name: "Collaborative Learning",
    reply_button: "Reply",
    replay_button: "Reply",
    newer_comment_button: "New Comments",
    older_comment_button: "More Comments",
    reply_to_title: "Replying to",
    view_more_reply: "More replies",
    is_st_required: "Required Sub Task",
    create_task_name_placeholder: "Task Name",
    create_task_view_placeholder: "Task Type",
    create_task_view_op_0: "Improving sales",
    create_task_view_op_1: "Ops Tasks",
    create_task_view_op_3: "VM",
    create_task_view_op_2: "Collaborative Learning",
    create_task_assignee_placeholder: "Choose Assignee",
    create_task_assignee_selected: "Choose Assignee",
    create_task_start_date_placeholder: "Choose Start Date",
    create_task_end_date_placeholder: "Choose End Date",
    create_task_st_title_placeholder: "Sub-Task Name",
    create_task_st_pid_placeholder: "Product ID",
    create_task_status_menu_select_btn: "Choose The Possible Answers",
    create_task_add_img_btn: "Add Image",
    create_task_st_create_btn: "Create",
    create_task_st_cancel_btn: "Cancel",
    create_task_st_save_btn: "Save",
    create_task_add_cat_btn: "Add Category",
    create_task_name_title: "Task Name",
    create_task_view_title: "Task Type",
    create_task_assignee_title: "To",
    create_task_dialog_box_title: "Add a description for",
    create_task_dialog_box_placeholder: "Description ...",
    create_task_st_image_title: "Capture a photo sub-tasks",
    create_task_st_text_title: "Question sub-tasks",
    create_task_st_link_title: "External Links",
    create_task_st_product_title: "Product Cards sub-tasks",
    create_task_st_image_btn: "Add a capture photo sub-tasks",
    create_task_st_text_btn: "Add a question sub-tasks",
    create_task_st_link_btn: "Add an external Links",
    create_task_st_product_btn: "Add product cards sub-tasks",
    create_task_sidebar_task_title: "Task Name",
    forget_password_alert_success_body: "Password was reset",
    create_task_modal_title: "Create Task",
    create_task_btn: "Create Task",
    create_task_assignee_header: "Assignee",
    choose_all_groups_btn: "choose all",
    submit_btn: "Ok, done",
    footer_button_return: "Ok, done",
    filter_btn: "Filter",
    task_overview_graph_title: "Task Status",
    task_overview_card_r_title: "No. of Tasks:",
    task_overview_card_c_title: "Task Clusters:",
    task_overview_card_l_title: "Selected Groups:",
    task_overview_naxt_page_button: "More",
    task_overview_see_task_button: "See Tasks",
    filter_header: "Filter",
    assigned_by_filter: "Assigned by",
    assigned_by_me_filter: "Me",
    assigned_by_all_filter: "All",
    group_filter: "Groups",
    group_selecte_all_filter: "Select All",
    group_unselecte_filter: "Unselect All",
    group_back_filter: "Cancel",
    group_filter_header: "Selected Groups",
    done_filter_btn: "Ok, done",
    apply_all_filters_btn: "Apply all filters",
    level_filter: "Segmentation by level of ",
    all_tasks_group_list: "Group List",
    stores_all_tasks_group_list: "Store",
    areas_all_tasks_group_list: "Area",
    task_overview_title: "Task Insights",
    filter_by_retail: "Retail ",
    analytical_done: "Done",
    analytical_inprogress: "In progress",
    analytical_todo: "To do",
    task_overview_filter_title: "Filter",
    filter_by_area: "Area",
    filter_by_store: "Store",
    task_overview_category_status_title: "Status of Current Category",
    filter_status_done: "Done",
    filter_status_inprogress: "In progress",
    filter_status_todo: "To do",
    task_overview_status_title: "Status",
    sort_by_status: "sort by status",
    task_overview_task_view_0: "Improving sales",
    task_overview_task_view_1: "Ops Tasks",
    task_overview_task_view_3: "VM",
    task_overview_task_view_2: "Collaborative Learning",
    back_button: "Back",
    task_overview_filter_status: "Status",
    task_overview_filter_status_all: "All ",
    task_overview_export_btn: "Export",
    task_overview_select_all_btn: "Select All",
    task_overview_choose_group_btn: "Select",
    delete_task_btn: "Delete Task",
    delete_task_cluster_btn: "Delete Task Cluster",
    delete_task_instance_btn: "Delete Task Instance",
    delete_task_modal_body: "How would you like to delete the task?",
    delete_task_modal_header: "Delete Task",
    search_context_placeholder: "Search for a group",
    search_context_empty_results: "No matches",
    task_status_done: "Done",
    task_status_inprogress: "In progress",
    task_status_todo: "To do",
    mobile_menu_header: "Menu",
    task_overview_expand_close_button: "Minimal View"
  },
  he: {
    settings: "הגדרות",
    logout: "להתנתק",
    done: "אישור",
    yes: "כן",
    no: "לא",
    undone: "לא בוצע",
    weeklyTasksTitle: "משימות שבועיות לביצוע",
    weekly_report: "דו״ח שבועי",
    noItems: "טוען נתונים",
    optimized: "ממוקם נכון",
    out_of_stock: "לא במלאי",
    in_order: "בהזמנה",
    comment: "הערה",
    cancel: "ביטול",
    new: "חדש",
    best_seller: "הנמכרים ביותר",
    unitsChange: "שינוי יחידות",
    status: "סטטוס",
    tasks: "משימות",
    total_items: "סך הכל פריטים",
    improved_location: "שיפור תצוגה",
    sales_status: "סטטוס מכירות (יח׳)",
    increase_in_units: "Increase in units",
    already_optimized: "ממוקם נכון",
    new_location: "שונה מיקום",
    card_confirm_text: "האם אתה בטוח/ה?",
    return1: "החזר 1",
    return2: "החזר 2",
    return3: "החזר 3",
    return4: "החזר 4",
    in_stock: "זמין במלאי",
    manager_guidelines: "הנחיות מנהל",
    menu_title: "תפריט",
    category_images_title: "תמונות להמחשה",
    alert_success: " קיבלתי, תודה !",
    alert_error: "שגיאה !",
    choose: "בחר",
    description: "תיאור",
    tags: "תגיות",
    send: "שלח",
    save: "שמור",
    write_a_comment: "כתוב תגובה...",
    files: "קבצים",
    comments: "תגובות",
    reply: "הגב",
    info: "מידע",
    attached_files: "קבצים משוייכים",
    cover_name: "העלאת תמונה",
    please_take_an_item_photo: "Please take a an item photo",
    hello: "שלום",
    menu_in_stock_smaller_then_5: " קיים במלאי<5",
    menu_done: "בוצע",
    menu_undone: "לא בוצע",
    menu_yes: "כן",
    menu_no: "לא",
    menu_irrelevant: "לא רלוונטי",
    menu_out_of_stock: "אזל מהמלאי",
    menu_in_stock: "קיים במלאי",
    menu_not_carried_out: "לא בוצע",
    menu_there_are_shortcomings: "יש חוסרים",
    menu_there_are_no_shortcomings: "אין חוסרים",
    menu_read: "קראתי",
    menu_different_location: "מיקום שונה",
    menu_missing_dimensions: "חסר מידות",
    menu_missing_colors: "חסר צבעים",
    menu_I_didnt_change_anything: "לא שיניתי כלום",
    menu_located_right: "ממוקם נכון",
    menu_location_changed: "שונה מיקום",
    menu_switched: "הוחלף",
    menu_in_order: "בהזמנה",
    menu_signed_storage: "שולט+מחסן",
    menu_pasted_storage: "הודבק+מחסן",
    menu_signed_pasted_storage: "שולט והודבק+מחסן",
    menu_missing_size: "מידה חסרה",
    menu_optimized_location: "מיקום אפטימלי",
    menu_driver: "לא, היה נהג",
    menu_driver_no_no: "לא, לא היה נהג",
    menu_fixed: "תקין",
    menu_no_fixed: "לא תקין",
    no_status_taskoverview: "ללא סטטוס",
    no_status: "בחר סטטוס",
    choose_status: "בחר סטטוס",
    menu: "תפריט מס.",
    menu_improved_location: "שיפרתי מיקום",
    False: "שקר",
    True: "אמת",
    menu_size_or_color_missing: "מידות/צבעים חסרים",
    err_page_title: "טעינה איטית",
    err_page_homepgae_btn: "נסה שנית",
    search_branch: "Search for a branch",
    user_search_placeholder: "חיפוש סניף",
    login_username: "שם משתמש",
    login_password: "סיסמה",
    forgot_password: "שכחתי סיסמא",
    login_header: "התחבר/י לחשבונך",
    login: "התחברות",
    change_role: "החלף זהות",
    you_are: "זהותך",
    store_manager: "מנהל חנות",
    district_manager: "מנהל אזור",
    chain_store_manager: "מנהל רשת",
    choose_store: "בחר סניף",
    choose_store_search: "איזו חנות אתה מחפש?",
    data_analytics_title: "ניתוח נתונים",
    data_tasks: "שיפור מכירות",
    ops_tasks: "משימות תפעוליות",
    history_tasks: "VM",
    training_tasks: "הכשרה והדרכה",
    vm_tasks: "תצוגה",
    tasks_search: "חיפוש משימות",
    view_subtasks: "תתי משימות",
    delete: "מחק",
    view_more_comments: "הצג תגובות נוספות...",
    unathorized: "משתמש אינו מורשה",
    permission: "מצטערים, אין לך הרשאה",
    wrong: "מצטערים, חלה שגיאה",
    no_internet: "אין חיבור אינטרנט",
    language: "שפה",
    tasks_dashboard: "לוח בקרת משימות",
    language_name: "עברית",
    monthly_rate_header: "קצב יומי נדרש",
    monthly_rate_current: "נוכחי",
    monthly_rate_gap: "פער",
    card_data_1: "יעד מכירות חודשי",
    card_data_2: "מכירות עד כה",
    card_data_3: "מכירות אתמול",
    card_data_4: "יעד יומי",
    monthly_sales_goal_rate: "עמידה ביעד המכירות החודשי",
    main_clock_sub_title: "קצב נדרש",
    main_clock_title: "אחוז מול יעד",
    clocks_header: "יעדים",
    data_tasks_header: "שיפור מכירה",
    data_tasks_sub_header: "משימות דאטה מבוססות בינה מלכותית ",
    opt_tasks_header: "משימות תפעוליות",
    opt_tasks_sub_header: "משימות שנוצרו ידנית",
    default_filter_header: "הצג את כל האזורים",
    area_filters: "הצג את כול האזורים",
    questions: "שאלות",
    stores: "חנויות",
    current_status: "סטטוס נוכחי",
    no_tasks_to_present: "אין משימות להצגה",
    attach_file_btn: "צירוף קובץ",
    prod_id: `מק"ט`,
    image_sub_header: `צלם/י תמונה!`,
    subtask_view: "הצג",
    legal_privacy: "מדיניות פרטיות",
    legal_terms: "תנאי שימוש",
    no_reports: "חסר דוחות",
    goal: "יעד",
    enable_notification: "אפשר התראות",
    on_boarding_header: "ברוכים הבאים",
    on_boarding_sub_header: "על מנת להתחיל, אנא צלמ/י סלפי",
    on_boarding_open_camera_btn: "פתח מצלמה",
    on_boarding_upload_image_btn: "בחר תמונה מהגלריה",
    on_boarding_image_added: "נראה נהדר :)",
    nothing_found_massage: "אין תוכן להצגה נא לעבור לקטגוריה הבאה",
    no_camera_premision_title: "אין הרשאת מצלמה",
    no_camera_premision_body: "אנא אפשר שימוש במצלמה",
    no_camera_premision_cancel: "ביטול",
    no_camera_premision_confirm: "עבור להגדרות",
    read_less: "קרא/י פחות",
    read_more: "קרא/י עוד",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟 ",
    morning_greeting: "בוקר טוב",
    noon_greeting: "צהריים טובים",
    evening_greeting: "ערב טוב",
    night_greeting: "לילה טוב",
    user_liked: "קיבלת",
    user_not_liked: "קיבלתי",
    newsfeed_like_btn: "קיבלת",
    newsfeed_like_btn_1: "קיבלתי",
    newsfeed_comment_btn: "תגובות",
    view: "הצג",
    caming_soon: "Coming soon",
    action_button_1: "בקרת משימות",
    action_button_2: "הכשרה והדרכה",
    action_button_3: "ניהול מלאי",
    action_button_4: "כוח אדם",
    action_button_5: "ניהול כספים",
    action_button_6: "תכנון משמרות",
    action_button_7: "צור לינק",
    action_copy_to_clickbourd_alert: "הלינק ישאר זמין למשך של 7 ימים",
    action_copy_to_clickbourd_alert_title: "הלינק העותק ",
    control_panels_title: "לוח בקרה",
    pinged: "נשלח פינג",
    ping_all: "פינג לכולם",
    ping: "פינג",
    ping_users_got_it: "משתמשים שקיבלו",
    create_post_placeholder: "על מה אתה חושב?",
    post_entry_placeholder: "רוצה להעביר הודעה לצוות שלך?",
    link_task: "קשר למשימה",
    user_blessing: "ברוך הבא",
    delete_post_title: "מחק הודעה",
    delete_post_approval: "האם הנך בטוח/ה שברצונך למחוק את ההודעה?",
    edit: "ערוך הודעה",
    post: "הבא",
    crash_go_back: "חזור אחורה",
    crash_title: "תקשורת חלשה או עומס כבד על השרת",
    sub_task_search: "חפש תת משימה",
    skip: "דלג",
    show: "הצג",
    actions: "פעולות",
    legal: "משפטי",
    account_settings: "הגדרות חשבון",
    on_boarding_change_image_btn: "שנה תמונה",
    related_files: "קבצים מצורפים",
    card_1_time_1: "יעד יומי",
    card_2_time_1: "מתחילת היום",
    clock_1_title: "עמידה ביעד",
    clock_1_sub_title: "קצב נדרש",
    clock_1_info1: "דירוג ביחס לאזור",
    clock_1_info2: "דירוג ביחס לרשת",
    card_1_time_2: "יעד יומי",
    card_2_time_2: "מתחילת השבוע",
    card_1_time_3: "יעד שבועי",
    card_2_time_3: "מתחילת השבוע",
    card_1_time_4: "יעד חודשי",
    card_2_time_4: "מתחילת החודש",
    time_1: "היום",
    time_2: "אתמול",
    time_3: "מתחילת השבוע",
    time_4: "מתחילת החודש",
    report_sub_title_1: "מכירות",
    report_sub_title_2: "טראפיק",
    report_sub_title_3: "תקן שעות",
    report_sub_title_4: "גודל סל",
    report_sub_title_5: "מועדון לקוחות",
    report_sub_title_6: "כללי",
    clock_2_title: "יחס המרה",
    clock_2_sub_title: "יעד",
    clock_2_info1: "מבקרים",
    clock_2_info2: "עסקאות",
    card_title_1: "ניצול בפועל",
    card_title_2: "יתרת שעות לניצול",
    card_title_3: "ממוצע סל כספי",
    card_title_4: "ממוצע סל כמותי",
    clock_3_title: "חברים חדשים",
    clock_3_sub_title: "יעד",
    clock_4_title: "לידים חדשים",
    clock_4_sub_title: "יעד",
    card_3_title: "המוצרים הנמכרים ביותר בחנות",
    card_4_title: "התפלגות מכירות לפי קטגוריות",
    category_1: "קטגוריה א'",
    category_2: "קטגוריה ב'",
    category_3: "קטגוריה ג'",
    category_4: "קטגוריה ד'",
    category_type_1: "רשת",
    category_type_2: "חנות",
    time_1_period_start: "יוני 30, 2020",
    time_1_period_end: "vs יוני 30, 2019",
    time_2_period_start: "מרץ 28, 2020",
    time_2_period_end: "vs מרץ 28, 2019",
    time_3_period_start: "מרץ 29, 2020",
    time_3_period_end: "vs מרץ 24- מרץ 31, 2019",
    time_4_period_start: "מרץ 1 - מרץ 31, 2020",
    time_4_period_end: "vs מרץ 1- מרץ 31, 2019",
    report_units: "יח'",
    report_currency: "₪",
    prod_1: "מוצר 1",
    prod_2: "מוצר 2",
    prod_3: "מוצר 3",
    prod_4: "מוצר 4",
    prod_5: "מוצר 5",
    prod_6: "מוצר 6",
    prod_7: "מוצר 7",
    prod_8: "מוצר 8",
    prod_9: "מוצר 9",
    prod_10: "מוצר 10",
    real_report: "הנתונים ממתינים לאישור ההנהלה.",
    force_update_title: "שיפרנו את המערכת בשבילך. לחץ לעדכון!",
    tasks_done: "בוצע",
    tasks_progress: "בתהליך",
    tasks_open: "טרם בוצע",
    validation_rule_is_required: "זה הוא שדה חובה",
    reset_password_success_text: "כעת אפשר להכנס לחשבון שלך מחדש, עם הסיסמה החדשה. ",
    reset_password_success_title: "הסיסמה שונתה בהצלחה!",
    reset_password_submit_button: "שלח/י",
    reset_password_title: "איפוס סיסמה",
    reset_password_validation_rule: "הסיסמה צריכה להיות לפחות באורך של 8 תווים, לכלול אות קטנה, אות גדולה, ספרה/תו מיוחד",
    reset_password_input1: "הקלד סיסמה חדשה",
    reset_password_input2: "חזור על הסיסמה",
    forgot_password_title: "אנא אכניס/י את מספר הטלפון אשר מקושר לחשבונך.",
    forgot_password_subtitle: "אנו נשלח לינק לאיפוס הסיסמה, בעזרת הודעה למספר זה.",
    forgot_password_alert_succes_body: "מעולה! מיד תקבל/י מאיתנו לינק לאיפוס סיסמה ",
    forgot_password_alert_error_body: "סליחה, אבל לא הצלחנו לזהות את מספר זה.",
    enter_password_alert_not_valid_body: "הסיסמה לא תקינה או חלשה, סיסמה חזקה מכילה אותיות קטנות וגדולות וגם מספרים.",
    enter_password_alert_not_matching_body: "לא חזרת על הסיסמה פעמיים",
    is_st_product_camera_enabled: "אפשר כפתור מצלמה בכרטיסי מוצר",
    is_st_product_numbered: "מספר את כרטיסי המוצר",
    header_button_back: "חזרה",
    header_button_next: "הבא",
    header_button_publish: "הוסף",
    publish_task_btn: "הוסף",
    placeholder_task_name: "שם המשימה",
    date_picker_start: "בחר תאריך התחלה",
    date_picker_end: "בחר תאריך סיום",
    placeholder_category_name: "קטגוריה ללא שם",
    category_default_name: "קטגוריה ללא שם",
    creat_task_sidebar_task_title: "שם המשימה",
    creat_task_dialog_box_placeholder: "הוסף תאור...",
    add_category_button: "קטגוריה חדשה",
    add_dailog_box_button: "הוסף תאור",
    add_inspiration_image_button: "הוסף תמונות",
    placeholder_st_text_title: "מה תרצה לשאול?",
    placeholder_st_product_title: "מלא את תאור המוצר",
    placeholder_st_product_id: `מלא את מק"ט המוצר`,
    placeholder_st_image_title: `מה תרצה שהצוות יצלם?`,
    subtask_text: "הוספת שאלה",
    subtask_product: "עידכון מוצר",
    subtask_image: "משימת צילום",
    subtask_link: "צירוף קישור",
    menu_add_button: "הוסף תפריטי סטאטוס",
    menu_edit_button: "שנה תפריטי סטאטוס",
    creat_task_status_menu_modal_open: "שנה תפריטי סטאטוס",
    create_task_status_menu_modal_header: "שנה תפריטי סטאטוס",
    menu_select_button: "בחר",
    deselect_all_stores_button: "בטל את הבחירות",
    select_all_stores_button: "בחר הכול",
    search_stores: "חפש חנויות ואזורים",
    select_counter_1: "נבחרו",
    select_counter_2: "מתוך",
    placeholder_st_link_title: "תאור הקישור",
    more_options: "אפשרויות נוספות",
    placeholder_st_link_link: "הוסף את הקישור",
    report_title_sales: "מכירות",
    report_same_date_last_year: "היום בשנה שעברה",
    report_compared_period_sales: "היום בשנה שעברה",
    report_target: "יעד",
    report_sales: "מכירות",
    report_monthly_target: "יעד מכירות חודשי",
    report_from_begging_of_month: "מתחילת החודש",
    report_last_week: "בשבוע שעבר",
    report_weekly_target: "יעד שבועי",
    report_required_target: "הקצב הנדרש עד כה",
    report_sales_rate_vs_target: "קצב המכירות מול היעד",
    report_store_area_rank: "דירוג ביחס לאזור",
    report_store_retail_rank: "דירוג ביחס לרשת",
    report_title_traffic: "תנועת לקוחות",
    report_traffic_visitors: "מבקרים",
    report_traffic_transactions: "עסקאות",
    report_conversion_rate: "יחס המרה",
    general_target: "יעד",
    report_title_basket_size: "גודל סל",
    general_units: "יח'",
    report_basket_size_sales: "ממוצע סל כספי",
    report_basket_size_qty: "ממוצע סל כמותי",
    report_title_labor: "תקן שעות",
    report_labor_used: "ניצול בפועל",
    report_labor_remainder: "יתרת שעות לניצול",
    report_labor_unit: "שעות",
    report_title_loyalty_program: "מועדון לקוחות",
    report_loyalty_program_new_members: "חברים חדשים",
    report_loyalty_program_new_leads: "לידים חדשים",
    report_title_department: "ביצועי מחלקות",
    report_title_general: "כללי",
    report_general_best_sellers: "המוצרים המכניסים ביותר בחנות מתחילת החודש",
    report_general_best_categories: "התפלגות מכירות לפי קטגוריות",
    report_title_stores_performance: "ביצועי החנויות",
    report_series0: "חנות",
    report_series1: "רשת",
    report_yesterday: "מכירות יום האתמול",
    report_relative_target: "יעד יחסי",
    report_todays_target: "יעד יומי",
    report_title_brands: "ביצועי מותגים",
    delete_task_options_title: "מחיקת משימה",
    delete_task_options_subtitle: "בחר את אחת האפשרויות",
    delete_all_stores: "מחק מכלל החנויות",
    delete_this_store: "מחק מחנות זו",
    delete_category: "מחק קטגוריה",
    same_category_alert_title: "קטגוריה עם שם זה כבר קיימת במשימה, אנא בחר/י שם אחר",
    uploading_post_media_loading: "טוען...",
    report_the_product_sold_units: "כמות:",
    report_the_product_sold_money: "הכנסות: ",
    report_best_sellers_man: "המוצרים הנמכרים ביותר בחנות - גברים",
    report_best_categories_man: "התפלגות מכירות לפי קטגוריות - גברים",
    report_best_sellers_women: "המוצרים הנמכרים ביותר בחנות - נשים",
    report_best_categories_women: "התפלגות מכירות לפי קטגוריות - נשים",
    report_general_best_sellers_man: "המוצרים הנמכרים ביותר בחנות מתחילת החודש - גברים",
    report_general_best_categories_man: "התפלגות מכירות לפי קטגוריות מתחילת החודש - גברים",
    report_general_best_sellers_women: "המוצרים הנמכרים ביותר בחנות מתחילת החודש - נשים",
    report_general_best_categories_women: "התפלגות מכירות לפי קטגוריות מתחילת החודש - נשים",
    Manager_goal: "יעדי מנהל/ת",
    groups_title_by_income: "ביצועי מבצעים לפי הכנסה",
    groups_title_by_qty: "ביצועי מבצעים לפי כמות",
    show_all: "הצג עוד",
    view_linked_task: "קרא עוד",
    linked_tasks_title: "תאור המשימה",
    subtask_comments: "תגובות",
    subtask_files: "קבצים",
    newsfeed_sidebar: "לוח עדכונים",
    reports_sidebar: "דוחות",
    store_filter_sidebar: "סנן לפי חנות",
    see_less_sidebar: "תראה פחות ",
    see_more_sidebar: "הצג עוד",
    area_filter_sidebar: "סנן אזורים",
    like_counter_of: "מתוך",
    like_counter_users: "משתמשים",
    title_header: "כותרת",
    priority_header: "חשיבות",
    timeline_header: "קמפיין",
    status_header: "סטטוס",
    title_analytic_header: "התפלגות ביצוע המשימות",
    inspiration_image_header: "תמונות להמחשה",
    st_image_header: "משימות צילום",
    st_text_header: "שאלות",
    st_link_header: "לינקים חיצוניים",
    st_product_header: "כרטיסי מוצר",
    task_data_subtitle: `משימות שמיוצרות ע"י המערכת שלנו, בעזרת מידע זה תוכלוט לשפר על המכירות בחנות שלכם `,
    task_opt_subtitle: "משימות אופרטיביות, המיועדות לניהול החנות המלאי והתצוגה בחנות שלכם ",
    add: "הוסף",
    rotate: "סובב",
    sub_task_hover_details: "לחץ לפרטים נוספים",
    uploaded_tag: "UPLOADED",
    photo: "תמונה",
    video: "סרט",
    post_header: "צור פוסט",
    view_all: "הצג הכול",
    high_priority: "גבוהה",
    temp_share_to: "פרסם לכולם",
    fetch_more_comments_post_button: "עוד תגובות",
    hide_comments_post_button: "פחות תגובות",
    is_roll_toggale: "STORE-E Highlights",
    traning_task_name: "הכשרה והדרכה",
    reply_button: "השב/י",
    replay_button: "השב/י",
    newer_comment_button: "תגובות חדשות",
    older_comment_button: "עוד תגובות",
    reply_to_title: "משיב/ה ל",
    view_more_reply: "עוד תגובות",
    is_st_required: "תת משימה חובה",
    create_task_name_placeholder: "שם המשימה",
    create_task_view_placeholder: "סוג המשימה",
    create_task_view_op_0: "שיפור מכירות",
    create_task_view_op_1: "משימות תפעוליות",
    create_task_view_op_3: "VM",
    create_task_view_op_2: "הכשרה והדרכה",
    create_task_assignee_placeholder: "המשימה עבור",
    create_task_assignee_selected: "המשימה עבור",
    create_task_start_date_placeholder: "בחר/י תאריך התחלה",
    create_task_end_date_placeholder: "בחר/י תאריך סיום",
    create_task_st_title_placeholder: "שם תת-המשימה",
    create_task_st_pid_placeholder: "מק״ט",
    create_task_status_menu_select_btn: "בחר/י את התשובות האפשריות",
    create_task_add_img_btn: "הוספ/י תמונה",
    create_task_st_create_btn: "הוספה",
    create_task_st_cancel_btn: "ביטול",
    create_task_st_save_btn: "שמור",
    create_task_add_cat_btn: "הוספת קטגוריה",
    create_task_name_title: "שם המשימה",
    create_task_view_title: "סוג המשימה",
    create_task_assignee_title: "עבור",
    create_task_dialog_box_title: "הוסף תאור עבור",
    create_task_dialog_box_placeholder: "תאור...",
    create_task_st_image_title: "משימות צילום",
    create_task_st_text_title: "שאלות",
    create_task_st_link_title: "לינקים חיצוניים",
    create_task_st_product_title: "כרטיסי מוצר",
    create_task_st_image_btn: "הוספ/י משימות צילום",
    create_task_st_text_btn: "הוספ/י שאלות",
    create_task_st_link_btn: "הוספ/י לינקים חיצוניים",
    create_task_st_product_btn: "הוספ/י כרטיסי מוצר",
    create_task_sidebar_task_title: "שם המשימה",
    forget_password_alert_success_body: "הסיסמה שונתה",
    create_task_modal_title: "יצירת משימה",
    create_task_btn: "יצירת משימה",
    create_task_assignee_header: "בחר/י נמענים",
    choose_all_groups_btn: "בחר/י הכול",
    submit_btn: "אישור",
    footer_button_return: "אישור",
    filter_btn: "סנן",
    task_overview_graph_title: "התפלגות ביצוע המשימות",
    task_overview_card_r_title: "מס׳ משימות:",
    task_overview_card_c_title: "קבוצת משימות:",
    task_overview_card_l_title: "קבוצות:",
    task_overview_naxt_page_button: "מידע נוסף",
    task_overview_see_task_button: "פירוט",
    filter_header: "סנן",
    assigned_by_filter: "משימות שהוקצו",
    assigned_by_me_filter: "על ידי",
    assigned_by_all_filter: "ע״י כל משתמש",
    group_filter: "קבוצות",
    group_selecte_all_filter: "בחר הכול",
    group_unselecte_filter: "בטל בחירה",
    group_back_filter: "ביטול",
    group_filter_header: "בחירת קבוצות",
    done_filter_btn: "אישור",
    apply_all_filters_btn: "אישור",
    level_filter: "פילוח לפי רמת",
    all_tasks_group_list: "רשימת קבוצות",
    stores_all_tasks_group_list: "חנות",
    areas_all_tasks_group_list: "אזור",
    task_overview_title: "לוח עדכונים",
    filter_by_retail: "רשת",
    analytical_done: "בוצע",
    analytical_inprogress: "בתהליך",
    analytical_todo: "טרם בוצע",
    task_overview_filter_title: "סנן",
    filter_by_area: "אזור",
    filter_by_store: "חנות",
    task_overview_category_status_title: "נתוני הקטגוריה נוכחית",
    filter_status_done: "בוצע",
    filter_status_inprogress: "בתהליך",
    filter_status_todo: "טרם בוצע",
    task_overview_status_title: "התקדמות",
    sort_by_status: "סדר לפי התקדמות",
    task_overview_task_view_0: "שיפור מכירות",
    task_overview_task_view_1: "משימות תפעוליות",
    task_overview_task_view_3: "VM",
    task_overview_task_view_2: "הכשרה והדרכה",
    back_button: "חזור אחורה",
    task_overview_filter_status: "סטטוס",
    task_overview_filter_status_all: "הכול",
    task_overview_export_btn: "הכן קובץ",
    task_overview_select_all_btn: "בחר/י הכול",
    task_overview_choose_group_btn: "בחר/י",
    delete_task_btn: "מחיקת משימה",
    delete_task_cluster_btn: "מחיקה מכול הקבוצות",
    delete_task_instance_btn: "מחיקת רק מקבוצה זאת",
    delete_task_modal_body: "כיצד תרצו למחוק את המשימה?",
    delete_task_modal_header: "מחיקת משימה",
    search_context_placeholder: "חיפוש של קבוצה",
    search_context_empty_results: "אין קבוצות מתאימות",
    task_status_done: "בוצע",
    task_status_inprogress: "בתהליך",
    task_status_todo: "טרם בוצע",
    mobile_menu_header: "תפריט",
    task_overview_expand_close_button: "תצוגה מינימלית"
  },
  fr: {
    settings: "Réglages",
    logout: "Déconnexion",
    done: "Terminé",
    yes: "Oui",
    no: "Non",
    undone: "Non effectué",
    weeklyTasksTitle: "Tâches hebdomadaires à effectuer",
    weekly_report: "Rapport hebdomadaire",
    noItems: "Chargement des données",
    optimized: "Emplacement optimal",
    out_of_stock: "En rupture de stock",
    in_order: "Commande en cours",
    cancel: "Annulation",
    new: "New",
    best_seller: "Meilleure vente",
    unitsChange: "Changement d'unités",
    status: "Statut",
    tasks: "Tâches",
    total_items: "La totalité des articles",
    improved_location: "Amélioration de l'agencement",
    sales_status: "Statut des ventes",
    increase_in_units: "Augmentation des unités",
    already_optimized: "Emplacement optimal",
    new_location: "Emplacement différent",
    card_confirm_text: "Êtes-vous sûr de vouloir quitter?",
    return1: "Article échangé 1",
    return2: "Article échangé 2",
    return3: "Article échangé 3",
    return4: "Article échangé 4",
    in_stock: "En stock",
    manager_guidelines: "Instructions du gestionnaire",
    menu_title: "Menu",
    category_images_title: "Images",
    alert_success: "Bien reçu(e), merci !",
    alert_error: "Erreur !",
    choose: "Sélectionnez",
    description: "Description",
    tags: "Sticker",
    send: "Envoyer",
    save: "Sauvegarder",
    write_a_comment: "Écrire un commentaire...",
    files: "Fichiers",
    comments: "Commentaires",
    reply: "Répondre",
    info: "Information",
    attached_files: "Pièce jointe",
    cover_name: "Télécharger une image",
    please_take_an_item_photo: "Veuillez prendre une photo de l'article",
    hello: "Bonjour!",
    menu_in_stock_smaller_then_5: "En stock",
    menu_done: "Effectué",
    menu_undone: "Non effectué",
    menu_yes: "Oui",
    menu_no: "Non",
    menu_irrelevant: "Hors sujet",
    menu_out_of_stock: "En rupture de stock",
    menu_in_stock: "En stock",
    menu_not_carried_out: "Non effectué",
    menu_there_are_shortcomings: "Il y a des articles manquants",
    menu_there_are_no_shortcomings: "Il n'y a pas d'articles manquants",
    menu_read: "J'ai lu",
    menu_different_location: "Emplacement différent",
    menu_missing_dimensions: "En manque de taille",
    menu_missing_colors: "En manque de couleurs",
    menu_I_didnt_change_anything: "Je n'ai rien changé",
    menu_located_right: "Emplacement optimal",
    menu_location_changed: "L'emplacement a changé",
    menu_switched: "Remplacé",
    menu_in_order: "Commande en cours",
    menu_missing_size: "En manque de taille",
    menu_optimized_location: "Optimized location",
    menu_driver: "Non, il y avait un chauffeur",
    menu_driver_no_no: "Non, il n'y avait pas de chauffeur",
    menu_fixed: "Réglé",
    menu_no_fixed: "Non réglé",
    no_status_taskoverview: "Sans statut",
    no_status: "Choisir statut",
    choose_status: "Choisir statut",
    menu: "Numéro du menu",
    menu_improved_location: "Emplacement amélioré",
    False: "Faux",
    True: "Vrai",
    menu_size_or_color_missing: "Tailles / Couleurs manquantes",
    err_page_title: "Chargement lent",
    err_page_homepgae_btn: "Chargement du système",
    search_branch: "Chercher un magasin",
    user_search_placeholder: "Chercher un magasin",
    login_username: "Identifiant",
    login_password: "Mot de passe",
    forgot_password: "Mot de passe oublié ?",
    login_header: "Login into your account",
    login: "Connexion",
    change_role: "Changer l'identifiant",
    you_are: "Vous êtes",
    store_manager: "Responsable du magasin",
    district_manager: "Responsable régional",
    chain_store_manager: "Responsable de la chaîne",
    choose_store: "Sélectionner un magasin",
    choose_store_search: "Quel magasin cherchez vous?",
    data_analytics_title: "Analyse des données",
    data_tasks: "Amélioration des ventes",
    ops_tasks: "Missions",
    history_tasks: "Historique",
    training_tasks: "Formation et instruction",
    vm_tasks: "VM",
    tasks_search: "Recherche de tâches",
    view_subtasks: "Afficher les sous-tâches",
    delete: "Supprimer",
    view_more_comments: "Afficher plus de commentaires",
    unathorized: "L'identifiant n'est pas autorisé",
    permission: "Désolé, vous n'êtes pas autorisé(e)",
    wrong: "Désolé, une erreur s'est produite",
    no_internet: "Pas de connexion internet",
    language: "Langue",
    tasks_dashboard: "Panneau de configuration des tâches",
    language_name: "Français",
    monthly_rate_header: "Production quotidienne requise",
    monthly_rate_current: "Actuel",
    monthly_rate_gap: "Un écart",
    card_data_1: "Objectifs mensuels",
    card_data_2: "Les ventes MTD",
    card_data_3: "Ventes de la veille",
    card_data_4: "Objectif quotidien",
    monthly_sales_goal_rate: "Atteindre l'objectif de vente mensuel",
    main_clock_sub_title: "Ventes en rapport à la cible",
    main_clock_title: "Pourcentage selon la cible",
    clocks_header: "Objectifs",
    data_tasks_header: "Amélioration des ventes",
    data_tasks_sub_header: "Tâches de donnés basée sur le IA",
    opt_tasks_header: "Tâches opérationnelles",
    opt_tasks_sub_header: "Tâches créées manuellement",
    default_filter_header: "Afficher toutes les zones",
    area_filters: "Afficher toutes les zones",
    questions: "Questions",
    stores: "Magasins",
    current_status: "Statut actuel",
    no_tasks_to_present: "Aucune tâche à afficher",
    attach_file_btn: "Joindre un fichier",
    prod_id: "RÉF",
    image_sub_header: "Prenez une photo!",
    subtask_view: "Afficher",
    legal_privacy: "Politique de confidentialité",
    legal_terms: "Conditions d'utilisation",
    no_reports: "Rapports manquants",
    goal: "Objectif",
    enable_notification: "Activer les alertes",
    on_boarding_header: "Bienvenue",
    on_boarding_sub_header: "Pour commencer, veuillez ajouter votre photo",
    on_boarding_open_camera_btn: "Prenez un selfie",
    on_boarding_upload_image_btn: "Téléchargez de la galerie",
    on_boarding_image_added: "Magnifique!",
    nothing_found_massage: "Aucun contenu à afficher, veuillez passer à la catégorie suivante",
    no_camera_premision_title: "Aucune autorisation d'utilisation de l'appareil photo",
    no_camera_premision_body: "Veuillez autoriser l'utilisation de l'appareil photo",
    no_camera_premision_cancel: "Annulation",
    no_camera_premision_confirm: "Veuillez passer aux réglages",
    read_less: "Voir moins",
    read_more: "Voir plus",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟",
    morning_greeting: "Bonjour",
    noon_greeting: "Bon après-midi",
    evening_greeting: "Bonsoir",
    night_greeting: "Bonne nuit",
    user_liked: "Reçu(e)",
    user_not_liked: "Reçu(e)",
    newsfeed_like_btn: "Reçu(e)",
    newsfeed_like_btn_1: "Reçu(e)",
    newsfeed_comment_btn: "Commentaire",
    view: "Afficher",
    caming_soon: "Bientôt disponible",
    action_button_1: "Contrôle des tâches",
    action_button_2: "Formation et instruction",
    action_button_3: "Gestion de l'inventaire",
    action_button_4: "Ressource humaine",
    action_button_5: "Comptabilité",
    action_button_6: "Planifier les vacations de travail",
    action_button_7: "Créer un lien",
    action_copy_to_clickbourd_alert: "Le lien restera disponible pendant 7 jours",
    action_copy_to_clickbourd_alert_title: "Le lien a été copié",
    control_panels_title: "Panneaux de contrôle",
    pinged: "Le ping a été envoyé",
    ping_all: "Envoyez un ping à tous",
    ping: "Ping",
    ping_users_got_it: "Utilisateurs qui ont reçu",
    create_post_placeholder: "A quoi pensez-vous?",
    post_entry_placeholder: "Vous souhaitez envoyer un message à votre équipe?",
    link_task: "Liée à la tâche",
    user_blessing: "Good Morning,",
    delete_post_title: "Supprimer la publication",
    delete_post_approval: "Êtes-vous sur de vouloir supprimer cette publication?",
    edit: "Modifier la publication",
    post: "Afficher la publication",
    crash_go_back: "Retourner",
    crash_title: "Communication faible ou forte charge sur le serveur",
    sub_task_search: "Recherchez une sous-tâche",
    skip: "Suivant",
    show: "Afficher",
    actions: "Actions",
    legal: "Juridique",
    account_settings: "Paramètres du compte",
    on_boarding_change_image_btn: "Changer l'image",
    related_files: "Fichiers",
    card_1_time_1: "Objectif quotidien",
    card_2_time_1: "Depuis le début de la journée",
    clock_1_title: "Les ventes par rapport à la cible",
    clock_1_sub_title: "Taux requis",
    clock_1_info1: "Rang/Region",
    clock_1_info2: "Rang/Chaîne",
    card_1_time_2: "Objectif quotidien",
    card_2_time_2: "Depuis le début de la semaine à ce jour",
    card_1_time_3: "Objectif hebdomadaire",
    card_2_time_3: "Depuis le début de la semaine à ce jour",
    card_1_time_4: "Objectif mensuel",
    card_2_time_4: "Depuis le début du mois à ce jour",
    time_1: "Aujourd'hui",
    time_2: "Hier",
    time_3: "Depuis le début de la semaine à ce jour",
    time_4: "Depuis le début du mois à ce jour",
    report_sub_title_1: "Ventes",
    report_sub_title_2: "Visiteurs",
    report_sub_title_3: "Heures de travail",
    report_sub_title_4: "Taille du panier",
    report_sub_title_5: "Programme fidélité",
    report_sub_title_6: "Performance du magasin",
    clock_2_title: "Taux de conversion",
    clock_2_sub_title: "Objectif",
    clock_2_info1: "Visiteurs",
    clock_2_info2: "Transactions",
    card_title_1: "Heures de travail selon l’objectif",
    card_title_2: "Rappel des heures restantes à utiliser",
    card_title_3: "Panier moyen",
    card_title_4: "Panier moyen (en quantité)",
    clock_3_title: "Nouveaux membres",
    clock_3_sub_title: "Objectif",
    clock_4_title: "Nouveaux prospects",
    clock_4_sub_title: "Objectif",
    card_3_title: "Les meilleures ventes du magasin",
    card_4_title: "Rapport par catégorie",
    category_1: "Catégories A",
    category_2: "Catégories B",
    category_3: "Catégories C",
    category_4: "Catégories D",
    category_type_1: "Chaîne",
    category_type_2: "Magasin",
    time_1_period_start: "Juin 30, 2020",
    time_1_period_end: "vs Juin 30, 2019",
    time_2_period_start: "Mars 28, 2020",
    time_2_period_end: "vs Mars 28, 2019",
    time_3_period_start: "Mars 29, 2020",
    time_3_period_end: "vs Mars 24 - Mars 31, 2019",
    time_4_period_start: "Mars 1 - Mars 31, 2020",
    time_4_period_end: "vs Mars 1- Mars 31, 2019",
    report_units: "Articles",
    report_currency: "€",
    prod_1: "Article 1",
    prod_2: "Article 2",
    prod_3: "Article 3",
    prod_4: "Article 4",
    prod_5: "Article 5",
    prod_6: "Article 6",
    prod_7: "Article 7",
    prod_8: "Article 8",
    prod_9: "Article 9",
    prod_10: "Article 10",
    real_report: "En attente de l'approbation du siège",
    force_update_title: "Nous avons amélioré le système. Veuillez cliquer pour mettre à jour!",
    tasks_done: "Effectué",
    tasks_progress: "En cours",
    tasks_open: "Reste à faire",
    forgot_password_title: "Saisissez le numéro de téléphone associé à votre compte.",
    forgot_password_subtitle: "Nous enverrons un lien par SMS à ce numéro",
    forgot_password_alert_succes_body: "Génial! Un lien de réinitialisation du mot de passe a été envoyé sur votre téléphone",
    forgot_password_alert_error_body: "Désolé, nous ne pouvons pas identifier ce numéro",
    enter_password_alert_not_valid_body: "Ce mot de passe n'est pas valide ou très faible, le mot de passe doit comporter au moins 8 caractères. Un mot de passe fort est une combinaison de lettres majuscules et minuscules et de chiffres",
    enter_password_alert_not_matching_body: "Vous avez mal saisi le mot de passe",
    is_st_product_camera_enabled: "Activer l'appareil photo dans les fiches produit",
    is_st_product_numbered: "Numérotez les fiches produits",
    header_button_back: "Retour",
    header_button_next: "Suivant",
    header_button_publish: "Publier",
    publish_task_btn: "Publier",
    placeholder_task_name: "Nom de la tâche",
    date_picker_start: "Choisissez la date de début",
    date_picker_end: "Choisissez la date de fin",
    placeholder_category_name: "Catégorie sans titre",
    category_default_name: "Catégorie sans titre",
    creat_task_sidebar_task_title: "Nom de la tâche",
    creat_task_dialog_box_placeholder: "Ajouter une description...",
    add_category_button: "Ajouter une catégorie",
    add_dailog_box_button: "Ajouter une description",
    add_inspiration_image_button: "Ajouter des photos",
    placeholder_st_text_title: "Qu'est ce que vous voulez demander?",
    placeholder_st_product_title: "Remplissez le titre du produit",
    placeholder_st_product_id: "Remplissez l'identifiant du produit",
    placeholder_st_image_title: "Prenez une photo de ...",
    subtask_text: "Poser une question",
    subtask_product: "Mise à jour du produit",
    subtask_image: "Demander une photo",
    subtask_link: "Ajouter un lien",
    menu_add_button: "Ajouter des menus",
    menu_edit_button: "Change menus",
    creat_task_status_menu_modal_open: "Change menus",
    create_task_status_menu_modal_header: "Change menus",
    menu_select_button: "Sélectionner",
    deselect_all_stores_button: "Annuler la sélection",
    select_all_stores_button: "Tout sélectionner",
    search_stores: "Rechercher des magasins",
    select_counter_1: "Sélectionné",
    select_counter_2: "De",
    placeholder_st_link_title: "Description du lien",
    more_options: "Plus d'options",
    placeholder_st_link_link: "Ajouter un lien",
    report_title_sales: "Ventes",
    report_same_date_last_year: "Les ventes MTD de l'année dernière",
    report_compared_period_sales: "Les ventes MTD de l'année dernière",
    report_target: "Objectif mensuel",
    report_sales: "Depuis le début du mois à ce jour",
    report_monthly_target: "Objectif mensuel",
    report_from_begging_of_month: "Depuis le début du mois à ce jour",
    report_last_week: "La semaine dernière",
    report_weekly_target: "Objectif hebdomadaire",
    report_required_target: "La cible requise",
    report_sales_rate_vs_target: "Taux de vente par rapport à cible",
    report_store_area_rank: "Rang/Region",
    report_store_retail_rank: "Rang/Chaîne",
    report_title_traffic: "Visiteurs",
    report_traffic_visitors: "Visiteurs",
    report_traffic_transactions: "Transactions",
    report_conversion_rate: "Taux de conversion",
    general_target: "Objectif",
    report_title_basket_size: "Taille du panier",
    general_units: "Articles",
    report_basket_size_sales: "Le panier moyen",
    report_basket_size_qty: "Panier moyen (en quantité)",
    report_title_labor: "Heures de travail",
    report_labor_used: "Heures de travail selon l’objectif",
    report_labor_remainder: "Rappel des heures restantes à utiliser",
    report_labor_unit: "Heures",
    report_title_loyalty_program: "Programme fidélité",
    report_loyalty_program_new_members: "Nouveaux membres",
    report_loyalty_program_new_leads: "Nouveaux prospects",
    report_title_department: "Departments",
    report_title_general: "La performance du magasin",
    report_general_best_sellers: "Les meilleures ventes du magasin",
    report_general_best_categories: "Rapport par catégorie",
    report_title_stores_performance: "La performance du magasin",
    report_series0: "Magasin",
    report_series1: "Chaîne",
    report_yesterday: "Ventes de la veille",
    report_relative_target: "Objectif quotidien",
    report_todays_target: "Objectif quotidien",
    report_title_brands: "Brands",
    delete_task_options_title: "Supprimer la tâche",
    delete_task_options_subtitle: "Choisissez une des options",
    delete_all_stores: "Supprimer de tous les magasins",
    delete_this_store: "Supprimer de ce magasin",
    delete_category: "Supprimer la catégorie",
    same_category_alert_title: "Une catégorie portant ce nom existe déjà dans la tâche, veuillez sélectionner un autre nom",
    uploading_post_media_loading: "Chargement ...",
    report_the_product_sold_units: "le produit a été vendu",
    report_the_product_sold_money: "Résumer à",
    report_best_sellers_man: "Meilleures ventes du magasin - Homme",
    report_best_categories_man: "Rapport de catégorie - Homme",
    report_best_sellers_women: "Meilleures ventes du magasin - Femme",
    report_best_categories_women: "Rapport de catégorie - Femme",
    report_general_best_sellers_man: "Meilleures ventes du magasin - Homme",
    report_general_best_categories_man: "Rapport de catégorie - Homme",
    report_general_best_sellers_women: "Meilleures ventes du magasin - Femme",
    report_general_best_categories_women: "Rapport de catégorie - Femme",
    Manager_goal: "Manager's Goal",
    groups_title_by_income: "Groups by income",
    groups_title_by_qty: "Groups by quantity",
    show_all: "Show all ",
    view_linked_task: "Voir Missions",
    linked_tasks_title: "Linked with task",
    newsfeed_sidebar: "Newsfeed",
    reports_sidebar: "Reports",
    store_filter_sidebar: "Filter by store",
    see_less_sidebar: "See Less",
    see_more_sidebar: "See More",
    area_filter_sidebar: "Filter by area",
    like_counter_of: "of",
    like_counter_users: "users",
    title_header: "Title",
    priority_header: "Priority",
    timeline_header: "Timeline",
    status_header: "status",
    inspiration_image_header: "Inspiration Images",
    st_image_header: "Capture a photo sub-tasks",
    st_text_header: "Question sub-tasks",
    st_link_header: "External Links",
    st_product_header: "Product Cards sub-tasks",
    task_data_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    task_opt_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    add: "Add",
    rotate: "Rotate",
    sub_task_hover_details: "Click for Details",
    uploaded_tag: "UPLOADED",
    photo: "Photo",
    video: "Video",
    post_header: "Create Post",
    view_all: "View all",
    high_priority: "High",
    temp_share_to: "Share to all Stores",
    fetch_more_comments_post_button: "More comments",
    hide_comments_post_button: "Hide comments",
    is_roll_toggale: "STORE-E Highlights",
    traning_task_name: "Formation et instruction",
    reply_button: "Reply",
    replay_button: "Reply",
    newer_comment_button: "New Comments",
    older_comment_button: "More Comments",
    reply_to_title: "Replying to",
    view_more_reply: "More replies",
    is_st_required: "Required Sub Task",
    create_task_view_op_0: "Amélioration des ventes",
    create_task_view_op_1: "Missions",
    create_task_view_op_3: "Historique",
    create_task_view_op_2: "Formation et instruction",
    create_task_assignee_placeholder: "Choose Assignee",
    create_task_assignee_selected: "Choose Assignee",
    create_task_start_date_placeholder: "Choose Start Date",
    create_task_end_date_placeholder: "Choose End Date",
    create_task_st_title_placeholder: "Sub-Task Name",
    create_task_st_pid_placeholder: "Product ID",
    create_task_status_menu_select_btn: "Choose The Possible Answers",
    create_task_add_img_btn: "Add Image",
    create_task_st_create_btn: "Create",
    create_task_st_cancel_btn: "Cancel",
    create_task_st_save_btn: "Save",
    create_task_add_cat_btn: "Add Category",
    create_task_name_title: "Task Name",
    create_task_view_title: "Task Type",
    create_task_assignee_title: "To",
    create_task_dialog_box_title: "Add a description for",
    create_task_dialog_box_placeholder: "Description ...",
    create_task_st_image_title: "Capture a photo sub-tasks",
    create_task_st_text_title: "Question sub-tasks",
    create_task_st_link_title: "External Links",
    create_task_st_product_title: "Product Cards sub-tasks",
    create_task_st_image_btn: "Add a capture photo sub-tasks",
    create_task_st_text_btn: "Add a question sub-tasks",
    create_task_st_link_btn: "Add an external links",
    forget_password_alert_success_body: "Password was reset",
    create_task_assignee_header: "Assignee",
    choose_all_groups_btn: "choose all",
    level_filter: "Segmentation by level of ",
    analytical_done: "Effectué",
    analytical_inprogress: "En cours",
    analytical_todo: "Reste à faire",
    filter_status_done: "Effectué",
    filter_status_inprogress: "En cours",
    filter_status_todo: "Reste à faire",
    task_overview_task_view_0: "Amélioration des ventes",
    task_overview_task_view_1: "Missions",
    task_overview_task_view_3: "Historique",
    task_overview_task_view_2: "Formation et instruction",
    back_button: "Retourner",
    task_overview_filter_status: "Status",
    task_overview_select_all_btn: "Tout sélectionner",
    task_overview_choose_group_btn: "Sélectionnez",
    task_status_done: "Effectué",
    task_status_inprogress: "En cours",
    task_status_todo: "Reste à faire",
    mobile_menu_header: "Menu",
    task_overview_expand_close_button: "Minimal View"
  },
  es: {
    settings: "Definiciones",
    logout: "Cerrar sesión",
    done: "Hecho ",
    yes: "Si ",
    no: "No",
    weeklyTasksTitle: "Completemos las tareas semanales para mejorar el rendimiento",
    weekly_report: "Informe semanal",
    noItems: "Cargando datos",
    optimized: "Optimizado",
    out_of_stock: "Agotado",
    comment: "Comentario ",
    cancel: "Cancelar ",
    new: "Nuevo",
    best_seller: "Más vendido ",
    status: "Estado ",
    tasks: "Tareas",
    total_items: "Articulos Totales",
    sales_status: "Estado de ventas (unidad)",
    increase_in_units: "Aumento en unidades",
    already_optimized: "Ya optimizado",
    new_location: "Nueva ubicacion",
    card_confirm_text: "Estas seguro?",
    return1: "return 1",
    return2: "return 2",
    return3: "return 3",
    return4: "return 4",
    in_stock: "in stock",
    manager_guidelines: "Directrices del gerente",
    menu_title: "Menú",
    category_images_title: "Imagenes",
    alert_success: "Gracias! ",
    alert_error: "Error !",
    choose: "Elige",
    description: "Descripción",
    tags: "Etiquetas",
    send: "Enviar",
    save: "Guardar",
    write_a_comment: "Escribe un comentario...",
    files: "Documentos",
    comments: "Comments",
    reply: "Responder",
    info: "Info",
    attached_files: "Documentos adjuntos",
    cover_name: "Cargar imagen",
    please_take_an_item_photo: "Por favor Toma una foto del artículo.",
    hello: "¡Hola!",
    menu_in_stock_smaller_then_5: " En Stock<5",
    menu_done: "Hecho",
    menu_undone: "No Realizado ",
    menu_yes: "Si",
    menu_no: "no",
    menu_irrelevant: "Irrelevante",
    menu_out_of_stock: "Fuera se Stock/Agotado",
    menu_in_stock: "En stock",
    menu_not_carried_out: "No realizado",
    menu_there_are_shortcomings: "Hay deficiencias",
    menu_there_are_no_shortcomings: "No hay deficiencias",
    menu_read: "Leer",
    menu_different_location: "Ubicación diferente",
    menu_missing_dimensions: "Dimensiones faltantes",
    menu_missing_colors: "Colores faltantes",
    menu_I_didnt_change_anything: "no cambié nada",
    menu_located_right: "Situado correctamente",
    menu_location_changed: "Ubicación cambiada",
    menu_switched: "Reemplazado",
    menu_missing_size: "Tamaño faltante",
    menu_optimized_location: "Optimized location",
    menu_fixed: "Resuelto",
    menu_no_fixed: "No resuelto",
    no_status_taskoverview: "No estado",
    no_status: "Elige estado",
    choose_status: "Elige estado",
    menu: "Menú",
    menu_improved_location: "Ubicación mejorada",
    False: "Falso",
    True: "Verdadero",
    menu_size_or_color_missing: "Tamano/Color Faltan",
    err_page_homepgae_btn: "Ir al inicio",
    search_branch: "Buscar marca",
    login_username: "Usuario",
    login_password: "contraseña",
    forgot_password: "Olvidó contraseña",
    login_header: "acceso en su cuenta",
    login: "Login",
    you_are: "Tu eres ",
    store_manager: "Gerente del local",
    district_manager: "Gerente del distrito",
    choose_store: "Elige Local ",
    choose_store_search: "¿Qué tienda estás buscando?",
    data_analytics_title: "Análisis de datos",
    data_tasks: "Improving sales",
    ops_tasks: "Ops Tasks",
    history_tasks: "History",
    training_tasks: "Collaborative Learning",
    vm_tasks: "VM",
    tasks_search: "Tasks Search",
    view_subtasks: "View Subtasks",
    delete: "Delete",
    view_more_comments: "View more comments...",
    unathorized: "Unathorized",
    permission: "Sorry, you don't have permission",
    wrong: "Sorry, something went wrong",
    no_internet: "No internet connection",
    language: "Language",
    tasks_dashboard: "Tasks Dashboard",
    language_name: "Español ",
    monthly_rate_header: "Required Daily Rate",
    monthly_rate_current: "Current",
    monthly_rate_gap: "Difference",
    card_data_1: "Monthly Target",
    card_data_2: "MTD Sales",
    card_data_3: "Yesterday's Sales",
    card_data_4: "Daily Target",
    monthly_sales_goal_rate: "Monthly sales goal performens",
    main_clock_sub_title: "MTD vs Target (%)",
    main_clock_title: "Percentage vs. Target",
    clocks_header: "Goals",
    data_tasks_header: "Data tasks",
    data_tasks_sub_header: "AI generated tasks based on data",
    opt_tasks_header: "Operational Tasks",
    opt_tasks_sub_header: "Tasks created manually",
    default_filter_header: "Show All Areas",
    area_filters: "Show All Areas",
    questions: "Questions",
    stores: "Stores",
    current_status: "Current status",
    no_tasks_to_present: "No tasks to present at this time",
    attach_file_btn: "Add File",
    prod_id: "ID",
    image_sub_header: "Snap a pic!",
    subtask_view: "View",
    legal_privacy: "Privacy Policy",
    legal_terms: "Terms Of Use",
    no_reports: "No reports",
    goal: "Goal",
    enable_notification: "Enable Notification",
    on_boarding_header: "Welcome",
    on_boarding_sub_header: "To Get Started Please Add Your Photo",
    on_boarding_open_camera_btn: "Take a Selfie",
    on_boarding_upload_image_btn: "Upload From Gallery",
    on_boarding_image_added: "Looks good :)",
    nothing_found_massage: " Nothing to overview. See the next category.",
    no_camera_premision_title: "No Camera Permission",
    no_camera_premision_body: "Please Allow Camera Permission In Settings",
    no_camera_premision_cancel: "Cancel",
    no_camera_premision_confirm: "Go to Settings",
    read_less: "Read Less",
    read_more: "Read More",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟 ",
    morning_greeting: "Good Morning",
    noon_greeting: "Good Afternoon",
    evening_greeting: "Good Evening",
    night_greeting: "Good Night",
    user_liked: "Gosto",
    user_not_liked: "Gosto",
    newsfeed_like_btn: "Gosto",
    newsfeed_like_btn_1: "Gosto",
    newsfeed_comment_btn: "Comentario ",
    view: "View",
    caming_soon: "Coming soon",
    action_button_1: "Tasks Overview",
    action_button_2: "Collaborative Learning",
    action_button_3: "Inventory Balance",
    action_button_4: "Human Resources",
    action_button_5: "Cash management",
    action_button_6: "Employee Scheduling",
    action_button_7: "Master Key",
    action_copy_to_clickbourd_alert: "the link is valid for 7 days",
    action_copy_to_clickbourd_alert_title: "link was copyed to clipboard",
    control_panels_title: "Control Panel",
    pinged: "Pinged",
    ping_all: "Ping all",
    ping: "Ping",
    ping_users_got_it: "People Who Got It",
    create_post_placeholder: "What’s on your mind?",
    post_entry_placeholder: "Want to message your team?",
    link_task: "Link Task",
    user_blessing: "Good Morning,",
    delete_post_title: "Delete Post",
    delete_post_approval: "Are You Sure You Want To Delete This Post?",
    edit: "Edit",
    post: "Post",
    crash_go_back: "Go Back",
    crash_title: "Connection lost or the server is busy",
    sub_task_search: "Search subtasks",
    skip: "Skip",
    show: "Show",
    actions: "Actions",
    legal: "Legal",
    account_settings: "Account Settings",
    on_boarding_change_image_btn: "Change Image",
    related_files: "Files",
    card_1_time_1: "Target",
    card_2_time_1: "Today",
    clock_1_title: "Sales vs Target",
    clock_1_sub_title: "Required Rate",
    clock_1_info1: "Rank/District",
    clock_1_info2: "Rank/Chain",
    card_1_time_2: "Target",
    card_2_time_2: "Week To Date",
    card_1_time_3: "Weekly Target",
    card_2_time_3: "Week To Date",
    card_1_time_4: "Monthly Target",
    card_2_time_4: "Month To Date",
    time_1: "Today",
    time_2: "Yesterday",
    time_3: "Week To Date",
    time_4: "Month To Date",
    report_sub_title_1: "Sales",
    report_sub_title_2: "Traffic",
    report_sub_title_3: "Labor",
    report_sub_title_4: "Basket Size",
    report_sub_title_5: "Loyalty Program",
    report_sub_title_6: "Store Performance",
    clock_2_title: "Conversion Rate",
    clock_2_sub_title: "Target",
    clock_2_info1: "Traffic",
    clock_2_info2: "Transactions",
    card_title_1: "Labor Hours vs. Target",
    card_title_2: "Remainder",
    card_title_3: "ATV",
    card_title_4: "UPT",
    clock_3_title: "New Members",
    clock_3_sub_title: "Target",
    clock_4_title: "New Leads",
    clock_4_sub_title: "Target",
    card_3_title: "Store's Best Sellers",
    card_4_title: "Category Report",
    category_1: "category A",
    category_2: "category B",
    category_3: "category C",
    category_4: "category D",
    category_type_1: "Chain",
    category_type_2: "Store",
    time_1_period_start: "June 30, 2020",
    time_1_period_end: "vs June 30, 2019",
    time_2_period_start: "March 28, 2020",
    time_2_period_end: "vs March 28, 2019",
    time_3_period_start: "March 29, 2020",
    time_3_period_end: "vs March 24- March 31, 2019",
    time_4_period_start: "March 1 - March 31, 2020",
    time_4_period_end: "vs March 1- March 31, 2019",
    report_units: "Items",
    report_currency: "€",
    prod_1: "Product 1",
    prod_2: "Product 2",
    prod_3: "Product 3",
    prod_4: "Product 4",
    prod_5: "Product 5",
    prod_6: "Product 6",
    prod_7: "Product 7",
    prod_8: "Product 8",
    prod_9: "Product 9",
    prod_10: "Product 10",
    real_report: "Waiting for HQ approval.",
    force_update_title: "We've improved the system for you. Click to update! ",
    tasks_done: "Done",
    tasks_progress: "In progress",
    tasks_open: "To do",
    forgot_password_title: "Enter the phone number associated with your account.",
    forgot_password_subtitle: "We will send a link via SMS to this number",
    forgot_password_alert_succes_body: "Great! a reset password link was sent to your phone",
    forgot_password_alert_error_body: "Sorry, we can't idenify this number.",
    enter_password_alert_not_valid_body: "This password is note valide or week, the password should be at least 8 characters long. A strong password is combination of upper and lower case letters and numbers",
    enter_password_alert_not_matching_body: "You re-typed the password wrong",
    is_st_product_camera_enabled: "Enable camera in product cards",
    is_st_product_numbered: "Number the product Cards",
    header_button_back: "Go Back",
    header_button_next: "Next",
    header_button_publish: "Publish",
    publish_task_btn: "Publish",
    placeholder_task_name: "Task Name",
    date_picker_start: "Pick Starting Date",
    date_picker_end: "Pick Ending Date",
    placeholder_category_name: "Untitled Category",
    category_default_name: "Untitled Category",
    creat_task_sidebar_task_title: "Task Name",
    creat_task_dialog_box_placeholder: "Add Description...",
    add_category_button: "Add Category",
    add_dailog_box_button: "Add Description",
    add_inspiration_image_button: "Add Images",
    placeholder_st_text_title: "What you want to ask?",
    placeholder_st_product_title: "Fill the product's title",
    placeholder_st_product_id: "Fill the product's ID",
    placeholder_st_image_title: "Take A Picture Of...",
    subtask_text: "Ask a quastion",
    subtask_product: "Show a product",
    subtask_image: "Ask for an image",
    subtask_link: "Add a link",
    menu_add_button: "Add menus",
    menu_edit_button: "Change menus",
    creat_task_status_menu_modal_open: "Change menus",
    create_task_status_menu_modal_header: "Change menus",
    menu_select_button: "Select",
    deselect_all_stores_button: "Unselect All",
    select_all_stores_button: "Select All",
    search_stores: "Search Stores",
    select_counter_1: "Selected",
    select_counter_2: "of",
    placeholder_st_link_title: "Link description",
    more_options: "More options",
    placeholder_st_link_link: "Add Link",
    report_title_sales: "Sales",
    report_same_date_last_year: "MTD LY",
    report_compared_period_sales: "MTD LY",
    report_target: "Monthly Target",
    report_sales: "Month To Date",
    report_monthly_target: "Monthly Target",
    report_from_begging_of_month: "Month To Date",
    report_last_week: "Last Week",
    report_weekly_target: "Weekly Traget",
    report_required_target: "Required Target",
    report_sales_rate_vs_target: "Sales rate VS Target",
    report_store_area_rank: "Rank/District",
    report_store_retail_rank: "Rank/Chain",
    report_title_traffic: "Traffic",
    report_traffic_visitors: "Traffic",
    report_traffic_transactions: "Transactions",
    report_conversion_rate: "Conversion Rate",
    general_target: "Target",
    report_title_basket_size: "Basket Size",
    general_units: "Items",
    report_basket_size_sales: "ATV",
    report_basket_size_qty: "UPT",
    report_title_labor: "Labor",
    report_labor_used: "Labor Hours vs. Target",
    report_labor_remainder: "Remainder",
    report_labor_unit: "h.",
    report_title_loyalty_program: "Loyalty Program",
    report_loyalty_program_new_members: "New Members",
    report_loyalty_program_new_leads: "New Leads",
    report_title_department: "Departments",
    report_title_general: "Store Performance",
    report_general_best_sellers: "Store's Best Sellers",
    report_general_best_categories: "Category Report",
    report_title_stores_performance: "Stores Performance",
    report_series0: "Store",
    report_series1: "Chain",
    report_yesterday: "Yesterday Sales",
    report_relative_target: "Today's Sales Target",
    report_todays_target: "Today's Sales Target",
    report_title_brands: "Brands",
    delete_task_options_title: "Delete the task ",
    delete_task_options_subtitle: "Choose one of the options",
    delete_all_stores: "Delete from all the stores",
    delete_this_store: "Delete from this store",
    delete_category: "delete category",
    same_category_alert_title: "the category currently exist in the task please choose a different name",
    uploading_post_media_loading: "Uploading...",
    report_the_product_sold_units: "The product sold",
    report_the_product_sold_money: "Summing to",
    report_best_sellers_man: "Store's Best Sellers - Man",
    report_best_categories_man: "Category Report - Man",
    report_best_sellers_women: "Store's Best Sellers - Women",
    report_best_categories_women: "Category Report - Women",
    report_general_best_sellers_man: "Store's Best Sellers - Man",
    report_general_best_categories_man: "Category Report - Man",
    report_general_best_sellers_women: "Store's Best Sellers - Women",
    report_general_best_categories_women: "Category Report - Women",
    Manager_goal: "Manager's Goal",
    groups_title_by_income: "Groups by income",
    groups_title_by_qty: "Groups by quantity",
    show_all: "Show all ",
    view_linked_task: "Learn More",
    linked_tasks_title: "Linked with task",
    newsfeed_sidebar: "Newsfeed",
    reports_sidebar: "Reports",
    store_filter_sidebar: "Filter by store",
    see_less_sidebar: "See Less",
    see_more_sidebar: "See More",
    area_filter_sidebar: "Filter by area",
    like_counter_of: "of",
    like_counter_users: "users",
    title_header: "Title",
    priority_header: "Priority",
    timeline_header: "Timeline",
    status_header: "status",
    title_analytic_header: "Estado de Tarea",
    inspiration_image_header: "Inspiration Images",
    st_image_header: "Capture a photo sub-tasks",
    st_text_header: "Question sub-tasks",
    st_link_header: "External Links",
    st_product_header: "Product Cards sub-tasks",
    task_data_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    task_opt_subtitle: "Store arrangement, items placement and more description explaining about this kind of tasks. ",
    add: "Add",
    rotate: "Rotate",
    sub_task_hover_details: "Click for Details",
    uploaded_tag: "UPLOADED",
    photo: "Photo",
    video: "Video",
    post_header: "Create Post",
    view_all: "View all",
    high_priority: "High",
    temp_share_to: "Share to all Stores",
    fetch_more_comments_post_button: "More comments",
    hide_comments_post_button: "Hide comments",
    is_roll_toggale: "STORE-E Highlights",
    traning_task_name: "Collaborative Learning",
    reply_button: "Reply",
    replay_button: "Reply",
    newer_comment_button: "New Comments",
    older_comment_button: "More Comments",
    reply_to_title: "Replying to",
    view_more_reply: "More replies",
    is_st_required: "Required Sub Task",
    forget_password_alert_success_body: "Password was reset",
    create_task_assignee_header: "Assignee",
    choose_all_groups_btn: "choose all",
    submit_btn: "Ok, Hecho",
    footer_button_return: "Ok, Hecho",
    filter_btn: "Filtrar ",
    task_overview_graph_title: "Estado de Tarea:",
    task_overview_card_r_title: "N. de Tarea :",
    task_overview_card_c_title: "Grupo de Tareas:",
    task_overview_card_l_title: "Seleccionar Grupo ",
    task_overview_naxt_page_button: "Más",
    task_overview_see_task_button: "Ver Tarea ",
    filter_header: "Filtrar ",
    assigned_by_filter: "Asignado por ",
    assigned_by_me_filter: "yo ",
    assigned_by_all_filter: "Todos ",
    group_filter: "Grupos ",
    group_selecte_all_filter: "Seleccionar Todo ",
    group_unselecte_filter: "Cancelar ",
    group_back_filter: "Cancelar",
    group_filter_header: "Seleccionar Grupos ",
    done_filter_btn: "Ok, Hecho",
    apply_all_filters_btn: "Aplicar todos los Filtros ",
    level_filter: "Segmentación por nivel de",
    all_tasks_group_list: "Lista de Grupo ",
    stores_all_tasks_group_list: "Tienda",
    areas_all_tasks_group_list: "Área",
    task_overview_title: "Detalles de la tarea",
    filter_by_retail: "Retail",
    analytical_done: "Done",
    analytical_inprogress: "In progress",
    analytical_todo: "To do",
    task_overview_filter_title: "Filtro",
    filter_by_area: "área",
    filter_by_store: "Tienda",
    task_overview_category_status_title: "Estado de la actual Categoria",
    filter_status_done: "Done",
    filter_status_inprogress: "In progress",
    filter_status_todo: "To do",
    task_overview_status_title: "Estado",
    sort_by_status: "Ordenado por estado",
    task_overview_task_view_0: "Mejorar las ventas",
    task_overview_task_view_1: "Tareas Operacionales",
    task_overview_task_view_3: "VM",
    task_overview_task_view_2: "Aprendizaje colaborativo",
    back_button: "Go Back",
    task_overview_filter_status: "Estado",
    task_overview_filter_status_all: "Todos ",
    task_overview_select_all_btn: "Select All",
    task_overview_choose_group_btn: "Elige",
    task_status_done: "Done",
    task_status_inprogress: "In progress",
    task_status_todo: "To do",
    mobile_menu_header: "Menú",
    task_overview_expand_close_button: "Minimal View"
  },
  it: {
    settings: "impostazioni",
    logout: "disconesso",
    done: "finito ",
    yes: "si",
    no: "no ",
    undone: "non terminato ",
    weeklyTasksTitle: "completare il compito settimanale ",
    weekly_report: "rapporto settimanale ",
    noItems: "caricamento data ",
    optimized: "ottimizzare ",
    out_of_stock: "esaurito ",
    in_order: "ordinato ",
    comment: "commento ",
    cancel: "annulla ",
    new: "nuovo ",
    best_seller: "più venduti ",
    unitsChange: "cambio di unità ",
    status: "status ",
    tasks: "compito ",
    total_items: "totale articoli ",
    improved_location: "miglioramento del display ",
    sales_status: "stato di vendita ",
    increase_in_units: "aumenta le unità ",
    already_optimized: "già ottimizzato ",
    new_location: "nuova localizzazione ",
    card_confirm_text: "sei sicuro? ",
    return1: "torna indietro 1 ",
    return2: "torna indietro 2 ",
    return3: "torna indietro 3 ",
    return4: "torna indietro 4 ",
    in_stock: "disponibile ",
    manager_guidelines: "linee guide del direttore ",
    menu_title: "menù ",
    category_images_title: "immagini ",
    alert_success: "capito, grazie!",
    alert_error: "errore",
    choose: "scegli ",
    description: "descrizione ",
    tags: "tag ",
    send: "invia ",
    save: "salva ",
    write_a_comment: "scrivi un commento ",
    files: "file ",
    comments: "commenti ",
    reply: "rispondi ",
    info: "informazioni ",
    attached_files: "file allegato ",
    cover_name: "immagine scaricata ",
    please_take_an_item_photo: "per favore, fotografare l'articolo",
    hello: "cio",
    menu_in_stock_smaller_then_5: "disponibile ",
    menu_done: "finito ",
    menu_undone: "non finito ",
    menu_yes: "si",
    menu_no: "no",
    menu_irrelevant: "irrilevante ",
    menu_out_of_stock: "esaurito ",
    menu_in_stock: "disponibile ",
    menu_not_carried_out: "non eseguito ",
    menu_there_are_shortcomings: "ci sono difetti ",
    menu_there_are_no_shortcomings: "non ci sono difetti ",
    menu_read: "letto ",
    menu_different_location: "localizzazione differente",
    menu_missing_dimensions: "mancano le dimensioni ",
    menu_missing_colors: "mancano i colori ",
    menu_I_didnt_change_anything: "non ho cambiato niente ",
    menu_located_right: "ottimizza la localizzazione ",
    menu_location_changed: "cambia localizzazione ",
    menu_switched: "cambiato ",
    menu_in_order: "in ordine ",
    menu_missing_size: "mancano le taglie ",
    menu_optimized_location: "ottimizzare la localizzazione ",
    menu_driver: "no, c'era un driver ",
    menu_driver_no_no: "no, non c'era un driver ",
    menu_fixed: "fisso ",
    menu_no_fixed: "non fisso ",
    no_status_taskoverview: "no status ",
    no_status: "scegli status ",
    choose_status: "scegli status ",
    menu: "menù ",
    menu_improved_location: "migliora la localizzazione ",
    False: "falso ",
    True: "vero ",
    menu_size_or_color_missing: "taglie/ colori mancano ",
    err_page_title: "caricamento lento ",
    err_page_homepgae_btn: "vai alla homepage ",
    search_branch: "cerca una filiale ",
    login_username: "nome utente ",
    login_password: "password ",
    forgot_password: "password dimenticata ",
    login_header: "accedi al tuo account ",
    login: "accedi ",
    change_role: "ciambia ruolo ",
    you_are: "tu sei ",
    store_manager: "direttore del negozio ",
    district_manager: "direttore del distretto ",
    chain_store_manager: "direttore della catena ",
    choose_store: "scegli il negozio ",
    choose_store_search: "che negozio stai cercando? ",
    data_analytics_title: "data analisi ",
    data_tasks: "migliora le vndite ",
    ops_tasks: "missioni ",
    history_tasks: "storia ",
    training_tasks: "apprendimento collaborativo ",
    vm_tasks: "VM ",
    tasks_search: "ricerca del compito ",
    view_subtasks: "mostra attività secondarie ",
    delete: "cancella ",
    view_more_comments: "vedi più commenti ",
    unathorized: "inautorizzato ",
    permission: "ci scusiamo, non hai l'accesso",
    wrong: "ci scusamo, si è verificato un errore ",
    no_internet: "non connesso ad internt ",
    language: "lingua ",
    tasks_dashboard: "pannello di controllo delle attivita ",
    language_name: "Italiano ",
    monthly_rate_header: "tariffa giornaliera richiesta ",
    monthly_rate_current: "attuale ",
    monthly_rate_gap: "divario ",
    card_data_1: "target mesile ",
    card_data_2: "vendite MTD ",
    card_data_3: "le vendite di ieri ",
    card_data_4: "target giornaliero ",
    monthly_sales_goal_rate: "performance dell'obbiettivo di vendite mensili ",
    main_clock_sub_title: "MTD vs target ",
    main_clock_title: "target ",
    clocks_header: "obbiettivi ",
    data_tasks_header: "data task ",
    data_tasks_sub_header: "ttività generata da AI in base ai dati ",
    opt_tasks_header: "task operativo",
    opt_tasks_sub_header: "task creato manualmente ",
    default_filter_header: "mostra tutte le aree ",
    area_filters: "mostra tutte le aree ",
    questions: "domande ",
    stores: "storia ",
    current_status: "status attuale ",
    no_tasks_to_present: "nessun task da presentare ",
    attach_file_btn: "aggiungi file ",
    prod_id: "ID ",
    image_sub_header: "manda foto!",
    subtask_view: "vedi",
    legal_privacy: "privacy policy ",
    legal_terms: "termini di uso ",
    no_reports: "nessun report ",
    goal: "obbiettivo ",
    enable_notification: "abbilità le notifiche ",
    on_boarding_header: "benvenuto ",
    on_boarding_sub_header: "per iniziare aggiungi una foto",
    on_boarding_open_camera_btn: "fai un selfie ",
    on_boarding_upload_image_btn: "scaricala dalla galleria ",
    on_boarding_image_added: "magnifico! ",
    nothing_found_massage: "niente da vedere. passa alla prossima categoria ",
    no_camera_premision_title: "nessun consenso alla telecamera ",
    no_camera_premision_body: "consenti l'autorizzazione della telecamera dall'impostanzioni ",
    no_camera_premision_cancel: "cancella ",
    no_camera_premision_confirm: "vai alle impostazioni ",
    read_less: "leggi di meno ",
    read_more: "leggi di più",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟 ",
    morning_greeting: "buon giorno ",
    noon_greeting: "buon pomeriggio ",
    evening_greeting: "buona sera ",
    night_greeting: "buona notte ",
    user_liked: "hai capito ",
    user_not_liked: "ho capito ",
    newsfeed_like_btn: "hai capito ",
    newsfeed_like_btn_1: "ho capito ",
    newsfeed_comment_btn: "commento ",
    view: "vedi",
    caming_soon: "arriva presto ",
    action_button_1: "panoramica delle attività ",
    action_button_2: "apprendimento collaborativo ",
    action_button_3: "gestione dell'inventario ",
    action_button_4: "risorse umane ",
    action_button_5: "gestimento del contante ",
    action_button_6: "programma dei dipendenti ",
    action_button_7: "chiave principale ",
    action_copy_to_clickbourd_alert: "il link è valido solo per 7 giorni ",
    action_copy_to_clickbourd_alert_title: "il link è stato copiato ",
    control_panels_title: "pannello di controllo ",
    pinged: "il ping è stato inviato ",
    ping_all: "ping tutti ",
    ping: "ping ",
    ping_users_got_it: "persone che lhanno ricevuto ",
    create_post_placeholder: "che pensi? ",
    post_entry_placeholder: "vuoi mandare un messaggio al tuo team? ",
    link_task: "link task ",
    user_blessing: "buongiorno ",
    delete_post_title: "cancella il post ",
    delete_post_approval: "sei sicuro di voler eliminare il post? ",
    edit: "modifica ",
    post: "post",
    crash_go_back: "vai indietro ",
    crash_title: "connessione persa, il server è occupato ",
    sub_task_search: "cerca un subtask ",
    skip: "salta ",
    show: "mostra",
    actions: "azioni ",
    legal: "legale ",
    account_settings: "impostazioni account ",
    on_boarding_change_image_btn: "cambia immagine ",
    related_files: "files ",
    card_1_time_1: "target ",
    card_2_time_1: "oggi ",
    clock_1_title: "vendite vs target ",
    clock_1_sub_title: "tasso richiesto ",
    clock_1_info1: "rango / distretto ",
    clock_1_info2: "rango/ catena ",
    card_1_time_2: "target",
    card_2_time_2: "settimana fino ad oggi ",
    card_1_time_3: "target settimanale",
    card_2_time_3: "settimana fino ad oggi ",
    card_1_time_4: "target mensile ",
    card_2_time_4: "mese ad oggi",
    time_1: "oggi ",
    time_2: "ieri",
    time_3: "settimana fino ad oggi ",
    time_4: "mese ad oggi",
    report_sub_title_1: "vendite",
    report_sub_title_2: "traffico",
    report_sub_title_3: "manodopera ",
    report_sub_title_4: "carrello ",
    report_sub_title_5: "programma fedeltà ",
    report_sub_title_6: "performance del negozio ",
    clock_2_title: "tasso di conversione ",
    clock_2_sub_title: "target ",
    clock_2_info1: "traffico",
    clock_2_info2: "transizioni ",
    card_title_1: "manodopera vs target ",
    card_title_2: "promemoria ",
    card_title_3: "ATV",
    card_title_4: "UPT",
    clock_3_title: "nuovi membri ",
    clock_3_sub_title: "target ",
    clock_4_title: "nuovi lead ",
    clock_4_sub_title: "target ",
    card_3_title: "i più venduti al negozio ",
    card_4_title: "report categoria ",
    category_1: "categoria A",
    category_2: "categoria B",
    category_3: "categoria C",
    category_4: "categoria D",
    category_type_1: "catena ",
    category_type_2: "negozio",
    time_1_period_start: "giugno 30,2020",
    time_1_period_end: "vs giugno 28,2020 ",
    time_2_period_start: "vs marzo 28, 2020",
    time_2_period_end: "vs marzo 28, 2020",
    time_3_period_start: "marzo 29, 2020 ",
    time_3_period_end: "vs marzo 24- marzo 31, 2019",
    time_4_period_start: "marzo 1- marzo 31, 2020",
    time_4_period_end: "vs marzo 1- marzo 31 ,2019 ",
    report_units: "articoli ",
    prod_1: "prodotto 1",
    prod_2: "prodottto 2 ",
    prod_3: "prodotto 3 ",
    prod_4: "prodotto 4 ",
    prod_5: "prodotto 5 ",
    prod_6: "prodotto 6",
    prod_7: "prodotto 7",
    prod_8: "prodotto 8 ",
    prod_9: "prodotto 9",
    prod_10: "prodotto 10",
    real_report: "aspettando l'approvazione del HQ",
    force_update_title: "abbiamo migliorato il sistem per te. clicca per aggiornare",
    tasks_done: "finito ",
    tasks_progress: "in processo ",
    tasks_open: "da fare",
    forgot_password_title: "accedere il numero di telefono associato all'account ",
    forgot_password_subtitle: "manderemo un SMS con il link a questo numero ",
    forgot_password_alert_succes_body: "ottimo! una nuova password è stata mandata tramite SMS ",
    forgot_password_alert_error_body: "spiacenti, numero non identificato ",
    enter_password_alert_not_valid_body: "la password non è valida o sufficiente, la password dovrebbe essere almeno 8 caratteri. una password complessa è una combinazione di lettere maiuscole e minuscole e numeri. ",
    enter_password_alert_not_matching_body: "hai scritto la password sbagliata",
    is_st_product_camera_enabled: "abilita la telecamera nelle schede prodotto ",
    is_st_product_numbered: "numera le schede prodotto ",
    header_button_back: "torna indietro ",
    header_button_next: "prossimo ",
    header_button_publish: "pubblica ",
    publish_task_btn: "pubblica ",
    placeholder_task_name: "nome del task ",
    date_picker_start: "scegli la data di inizio ",
    date_picker_end: "scegli la data di fine ",
    placeholder_category_name: "categoria senza nome ",
    category_default_name: "categoria senza nome ",
    creat_task_sidebar_task_title: "nome del task ",
    creat_task_dialog_box_placeholder: "aggiungi descrizione...",
    add_category_button: "aggiungi una categoria",
    add_dailog_box_button: "aggiungi descrizione ",
    add_inspiration_image_button: "aggiungi immagine ",
    placeholder_st_text_title: "cosa vuoi chiedere? ",
    placeholder_st_product_title: "completa il prodotto del titolo ",
    placeholder_st_product_id: "completa il prodotto ID ",
    placeholder_st_image_title: "Scatta una foto di..",
    subtask_text: "chiedi una domanda ",
    subtask_product: "mostra un prodotto ",
    subtask_image: "chiedi un immagine ",
    subtask_link: "aggiungi un link",
    menu_add_button: "aggiungi menù ",
    menu_edit_button: "cambia menù ",
    creat_task_status_menu_modal_open: "cambia menù ",
    create_task_status_menu_modal_header: "cambia menù ",
    menu_select_button: "seleziona",
    deselect_all_stores_button: "deseleziona tutto ",
    select_all_stores_button: "seleziona tutto ",
    search_stores: "cerca negozi ",
    select_counter_1: "seleziona tutto ",
    select_counter_2: "di",
    placeholder_st_link_title: "descrizione dell'link ",
    more_options: "più opzioni ",
    placeholder_st_link_link: "aggiungi link",
    report_title_sales: "vendite ",
    report_same_date_last_year: "MTD LY ",
    report_compared_period_sales: "MTD LY ",
    report_target: "target mensile ",
    report_sales: "mese fino ad oggi",
    report_monthly_target: "target mensile ",
    report_from_begging_of_month: "mese fino ad oggi",
    report_last_week: "scorsa settimana ",
    report_weekly_target: "target settimanale ",
    report_required_target: "target richiesto",
    report_sales_rate_vs_target: "tasso di vendita vs target ",
    report_store_area_rank: "rango / distretto",
    report_store_retail_rank: "rango/ catena ",
    report_title_traffic: "traffico ",
    report_traffic_visitors: "traffico ",
    report_traffic_transactions: "transizioni ",
    report_conversion_rate: "tasso di converzione ",
    general_target: "target",
    report_title_basket_size: "carrello ",
    general_units: "articoli ",
    report_basket_size_sales: "ATV",
    report_basket_size_qty: "UPT",
    report_title_labor: "manodopera ",
    report_labor_used: "manodopera vs target ",
    report_labor_remainder: "promemoria ",
    report_labor_unit: "ore",
    report_title_loyalty_program: "programma fedeltà ",
    report_loyalty_program_new_members: "nuovi membri ",
    report_loyalty_program_new_leads: "nuovi leads ",
    report_title_department: "dipartimenti ",
    report_title_general: "performance del negozio ",
    report_general_best_sellers: "i più venduti al negozio ",
    report_general_best_categories: "report della categoria ",
    report_title_stores_performance: "performance del negozio ",
    report_series0: "negozio ",
    report_series1: "catena ",
    report_yesterday: "vendite di ieri",
    report_relative_target: "target delle vendite di oggi",
    report_todays_target: "target delle vendite di oggi ",
    report_title_brands: "brands ",
    delete_task_options_title: "cancella il task ",
    delete_task_options_subtitle: "scegli una delle opzioni ",
    delete_all_stores: "cancella da tutti i negozi ",
    delete_this_store: "cancella da questo negozio ",
    delete_category: "cancella categoria ",
    same_category_alert_title: "la categoria esiste attualmentenel task, per favore scegli un nome differente ",
    uploading_post_media_loading: "caricando.. ",
    report_the_product_sold_units: "il prodotto è stato venduto",
    report_the_product_sold_money: "sommando a ",
    report_best_sellers_man: "i più venduti al negozio - uomo",
    report_best_categories_man: "report della categoria- uomo ",
    report_best_sellers_women: "i più venduti al negozio- donna ",
    report_best_categories_women: "report della categoria- donna ",
    report_general_best_sellers_man: "i più venduti al negozio - uomo",
    report_general_best_categories_man: "report della categoria- uomo ",
    report_general_best_sellers_women: "i più venduti al negozio- donna ",
    report_general_best_categories_women: "report della categoria- donna ",
    Manager_goal: "obiettivo del direttore ",
    groups_title_by_income: "gruppi per reddito ",
    groups_title_by_qty: "gruppi per quanità ",
    show_all: "mostra tutto ",
    view_linked_task: "per saperne di più ",
    linked_tasks_title: "link con il task ",
    newsfeed_sidebar: "neewsfeed ",
    reports_sidebar: "reporti ",
    store_filter_sidebar: "filtra per negozio ",
    see_less_sidebar: "vedi meno ",
    see_more_sidebar: "vedi di più ",
    area_filter_sidebar: "filtra per area ",
    like_counter_of: "di ",
    like_counter_users: "utente ",
    title_header: "titolo",
    priority_header: "priorità ",
    timeline_header: "sequenza temporale ",
    status_header: "stato ",
    title_analytic_header: "status della missione",
    inspiration_image_header: "imagini da inspirazione ",
    st_image_header: "cattura una foto subtask ",
    st_text_header: "doanda sub task ",
    st_link_header: "link esterni ",
    st_product_header: "schede prodotto sub task ",
    task_data_subtitle: "disposizone del negozio, posizione degli articoli e altre descrizioni che spiegano questo tipo di compito ",
    add: "aggiungi ",
    rotate: "ruota",
    sub_task_hover_details: "clicca per dettagli ",
    uploaded_tag: "caricata ",
    photo: "foto ",
    video: "video ",
    post_header: "crea post ",
    view_all: "guarda tutto ",
    high_priority: "alto ",
    temp_share_to: "condividi con tutti gli store ",
    fetch_more_comments_post_button: "più commenti ",
    hide_comments_post_button: "nascondi commenti",
    is_roll_toggale: "STORE-E evidenziato ",
    traning_task_name: "apprendimento collaborativo ",
    reply_button: "rispondi ",
    replay_button: "rispondi ",
    newer_comment_button: "New Comments",
    older_comment_button: "More Comments",
    reply_to_title: "Replying to",
    view_more_reply: "More replies",
    is_st_required: "Required Sub Task",
    create_task_name_placeholder: "nome della missione ",
    create_task_view_placeholder: "tipo di missione",
    create_task_view_op_0: "migliora le vendite",
    create_task_view_op_1: "missioni",
    create_task_view_op_3: "storia",
    create_task_view_op_2: "apprendimento collaborativo",
    create_task_assignee_placeholder: "scegli l'assegnatario ",
    create_task_assignee_selected: "scegli l'assegnatario ",
    create_task_start_date_placeholder: "scegli la data d'inizio",
    create_task_end_date_placeholder: "scegli la data di fine ",
    create_task_st_title_placeholder: "nome attività secondaria ",
    create_task_st_pid_placeholder: "Prodotto ID",
    create_task_status_menu_select_btn: "Scegli una possibile risposta",
    create_task_add_img_btn: "aggiungi un immagine ",
    create_task_st_create_btn: "crea",
    create_task_st_cancel_btn: "cancella",
    create_task_st_save_btn: "salva",
    create_task_add_cat_btn: "aggiungi una categoria",
    create_task_name_title: "nome della missione ",
    create_task_view_title: "tipo di missione",
    create_task_assignee_title: "a",
    create_task_dialog_box_title: "aggiungi una descrizione per ",
    create_task_dialog_box_placeholder: "descrizione... ",
    create_task_st_image_title: "scatta una foto ",
    create_task_st_text_title: "domanda ",
    create_task_st_link_title: "link esterni",
    create_task_st_product_title: "schede prodotto ",
    create_task_st_image_btn: "aggiungi una foto ",
    create_task_st_text_btn: "aggiungi una domanda ",
    create_task_st_link_btn: "aggiungi un llink esterno",
    create_task_sidebar_task_title: "nome della missione ",
    forget_password_alert_success_body: "Password was reset",
    create_task_assignee_header: "Assignee",
    choose_all_groups_btn: "choose all",
    submit_btn: "ok, finito",
    footer_button_return: "ok, finito",
    filter_btn: "filtro",
    task_overview_graph_title: "status della missione:",
    task_overview_card_r_title: "num. della missione:",
    task_overview_card_c_title: "set di missioni:",
    task_overview_card_l_title: "gruppi selezionati",
    task_overview_naxt_page_button: "più",
    task_overview_see_task_button: "vedi la missione",
    filter_header: "filtro",
    assigned_by_filter: "assegnato da",
    assigned_by_me_filter: "io",
    assigned_by_all_filter: "tutti",
    group_filter: "gruppi ",
    group_selecte_all_filter: "seleziona tutto",
    group_unselecte_filter: "deseleziona tutto ",
    group_back_filter: "cancella",
    group_filter_header: "seleziona gruppi ",
    done_filter_btn: "ok, finito",
    apply_all_filters_btn: "applica tutti i filtri",
    level_filter: "Segmentazione per livello di",
    all_tasks_group_list: "lista del gruppo ",
    stores_all_tasks_group_list: "negozio",
    areas_all_tasks_group_list: "area",
    analytical_done: "finito ",
    analytical_inprogress: "in processo ",
    analytical_todo: "da fare",
    filter_status_done: "finito ",
    filter_status_inprogress: "in processo ",
    filter_status_todo: "da fare",
    task_overview_status_title: "status",
    task_overview_task_view_0: "migliora le vendite",
    task_overview_task_view_1: "missioni",
    task_overview_task_view_3: "storia",
    task_overview_task_view_2: "apprendimento collaborativo",
    back_button: "vai indietro ",
    task_overview_filter_status: "status",
    task_overview_filter_status_all: "tutti",
    task_overview_select_all_btn: "seleziona tutto ",
    task_overview_choose_group_btn: "scegli ",
    task_status_done: "finito ",
    task_status_inprogress: "in processo ",
    task_status_todo: "da fare",
    mobile_menu_header: "Menù ",
    task_overview_expand_close_button: "Minimal View"
  },
  pt: {
    settings: "Definições",
    logout: "Sair",
    done: "Feito",
    yes: "Sim ",
    no: "Não ",
    undone: "Não realizado",
    weeklyTasksTitle: "Vamos completar as tarefas semanais para melhorar o desempenho",
    weekly_report: "Relatório Semanal",
    noItems: "Carregando Dados ",
    optimized: "Otimizado",
    out_of_stock: "Esgotado",
    in_order: "Encomendado",
    comment: "Comentário",
    cancel: "Cancelar",
    new: "Novo",
    best_seller: "Mais vendido",
    unitsChange: "Troca de unidades",
    status: "Status",
    tasks: "Tarefas",
    total_items: "Total de Itens ",
    improved_location: "Apresentação Otimizada",
    sales_status: "Status de Vendas (unidades) ",
    increase_in_units: "Aumento de Unidades",
    already_optimized: "Já Otimizado",
    new_location: "Nova Localização",
    card_confirm_text: "Tem a certeza?",
    return1: "Return 1",
    return2: "Return 2",
    return3: "Return 3",
    return4: "Return 4",
    in_stock: "Disponível",
    manager_guidelines: "Diretrizes do Gerente ",
    menu_title: "Menu",
    category_images_title: "Imagens ",
    alert_success: "Entendi, Obrigado!",
    alert_error: "Erro !",
    choose: "Escolha",
    description: "Descrição",
    tags: "Etiquetas",
    send: "Enviar",
    save: "Salvar",
    write_a_comment: "Escreva um comentário...",
    files: "Arquivos",
    comments: "Comentários",
    reply: "Responder",
    info: "Informação",
    attached_files: "Arquivos anexados ",
    cover_name: "Carregar imagem",
    please_take_an_item_photo: "Por favor, tire uma foto do artigo ",
    hello: "Olá!",
    menu_in_stock_smaller_then_5: "Disponível <5",
    menu_done: "Feito ",
    menu_undone: "Por Realizar",
    menu_yes: "Sim",
    menu_no: "Não",
    menu_irrelevant: "Irrelevante",
    menu_out_of_stock: "Esgotado ",
    menu_in_stock: "Disponível",
    menu_not_carried_out: "Não realizado",
    menu_there_are_shortcomings: "Existem Lacunas",
    menu_there_are_no_shortcomings: "Não existem lacunas",
    menu_read: "Ler",
    menu_different_location: "Localização diferente",
    menu_missing_dimensions: "Dimensões em falta",
    menu_missing_colors: "Cores em falta",
    menu_I_didnt_change_anything: "Não mudei nada",
    menu_located_right: "Localização otimizada",
    menu_location_changed: "Localização alterada",
    menu_switched: "Substituído",
    menu_in_order: "Em ordem",
    menu_missing_size: "Tamanho em falta",
    menu_optimized_location: "Localização otimizada",
    menu_driver: "Não, havia motorista",
    menu_driver_no_no: "Não, não havia motorista",
    menu_fixed: "Resolvido",
    menu_no_fixed: "Não resolvido",
    no_status_taskoverview: "Status Indefinido",
    no_status: "Escolha o status",
    choose_status: "Escolha o status",
    menu: "Menu",
    menu_improved_location: "Localização Melhorada",
    False: "Falso",
    True: "Verdadeiro",
    menu_size_or_color_missing: "Tamanhos / Cores em Falta",
    err_page_title: "Carregamento Lento",
    err_page_homepgae_btn: "Ir para a Página Inicial",
    login_username: "Utilizador",
    login_password: "Senha",
    forgot_password: "Esqueceu sua Senha",
    login_header: "Aceda à sua conta",
    login: "Entrar",
    change_role: "Trocar de Funções",
    you_are: "Você é",
    store_manager: "Gerente de Loja",
    district_manager: "Gerente Distrital",
    chain_store_manager: "Gerente da Cadeia de Lojas",
    choose_store: "Escolha a Loja",
    choose_store_search: "De que Loja está à Procura?",
    data_analytics_title: "Análise de Dados",
    data_tasks: "Melhorar as Vendas",
    ops_tasks: "Tarefas Operacionais",
    history_tasks: "VM",
    training_tasks: "Biblioteca",
    vm_tasks: "VM",
    tasks_search: "Pesquisa de Tarefas",
    view_subtasks: "Visualizar as Sub-Tarefas",
    delete: "Eliminar",
    view_more_comments: "Ver mais comentários",
    unathorized: "Não autorizado",
    permission: "Desculpe, não tem permissão ",
    wrong: "Desculpe, algo deu errado.",
    no_internet: "Não há ligação à Internet",
    language: "Idioma",
    tasks_dashboard: "Painel de Controlo de Tarefas",
    language_name: "Portugues ",
    monthly_rate_header: "Preço Diário Exigido",
    monthly_rate_current: "Atual",
    monthly_rate_gap: "Diferença",
    card_data_1: "Objetivo Mensal",
    card_data_2: "Vendas Mensais (MTD)",
    card_data_3: "As vendas de ontem",
    card_data_4: "Objetivo Diário",
    monthly_sales_goal_rate: "Desempenho do objetivo mensal de vendas",
    main_clock_sub_title: "Vendas mensais vs. Objectivo (%)",
    main_clock_title: "Objetivo",
    clocks_header: "Metas",
    data_tasks_header: "Dados de tarefa",
    data_tasks_sub_header: "Tarefas geradas pela IA com base em dados",
    opt_tasks_header: "Tarefas Operacionais",
    opt_tasks_sub_header: "Tarefas criadas manualmente",
    default_filter_header: "Mostrar todas as regiões",
    area_filters: "Mostrar todas as regiões",
    questions: "Perguntas",
    stores: "Lojas",
    current_status: "Status Atual",
    no_tasks_to_present: "Não há tarefas para apresentar neste momento",
    attach_file_btn: "Adicionar Arquivo",
    prod_id: "ID",
    image_sub_header: "Tirar uma foto",
    subtask_view: "Visualizar",
    legal_privacy: "Política de Privacidade",
    legal_terms: "Termos de Utilização",
    no_reports: "Nenhum relatório",
    goal: "Meta",
    enable_notification: "Ativar Notificações",
    on_boarding_header: "Bem-vindo",
    on_boarding_sub_header: "Para começar, adicione a sua fotografia",
    on_boarding_open_camera_btn: "Tire uma Selfie",
    on_boarding_upload_image_btn: "Carregar foto",
    on_boarding_image_added: "Parece bom :)",
    nothing_found_massage: "Nada a apresentar. Veja a próxima categoria. ",
    no_camera_premision_title: "Sem autorização para aceder à câmara",
    no_camera_premision_body: "Por favor ative o acesso à câmara nas Definições",
    no_camera_premision_cancel: "Cancelar",
    no_camera_premision_confirm: "Aceda a Definições",
    read_less: "Ler menos",
    read_more: "Ler mais",
    morning_icon: "☀",
    noon_icon: "😎",
    evening_icon: "🌜",
    night_icon: "🌟 ",
    morning_greeting: "Bem-vindo",
    noon_greeting: "Bem-vindo",
    evening_greeting: "Bem-vindo",
    night_greeting: "Bem-vindo",
    user_liked: "Você Entendeu",
    user_not_liked: "Entendi",
    newsfeed_like_btn: "Você Entendeu",
    newsfeed_like_btn_1: "Entendi",
    newsfeed_comment_btn: "Comentário",
    view: "Ver",
    caming_soon: "Em Breve",
    action_button_1: "Visão Geral de Tarefas",
    action_button_2: "Biblioteca",
    action_button_3: "Balanços de Inventário",
    action_button_4: "Recursos Humanos ",
    action_button_5: "Gestão de Tesouraria",
    action_button_6: "Escalonamento de Funcionários",
    action_button_7: " Chave Mestra",
    action_copy_to_clickbourd_alert: "O link é válido por sete dias",
    action_copy_to_clickbourd_alert_title: "Link copiado para a área de transferência",
    control_panels_title: "Painel de Controlo",
    pinged: "Pinged",
    ping_all: "Ping Todos",
    ping: "Ping",
    ping_users_got_it: "Pessoas Que Entenderam ",
    create_post_placeholder: "Em que está a pensar?",
    post_entry_placeholder: "Quer mandar uma mensagem à equipa?",
    link_task: "Linkar Tarefa",
    user_blessing: "Bom Dia, ",
    delete_post_title: "Apagar Publicação",
    delete_post_approval: "Tem a certeza que deseja apagar esta publicação?",
    edit: "Editar",
    post: "Próximo",
    crash_go_back: "Voltar",
    crash_title: "Ligação perdida ou o servidor está ocupado",
    sub_task_search: "Pesquisar Sub-Tarefas",
    skip: "Passar",
    show: "Mostrar",
    actions: "Ações",
    legal: "Legal",
    account_settings: "Definições de conta",
    on_boarding_change_image_btn: "Alterar Imagem",
    related_files: "Arquivos",
    card_1_time_1: "Objetivo",
    card_2_time_1: "Hoje ",
    clock_1_title: "Vendas vs Objectivo",
    clock_1_sub_title: "Taxa Esperada",
    clock_1_info1: "Posição/Distrito",
    clock_1_info2: "Posição/Cadeia",
    card_1_time_2: "Objetivo",
    card_2_time_2: "Do início da semana até à data ",
    card_1_time_3: "Objetivo Semanal",
    card_2_time_3: "Do início da semana até à data ",
    card_1_time_4: "Objetivo mensal",
    card_2_time_4: "Do início do mês até à data ",
    time_1: "Hoje ",
    time_2: "Ontem",
    time_3: "Do início da semana até à data ",
    time_4: "Do início do mês até à data ",
    report_sub_title_1: "Vendas",
    report_sub_title_2: "Tráfego",
    report_sub_title_3: "Trabalho",
    report_sub_title_4: "Tamanho do cesto",
    report_sub_title_5: "Programa de fidelização",
    report_sub_title_6: "Desempenho da Loja",
    clock_2_title: "Taxa de Conversão",
    clock_2_sub_title: "Objetivo",
    clock_2_info1: "Tráfego",
    clock_2_info2: "Transações",
    card_title_1: "Horas de trabalho vs. Objetivo",
    card_title_2: "Remanescente",
    card_title_3: "ATV",
    card_title_4: "UPT",
    clock_3_title: "Novos Membros",
    clock_3_sub_title: "Objetivo",
    clock_4_title: "Novas Oportunidades",
    clock_4_sub_title: "Objetivo",
    card_3_title: "Os mais vendidos da loja",
    card_4_title: "Relatório de Categorias",
    category_1: "Categoria A",
    category_2: "Categoria B",
    category_3: "Categoria C",
    category_4: "Categoria D",
    category_type_1: "Cadeia ",
    category_type_2: "Loja",
    time_1_period_start: " 30 de junho de 2020",
    time_1_period_end: "vs 30 de junho de 2019",
    time_2_period_start: "28 de março de 2020",
    time_2_period_end: "vs 27 de março de 2019",
    time_3_period_start: "29 de março de 2020",
    time_3_period_end: "vs 24 de março- 31 de março 2019",
    time_4_period_start: "1 de março - 31 de março 2019",
    time_4_period_end: "vs 1 de março - 31 de março 2019",
    report_units: "Artigos",
    report_currency: "€",
    prod_1: "Produto 1",
    prod_2: "Produto 2",
    prod_3: "Produto 3",
    prod_4: "Produto 4",
    prod_5: "Produto 5",
    prod_6: "Produto 6",
    prod_7: "Produto 7",
    prod_8: "Produto 8",
    prod_9: "Produto 9",
    prod_10: "Produto 10",
    real_report: "À espera da aprovação dos Serviços Centrais",
    force_update_title: "Aperfeiçoamos o sistema para si. Clique para atualizar! ",
    tasks_done: "Feito",
    tasks_progress: "Em curso",
    tasks_open: "Por fazer",
    forgot_password_title: "Insira o número de telefone associado à sua conta.",
    forgot_password_subtitle: "Enviaremos um link via SMS para este número ",
    forgot_password_alert_succes_body: "Ótimo! Um link de redefinição de senha foi enviado para o seu telefone ",
    forgot_password_alert_error_body: "Desculpe, não podemos identificar este número.",
    enter_password_alert_not_valid_body: "Esta senha é inválida ou fraca, a senha deve ter pelo menos 8 caracteres de comprimento. Uma senha forte é a combinação de maiúsculas e minúsculas...",
    enter_password_alert_not_matching_body: "Você redigitou a senha errada",
    is_st_product_camera_enabled: "Permitir o acesso a cámara para os cartões de produto",
    is_st_product_numbered: "Numerar os cartões de produto",
    header_button_back: "Voltar",
    header_button_next: "Seguinte",
    header_button_publish: "Publicar",
    publish_task_btn: "Publicar",
    placeholder_task_name: "Nome da tarefa",
    date_picker_start: "Escolha data de inicio",
    date_picker_end: "Escolha a data final",
    placeholder_category_name: "Categoria Sem Título",
    category_default_name: "Categoria Sem Título",
    creat_task_sidebar_task_title: "Nome da tarefa",
    creat_task_dialog_box_placeholder: "Adicione uma Descrição...",
    add_category_button: "Adicione Categoria",
    add_dailog_box_button: "Adicione uma Descrição",
    add_inspiration_image_button: "Adicionar Imagens",
    placeholder_st_text_title: "O que deseja perguntar?",
    placeholder_st_product_title: "Preencha o título do produto",
    placeholder_st_product_id: "Preencha o ID do produto",
    placeholder_st_image_title: "Tire uma foto de ...",
    subtask_text: "Faça uma pergunta",
    subtask_product: "Mostre um produto",
    subtask_image: "Peça uma imagem",
    subtask_link: "Adicionar um link",
    menu_add_button: "Adicione menus",
    menu_edit_button: "Alterar o menu",
    creat_task_status_menu_modal_open: "Alterar o menu",
    create_task_status_menu_modal_header: "Alterar o menu",
    menu_select_button: "Selecionar",
    deselect_all_stores_button: "Deselecionar Tudo",
    select_all_stores_button: "Selecionar Tudo",
    search_stores: "Pesquisar lojas",
    select_counter_1: "Selecionar",
    select_counter_2: "de",
    placeholder_st_link_title: "Descrição do link ",
    more_options: "Mais opções",
    placeholder_st_link_link: "Adicionar link ",
    report_title_sales: "Vendas",
    report_same_date_last_year: "MTD LY ",
    report_compared_period_sales: "MTD LY ",
    report_target: "Objectivo Mensal",
    report_sales: "Do início do mês até à data",
    report_monthly_target: "Objectivo Mensal",
    report_from_begging_of_month: "Do início do mês até à data",
    report_last_week: "Semana Anterior",
    report_weekly_target: "Objectivo Semanal ",
    report_required_target: "Objectivo Exigido",
    report_sales_rate_vs_target: " Taxa de vendas VS Objectivo",
    report_store_area_rank: "Posição / Distrito",
    report_store_retail_rank: "Posição / Cadeia ",
    report_title_traffic: "Tráfego",
    report_traffic_visitors: "Tráfego",
    report_traffic_transactions: "Transações",
    report_conversion_rate: "Taxa de conversão",
    general_target: "Objetivo",
    report_title_basket_size: "Tamanho do cesto",
    general_units: "Artigos",
    report_basket_size_sales: "ATV",
    report_basket_size_qty: "UPT",
    report_title_labor: "Trabalho",
    report_labor_used: "Horas de trabalho vs. Objetivos ",
    report_labor_remainder: "Restante",
    report_labor_unit: "h.",
    report_title_loyalty_program: "Programa de Fidelidade",
    report_loyalty_program_new_members: "Novos Membros",
    report_loyalty_program_new_leads: "Novas Oportunidades",
    report_title_department: "Departamentos",
    report_title_general: "Desempenho da Loja",
    report_general_best_sellers: "Mais vendidos da Loja",
    report_general_best_categories: "Relatório de Categoria",
    report_title_stores_performance: "Desempenho das Lojas",
    report_series0: "Loja ",
    report_series1: "Cadeia ",
    report_yesterday: "Vendas de ontem ",
    report_relative_target: "Objectivo de vendas de hoje",
    report_todays_target: "Objectivo de vendas de hoje",
    report_title_brands: "Marcas ",
    delete_task_options_title: "Apagar a tarefa",
    delete_task_options_subtitle: "Escolha uma das opções",
    delete_all_stores: "Apagar de todas as lojas",
    delete_this_store: "Apagar desta loja ",
    delete_category: "Apagar a categoria",
    same_category_alert_title: "Categoria já existente. Por favorescolha um nome diferente",
    uploading_post_media_loading: "Carregando...",
    report_the_product_sold_units: " O Produto Vendeu",
    report_the_product_sold_money: "Somando",
    report_best_sellers_man: "Mais Vendidos em lojas - Homem ",
    report_best_categories_man: "Relatório de Categoria - Homem",
    report_best_sellers_women: "Mais Vendidos em Lojas - Mulher",
    report_best_categories_women: "Relatório de Categoria - Mulher",
    report_general_best_sellers_man: "Mais Vendidos em lojas - Homem ",
    report_general_best_categories_man: "Relatório de Categoria - Homem",
    report_general_best_sellers_women: "Mais Vendidos em Lojas - Mulher",
    report_general_best_categories_women: "Relatório de Categoria - Mulher",
    Manager_goal: "Meta do Gerente",
    groups_title_by_income: "Grupos por Rendimento",
    groups_title_by_qty: "Grupos por Quantidade",
    show_all: "Mostrar Todos",
    view_linked_task: "Saber Mais",
    linked_tasks_title: "Linkado a uma tarefa ",
    subtask_comments: "Comentários",
    subtask_files: "Arquivos",
    newsfeed_sidebar: "Feed de notícias",
    reports_sidebar: "Relatórios",
    store_filter_sidebar: "filtrar por loja ",
    see_less_sidebar: "Ver Menos ",
    see_more_sidebar: "Ver Mais ",
    area_filter_sidebar: "Filtrar por área ",
    like_counter_of: "de",
    like_counter_users: "usuários",
    title_header: "Título",
    priority_header: "Prioridade",
    timeline_header: "Cronograma",
    status_header: "Status",
    title_analytic_header: "Estado da Tarefa",
    inspiration_image_header: "Imagens de inspiração ",
    st_image_header: "Tire uma fotografia",
    st_text_header: "Pergunta(s)",
    st_link_header: "Links Externos",
    st_product_header: "Cartão de Produto",
    task_data_subtitle: "Organização da loja, colocação de artigos e mais descrições sobre este tipo de tarefas.",
    task_opt_subtitle: "Organização da loja, colocação de artigos e mais descrições sobre este tipo de tarefas.",
    add: "Adicionar",
    rotate: "Girar",
    sub_task_hover_details: "Clique para ver detalhes",
    uploaded_tag: "Carregado",
    photo: "Foto ",
    video: "Vídeo",
    post_header: "Criar uma publicação",
    view_all: "Ver todos",
    high_priority: "Elevado",
    temp_share_to: "Partilhar com todas as Lojas",
    fetch_more_comments_post_button: "Mais Comentários",
    hide_comments_post_button: "Esconder Comentários",
    is_roll_toggale: "Destaques de STORE-E",
    traning_task_name: "Biblioteca",
    reply_button: "Responder",
    replay_button: "Responder",
    newer_comment_button: "New Comments",
    older_comment_button: "More Comments",
    reply_to_title: "Responder a",
    view_more_reply: "More replies",
    is_st_required: "Required Sub Task",
    create_task_name_placeholder: "Nome da Tarefa",
    create_task_view_placeholder: "Tipo de Tarefa",
    create_task_view_op_0: "Melhorar as Vendas",
    create_task_view_op_1: "Tarefas Operacionais",
    create_task_view_op_3: "VM",
    create_task_view_op_2: "Aprendizagem Colaborativa",
    create_task_start_date_placeholder: "Escolha data de inicio ",
    create_task_end_date_placeholder: "Escolha data final",
    create_task_st_title_placeholder: "Nome da Sub-Tarefa",
    create_task_st_pid_placeholder: "ID do Produto",
    create_task_status_menu_select_btn: "Escolha a posivel resposta",
    create_task_add_img_btn: "Adicionar Imagem ",
    create_task_st_create_btn: "Crear",
    create_task_st_cancel_btn: "Cancelar",
    create_task_st_save_btn: "Salvar",
    create_task_add_cat_btn: "Adicionar Categoria",
    create_task_name_title: "Nome da Tarefa",
    create_task_view_title: "Tipo de Tarefa",
    create_task_assignee_title: "a",
    create_task_dialog_box_title: "Adicionar Descrição para",
    create_task_dialog_box_placeholder: "Descrição...",
    create_task_st_image_title: "Tirar uma fotografia",
    create_task_st_text_title: "Pergunta(s)",
    create_task_st_link_title: "Links Externos",
    create_task_st_product_title: "Cartão de Produto",
    create_task_st_image_btn: "Adicionar Fotografia ",
    create_task_st_text_btn: "Adicionar Pergunta",
    create_task_st_link_btn: "Adicionar Link Externo",
    create_task_sidebar_task_title: "Nome da Tarefa",
    forget_password_alert_success_body: "Password was reset",
    create_task_assignee_header: "Assignee",
    choose_all_groups_btn: "choose all",
    submit_btn: "Ok, Feito ",
    footer_button_return: "Ok, Feito ",
    filter_btn: "Filtrar ",
    task_overview_graph_title: "Estado da Tarefa:",
    task_overview_card_r_title: "Nº da Tarefa:",
    task_overview_card_c_title: "Grupo de Tarefas:",
    task_overview_card_l_title: "Grupos Selecionados",
    task_overview_naxt_page_button: "Mais ",
    task_overview_see_task_button: "Ver Tarefas",
    filter_header: "Filtrar ",
    assigned_by_filter: "Atribuída por",
    assigned_by_me_filter: "Eu ",
    assigned_by_all_filter: "Todos ",
    group_filter: "Grupos",
    group_selecte_all_filter: "Selecionar Todos",
    group_unselecte_filter: "Deselecionar Todos",
    group_back_filter: "Cancelar ",
    group_filter_header: "Grupos Selecionados",
    done_filter_btn: "Ok, Feito ",
    apply_all_filters_btn: "Aplicar todos os Filtros ",
    level_filter: "Segmentação por nível de",
    all_tasks_group_list: "Lista de Grupo ",
    stores_all_tasks_group_list: "Loja ",
    areas_all_tasks_group_list: "Área ",
    task_overview_title: "Detalhes da tarefa",
    filter_by_retail: "Retalho",
    analytical_done: "Feito",
    analytical_inprogress: "Em curso",
    analytical_todo: "Por fazer",
    task_overview_filter_title: "Filtro",
    filter_by_area: "Área ",
    filter_by_store: "Loja ",
    task_overview_category_status_title: "Status da atual Categoria",
    filter_status_done: "Feito",
    filter_status_inprogress: "Em curso",
    filter_status_todo: "Por fazer",
    task_overview_status_title: "Status",
    sort_by_status: "Ordenar por status",
    task_overview_task_view_0: "Melhorar as Vendas",
    task_overview_task_view_1: "Tarefas Operacionais",
    task_overview_task_view_3: "VM",
    task_overview_task_view_2: "Biblioteca",
    back_button: "Voltar",
    task_overview_filter_status: "Status",
    task_overview_filter_status_all: "Todos ",
    task_overview_select_all_btn: "Selecionar Tudo",
    task_overview_choose_group_btn: "Escolha",
    task_status_done: "Feito",
    task_status_inprogress: "Em curso",
    task_status_todo: "Por fazer",
    mobile_menu_header: "Menu",
    task_overview_expand_close_button: "Minimal View"
  }
}

export default translations
