import React from 'react'
import { Image } from '@chakra-ui/core'
import { Post } from 'redux/reducers/feed'

export type MediaArray = {
  type?: string[]
  iconPath: string
  text: string
}
export type PinedPostsObject = {
  [key: string]: string[]
}
export const getPostsIdsFromLocalStorage = (): PinedPostsObject => {
  const storedData = localStorage.getItem('pinnedPosts')
  const pinedPostsObject: PinedPostsObject = storedData ? JSON.parse(storedData) : {}
  return pinedPostsObject
}

export const generateIcons = (mediaArray: MediaArray[], cdnPath: string, w: string, h: string) => {
  return mediaArray
    .filter((media) => media.type && media.type.length > 0)
    .map((media) => ({
      icon: <Image src={`${cdnPath}default/icons/posts/${media.iconPath}`} opacity={0.5} w={w} h={h} />,
      text: media.text,
    }))
}
export const insertPost = (posts: Post[], unPinnedPost: Post): Post[] => {
  let pinnedPostsCount = 0
  const postsWithoutUnpinned = posts.filter((post) => {
    if (post.is_self_pin) pinnedPostsCount++
    return post.post_id !== unPinnedPost.post_id
  })

  if (posts[0].post_id === unPinnedPost.post_id) {
    pinnedPostsCount--
  }

  const indexToInsert = postsWithoutUnpinned.findIndex(
    (post, index) =>
      !post.is_self_pin &&
      !post.is_manager_pin &&
      index >= pinnedPostsCount &&
      unPinnedPost.updated_at_ts > post.updated_at_ts
  )

  if (indexToInsert === -1) {
    return postsWithoutUnpinned
  }

  return [...postsWithoutUnpinned.slice(0, indexToInsert), unPinnedPost, ...postsWithoutUnpinned.slice(indexToInsert)]
}

export const updateLocalStorage = (postsIdsArray: PinedPostsObject) => {
  localStorage.setItem('pinnedPosts', JSON.stringify(postsIdsArray))
}

const addToPinedPostsObject = (object: PinedPostsObject, uid: string, postId: string): PinedPostsObject => {
  let updatedObject = { ...object }
  if (!updatedObject[uid]) {
    updatedObject[uid] = []
  }
  if (!updatedObject[uid].includes(postId)) {
    updatedObject[uid].push(postId)
  }
  return updatedObject
}

export const removeFromPinedPostsObject = (object: PinedPostsObject, uid: string, postId: string): PinedPostsObject => {
  if (!object[uid]) return object

  let updatedObject = { ...object }
  const filteredPosts = updatedObject[uid].filter((id) => id !== postId)

  if (filteredPosts.length === 0) {
    delete updatedObject[uid]
  } else {
    updatedObject[uid] = filteredPosts
  }

  return updatedObject
}

export type PinType = 'is_self_pin' | 'is_manager_pin'

export const pinPost = (posts: Post[], postId: string, uid: string, pinType: PinType): Post[] => {
  const postToPin = posts.find((post) => post.post_id === postId)
  if (!postToPin) return posts

  const otherPosts = posts.filter((post) => post.post_id !== postId)

  if (pinType === 'is_manager_pin') {
    return [{ ...postToPin, is_manager_pin: true }, ...otherPosts]
  }

  if (pinType === 'is_self_pin') {
    const pinnedPostsObject: PinedPostsObject = getPostsIdsFromLocalStorage()
    const updatedPostsObject: PinedPostsObject = addToPinedPostsObject(pinnedPostsObject, uid, postId)
    updateLocalStorage(updatedPostsObject)

    const managerPinnedIndex = otherPosts.findIndex((post) => !post.is_manager_pin)
    if (managerPinnedIndex === -1) {
      return [{ ...postToPin, is_self_pin: true }, ...otherPosts]
    }
    return [
      ...otherPosts.slice(0, managerPinnedIndex),
      { ...postToPin, is_self_pin: true },
      ...otherPosts.slice(managerPinnedIndex),
    ]
  }

  return posts
}

export const unpinPost = (posts: Post[], postId: string, uid: string, pinType: PinType): Post[] => {
  const postToUnpin = posts.find((post) => post.post_id === postId)

  if (!postToUnpin) {
    return posts
  }

  if (pinType === 'is_self_pin') {
    const pinedPostsObject: PinedPostsObject = getPostsIdsFromLocalStorage()
    const updatedPostsObject: PinedPostsObject = removeFromPinedPostsObject(pinedPostsObject, uid, postId)
    updateLocalStorage(updatedPostsObject)
  }

  const unPinnedPost = {
    ...postToUnpin,
    [pinType]: false,
  }

  return insertPost(posts, unPinnedPost)
}
