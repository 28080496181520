import { TASK_DASHBOARD_LEVELS } from './../../constants/enums'
import { getDoneRation, getOverviewAnalytical, handleError, handleErrorWithCallback } from 'utils'
import { ThunkActionType } from 'config/types'
import {
  IOverviewTask,
  IGroupCheckbox,
  IConfigStateGroups,
  RootState,
  ITaskOverviewGroupsList,
  IGetInsight,
  ITasksOverviewStateFilter,
  TasksOverviewStateData,
  ITaskOverviewGroupsListItem,
  IGetInsightTask,
  IOverviewGroupTask,
} from 'constants/interfaces'
import strings from 'constants/strings'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { defaultTaskStatuses } from 'constants/baseValues'
import keys from 'constants/keys'
import { logoutHandler } from './auth'
import { format, add } from 'date-fns'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash'

interface IFetchDataResponse {
  info: {
    groupsCounter: number
    tasksCounter: number
    clustersCounter: number
  }
  analytic: {
    name: string
    value: number
    color: string
  }[]
  groups: ITaskOverviewGroupsList
  tasks: IOverviewTask[]
}

interface IFetchDataRequest {
  groups: string[]
  created_by?: string
  rid?: string
  from_ts?: string
  to_ts?: string
}

export const actionTypes = {
  SET_INITIAL_FILTER: '[TASKS_OVERVIEW] SET_INITIAL_FILTER',
  SET_INITIAL_DATA: '[TASKS_OVERVIEW] SET_INITIAL_DATA',
  SET_RENDER_FILTER: '[TASKS_OVERVIEW] SET_RENDER_FILTER',
  UPDATE_FILTER: '[TASKS_OVERVIEW] UPDATE_FILTER',
  SET_FILTER: '[TASKS_OVERVIEW] SET_FILTER',
  SET_FETCHING: '[TASKS_OVERVIEW] SET_FETCHING',
  SORT_BY_DONE_RATIO: '[TASKS_OVERVIEW] SORT_BY_DONE_RATIO',
  SET_ACTIVE_GROUP_ID: '[TASKS_OVERVIEW] SET_ACTIVE_GROUP_ID',
  SET_ACTIVE_STORE: '[TASKS_OVERVIEW] SET_ACTIVE_STORE',
  SET_MAX_GROUP_LEVEL: '[TASKS_OVERVIEW] SET_MAX_GROUP_LEVEL',
  SET_IS_SINGLE_GROUP: '[TASKS_OVERVIEW] SET_IS_SINGLE_GROUP',
}

const setMaxGroupLevel = (maxGroupLevel: number) => ({
  type: actionTypes.SET_MAX_GROUP_LEVEL,
  payload: maxGroupLevel,
})

const setFetchingStatusAction = (value: boolean) => ({
  type: actionTypes.SET_FETCHING,
  payload: value,
})

const setIsSingleGroup = (value: boolean) => ({
  type: actionTypes.SET_IS_SINGLE_GROUP,
  payload: value,
})

const setInitialData =
  (data: TasksOverviewStateData): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_INITIAL_DATA,
      data,
    })
  }

const setInitialFilter =
  (data: ITasksOverviewStateFilter): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_INITIAL_FILTER,
      payload: data,
    })
  }

export const setActiveStore =
  (storeId: string): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_ACTIVE_STORE,
      storeId,
    })
  }

export const setActiveGroupId =
  (groupId: string): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_ACTIVE_GROUP_ID,
      groupId,
    })
  }

const getInitialGroupsData = (currentGroups: string[], allGroups: IConfigStateGroups) => {
  let variants: IGroupCheckbox[] = []
  currentGroups.forEach((key: string) => {
    if (allGroups[key]) {
      if (allGroups[key].level === strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value) {
        const parentVariantIdx = variants.findIndex((item) => item.value === key)
        if (parentVariantIdx < 0) {
          const parentId = allGroups[key].parent
          const variant: IGroupCheckbox = {
            title: allGroups[key].name,
            isChecked: true,
            child: [],
            choosedChilds: [],
            value: key,
            level: strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value,
            parentId: parentId || '',
            retailId: allGroups?.[parentId]?.parent,
            isAllChecked: true,
          }
          variants.push(variant)
        }
      } else if (allGroups[key].level === strings.OVERVIEW_FILTER.BY_LEVEL.AREA.value) {
        const parentVariantIdx = variants.findIndex((item) => item.value === allGroups[key].parent)
        const childVariant = {
          title: allGroups[key].name,
          isChecked: true,
          value: key,
          level: strings.OVERVIEW_FILTER.BY_LEVEL.AREA.value,
        }

        if (parentVariantIdx >= 0) {
          variants[parentVariantIdx].child.push(childVariant)
          variants[parentVariantIdx].choosedChilds = [...variants[parentVariantIdx].choosedChilds!, key]
        } else {
          const parentKey = allGroups[key].parent

          const variant: IGroupCheckbox = {
            title: allGroups[parentKey] ? allGroups[parentKey].name : '',
            isChecked: true,
            child: [childVariant],
            choosedChilds: [key],
            value: parentKey,
            level: 2,
            isAllChecked: true,
          }
          variants.push(variant)
        }
      }
    }
  })

  const groupsData = {
    title: 'group_filter',
    value: ['group_selecte_all_filter'],
    variants: variants,
    isAll: true,
  }
  return groupsData
}

const getInitialLevelData = (maxGroupLevel: number) => {
  const findLevelKey =
    Object.keys(strings.OVERVIEW_FILTER.BY_LEVEL).find((key: string) => {
      return strings.OVERVIEW_FILTER.BY_LEVEL[key].value === maxGroupLevel - 1
    }) || 'STORE'

  const level = strings.OVERVIEW_FILTER.BY_LEVEL[findLevelKey]

  return level
}

const getCurrentGroups = (activeGroupID: string, groups: IConfigStateGroups) => {
  let currentGroups: string[] = []
  if (groups[activeGroupID]) {
    if (groups[activeGroupID].level > 1) {
      groups[activeGroupID].child.forEach((item: string) => {
        currentGroups = [...currentGroups, ...getCurrentGroups(item, groups)]
      })
      currentGroups.push(activeGroupID)
    } else {
      currentGroups = [...currentGroups, ...groups[activeGroupID].child, activeGroupID]
    }
  }

  return currentGroups
}

const getCurrentStatus = (prevStatus: number | undefined, currentStatus: number) => {
  let status
  if (prevStatus) {
    if (currentStatus === keys.TASK_STATUSES.TODO.value && prevStatus === keys.TASK_STATUSES.TODO.value) {
      status = keys.TASK_STATUSES.TODO.value
    } else if (
      prevStatus === keys.TASK_STATUSES.INPROGRESS.value ||
      currentStatus === keys.TASK_STATUSES.INPROGRESS.value ||
      (prevStatus === keys.TASK_STATUSES.TODO.value && currentStatus === keys.TASK_STATUSES.DONE.value)
    ) {
      status = keys.TASK_STATUSES.INPROGRESS.value
    } else if (prevStatus === keys.TASK_STATUSES.DONE.value && currentStatus === keys.TASK_STATUSES.DONE.value) {
      status = keys.TASK_STATUSES.DONE.value
    }
  } else {
    status = currentStatus
  }

  return status
}

const getInitialUniqueGroups = (
  uniqueGroups: ITaskOverviewGroupsList,
  groups: IConfigStateGroups,
  arrayKeys: { [key: string]: number },
  row: string | number,
  task: IGetInsightTask
): ITaskOverviewGroupsList => {
  const currentGroupId = row[arrayKeys.group_id]
  const currentUniqueGroup = uniqueGroups[currentGroupId]
  const isFindGroup = groups && groups[currentGroupId]

  const prevValueGroup = (currentUniqueGroup && currentUniqueGroup.tasks) || []
  const prevStatusesByRefsGroup = (currentUniqueGroup && currentUniqueGroup.statusByRefs) || {}
  const groupName = isFindGroup ? groups![currentGroupId].name : currentGroupId
  const groupLevel = isFindGroup ? groups![currentGroupId].level - 1 : -1
  const parentGroupId = isFindGroup ? groups![currentGroupId].parent : ''
  const retailId = isFindGroup && parentGroupId && groups![parentGroupId] ? groups![parentGroupId].parent : ''

  const prevRefs = (currentUniqueGroup && currentUniqueGroup.tasksByRefs) || {}
  const tasksByRefs: string[] = prevRefs[row[arrayKeys.ref_id]] || []
  const availableTask = tasksByRefs.find((item) => item === row[arrayKeys.tid])

  if (!availableTask) {
    tasksByRefs.push(row[arrayKeys.tid])
  }

  const updatedGroups = {
    ...uniqueGroups,
    [currentGroupId]: {
      ...currentUniqueGroup,
      title: groupName,
      tasksByRefs: {
        ...prevRefs,
        [row[arrayKeys.ref_id]]: [...tasksByRefs],
      },
      tasks: [...prevValueGroup, task],
      statusByRefs: {
        ...prevStatusesByRefsGroup,
        [row[arrayKeys.ref_id]]: getCurrentStatus(
          prevStatusesByRefsGroup[row[arrayKeys.ref_id]],
          row[arrayKeys.status]
        ),
      },
      refId: row[arrayKeys.ref_id],
      groupLevel: groupLevel,
      groupId: currentGroupId,
      parentId: groupLevel === strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value ? currentGroupId : parentGroupId,
      retailId:
        groupLevel === strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value
          ? currentGroupId
          : retailId
          ? retailId
          : parentGroupId,
    },
  }

  return updatedGroups
}

const getInitialUniqueTasks = (
  uniqueTasks: IOverviewTask[],
  arrayKeys: { [key: string]: number },
  row: string | number,
  currentTask: IGetInsightTask
): IOverviewTask[] => {
  let updatedTasks: IOverviewTask[] = [...uniqueTasks]
  const findElementIdx = updatedTasks.findIndex((item) => item.ref_id === row[arrayKeys.ref_id])
  const currentStatus = row[arrayKeys.status]

  if (findElementIdx >= 0) {
    const prevCategoryStatus = updatedTasks[findElementIdx].statusesByCategory![row[arrayKeys.category]] || {}
    let prevGroupStatuses = {}

    if (prevCategoryStatus && prevCategoryStatus[row[arrayKeys.group_id]]) {
      prevGroupStatuses = prevCategoryStatus[row[arrayKeys.group_id]]
    }

    const updatedTask = {
      ...updatedTasks[findElementIdx],
      statuses: {
        ...updatedTasks[findElementIdx].statuses,
        [currentStatus]: updatedTasks[findElementIdx].statuses![currentStatus] + 1 || 1,
      },
      statusesByCategory: {
        ...updatedTasks[findElementIdx].statusesByCategory,
        [row[arrayKeys.category]]: {
          ...prevCategoryStatus,
          [row[arrayKeys.group_id]]: {
            ...prevGroupStatuses,
            [currentStatus]: prevGroupStatuses[currentStatus] + 1 || 1,
          },
        },
      },
      countEntries: updatedTasks[findElementIdx].countEntries! + 1,
    }

    updatedTasks[findElementIdx] = updatedTask
  } else {
    updatedTasks.push({
      ...currentTask,
      statuses: {
        [currentStatus]: 1,
      },
      statusesByCategory: {
        [row[arrayKeys.category]]: {
          [row[arrayKeys.group_id]]: {
            [currentStatus]: 1,
          },
        },
      },
      countEntries: 1,
    })
  }

  return updatedTasks
}

const taskGenerate = (row: string | number, keys: { [key: string]: number }): IGetInsightTask => {
  return {
    retail_name: row[keys.retail_name],
    group_id: row[keys.group_id],
    tid: row[keys.tid],
    category: row[keys.category],
    ref_id: row[keys.ref_id],
    title: row[keys.title],
    view: row[keys.view],
    status: row[keys.status],
    created_by: row[keys.created_by],
    from_ts: row[keys.from_ts],
    to_ts: row[keys.to_ts],
  }
}

const getStateGroups = (groups: ITaskOverviewGroupsList): ITaskOverviewGroupsList => {
  const updatedGroups = { ...groups }
  Object.keys(updatedGroups).forEach((group: string) => {
    const tasksCounter = new Set<string>()
    const statusesKeys = Object.keys(updatedGroups[group].statusByRefs)
    let statuses = { ...defaultTaskStatuses }
    updatedGroups[group].tasks.forEach((task) => {
      tasksCounter.add(task.group_id + '/' + task.tid)
    })

    statusesKeys.forEach((refKey: string) => {
      statuses[updatedGroups[group].statusByRefs[refKey]] = statuses[updatedGroups[group].statusByRefs[refKey]] + 1
    })

    updatedGroups[group] = {
      ...updatedGroups[group],
      statuses: statuses,
      analytic: getOverviewAnalytical(statuses, statusesKeys.length),
      doneRatio: getDoneRation(statuses, statusesKeys.length),
      uniqueTasks: tasksCounter,
    }
  })
  return updatedGroups
}

const fetchData = async (
  allGroups: IConfigStateGroups,
  groups: string[],
  userID?: string | null,
  callbackCalls?: number,
  startDate?: Date,
  endDate?: Date
): Promise<IFetchDataResponse | null> => {
  let data: IFetchDataResponse | null = null
  try {
    let clusters = new Set()
    let uniqueGroups: ITaskOverviewGroupsList = {}
    let uniqueTasks: IOverviewTask[] = []
    let tasksCount = new Set<string>()

    let responseRows: any = []
    let responseColumns: any = []
    let currentGroupIndex = 0
    let groupPerRequest = 250

    while (currentGroupIndex < groups.length) {
      let dataReq: IFetchDataRequest = {
        groups: groups.slice(currentGroupIndex, currentGroupIndex + groupPerRequest),
      }

      if (userID) {
        dataReq.created_by = userID
      }

      if (startDate && endDate) {
        const endDateTime = add(endDate, { hours: 23, minutes: 59, seconds: 59 })
        dataReq.from_ts = format(startDate, 'yyyy-MM-dd HH:mm:ss')
        dataReq.to_ts = format(endDateTime, 'yyyy-MM-dd HH:mm:ss')
      }

      const res: IGetInsight = await httpAuth.post(endpoints.getInsights, dataReq)

      const { data_columns, data_rows } = res.data
      responseRows = [...responseRows, ...data_rows]
      responseColumns = data_columns
      currentGroupIndex += groupPerRequest
    }

    // Index === key
    let arrayKeys: { [key: string]: number } = {}
    responseColumns.forEach((column: string, index: number) => {
      arrayKeys = {
        ...arrayKeys,
        [column]: index,
      }
    })

    responseRows.forEach((row: string | number) => {
      const task: IGetInsightTask = taskGenerate(row, arrayKeys)

      uniqueGroups = getInitialUniqueGroups(uniqueGroups, allGroups, arrayKeys, row, task)
      uniqueTasks = getInitialUniqueTasks(uniqueTasks, arrayKeys, row, task)

      clusters.add(row[arrayKeys.ref_id])
      tasksCount.add(row[arrayKeys.group_id] + '/' + row[arrayKeys.tid])
    })
    uniqueGroups = getStateGroups(uniqueGroups)
    let statuses: { [key: number]: number } = { ...defaultTaskStatuses }
    let totalTaskCounter: number = 0

    Object.keys(uniqueGroups).forEach((groupKey: string) => {
      statuses[keys.TASK_STATUSES.TODO.value] =
        statuses[keys.TASK_STATUSES.TODO.value] + uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.TODO.value]
      statuses[keys.TASK_STATUSES.INPROGRESS.value] =
        statuses[keys.TASK_STATUSES.INPROGRESS.value] +
        uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.INPROGRESS.value]
      statuses[keys.TASK_STATUSES.DONE.value] =
        statuses[keys.TASK_STATUSES.DONE.value] + uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.DONE.value]
      totalTaskCounter =
        uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.TODO.value] +
        uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.INPROGRESS.value] +
        uniqueGroups[groupKey].statuses[keys.TASK_STATUSES.DONE.value]
    })

    const analytic = getOverviewAnalytical(statuses, totalTaskCounter)
    const info = {
      groupsCounter: Object.keys(uniqueGroups).length,
      tasksCounter: tasksCount.size,
      clustersCounter: clusters.size,
    }

    uniqueTasks = uniqueTasks.map((task: IOverviewTask) => {
      return (task = {
        ...task,
        analytic: getOverviewAnalytical(task.statuses!, task.countEntries!),
      })
    })

    data = {
      info,
      analytic,
      groups: uniqueGroups,
      tasks: uniqueTasks,
    }
  } catch (err) {
    const error: AxiosError | any = err
    // @ts-ignore
    const MAX_CALLBACK_CALLS = 4
    const callbacksCount = callbackCalls || 0
    if (callbacksCount < MAX_CALLBACK_CALLS) {
      data = handleErrorWithCallback(
        error?.response?.status,
        2000,
        fetchData,
        allGroups,
        groups,
        userID,
        callbacksCount + 1
      )
    }
  }
  return data
}

export const getInitialData =
  (
    groups: IConfigStateGroups,
    activeGroupID?: string | null,
    startDate?: Date,
    endDate?: Date,
    isResetFilter?: boolean
  ): ThunkActionType =>
  async (dispatch) => {
    dispatch(setFetchingStatusAction(true))
    let requestGroups: string[] = []
    let maxGroupLevel = 0

    if (activeGroupID && groups && !isEmpty(groups)) {
      requestGroups = getCurrentGroups(activeGroupID, groups)
      maxGroupLevel = groups[activeGroupID].level - 1
      dispatch(setMaxGroupLevel(maxGroupLevel))
      dispatch(setActiveStore(activeGroupID))
    } else {
      requestGroups = Object.keys(groups)
    }

    try {
      const data = await fetchData(groups, requestGroups, null, 0, startDate, endDate)
      if (data) {
        const isSingleGroup = !(Object.keys(data.groups).length > 1)
        dispatch(setIsSingleGroup(isSingleGroup))
        dispatch(setInitialData(data))
        if (isResetFilter) {
          dispatch(getInitialFilterData(Object.keys(data.groups), maxGroupLevel))
        }
        dispatch(setFetchingStatusAction(false))
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error?.response.status, () => dispatch(logoutHandler()))
      }
      console.log('[getInitialData]', error)
    }
  }

export const getInitialFilterData =
  (currentGroupKeys: string[], maxGroupLevel: number): ThunkActionType =>
  async (dispatch, getState: () => RootState) => {
    const {
      config: {
        config: { groups },
      },
    } = getState()

    const initialLevel =
      currentGroupKeys.length > 1 ? getInitialLevelData(maxGroupLevel) : getInitialLevelData(maxGroupLevel + 1)

    let byLevelVariants = [
      {
        title: strings.OVERVIEW_FILTER.BY_LEVEL.STORE.title,
        value: strings.OVERVIEW_FILTER.BY_LEVEL.STORE.value,
      },
    ]

    if (maxGroupLevel >= TASK_DASHBOARD_LEVELS.STORE) {
      byLevelVariants = [
        ...byLevelVariants,
        {
          title: strings.OVERVIEW_FILTER.BY_LEVEL.AREA.title,
          value: strings.OVERVIEW_FILTER.BY_LEVEL.AREA.value,
        },
      ]
    }

    if (maxGroupLevel >= TASK_DASHBOARD_LEVELS.AREA) {
      byLevelVariants = [
        ...byLevelVariants,
        {
          title: strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.title,
          value: strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value,
        },
      ]
    }
    byLevelVariants = [
      ...byLevelVariants,
      {
        title: strings.OVERVIEW_FILTER.BY_LEVEL.USER.title,
        value: strings.OVERVIEW_FILTER.BY_LEVEL.USER.value,
      },
    ]

    byLevelVariants.reverse()

    const initialFilterData = {
      assignBy: {
        title: 'assigned_by_filter',
        value: strings.OVERVIEW_FILTER.BY_ASSIGN.ALL,
        variants: [strings.OVERVIEW_FILTER.BY_ASSIGN.ALL, strings.OVERVIEW_FILTER.BY_ASSIGN.ME],
      },
      group: getInitialGroupsData(currentGroupKeys, groups),
      byLevel: {
        title: 'level_filter',
        value: initialLevel,
        variants: currentGroupKeys.length > 1 ? byLevelVariants : [initialLevel],
      },
    }
    dispatch(setFilter(initialFilterData, true))
    dispatch(setInitialFilter(initialFilterData))
  }

export const updateLevelFilter =
  (title: string, value: number | string): ThunkActionType =>
  (dispatch, getState: () => RootState) => {
    const {
      tasksOverview: { renderFilter },
    } = getState()

    const updatedFilter = {
      ...renderFilter,
      byLevel: {
        ...renderFilter?.byLevel,
        value: {
          title,
          value,
        },
      },
    }

    dispatch({
      type: actionTypes.UPDATE_FILTER,
      updatedFilter,
    })
  }

export const updateAssignFilter =
  (title: string, value: number | string): ThunkActionType =>
  (dispatch, getState: () => RootState) => {
    const {
      tasksOverview: { renderFilter },
    } = getState()

    const updatedFilter = {
      ...renderFilter,
      assignBy: {
        ...renderFilter?.assignBy,
        value: {
          title,
          value,
        },
      },
    }

    dispatch({
      type: actionTypes.UPDATE_FILTER,
      updatedFilter,
    })
  }

export const updateGroupsFilter =
  (name: string, parent: string | null, currentVariant?: IGroupCheckbox): ThunkActionType =>
  (dispatch, getState: () => RootState) => {
    const {
      tasksOverview: { renderFilter },
    } = getState()

    let isAllChecked = true
    let checkedVariants: string[] = []

    const updatedVariants = renderFilter!.group.variants.map((variant) => {
      let checkedChildVariants: string[] = []
      if (name === 'areas_all_tasks_group_list') {
        variant.isChecked = !renderFilter!.group.isAll

        variant.child.forEach((childVariant) => {
          childVariant.isChecked = !renderFilter!.group.isAll

          if (!renderFilter!.group.isAll) {
            checkedChildVariants.push(childVariant.value!)
          }
        })
      } else if (currentVariant && variant.value === currentVariant.value) {
        let isOnceChecked = false
        let isValid: boolean
        variant.child.forEach((childVariant, index) => {
          if (index === 0) {
            isValid = !childVariant.isChecked
          }
          childVariant.isChecked = isValid
          if (isValid) {
            checkedChildVariants.push(childVariant.value!)
            isOnceChecked = true
          }
        })
        variant.isChecked = isOnceChecked
      } else if (parent === variant.title) {
        let isOnceChecked = false
        let isAllChildChecked = true
        variant.child.forEach((childVariant) => {
          if (childVariant.title === name) {
            childVariant.isChecked = !childVariant.isChecked
          }
          if (childVariant.isChecked) {
            checkedChildVariants.push(childVariant.value!)
            isOnceChecked = true
          }

          if (!childVariant.isChecked) {
            isAllChildChecked = false
          }
        })
        variant.isAllChecked = isAllChildChecked
        variant.isChecked = isOnceChecked
      } else if (parent === null && name === variant.title) {
        const isChecked = !(variant.choosedChilds?.length === variant.child.length)
        variant.child.forEach((childVariant) => {
          childVariant.isChecked = isChecked

          if (isChecked) {
            checkedChildVariants.push(childVariant.value!)
          }
        })
      } else {
        checkedChildVariants = [...variant.choosedChilds!]
      }

      variant.choosedChilds = checkedChildVariants

      if (!variant.isChecked) {
        isAllChecked = false
      } else {
        if (!variant.isAllChecked) {
          checkedVariants.push(`${variant.title} (${variant.choosedChilds!.length}/${variant.child.length})`)
        }
        checkedVariants.push(variant.title)
      }
      return variant
    })
    if (updatedVariants.length === checkedVariants.length) {
      let isAll = true
      updatedVariants.forEach((variant) => {
        if (!variant.isAllChecked) {
          isAll = false
        }
      })
      if (isAll) checkedVariants = ['assigned_by_all_filter']
    }

    const updatedFilter = {
      ...renderFilter,
      group: {
        ...renderFilter?.group,
        variants: updatedVariants,
        isAll: isAllChecked,
        value: checkedVariants,
      },
    }

    dispatch({
      type: actionTypes.UPDATE_FILTER,
      updatedFilter,
    })
  }

const groupByLevel = (
  filter: ITasksOverviewStateFilter,
  overviewData: TasksOverviewStateData,
  groups: IConfigStateGroups | null,
  groupsKeys: string[],
  myUID?: string
): ITaskOverviewGroupsList => {
  let groupedItems = {}
  const filterLevel = filter.byLevel.value.value
  const areaLevel = strings.OVERVIEW_FILTER.BY_LEVEL.AREA.value
  const storeLevel = strings.OVERVIEW_FILTER.BY_LEVEL.STORE.value
  const retailLevel = strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value
  const userLevel = strings.OVERVIEW_FILTER.BY_LEVEL.USER.value
  const usersKeys = Object.fromEntries(
    Object.entries(overviewData!.groups).filter(([key, value]) => value.groupLevel === -1)
  )

  if (filterLevel !== userLevel) {
    groupsKeys.forEach((key: string) => {
      try {
        if (overviewData!.groups[key]) {
          if (filterLevel >= areaLevel) {
            let currentLevel = 'parentId'

            if (filterLevel === retailLevel) {
              currentLevel = 'retailId'
            }

            let parentKey = overviewData!.groups[key][currentLevel] || ''

            const parentGroupLevel = parentKey
              ? overviewData!.groups[parentKey]
                ? overviewData!.groups[parentKey].groupLevel
                : groups!?.[parentKey]?.level - 1
              : null

            if (
              overviewData!.groups[key].groupLevel === 1 &&
              parentGroupLevel !== null &&
              parentGroupLevel > filter.byLevel.value.value
            ) {
              parentKey = key
            }

            const parentTasks = groupedItems[parentKey] ? groupedItems[parentKey].tasks : []
            let statuses = {
              [keys.TASK_STATUSES.TODO.value]: overviewData!.groups[key].statuses[keys.TASK_STATUSES.TODO.value] || 0,
              [keys.TASK_STATUSES.INPROGRESS.value]:
                overviewData!.groups[key].statuses[keys.TASK_STATUSES.INPROGRESS.value] || 0,
              [keys.TASK_STATUSES.DONE.value]: overviewData!.groups[key].statuses[keys.TASK_STATUSES.DONE.value] || 0,
            }

            const tasks = [...overviewData!.groups[key].tasks]
            groupedItems = {
              ...groupedItems,
              [parentKey]: {
                ...overviewData!.groups[key],
                title: groups![parentKey] ? groups![parentKey].name : overviewData!.groups[key].title,
                tasks: [...parentTasks, ...tasks],
                statuses: {
                  [keys.TASK_STATUSES.TODO.value]:
                    ((groupedItems[parentKey] && groupedItems[parentKey].statuses[keys.TASK_STATUSES.TODO.value]) ||
                      0) + statuses[keys.TASK_STATUSES.TODO.value],
                  [keys.TASK_STATUSES.INPROGRESS.value]:
                    ((groupedItems[parentKey] &&
                      groupedItems[parentKey].statuses[keys.TASK_STATUSES.INPROGRESS.value]) ||
                      0) + statuses[keys.TASK_STATUSES.INPROGRESS.value],
                  [keys.TASK_STATUSES.DONE.value]:
                    ((groupedItems[parentKey] && groupedItems[parentKey].statuses[keys.TASK_STATUSES.DONE.value]) ||
                      0) + statuses[keys.TASK_STATUSES.DONE.value],
                },
                refId: overviewData!.groups[key].refId,
                groupLevel: overviewData!.groups[key].groupLevel,
                parentId: groupedItems[parentKey] ? groupedItems[parentKey].parentId : '',
                groupId: parentKey,
              },
            }
          } else if (filterLevel === storeLevel && overviewData!.groups[key].groupLevel === storeLevel) {
            groupedItems = {
              ...groupedItems,
              [key]: {
                ...overviewData!.groups[key],
              },
            }
          }
        }
      } catch (e) {
        console.log(e)
      }
    })
  } else {
    Object.keys(usersKeys).forEach((key: string) => {
      const groupData = overviewData!.groups[key]
      if (filterLevel === userLevel) {
        if (groupData.groupLevel === userLevel) {
          groupedItems[key] = {
            ...groupData,
            title: groups![key]?.name || groupData.title,
            tasks: [...groupData.tasks],
            statuses: {
              [keys.TASK_STATUSES.TODO.value]: groupData.statuses[keys.TASK_STATUSES.TODO.value] || 0,
              [keys.TASK_STATUSES.INPROGRESS.value]: groupData.statuses[keys.TASK_STATUSES.INPROGRESS.value] || 0,
              [keys.TASK_STATUSES.DONE.value]: groupData.statuses[keys.TASK_STATUSES.DONE.value] || 0,
            },
            refId: groupData.refId,
            groupLevel: groupData.groupLevel,
            parentId: groupData.parentId || '',
            groupId: key,
          }
        }
      }
    })
  }

  return groupedItems
}
const filteredByGroups = (filter: ITasksOverviewStateFilter, overviewData: TasksOverviewStateData): string[] => {
  let groupsKeys: string[] = []

  if (filter) {
    if (filter.group.variants.length === 0) {
      return Object.keys(overviewData!.groups)
    }
    filter.group.variants.forEach((variant: IGroupCheckbox) => {
      if (variant.choosedChilds?.length === variant.child.length) {
        groupsKeys = [...groupsKeys, ...variant.choosedChilds, variant.value!]
      } else if (variant.choosedChilds!.length > 0) {
        groupsKeys = [...groupsKeys, ...variant.choosedChilds!]
      }
    })
  }

  return groupsKeys
}

export const setFilter =
  (initialFilter?: ITasksOverviewStateFilter, isInitial: boolean = false): ThunkActionType =>
  async (dispatch, getState: () => RootState) => {
    try {
      if (!isInitial) {
        dispatch(setFetchingStatusAction(true))
      }
      const {
        tasksOverview: { renderFilter, overviewData, choosedGroup },
        config: {
          config: { groups, myUID },
        },
      } = getState()

      const currentFilter = initialFilter || renderFilter
      if (currentFilter) {
        const groupsKeys: string[] = filteredByGroups(currentFilter, overviewData)
        const clustersCounter = new Set()
        const groupsCounter = new Set()
        const groupTasksCounter = new Set()
        let tasksCounter = 0
        let statuses = { ...defaultTaskStatuses }

        let currentUser = null
        if (currentFilter.assignBy.value.value !== 'assigned_by_all_filter') {
          currentUser = myUID
        }
        const currentData = overviewData

        let filteredGroups: ITaskOverviewGroupsList = groupByLevel(currentFilter, currentData, groups, groupsKeys)

        const groupsArray: ITaskOverviewGroupsListItem[] = []
        const tasksRefs: Set<string> = new Set()
        Object.keys(filteredGroups).forEach((key: string) => {
          const totalCounter =
            (filteredGroups[key].statuses[keys.TASK_STATUSES.TODO.value] || 0) +
            (filteredGroups[key].statuses[keys.TASK_STATUSES.INPROGRESS.value] || 0) +
            (filteredGroups[key].statuses[keys.TASK_STATUSES.DONE.value] || 0)
          filteredGroups[key].analytic = getOverviewAnalytical(filteredGroups[key].statuses, totalCounter)
          filteredGroups[key].doneRatio = getDoneRation(filteredGroups[key].statuses, totalCounter)

          statuses = {
            [keys.TASK_STATUSES.TODO.value]:
              statuses[keys.TASK_STATUSES.TODO.value] +
              (filteredGroups[key].statuses[keys.TASK_STATUSES.TODO.value] || 0),
            [keys.TASK_STATUSES.INPROGRESS.value]:
              statuses[keys.TASK_STATUSES.INPROGRESS.value] +
              (filteredGroups[key].statuses[keys.TASK_STATUSES.INPROGRESS.value] || 0),
            [keys.TASK_STATUSES.DONE.value]:
              statuses[keys.TASK_STATUSES.DONE.value] +
              (filteredGroups[key].statuses[keys.TASK_STATUSES.DONE.value] || 0),
          }
          filteredGroups[key].tasks.forEach((task) => {
            tasksRefs.add(task.ref_id)
            clustersCounter.add(task.ref_id)
            groupsCounter.add(task.group_id)
            groupTasksCounter.add(task.group_id + task.tid)
            if (overviewData!.groups[task.group_id]) {
              if (currentFilter.byLevel.value.value >= strings.OVERVIEW_FILTER.BY_LEVEL.RETAIL.value) {
                groupsCounter.add(overviewData!.groups[task.group_id].retailId)
              }
              if (currentFilter.byLevel.value.value > strings.OVERVIEW_FILTER.BY_LEVEL.AREA.value) {
                groupsCounter.add(overviewData!.groups[task.group_id].parentId)
              }
            }
          })
          tasksCounter +=
            filteredGroups[key].statuses[keys.TASK_STATUSES.TODO.value] +
            filteredGroups[key].statuses[keys.TASK_STATUSES.INPROGRESS.value] +
            filteredGroups[key].statuses[keys.TASK_STATUSES.DONE.value]
          groupsArray.push(filteredGroups[key])
        })
        let tasksList: IOverviewTask[] = []
        let statusesByStore = {}
        Object.keys(filteredGroups).forEach((groupKey: string) => {
          filteredGroups[groupKey].tasks.forEach((task: IOverviewGroupTask) => {
            const findElementIdx = tasksList.findIndex((item) => item.ref_id === task.ref_id)
            let currentStatusTask = statusesByStore[task.ref_id] || {}
            statusesByStore = {
              ...statusesByStore,
              [task.ref_id]: {
                ...currentStatusTask,
                [task.group_id]: overviewData!.groups[task.group_id].statusByRefs[task.ref_id],
              },
            }
            if (findElementIdx >= 0) {
              const updatedTask = {
                ...tasksList[findElementIdx],
              }
              tasksList[findElementIdx] = updatedTask
            } else {
              // @ts-ignore
              tasksList.push({
                ...task,
                statuses: {
                  [task.status]: 1,
                },
              })
            }
          })
        })

        tasksList = tasksList.map((task: IOverviewTask) => {
          const taskStatuses = statusesByStore[task.ref_id!]
          let statuses = { ...defaultTaskStatuses }

          Object.keys(taskStatuses).forEach((groupKey: string) => {
            statuses = {
              ...statuses,
              [taskStatuses[groupKey]]: statuses[taskStatuses[groupKey]] + 1,
            }
          })

          let countEntries =
            statuses[keys.TASK_STATUSES.TODO.value] +
            statuses[keys.TASK_STATUSES.INPROGRESS.value] +
            statuses[keys.TASK_STATUSES.DONE.value]
          return (task = {
            ...task,
            statuses,
            analytic: getOverviewAnalytical(statuses, countEntries),
          })
        })
        const currentGroup = groupsArray.find((group) => group.groupId === choosedGroup)
        if (!currentGroup) {
          dispatch(setActiveGroupId('all'))
        }
        const data = {
          groups: groupsArray,
          tasks: tasksList,
          info: {
            groupsCounter: groupsCounter.size,
            tasksCounter: groupTasksCounter.size,
            clustersCounter: clustersCounter.size,
          },
          analytic: getOverviewAnalytical(statuses, tasksCounter),
        }
        dispatch({
          type: actionTypes.SET_FILTER,
          payload: data,
          appliedFilter: currentFilter,
        })
      }
      if (!isInitial) {
        dispatch(setFetchingStatusAction(false))
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error?.response.status, () => dispatch(logoutHandler()))
      }
    } finally {
      dispatch(setFetchingStatusAction(false))
    }
  }

export const setRenderFilter = (): ThunkActionType => (dispatch, getState: () => RootState) => {
  const {
    tasksOverview: { appliedFilter },
  } = getState()

  dispatch({
    type: actionTypes.SET_RENDER_FILTER,
    payload: appliedFilter,
  })
}

export const setSortByDoneRation = (): ThunkActionType => (dispatch, getState: () => RootState) => {
  const {
    tasksOverview: { filteredData, isSortedByDoneRation },
  } = getState()
  let sortedGroups = [...filteredData!.groups]

  if (isSortedByDoneRation) {
    sortedGroups.reverse()
  } else {
    sortedGroups.sort((a: ITaskOverviewGroupsListItem, b: ITaskOverviewGroupsListItem) => {
      if (a.doneRatio < b.doneRatio) {
        return 1
      } else if (a.doneRatio > b.doneRatio) {
        return -1
      }

      return 0
    })
  }

  dispatch({
    type: actionTypes.SORT_BY_DONE_RATIO,
    payload: sortedGroups,
  })
}
