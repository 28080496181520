import React from 'react'
import { Flex, Text, Divider, Avatar } from '@chakra-ui/core'
import { CustomCheckBox } from '../GroupsTree/style'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'

interface IUserNode {
  title: string | undefined
  desc?: string
  secondaryUID?: string
  checkedUids: string[] | undefined
  id: string | undefined
  profileImg: string | undefined
  handleCheckUids: (id: string) => void
}

const UserNode = ({ title, id, checkedUids, profileImg, handleCheckUids, desc, secondaryUID }: IUserNode) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <>
      <Flex width="100%" justifyContent="space-between">
        <Flex alignItems="center" flexGrow={1} cursor="pointer" minH="45px">
          <Flex justifyContent="center" flexDirection="row" alignItems="center" style={{ columnGap: '10px' }}>
            <Avatar src={profileImg} w="40px" h="40px" />
            <Flex flexDir="column" mx="5px" fontWeight={'500'} justifyContent="flex-end">
              <Text id={id} fontSize="18px" color="black">
                {title}
              </Text>
              {desc && (
                <Text id={desc} fontSize="15px" color="#90949C">
                  {desc}
                </Text>
              )}
              {secondaryUID && (
                <Text id={secondaryUID} fontSize="15px" color="#90949C">
                  {secondaryUID}
                </Text>
              )}
            </Flex>
          </Flex>
        </Flex>
        <CustomCheckBox
          backgroundImage="none"
          isChecked={checkedUids?.includes(id!)}
          onChange={() => {
            handleCheckUids(id!)
          }}
          color={theme?.elementsColor}
          hoverColor={theme?.backgroundLight}
        />
      </Flex>
      <Divider my="5px" color={colors.greyMedium} />
    </>
  )
}

export default UserNode
