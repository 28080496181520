import { Flex, Image, Text, Box, Input, Button } from '@chakra-ui/core'
import colors from 'constants/colors'
import dayjs from 'dayjs'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import { ButtonBox, ButtonBoxWrapper, CustomTextareaAutosize } from 'components/helperStyles'
import { BsCardImage } from 'react-icons/bs'
import keys from 'constants/keys'
import { ReactionsPicker } from '../Emojis'
import { IoCloseCircleOutline } from 'react-icons/io5'
import { AudioPlayer } from 'components/AudioPlayer'
import { PostMediaGrid } from 'components/NewsFeed/PostMediaGrid'
import { FbGridVideoPreview } from '../FbGrid/FbGridVideoPreview'

interface IProps {
  created_at_ts: number
  popTitle: string
  popBody: string
  popImageURL: string
  pop_initiator: number
  created_by: string
  isEditMode: boolean
  setPopBody: (value: string) => void
  setPopTitle: (value: string) => void
  handleSingleMediaUploading: (e: React.ChangeEvent<HTMLInputElement>) => void
  setUploadedImagesURL: (value: string[]) => void
  addMedia: () => void
  isUploadLoading: boolean
  imageInputRef: React.RefObject<HTMLInputElement>
  isLoading: boolean
  audio?: string
  video?: string
}

export default function RePopBody({
  created_by,
  created_at_ts,
  popBody,
  popTitle,
  popImageURL,
  isEditMode,
  pop_initiator,
  setPopBody,
  setPopTitle,
  handleSingleMediaUploading,
  addMedia,
  imageInputRef,
  isLoading,
  setUploadedImagesURL,
  audio,
  video,
}: IProps) {
  const [activeTitleInput, setActiveTitleInput] = useState(false)
  const [activeBodyInput, setActiveBodyInput] = useState(false)

  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const date = dayjs.unix(created_at_ts)
  const formattedDate = date.locale('en').format('DD/MM/YYYY, HH:mm')
  const creatorsName = `${retailUsersObject?.[created_by]?.first_name ?? ''} ${
    retailUsersObject?.[created_by]?.last_name ?? ''
  }`
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleDeleteMedia = () => {
    setUploadedImagesURL([])
  }

  const handleActiveTitleInput = () => {
    setActiveTitleInput(true)
    setActiveBodyInput(false)
  }

  const handleActiveBodyInput = () => {
    setActiveBodyInput(true)
    setActiveTitleInput(false)
  }
  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPopTitle(e.target.value)
  }

  const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textareaRef.current
    if (!textarea) return
    const newText = e.target.value

    const lineHeight = parseInt(window.getComputedStyle(textarea).lineHeight, 10)
    const scrollHeight = textarea.scrollHeight
    const totalLines = Math.ceil(scrollHeight / lineHeight)

    if (totalLines > 7) {
      e.preventDefault()
      return
    }
    setPopBody(newText)
  }
  const onEmojiSelect = (emoji: string) => {
    if (activeTitleInput && inputRef.current) {
      const start = inputRef.current.selectionStart ?? 0
      const end = inputRef.current.selectionEnd ?? 0
      const text = popTitle
      const newText = text.substring(0, start) + emoji + text.substring(end)
      setPopTitle(newText)
    } else if (activeBodyInput && textareaRef.current) {
      const start = textareaRef.current.selectionStart
      const end = textareaRef.current.selectionEnd
      const text = popBody
      const newText = text.substring(0, start) + emoji + text.substring(end)
      setPopBody(newText)
    }
  }
  const videos = [video]
  return (
    <Flex minH="100px" flexDir="column" alignItems="center" px="55px" textAlign="center" position="relative">
      {isEditMode && !isLoading && <ReactionsPicker onEmojiSelect={onEmojiSelect} />}
      <Text dir="auto" fontWeight="600">
        {creatorsName.trim() ? creatorsName : strings.COMPANY_NAME}
      </Text>

      <Text dir="auto" color={colors.greyMain}>
        {formattedDate}
      </Text>
      <Box
        w={'100%'}
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'inherit',
          paddingTop: '15px',
        }}
      >
        <Input
          isReadOnly={!isEditMode}
          value={popTitle}
          ref={inputRef}
          onMouseDown={handleActiveTitleInput}
          maxLength={keys.POP_TITLE_MAX_LENGTH}
          onChange={handleTitleChange}
          variant="unstyled"
          fontWeight="bold"
          fontFamily="Arial"
          lineHeight="25px"
          fontSize="24px"
          textAlign="center"
        />
      </Box>
      <Box
        w={'100%'}
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'inherit',
          paddingTop: '10px',
        }}
      >
        <CustomTextareaAutosize
          maxRows={7}
          ref={textareaRef}
          readOnly={!isEditMode}
          value={popBody}
          onMouseDown={handleActiveBodyInput}
          onChange={handleBodyChange}
          boxShadow="none"
          minHeight="unset"
          style={{
            padding: '0px',
            textAlign: 'center',
            fontFamily: 'Arial',
            lineHeight: '25px',
            fontSize: '20px',
            color: 'black',
          }}
        />
      </Box>

      {isEditMode ? (
        <>
          <Input
            ref={imageInputRef}
            accept={strings.SERVER_IMAGE_FORMATS}
            type="file"
            multiple
            id="upload-image-button"
            style={{ display: 'none' }}
            onChange={handleSingleMediaUploading}
          />
          {popImageURL ? (
            <ButtonBoxWrapper>
              <ButtonBox className={!isLoading ? 'postModal__ButtonBox' : ''} style={{ left: 'unset' }}>
                <Button size="md" fontWeight="500" onClick={addMedia}>
                  <BsCardImage />
                  <Text mx="2">Change Image</Text>
                </Button>
                <Button onClick={handleDeleteMedia}>
                  <IoCloseCircleOutline size="25px" />
                  <Text mx="2">Delete</Text>
                </Button>
              </ButtonBox>
              <Image src={popImageURL} w="140pt" height={popImageURL ? '140pt' : 'auto'} maxH="160pt" my={'8pt'} />
            </ButtonBoxWrapper>
          ) : !audio && !video ? (
            <Button onClick={addMedia}>Upload Image</Button>
          ) : (
            <>
              {audio && <AudioPlayer src={audio} />}
              {video && (
                <PostMediaGrid media={[video]} isEditable={false} px="0">
                  {[video].map((videoSrc) => (
                    <FbGridVideoPreview key={videoSrc} src={videoSrc} />
                  ))}
                </PostMediaGrid>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {popImageURL && (
            <Image src={popImageURL} w="140pt" height={popImageURL ? '140pt' : 'auto'} maxH="160pt" my={'8pt'} />
          )}
          {audio && <AudioPlayer src={audio} />}
          {video && (
            <PostMediaGrid media={[video]} isEditable={false} px="0">
              {[video].map((videoSrc) => (
                <FbGridVideoPreview key={videoSrc} src={videoSrc} />
              ))}
            </PostMediaGrid>
          )}
        </>
      )}
    </Flex>
  )
}
