import { Avatar, Box } from '@chakra-ui/core'
import React from 'react'
import { ChatPreviewText, ChatPreviewContainer, ChatPreviewTitle, LastMessageBox } from './helperStyles'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import storeeDollIcon from 'assets/storee_man_yellow.png'
import { getChatInsightMessage } from './utils'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { getTextDirection } from 'utils'

export const StoreeDollPreview = () => {
  const navigate = useNavigate()
  const statuses = useSelector((state: RootState) => state.tasks?.allTasks?.[0]?.statuses)
  const theme = useSelector((state: RootState) => state.general.theme)
  const handleClick = () => {
    navigate(keys.ROUTE_NAMES.IMPROVING_SALES)
  }
  const todoTasksCount = statuses?.[keys.TASK_STATUSES.TODO.value]
  const avatarImg = theme?.avatarImgUrl ?? storeeDollIcon

  return (
    <ChatPreviewContainer mt={'24px'}>
      {todoTasksCount > 0 && (
        <LastMessageBox dir={getTextDirection(getChatInsightMessage(todoTasksCount))}>
          <ChatPreviewText>
            <LocaleText text={getChatInsightMessage(todoTasksCount)} />
          </ChatPreviewText>
        </LastMessageBox>
      )}

      <Box
        border={todoTasksCount > 0 ? `2px solid ${theme?.elementsColor}` : '2px solid  #A0AEC0'}
        bg="white"
        borderRadius="50%"
        cursor="pointer"
        onClick={handleClick}
      >
        <Avatar w="60px" h="60px" background="transparent" src={avatarImg} />
      </Box>
      <ChatPreviewTitle isTruncated dir={getTextDirection(keys.COMPANY_NAME)}>
        {keys.COMPANY_NAME}
      </ChatPreviewTitle>
    </ChatPreviewContainer>
  )
}
