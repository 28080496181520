import { Avatar, Flex, IconButton } from '@chakra-ui/core'
import React, { memo, useState } from 'react'
import { MessageInputProps } from './types'
import { useSelector } from 'react-redux'
import { CustomTextareaAutosize } from 'components/helperStyles'
import { IoSend } from 'react-icons/io5'
import Loader from './Loader'
import { RootState } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'

export const MessageInput = memo(({ handleSend, loading }: MessageInputProps) => {
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const { uid } = useSelector((state: RootState) => state.auth)
  const isRtl = useSelector(isRtlSelector)
  const [userInput, setUserInput] = useState('')

  return (
    <Flex width="100%" mx="auto" alignItems="center" style={{ columnGap: '25px' }}>
      <Avatar src={retailUsersObject?.[uid!]?.profile_img_url} />
      <CustomTextareaAutosize
        placeholder="Type your message..."
        value={userInput}
        onChange={(e) => setUserInput(e.target.value)}
        maxRows={4}
        style={{ boxShadow: '0px 0px 7px 0px rgba(218,63,140,1)', paddingRight: '45px' }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSend(userInput)
            setUserInput('')
          }
        }}
      />
      {!loading ? (
        <IconButton
          _active={{ bg: 'transparent' }}
          _hover={{ bg: 'transparent' }}
          _focus={{ boxShadow: 'none' }}
          color="#DA3F8C"
          fontSize="2rem"
          bg="transparent"
          onClick={() => {
            handleSend(userInput)
            setUserInput('')
          }}
          isDisabled={userInput.length === 0}
          icon={IoSend}
          aria-label={'send'}
          minW="unset"
          transform={isRtl ? 'rotate(180deg)' : 'none'}
        />
      ) : (
        <Loader />
      )}
    </Flex>
  )
})
