import React, { memo } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { LikeButton } from './LikeButton'
import { CommentButton } from './CommentButton'
import { IconList } from './IconList'
import colors from '../../constants/colors'
import { ViewButton } from './ViewButton'
import { Post } from 'redux/reducers/feed'
import 'video-react/dist/video-react.css'
import { PostTitle } from './PostTitle'

interface CollapsedPost extends Post {
  commentsCounter: number
  commentsCounterLoading: boolean
  isShowLikeButton: boolean
  isLikedPost: boolean
  onViewModalOpen: () => void
  handleLikesModal?: () => void
  requireToUpdateViews: string[]
  isDoublePost?: boolean
  isAllPostCollapsed?: boolean
}
export const CollapsedPost = memo(
  ({
    commentsCounter,
    commentsCounterLoading,
    isShowLikeButton,
    isLikedPost,
    onViewModalOpen,
    handleLikesModal,
    requireToUpdateViews,
    isDoublePost,
    isAllPostCollapsed,
    ...post
  }: CollapsedPost) => {
    const uid = useSelector((state: RootState) => state.auth.uid)
    const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
    return (
      <>
        <Flex flexDir="column" px="22px" color={colors.greyMain} fontWeight={500}>
          {post.title && (
            <Flex alignItems="center">
              <Box
                wordBreak={'break-word'}
                fontSize="14px"
                px="30px"
                style={{
                  display: '-webkit-box',
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                <PostTitle
                  post={post}
                  retailUsersObject={retailUsersObject}
                  isShowPreview={false}
                  isDoublePost={isDoublePost!}
                />
              </Box>
            </Flex>
          )}
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" w="100%" pt="3px" px="4px">
          <Box style={{ cursor: 'pointer' }} w="40%">
            <IconList audios={post.audios} images={post.images} videos={post.videos} files={post.files} />
          </Box>

          <Flex justifyContent={'flex-end'} alignItems="center" w="60%">
            <LikeButton
              created_by={post.created_by}
              likes_counter={post.likes_counter}
              liked={post.liked}
              isShowLikeButton={isShowLikeButton}
              isLikedPost={isLikedPost}
              handleLikesModal={handleLikesModal}
              isDoublePost={isDoublePost}
              isAllPostCollapsed={isAllPostCollapsed}
            />
            {post.viewed !== undefined && post.views_counter !== undefined && (
              <ViewButton
                viewed={post.viewed}
                views_counter={post.views_counter}
                created_by={post.created_by}
                openModal={onViewModalOpen}
                isShowViewIcon={requireToUpdateViews.includes(uid!) && post.created_by !== uid}
                isViewed={requireToUpdateViews.includes(uid!) && post.viewed[uid!]}
                isDoublePost={isDoublePost}
                isAllPostCollapsed={isAllPostCollapsed}
              />
            )}

            <CommentButton
              postId={post.post_id}
              commentsCounterLoading={commentsCounterLoading}
              showCommentsSection={false}
              commentsCounter={commentsCounter}
              isDoublePost={isDoublePost}
              isAllPostCollapsed={isAllPostCollapsed}
              isViewed={
                post.viewed[uid!] ||
                (requireToUpdateViews.includes(uid!) && post.created_by === uid!) ||
                !requireToUpdateViews.includes(uid!)
              }
            />
          </Flex>
        </Flex>
      </>
    )
  }
)
