import { Box, Button, Divider, Flex, IconButton, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { useMemo, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import BaseModal from '../BaseModal'
import InputSearch from '../GroupsTree/InputSearch'
import { NoResults } from '../GroupsTree/NoResults'
import { ITagsList } from './types'
import { TagNode } from './TagNode'
import { SelectedItemsCount } from '../UsersListModal/SelectedCountOfUsers'
import keys from 'constants/keys'

export const TagsList = ({
  isOpen,
  onClose,
  setShowTagsList,
  setViewOption,
  setSelectedTagsIds,
  selectedTagsIds,
  tags,
}: ITagsList) => {
  const { theme, locale } = useSelector((state: RootState) => state?.general)
  const [checkedIds, setCheckedIds] = useState<string[]>(selectedTagsIds || [])
  const [searchValue, setSearchValue] = useState<string>('')
  const isHebrew = locale === 'he'
  const isRtl = isHebrew ? 'rtl' : 'ltr'
  const post_select_tags_search = useLocaleText('post_select_tags_search')
  const t_retail_tags = useLocaleText('t_retail_tags')

  const handleCheckIds = (uid: string) => {
    if (checkedIds.includes(uid)) {
      setCheckedIds(checkedIds.filter((id: any) => id !== uid))
    } else {
      setCheckedIds([...checkedIds, uid])
    }
  }
  const searchResults = useMemo(() => {
    return tags?.filter((tag) => tag.name?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()))
  }, [searchValue, tags])

  const onSubmit = () => {
    if (searchValue) {
      setSearchValue('')
    } else {
      setSelectedTagsIds(checkedIds)
      setShowTagsList?.(false)
      setViewOption?.(null)
    }
  }
  const handleSelectAll = () => {
    if (checkedIds.length === tags?.length) {
      setCheckedIds([])
    } else {
      const allUids = tags?.map((tag) => tag.sk)
      setCheckedIds(allUids)
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      headerContent={
        <>
          <Flex alignItems="center" justifyContent="space-between" dir={isRtl}>
            <Box
              onClick={() => {
                setShowTagsList?.(false)
                setViewOption?.(null)
              }}
              mx="4"
              position="absolute"
            >
              <IconButton
                aria-label="close groups"
                icon={BsArrowLeftShort}
                borderRadius="50%"
                style={{ color: colors.greyMain }}
                fontSize="32px"
                transform={`rotate(${+isHebrew * 180}deg)`}
              />
            </Box>
            <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
              <LocaleText text={t_retail_tags} />
            </Text>
          </Flex>
          <Divider />
          <Flex flexDirection="column" px={6} mt="20px">
            <InputSearch
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={post_select_tags_search}
            />
            {searchValue ? (
              <Text color={colors.greyDark} fontSize="16px" fontWeight="700" my="17px" textAlign="start">
                <LocaleText text="select_group_search_results" />
              </Text>
            ) : (
              <Flex justifyContent="space-between" alignItems="center" mt="17px">
                <SelectedItemsCount data={tags} checkedIds={checkedIds} />
                <Button
                  variant="outline"
                  borderRadius="16px"
                  py="6px"
                  height="auto"
                  onClick={handleSelectAll}
                  minW="90px"
                >
                  {checkedIds.length === tags.length ? (
                    <LocaleText text="create_task_post_assignee_clear_button" />
                  ) : (
                    <LocaleText text="create_task_post_assignee_select_all_button" />
                  )}
                </Button>
              </Flex>
            )}
          </Flex>
        </>
      }
      bodyContent={
        <>
          {tags && (
            <Flex
              width="100%"
              flexDirection="column"
              paddingRight="50px"
              paddingLeft="50px"
              minHeight="300px"
              mt="10px"
            >
              {searchValue ? (
                <>
                  {searchResults?.length ? (
                    searchResults.map((result, index) => {
                      const colorIndex = index % keys.IMPROVING_TASKS_COLORS.length
                      const tagColor = keys.IMPROVING_TASKS_COLORS[colorIndex]
                      return (
                        <Box key={result.sk}>
                          <TagNode
                            id={result.sk}
                            title={result.name}
                            checkedIds={checkedIds}
                            handleCheckIds={handleCheckIds}
                            tagColor={tagColor}
                          />
                        </Box>
                      )
                    })
                  ) : (
                    <NoResults />
                  )}
                </>
              ) : (
                <>
                  {tags.map((tag, index) => {
                    const colorIndex = index % keys.IMPROVING_TASKS_COLORS.length
                    const tagColor = keys.IMPROVING_TASKS_COLORS[colorIndex]
                    return (
                      <Box key={tag.sk}>
                        <TagNode
                          id={tag.sk}
                          title={tag.name}
                          checkedIds={checkedIds}
                          handleCheckIds={handleCheckIds}
                          tagColor={tagColor}
                        />
                      </Box>
                    )
                  })}
                </>
              )}
            </Flex>
          )}
        </>
      }
      footerContent={
        <Flex justifyContent="flex-end" background="white" px="6">
          {searchValue && searchResults.length === 0 ? (
            <></>
          ) : (
            <Button
              borderRadius="20px"
              onClick={onSubmit}
              color="white"
              textTransform="uppercase"
              background={theme?.elementsColor}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="select_group_select_button" />
            </Button>
          )}
        </Flex>
      }
    />
  )
}
