import React from 'react'
import { Button, Tooltip } from '@chakra-ui/core'

interface IProps {
  children: React.ReactNode
  tooltipText: string
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  isDisabled?: boolean
}

export const InputTooltip = ({ tooltipText, children, onClick, isDisabled }: IProps) => {
  return (
    <Tooltip aria-label={tooltipText} label={tooltipText} fontSize="14px" p="10px" placement="top" closeOnClick>
      <Button
        onClick={onClick}
        px="1"
        mx="2px"
        cursor="pointer"
        isDisabled={isDisabled}
        variant="ghost"
        _focus={{ borderColor: 'transparent' }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}
