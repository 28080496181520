import { useCallback } from 'react'
import { PinType, getPostsIdsFromLocalStorage, pinPost, unpinPost } from '../utils'
import { useDispatch } from 'react-redux'
import { pinManager, setPosts, setSearchPosts } from 'redux/actions/feed'
import { Post } from 'redux/reducers/feed'

interface UsePinnedPostsProps {
  myUID: string
  pin_created_at_ts: number | undefined
  created_at_ts: number
  post_id: string
  groups: string[]
  users: string[]
  is_manager_pin?: boolean
  posts: Post[]
  isSearchPage?: boolean
}
export const usePinnedPosts = ({
  myUID,
  pin_created_at_ts,
  created_at_ts,
  post_id,
  groups,
  users,
  is_manager_pin,
  posts,
  isSearchPage,
}: UsePinnedPostsProps) => {
  const dispatch = useDispatch()

  const handlePinPost = useCallback(
    (postId: string, pinType: PinType) => {
      const pinedPostsObject = getPostsIdsFromLocalStorage()
      const isPostPinned = pinedPostsObject[myUID!]?.includes(postId) || is_manager_pin
      const updatedPosts = isPostPinned
        ? unpinPost(posts, postId, myUID!, pinType)
        : pinPost(posts, postId, myUID!, pinType)

      dispatch(isSearchPage ? setSearchPosts(updatedPosts) : setPosts({ posts: updatedPosts, isRefresh: true }))
      if (pinType === 'is_manager_pin') {
        const pinStatus = isPostPinned ? false : true
        const createdAtTs = isPostPinned ? pin_created_at_ts : created_at_ts
        dispatch(
          pinManager({
            post_id,
            is_pin: pinStatus,
            groups,
            users,
            created_at_ts: createdAtTs,
            isSearchPage,
          })
        )
      }
    },
    [created_at_ts, dispatch, groups, isSearchPage, is_manager_pin, myUID, pin_created_at_ts, post_id, posts, users]
  )
  return { handlePinPost }
}
