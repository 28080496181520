import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Avatar, Box, Divider, Flex, Text } from '@chakra-ui/core'
import BaseModal from 'components/CommonComponents/BaseModal'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'
import useLocaleText from 'components/useLocaleText'
import { ReactComponent as ViewedIconActive } from '../../assets/visibility_black_24dp_filled.svg'

interface IViewsModal {
  isOpen: boolean
  onClose: () => void
  views_counter: number
  viewed: { [key: string]: boolean }
  created_by: string
}
interface IListItem {
  firstName?: string
  lastName?: string
  uid: string
  isViewed: boolean
  iconColor?: string
}
const ListItem = ({ firstName, lastName, uid, isViewed, iconColor }: IListItem) => {
  const avatar = useAvatar(uid)

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Avatar src={avatar} size="sm" style={{ width: '40px', height: '40px' }} />
          <Box fontWeight="600" mx="20px">
            {`${firstName} ${lastName ? lastName : ''}`}
          </Box>
        </Flex>
        {isViewed && <ViewedIconActive width="25px" height="25px" fill={iconColor} />}
      </Flex>
      <Divider />
    </>
  )
}
export const ViewsModal = ({ isOpen, onClose, views_counter, viewed, created_by }: IViewsModal) => {
  const { retail_users } = useSelector((state: RootState) => state.config.config)
  const theme = useSelector((state: RootState) => state.general.theme)
  const modalHeaderLiteral = useLocaleText('Viewed to')
  const sentToLiteral = useLocaleText('sent_to')

  const permittedUsers = useMemo(() => {
    return retail_users.filter((retUser) => Object.keys(viewed).includes(retUser.uid!) && retUser.uid !== created_by)
  }, [created_by, retail_users, viewed])

  const sortedPermittedUsersByViews = useMemo(() => {
    return permittedUsers.sort((a, b) => {
      if (viewed[a.uid] && !viewed[b.uid]) {
        return -1
      } else if (!viewed[a.uid] && viewed[b.uid]) {
        return 1
      }
      return 0
    })
  }, [permittedUsers, viewed])

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      headerContent={<>{modalHeaderLiteral}</>}
      bodyContent={
        <Box px="6">
          <Flex justifyContent="space-between" alignItems="center" color={colors.greyMain}>
            <Flex alignItems="center" justifyContent="space-between" w="100%">
              <Flex style={{ columnGap: '10px' }} alignItems="center">
                <ViewedIconActive width="25px" height="25px" fill={theme?.elementsColor} />
                <Box>{views_counter}</Box>
              </Flex>
              <Text>
                {sentToLiteral}: {permittedUsers.length}
              </Text>
            </Flex>
          </Flex>
          <Divider />
          {sortedPermittedUsersByViews &&
            sortedPermittedUsersByViews.map((user) => (
              <ListItem
                key={user.uid}
                firstName={user.first_name}
                lastName={user.last_name}
                uid={user.uid}
                isViewed={viewed[user.uid]}
                iconColor={theme?.elementsColor}
              />
            ))}
        </Box>
      }
    />
  )
}
