import { Button, Divider, Flex, Icon, IconButton, Input, Stack, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React, { memo } from 'react'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { SubTask } from './utils'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'

interface IProps {
  label: string
  id: number
  placeholder: string
  subTasks: SubTask[]
  index: number
  errors: { [key: string]: boolean }
  onAddSubtask: (id: number) => void
  onRemoveSubtask: (fieldId: number, index: number) => void
  onChangeSubtask: (fieldId: number, index: number, value: string) => void
  onChangeSubtaskTitle: (fieldId: number, index: number, value: string) => void
  handleBlur: () => void
}
export const SubtaskField = memo(
  ({
    label,
    subTasks,
    id,
    index,
    placeholder,
    onAddSubtask,
    onRemoveSubtask,
    onChangeSubtask,
    onChangeSubtaskTitle,
    handleBlur,
    errors,
  }: IProps) => {
    const { isLoading } = useSelector((state: RootState) => state.tasks_v2)
    const t_incorrent_link = useLocaleText('t_incorrent_link')
    const t_empty_field = useLocaleText('t_empty_field')
    const inputPlaceholder = useLocaleText(placeholder)
    return (
      <Stack direction="column">
        <Button
          onClick={() => onAddSubtask(id)}
          flexDir="row"
          mb="0px"
          cursor="pointer"
          w="max-content"
          bg="white"
          borderRadius="10px"
          border="none"
          mt={index === 0 ? '20px' : '10px'}
          px="10px"
          _focus={{ boxShadow: 'none' }}
        >
          <Flex alignItems="center" fontSize="16px" fontWeight="bold">
            <Icon name="add" />
            <Text mx="5px" w="max-content">
              <LocaleText text={label} />
            </Text>
          </Flex>
        </Button>

        {subTasks.map((subTask, index) => {
          return (
            <Flex flexDir="column" key={index} w="100%" h="100%" my="10px">
              <Flex>
                <Input
                  key={index}
                  isDisabled={isLoading}
                  placeholder={inputPlaceholder}
                  type="text"
                  borderRadius="10px"
                  px="10px"
                  value={subTask.title}
                  onChange={(e) => onChangeSubtask(id, index, (e.target as HTMLInputElement).value)}
                  onBlur={handleBlur}
                  borderColor={!subTask.title ? 'red.500' : 'gray.200'}
                />
                <IconButton
                  borderRadius="full"
                  aria-label="Remove option"
                  icon={IoClose}
                  mx="10px"
                  isDisabled={isLoading}
                  onClick={() => onRemoveSubtask(id, index)}
                />
              </Flex>
              {!subTask.title && (
                <Text color="red.500" fontSize="12px" px="10px" pt="5px">
                  {t_empty_field}
                </Text>
              )}
              {'external_link' in subTask && (
                <>
                  <Input
                    mb="10px"
                    isDisabled={isLoading}
                    placeholder={subTask.placeholder}
                    type="text"
                    px="10px"
                    marginBottom="0px"
                    value={subTask.external_link}
                    onChange={(e) => onChangeSubtaskTitle(id, index, (e.target as HTMLInputElement).value)}
                    onBlur={handleBlur}
                    border="none"
                    borderBottom="1px solid"
                    borderBottomColor={errors[`${id}-${index}`] ? 'red.500' : 'gray.200'}
                    borderRadius="0"
                    _focus={{ boxShadow: 'none' }}
                  />
                  <>
                    {errors[`${id}-${index}`] && (
                      <Text color="red.500" fontSize="12px" px="10px" pt="5px">
                        {t_incorrent_link}
                      </Text>
                    )}
                  </>
                </>
              )}
            </Flex>
          )
        })}
        <Divider />
      </Stack>
    )
  }
)
