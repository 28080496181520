import { Button } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import React from 'react'
import { useDispatch } from 'react-redux'
import { handleConfig, handleSetTaskPermittedGroup, handleUserWritePermitted } from 'redux/actions/config'

export const ConfigErrorHandler = ({ status }: { status: number | null }) => {
  const dispatch = useDispatch()
  const try_again = useLocaleText('try_again')
  const fetchConfig = () => {
    try {
      dispatch(handleConfig())
      dispatch(handleSetTaskPermittedGroup())
      dispatch(handleUserWritePermitted())
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }
  const handleTryAgain = () => {
    fetchConfig()
  }

  const getTitleError = (status: number | null) => {
    switch (status) {
      case 401:
        return keys.RESPONSE_ERROR_STATUSES.NO_AUTHORIZATION
      case 403:
        return keys.RESPONSE_ERROR_STATUSES.NO_PERMISSION
      case 404:
        return keys.RESPONSE_ERROR_STATUSES.NOT_FOUND
      case 500:
        return keys.RESPONSE_ERROR_STATUSES.INTERNAL_SERVER_ERROR
      default:
        return 'Something went wrong'
    }
  }
  return (
    <LoginWrapper title={getTitleError(status)} isShowUserMenu={true}>
      <Button
        width="100%"
        borderRadius="20px"
        mt={4}
        variantColor="pink"
        variant="outline"
        type="submit"
        style={{ columnGap: '10px' }}
        onClick={handleTryAgain}
      >
        <LocaleText text={try_again} />
      </Button>
    </LoginWrapper>
  )
}
