import React from 'react'
import { Box, Button, Flex } from '@chakra-ui/core'
import { GoTrashcan } from 'react-icons/go'

import { AudioPlayer } from 'components/AudioPlayer'
import colors from 'constants/colors'

interface IProps {
  audios: string[]
  onDelete?: (audio: string) => void
}

export const PostAudioList = ({ audios, onDelete }: IProps) => {
  return (
    <Box>
      {audios.map((audio) => (
        <Flex key={audio} alignItems="center" mt="15px">
          <AudioPlayer src={audio} />
          {onDelete && (
            <Button display="flex" variant="unstyled" onClick={() => onDelete(audio)}>
              <GoTrashcan fill={colors.red} size="25px" />
            </Button>
          )}
        </Flex>
      ))}
    </Box>
  )
}
