import isSolid from 'is-solid'
import { AppTheme, GeneralState } from '../../constants/interfaces'

export const actionTypes = {
  SET_DATA: '[GENERAL] SET_DATA',
  SET_DATE: '[GENERAL] SET_DATE',
  SET_SEARCH_INPUT: '[GENERAL] SET_SEARCH_INPUT',
  SET_LOCALE: '[GENERAL] SET_LOCALE',
  SET_SELECTED_ROUTE: '[GENERAL] SET_SELECTED_ROUTE',
  SET_RESPONSE_STATUS: '[GENERAL] SET_RESPONSE_STATUS',
  SET_THEME_CONFIG: '[GENERAL] SET_THEME_CONFIG',
}
export const setResponseStatus = (isResponseSuccess: boolean | null) => ({
  type: actionTypes.SET_RESPONSE_STATUS,
  payload: isResponseSuccess,
})

export const setData = (data: object) => ({
  type: actionTypes.SET_DATA,
  data,
})

export const setDate = (selectedDate: GeneralState) => ({
  type: actionTypes.SET_DATE,
  selectedDate,
})

export const setSearchInput = (searchInput: GeneralState) => ({
  type: actionTypes.SET_SEARCH_INPUT,
  searchInput,
})

export const setLocale = (locale: string) => ({
  type: actionTypes.SET_LOCALE,
  locale,
})

export const setSelectedRoute = (selectedRoute: string) => ({
  type: actionTypes.SET_SELECTED_ROUTE,
  selectedRoute,
})

export const handleSearchInput = (searchInput: GeneralState) => (dispatch: any) => {
  if (!isSolid(searchInput)) return
  dispatch(setSearchInput(searchInput))
}
export const setThemeConfig = (themeConfig: AppTheme) => ({
  type: actionTypes.SET_THEME_CONFIG,
  payload: themeConfig,
})
