import {
  Box,
  Flex,
  Input,
  Button,
  Tag,
  ModalCloseButton,
  Text,
  Tooltip,
  Link as ChakraLink,
  Checkbox,
} from '@chakra-ui/core'
import { Link, NavLink } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { Status } from 'config/types'
import TextareaAutosize from 'react-textarea-autosize'

import colors from 'constants/colors'
import { ICustomTextareaAutosize } from 'constants/interfaces'
import InfiniteScroll from 'react-infinite-scroller'

// TODO: remove after change at overview detail page
export const CustomTag = styled(Tag)<{ locale: string }>`
  font-family: 'Asap', sans-serif;
  font-weight: 400;
  background-color: white;
  color: black;
  font-size: 0.875rem;
  line-height: 1.75rem;
  padding: 0px 17px;
  border-radius: 16px;
  border: solid 1px black;
  margin-right: ${(props) => (props.locale === 'he' ? '0' : '10px')};
  margin-left: ${(props) => (props.locale === 'he' ? '10px' : '0')};

  @media (max-width: 1000px) {
    white-space: nowrap;
  }
`

export const CustomTagButton = styled(Button)<{ locale: string; color?: string; border?: string; bgColor?: string }>`
  font-family: 'Asap', sans-serif;
  font-weight: 400;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#e8ecfd')};
  color: ${(props) => (props.color ? props.color : '#667bd5')};
  font-size: 0.875rem;
  line-height: 1.75rem;
  padding: 0px 17px;
  border-radius: 16px;
  border: ${(props) => (props.border ? props.border : 'solid 1px #8ca0f3')};
  margin-right: ${(props) => (props.locale === 'he' ? '0' : '10px')};
  margin-left: ${(props) => (props.locale === 'he' ? '10px' : '0')};
  height: 32px;

  @media (max-width: 1000px) {
    white-space: nowrap;
  }
`

export const CardWrap = styled(Box)<{
  backGround?: string
  w?: string
  isAllpostCollapsed?: boolean
  doublePost?: boolean
  boxShadowColor?: string
}>`
  background-color: ${({ backGround }) => backGround || 'white'};
  border-radius: ${({ isAllpostCollapsed }) => (isAllpostCollapsed ? '0' : '12px')};
  position: relative;
  margin-bottom: ${({ isAllpostCollapsed }) => (isAllpostCollapsed ? '5px' : '10px')};
  padding: 10px;
  min-height: ${({ isAllpostCollapsed, doublePost }) => (isAllpostCollapsed && doublePost ? '100px' : 'unset')}
  width: ${({ w }) => (w ? w : '100%')};
  &:hover,
  &:focus {
    box-shadow: ${({ isAllpostCollapsed, doublePost, boxShadowColor }) =>
      isAllpostCollapsed && !doublePost ? `0 0 2px 0px ${boxShadowColor}` : 'none'};
  }
`
export const CardOverlay = styled(Flex)<{ background?: string }>`
  transition: all 350ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  z-index: 100;
  background: ${({ background }) => background || 'rgba(0, 0, 0, 0.64)'};
  color: white;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const CreateTaskInput = styled(Input)`
  &::-webkit-input-placeholder {
    font-weight: 400;
    color: ${colors.greyDark};
  }
  &::-moz-placeholder {
    font-weight: 400;
    color: ${colors.greyDark};
  }
  &:-moz-placeholder {
    font-weight: 400;
    color: ${colors.greyDark};
  }
  &:-ms-input-placeholder {
    font-weight: 400;
    color: ${colors.greyDark};
  }
`

export const PostDeleteButton = styled.div`
  background: none;
  appereance: none;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: ${colors.greyLight};
  position: absolute;
  top: 5px;
  right: 10px;
  transition: 0.3s ease-in-out;
  z-index: 5;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${colors.greyMedium};
  }

  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2px;
    height: 13px;
    border-radius: 1px;
    background-color: ${colors.greyDark};
  }

  &:after {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &:before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
`

const underlinedButtonStyles = (active: string, bottom?: number) => {
  return `
  font-family: Asap, sans-serif;
  font-weight: 400;
  color: ${active === 'true' ? colors.violetLight : 'black'};
  font-size: 16px;
  line-height: 1.71;
  position: relative;
  transition: .2s ease-in-out;
  padding: 2px 0;
  height: auto;
  max-height: 21px;
  background-color: transparent;


  &:hover, &:focus {
    background-color: transparent;
    color: black;
    text-decoration: none;
    outline: none;
  }
  `
}

export const CustomLink = styled(Link)<{ active: string; bottom?: number }>`
  ${(props) => underlinedButtonStyles(props.active, props.bottom)}
`

export const UnderlinedButton = styled(Button)<{ active: string; bottom?: number }>`
  ${(props) => underlinedButtonStyles(props.active, props.bottom)}
`

export const CustomOverflow = styled(Box)<{ locale: string; showScroll?: boolean }>`
  width: 100%;
  // animation: background 5s infinite alternate;
  overflow-y: auto;
  mask-image: ${({ showScroll = true, locale }) =>
    showScroll &&
    (locale === 'he'
      ? `linear-gradient(to top, transparent, white),
linear-gradient(to right, transparent 12px, white 12px)`
      : `linear-gradient(to top, transparent, white),
linear-gradient(to left, transparent 12px, white 12px)`)};
  mask-size: 101% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: ${({ showScroll = true, locale }) =>
    showScroll &&
    (locale === 'he'
      ? `linear-gradient(to top, transparent, white),
linear-gradient(to right, transparent 12px, white 12px)`
      : `linear-gradient(to top, transparent, white),
linear-gradient(to left, transparent 12px, white 12px)`)};
  -webkit-mask-size: 101% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;

  &:hover {
    -webkit-mask-position: left top;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.greyLight};
    outline: none;
    width: 8px;
    border-radius: 6px;
  }
`

const blueButtonStyles = (locale: string, color?: string) => {
  return `
  font-family: 'Asap', sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  // box-shadow: 0 2px 7px 0 rgba(187, 187, 187, 0.25);
  color: ${color ? color : '#8ca0f3'};
  text-decoration: none;
  display: inline-flex;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.36;
  height: max-content;
  max-width: 200px;
  max-height: 40px;
  position: relative;
  padding: 0;
  margin-right: ${locale !== 'he' ? '16px' : '0'};
  margin-left: ${locale === 'he' ? '16px' : '0'};
  
  svg {
    overflow: hidden;
    position: absolute;
    right: ${locale !== 'he' ? '-16px' : 'auto'};
    left: ${locale === 'he' ? '-16px' : 'auto'};
    transform: ${locale === 'he' ? 'rotate(180deg)' : ''};
  }
  
  path {
    transition: .2s ease-in-out;
  }
  
  &:hover, &:focus {
    color: ${color ? color : '#8ca0f3'};
    text-decoration: none;
  
    path {
      animation: .4s ease-in-out arrow-move;
    }
  
    @keyframes arrow-move{
      from {
        transform: translateX(0px);
      }
      50% {
        transform: translateX(50px) translateY(0px);
      }
      51% {
        transform: translateY(-50px);
      }
      51% {
        transform: translateX(-50px) translateY(0px);
      }
      to {
        transform: translateX(0px);
      }
    }
  }
  `
}

export const BlueButton = styled(Button)<{ locale: string; color?: string }>`
  ${(props) => blueButtonStyles(props.locale, props.color)}
`
export const BlueButtonLink = styled(Link)<{ locale: string }>`
  ${(props) => blueButtonStyles(props.locale)}
`

export const FilterItemWrapper = styled(Flex)`
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    outline: none;
    background-color: rgba(140, 160, 243, 0.17);
  }
`

export const StyledModalClose = styled(ModalCloseButton)`
  height: 36px;
  width: 36px;
  background-color: ${colors.greyMedium};
  transition: 0.3s ease-in-out;
  border-radius: 100%;
  z-index: 1;

  &:hover,
  &:focus {
    background-color: ${colors.greyMedium2};
    box-shadow: none;
  }

  svg {
    stroke: #90949c;
  }
`

interface IMiniDrawer {
  isShow: boolean
}

export const MiniDrawer = styled('div')<IMiniDrawer>`
  position: absolute;
  z-index: 2000;
  background: white;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding-top: 25px;
  padding-bottom: 25px;
  visibility: ${(props) => (props.isShow ? 'visible' : 'hidden')};
  transition: all 250ms ease-in-out;
  transform: translateX(${(props) => (props.isShow ? '0' : '-100%')});
`
export const ButtonBoxWrapper = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: center;

  overflow: hidden;

  .grid-container {
    padding: 0;
  }

  &:hover {
    .postModal__ButtonBox {
      transform: translateY(0);
    }
  }
`

export const ButtonBox = styled(Flex)`
  width: calc(100% - 20px);
  justify-content: space-between;
  flex-direction: row;
  transform: translateY(-100px);
  transition: 0.3s ease-in-out;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 200;
`

export const TasksList = styled(Box)`
  border-radius: 24px;
  background-color: #ffffff;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
`

export const StyledMenuButton = styled(Button)<{ statusBGColor?: string; status?: Status; statusBorderColor?: string }>`
  font-size: 14px;
  font-weight: 400;
  height: 2rem;
  border-radius: 20px;
  padding: 0 20px;
  border-width: 1px;
  background-color: ${({ statusBGColor }) => statusBGColor};
  color: ${({ status }) => (status ? colors.tealLight : colors.blackLight)};
  border-color: ${({ statusBorderColor }) => statusBorderColor};
`

export const CustomTextareaAutosize = styled(TextareaAutosize)<ICustomTextareaAutosize>`
  font-size: 1.125rem;
  background-color: white;
  min-height: ${(props) => (props.minHeight ? props.minHeight : '3rem')};
  width: 100%;
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : '0 2px 4px 0 rgba(0, 0, 0, 0.05)')};
  color: ${({ color }) => (color ? color : colors.greyMain)};
  padding: 10px 20px;
  resize: none;
  overflow: auto;
  transition: 0.1s ease-in-out;
  border-top-left-radius: ${(props) => props.borderTopLeftRadius || '12px'};
  border-top-right-radius: ${(props) => props.borderTopRightRadius || '12px'};
  border-bottom-left-radius: ${(props) => props.borderBottomLeftRadius || '12px'};
  border-bottom-right-radius: ${(props) => props.borderBottomRightRadius || '12px'};

  &:focus {
    outline: none;
  }
`

export const BlockTitle = styled(Text)<{ margin?: string }>`
  margin: ${({ margin }) => margin ?? '0 9px 12px'};
  color: ${colors.greyMain};
  font-size: 0.875rem;
  font-weight: 500;
`

export const CustomText = styled(Text)`
  max-height: 20px;
`

export const CustomTextArea = styled(TextareaAutosize)<{ height?: string }>`
  background-color: ${colors.greyMedium};
  width: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  transition: all 0.2s ease 0s;
  outline: none;
  appearance: none;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 7px;
  padding-bottom: 8px;
  height: ${({ height }) => (height ? height : '2.5rem')};
  border-radius: 12px;
  min-height: 32px;
  line-height: 1.5rem;
  resize: none;
  overflow: hidden;
  &::placeholder {
    font-size: 14px;
  }
`
export const CustomFieldWrapper = styled(Flex)`
  border-radius: 12px;
  background-color: ${colors.greyMedium};
`

export const ImageWrapper = styled(Box)`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  &:hover ${CardOverlay} {
    opacity: 1;
    visibility: visible;
  }
`

export const TaskWrapper = styled(Link)`
  display: block;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  background-color: white;
  border-radius: 12px;
  overflow-x: hidden;
  margin-bottom: 1.5rem;
  height: 88px;
  color: ${colors.greyMain};
  font-size: 0.875rem;
  font-weight: 500;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  }
`
export const PollTaskWrapper = styled(Flex)`
  display: block;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  background-color: white;
  border-radius: 12px;
  overflow-x: hidden;
  margin-bottom: 1.5rem;
  height: 88px;
  color: ${colors.greyMain};
  font-size: 0.875rem;
  font-weight: 500;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  }
`
export const TaskWrapperExternalLink = styled(ChakraLink)`
  display: block;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  background-color: white;
  border-radius: 12px;
  overflow-x: hidden;
  margin-bottom: 1.5rem;
  height: 88px;
  color: ${colors.greyMain};
  font-size: 0.875rem;
  font-weight: 500;
  transition: 0.2s ease-in-out;

  &:hover,
  &:focus {
    color: inherit;
    text-decoration: none;
    transform: translateY(-4px);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  }
`

export const DotsWrapper = styled(Box)`
  width: 40px;
  height: 30px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  border-radius: 5px;

  &:hover,
  &:focus,
  &.active {
    outline: none;
    background-color: rgba(140, 160, 243, 0.17);
  }
`
export const Dots = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${colors.greyDark};

  &::before,
  &:after {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 100%;
    position: absolute;
    background-color: ${colors.greyDark};
  }

  &::before {
    left: -10px;
  }

  &::after {
    right: -10px;
  }
`

export const IconWrapper = styled(Flex)`
  background-color: ${colors.greyMedium};
  width: 36px;
  height: 36px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
`

export const SubtaskWrapper = styled(Flex)<{
  showBoxShadow?: boolean
  mb?: string
  maxHeight?: string
  height?: string
}>`
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-between;
  background: white;
  margin-bottom: ${(props) => props.mb};
  max-height: ${(props) => props.maxHeight || 'auto'};
  cursor: pointer;
  border-radius: 12px;
  box-shadow: ${({ showBoxShadow }) => (showBoxShadow ? '0 2px 8px 0 rgba(187,187,187,0.25)' : null)};
  position: relative;
  height: ${({ height }) => height || '100px'};
  &:hover {
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
  }
`

export const TaskViewsListWrapper = styled(Flex)`
  flex-wrap: wrap;
  gap: 20px;
  justify-content: flex-start;
  @media (min-width: 454px) {
    justify-content: space-evenly;
  }
  @media (min-width: 560px) {
    justify-content: flex-start;
  }
`

export const TaskViewsWrapper = styled(Box)<{ isRtl: boolean }>`
  width: 710px;
`
export const RankingActionButton = styled(Button)`
  width: 110px;
  border-radius: 30px;
  background: transparent;
  color: ${colors.greyDark};
  border: 1px solid ${colors.greyMedium};
  box-shadow: 0 0 2px ${colors.greyDark};
`

export const ProductivityWrapper = styled(Flex)`
  justify-content: space-between;
  padding: 0 10px;
  width: 100%;
  align-items: center;
  @media (max-width: 1450px) {
    flex-direction: column;
    align-items: center;
    .progress-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`

export const CustomBadge = styled(Flex)<{
  bg?: string
  minW?: string
  height?: string
  fontSize?: string
  padding?: string
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${({ minW }) => minW || '35px'};
  height: ${({ height }) => height || '35px'};
  border-radius: 18px;
  padding: ${({ padding }) => (padding ? padding : '5px')};
  background: ${({ bg }) => bg || colors.red2};
  color: ${colors.tealLight};
  font-weight: bold;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '17px')};
`

export const RankingPodiumWrapper = styled(Flex)<{ isRtl: boolean }>`
  margin-left: 0;
  margin-right: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (min-width: 1400px) {
    margin-right: ${({ isRtl }) => (isRtl ? '-60px' : 0)};
    margin-left: ${({ isRtl }) => (isRtl ? 0 : '-60px')};
  }
`

export const CustomTooltip = styled(Tooltip)`
  z-index: 10000;
  position: absolute;
  padding: 15px;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  overflow-y: auto;
`

export const UserMenuItemWrapper = styled(Flex)`
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #edf2f7;
  }
`
export const ArrowRankingDown = styled(Box)`
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-bottom: 1px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 8px solid ${colors.red};
`
export const ArrowRankingUp = styled(Box)`
  width: 0;
  height: 0;
  margin-top: 1px;
  margin-bottom: 1px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-bottom: 8px solid ${colors.greenDark};
`
export const TaskItemWrapper = styled(Flex)`
  margin-bottom: 12px;
  padding: 9px 15px 9px 20px;
  border-radius: 12px;
  border: solid 1px rgba(179, 184, 196, 0.4);
  box-shadow: 0 2px 8px 0 rgba(173, 173, 173, 0.25);
  background-color: #fff;
`
export const NavItem = styled(NavLink)`
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  @media (max-width: 1000px) {
    padding: 5px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: inherit;
  }
`
export const CustomInfiniteScroll = styled(InfiniteScroll)<{ isAllPostCollapsed: boolean; isRtl: boolean }>`
  margin-left: ${({ isAllPostCollapsed, isRtl }) => (isAllPostCollapsed ? (isRtl ? '10px' : '1px') : '0')};
  margin-right: ${({ isAllPostCollapsed, isRtl }) => (isAllPostCollapsed ? (isRtl ? '1px' : '10px') : '0')};
  margin-top: 1px;
  margin-bottom: 1px;
  min-height: ${({ isAllPostCollapsed }) => (isAllPostCollapsed ? '79vh' : '100%')};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border: ${({ isAllPostCollapsed }) => (isAllPostCollapsed ? '1px solid #efefef' : 'none')};
`
export const RoundedCheckbox = styled(Checkbox)<{ color?: string }>`
  & > div {
    border-radius: 50%;
  }
  & input[type='checkbox']:checked + div {
    background: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }
`
