import { Flex, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'

export const LastMessageBox = styled(Flex)<{ dir: string }>`
  direction: ${({ dir }) => dir};
  position: absolute;
  flex-direction: column;
  justify-content: center;
  padding: 4px;
  top: -23px;
  z-index: 100;
  height: 38px;
  border-radius: 14px;
  background: white;
  width: 90px;

  box-shadow: 0 1px 3px 0 #d1d1d1;
  &:after {
    content: '';
    position: absolute;
    top: 80%;
    left: 77%;
    width: 0;
    height: 0;
    border-radius: 100%;
    border: 13px solid transparent;
    border-bottom-color: white;
    border-top: 0;
    border-right: 0;
    margin-left: -2px;
    margin-bottom: -20px;
    transform: rotate(45deg);
    box-shadow: 5px 11px 0px -4px white;
  }
`

export const ChatPreviewText = styled(Text)`
  word-break: break-word;
  display: -webkit-box;
  font-size: 11px;
  max-width: 80px;
  overflow: hidden;
  line-height: 12px;
  text-align: center;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const ChatPreviewContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 18px;
  margin-bottom: 18px;
`

export const ChatPreviewTitle = styled(Text)`
  max-width: 60px;
  text-align: center;
  font-size: 12px;
  margin-top: 12px;
  font-weight: 500;
`
