import { CleanTask, ISubtask, SubTaskImage, SubTaskProduct, SubTaskText, SubTaskVideo } from './../../config/tasksTypes'
import ImagePlaceholder from 'assets/image_placeholder.jpeg'
import {
  RootState,
  TasksOverviewStateData,
  ICheckbox,
  ITaskOverviewAnalytic,
  ITaskOverviewGroupsListItem,
  ITaskOverviewDetailGroup,
  ITaskData,
} from './../../constants/interfaces'
import { ThunkActionType } from 'config/types'
import { getOverviewAnalytical } from 'utils'
import { httpAuth } from 'config/apiClient'
import strings from 'constants/strings'
import endpoints from 'constants/endpoints'
import { defaultTaskStatuses } from 'constants/baseValues'
import keys from 'constants/keys'

export const actionTypes = {
  SET_INITIAL_DATA: '[TASKS_OVERVIEW_DETAIL] SET_INITIAL_DATA',
  UPDATE_FILTER: '[TASKS_OVERVIEW_DETAIL] UPDATE_FILTER',
  RESET_FILTER: '[TASKS_OVERVIEW_DETAIL] RESET_FILTER',
  SET_CATEGORY: '[TASKS_OVERVIEW_DETAIL] SET_CATEGORY',
  SET_TASK_ANALYTIC: '[TASKS_OVERVIEW_DETAIL] SET_TASK_ANALYTIC',
  SET_TASK_GROUPS: '[TASKS_OVERVIEW_DETAIL] SET_TASK_GROUPS',
  SET_HAS_MORE: '[TASKS_OVERVIEW_DETAIL] SET_HAS_MORE',
  SET_IS_READY: '[TASKS_OVERVIEW_DETAIL] SET_IS_READY',
  RESET_STATE: '[TASKS_OVERVIEW_DETAIL] RESET_STATE',
  SET_CURRENT_TASK: '[TASKS_OVERVIEW_DETAIL] SET_CURRENT_TASK',
  RESET_GROUPS: '[TASKS_OVERVIEW_DETAIL] RESET_GROUPS',
  SET_CURRENT_KEYS: '[TASKS_OVERVIEW_DETAIL] SET_CURRENT_KEYS',
  SET_GROUP_KEYS: '[TASKS_OVERVIEW_DETAIL] SET_GROUP_KEYS',
  SET_IS_FETCHING: '[TASKS_OVERVIEW_DETAIL] SET_IS_FETCHING',
  SET_IS_TILE_MODE: '[TASKS_OVERVIEW_DETAIL] SET_IS_TILE_MODE',
  SET_SUBTASKS: '[TASKS_OVERVIEW_DETAIL] SET_SUBTASKS',
  SET_SUBTASKS_OF_CURRENT_TASK: '[TASKS_OVERVIEW_DETAIL] SET_SUBTASKS_OF_CURRENT_TASK',
  SET_SUBTASKS_IS_LOADING: '[TASKS_OVERVIEW_DETAIL] SET_SUBTASKS_IS_LOADING',
  SET_IS_STATUS_MODAL_OPEN: '[TASKS_OVERVIEW_DETAIL] SET_IS_STATUS_MODAL_OPEN',
  SET_ACTIVE_FILTER_STATUSES: '[TASKS_OVERVIEW_DETAIL] SET_ACTIVE_FILTER_STATUSES',
}

const subtaskTypesCount = (subtasks: ISubtask, subtaskKeys: string[]) => {
  let data = {
    [keys.SUBTASK_TYPES.TEXT_SUBTASK]: {
      done: 0,
      total: 0,
    },
    [keys.SUBTASK_TYPES.PRODUCT_SUBTASK]: {
      done: 0,
      total: 0,
    },
    [keys.SUBTASK_TYPES.IMAGE_SUBTASK]: {
      done: 0,
      total: 0,
    },
    [keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK]: {
      done: 0,
      total: 0,
    },
    [keys.SUBTASK_TYPES.LINK_SUBTASK]: {
      done: 0,
      total: 0,
    },
    [keys.SUBTASK_TYPES.VIDEO_SUBTASKS]: {
      done: 0,
      total: 0,
    },
  }

  subtaskKeys.forEach((key: string) => {
    const subtask = subtasks[key]
    if (subtask.is_st_required) {
      const isDone = subtask.status !== null ? 1 : 0

      data = {
        ...data,
        [subtask.type]: {
          ...data[subtask.type],
          total: data[subtask.type].total + 1,
          done: data[subtask.type].done + isDone,
        },
      }
    }
  })
  return data
}

const findCurrentGroups = (
  overviewData: TasksOverviewStateData,
  currentKey: string,
  refId: string,
  maxGroupLevel: number
): string[] => {
  let currentKeys: string[] = []
  if (currentKey === 'all') {
    const set: Set<string> = new Set()
    const currentGroups: string[] = []
    Object.keys(overviewData!.groups!).forEach((groupKey: string) => {
      if (overviewData!.groups![groupKey] && overviewData!.groups![groupKey].groupLevel <= maxGroupLevel) {
        currentGroups.push(groupKey)
      }
    })
    currentGroups.forEach((key: string) => {
      const findTask = overviewData?.groups![key].tasks.find((task) => task.ref_id === refId)
      if (findTask) {
        set.add(key)
      }
    })
    return Array.from(set)
  }

  Object.keys(overviewData?.groups!).forEach((key: string) => {
    if (
      overviewData?.groups![key].parentId === currentKey &&
      overviewData?.groups![key].parentId !== overviewData?.groups![key].groupId &&
      overviewData?.groups![key].parentId !== overviewData?.groups![key].retailId
    ) {
      const findGroup = overviewData?.groups![key]
      if (findGroup?.groupLevel! > strings.OVERVIEW_FILTER.BY_LEVEL.STORE.value) {
        currentKeys = [...currentKeys, ...findCurrentGroups(overviewData, key, refId, maxGroupLevel)]
      } else {
        currentKeys.push(key)
      }
    }
  })

  if (
    overviewData?.groups?.[currentKey]?.groupLevel &&
    overviewData?.groups?.[currentKey]?.groupLevel > strings.OVERVIEW_FILTER.BY_LEVEL.STORE.value
  ) {
    currentKeys.push(currentKey)
  }
  return currentKeys
}

const getStatuses = (
  overviewData: TasksOverviewStateData,
  refId: string,
  currentGroupKeys: string[],
  currentCategory?: string
) => {
  const findTask = overviewData?.tasks.find((task) => task.ref_id === refId)
  const curCategory = currentCategory ? currentCategory : findTask ? findTask.category : ''
  let totalCounter = 0

  if (findTask && findTask.statusesByCategory![curCategory]) {
    const findStatuses = findTask.statusesByCategory![curCategory]
    let statusCounter = { ...defaultTaskStatuses }
    currentGroupKeys.forEach((groupKey: string) => {
      if (findStatuses[groupKey]) {
        const zeroStatus = findStatuses[groupKey][keys.TASK_STATUSES.TODO.value] || 0
        const firstStatus = findStatuses[groupKey][keys.TASK_STATUSES.INPROGRESS.value] || 0
        const secondStatus = findStatuses[groupKey][keys.TASK_STATUSES.DONE.value] || 0

        if (secondStatus > 0 && zeroStatus === 0 && firstStatus === 0) {
          statusCounter = {
            ...statusCounter,
            [keys.TASK_STATUSES.DONE.value]: statusCounter[keys.TASK_STATUSES.DONE.value] + 1,
          }
        } else if (firstStatus > 0 || (secondStatus > 0 && zeroStatus > 0)) {
          statusCounter = {
            ...statusCounter,
            [keys.TASK_STATUSES.INPROGRESS.value]: statusCounter[keys.TASK_STATUSES.INPROGRESS.value] + 1,
          }
        } else {
          statusCounter = {
            ...statusCounter,
            [keys.TASK_STATUSES.TODO.value]: statusCounter[keys.TASK_STATUSES.TODO.value] + 1,
          }
        }

        totalCounter++
      }
    })
    return {
      statusCounter,
      totalCounter,
    }
  }
}

export const getRenderGroup = (
  groupKey: string,
  overviewGroup: ITaskOverviewGroupsListItem,
  findGroup: CleanTask,
  currentCategory: string,
  subTasks: ISubtask,
  statuses: {
    [statusKey: number]: number
  },
  taskData: ITaskData
): ITaskOverviewDetailGroup => {
  const subtasksList = findGroup.categories[currentCategory].sub_tasks_order
  const images: string[] = []
  let hasImageSubtask = false
  let textSubtasks: SubTaskText[] = []
  let productSubtasks: SubTaskProduct[] = []
  let imageSubtasks: SubTaskImage[] = []
  let uploadFileSubtasks: SubTaskImage[] = []
  let videoSubtasks: SubTaskVideo[] = []

  subtasksList.forEach((key: string) => {
    const subtask = subTasks[key]
    if (subtask.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK) {
      hasImageSubtask = true
      subtask.cover_img!.forEach((img: string | null) => {
        if (img) {
          images.push(img)
        }
      })

      imageSubtasks = [...imageSubtasks, subtask]
    } else if (subtask.type === keys.SUBTASK_TYPES.PRODUCT_SUBTASK) {
      let image: string | null = null
      if (taskData) {
        subtask.pid.forEach((pid: string) => {
          if (taskData[pid]) {
            image = taskData[pid].image
          }
        })
      }
      const productSubTask = {
        ...subtask,
      }
      if (image) {
        productSubTask.image = image
      }
      productSubtasks = [...productSubtasks, productSubTask]
    } else if (subtask.type === keys.SUBTASK_TYPES.TEXT_SUBTASK) {
      textSubtasks = [...textSubtasks, subtask]
    } else if (subtask.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS) {
      videoSubtasks = [...videoSubtasks, subtask]
    } else if (subtask.type === keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK) {
      uploadFileSubtasks = [...uploadFileSubtasks, subtask]
    }
  })

  if (hasImageSubtask && images.length === 0) {
    images.push(ImagePlaceholder)
  }

  const typesCounter = subtaskTypesCount(subTasks, subtasksList)

  const group = {
    groupId: groupKey,
    groupLevel: overviewGroup!.groupLevel,
    name: overviewGroup!.title,
    statuses: statuses,
    taskTypesCounter: typesCounter,
    images: images,
    textSubtasks,
    productSubtasks,
    imageSubtasks,
    uploadFileSubtasks,
    videoSubtasks,
  }

  return group
}

export const handleFilter =
  (state: ICheckbox<number>[], groupId: string, refId: string): ThunkActionType =>
  (dispatch, getState: () => RootState) => {
    const {
      tasksOverview: { overviewData, appliedFilter },
      tasksOverviewDetail: { availableGroups, currentCategory },
    } = getState()
    const maxGroupLevel = appliedFilter?.byLevel?.value?.value || 0

    let isAllValid = true
    const validStatuses: { [key: number]: boolean } = {}
    state.forEach((status) => {
      if (!status.isChecked) {
        isAllValid = false
      }
      validStatuses[status.value!] = status.isChecked
    })
    let currentKeys: string[] = findCurrentGroups(overviewData, groupId, refId, maxGroupLevel)

    if (!isAllValid) {
      const findTaskStatuses = overviewData?.tasks.find((task) => task.ref_id === refId)?.statusesByCategory?.[
        currentCategory
      ]
      currentKeys = currentKeys.filter((key: string) => {
        const status = findTaskStatuses?.[key]
        if (!status) return false
        const findStatus = Object.keys(status)[0]
        let isValid = validStatuses[findStatus]
        return isValid
      })
    }

    const filteredGroups = availableGroups.filter((group) => {
      let isValid = false
      Object.keys(group.statuses!).forEach((status: string) => {
        if (validStatuses[status]) isValid = true
      })

      return isValid
    })

    const data = {
      currentGroupKeys: currentKeys,
      availableGroups: filteredGroups,
      hasMoreGroups: true,
      statusFilter: state,
    }

    dispatch(setFilter(data))
  }

export const updateOverviewAnalytic = (): ThunkActionType => (dispatch, getState: () => RootState) => {
  const {
    tasksOverview: { overviewData },
    tasksOverviewDetail: { refId, currentCategory, allGroupKeys },
  } = getState()
  const statuses = getStatuses(overviewData, refId, allGroupKeys, currentCategory)
  let analytic = null
  if (statuses) {
    analytic = getOverviewAnalytical(statuses!.statusCounter, statuses!.totalCounter)
  } else {
    updateOverviewAnalytic()
  }

  if (analytic) {
    dispatch(setTaskAnalytic(analytic))
  }
}

export const handleChangeCategory = (): ThunkActionType => (dispatch, getState: () => RootState) => {
  const {
    tasksOverview: { overviewData },
    tasksOverviewDetail: { refId, currentCategory, allGroupKeys, statusFilter },
  } = getState()
  const statuses = getStatuses(overviewData, refId, allGroupKeys, currentCategory)

  const updatedFilter = statusFilter.map((item) => {
    const findStatus = statuses?.statusCounter[item.value!] || 0
    return {
      ...item,
      counter: findStatus,
    }
  })

  dispatch(setCurrentKeys(allGroupKeys))
  dispatch(updateOverviewAnalytic())
  dispatch(setFilter({ statusFilter: updatedFilter }))
}

export const getInitialDetailData =
  (refId: string, groupId: string): ThunkActionType =>
  (dispatch, getState: () => RootState) => {
    const {
      tasksOverview: { overviewData, appliedFilter },
      config: { activeGroupID },
    } = getState()
    const maxGroupLevel = appliedFilter?.byLevel.value.value || 0
    let groupsKeys: string[] = findCurrentGroups(overviewData, groupId, refId, maxGroupLevel)

    if (groupsKeys.length < 1) {
      groupsKeys.push(groupId)
    }

    const statusFilter = getStatuses(overviewData, refId, groupsKeys)

    const data = {
      statusFilter: statusFilter?.statusCounter,
      refId: refId,
      groupId: groupId,
      currentStore: activeGroupID,
    }
    dispatch(setGroupKeys(groupsKeys))
    dispatch(setCurrentKeys(groupsKeys))
    dispatch(setTaskAnalytic(getOverviewAnalytical(statusFilter!.statusCounter, statusFilter!.totalCounter)))
    dispatch(setInitialData(data))
  }

export const setCurrentCategory =
  (category: string): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_CATEGORY,
      payload: category,
    })
  }

export const getRequestData = (
  currentKeys: string[],
  overviewData: TasksOverviewStateData,
  refId: string,
  availableGroups: ITaskOverviewDetailGroup[],
  groupsByRequest: number
) => {
  const filteredGroupKeys = currentKeys.filter((key: string) => {
    const findGroup = overviewData!.groups[key]
    return findGroup && findGroup.tasksByRefs[refId] && !availableGroups.find((group) => group.groupId === key)
  })

  const groupKeys = filteredGroupKeys.slice(0, groupsByRequest)

  if (groupKeys.length > 0) {
    const groupsTids: string[] = []
    groupKeys.forEach((key: string) => {
      const findGroup = overviewData!.groups[key]
      if (findGroup && findGroup.tasksByRefs[refId]) {
        groupsTids.push(findGroup.tasksByRefs[refId][0])
      }
    })

    return {
      groupKeys,
      groupsTids,
    }
  }
  return null
}

export const getTaskGroups =
  (groupsByRequest: number, refId: string): ThunkActionType =>
  async (dispatch, getState: () => RootState) => {
    const {
      tasksOverviewDetail: { currentGroupKeys, availableGroups, currentCategory, allGroupKeys },
      tasksOverview: { overviewData },
    } = getState()
    if (overviewData && currentGroupKeys) {
      const requestData = getRequestData(currentGroupKeys, overviewData, refId, availableGroups, groupsByRequest)
      if (requestData) {
        try {
          dispatch(setIsFetching(true))
          const { data } = await httpAuth.get(endpoints.getSubtasks(requestData.groupsTids, requestData.groupKeys))
          dispatch(setSubtasksOfCurrentTask(data.sub_tasks))

          if (data.groups_tasks) {
            let curCategory: string = currentCategory ? currentCategory : data.groups_tasks[0].categories_order[0]
            const renderGroups: ITaskOverviewDetailGroup[] = []
            let isUpdateKeys: boolean = false
            let filteredKeys = currentGroupKeys

            let taskData = {}

            if (data?.tasks_data) {
              Object.keys(data?.tasks_data).forEach((key: string) => {
                taskData = {
                  ...taskData,
                  ...data.tasks_data[key].data,
                }
              })
            }

            requestData.groupKeys.forEach((key: string) => {
              const currentOverviewGroup = overviewData?.groups[key]
              const findGroup = data.groups_tasks.find((groupTask: CleanTask) => {
                return groupTask.rs_id.indexOf(key) >= 0 && groupTask.ref_id === refId
              })

              if (findGroup) {
                const currentTask = overviewData?.tasks.find((task) => task.ref_id === refId)
                const statuses = currentTask?.statusesByCategory![curCategory]
                  ? currentTask?.statusesByCategory![curCategory][key]
                  : null
                if (statuses) {
                  const newGroup = getRenderGroup(
                    key,
                    currentOverviewGroup,
                    findGroup,
                    curCategory,
                    data.sub_tasks,
                    statuses,
                    taskData
                  )
                  renderGroups.push(newGroup)
                } else {
                  filteredKeys = filteredKeys.filter((groupKey) => groupKey !== key)
                  isUpdateKeys = true
                }
              }
            })

            if (isUpdateKeys) {
              console.warn('Oops, error with category names')
              dispatch(setCurrentKeys(filteredKeys))
            }

            if (availableGroups.length === 0) {
              dispatch(setCurrentTask(data.groups_tasks[0]))
            }

            const updatedGroups = [...availableGroups, ...renderGroups]

            if (!availableGroups.length && updatedGroups.some((group) => group.imageSubtasks.length)) {
              dispatch(setIsTileMode(true))
            }
            dispatch(setGroups(updatedGroups))

            if (!currentCategory) {
              dispatch(setCurrentCategory(curCategory))
            }
            if (updatedGroups.length > 0) {
              dispatch(setHasMoreGroups(true))
            }
          } else {
            dispatch(setHasMoreGroups(false))
          }
        } catch (error) {
          console.error('[getTaskGroups]', error)
          dispatch(setHasMoreGroups(false))
        } finally {
          dispatch(setIsFetching(false))
          dispatch(setIsPageReady(true))
          dispatch(setCurrentKeys(allGroupKeys))
        }
      } else {
        dispatch(setHasMoreGroups(false))
        dispatch(setIsFetching(false))
      }
    }
  }

export const setIsFetching =
  (bool: boolean): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_IS_FETCHING,
      payload: bool,
    })
  }

export const setIsPageReady =
  (bool: boolean): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_IS_READY,
      payload: bool,
    })
  }

export const resetGroups = (): ThunkActionType => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_GROUPS,
  })
  dispatch(setHasMoreGroups(true))
}

export const resetState = (): ThunkActionType => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_STATE,
  })
}

const setTaskAnalytic =
  (analytic: ITaskOverviewAnalytic[]): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_TASK_ANALYTIC,
      payload: analytic,
    })
  }

const setHasMoreGroups =
  (bool: boolean): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_HAS_MORE,
      payload: bool,
    })
  }

const setInitialData =
  (data: {
    statusFilter:
      | {
          [x: number]: number
        }
      | undefined
    refId: string
    groupId: string
    currentStore: string | null
  }): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_INITIAL_DATA,
      payload: data,
    })
  }

const setCurrentTask =
  (task: CleanTask): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_TASK,
      payload: task,
    })
  }

const setGroupKeys =
  (groupKeys: string[]): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_GROUP_KEYS,
      payload: groupKeys,
    })
  }

const setCurrentKeys =
  (groupKeys: string[]): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_KEYS,
      payload: groupKeys,
    })
  }

export const setGroups =
  (data: ITaskOverviewDetailGroup[]): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_TASK_GROUPS,
      payload: data,
    })
  }
export const setSubtasksOfCurrentTask =
  (data: any): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.SET_SUBTASKS_OF_CURRENT_TASK,
      payload: data,
    })
  }

export const resetFilter = (): ThunkActionType => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_FILTER,
  })
}

const setFilter =
  (data: any): ThunkActionType =>
  (dispatch) => {
    dispatch({
      type: actionTypes.UPDATE_FILTER,
      payload: data,
    })
  }

export const setIsTileMode = (isTileMode: boolean) => ({
  type: actionTypes.SET_IS_TILE_MODE,
  payload: isTileMode,
})

export const setIsOpenStatusModal = (isOpen: boolean) => ({
  type: actionTypes.SET_IS_STATUS_MODAL_OPEN,
  payload: isOpen,
})

export const setActiveFilterStatus = (activeStatuses: number[]) => ({
  type: actionTypes.SET_ACTIVE_FILTER_STATUSES,
  payload: activeStatuses,
})
