import { Flex, Box, IconButton, Divider } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { RankNavItem } from './RankItem'
import styled from '@emotion/styled'
import { KpisContainer } from './style'

export const StyledKpisWrapper = styled(Flex)<{ isShowDropDown: boolean }>`
  flex-direction: column;
  max-height: ${({ isShowDropDown }) => (isShowDropDown ? '400px' : '0px')};
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 82px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: max-height 0.5s ease-out;
  box-shadow: 0px 3px 3px #ababab;
  width: 100%;
  background-color: white;
  &::-webkit-scrollbar {
    width: 5px;
  }
  ,
  &::-webkit-scrollbar-track {
    margin-bottom: 10px;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 40px;
    background: gray;
  }
`
export const RankingNavigation = () => {
  const { kpiListHeaderView, kpiListDropDownView } = useSelector((state: RootState) => state.ranking)
  const [isShowDropDown, setIsShowDropDown] = useState(false)
  const ref = useRef<any>()
  const refBtn = useRef<any>()
  const handleClickOutside = (event: any) => {
    let parent = event.target

    while (parent) {
      if (ref.current === parent || refBtn.current === parent) {
        return
      }
      parent = parent.parentElement
    }

    setIsShowDropDown(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  const [shuffledArray, setShuffledArray] = useState<any>([])
  const [isKpisUpdated, setIsKpisUpdated] = useState(true)

  useEffect(() => {
    if (!isShowDropDown) {
      let currentIndex = 0

      const intervalId = setInterval(() => {
        const newArray = [...kpiListHeaderView]

        // Swap the current index element with the next one
        const temp = newArray[currentIndex]
        newArray[currentIndex] = newArray[(currentIndex + 1) % newArray.length]
        newArray[(currentIndex + 1) % newArray.length] = temp

        setShuffledArray(newArray)
        setIsKpisUpdated((prevState) => !prevState)

        currentIndex = (currentIndex + 1) % newArray.length // Move to the next index
      }, 4000)

      return () => {
        clearInterval(intervalId)
      }
    }
  }, [isShowDropDown, kpiListHeaderView])

  const kpis = useMemo(() => {
    if (shuffledArray.length > 0) return [...shuffledArray, ...kpiListDropDownView]
    else return [...kpiListHeaderView, ...kpiListDropDownView]
  }, [kpiListDropDownView, kpiListHeaderView, shuffledArray])

  return (
    <>
      {kpis && (
        <Flex alignItems="center">
          <KpisContainer>
            <>
              {kpis && kpis?.length > 0 && (
                <CSSTransition in={isKpisUpdated} timeout={900} classNames="fadeIn">
                  <RankNavItem kpi={kpis[0]} setIsShowDropDown={setIsShowDropDown} />
                </CSSTransition>
              )}

              <StyledKpisWrapper ref={ref} isShowDropDown={isShowDropDown}>
                {kpis?.slice(1).map((kpi, i) => {
                  return (
                    <Box key={i}>
                      <RankNavItem kpi={kpi} setIsShowDropDown={setIsShowDropDown} />
                      <Divider my="3px" mx="auto" w="90%" />
                    </Box>
                  )
                })}
              </StyledKpisWrapper>
            </>
          </KpisContainer>
          <IconButton
            onClick={() => {
              setIsShowDropDown(!isShowDropDown)
            }}
            mt="-8px"
            variant="outline"
            variantColor="gray"
            aria-label="dropDown"
            background="white"
            fontSize="20px"
            mx="10px"
            h="20px"
            w="20px"
            minW="unset"
            icon={isShowDropDown ? 'triangle-up' : 'triangle-down'}
            border="none"
            ref={refBtn}
            _hover={{ background: 'white' }}
            _focus={{ boxShadow: 'none' }}
          />
        </Flex>
      )}
    </>
  )
}
