import React from 'react'
import { ModalFooter } from '@chakra-ui/core'
import { PublishButton } from 'components/CommonComponents/PublishButton'
import colors from 'constants/colors'

interface IProps {
  submitHandler: VoidFunction
}

export const FileModalFooter = ({ submitHandler }: IProps) => {
  return (
    <ModalFooter height="70px" justifyContent="center" background={colors.greyLight}>
      <PublishButton onClick={submitHandler} tabIndex={1} />
    </ModalFooter>
  )
}
