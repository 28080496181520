import BaseModal from 'components/CommonComponents/BaseModal'
import React, { useCallback, useState } from 'react'
import { IPop, RootState } from 'constants/interfaces'
import { Box, Button, Flex, IconButton, Image, Text, useToast } from '@chakra-ui/core'
import colors from 'constants/colors'
import PopAvatar from './PopAvatar'
import { PopType } from './utils'
import RePopBody from './RePopBody'
import PopSubmitBtn from './PopSubmitBtn'
import { useMediaState } from 'hooks/useMediaState'
import AudiencePublicationsModal from '../AudiencePublicationModal'
import GroupsTree from '../GroupsTree'
import { UsersList } from '../UsersListModal'
import strings from 'constants/strings'
import { useAudienceState } from 'hooks/useAudienceState'
import { FaUsers } from 'react-icons/fa'
import { TooltipList } from '../TooltipList'
import { MdEdit } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { editRepop } from 'redux/actions/pops'
import { AppDispatch } from 'config/redux'
import useLocaleText from 'components/useLocaleText'

interface IProps {
  isOpen: boolean
  onClose: () => void
  firstPopInStack: IPop
  handleSubmit: (popType: number | undefined) => void
}
export const RePop = ({ isOpen, onClose, firstPopInStack, handleSubmit }: IProps) => {
  const dispatch: AppDispatch = useDispatch()
  const toast = useToast()
  const pop_recipients = useLocaleText('pop_recipients')

  const [isEditMode, setIsEditMode] = useState(false)
  const [audienceModalOpen, setAudienceModalOpen] = useState(false)
  const [popTitle, setPopTitle] = useState<string>(firstPopInStack?.pop_title)
  const [popBody, setPopBody] = useState<string>(firstPopInStack?.pop_body)

  const { groups } = useSelector((state: RootState) => state.config.config)
  const { isLoading } = useSelector((state: RootState) => state.pops)

  const {
    uploadedImagesURL,
    handleSingleMediaUploading,
    isUploadLoading,
    addMediaHandler,
    imageInputRef,
    setUploadedImagesURL,
  } = useMediaState({
    images: [firstPopInStack?.pop_image_url],
  })

  const {
    selectedUsersUids,
    setSelectedUsersUids,
    selectedGroupsIds,
    setSelectedGroupsIds,
    groupsToPayload,
    setGroupsToPayload,
    namesForTooltip,
    filteredRerailUsersByTreeUsers,
    treeUsers,
    treeGroups,
  } = useAudienceState({ users: firstPopInStack?.repop_contexts_ids, groups: [], selectedOptionType: '30' })

  const handleRevertChanges = useCallback(() => {
    setPopTitle(firstPopInStack?.pop_title)
    setPopBody(firstPopInStack?.pop_body)
    setUploadedImagesURL([firstPopInStack?.pop_image_url])
    setSelectedUsersUids(firstPopInStack?.repop_contexts_ids)
  }, [firstPopInStack, setSelectedUsersUids, setUploadedImagesURL])

  const handleSaveChanges = async () => {
    const extractedUidsFromGroups = selectedGroupsIds.flatMap((groupId) => groups[groupId]?.uids)
    const repopContextsIds = Array.from(new Set([...selectedUsersUids, ...extractedUidsFromGroups]))

    if (repopContextsIds.length === 0 || !popTitle || !popBody) {
      toast({
        title: 'Error',
        description: 'Please fill all required fields',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
      return
    }

    const body = {
      pop_id: firstPopInStack?.pk,
      data: {
        pop_title: popTitle,
        pop_body: popBody,
        pop_image_url: uploadedImagesURL.length ? uploadedImagesURL[0] : '',
        repop_contexts_ids: repopContextsIds,
      },
    }

    const res = await dispatch(editRepop({ body }))
    if (res) {
      setIsEditMode((prev) => !prev)
    }
  }

  const renderViewOptionModal = useCallback(
    (viewOption: number, setViewOption: React.Dispatch<React.SetStateAction<number>> | any) => {
      switch (viewOption) {
        case 0:
          return (
            <GroupsTree
              isOpen={isOpen}
              selectedGroupID={selectedGroupsIds}
              mode={strings.MULTIPLE_MODE}
              setViewOption={setViewOption}
              setSelectedGroupID={setSelectedGroupsIds}
              setGroupsToPayload={setGroupsToPayload}
              groupsToPayload={groupsToPayload}
              isAllowSubmitWhithoutGroups={true}
              treeGroups={treeGroups}
            />
          )
        case 1:
          return (
            <UsersList
              isOpen={isOpen}
              onClose={onClose}
              setViewOption={setViewOption}
              selectedUsersUids={selectedUsersUids}
              setSelectedUsersUids={setSelectedUsersUids}
              treeUsers={filteredRerailUsersByTreeUsers}
            />
          )

        default:
          break
      }
    },
    [
      filteredRerailUsersByTreeUsers,
      groupsToPayload,
      isOpen,
      onClose,
      selectedGroupsIds,
      selectedUsersUids,
      setGroupsToPayload,
      setSelectedGroupsIds,
      setSelectedUsersUids,
      treeGroups,
    ]
  )

  return (
    <>
      <BaseModal
        display={audienceModalOpen ? 'none' : 'block'}
        isOpen={isOpen}
        onClose={onClose}
        marginTop="7%"
        borderRadius="30px"
        closeOnEsc={false}
        maxWidth="29rem"
        overflowY="unset"
        hideCloseButton={true}
        isShowAnimation={true}
        headerContent={
          <Flex minH="100px" justifyContent="space-between">
            <PopAvatar {...firstPopInStack} />
            {firstPopInStack?.pop_icon_url && (
              <Box
                width="50px"
                height="50px"
                pos="absolute"
                borderRadius="120px"
                left="30px"
                top="45px"
                background={colors.greyLight3}
              >
                <Image src={firstPopInStack.pop_icon_url} width="40px" height="30px" m="9px auto" />
              </Box>
            )}

            {isEditMode ? (
              <>
                <Flex flexDir="column" justifyContent="space-between">
                  <IconButton
                    isDisabled={isLoading}
                    onClick={handleRevertChanges}
                    w="40px"
                    h="40px"
                    borderRadius="full"
                    aria-label="Search database"
                    icon="repeat"
                  />
                  <TooltipList items={namesForTooltip}>
                    <Button
                      fontSize="16px"
                      isDisabled={isLoading}
                      h="auto"
                      fontWeight="500"
                      p="5px"
                      onClick={() => setAudienceModalOpen(true)}
                    >
                      <FaUsers />
                      <Text mx="2">{pop_recipients}</Text>
                    </Button>
                  </TooltipList>
                </Flex>

                <Button isDisabled={isLoading} onClick={handleSaveChanges}>
                  save
                </Button>
              </>
            ) : (
              firstPopInStack.pop_type !== PopType.FAKE_POP && (
                <Button onClick={() => setIsEditMode((prev) => !prev)}>
                  <MdEdit />
                  <Text mx="2">Edit</Text>
                </Button>
              )
            )}
          </Flex>
        }
        bodyContent={
          <RePopBody
            isEditMode={isEditMode}
            popBody={popBody}
            popTitle={popTitle}
            popImageURL={uploadedImagesURL?.[0]}
            audio={firstPopInStack?.audio ?? ''}
            video={firstPopInStack?.video ?? ''}
            pop_initiator={firstPopInStack.pop_initiator}
            created_at_ts={firstPopInStack.created_at_ts}
            created_by={firstPopInStack.created_by}
            setPopBody={setPopBody}
            setPopTitle={setPopTitle}
            handleSingleMediaUploading={handleSingleMediaUploading}
            isUploadLoading={isUploadLoading}
            addMedia={addMediaHandler}
            imageInputRef={imageInputRef}
            isLoading={isLoading}
            setUploadedImagesURL={setUploadedImagesURL}
          />
        }
        footerContent={
          <Flex w="100%" justifyContent="center" alignItems="center" flexDir="column">
            <>
              <PopSubmitBtn
                isDisabled={isEditMode || isLoading}
                hasIcon={true}
                onClose={handleSubmit}
                btnText={firstPopInStack.repop_main_btn}
                pop_initiator={firstPopInStack.pop_initiator}
                popType={firstPopInStack.pop_type}
              />
              <Button
                isDisabled={isEditMode || isLoading}
                onClick={() => handleSubmit(PopType.DEFAULT_POP)}
                mb="10pt"
                background="transparent"
                color="#879AF2"
                _focus={{ boxShadow: 'none' }}
                _hover={{ background: 'transparent' }}
                _active={{ background: 'transparent' }}
              >
                {firstPopInStack.repop_sec_btn}
              </Button>
            </>
          </Flex>
        }
      />
      <AudiencePublicationsModal
        isOpen={audienceModalOpen}
        groups={treeGroups}
        users={treeUsers}
        onClose={onClose}
        title={pop_recipients}
        setShowOptionsList={setAudienceModalOpen}
        renderViewOptionModal={renderViewOptionModal}
        selectedUsersUids={selectedUsersUids}
        groupsToPayload={selectedGroupsIds ?? []}
      />
    </>
  )
}
