/* eslint-disable react-hooks/exhaustive-deps */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { memo } from 'react'
import { Box, Button, Flex, useDisclosure } from '@chakra-ui/core'
import styled from '@emotion/styled'
import Logo from './Logo'
import { Link } from 'react-router-dom'
import UserDropDown from '../DropDown/UserDropDown'
import { DEVICE_TYPES } from 'constants/UI'
import colors from 'constants/colors'
import useWindowDimensions from 'hooks/useWindowDimensions'
import MobileMenu from './MobileMenu'
import MobileUserMenu from './MobileUserMenu'
import keys from 'constants/keys'
import { useSelector } from 'react-redux'
import { retailConfigSelector } from 'redux/selectors/config'
import { RankingNavigation } from '../Ranking/RankingNavigation/RankingNavigation'
import { RootState } from 'constants/interfaces'

const BurgerIcon = styled('span')`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25px;
  height: 2px;
  background-color: ${colors.blackLight};

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: translateX(-50%);
    left: 50%;
    width: 100%;
    height: 2px;
    background-color: ${colors.blackLight};
  }

  &::before {
    top: -10px;
  }

  &::after {
    bottom: -10px;
  }
`

const StyledHeader = styled(Box)`
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1300;
  left: 0;
  right: 0;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
`

const Header = memo(() => {
  const { windowWidth } = useWindowDimensions()
  const { isOpen: isOpenMobileMenu, onOpen: onOpenMobileMenu, onClose: onCloseMobileMenu } = useDisclosure()
  const retailConfig = useSelector(retailConfigSelector)
  const { rankingReports } = useSelector((state: RootState) => state.ranking)
  const theme = useSelector((state: RootState) => state.general.theme)
  const logoSrc = theme?.logoUrl || retailConfig?.logo_image_url

  return (
    <>
      <StyledHeader as="header" bg="white" w="100%" height="94px">
        <Flex p="5" alignItems="center" m="auto" justifyContent="space-between" flexDir="row" height="94px">
          {!DEVICE_TYPES.DESKTOP(windowWidth) && (
            <Button onClick={onOpenMobileMenu} position="relative" variant="ghost">
              <BurgerIcon />
            </Button>
          )}
          {DEVICE_TYPES.DESKTOP(windowWidth) && (
            <Box background="white">
              <Link to={keys.ROUTE_NAMES.NEWS_FEED}>
                <Logo src={logoSrc} w="196px" maxHeight="60px" />
              </Link>
            </Box>
          )}

          {Object.keys(rankingReports).length > 0 && <RankingNavigation />}
          <Box>
            {DEVICE_TYPES.DESKTOP(windowWidth) && (
              <>
                <UserDropDown />
              </>
            )}

            {!DEVICE_TYPES.DESKTOP(windowWidth) && (
              <>
                <MobileUserMenu />
              </>
            )}
          </Box>
        </Flex>
        {!DEVICE_TYPES.DESKTOP(windowWidth) && <MobileMenu isOpen={isOpenMobileMenu} onClose={onCloseMobileMenu} />}
      </StyledHeader>
    </>
  )
})

export default Header
