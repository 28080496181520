import React from 'react'
import { Button, ButtonProps, Icon } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import colors from 'constants/colors'

interface IProps extends Omit<ButtonProps, 'children'> {
  iconSize?: string
  children?: JSX.Element
}

export const DeleteButton = ({ iconSize, children, ...props }: IProps) => {
  const { locale } = useSelector((state: RootState) => state.general)
  return (
    <Button
      position="absolute"
      zIndex={101}
      borderRadius="50%"
      size="xs"
      height="25px"
      width="25px"
      left={locale !== 'he' ? '-5%' : null}
      right={locale === 'he' ? '-5%' : null}
      top="50%"
      transform="translateY(-50%)"
      background={colors.tealLight}
      {...props}
    >
      {children || <Icon name="close" size={iconSize || '8px'} />}
    </Button>
  )
}
