import React from 'react'
import { Flex, Grid, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import './style.css'

interface IProps {
  children: React.ReactNode
  onClickToItem?: (index: number) => void
}

export const FbGrid = ({ children, onClickToItem }: IProps) => {
  const Children = React.Children.toArray(children)
  const childrenCount = React.Children.count(children)
  const getClassWrapper = () => `fb-grid-wrapper fb-grid-wrapper-${childrenCount > 5 ? 5 : childrenCount}`

  const onClickToItemHandler = (index: number) => {
    if (onClickToItem) {
      onClickToItem(index)
    }
  }

  return (
    <Grid className={getClassWrapper()}>
      {Children.slice(0, 5).map((child, index) => {
        return (
          <Flex
            position="relative"
            key={index}
            className={`fb-item-${index + 1}`}
            alignItems="center"
            onClick={() => onClickToItemHandler(index)}
            overflow="hidden"
          >
            {child}
            {childrenCount > 5 && index === 4 && (
              <Flex
                position="absolute"
                width="100%"
                height="100%"
                opacity={0.7}
                background={colors.blackLight}
                top="0"
                alignItems="center"
                justifyContent="center"
                zIndex={3}
              >
                <Text fontSize="4xl" color={colors.tealLight}>
                  +{childrenCount - 5}
                </Text>
              </Flex>
            )}
          </Flex>
        )
      })}
    </Grid>
  )
}
