import { Flex, Text } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { isRtlSelector } from 'redux/selectors/general'

export type SwitchItem = {
  title: string
  value: number
}
interface IProps {
  onToggePostMode: (creationMode: SwitchItem) => void
  creationMode: SwitchItem
  items: SwitchItem[]
  title: string
  isSwitchDisabled?: boolean
}

export const SwitchTabs = ({ onToggePostMode, creationMode, items, title, isSwitchDisabled = false }: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  const [activePost, setActivePost] = useState(creationMode ? creationMode : items[0])

  const handleToggle = (item: { title: string; value: number }) => {
    if (isSwitchDisabled) return
    if (item !== activePost) {
      setActivePost(item)
      onToggePostMode(item)
    }
  }

  const ToggleText = ({ item }: { item: { title: string; value: number } }) => (
    <Text
      background={activePost.value === item.value ? 'white' : 'none'}
      cursor="pointer"
      minW="120px"
      p="5px"
      onClick={() => handleToggle(item)}
      borderRadius={activePost.value === item.value ? '7px' : '0'}
      color={activePost.value === item.value ? 'black' : colors.greyDark2}
      borderColor="none"
      fontWeight={activePost.value === item.value ? 'bold' : 'normal'}
    >
      <LocaleText text={item.title} />
    </Text>
  )

  return (
    <Flex flexDir="column" alignItems="center" mb="20px">
      <Text>
        <LocaleText text={title} />
      </Text>
      <Flex
        flexDir="row"
        minW="250px"
        background={colors.greyLight}
        p="3px"
        alignItems="center"
        justifyContent="space-between"
        borderRadius="10px"
        mt="10px"
        dir={isRtl ? 'row-reverse' : 'row'}
      >
        {items.map((item, index) => (
          <ToggleText item={item} key={index} />
        ))}
      </Flex>
    </Flex>
  )
}
