import { Flex, IconButton } from '@chakra-ui/core'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { Post } from 'redux/reducers/feed'
import isSolid from 'is-solid'
import { useDispatch } from 'react-redux'
import { CHAT, handleGetPosts } from 'redux/actions/feed'
import { AdvancedInfiniteScroll } from 'components/CommonComponents/AdvancedInfiniteScroll'

const SCROLL_AMOUNT = 400

const ScrollButton = styled(IconButton)<{ bgColor?: string }>`
  position: absolute;
  background: ${({ bgColor }) => bgColor || '#ffd6eb'};
  border-radius: 50%;
  min-width: unset;
  font-size: 1.5rem;
  width: 30px;
  height: 30px;
  &:hover,
  &:active {
    background-color: ${({ bgColor }) => bgColor || '#ffd6eb'};
  }
  &:focus {
    box-shadow: none;
  }
`

export const InfiniteScrollWithScrollControl = ({
  children,
  chats,
  isAllPostCollapsed,
  hasMoreChats,
  chatsFetching,
}: {
  children: ReactNode
  chats: Post[]
  isAllPostCollapsed: boolean | null
  hasMoreChats: boolean
  chatsFetching: boolean
}) => {
  const dispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [showScrollButtons, setShowScrollButtons] = useState(false)

  const scroll = (direction: 'left' | 'right') => {
    const scrollValue = direction === 'left' ? -SCROLL_AMOUNT : SCROLL_AMOUNT
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy({ left: scrollValue, behavior: 'smooth' })
    }
  }
  const checkIfScrollNeeded = () => {
    if (wrapperRef.current) {
      const totalWidthOfChats = wrapperRef.current.scrollWidth
      const containerWidth = wrapperRef.current.offsetWidth
      setShowScrollButtons(totalWidthOfChats > containerWidth)
    }
  }
  useEffect(() => {
    checkIfScrollNeeded()
  }, [chats.length, isAllPostCollapsed])

  useEffect(() => {
    window.addEventListener('resize', checkIfScrollNeeded)
    return () => {
      window.removeEventListener('resize', checkIfScrollNeeded)
    }
  }, [])

  const fetchPosts = useCallback(() => {
    if (chatsFetching || !isSolid(chats) || !hasMoreChats) return
    const lastChatItem = chats?.[chats.length - 1]
    const { updated_at_ts } = lastChatItem
    const handleGetPostsParams = {
      type: CHAT,
      ts: updated_at_ts?.toString(),
      isRefresh: false,
    }

    try {
      dispatch(handleGetPosts(handleGetPostsParams))
    } catch (err) {
      console.log(err)
    }
  }, [chatsFetching, chats, hasMoreChats, dispatch])

  return (
    <>
      {showScrollButtons && (
        <ScrollButton
          icon="chevron-left"
          aria-label="Scroll left"
          onClick={() => scroll('left')}
          left="-35px"
          top="40px"
          bgColor="white"
        />
      )}
      <Flex
        w="100%"
        h="135px"
        ref={wrapperRef}
        overflowX={'auto'}
        css={css`
          &::-webkit-scrollbar {
            display: none;
          }
        `}
      >
        <AdvancedInfiniteScroll
          loadMore={fetchPosts}
          hasMore={hasMoreChats}
          threshold={100}
          scrollRef={wrapperRef}
          isUpwardScroll={false}
          isHorizontalScroll={true}
        >
          {children}
        </AdvancedInfiniteScroll>
      </Flex>
      {showScrollButtons && (
        <ScrollButton
          icon="chevron-right"
          aria-label="Scroll right"
          onClick={() => scroll('right')}
          right="-35px"
          top="40px"
          bgColor="white"
        />
      )}
    </>
  )
}
