import { Button, Flex } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

export const TablePagination = ({
  previousPage,
  canNextPage,
  canPreviousPage,
  pageOptions,
  pageIndex,
  gotoPage,
  nextPage,
}: any) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <Flex flexDir="row" justifyContent="flex-end" alignItems="flex-end" mt={'20px'}>
      <Button
        onClick={() => previousPage()}
        isDisabled={!canPreviousPage}
        height={'1.5rem'}
        width={'1rem'}
        minW={'none'}
        background={'none'}
        color={'rgb(162, 162, 182)'}
        _active={{ background: 'none', color: 'd53f8c' }}
        _focus={{ background: theme?.elementsColor, color: 'white' }}
        _hover={{
          background: '#none',
          color: theme?.elementsColor,
        }}
      >
        {'<'}
      </Button>
      {pageOptions.map((index: number) => {
        if (index === 0 || index === pageOptions.length - 1 || (index >= pageIndex - 1 && index <= pageIndex + 1)) {
          return (
            <Button
              height="25px"
              width={'1rem'}
              minW={'none'}
              _active={{ background: theme?.elementsColor, color: 'white' }}
              _focus={{ background: theme?.elementsColor, color: 'white' }}
              _hover={{
                boxShadow: 'none',
                color: theme?.elementsColor,
                fontWeight: '800',
              }}
              background={pageIndex === index ? theme?.elementsColor : 'none'}
              key={index}
              mx="6px"
              color={pageIndex === index ? 'white' : 'rgb(162, 162, 182)'}
              onClick={() => gotoPage(index)}
              style={{
                fontWeight: pageIndex === index ? 'bold' : 'normal',
              }}
            >
              {index + 1}
            </Button>
          )
        } else if (index === pageIndex - 2 || index === pageIndex + 2) {
          return <span key={index}>...</span>
        }
        return null
      })}
      <Button
        onClick={() => nextPage()}
        isDisabled={!canNextPage}
        height={'1.5rem'}
        width={'1rem'}
        minW={'none'}
        background={'none'}
        color={'rgb(162, 162, 182)'}
        _active={{ background: theme?.elementsColor, color: 'white' }}
        _focus={{ background: theme?.elementsColor, color: 'white' }}
        _hover={{
          background: '#none',
          color: theme?.elementsColor,
        }}
      >
        {'>'}
      </Button>
    </Flex>
  )
}
