/* eslint-disable react/prop-types,react-hooks/exhaustive-deps */
import React, { forwardRef, useRef, useState, useEffect } from 'react'
import { Flex, Heading } from '@chakra-ui/core'
import colors from 'constants/colors'
import { TiTimes, TiTick } from 'react-icons/ti'
import { PublishButton } from 'components/CommonComponents/PublishButton'
import { secondsToFormatedTime } from 'utils'
import { AudioRecorderControlBtn } from './AudioRecorderControlBtn'
import { AudioPlayer } from 'components/AudioPlayer'
import { PreviewFile } from 'config/types'

// @ts-ignore
import MicRecorder from 'mic-recorder-to-mp3'

interface IProps {
  isRecording: boolean
  onSuccess: (audio: PreviewFile) => void
  onFailure?: VoidFunction
  onStopRecording: VoidFunction
  showCheckAudioStep?: boolean
}

export const AudioRecorder = forwardRef(
  ({ onFailure, onSuccess, onStopRecording, isRecording, showCheckAudioStep = true }: IProps, ref: any) => {
    const audioRef = useRef<HTMLAudioElement>(null)
    const interval = useRef<null | NodeJS.Timeout>(null)
    const [isRecordReady, setIsRecordReady] = useState(false)
    const [duration, setDuration] = useState(0)
    const [recorder, setRecorder] = useState<any>(null)
    const [src, setSrc] = useState<PreviewFile | null>(null)

    const startRecording = () => {
      const recorder = new MicRecorder({ bitRate: 128 })
      if (ref) {
        ref.current = recorder
      }
      setRecorder(recorder)
      recorder.start()
      interval.current = setInterval(() => {
        setDuration((prevState) => prevState + 1)
      }, 1000)
    }

    const successRecording = () => {
      if (src) {
        onSuccess(src)
      }
      onStopRecording()
    }

    const clearIntervalHandler = () => {
      if (interval.current) {
        clearInterval(interval.current)
      }
      setDuration(0)
    }

    useEffect(() => {
      if (isRecording) {
        startRecording()
      } else {
        clearIntervalHandler()
      }
    }, [isRecording])

    const endRecordingVoice = () => {
      if (recorder) {
        recorder?.stop()
        onStopRecording()
        if (onFailure) {
          onFailure()
        }
      }
    }

    const stopRecording = async () => {
      if (showCheckAudioStep) {
        setIsRecordReady(true)
      }
      const [buffer, blob] = await recorder?.stop().getMp3()
      if (interval.current) {
        clearInterval(interval.current)
      }
      const file = new File(buffer, 'me-at-thevoice.mp3', {
        type: blob.type,
        lastModified: Date.now(),
      })
      const recordURI = URL.createObjectURL(file)
      const filePreview = { file, previewUrl: recordURI }
      if (!showCheckAudioStep) {
        onSuccess(filePreview)
        onStopRecording()
      }
      setSrc(filePreview)
    }

    return (
      <Flex flex={1}>
        {!isRecordReady && (
          <Flex flexGrow={1} minWidth="100px" background={colors.analyticGrey} borderRadius="5px" mx="5px" />
        )}
        {isRecordReady && (
          <Flex flexGrow={1} px="5px">
            <AudioPlayer src={src?.previewUrl || ''} />
          </Flex>
        )}
        <Flex width={150} justifyContent="space-between" alignItems="center">
          <AudioRecorderControlBtn onClick={endRecordingVoice} btnColor={colors.red}>
            <TiTimes transform="scale(2)" />
          </AudioRecorderControlBtn>
          <Heading fontSize={20}>{secondsToFormatedTime(duration)}</Heading>
          {!isRecordReady && (
            <AudioRecorderControlBtn onClick={stopRecording}>
              <TiTick transform="scale(2)" fill={colors.greenDark} />
            </AudioRecorderControlBtn>
          )}
          {isRecordReady && <PublishButton onClick={successRecording} />}
          <audio ref={audioRef} src={src?.previewUrl} preload="metadata" />
        </Flex>
      </Flex>
    )
  }
)
