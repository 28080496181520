import React from 'react'
import { Button } from '@chakra-ui/core'
import colors from 'constants/colors'

interface IProps {
  children?: React.ReactNode
  onClick?: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  btnColor?: string
}

export const AudioRecorderControlBtn = ({ children, onClick, btnColor = colors.greenDark }: IProps) => {
  return (
    <Button
      variant="outline"
      borderColor={btnColor}
      color={colors.red}
      borderRadius="full"
      onClick={onClick}
      width={44}
      height={44}
      p="0"
      minWidth="0"
    >
      {children}
    </Button>
  )
}
