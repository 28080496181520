const getGaId = (prefix: string, idx: number, id: string, extraValue?: string) =>
  `${prefix}${idx}_${extraValue || ''}${id}`
// Header
export const searchGA = 'search_input'

// Sidebar
export const contextGA = (idx: number) => `ctx_btn_${idx}`

// Post
type GaFn = (idx: number, id: string) => string
const post_ga_prefix = 'post_'
const story_ga_prefix = 'story_'

export const postGA = (idx: number, id: string, extraValue?: string) => getGaId(post_ga_prefix, idx, id, extraValue)
export const storyGA = (idx: number) => story_ga_prefix + idx

export const postHeaderGA: GaFn = (idx, id) => postGA(idx, id, '_btn_')
export const postImageGA: GaFn = (idx, id) => postGA(idx, id, '_img_btn_')

// Dashboard
const dashboardTaskPrefix = 'task-'
export const assigneeBtnGA = 'assigned-btn'
export const pointOfScaleBtnGA = 'point_of_scale-btn'
export const tsStatusBtnGA = 'ts_status-btn'
export const segLevelBtnGA = 'seg_level-btn'
export const sortByBtnGA = 'sort_by-btn_0'
export const dashBoardAreaBtnGA = 'area-btn-'

export const areaBtnGA: GaFn = (idx, id) => `${dashBoardAreaBtnGA}${idx}_${id}`
export const dashboardTaskGA: GaFn = (idx, id) => getGaId(dashboardTaskPrefix, idx, id)

// Reports
export const reportDateBtnGA = 'report_date-btn'
export const reportContentGA = 'report_content'

// Tasks
enum tasksPrefixes {
  operationalTasks = 'ops_tasks-btn_',
  autoTasks = 'imp_sales-btn_',
  trainingTasks = 'colab_learn-btn_',
  historyTasks = 'vm-btn_',
}

export const taskGA = (taskType: string, idx: number, id: string) => getGaId(tasksPrefixes[taskType], idx, id)
