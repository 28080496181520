import { Flex, Grid, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'

export const BaseComponent = styled(Flex)<{ bgColor?: string }>`
  flex-direction: column;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  border-radius: 15px;
`

export const BaseCard = styled(Flex)<{ bgColor: string; isClickable?: boolean }>`
  flex-direction: column;
  justify-content: flex-start;
  background-color: ${({ bgColor }) => bgColor || 'white'};
  border-radius: 15px;
  min-height: 120px;
  min-width: 320px;
  padding: 15px 20px;

  ${({ isClickable }) =>
    isClickable &&
    `
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    &:hover, &:focus {
      color: inherit;
      text-decoration: none;
      transform: scale(0.98);
    }
  `}
  @media (max-width: 759px) {
    width: 100%;
  }
`
export const DiffTag = styled(Text)<{ color: string; borderColor?: string }>`
  border-radius: 10px;
  background-color: ${({ color }) => color};
  padding: 0px 5px;
  line-height: 10px;
  min-width: 35px;
  text-align: center;
  border: 1px solid ${({ borderColor }) => borderColor};
`
export const ReportWrapper = styled(Grid)`
  column-gap: 10px;
  width: 100%;
  align-items: stretch;

  @media (max-width: 699px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (min-width: 700px) and (max-width: 1260px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1261px) {
    grid-template-columns: repeat(3, 1fr);
  }
`
