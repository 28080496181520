import { Flex } from '@chakra-ui/core'
import React from 'react'
import { ReactComponent as LikeIcon } from '../../assets/like.svg'
import { ReactComponent as LikeIconActive } from '../../assets/like-active.svg'
import { css } from '@emotion/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface ILikeButton {
  liked: boolean
  likeHandler?: () => void
}
export const LikeButtonSquare = ({ liked, likeHandler }: ILikeButton) => {
  const { theme } = useSelector((state: RootState) => state.general)
  return (
    <Flex
      align="center"
      justifyContent="center"
      backgroundColor={'white'}
      borderRadius="12px"
      mx="auto"
      mt="10px"
      mb="15px"
      h="40px"
      w="50%"
      onClick={likeHandler}
      boxShadow={`0 1px 4px 0px ${theme?.elementsColor}`}
      cursor="pointer"
      css={css`
        transition: all 350ms ease-in-out;
        &:hover {
          background-color: ${theme?.backgroundLight};
        }
      `}
    >
      {liked ? (
        <LikeIconActive
          width="40px"
          height="40px"
          style={{ marginTop: '10px', '--svg-color': theme?.elementsColor } as React.CSSProperties}
          cursor="pointer"
        />
      ) : (
        <LikeIcon width="40px" height="40px" style={{ marginTop: '10px' }} cursor="pointer" />
      )}
    </Flex>
  )
}
