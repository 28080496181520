import { Image, Box, Flex, IconButton, Divider, useDisclosure } from '@chakra-ui/core'
import { FbGridVideoPreview } from 'components/CommonComponents/FbGrid/FbGridVideoPreview'
import { PostAudioList } from 'components/NewsFeed/PostAudioList'
import { PostMediaGrid } from 'components/NewsFeed/PostMediaGrid'
import { CommentButton } from 'components/Post/CommentButton'
import { LikeButton } from 'components/Post/LikeButton'
import { LikeButtonSquare } from 'components/Post/LikeButtonSquare'
import LikesModal from 'components/Post/LikesModal'
import { CreatorReceiverTimeDisplay } from 'components/Post/PostHeader/CreatorReceiverTimeDisplay'
import { renderTextWithLineBreaks } from 'components/Post/PostTitle'
import { ViewButton } from 'components/Post/ViewButton'
import { ViewsModal } from 'components/Post/ViewsModal'
import { UploadedFileList } from 'components/UploadedFileList'
import { IComment, RootState } from 'constants/interfaces'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { updateCommentViews, updateLikes } from 'redux/actions/comments'
import { Post } from 'redux/reducers/feed'
import { isRtlSelector } from 'redux/selectors/general'
import { calculateUserActivity } from 'utils'

interface Props {
  message: IComment
  currentChat: Post
  repliesCounter: number
  repliesCounterLoading: boolean
  likes: { [key: string]: boolean }
  views: { [key: string]: boolean }
}
export const AnnouncementChatHeader = ({
  currentChat,
  message,
  repliesCounter,
  repliesCounterLoading,
  likes,
  views,
}: Props) => {
  const dispatch = useDispatch()
  const { isOpen: isViewModalOpen, onOpen: onViewModalOpen, onClose: onCloseViewModal } = useDisclosure()
  const { isOpen: isLikesModalOpen, onOpen: onLikesModalOpen, onClose: onCloseLikesModal } = useDisclosure()
  const isRtl = useSelector(isRtlSelector)
  const { uid } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()

  const onLikeClick = () => {
    dispatch(updateLikes(message.cid, uid!))
  }

  const likesCounter = (likes && Object.keys(likes).length) ?? 0
  const viewsCounter = (views && Object.keys(views).length) ?? 0

  const users = Array.from(new Set([...(currentChat?.group_users ?? []), ...(currentChat?.post_origin_users ?? [])]))
  const viewed = useMemo(() => calculateUserActivity(users, views), [users, views])
  const liked = useMemo(() => calculateUserActivity(users, likes), [likes, users])

  useEffect(() => {
    if (currentChat.created_by === uid) return
    dispatch(updateCommentViews(message.cid, uid!))
  }, [currentChat.created_by, dispatch, message.cid, uid])

  return (
    <>
      <IconButton
        icon={!isRtl ? 'chevron-left' : 'chevron-right'}
        fontSize="30px"
        position="absolute"
        top="10px"
        left={isRtl ? 'unset' : '-125px'}
        right={isRtl ? '-125px' : 'unset'}
        h="35px"
        w="35px"
        bg="none"
        borderRadius="full"
        minW="unset"
        onClick={() => navigate(-1)}
        aria-label="back"
        mx="20px"
      />
      <Flex w="100%" p="20px" flexDir="column">
        {currentChat && (
          <CreatorReceiverTimeDisplay
            created_by={currentChat?.created_by}
            postOriginUsers={currentChat?.post_origin_users ?? []}
            users={currentChat?.users}
            created_at_ts={message?.updated_at_ts}
            postGroupName={currentChat?.group_users ?? []}
          />
        )}
        {message && (
          <>
            {message?.message && (
              <Flex alignItems="center">
                <Box px="50px" wordBreak={'break-word'}>
                  {renderTextWithLineBreaks({ text: message?.message })}
                </Box>
              </Flex>
            )}
            {message?.audios && message.audios.length > 0 && (
              <Box px="50px" py="10px">
                <PostAudioList audios={message.audios} />
              </Box>
            )}

            {message?.files && message.files.length > 0 && (
              <Box px="50px" marginBottom="15px">
                <UploadedFileList fileList={message.files} />
              </Box>
            )}

            {message?.images && message.images.length > 0 && (
              <PostMediaGrid media={message.images} isEditable={false}>
                {message.images !== null &&
                  message.images?.map((imageSrc) => <Image key={imageSrc} src={imageSrc} width="100%" />)}
              </PostMediaGrid>
            )}

            {message.videos && message.videos.length > 0 && (
              <PostMediaGrid media={message.videos} isEditable={false}>
                {message.videos !== null &&
                  message.videos?.map((videoSrc) => <FbGridVideoPreview key={videoSrc} src={videoSrc} />)}
              </PostMediaGrid>
            )}
          </>
        )}
        <Flex flexDir="column" justifyContent="center">
          <Flex w="100%" justifyContent="flex-end">
            <LikeButton
              created_by={message.created_by}
              likes_counter={likesCounter}
              liked={liked}
              isShowLikeButton={true}
              isLikedPost={likes?.[uid!] ?? false}
              isDoublePost={false}
              isAllPostCollapsed={true}
              handleLikesModal={onLikesModalOpen}
            />

            <ViewButton
              viewed={viewed}
              views_counter={viewsCounter}
              created_by={currentChat.created_by}
              isShowViewIcon={true}
              isViewed={views?.[uid!] ?? false}
              isDoublePost={false}
              isAllPostCollapsed={true}
              openModal={onViewModalOpen}
            />

            <CommentButton
              postId={currentChat.post_id}
              commentsCounterLoading={repliesCounterLoading}
              showCommentsSection={false}
              commentsCounter={repliesCounter}
              isViewed={true}
              isDoublePost={false}
              isAllPostCollapsed={true}
            />
          </Flex>
          {message.created_by !== uid && <LikeButtonSquare liked={likes?.[uid!] ?? false} likeHandler={onLikeClick} />}
        </Flex>
      </Flex>
      <Divider w="95%" />
      {isViewModalOpen && (
        <ViewsModal
          isOpen={isViewModalOpen}
          onClose={onCloseViewModal}
          views_counter={viewsCounter}
          created_by={message.created_by}
          viewed={viewed}
        />
      )}
      {isLikesModalOpen && (
        <LikesModal
          isOpen={isLikesModalOpen}
          onClose={onCloseLikesModal}
          likes_counter={likesCounter}
          created_by={message.created_by}
          liked={liked}
          isShowPings={false}
        />
      )}
    </>
  )
}
