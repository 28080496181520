import { Box, Flex, useDisclosure } from '@chakra-ui/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { HiUserGroup } from 'react-icons/hi'
import { GroupTagsList } from './GroupTagsList'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import strings from 'constants/strings'
import useLocaleText from 'components/useLocaleText'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { setIsFetching, setRegisteredUser, updateGroup, updateUser } from 'redux/actions/admin'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import { StyledConfirmButton } from '../UI/CustomComponents'
import UsersActionModal from '../Modals'
import { getParentsFromGroups, getTreeNodesIds } from 'components/CommonComponents/GroupsTree/utils'

const UsersGroupManagement = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [firstEnter, setFirstEnter] = useState(true)
  const [selectedIds, setSelectedIds] = useState<string[] | undefined>([])
  const [defautGroup, setDefaultGroup] = useState<string>('')
  const [intermidateCheckedIds, setIntermediateCheckedIds] = useState<string[]>([])
  const [groupToAdd, setGroupToAdd] = useState<string>('')
  const [groupToDelete, setGroupToDelete] = useState<string>('')
  const theme = useSelector((state: RootState) => state.general.theme)
  const { registeredUser, isFetching, isActionSuccessful, isError } = useSelector(
    (state: RootState) => state.admin.userTable
  )
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { onClose: onGroupsTreeClose, isOpen: isGroupsTreeOpen, onOpen: onGroupsTreeOpen } = useDisclosure()
  const { onClose, isOpen, onOpen } = useDisclosure()
  const admin_finish = useLocaleText('admin_finish')
  const admin_skip_button = useLocaleText('admin_skip_button')
  const admin_add_group_success = useLocaleText('admin_add_group_success')

  const handleSaveGroups = useCallback(() => {
    if (registeredUser) {
      dispatch(setIsFetching(true))
      const usersObjectToPayload = {
        uid: registeredUser?.uid,
        ...(defautGroup ? { default_group: defautGroup } : {}),
      }
      if ('default_group' in usersObjectToPayload) {
        dispatch(updateUser(registeredUser, usersObjectToPayload))
      }
      dispatch(setRegisteredUser(null))
    }
  }, [defautGroup, dispatch, registeredUser])

  const disabledIds = useMemo(() => {
    if (selectedIds && selectedIds?.length > 0) {
      const setParents = []
      const setChildren = []
      for (const id of selectedIds) {
        setParents.push(...getParentsFromGroups(id, groups).map((parent) => parent.parent))
        setChildren.push(...getTreeNodesIds(groups, id))
      }

      return Array.from(new Set([...setParents, ...setChildren]))
    }
  }, [groups, selectedIds])

  const handleAddGroup = useCallback(
    (groupId: string) => {
      if (registeredUser) {
        setGroupToAdd(groupId)
        dispatch(setIsFetching(true))
        dispatch(updateGroup({ groupId, added_users_ids: [registeredUser?.uid] }))
      }
    },
    [dispatch, registeredUser]
  )
  const handleFinish = useCallback(() => {
    if (defautGroup) {
      handleSaveGroups()
    } else {
      navigate(keys.ROUTE_NAMES.USERS_TABLE)
      onClose()
    }
  }, [defautGroup, handleSaveGroups, navigate, onClose])

  const onSuccessHandleAddGroup = useCallback(() => {
    setSelectedIds(intermidateCheckedIds)
    setGroupToAdd('')
  }, [intermidateCheckedIds])

  const onSuccessHandleDeleteGroup = () => {
    const updatedIds = selectedIds?.filter((id: any) => id !== groupToDelete)
    setSelectedIds(updatedIds!)
    setGroupToDelete('')
    setFirstEnter(false)
  }

  useEffect(() => {
    if (isActionSuccessful || isError) onOpen()
  }, [isActionSuccessful, isError, onOpen])

  useEffect(() => {
    if (selectedIds?.length === 0 && firstEnter) onGroupsTreeOpen()
  }, [firstEnter, onGroupsTreeOpen, selectedIds])

  return (
    <Flex flexDir="column">
      <Flex alignItems="flex-start" justifyContent="flex-start" mt="10px">
        <Flex flexDir="column" alignItems="center">
          <Box mt="30px" mx="30px">
            <HiUserGroup size="120px" fill={theme?.backgroundMedium} />
          </Box>
        </Flex>
        <GroupTagsList
          onGroupsTreeOpen={onGroupsTreeOpen}
          selectedIds={selectedIds}
          isShowDeleteBtn={true}
          setDefaultGroup={setDefaultGroup}
          setGroupToDelete={setGroupToDelete}
          onOpen={onOpen}
        />
      </Flex>
      <Flex justifyContent="flex-end" flexDir="row">
        <StyledConfirmButton
          isDisabled={(selectedIds && selectedIds?.length > 0) || isFetching}
          onClick={() => {
            dispatch(setRegisteredUser(null))
            navigate(keys.ROUTE_NAMES.USERS_TABLE)
          }}
          themeColor={theme?.elementsColor}
        >
          {admin_skip_button}
        </StyledConfirmButton>
        <StyledConfirmButton
          onClick={() => (defautGroup ? onOpen() : navigate(keys.ROUTE_NAMES.USERS_TABLE))}
          isDisabled={selectedIds?.length === 0}
          themeColor={theme?.elementsColor}
        >
          {admin_finish}
        </StyledConfirmButton>
      </Flex>
      <>
        {isGroupsTreeOpen && (
          <GroupsTree
            setSelectedGroupID={setSelectedIds}
            selectedGroupID={selectedIds!}
            setIntermediateCheckedIds={setIntermediateCheckedIds}
            handleAddGroup={handleAddGroup}
            isOpen={isGroupsTreeOpen}
            onClose={onGroupsTreeClose}
            mode={strings.USERS_MODE}
            setGroupList={onGroupsTreeClose}
            treeGroups={groups}
            disabledIds={disabledIds}
          />
        )}
      </>
      {isOpen && (
        <UsersActionModal
          closeOnOverlayClick={isFetching ? false : true}
          isOpen={isOpen}
          onClose={onClose}
          selectedIds={selectedIds}
          setGroupToDelete={setGroupToDelete}
          actionLiteral={admin_add_group_success}
          groupToDelete={groupToDelete}
          defautGroup={defautGroup}
          handleFinish={handleFinish}
          route={groupToAdd || groupToDelete ? '' : keys.ROUTE_NAMES.USERS_TABLE}
          mode={groupToDelete ? 'delete_group' : 'default_group'}
          handleSaveGroups={handleSaveGroups}
          {...(groupToAdd || groupToDelete
            ? groupToAdd
              ? { onSuccessGroupChange: onSuccessHandleAddGroup }
              : { onSuccessGroupChange: onSuccessHandleDeleteGroup }
            : {})}
        />
      )}
    </Flex>
  )
}

export default UsersGroupManagement
