import { Flex } from '@chakra-ui/core'
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { SubtaskField } from './SubtaskField'
import { FormField, getCategoryById, initialFormFields } from './utils'
import keys from 'constants/keys'
import { regExLinkRecognizer } from 'constants/baseValues'
import useLocaleText from 'components/useLocaleText'

interface IProps {
  setPollSubTasks: (subTasks: FormField[]) => void
  setIsValidPollSubtasks: (value: boolean) => void
}
export const PollSubtask = ({ setPollSubTasks, setIsValidPollSubtasks }: IProps) => {
  const t_pls_add_link = useLocaleText('t_pls_add_link')
  const [formFields, setFormFields] = useState<FormField[]>(initialFormFields)
  const memoizedFormFields = useMemo(() => formFields, [formFields])
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({})

  const handleAddSubtask = useCallback(
    (id: number) => {
      setFormFields((prevFields) =>
        prevFields.map((field) =>
          field.id === id
            ? {
                ...field,
                subTasks: [
                  ...field.subTasks,
                  {
                    title: '',
                    is_required: true,
                    category: getCategoryById(id),
                    type: id,
                    ...(id === keys.SUBTASK_TYPES.LINK_SUBTASK && { external_link: '', placeholder: t_pls_add_link }),
                  },
                ],
              }
            : field
        )
      )
    },
    [t_pls_add_link]
  )

  const handleRemoveSubtask = useCallback((fieldId: number, index: number) => {
    setFormFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              subTasks: field.subTasks.filter((_, i) => i !== index),
            }
          : field
      )
    )
  }, [])

  const handleChangeSubtask = useCallback((fieldId: number, index: number, value: string) => {
    setFormFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              subTasks: field.subTasks.map((subTask, i) => (i === index ? { ...subTask, title: value } : subTask)),
            }
          : field
      )
    )
  }, [])

  const handleChangeSubtaskTitle = useCallback((fieldId, index, value) => {
    setFormFields((prevFields) =>
      prevFields.map((field) =>
        field.id === fieldId
          ? {
              ...field,
              subTasks: field.subTasks.map((subTask, i) =>
                i === index ? { ...subTask, external_link: value } : subTask
              ),
            }
          : field
      )
    )
  }, [])

  const validateFormFields = useCallback(() => {
    const newErrors = {}
    formFields.forEach((field, fieldIndex) => {
      field.subTasks.forEach((subTask, index) => {
        const errorKey = `${field.id}-${index}`
        if (field.id === keys.SUBTASK_TYPES.IMAGE_SUBTASK && !subTask.title.trim()) {
          newErrors[errorKey] = true
        } else if (field.id === keys.SUBTASK_TYPES.LINK_SUBTASK) {
          const isEmptyTitle = !subTask.title.trim()
          const isEmptyLink = !subTask.external_link?.trim()
          const isValidLink = !isEmptyLink && regExLinkRecognizer.test(subTask.external_link!)
          newErrors[errorKey] = isEmptyTitle || isEmptyLink || !isValidLink
        }
      })
    })
    setErrors(newErrors)
    setIsValidPollSubtasks(!Object.values(newErrors).some((error) => error))
  }, [formFields, setIsValidPollSubtasks])

  const handleBlur = () => {
    const updatedFormFields = formFields.map((field) => ({
      ...field,
      subTasks: field.subTasks.map((subTask) => {
        const { placeholder, ...restSubTask } = subTask

        return restSubTask
      }),
    }))
    setPollSubTasks(updatedFormFields)
  }
  useEffect(() => {
    validateFormFields()
  }, [formFields, validateFormFields])

  useEffect(() => {
    setPollSubTasks(formFields)
  }, [formFields, setPollSubTasks])

  return (
    <Flex w="100%" direction="column">
      {memoizedFormFields.map(({ id, label, placeholder, subTasks }, index) => (
        <Fragment key={`field-${id}`}>
          <SubtaskField
            index={index}
            id={id}
            label={label}
            placeholder={placeholder}
            subTasks={subTasks}
            onAddSubtask={handleAddSubtask}
            onRemoveSubtask={handleRemoveSubtask}
            onChangeSubtask={handleChangeSubtask}
            onChangeSubtaskTitle={handleChangeSubtaskTitle}
            handleBlur={handleBlur}
            errors={errors}
          />
        </Fragment>
      ))}
    </Flex>
  )
}
