// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react'
import { Button, Flex, FormControl, Input, useToast, Text, Textarea, InputGroup } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordSubmit, setCandidateToLogin } from 'redux/actions/auth'
import { RootState } from 'constants/interfaces'
import { useNavigate } from 'react-router'
import { isRtlSelector } from 'redux/selectors/general'
import { Navigate } from 'react-router-dom'
import keys from 'constants/keys'
import { useTimer } from 'react-timer-hook'
import { refactorPartOfTime } from 'utils'
import { PasswordEyeToggle } from 'components/Login/PasswordEyeToggle'

const ForgotPasswordSubmit = () => {
  const timerTime = new Date(new Date().getTime() + 3 * 60000)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const isRtl = useSelector(isRtlSelector)
  const mfa_message_sms_1 = useLocaleText('mfa_message_sms_1')
  const mfa_message_email_1 = useLocaleText('mfa_message_email_1')
  const mfa_message_2 = useLocaleText('mfa_message_2')
  const new_password_placeholder = useLocaleText('new_password')
  const set_new_password = useLocaleText('set_new_password')
  const forgot_password_return_login_page = useLocaleText('forgot_password_return_login_page')
  const mfa_code_input_place_holder = useLocaleText('mfa_code_input_place_holder')
  const change_password_policy = useLocaleText('change_password_policy')
  const {
    isLoginLoading,
    isError,
    errorMessage,
    candidateToLogin: { userName, destination, method },
    isLoggedIn,
  } = useSelector((state: RootState) => state.auth)
  const [isShow, setShow] = useState(false)

  const { handleSubmit, errors, register } = useForm()

  const submitNewPassword = (data: any) => {
    if (data.password === '') {
      toast({
        title: 'Add username and password.',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      })
    } else {
      dispatch(resetPasswordSubmit(userName, data.new_password, data.code, navigate))
    }
  }
  const onExpire = () => {
    navigate(keys.ROUTE_NAMES.LOGIN_PAGE)
    dispatch(setCandidateToLogin(null))
  }

  const { minutes, seconds } = useTimer({ autoStart: true, expiryTimestamp: timerTime, onExpire })

  useEffect(() => {
    if (errors?.password?.types?.message || (isError && errorMessage !== '')) {
      toast({
        title: errors?.password?.types?.message || errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [errorMessage, errors, isError, toast])

  if (isLoggedIn) {
    return <Navigate to="/" />
  }
  return (
    <LoginWrapper
      isChangingPassword={true}
      header={
        <Flex flexDirection="column" alignItems={isRtl ? 'right' : 'left'} fontSize="1.1rem">
          <Text>{method === 'phone_number' ? mfa_message_sms_1 : mfa_message_email_1}</Text>
          <Text>{destination}</Text>
          <Text>{mfa_message_2}</Text>
        </Flex>
      }
    >
      <form autoComplete="none" onSubmit={handleSubmit(submitNewPassword)}>
        <FormControl>
          <Textarea
            id="code"
            name="code"
            borderRadius="20px"
            placeholder={mfa_code_input_place_holder}
            ref={register}
            marginBottom="20px"
            style={{ minHeight: '0px', height: '2.5rem' }}
            resize={'none'}
          />
          <Flex
            flexDirection="column"
            textAlign={isRtl ? 'right' : 'left'}
            marginBottom="20px"
            fontSize="1.1rem"
            fontWeight="700"
          >
            <Flex justifyContent="center" mt={4}>
              {refactorPartOfTime(minutes)}:{refactorPartOfTime(seconds)}
            </Flex>
            <Text my="10px">{set_new_password}</Text>
            <Text>
              {change_password_policy
                .split('\n')
                .filter(Boolean)
                .map((paragraph) => (
                  <>
                    <Text
                      as="span"
                      key={paragraph}
                      fontSize="16px"
                      my="10px"
                      dangerouslySetInnerHTML={{ __html: paragraph }}
                    />
                    <br />
                  </>
                ))}
            </Text>
          </Flex>
          <InputGroup>
            <Input
              id="new_password"
              name="new_password"
              borderRadius="20px"
              placeholder={new_password_placeholder}
              ref={register}
              marginBottom="20px"
              type={!isShow ? 'password' : 'text'}
            />
            <PasswordEyeToggle isShow={isShow} setShow={setShow} />
          </InputGroup>
        </FormControl>

        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          isLoading={isLoginLoading}
          type="submit"
        >
          <LocaleText text="login" />
        </Button>
        <Button
          onClick={() => navigate(keys.ROUTE_NAMES.LOGIN_PAGE)}
          width="100%"
          mt={4}
          style={{ background: 'none', color: '#d53f8c', borderRadius: '20px' }}
        >
          {forgot_password_return_login_page}
        </Button>
      </form>
    </LoginWrapper>
  )
}

export default ForgotPasswordSubmit
