import { createSelector } from 'reselect'
import { IAllTasksById, RootState } from 'constants/interfaces'
import { CleanTask, SubTask } from 'config/tasksTypes'
import { filterNotificationTasks, getSubtasks } from 'utils'
import { TASKS_NOTIFICATION_COUNT } from 'constants/baseValues'
import UploadFileSubTasks from 'components/CreateTask/UploadFileSabTasks'

const tasks = (state: RootState) => state.tasks
const subtasks = (state: RootState) => state.tasks.subTasks

export const allTasksByTypeSelector = createSelector(tasks, ({ allTasks }) => {
  return allTasks
    ? Object.keys(allTasks).reduce<IAllTasksById>(
        (acc, viewId) => ({ ...acc, [viewId.toString()]: allTasks[viewId].tasks || [] }),
        {}
      )
    : {}
})

export const notificationsTasksSelector = createSelector([allTasksByTypeSelector, subtasks], (allTasks, subtasks) => {
  return allTasks
    ? Object.keys(allTasks).reduce<IAllTasksById>((acc, viewId) => {
        const notificationTasks = filterNotificationTasks(allTasks[viewId], subtasks, TASKS_NOTIFICATION_COUNT)
        if (!notificationTasks) return acc
        return {
          ...acc,
          [viewId]: notificationTasks,
        }
      }, {})
    : {}
})

interface ISubtasksByTypes {
  productSubTasks: SubTask[]
  linkSubTasks: SubTask[]
  imageSubTasks: SubTask[]
  videoSubTasks: SubTask[]
  textSubTasks: SubTask[]
  uploadFileSubTasks: SubTask[]
}

export const allTasksSelector = createSelector(tasks, ({ allTasks }) => {
  return allTasks
    ? Object.keys(allTasks).reduce<CleanTask[]>((acc, el) => [...acc, ...(allTasks[el]?.tasks || [])], [])
    : []
})

export const taskByTypesSelector = createSelector(
  [tasks, allTasksSelector],
  ({ productSubTasks, linkSubTasks, imageSubTasks, uploadFileSubTasks, videoSubTasks, textSubTasks }, allTasks) =>
    (currentCategory: string, tid: string) => {
      const subTasksByType = {
        productSubTasks,
        linkSubTasks,
        imageSubTasks,
        uploadFileSubTasks,
        videoSubTasks,
        textSubTasks,
      }
      const currentTask = allTasks.find((task) => task.tid === tid)
      const categories = currentTask?.categories
      const subTasksOrder = categories?.[currentCategory]?.sub_tasks_order
      const subTasksObject: ISubtasksByTypes = {
        imageSubTasks: [],
        linkSubTasks: [],
        productSubTasks: [],
        uploadFileSubTasks: [],
        textSubTasks: [],
        videoSubTasks: [],
      }

      for (let key of Object.keys(subTasksByType)) {
        subTasksObject[key] =
          subTasksOrder && subTasksByType[key] ? getSubtasks(subTasksOrder, subTasksByType[key]) : []
      }

      return subTasksObject
    }
)
