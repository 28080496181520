import { Flex, Box } from '@chakra-ui/core'
import React from 'react'
import keys from 'constants/keys'
import { Result, Subtask } from 'constants/interfaces'
import { ImageSubtask } from './ImageSubtask'

interface IProps {
  subTask: Subtask
  subTaskResult?: Result
  contextId: string | null
}

export const ModalMediaSection = ({ subTask, subTaskResult, contextId }: IProps) => {
  const { type } = subTask

  return (
    <Flex justifyContent="center" overflow="hidden">
      <Box w="100%" m={'auto'} textAlign={'center'}>
        {type === keys.SUBTASK_TYPES.IMAGE_SUBTASK && (
          <ImageSubtask images={subTaskResult?.images ?? []} subTask={subTask} contextId={contextId ?? null} />
        )}
        {/* {type === keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK && <VideoSubtask />}
            {type === keys.SUBTASK_TYPES.TEXT_SUBTASK && <QuestionSubtask />} */}
      </Box>
    </Flex>
  )
}
