import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import isSolid from 'is-solid'
import { Box, Text } from '@chakra-ui/core'

import colors from '../../constants/colors'
import { BackArrow, NavItem, NavTitleItem, SidebarContent, StyledSidebar } from './SidebarStyles'

const TaskSidebar = () => {
  const locale = useSelector((state: any) => state.general.locale)
  const navigate = useNavigate()
  const location = useLocation()
  const { categories_order, selectedCategory, title }: any = location.state
  const isHebrew = locale === 'he'

  const { tid } = useParams<'tid'>()

  const [currentCategory, setCurrentCategory] = useState(
    isSolid(selectedCategory) ? selectedCategory : isSolid(categories_order) ? categories_order[0] : null
  )

  const handleCategory = (e: React.MouseEvent<HTMLElement>, category: string) => {
    e.preventDefault()
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
    setCurrentCategory(category)
    let locationState = {}
    if (typeof location.state === 'object' && locationState !== null) {
      locationState = { ...location.state }
    }
    navigate(location.pathname, { state: { ...locationState, selectedCategory: category } })
  }

  const getTaskRoute = () => {
    return navigate(`${location.pathname.replace(`/${tid}`, '')}`)
  }

  return (
    <StyledSidebar
      as="aside"
      bg={colors.greyMedium}
      w="100%"
      overflow="visible"
      p={{
        base: 0,
        lg: 4,
      }}
      isHebrew={isHebrew}
    >
      <SidebarContent justifyContent="space-between" flexDirection="column">
        <Box
          as="nav"
          mt={{
            lg: 6,
            base: 0,
          }}
        >
          <NavTitleItem onClick={() => getTaskRoute()}>
            <BackArrow />
            <Text fontWeight="bold" pl={locale === 'he' ? '0' : '20px'} pr={locale === 'he' ? '20px' : '0'}>
              {title}
            </Text>
          </NavTitleItem>
          {isSolid(categories_order) && (
            <Box
              display="flex"
              overflowX="auto"
              flexDirection={{
                lg: 'column',
                base: 'row',
              }}
              py={{
                base: 2,
                lg: 0,
              }}
              pl={{
                lg: locale === 'he' ? '0' : '38px',
                base: 0,
              }}
              pr={{
                lg: locale === 'he' ? '38px' : '0',
                base: 0,
              }}
            >
              {categories_order.map((item: string) => {
                return (
                  <NavItem
                    to=""
                    key={item}
                    className={currentCategory === item ? 'active' : ''}
                    onClick={(e) => handleCategory(e, item)}
                    locale={locale}
                  >
                    {item}
                  </NavItem>
                )
              })}
            </Box>
          )}
        </Box>
      </SidebarContent>
    </StyledSidebar>
  )
}

export default TaskSidebar
