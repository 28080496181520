export const UNSEENS = {
  FIVE: 5,
  FOUR: 4,
  THREE: 3,
  TWO: 2,
  ONE: 1,
  ZERO: 0,
}
export const CHAT_INSIGHTS_MESSAGES = {
  [UNSEENS.FIVE]: 'chat_insights_message_5',
  [UNSEENS.FOUR]: 'chat_insights_message_4',
  [UNSEENS.THREE]: 'chat_insights_message_3',
  [UNSEENS.TWO]: 'chat_insights_message_2',
  [UNSEENS.ONE]: 'chat_insights_message_1',
  [UNSEENS.ZERO]: 'chat_insights_message_0',
}

export const getChatInsightMessage = (unseensCount: number): string => {
  return CHAT_INSIGHTS_MESSAGES[unseensCount] || CHAT_INSIGHTS_MESSAGES[UNSEENS.FIVE]
}
