import React from 'react'
import { Text } from '@chakra-ui/core'
import colors from 'constants/colors'

export const SecretID = ({ value }: { value: string }) => {
  return (
    <Text color={colors.greyMain} fontSize="6px">
      {value}
    </Text>
  )
}
