import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import keys from 'constants/keys'

export const useSearchPage = () => {
  const location = useLocation()

  const isSearchPage = useMemo(() => location.pathname === keys.ROUTE_NAMES.NEWS_FEED_SEARCH, [location.pathname])

  return { isSearchPage }
}
