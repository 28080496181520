import { Button, Flex, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { DefaultGroupSelect } from '../UserForm/DefaultGroupSelect'
import { useParams } from 'react-router-dom'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import { GroupTag } from './GroupTagItem'
import LocaleText from 'components/LocaleText'

export interface IGroupsSelection {
  selectedIds?: string[]
  setSelectedIds?: (group: string[]) => void
  isShowDeleteBtn?: boolean
  register?: any
  field?: string
  handleSetValue?: (field: string, val: string | number) => void
  setDefaultGroup?: (group: string) => void
  setGroupToDelete?: (group: string) => void
  onGroupsTreeOpen?: () => void
  onOpen?: () => void
}
export const GroupTagsList = ({
  selectedIds,
  isShowDeleteBtn,
  register,
  field,
  handleSetValue,
  setDefaultGroup,
  setGroupToDelete,
  onOpen,
  onGroupsTreeOpen,
}: IGroupsSelection) => {
  const { uid } = useParams()
  const { isFetching } = useSelector((state: RootState) => state.admin.userTable)
  const theme = useSelector((state: RootState) => state.general.theme)

  const renderGroupTag = useMemo(() => {
    return (id: string) => {
      if (isFetching) {
        return <SkeletonText isLoaded={!isFetching} key={id} width="150px" mr="10px" mb="10px" height="40px" />
      }
      return (
        <GroupTag
          key={id}
          isShowDeleteBtn={isShowDeleteBtn}
          groupId={id}
          selectedIds={selectedIds}
          setGroupToDelete={setGroupToDelete}
          onOpen={onOpen}
        />
      )
    }
  }, [isFetching, isShowDeleteBtn, selectedIds, setGroupToDelete, onOpen])

  return (
    <>
      <Flex flexDir="column" mx="20px" w="90%">
        <Text
          mb="20px"
          color={theme?.elementsColor}
          fontWeight={'800'}
          textAlign={isShowDeleteBtn ? 'inherit' : 'center'}
        >
          <LocaleText text={!isShowDeleteBtn ? 'users_groups_literal' : 'admin_add_groups_description'} />
        </Text>

        <Flex flexDir="row" justifyContent="space-between">
          {isShowDeleteBtn && (
            <Button
              isDisabled={isFetching}
              justifySelf="flex-end"
              onClick={() => onGroupsTreeOpen?.()}
              color={theme?.backgroundMedium}
              mb="20px"
              border={`1px solid ${theme?.elementsColor}`}
              borderRadius="10px"
              bg="white"
              width="max-content"
              _focus={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}`, border: `1px solid ${theme?.elementsColor}` }}
              _hover={{ background: theme?.backgroundMedium, color: theme?.elementsColor }}
            >
              <LocaleText text="admin_add_groups" />
            </Button>
          )}
        </Flex>
        {isShowDeleteBtn ? (
          <>
            {selectedIds && selectedIds.length > 0 ? (
              <Flex flexDir="row" flexWrap="wrap">
                {selectedIds.map((id) => renderGroupTag(id))}
              </Flex>
            ) : (
              isFetching && <SkeletonText isLoaded={!isFetching} width="200px" height="40px" />
            )}
          </>
        ) : (
          <>
            {selectedIds && selectedIds.length > 0 && (
              <Flex flexDir="row" flexWrap="wrap">
                {selectedIds.map((id) => (
                  <GroupTag
                    key={id}
                    isShowDeleteBtn={isShowDeleteBtn}
                    groupId={id}
                    selectedIds={selectedIds}
                    setGroupToDelete={setGroupToDelete}
                    onOpen={onOpen}
                  />
                ))}
              </Flex>
            )}
          </>
        )}
        {isShowDeleteBtn && selectedIds && selectedIds.length > 0 && !uid && (
          <Flex mt="30px">
            <DefaultGroupSelect
              disabled={false}
              selectedIds={selectedIds}
              setDefaultGroup={setDefaultGroup}
              handleSetValue={handleSetValue}
              field={field!}
              register={register}
            />
          </Flex>
        )}
      </Flex>
    </>
  )
}
