/* eslint-disable no-prototype-builtins */
/* eslint-disable  eqeqeq  */
/* eslint-disable  no-sequences  */
import { DEVICE_TYPES_ENUM, INTEGRATION_TYPE, TASK_SORTING } from './constants/enums'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { isAfter, isBefore, isEqual, format, addSeconds, sub, subMilliseconds, getUnixTime } from 'date-fns'

import {
  MainTasksData,
  CleanTask,
  Task,
  Categories,
  CleanCategories,
  Category,
  SubTasks,
  SubTask,
} from './config/tasksTypes'
import strings from './constants/strings'
import isSolid from 'is-solid'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import keys from 'constants/keys'
import {
  ICheckbox,
  IGroupCheckbox,
  ITaskOverviewAnalytic,
  IConfigGroup,
  IValidationRules,
  IFileDownload,
  IAllUserTaskPermittedGroup,
  IAllTasks,
  IBuildedTasks,
  IRankingReport,
  IConfigStateGroups,
} from 'constants/interfaces'
import colors from 'constants/colors'
import { defaultTaskStatuses, OTHER_TASK_VIEW_ID } from 'constants/baseValues'
import { isNumber } from 'lodash'
import authService from 'services/authService'
import endpoints from 'constants/endpoints'
import { Sorting } from './config/types'

dayjs.extend(utc)
dayjs.extend(timezone)

const formatter = new Intl.NumberFormat('en-GB')

export function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const buildStoresByArea = (dirtyStores: any) => {
  const areas = {}
  for (const sid in dirtyStores) {
    if (dirtyStores.hasOwnProperty(sid)) {
      const area = dirtyStores[sid]
      if (!areas[area]) {
        areas[area] = []
        areas[area].push(sid)
      } else {
        areas[area].push(sid)
      }
    }
  }
  return areas
}

export const shakeObject = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  })
  return obj
}

export const isContainExternalLinkPhrase = (link: string) => {
  return link?.includes(keys.EXTERNAL_LINK_PHRASE)
}

export const extractSID = (config: any) => {
  const extractedSID = []
  for (const key in config.stores_details) {
    if (config.stores_details.hasOwnProperty(key)) {
      const sidName = config.stores_details[key]
      extractedSID.push({ value: key, label: sidName })
    }
  }
  return extractedSID
}

export const getStoresLength = (storesByArea: any) => {
  const storesLengthByArea = {}
  for (const area in storesByArea) {
    if (storesByArea.hasOwnProperty(area)) {
      const stores = storesByArea[area]
      storesLengthByArea[area] = stores.length
    }
  }
  return storesLengthByArea
}

export const buildCategories = (dirtyTask: Task): CleanCategories => {
  const dirtyCategories: Categories = dirtyTask.categories
  const categoriesOrder: string[] = dirtyTask.categories_order
  const cleanCategories: CleanCategories = {}

  for (const categoryName of categoriesOrder) {
    const category: Category = dirtyCategories[categoryName]
    cleanCategories[categoryName] = category
  }
  return cleanCategories
}

const getTaskStatus = (isOpenedTask: boolean, subtasksKeys: string[], allSubtasks: SubTasks): number => {
  let subtasksStatusesCounter = { ...defaultTaskStatuses }
  let requiredSubtasksCounter = 0

  subtasksKeys.forEach((key: string) => {
    if (allSubtasks[key] && allSubtasks[key].is_st_required) {
      requiredSubtasksCounter++
      if (allSubtasks[key].type === keys.SUBTASK_TYPES.IMAGE_SUBTASK) {
        if (isNumber(allSubtasks[key].status) && allSubtasks[key].cover_img[0]) {
          subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] =
            subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] + 1
        }
      } else if (allSubtasks[key].type === keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK) {
        if (isNumber(allSubtasks[key].status) && allSubtasks[key].files?.[0]) {
          subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] =
            subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] + 1
        }
      } else if (
        allSubtasks[key].type === keys.SUBTASK_TYPES.LINK_SUBTASK ||
        allSubtasks[key].type === keys.SUBTASK_TYPES.TEXT_SUBTASK
      ) {
        if (isNumber(allSubtasks[key].status)) {
          subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] =
            subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] + 1
        }
      } else if (allSubtasks[key].type === keys.SUBTASK_TYPES.PRODUCT_SUBTASK) {
        if (allSubtasks[key].cover_img?.[0] || isNumber(allSubtasks[key].status)) {
          subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] =
            subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] + 1
        }
      } else if (allSubtasks[key].type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS) {
        if (allSubtasks[key].videos?.[0] || isNumber(allSubtasks[key].status)) {
          subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] =
            subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] + 1
        }
      }
    }
  })

  let taskStatus = isOpenedTask ? keys.TASK_STATUSES.INPROGRESS.value : keys.TASK_STATUSES.TODO.value
  if (isOpenedTask && subtasksStatusesCounter[keys.TASK_STATUSES.DONE.value] === requiredSubtasksCounter) {
    taskStatus = keys.TASK_STATUSES.DONE.value
  }

  return taskStatus
}

const countTasksByStatus: (tasks: CleanTask[]) => { [key: number]: number } = (tasks) =>
  tasks.reduce((acc, task) => {
    const status = task.taskStatus
    return { ...acc, [status]: (acc[status] || 0) + 1 }
  }, {})

const isTaskRoll = (task: CleanTask, subTasks: SubTasks) => {
  const subtasksIds = Object.values(task.categories).reduce<string[]>(
    (acc, category) => acc && category?.sub_tasks_order && [...acc, ...category.sub_tasks_order],
    []
  )
  return (
    task.roll && subtasksIds.some((subtasksIds) => subTasks[subtasksIds].type === keys.SUBTASK_TYPES.PRODUCT_SUBTASK)
  )
}

export const buildTasks = ({ groups_tasks, sub_tasks }: MainTasksData, taskViewIds: string[]) => {
  const isTask = keys.TASK_TYPE.TASK
  const isReportTask = keys.TASK_TYPE.REPORT
  const isExternalLink = keys.TASK_TYPE.EXTERNAL_LINK
  const buildedTasks: IBuildedTasks = {}
  const reportTasks: Task[] = []
  if (!groups_tasks) {
    return buildedTasks
  }

  groups_tasks.forEach((dirtyTask: Task) => {
    if ((dirtyTask.type === isTask || dirtyTask.type === isExternalLink) && dirtyTask.categories) {
      let currentSubtasks: string[] = []
      Object.keys(dirtyTask.categories).forEach((category: string) => {
        if (dirtyTask.categories[category]?.sub_tasks_order)
          currentSubtasks = [...currentSubtasks, ...dirtyTask.categories[category].sub_tasks_order]
      })

      const cleanTask: CleanTask = {
        ...dirtyTask,
        createdDateFormated: formatTimestamp(dirtyTask.created_at_ts, strings.DATE_FORMATS.outputs.taskPeriod, null),
        fromDateFormated: formatTimestamp(
          dirtyTask.from_ts,
          strings.DATE_FORMATS.outputs.taskPeriod,
          strings.DATE_FORMATS.inputs.taskPeriod
        ),
        toDateFormated: formatTimestamp(
          dirtyTask.deadline || dirtyTask.to_ts,
          strings.DATE_FORMATS.outputs.taskPeriod,
          strings.DATE_FORMATS.inputs.taskPeriod
        ),
        taskStatus: getTaskStatus(dirtyTask.is_opened, currentSubtasks, sub_tasks),
      }
      if (dirtyTask.type === isTask) {
        cleanTask.categories = buildCategories(dirtyTask)
      }
      const taskView = dirtyTask.view.toString()
      const validTaskView = taskViewIds.includes(taskView) ? taskView : OTHER_TASK_VIEW_ID
      const updatedTasks = [...(buildedTasks[validTaskView]?.tasks || []), cleanTask]
      buildedTasks[validTaskView] = {
        tasks: updatedTasks,
        statuses: { ...defaultTaskStatuses, ...countTasksByStatus(updatedTasks) },
      }
    } else if (dirtyTask.type === isReportTask) {
      reportTasks.push(dirtyTask)
    }
  })

  const rollTasks = Object.values(buildedTasks).reduce<CleanTask[]>((acc, taskObj) => {
    const filteredTasks = taskObj.tasks.filter((task) => isTaskRoll(task, sub_tasks))
    return [...acc, ...filteredTasks]
  }, [])

  return { allTasks: buildedTasks, reportTasks, rollTasks }
}

export function formatTimestamp(input: string | number, outputFormat: any, inputFormat?: any) {
  /**
   * @param {string | number} input in epoc style i.e. 12345953334
   * @param outputFormatString  format fot moment to return
   */
  if (typeof input === 'number') {
    return dayjs.unix(input).format(outputFormat).toString()
  } else {
    const currentLocale = dayjs.tz.guess()
    return dayjs.utc(input, inputFormat).tz(currentLocale).format(outputFormat)
  }
}

export const downloadZip = async (files: IFileDownload[]) => {
  return new Promise(async (resolve, reject) => {
    const EXTENSIONS_TO_REPLACE = ['.jpg', '.jpeg']
    const zip = new JSZip()
    let errorFiles = 0
    for (let file in files) {
      try {
        const downloadUrl = files[file].downloadUrl
        let imageFileUrlExtensionToReplace = ''
        for (let sepInd in EXTENSIONS_TO_REPLACE) {
          const separator = EXTENSIONS_TO_REPLACE[sepInd]
          if (downloadUrl.indexOf(separator) !== -1) {
            imageFileUrlExtensionToReplace = separator
          }
        }
        const imageUrl = downloadUrl.replace(
          imageFileUrlExtensionToReplace,
          imageFileUrlExtensionToReplace + '?cache_baster=' + getCurrentEpoch().toString()
        )
        const res: any = await fetch(imageUrl, {
          method: 'GET',
          headers: {
            Accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
            // 'sec-fetch-dest': 'image'
          },
        })
        const arrayBuffer = await res.arrayBuffer()
        await zip.file(files[file].name + files[file].extension, arrayBuffer, { binary: true })
      } catch (error) {
        errorFiles++
        console.log(`Something went wrong with loading image for ${files[file].name}: ${error}`)
      }
    }

    if (errorFiles !== files.length) {
      zip.generateAsync({ type: 'blob' }).then(
        function (blob) {
          saveAs(blob, 'images_exported_files_' + getCurrentEpoch().toString() + '.zip')
        },
        function (err) {
          console.log(err)
        }
      )
      resolve(true)
    } else {
      reject()
    }
  })
}

export const fetchedStoresState = ({ storesByAreaToFetch, sid, area, fetchedStores }: any) => {
  const sidArray = sid
  const nextStoresToFetch = storesByAreaToFetch[area]
    ? storesByAreaToFetch[area].filter((store: any) => !sidArray.includes(store))
    : []

  const nextStores = storesByAreaToFetch

  if (!nextStoresToFetch.length) {
    delete nextStores[area]
  } else {
    nextStores[area] = nextStoresToFetch
  }

  let nextCurrentFetchedStores
  if (fetchedStores && fetchedStores[area]) {
    nextCurrentFetchedStores = [...fetchedStores[area], ...sidArray]
  } else {
    nextCurrentFetchedStores = [...sidArray]
  }

  return {
    nextStoresToFetch: JSON.parse(JSON.stringify(nextStores)),
    currentStores: JSON.parse(
      JSON.stringify({
        ...fetchedStores,
        [area]: nextCurrentFetchedStores,
      })
    ),
  }
}

export function getUrlParam(value: string) {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(value)
}

export const handleErrorWithCallback = (
  status: number,
  callbackDelay: number,
  callback: (...args: any) => any,
  ...callbackArgs: any
): any => {
  if (status === 502) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(callback(...callbackArgs))
      }, callbackDelay)
    })
  } else {
    return () => {}
  }
}

export const deleteAllCookies = () => {
  const cookies = document.cookie.split(';')
  cookies.forEach((cookie) => {
    const eqPos = cookie.indexOf('=')
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`
  })
}

export const handleError = async (status: number, callback?: () => void) => {
  if (status === 401) {
    deleteAllCookies()
    if (callback) {
      callback()
    }
  }
}

export const filterObject = (obj: any, predicate: any) => {
  return Object.keys(obj)
    .filter((key) => {
      return predicate(obj[key])
    })
    .reduce((res, key) => ((res[key] = obj[key]), res), {})
}

export const getFileName = (fullPath: string) => {
  return fullPath?.split('\\')?.pop()?.split('/').pop() || 'filename'
}

export const getStatusColor = (status: number) => {
  const statusKey = status % keys.STATUS_COLORS_LENGTH
  let statusBGColor = status !== null ? keys.STATUS_COLORS[statusKey] : 'white'
  let statusColor = status !== null ? keys.STATUS_COLORS[statusKey] : 'black'
  return { statusBGColor, statusColor }
}

export const isCoverImageValid = (coverImage: (string | null)[]) => {
  return isSolid(coverImage) && coverImage[0] !== null
}

export const getLiteral = (literals: { [key: string]: string }, literalKey: string) => {
  if (literals) {
    return literals[literalKey] ? literals[literalKey] : ''
  }

  return ''
}

export const getCurrentEpoch = () => dayjs().unix()

export const getGroupsMaxLevel = (groups: { [key: string]: IConfigGroup }): number => {
  let maxLevel = 0
  Object.keys(groups).forEach((key: string) => {
    if (groups[key].level > maxLevel) maxLevel = groups[key].level
  })

  return maxLevel
}

export const getCurrentActiveGroupIdByMaxLevel = (groups: { [key: string]: IConfigGroup }): string => {
  let currentActiveGroupID = Object.keys(groups)?.[0]
  Object.keys(groups).forEach((groupKey: string) => {
    if (groups[groupKey].level > groups[currentActiveGroupID].level) {
      currentActiveGroupID = groupKey
    }
  })

  return currentActiveGroupID
}

export const convertEpocTimeStampToDate = (createdAT: number, format: string = keys.READABLE_DATE_FORMAT) =>
  dayjs.unix(createdAT).format(format)

export const isPostLinkedToCurrentStoreTask = (tasks: any[], refID: string) => {
  if (!isSolid(tasks)) return
  let taskObjectByRefID = undefined
  taskObjectByRefID = tasks.find((item: any) => item.ref_id === refID)

  return isSolid(taskObjectByRefID)
}

export const taskObjectLinkedToPost = (tasks: any[], refID: string) => {
  if (!isSolid(tasks)) return
  let taskObjectByRefID = undefined
  taskObjectByRefID = tasks.find((item: any) => item.ref_id === refID)

  return taskObjectByRefID
}

export function addOrRemove(array: any[], value: any) {
  var index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }

  return array
}

export const sumObjectValues = (obj: object) => Object.values(obj).reduce((a, b) => a + b)

export const getTimestampAsString = (
  date = new Date(),
  outputFormat: string = keys.DEFAULT_STRING_FORMAT_TIMESTAMP,
  timezone?: string
) => {
  let currentDate = dayjs(date)
  if (timezone) {
    currentDate = currentDate.tz(timezone, true).utc()
  } else {
    currentDate = currentDate.utc()
  }
  return currentDate.format(outputFormat)
}

export const dateTimeToPercentageDone = (from: any, to: any) => {
  const current: any = getTimestampAsString()
  const result = ((current - from) / (to - from)) * 100
  return result
}

export const filterBySID = (sid: string) => (task: CleanTask) => task.rs_id.split('-')?.[1] === sid

export const filterByCategoryName = (categoryName: string) => (task: CleanTask) => {
  return task.categories_order && task.categories_order.includes(categoryName)
}

export const decomposeRetailGroupId = (rsid: string) => {
  return rsid.split(/-(.+)/)[1]
}

export const filteredTaskByArea = (tasks: any, storeKey: any, title: string) =>
  // eslint-disable-next-line array-callback-return
  tasks.find((task: any) => {
    const rsID = decomposeRetailGroupId(task?.rs_id)
    if (rsID == storeKey && title === task.title) {
      return task
    }
  })

export const isRTL = (text: string) => {
  const ltrChars =
    'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF' +
    '\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF'
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC'
  // eslint-disable-next-line no-misleading-character-class
  const rtlDirCheck = new RegExp('^[^' + ltrChars + ']*[' + rtlChars + ']')

  return rtlDirCheck.test(text)
}

export const generateID = () => Math.random().toString(36).substr(2, 9)

export const generateArrayForCommentsSkeleton = (commentsCounter: number, offset: number, limit: number) => {
  let total = limit + offset // 7
  if (total > commentsCounter) {
    let remainder = commentsCounter - offset
    total = remainder + offset
  }
  return Array.from(Array(total).keys())
}

export const sortGroupsObject = (obj: any) => {
  return Object.assign(
    Object.values(obj).sort(function (a: any, b: any) {
      return b.level - a.level
    })
  )
}

export const debounce = (func: any, wait: number) => {
  let timerId: any = undefined
  return (...args: any) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      func(...args)
    }, wait)
  }
}

export const generateUniqueName = (names: any[], providedName?: string) => {
  let defaultName = 'default_name'
  if (names.includes(defaultName) || names.includes(providedName)) {
    return defaultName + 1
  } else {
    return defaultName
  }
}

export const getUrlParamsString = (paramsObj: object) => {
  const params = new URLSearchParams()

  for (const param in paramsObj) {
    if (isSolid(paramsObj[param])) params.append(param, paramsObj[param])
  }

  return '&' + params.toString()
}

export const checkValidation = (value: any, validationRules: IValidationRules) => {
  let isValid = true

  if (!validationRules.isRequired) {
    return isValid
  }

  if (validationRules.isNumber) {
    isValid = typeof value === 'number' && isValid
  }

  if (validationRules.minLength) {
    isValid = value.length >= validationRules.minLength && isValid
  }

  if (validationRules.maxLength) {
    isValid = value.length <= validationRules.maxLength && isValid
  }

  return isValid
}
export const calcReportPercentage = (value: number, goal: number): number => {
  let percentage = (value / goal) * 100 - 100
  if (isNaN(percentage)) {
    percentage = 0
  }
  if (percentage === Infinity) {
    percentage = value === 0 ? 0 : 100
  }

  return percentage
}

export const customToFixed = (number: number, countAfterDot: number = 2): string => {
  return number.toFixed(countAfterDot).replace(/[.,]00$/, '')
}

export const getOverviewAnalytical = (
  statuses: { [key: number]: number },
  totalCounter: number
): ITaskOverviewAnalytic[] => {
  const todoPercent = (statuses[keys.TASK_STATUSES.TODO.value] / totalCounter) * 100 || 0
  const inprogressPercent = (statuses[keys.TASK_STATUSES.INPROGRESS.value] / totalCounter) * 100 || 0
  const donePercent = (statuses[keys.TASK_STATUSES.DONE.value] / totalCounter) * 100 || 0

  const analytic = [
    {
      name: 'analytical_done',
      value: Math.round(donePercent),
      color: colors.analyticGreen,
    },
    {
      name: 'analytical_inprogress',
      value: Math.round(inprogressPercent),
      color: colors.analyticYellow,
    },
    {
      name: 'analytical_todo',
      value: Math.round(todoPercent),
      color: colors.analyticGrey,
    },
  ]
  return analytic
}

export const getDoneRation = (statuses: { [key: number]: number }, totalCounter: number): number => {
  let doneRation = 0

  // Done Ratio  = done_tasks/total_number_of_tasks ration
  if (statuses[keys.TASK_STATUSES.DONE.value]) {
    doneRation = statuses[keys.TASK_STATUSES.DONE.value] / totalCounter
  }

  // options 2 : done ration  = done_tasks/total_number_of_tasks ration  + inprogress_tasks/2*total_number_of_tasks ration
  if (statuses[keys.TASK_STATUSES.INPROGRESS.value]) {
    doneRation += statuses[keys.TASK_STATUSES.INPROGRESS.value] / (2 * totalCounter)
  }

  //  * 100 === value in percents
  doneRation *= 100
  return +doneRation.toFixed(2)
}

export const generateInitialGroups = (
  allGroups: IAllUserTaskPermittedGroup,
  initialChecked: boolean = true,
  isAddParent: boolean = false
) => {
  let variants: IGroupCheckbox[] = []
  for (let key of Object.keys(allGroups)) {
    if (allGroups[key]) {
      if (allGroups[key].level === 2) {
        const parentVariantIdx = variants.findIndex((item) => item.value === key)
        if (parentVariantIdx < 0) {
          const parentId = allGroups[key].parent
          const childs: ICheckbox[] = []

          if (isAddParent) {
            childs.push({
              isHide: false,
              title: allGroups[key].name,
              isChecked: initialChecked,
              value: key,
            })
          }

          const variant: IGroupCheckbox = {
            title: allGroups[key].name,
            isChecked: initialChecked,
            child: childs,
            choosedChilds: [],
            value: key,
            level: 2,
            parentId: parentId || '',
            retailId: parentId ? allGroups[parentId]?.parent : '',
            isAllChecked: initialChecked,
          }
          variants.push(variant)
        }
      } else if (allGroups[key].level === 1) {
        const parentVariantIdx = variants.findIndex((item) => item.value === allGroups[key].parent)

        const childVariant = {
          title: allGroups[key].name,
          isChecked: initialChecked,
          value: key,
          level: 1,
        }

        if (parentVariantIdx >= 0) {
          variants[parentVariantIdx].child.push(childVariant)
          if (initialChecked) {
            variants[parentVariantIdx].choosedChilds = [...variants[parentVariantIdx].choosedChilds!, key]
          }
        } else {
          const parentKey = allGroups[key].parent
          const childs: ICheckbox[] = []

          if (isAddParent && allGroups[parentKey]) {
            childs.push({
              title: allGroups[parentKey].name,
              isChecked: initialChecked,
              value: parentKey,
            })
          }
          childs.push(childVariant)
          const variant: IGroupCheckbox = {
            title: allGroups[parentKey] ? allGroups[parentKey].name : '',
            isChecked: initialChecked,
            child: childs,
            choosedChilds: initialChecked ? [key] : [],
            value: parentKey,
            level: 2,
            isAllChecked: initialChecked,
          }
          variants.push(variant)
        }
      }
    }
  }

  return variants.map((variant) => {
    return {
      ...variant,
      countChilds: variant.child.length,
    }
  })
}

export const getTranslationsKeys = (translations: object) => {
  const BASE_TRANSLATION_KEY = 'base'
  const langKeys = Object.keys(translations).filter((key: string) => key !== BASE_TRANSLATION_KEY)

  return langKeys
}

export const getDeviceType = (): DEVICE_TYPES_ENUM => {
  const userAgent = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
    return DEVICE_TYPES_ENUM.TABLET
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      userAgent
    )
  ) {
    return DEVICE_TYPES_ENUM.MOBILE
  }
  return DEVICE_TYPES_ENUM.DESKTOP
}

export const cropText = (str: string, maxLength: number) =>
  str.length > maxLength ? `${str.slice(0, maxLength)}...` : str

export const checkIsAdmin = (userName: string) => userName.includes('admin')

export const kebabCaseToCamelCase = (str: string) => str.replace(/-./g, (x) => x.toUpperCase()[1])

export const pathNameToRouteName = (path: string) => kebabCaseToCamelCase(path).replace('/', '')

export const spreadOutOfDate = (date?: Date) => {
  const currentDate = date ? new Date(date) : new Date()
  return {
    fullYear: currentDate.getFullYear(),
    month: currentDate.getMonth(),
    day: currentDate.getDay(),
    date: currentDate.getDate(),
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes(),
    seconds: currentDate.getSeconds(),
  }
}
export const getAllCookies = (): { [key: string]: string } => {
  const cookies = {}
  document.cookie.split(';').forEach((cookie) => {
    const [key, value] = cookie.split('=')
    cookies[key] = value
  })
  return cookies
}

export const getTokenFromCookie = (): string | undefined => {
  const cookies = getAllCookies()
  const tokenKey = Object.keys(cookies).filter((cookieKey) => cookieKey.includes('token'))[0]
  return cookies[tokenKey]
}

export const setCookie = (key: string, value: string): void => {
  document.cookie = `${key}=${value}; path=/;`
}

export const refactorPartOfTime = (time: number) => (time < 10 ? `0${time}` : time)

export const getFileExtension = (fileName: string) => fileName?.split('.').pop() || ''

export const getFileNameWithoutExtension = (fileName: string) => fileName.split('.').shift()

export const isTaskDone = (subTaskIds: string[], subTasks: SubTasks) => {
  if (subTaskIds)
    for (const stId of subTaskIds) {
      const subTask = subTasks[stId]
      if (!subTask) return
      if (subTask.is_st_required) {
        if (subTask.status === null) {
          return false
        }
      }
    }
  return true
}

export const extractFileNameFromS3Link = (fileUrl: string) =>
  fileUrl.split('/').pop()?.split('_').slice(1).join('_').split('-').splice(1).join('-')

export const getStatusOfStore = (statuses?: { [key: number]: number }) => {
  if (statuses) {
    if (statuses[keys.TASK_STATUSES.DONE.value]) {
      return { status: keys.TASK_STATUSES.DONE.value, color: colors.analyticGreen }
    } else if (statuses[keys.TASK_STATUSES.INPROGRESS.value]) {
      return { status: keys.TASK_STATUSES.INPROGRESS.value, color: colors.analyticYellow }
    }
  }
  return { status: keys.TASK_STATUSES.TODO.value, color: colors.analyticGrey }
}

export const openInCloudViewer = (fileUrl: string) => {
  const fileExtension = getFileExtension(fileUrl)
  if (fileExtension) {
    return keys.extensionsForCloudViewer.includes(fileExtension) ? endpoints.cloudViewer(fileUrl) : fileUrl
  }
  return fileUrl
}

export const getAudioDuration = (url: string, callback: (dur: number) => void) => {
  const player = new Audio(url)
  player.addEventListener(
    'durationchange',
    function () {
      if (this.duration !== Infinity) {
        const duration = this.duration
        player.remove()
        callback(duration)
      }
    },
    false
  )
  player.load()
  player.currentTime = 24 * 60 * 60 //fake big time
}

export const convertDateReportStringToDate = (date: string) => {
  const dateArray = date.split('/')
  return new Date(+dateArray[2], +dateArray[1] - 1, +dateArray[0])
}

export const reportTitleToStartAndEndDate = (reportTitle: string) => {
  const dateArray = reportTitle.split('-')
  const dateArrayLength = dateArray.length
  const startDateString = dateArray[dateArrayLength - 2]
  const endDateString = dateArray[dateArrayLength - 1]
  return {
    startDateString,
    endDateString,
  }
}

export const isReportEnabled = (reportTitle: string, startDateOfRange?: Date | null, endDateOfRange?: Date | null) => {
  const { endDateString } = reportTitleToStartAndEndDate(reportTitle)
  if (startDateOfRange && endDateOfRange) {
    const endDate = convertDateReportStringToDate(endDateString)
    return (
      ((isAfter(endDate, startDateOfRange) || isEqual(endDate, startDateOfRange)) &&
        isBefore(endDate, endDateOfRange)) ||
      isEqual(endDate, endDateOfRange)
    )
  }
  return true
}

export const deploySignature = `${process.env.REACT_APP_DEPLOY_SIGNATURE}`

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getPercentageDifference = (prevValue: number, currentValue: number) =>
  !prevValue ? 0 : (currentValue / prevValue - 1) * 100

export const getSubtasks = (subTasksOrder: string[], subtkasks: SubTasks) => {
  return subTasksOrder?.reduce<SubTask[]>((acc, sabtaskId) => {
    const subtask = subtkasks[sabtaskId]
    if (subtask) {
      acc.push(subtask)
    }
    return acc
  }, [])
}

export const secondsToFormatedTime = (seconds?: number) => {
  if (!seconds) return '0:00'
  const helperDate = addSeconds(new Date(0), seconds)
  return format(helperDate, 'm:ss')
}

export const getRgId = (retailId: string, groupId: string) => `${retailId}-${groupId}`

export const populateUrl = (url: string, parameters: { [key: string]: string }) => {
  for (let key in parameters) {
    url = url.replaceAll(key, parameters[key])
  }
  return url
}

export const sortTasks = <T extends { from_ts: string }>(tasks: T[], sortingMode: string) => {
  if (sortingMode === TASK_SORTING.STARTING_DATE.toString()) {
    const sortedTasks = tasks.sort((task, nextTask) => {
      if (+task.from_ts && +nextTask.from_ts) {
        return +nextTask.from_ts - +task.from_ts
      } else {
        return new Date(nextTask.from_ts).getTime() - new Date(task.from_ts).getTime()
      }
    })
    return sortedTasks
  } else {
    return [...tasks]
  }
}

export const joinTasksViews = (tasks: IAllTasks) => {
  return Object.values(tasks).reduce<CleanTask[]>((acc, el) => [...acc, ...el?.tasks], [])
}

export const filterNotificationTasks = (tasks: CleanTask[], subTasks: SubTasks, lengthOfTasksList: number) =>
  tasks
    .filter((task) => {
      const allSubtask = Object.values(task.categories).reduce(
        (acc: string[], category) => acc && category?.sub_tasks_order && [...acc, ...category.sub_tasks_order],
        []
      )
      return !isTaskDone(allSubtask, subTasks)
    })
    .slice(0, lengthOfTasksList)

export const sortStoresByDiff = (reports: IRankingReport[], mode: Sorting) => {
  return reports.sort((report, nextReport) => {
    if (report.diff === nextReport.diff) {
      return 0
    } else if (report.diff === null) {
      return 1
    } else if (nextReport.diff === null) {
      return -1
    }
    return mode === 'asc' ? report.diff - nextReport.diff : nextReport.diff - report.diff
  })
}

export const sortStoresByRank = (reports: IRankingReport[], mode: Sorting) => {
  return reports
    .filter((report) => report.rank !== null)
    .sort((store, nextStore) => {
      if (mode === 'desc') {
        return nextStore.rank - store.rank || store.name.localeCompare(nextStore.name)
      } else {
        return store.rank - nextStore.rank || store.name.localeCompare(nextStore.name)
      }
    })
}

export const getArea = (ids: string[], groups: IConfigStateGroups) => {
  let arr: string[] = ids
  ids.forEach((id) => {
    const children = groups[id]?.child?.filter((id) => groups[id]?.child?.length)
    if (children.length) {
      arr = [...arr, ...getArea(children, groups)]
    }
  })
  return arr
}

export const getStores = (ids: string[], groups: IConfigStateGroups) => {
  const areas = getArea(ids, groups)
  return areas.map((area) => groups[area].child).flat(Infinity)
}

export const resetSecondsAndMS = (date: Date) => {
  const seconds = date.getSeconds()
  const mSeconds = date.getMilliseconds()
  return subMilliseconds(sub(date, { seconds }), mSeconds)
}

export const integrationAction = (method: string, options: { uid?: string; url?: string }) => {
  switch (method) {
    case INTEGRATION_TYPE.EZ_TIME:
      if (options.url && options.uid) {
        const populatedUrl = populateUrl(options.url, { '{uid}': options.uid })
        window.open(populatedUrl, '_blank')
      }
      break
  }
}

export const getCapsuleCalculation = ({
  leftOperand,
  rightOperand,
  createdAt,
  newValue,
}: {
  leftOperand: number
  rightOperand: number
  createdAt: number
  newValue?: number
}) => {
  return { capsuleValue: newValue || leftOperand, isNewCalculation: true }
}

export const getCapsuleColor = (value: number, goal: number) => {
  if (!value) return 'transparent'
  return value <= goal ? colors.tomato : colors.greenDark
}

export const validObjectStructure = (obj: object, keys: string[]) => {
  const objKeys = Object.keys(obj)
  return keys.every((key) => objKeys.includes(key))
}

export const getFormattedNumber = (value: number) => formatter.format(+value?.toFixed(2))

export const initialDate = { from_ts: getUnixTime(sub(new Date(), { days: 90 })), to_ts: getUnixTime(new Date()) }

export const getDateFromKpi = (value: string): Date => {
  const dateParts = value.split('/')
  const year = parseInt(dateParts[2], 10)
  const month = parseInt(dateParts[1], 10) - 1
  const day = parseInt(dateParts[0], 10)
  return new Date(year, month, day)
}
const replaceEmptyStringWithNull = (obj: any) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === '') {
        obj[key] = null
      }
    }
  }
}
export const getDifferenceOfObjects = (obj1: any, obj2: any, exceptions: string[]) => {
  const differentValues = {}
  for (const key in obj1) {
    if (exceptions.includes(key)) differentValues[key] = obj1[key]
    if (
      (!obj2?.hasOwnProperty(key) && obj1[key] !== '') ||
      (obj2?.hasOwnProperty(key) && obj2[key] !== null && obj1[key] !== obj2[key]) ||
      (obj2?.hasOwnProperty(key) && obj2[key] === null && obj1[key] !== null && obj1[key] !== '')
    ) {
      differentValues[key] = obj1[key]
    }
  }
  replaceEmptyStringWithNull(differentValues)

  return differentValues
}

export const generatePassword = (): string => {
  const regexPattern = /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*+.]){1,}).{8,26}$/
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*+.'
  const passwordLength = Math.floor(Math.random() * (26 - 8 + 1)) + 8

  let password = ''
  while (!password.match(regexPattern)) {
    password = ''
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length)
      password += characters.charAt(randomIndex)
    }
  }
  return password
}
export const findArrayDifference = (arr1: string[], arr2: string[]) => {
  const diff1 = arr1?.filter((item) => !arr2?.includes(item))
  const diff2 = arr2?.filter((item) => !arr1?.includes(item))
  const difference = diff1.concat(diff2)

  return difference
}

export const roundPercentage = (number: string) => {
  const roundedValue = parseFloat(number).toFixed(2)

  return roundedValue
}

export function mergeArrays(firstArray: any[], secondArray: any[]) {
  const mergedArray = []

  for (let i = 0; i < secondArray.length; i++) {
    const element = secondArray[i]
    let found = false

    for (let j = 0; j < firstArray.length; j++) {
      const item = firstArray[j]

      if (item.sk === element) {
        mergedArray.push(item)
        found = true
        break
      }
    }

    if (!found) {
      mergedArray.push(element)
    }
  }

  return mergedArray
}
export const isValidNumberString = (str: string): boolean => {
  const numberRegex = /^\d+$/
  const specialCharsRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?€£¥¢₽¥₩₺₹₿฿₫₴₱₸₪]/

  if (specialCharsRegex.test(str)) {
    return false
  }
  if (!numberRegex.test(str)) {
    return false
  }

  return true
}

export const isWord = (input: string): boolean => {
  const trimmedInput = input.trim()
  const isOnlyLetters = /^[\p{L}]+$/u.test(trimmedInput)

  return isOnlyLetters
}

export const wasBrowserRefreshed = () => {
  return !!sessionStorage.getItem('wasRefreshed')
}
export const isCurrencySymbol = (input: string): boolean => {
  const currencySymbols = /^[\p{Sc}]+$/u
  return currencySymbols.test(input)
}

export const updateLocalStorageItem = <T>(name: string, data: T): boolean => {
  try {
    const existingData: T = JSON.parse(localStorage.getItem(name) as string)
    if (existingData) {
      const updatedData: T = { ...existingData, ...data }
      localStorage.setItem(name, JSON.stringify(updatedData))
    } else {
      localStorage.setItem(name, JSON.stringify(data))
    }
    return true
  } catch (error) {
    console.error('Error updating data in local storage:', error)
    return false
  }
}

export const getLocalStorageItem = <T>(name: string): T | null => {
  try {
    const data: T | null = JSON.parse(localStorage.getItem(name) as string)
    return data
  } catch (error) {
    console.error('Error retrieving data from local storage:', error)
    return null
  }
}

export const checkKeyExists = (obj: any, key: string) => {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

export function trimAndCountExtraGroups({ items, sliceLimit }: { items: string; sliceLimit: number }): string {
  const splittedItems = items.split(',')
  if (splittedItems.length > sliceLimit) {
    const trimmedGroupsString = splittedItems.slice(0, sliceLimit).join(', ')
    const remainingCount = splittedItems.length - sliceLimit
    return `${trimmedGroupsString} + ${remainingCount}`
  }
  return items
}

export const getTextDirection = (text: string): 'rtl' | 'ltr' => {
  const rtlPattern = /[\u0590-\u05FF\u0600-\u06FF]/
  return rtlPattern.test(text) ? 'rtl' : 'ltr'
}

export const timastampStringToDate = (dateString: string) => {
  const formattedDateString = `${dateString.substring(0, 4)}-${dateString.substring(4, 6)}-${dateString.substring(
    6,
    8
  )}T${dateString.substring(8, 10)}:${dateString.substring(10, 12)}:${dateString.substring(12, 14)}.000Z`
  return dayjs(formattedDateString).toDate()
}

export const filterTasksByRelatedId = (obj: any, objKey: string, id: string) => {
  const filteredObj = {}

  for (const key in obj) {
    if (obj[key][objKey] === id) {
      filteredObj[key] = obj[key]
    }
  }

  return filteredObj
}

export const isValidDomain = (url: string): boolean => {
  try {
    const domainPattern = /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/
    const domain = new URL(url).hostname
    return domainPattern.test(domain)
  } catch (e) {
    console.error(e)
    return false
  }
}
export const calculateUserActivity = (users: string[] | undefined, activity: { [key: string]: boolean }) => {
  if (!users || !activity) {
    return {}
  }

  return users.reduce((acc, user) => {
    acc[user] = !!activity[user]
    return acc
  }, {})
}

const shiftChar = (char: string, shift = 3) => {
  return String.fromCharCode((char.charCodeAt(0) + shift) % 256)
}
export const encode = (string: string, shift = 3) => {
  const slicedString = string.slice(-5)
  let encoded = ''
  for (let i = 0; i < slicedString.length; i++) {
    encoded += shiftChar(slicedString[i], shift)
  }
  return encoded
}
export const decode = (encodecString: string, shift = 3) => {
  let decoded = ''
  for (let i = 0; i < encodecString.length; i++) {
    if (i < encodecString.length - 5) {
      decoded += encodecString[i]
    } else {
      decoded += shiftChar(encodecString[i], -shift)
    }
  }
  return decoded
}
export function extractSubstringAfterDelimiter(fullString: string, delimiter: string): string {
  const ridIndex = fullString.indexOf(delimiter)
  if (ridIndex === -1) {
    throw new Error('RID not found in the string')
  }
  return fullString.substring(ridIndex + delimiter.length + 1)
}

export const extractedUidsFromGroups = (groupsArray: string[], groupsObject: IConfigStateGroups) => {
  return groupsArray.flatMap((groupId) => groupsObject[groupId]?.uids)
}

export const parseDateTimeString = (dateTimeString: string): Date => {
  const year = parseInt(dateTimeString.substring(0, 4), 10)
  const month = parseInt(dateTimeString.substring(4, 6), 10) - 1
  const day = parseInt(dateTimeString.substring(6, 8), 10)
  const hours = parseInt(dateTimeString.substring(8, 10), 10)
  const minutes = parseInt(dateTimeString.substring(10, 12), 10)
  const seconds = parseInt(dateTimeString.substring(12, 14), 10)

  return new Date(year, month, day, hours, minutes, seconds)
}

type RecurrenceTimestampType = { dateNow: string; startTime: string; utc_offset: number }
export const calculateRecurrenceTimestamp = ({ dateNow, startTime, utc_offset }: RecurrenceTimestampType): string => {
  const currentDate = dayjs(dateNow, 'YYYYMMDDHHmmss')
  const currentTime = dateNow.slice(8)

  let recurrenceDatetime = currentDate
  if (currentTime < startTime) {
    recurrenceDatetime = currentDate.subtract(1, 'day')
  }

  const startHour = parseInt(startTime.slice(0, 2), 10)
  const startMinute = parseInt(startTime.slice(2, 4), 10)
  const startSecond = parseInt(startTime.slice(4, 6), 10)

  recurrenceDatetime = recurrenceDatetime.hour(startHour).minute(startMinute).second(startSecond)
  const recurrenceTimestamp = recurrenceDatetime.subtract(utc_offset, 'hour').format('YYYYMMDDHHmmss')
  return recurrenceTimestamp
}
