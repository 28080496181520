import { Button, Flex, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

export const StyledConfirmButton = styled(Button)<{ themeColor?: string }>`
  justify-content: center;
  background: ${({ themeColor }) => themeColor};
  margin: 5px;
  color: white;
  font-weight: 600;
  fon-size: 18px;
  width: max-content;
  alig-items: center;
  min-width: 150px;
  border-radius: 30px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  &:hover {
    background-color: ${({ themeColor }) => themeColor};
  }
  &:active {
    background-color: ${({ themeColor }) => themeColor};
  }
  &:focus {
    box-shadow: ${({ themeColor }) => `0 0 0 1px ${themeColor}`};
    border: ${({ themeColor }) => `1px solid ${themeColor}`};
  }
`

export const StyledGropTagWrapper = styled(Flex)`
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.05);
    background-color: #d53f8c;
  }
`
export const CustomNavLink = styled(NavLink)`
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 25%;
  &.active {
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
`
export const NavItem = styled(NavLink)`
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  @media (max-width: 1000px) {
    padding: 5px;
  }

  &:hover,
  &:focus {
    text-decoration: none;
    color: #d53f8c;
    border-bottom: 2px solid #d53f8c;
  }
`
export const FormWrapper = styled(Flex)`
  justify-content: flex-start;
  row-gap: 30px;
`
export const TextLink = styled(Text)<{ color?: string }>`
  cursor: pointer;
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${({ color }) => color || '#d53f8c'};
    border-bottom: 2px solid ${({ color }) => color || '#d53f8c'};
  }
`
