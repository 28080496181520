import { Avatar, Box, Button, Flex } from '@chakra-ui/core'
import { MessageInput } from 'components/ChatOpenAI/MessageInput'
import { MessageList } from 'components/ChatOpenAI/MessageList'
import { useWebSocket } from 'components/ChatOpenAI/useWebSocket'
import BaseModal from 'components/CommonComponents/BaseModal'
import { SwitchTabs } from 'components/CommonComponents/SwitchTabs'
import endpoints from 'constants/endpoints'
import React, { useCallback } from 'react'
import { useEffect, useState } from 'react'
import Auth from 'services/authService'
import { GPT_MODES } from './constansts'
import StoreeDoll from 'assets/doll_icon.png'
import useLocaleText from 'components/useLocaleText'
import { MarkUpText } from '../MarkUpText'
import keys from 'constants/keys'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useReportResponse } from 'hooks/useReportResponse'
import { fetchAndSetReportData } from 'redux/actions/reports'

interface IProps {
  isOpen: boolean
  handleSubmit: (popType: number | undefined) => void
  popText: string
  popInitiator: number
}
export const GPTPop = ({ isOpen, popText, popInitiator, handleSubmit }: IProps) => {
  const dispatch = useDispatch()
  const { activeGroupID, retailConfig, isConfigLoading, isConfigReady } = useSelector(
    (state: RootState) => state.config
  )
  const { reports } = useSelector((state: RootState) => state.reports)

  const [refreshToken, setRefreshToken] = useState<string | null>(null)
  const [creationMode, setCreationMode] = useState(GPT_MODES[0])
  const [shouldFetchData, setShouldFetchData] = useState(false)
  const [isDisabledButton, setIsDisabledButton] = useState(false)

  const handleGetReportData = useCallback(async () => {
    if (activeGroupID === null) return
    const queryParams = {
      reportDataKey: activeGroupID,
      groupId: activeGroupID,
      report_range_type: keys.REPORTS_DATE_OPTIONS[0].type,
      sub_retail_id: retailConfig?.sub_retail_id ?? undefined,
    }
    dispatch(fetchAndSetReportData(queryParams))
  }, [activeGroupID, dispatch, retailConfig])

  const response = useReportResponse({
    reportConfig: retailConfig?.report_config_new ?? null,
    reportData: reports[activeGroupID!]?.[0] ?? null,
  })

  const { messages, isBotTyping, handleSend } = useWebSocket({
    reportsRequestStr: response,
    socketAddress: endpoints.socketGPT(refreshToken),
    refreshToken,
    shouldTypeFirtMessage: false,
    shouldFetchData,
    referrer: 1,
  })

  const t_yes_btn = useLocaleText('gpt_pop_yes_btn')
  const t_no_btn = useLocaleText('gpt_pop_no_btn')

  const onToggePostMode = (creationMode: { title: string; value: number }) => {
    setCreationMode(creationMode)
  }

  const onSubmit = () => {
    setShouldFetchData(true)
    handleSend(t_yes_btn)
    setIsDisabledButton(true)
  }
  const handleClosePOP = () => {
    handleSubmit(keys.POP_TYPE.GPT_POP)
  }

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await Auth.getUpdatedToken()
        if (typeof token === 'string') {
          setRefreshToken(token)
        }
      } catch (error) {
        console.error('Error fetching the token:', error)
      }
    }

    fetchToken()
  }, [])

  useEffect(() => {
    if (!isConfigLoading && isConfigReady) handleGetReportData()
  }, [handleGetReportData, isConfigLoading, isConfigReady])

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={handleClosePOP}
      marginTop="50px"
      borderRadius="20px"
      closeOnEsc={false}
      px="0"
      maxWidth="55%"
      overflowY="auto"
      height="85vh"
      isShowAnimation={true}
      headerContent={
        <SwitchTabs
          items={GPT_MODES}
          isSwitchDisabled={true}
          onToggePostMode={onToggePostMode}
          creationMode={creationMode}
          title={''}
        />
      }
      bodyContent={
        <Flex width="100%" dir="ltr" flexDir="column" px="10px">
          <Flex justifyContent={'flex-start'} mx="10px" mb="10px">
            <Flex maxW={'70%'} flexDir={'row'} style={{ columnGap: '15px' }}>
              <Avatar w="4rem" h="3.4rem" background="transparent" src={StoreeDoll} />
              <Flex
                flexDir={'column'}
                bg={'#ffe6f2'}
                mt="20px"
                minW="200px"
                borderBottomRightRadius="20px"
                borderBottomLeftRadius="20px"
                borderTopLeftRadius={'0'}
                borderTopRightRadius={'20px'}
                p="1rem"
              >
                <MarkUpText text={popText} initiator={popInitiator} />
                <Flex justifyContent="space-between" mt="1rem">
                  <Button
                    bg="white"
                    minW="48%"
                    onClick={onSubmit}
                    _focus={{ boxShadow: 'none' }}
                    isDisabled={isDisabledButton}
                  >
                    {t_yes_btn}
                  </Button>
                  <Button bg="white" minW="48%" onClick={handleClosePOP} isDisabled={isDisabledButton}>
                    {t_no_btn}
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <MessageList messages={messages} />
        </Flex>
      }
      footerContent={
        <Box w={'100%'} m="15px">
          {shouldFetchData && <MessageInput handleSend={handleSend} loading={isBotTyping} />}
        </Box>
      }
    />
  )
}
