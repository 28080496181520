import { Box, CheckboxGroup, Flex, Skeleton, Stack, Text } from '@chakra-ui/core'
import React, { useState, useEffect, useCallback } from 'react'
import { RadioCheckbox } from './RadioCheckbox'
import { Result, RootState } from 'constants/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { getAllResults, updateResults } from 'redux/actions/tasks_v2'
import { buildPollOptions } from '../utils'
import { VotedParticipantsAvatars } from '../ui/VotedParticipantsAvatars'
import { AppDispatch } from 'config/redux'
import keys from 'constants/keys'
import colors from 'constants/colors'

interface IProps {
  options: { [key: string]: string }
  isMultiple: boolean
  pollResults: Result[]
  st_id: string
  tid: string
  contextId: string
  isSpecificResult: boolean
}

export const Votes: React.FC<IProps> = ({
  options,
  isMultiple,
  pollResults,
  st_id,
  tid,
  contextId,
  isSpecificResult,
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { isLoading, allResults, insights } = useSelector((state: RootState) => state.tasks_v2)
  const { users: permittedUsers, groups: permittedGroups } = useSelector(
    (state: RootState) => state.config.userWriteTaskPermissions
  )
  const { groups, retailUsersObject } = useSelector((state: RootState) => state.config.config)

  const [selectedValues, setSelectedValues] = useState<number[]>(
    isSpecificResult
      ? allResults?.[tid].find((result) => result.st_id === st_id && result.context_id === contextId)
          ?.poll_selections || []
      : []
  )
  const optionsArray = buildPollOptions({
    options,
    pollResults,
    permittedUsers,
    permittedGroups,
    insights,
    groups,
    retailUsersObject: retailUsersObject!,
  })
  const totalVotes = pollResults.filter((result) => result.st_id === st_id).length

  const handleChange = (index: number) => {
    let newSelectedValues: number[] = []

    if (isMultiple) {
      newSelectedValues = selectedValues.includes(index)
        ? selectedValues.filter((value) => value !== index)
        : [...selectedValues, index]
    } else {
      newSelectedValues = selectedValues.includes(index) ? [] : [index]
    }

    setSelectedValues(newSelectedValues)

    const status = newSelectedValues.length > 0 ? 2 : 1

    dispatch(
      updateResults({
        votes: newSelectedValues,
        tid,
        st_id,
        type: keys.SUBTASK_TYPES.POLL_SUBTASK,
        specificContextId: isSpecificResult ? contextId : null,
        status: status,
      })
    )
  }

  const handleGetAllResults = useCallback(async () => {
    const result = await dispatch(getAllResults({ tid }))
    const selectedOptions =
      result?.[tid].find((result) => result.st_id === st_id && result.context_id === contextId)?.poll_selections || []
    setSelectedValues(selectedOptions)
  }, [contextId, dispatch, st_id, tid])

  useEffect(() => {
    if (isSpecificResult) return
    handleGetAllResults()
  }, [contextId, dispatch, handleGetAllResults, isSpecificResult, tid])

  return (
    <CheckboxGroup value={selectedValues}>
      <Stack direction="column" spacing={6}>
        {optionsArray.map(({ label, count, currentQuestionParticipants }, index) => (
          <Box key={index}>
            {isLoading ? (
              <Skeleton height="23px" borderRadius="10px" />
            ) : (
              <>
                <RadioCheckbox
                  value={label}
                  isChecked={selectedValues.includes(index)}
                  onChange={() => handleChange(index)}
                >
                  <Flex alignItems="flex-end" justifyContent="space-between" w="100%">
                    <Text fontWeight="500" fontSize="18px" fontFamily="Arial">
                      {label}
                    </Text>
                    <Flex alignItems="center" h="24px">
                      <VotedParticipantsAvatars participants={currentQuestionParticipants} />
                      {count > 0 && (
                        <Text fontWeight="bold" fontSize="12px" mx="5px">
                          {count > 4 ? `+${currentQuestionParticipants.length - 4}` : count}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </RadioCheckbox>
              </>
            )}
            {isLoading ? (
              <Skeleton w="calc(100% - 25px)" h="14px" mx="25px" mt="8px" borderRadius="5px" />
            ) : (
              <Box bg="gray.200" w="calc(100% - 25px)" h="14px" mx="25px" mt="8px" borderRadius="5px">
                <Box
                  bg={colors.greenPoll}
                  h="14px"
                  borderRadius="5px"
                  width={totalVotes > 0 ? `${(count / totalVotes) * 100}%` : '0%'}
                  transition="width 0.5s ease"
                />
              </Box>
            )}
          </Box>
        ))}
      </Stack>
    </CheckboxGroup>
  )
}
