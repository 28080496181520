import { Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { NavLink } from 'react-router-dom'

export const CustomNavLink = styled(NavLink)`
  color: gray;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 100%;
  width: 100%;
  &:hover {
    text-decoration: none;
    color: gray;
  }
  ,
  &:focus {
    text-decoration: none;
    color: gray;
  }
  ,
  &:active {
    text-decoration: none;
    color: gray;
  }
`

export const KpisContainer = styled(Flex)`
  position: relative;
  flex-direction: column;
  @media (min-width: 1601px) {
    1200px;
  }
  @media (min-width: 1401px) {
    width: 1000px;
  }
  @media (max-width: 1400px) {
    width: 800px;
  }
  @media (max-width: 1200px) {
    width: 600px;
  }
`
