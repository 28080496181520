import { handleError } from 'utils'
import axios, { AxiosInstance } from 'axios'
import { baseURL } from '../constants/endpoints'
import Auth from 'services/authService'

const commonConfig = {
  baseURL,
  timeout: 60000,
}

export const axiosInstance = axios.create(commonConfig)
export const httpAuth = axios.create(commonConfig)
export const httpConfig = axios.create(commonConfig)

axiosInstance.interceptors.request.use((request) => {
  return request
})

httpAuth.interceptors.request.use(async (request) => {
  const refreshToken = await Auth.getUpdatedToken()
  request.headers.Authorization = refreshToken
  return request
})

httpAuth.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response.status === 401) {
      handleError(error.response.status)
    }
    return Promise.reject(error)
  }
)

export const http = (axiosInstance: AxiosInstance, token?: string | null) => {
  if (token) axiosInstance.defaults.headers.Authorization = token
  else axiosInstance.defaults.headers.Authorization = ''
  return axiosInstance
}

export const setAuthTokenInHeader = (token: any) => {
  httpAuth.defaults.headers.common.Authorization = `${token}`
}
