import React, { memo, useMemo } from 'react'
import { Box, Flex, Text, Image, Menu, MenuButton, MenuList, MenuItem, useDisclosure } from '@chakra-ui/core'
import { IComment, RootState } from 'constants/interfaces'
import { useUserName } from 'hooks/useUserName'
import { DateText } from 'components/Elements'
import { calculateUserActivity, convertEpocTimeStampToDate } from 'utils'
import { renderTextWithLineBreaks } from 'components/Post/PostTitle'
import { UploadedFileList } from 'components/UploadedFileList'
import { AudioPlayer } from 'components/AudioPlayer'
import LocaleText from 'components/LocaleText'
import { ReactComponent as OverflowMenu } from 'assets/overflow-menu.svg'
import colors from 'constants/colors'
import { CommentButton } from 'components/Post/CommentButton'
import { Post } from 'redux/reducers/feed'
import keys from 'constants/keys'
import { LikeButton } from 'components/Post/LikeButton'
import { ViewButton } from 'components/Post/ViewButton'
import { useSelector } from 'react-redux'
import LikesModal from 'components/Post/LikesModal'
import { ViewsModal } from 'components/Post/ViewsModal'
import { SecretID } from 'components/CommonComponents/SecretID'

interface IChatMessage {
  message: IComment
  isUserMessage: boolean
  deleteCommentHandler: (cid: string) => void
  onChatClick?: (commentId: string) => void
  currentChat?: Post
  commentsCounter?: number
  commentsCounterLoading?: boolean
  viewedIds?: string[]
}
export const ChatMessage = memo(
  ({
    message,
    isUserMessage,
    currentChat,
    deleteCommentHandler,
    onChatClick,
    commentsCounter = 0,
    commentsCounterLoading = false,
    viewedIds,
  }: IChatMessage & {}) => {
    const { uid } = useSelector((state: RootState) => state.auth)
    const {
      encodedUID,
      config: { permission },
    } = useSelector((state: RootState) => state.config)
    const { message: text, created_by, created_at_ts, videos, audios, images, files } = message
    const userName = useUserName(created_by)
    const { likes, views } = useSelector((state: RootState) => state.comments.commentsActivity[message.cid] || {})
    const { isOpen: isLikesModalOpen, onOpen: onLikesModalOpen, onClose: onCloseLikesModal } = useDisclosure()
    const { isOpen: isViewModalOpen, onOpen: onViewModalOpen, onClose: onCloseViewModal } = useDisclosure()

    const likesCounter = useMemo(() => (likes && Object.keys(likes)?.length) ?? 0, [likes])
    const viewsCounter = useMemo(() => (views && Object.keys(views).length) ?? 0, [views])

    const users = Array.from(new Set([...(currentChat?.group_users ?? []), ...(currentChat?.post_origin_users ?? [])]))
    const viewed = useMemo(() => calculateUserActivity(users, views), [users, views])
    const liked = useMemo(() => calculateUserActivity(users, likes), [likes, users])

    const isAdminCommentsPermission = permission?.comments === keys.USER_ACTIONS_MAP.APP_ADMIN

    return (
      <>
        <Flex
          onClick={() => onChatClick?.(message.cid)}
          bg={isUserMessage ? 'blue.200' : '#ffe6f2'}
          flexDir="column"
          alignItems={isUserMessage ? 'flex-end' : 'flex-start'}
          borderBottomRightRadius="20px"
          borderBottomLeftRadius="20px"
          borderTopLeftRadius={isUserMessage ? '20px' : '0'}
          borderTopRightRadius={isUserMessage ? '0' : '20px'}
          p="10px"
          mt="10px"
        >
          <Flex
            alignItems="center"
            minW="250px"
            w="100%"
            flexDir={isUserMessage ? 'row-reverse' : 'row'}
            justifyContent="space-between"
          >
            <Text fontSize="14px" fontWeight="600" color="black" maxW="85%">
              {userName}
            </Text>
            {(isUserMessage || isAdminCommentsPermission) && (
              <Menu>
                <MenuButton
                  outline="none"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  <OverflowMenu />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => deleteCommentHandler(message.cid)} color={colors.red}>
                    <LocaleText text="delete" />
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
          </Flex>
          {!!currentChat && currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT && (
            <Flex alignItems="center" style={{ columnGap: '5px' }} flexDir={isUserMessage ? 'row-reverse' : 'row'}>
              <DateText>{convertEpocTimeStampToDate(created_at_ts)}</DateText>
              <SecretID value={encodedUID} />
            </Flex>
          )}
          <Text
            my="5px"
            wordBreak={'break-word'}
            fontFamily="inherit"
            fontSize="14px"
            minW="40px"
            textAlign={isUserMessage ? 'right' : 'left'}
          >
            {text !== '' && renderTextWithLineBreaks({ text })}
          </Text>
          {videos &&
            videos.length > 0 &&
            videos.map((video) => {
              return (
                <Flex key={video} my="3" maxHeight="200px" objectFit="contain">
                  <video src={video} controls />
                </Flex>
              )
            })}
          {images && images.length > 0 && (
            <Box px="5px" py="5px">
              <Image src={images[0]} w="100%" m="0 auto" objectFit="cover" />
            </Box>
          )}

          {audios && audios.map((audioURI) => <AudioPlayer key={audioURI} src={audioURI} />)}
          {files && <UploadedFileList fileList={files} />}
          {!!currentChat && currentChat.type !== keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT && (
            <Flex alignItems="center" style={{ columnGap: '5px' }} flexDir={isUserMessage ? 'row-reverse' : 'row'}>
              <DateText>{convertEpocTimeStampToDate(created_at_ts)}</DateText>
              <SecretID value={encodedUID} />
            </Flex>
          )}
          {!!currentChat && currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT && (
            <Flex w="100%" justifyContent="flex-end">
              <LikeButton
                created_by={message.created_by}
                likes_counter={likesCounter}
                liked={liked}
                isShowLikeButton={message.created_by !== uid}
                isLikedPost={likes?.[uid!] ?? false}
                isDoublePost={false}
                isAllPostCollapsed={true}
                handleLikesModal={onLikesModalOpen}
              />

              <ViewButton
                viewed={viewed}
                views_counter={viewsCounter}
                created_by={message.created_by}
                isShowViewIcon={message.created_by !== uid}
                isViewed={views?.[uid!] ?? false}
                isDoublePost={false}
                isAllPostCollapsed={true}
                openModal={onViewModalOpen}
              />

              <CommentButton
                postId={message.cid}
                commentsCounterLoading={commentsCounterLoading}
                showCommentsSection={false}
                commentsCounter={commentsCounter}
                isViewed={true}
                isDoublePost={false}
                isAllPostCollapsed={true}
              />
            </Flex>
          )}
        </Flex>
        {isLikesModalOpen && (
          <LikesModal
            isOpen={isLikesModalOpen}
            onClose={onCloseLikesModal}
            likes_counter={likesCounter}
            created_by={message.created_by}
            liked={liked}
            isShowPings={false}
          />
        )}
        {isViewModalOpen && (
          <ViewsModal
            isOpen={isViewModalOpen}
            onClose={onCloseViewModal}
            views_counter={viewsCounter}
            created_by={message.created_by}
            viewed={viewed}
          />
        )}
      </>
    )
  }
)
