import { Button, useDisclosure } from '@chakra-ui/core'
import CreateTaskModal from 'components/CreateTask/CreateTaskModal'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { resetCreateTaskState } from 'redux/actions/createTask'

export const CreateTaskButton = () => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { groups } = useSelector((state: RootState) => state.config.userWriteTaskPermissions)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { isOpen: isOpenCreateTask, onOpen: onOpenCreateTask, onClose: onCloseCreateTask } = useDisclosure()
  const handleCreateTask = () => {
    dispatch(resetCreateTaskState())
    onOpenCreateTask()
  }
  return (
    <>
      <Button
        borderRadius="12px"
        h="50px"
        w="170px"
        boxShadow="0px 2px 2px -2px #616770;"
        fontSize="18px"
        dir="ltr"
        bg="white"
        color={theme?.elementsColor}
        onClick={handleCreateTask}
        type="submit"
        _active={{ bg: theme?.backgroundLight }}
        _hover={{ bg: theme?.backgroundLight }}
        _focus={{ boxShadow: 'none' }}
        isDisabled={pathname === keys.ROUTE_NAMES.CREATE_TASK || !groups}
        _disabled={{
          background: colors.greyLight,
          color: colors.greyLight2,
        }}
      >
        <LocaleText text="create_task_btn" />
      </Button>

      {isOpenCreateTask && <CreateTaskModal isOpen={isOpenCreateTask} onClose={onCloseCreateTask} />}
    </>
  )
}
