import { Avatar, Flex, useToast } from '@chakra-ui/core'
import { IComment, RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'
import React, { memo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChatMessage } from './ChatMessage'
import useComments from 'hooks/useComments'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCommentActivity, setCommentsCounter } from 'redux/actions/comments'
import { Post } from 'redux/reducers/feed'
import keys from 'constants/keys'

interface IChatItemProps {
  message: IComment
  currentChat: Post
}
export const ChatItem = memo(({ message, currentChat }: IChatItemProps) => {
  const { id } = useParams<Params<string>>()
  const query = new URLSearchParams(useLocation().search)
  const isPrefetched = query.get('prefetched')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const avatar = useAvatar(message.created_by)
  const { uid } = useSelector((state: RootState) => state.auth)
  const { isFetching } = useSelector((state: RootState) => state.comments)
  const { commentsCounter, deleteComment } = useComments(id!)
  const {
    fetchCommentsCounter: fetchReliesCounter,
    commentsCounter: repliesCounter,
    commentsCounterLoading: repliesCounterLoading,
  } = useComments(message.cid, true)

  const replies = useSelector((state: RootState) => state.comments.repliesState?.[message.cid]?.items || [])
  const isUserMessage = message.created_by === uid

  const deleteCommentHandler = async (cid: string) => {
    if (window.confirm('Are you sure ?')) {
      try {
        deleteComment({ id: id!, cid: cid, isReplyed: false })
        toast({
          title: 'Comment deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })

        dispatch(
          setCommentsCounter({
            count: commentsCounter - (replies.length || 0) - 1,
            key: id!,
            isReply: false,
          })
        )
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const onChatClick = (commentId: string) => {
    if (
      currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT &&
      message.object_type !== keys.COMMENT_OBJECT_TYPE.COMMENT_ON_ANNOUNCEMENT_GROUP_MESSAGE
    ) {
      navigate(keys.ROUTE_NAMES.GROUP_CHAT_ANNOUNCEMENT(id, commentId + `${isPrefetched ? '?prefetched={true}' : ''}`))
    }
  }

  useEffect(() => {
    if (currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT && !isFetching && !message.isDummy) {
      dispatch(getCommentActivity(message.cid))
      fetchReliesCounter(message.cid, id)
    }
  }, [currentChat.type, dispatch, fetchReliesCounter, id, isFetching, message])

  return (
    <Flex
      flexDirection={isUserMessage ? 'row-reverse' : 'row'}
      justifyContent="space-between"
      mx="10px"
      mb="30px"
      h="max-content"
      pos="relative"
    >
      <Flex maxW={'70%'} flexDir={isUserMessage ? 'row-reverse' : 'row'} style={{ columnGap: '15px' }}>
        <Avatar w="50px" h="50px" src={avatar} />
        <ChatMessage
          onChatClick={onChatClick}
          message={message}
          isUserMessage={isUserMessage}
          deleteCommentHandler={deleteCommentHandler}
          currentChat={currentChat}
          commentsCounter={repliesCounter}
          commentsCounterLoading={repliesCounterLoading}
        />
      </Flex>
    </Flex>
  )
})
