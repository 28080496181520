import { Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import React from 'react'

export const NoResults = () => {
  return (
    <Flex alignItems="center" justifyItems="center" width="100%" height="100%" flexDirection="column">
      <Text marginTop="10%" marginBottom="10%" fontWeight="700" color={colors.greyDark}>
        <LocaleText text="choose_assigment_seach_no_results" />
      </Text>
    </Flex>
  )
}
