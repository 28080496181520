import { Button, Flex } from '@chakra-ui/core'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import React, { memo } from 'react'
import { FiUsers } from 'react-icons/fi'
import { GoTriangleDown } from 'react-icons/go'

interface IProps {
  namesForTooltip: string[]
  groupNames: string
  setIsAudienceOptionsModalOpen: (value: boolean) => void
  clearSelections: () => void
  isEditing: boolean
}
export const ChooseAudienceButton = memo(
  ({ namesForTooltip, groupNames, clearSelections, setIsAudienceOptionsModalOpen, isEditing }: IProps) => {
    const handleClick = () => {
      setIsAudienceOptionsModalOpen(true)
      !isEditing && clearSelections()
    }

    return (
      <Flex cursor="pointer" flexDirection="row" style={{ columnGap: '10px' }}>
        <TooltipList items={namesForTooltip}>
          <Button
            onClick={handleClick}
            variant="outline"
            fontWeight={600}
            leftIcon={FiUsers}
            background="white"
            border="1px solid"
            borderRadius="10px"
            h="40px"
            mt="5px"
            fontSize="14px"
            rightIcon={GoTriangleDown}
            dir="ltr"
            px={{
              lg: 4,
              base: 1,
            }}
            size="xs"
          >
            {groupNames}
          </Button>
        </TooltipList>
      </Flex>
    )
  }
)
