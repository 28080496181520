import React, { memo, useMemo, useState } from 'react'
import { Button, useDisclosure, Text, Flex } from '@chakra-ui/core'
import { FbGrid } from '../CommonComponents/FbGrid'
import FsLightbox from 'fslightbox-react'
import { ButtonBox, ButtonBoxWrapper, PostDeleteButton } from '../helperStyles'
import { BsCardImage, BsPencil } from 'react-icons/bs'
import { MediaPostModal } from './MediaPostModal'
import { getFileExtension } from 'utils'
import strings from 'constants/strings'
import { IoCloseCircleOutline } from 'react-icons/io5'

interface IProps {
  media?: string[]
  addMedia?: VoidFunction
  deleteAll?: VoidFunction
  onDeleteMedia?: (mediaIdx: number) => void
  children: React.ReactNode
  isEditable?: boolean
  isPopImage?: boolean
  px?: string
}

export const PostMediaGrid = memo(
  ({
    media,
    onDeleteMedia,
    deleteAll,
    addMedia,
    children,
    isEditable = true,
    isPopImage = false,
    px = '50px',
  }: IProps) => {
    const { isOpen: isOpenLightBox, onToggle: onToggleLightBox } = useDisclosure()
    const { isOpen: isOpenMediaModal, onOpen: onOpenMediaModal, onClose: onCloseMediaModal } = useDisclosure()

    const [currentImageIdx, setCurrentImageIdx] = useState(0)

    const openImageModalHandler = (imageIdx: number) => {
      setCurrentImageIdx(imageIdx)
      onToggleLightBox()
    }

    const deleteMediaHandler = (idx: number) => {
      if (!onDeleteMedia) return
      onDeleteMedia(idx)
    }

    const mediaTypes = useMemo(
      () =>
        media?.map((el) => {
          const fileExt = getFileExtension(el)
          return strings.SERVER_VIDEO_FORMATS.includes(fileExt) ? 'video' : 'image'
        }),
      [media]
    )

    return (
      <>
        <ButtonBoxWrapper px={px} mx="auto" mt="5px" mb="10px">
          {isEditable && (
            <ButtonBox className="postModal__ButtonBox">
              <>
                {!isPopImage && (
                  <>
                    <Button size="md" fontWeight="500" onClick={addMedia}>
                      <BsCardImage />
                      <Text mx="2">Add Photos/Videos</Text>
                    </Button>
                    <Button size="md" fontWeight="500" onClick={onOpenMediaModal}>
                      <BsPencil />
                      <Text mx="2">Edit</Text>
                    </Button>
                  </>
                )}

                <Button onClick={deleteAll}>
                  <IoCloseCircleOutline size="25px" />
                  <Text mx="2">Delete</Text>
                </Button>
              </>
            </ButtonBox>
          )}
          <FbGrid onClickToItem={openImageModalHandler}>{children}</FbGrid>
        </ButtonBoxWrapper>
        <MediaPostModal isOpen={isOpenMediaModal} onClose={onCloseMediaModal}>
          {React.Children.map(children, (child, idx) => {
            const ChildClone =
              React.isValidElement(child) && child.type === 'video'
                ? React.cloneElement(child, { controls: true })
                : child
            return (
              <Flex width="100%" mb="10px" maxHeight="300px" position="relative" overflow="hidden">
                <PostDeleteButton onClick={() => deleteMediaHandler(idx)} tabIndex={0} role="button" />
                {ChildClone}
              </Flex>
            )
          })}
        </MediaPostModal>
        <FsLightbox
          key={JSON.stringify(media)}
          toggler={isOpenLightBox}
          sources={media}
          sourceIndex={currentImageIdx}
          types={mediaTypes}
        />
      </>
    )
  }
)
