import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'

export const useUserName = (uid: string) => {
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const firstName = retailUsersObject?.[uid]?.first_name
  const lastName = retailUsersObject?.[uid]?.last_name ? retailUsersObject?.[uid]?.last_name : ''
  const userName = firstName + ' ' + lastName
  return firstName ? userName : ''
}
