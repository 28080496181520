import { Box, Button, Flex, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import React from 'react'
import { BsExclamationCircle } from 'react-icons/bs'
import { StyledConfirmButton } from '../UI/CustomComponents'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface ICancelAction {
  onClose: () => void
}
export const CancelAction = ({ onClose }: ICancelAction) => {
  const navigate = useNavigate()
  const theme = useSelector((state: RootState) => state.general.theme)
  const admin_cancel_confirm = useLocaleText('admin_cancel_confirm')
  const admin_no_cancel = useLocaleText('admin_no_cancel')
  const admin_yes_cancell_buton = useLocaleText('admin_yes_cancel_button')
  const { isFetching } = useSelector((state: RootState) => state.admin.userTable)
  const handleConfirm = () => {
    onClose()
    navigate(-1)
  }

  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      <Box as={BsExclamationCircle} size="100px" color={theme?.elementsColor} />

      <Text my="18px" fontWeight="600" color="#a2a2b6" fontSize="22px" textAlign="center">
        {admin_cancel_confirm}
      </Text>
      <Flex flexDir="row" mt="40px" width="70%" justifyContent="space-around" alignItems="center">
        <Button
          _hover={{ backgroundColor: 'none', color: '#4299e199' }}
          _focus={{ boxShadow: 'none' }}
          backgroundColor="none"
          color="#a2a2b6"
          onClick={() => onClose()}
        >
          {admin_no_cancel}
        </Button>
        <StyledConfirmButton isDisabled={isFetching} onClick={handleConfirm} themeColor={theme?.elementsColor}>
          {admin_yes_cancell_buton}
        </StyledConfirmButton>
      </Flex>
    </Flex>
  )
}
