import { Button, Flex, Text } from '@chakra-ui/core'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Search } from './Search'
import { ClearSelectedItems } from './ClearSelectedItems'
import { ListNode } from './ListNode'
import { multipleIdsSelection, singleIdsSelection } from './utils'
import LocaleText from 'components/LocaleText'
import { SkeletonText } from '../SkeletonText'
import keys from 'constants/keys'
import { NoResults } from '../GroupsTree/NoResults'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { RootState } from 'constants/interfaces'

export type Item = {
  id: string
  name: string
  image?: string
  groups?: string[]
}
interface IProps {
  itemsDataArray: Item[]
  mode?: string
  minW?: string
  menuButtonLiteral?: string
  isLoading?: boolean
  isShowSearch?: boolean
  selectedIds?: string[]
  isShowClear?: boolean
  actionButtonIcon?: any | null
  onSubmit?: (items: string[]) => void
  resettingId?: string
  btnH?: string
  btnW?: string
  left?: string
}
export const SelectDropdown = memo(
  ({
    itemsDataArray,
    mode = keys.SELECT_MODES.MULTIPLE,
    selectedIds,
    minW = '330px',
    menuButtonLiteral = 'user_level_report_filter',
    isLoading = false,
    isShowSearch = false,
    isShowClear = true,
    actionButtonIcon = null,
    onSubmit,
    resettingId,
    btnH,
    btnW,
    left = '-100px',
  }: IProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [checkedIds, setCheckedIds] = useState<string[]>(selectedIds || [])
    const isRtl = useSelector(isRtlSelector)
    const theme = useSelector((state: RootState) => state?.general?.theme)
    const [searchText, setSearchText] = useState<string>('')
    const dropdownRef = useRef<HTMLDivElement>(null)

    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false)
        setCheckedIds(selectedIds || [])
      }
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
      // eslint-disable-next-line
    }, [])

    const handleSubmit = () => {
      setIsOpen(false)
      onSubmit?.(checkedIds)
    }
    const handleClearCheckedItems = () => {
      setCheckedIds([])
    }

    const handleCheckIds = (currentId: string) => {
      if (mode === keys.SELECT_MODES.SINGLE) {
        setCheckedIds(singleIdsSelection({ checkedIds, currentId }))
      } else {
        setCheckedIds(multipleIdsSelection({ checkedIds, currentId, resettingId, itemsDataArray }))
      }
    }

    const items = useMemo(() => {
      if (searchText && isShowSearch) {
        return itemsDataArray.filter((item: Item) => {
          const lowerSearchText = searchText.toLowerCase()
          const itemNameLower = item.name.toLowerCase()

          if (item.name && item.groups) {
            const groupsMatch = item.groups.some((group) => group.toLowerCase().includes(lowerSearchText))
            return itemNameLower.includes(lowerSearchText) || groupsMatch
          }

          return itemNameLower.includes(lowerSearchText)
        })
      } else {
        return itemsDataArray
      }
    }, [isShowSearch, itemsDataArray, searchText])

    const controlButtonTitle = useMemo(() => {
      if (selectedIds?.length === 0 || !selectedIds) {
        return <LocaleText text={menuButtonLiteral} />
      }

      const selectedItemName = items.filter((item) => item.id === selectedIds[0])[0]?.name
      if (selectedIds?.length === 1) {
        return <Text>{selectedItemName}</Text>
      }
      if (selectedIds && selectedIds?.length > 1) {
        return (
          <Text>
            {selectedItemName} + {selectedIds.length - 1}
          </Text>
        )
      }
    }, [items, menuButtonLiteral, selectedIds])

    return (
      <Flex pos="relative" ref={dropdownRef}>
        <Button
          isDisabled={isLoading}
          dir="ltr"
          bg="white"
          fontSize="12px"
          borderRadius="20px"
          minW="unset"
          w={btnW ? btnW : '100%'}
          p={actionButtonIcon ? '0' : '6px'}
          rightIcon={actionButtonIcon ? undefined : 'chevron-down'}
          height={btnH ? btnH : 'auto'}
          onClick={() => setIsOpen(!isOpen)}
          _focus={{ boxShadow: 'none' }}
          _hover={{ bg: theme?.backgroundLight }}
          _active={{ bg: theme?.backgroundLight }}
        >
          {actionButtonIcon ? (
            actionButtonIcon
          ) : (
            <SkeletonText isLoaded={!isLoading}>{controlButtonTitle}</SkeletonText>
          )}
        </Button>
        {isOpen && (
          <Flex
            flexDir="column"
            borderRadius="12px"
            pos="absolute"
            bg="white"
            top="50px"
            left={isRtl ? '0' : 'unset'}
            right={isRtl ? 'unset' : '0'}
            minW={minW}
            boxShadow="0px 0px 1px #8e8d9d"
            zIndex={2000}
            alignItems="center"
          >
            {isShowSearch && <Search searchText={searchText} setSearchText={setSearchText} />}
            {isShowClear && items.length > 0 && (
              <ClearSelectedItems
                handleClearCheckedItems={handleClearCheckedItems}
                disabled={checkedIds.length === 0}
              />
            )}

            <Flex maxHeight="500px" overflowY="auto" flexDir="column">
              {items.length > 0 ? (
                items?.map((item: any, i: number) => (
                  <ListNode key={i} handleCheckIds={handleCheckIds} checkedIds={checkedIds} item={item} mode={mode} />
                ))
              ) : (
                <NoResults />
              )}
            </Flex>
            {items.length > 0 && (
              <Button
                w="80%"
                h="25px"
                my="15px"
                isDisabled={isLoading}
                onClick={() => handleSubmit()}
                bg={theme?.elementsColor}
                color="white"
                fontSize="12px"
                borderRadius="12px"
                _hover={{ bg: theme?.elementsColor }}
                _active={{ bg: theme?.elementsColor }}
                _focus={{ boxShadow: 'none' }}
              >
                <LocaleText text="select_group_select_button" />
              </Button>
            )}
          </Flex>
        )}
      </Flex>
    )
  }
)
