import { Switch as DefaultSwitch } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
interface IProps {
  isChecked: boolean
  onToggle: (e?: React.ChangeEvent<HTMLInputElement>) => void
  color?: string
  isDisabled?: boolean
  size?: 'sm' | 'md' | 'lg'
}
const Switch = styled(DefaultSwitch)<{ color?: string; hoverColor?: string }>`
  > * {
    direction: ltr;
  }
  input[type='checkbox']:checked + div {
    background-color: ${(props) => props.color};
    border-color: ${(props) => props.color};
  }
  &:focus {
    box-shadow: none !important;
  }
`

export const CustomSwitch = ({ isChecked, onToggle, isDisabled = false, size = 'md' }: IProps) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <Switch
      onChange={() => onToggle()}
      size={size}
      dir="ltr"
      isChecked={isChecked}
      isDisabled={isDisabled}
      color={theme?.elementsColor}
      hoverColor={theme?.backgroundLight}
    />
  )
}
