import { Avatar, Box, Flex, Input, useToast } from '@chakra-ui/core'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'
import { useFileValidation } from 'hooks/useFileValidation'
import useUploadService from 'hooks/useUploadService'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import imagePlaceholderAnimated from 'assets/placeholder-640.svg'
import { getFileExtension } from 'utils'
import { updateUser } from 'redux/actions/admin'
import LocaleText from 'components/LocaleText'
import keys from 'constants/keys'
import strings from 'constants/strings'
import colors from 'constants/colors'

export const UserDetails = () => {
  const imageInputRef = useRef<HTMLInputElement>(null)

  const dispatch: AppDispatch = useDispatch()
  const toast = useToast()
  const { uploadFile } = useUploadService()
  const { fileSizeValidation } = useFileValidation()

  const { myUID, first_name, last_name, user_role } = useSelector((state: RootState) => state.config.config)

  const avatar = useAvatar(myUID)
  const [uploadedAvatar, setUploadedAvatar] = useState<string>('')
  const [isAvatarUploading, setIsAvatarUploading] = useState<boolean>(false)
  const fullName = `${first_name} ${last_name ? last_name : ''}`

  const handleSingleMediaUploading = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelfAvatarChange = true
    if (e.target.files?.length) {
      const file = e.target.files[0]
      try {
        const isValid = fileSizeValidation(file)
        if (!isValid) return
        const extension = getFileExtension(file.name).toLowerCase()
        const fileUrl = await uploadFile(file, extension, isSelfAvatarChange)

        setUploadedAvatar(fileUrl)
        return fileUrl
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setUploadedAvatar('')
        return ''
      }
    }
  }
  const handleChangeAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault() // Prevent menu close on input change
    e.stopPropagation()
    try {
      setIsAvatarUploading(true)
      const fileUrl = await handleSingleMediaUploading(e)
      if (fileUrl) {
        const usersObjectToPayload = {
          uid: myUID,
          profile_img_url: fileUrl,
        }
        const res = await dispatch(updateUser(null, usersObjectToPayload))
        if (res) {
          toast({
            title: 'Avatar updated',
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
        }
      }
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      console.log(error)
    } finally {
      setIsAvatarUploading(false)
    }
  }

  useEffect(() => {
    if (avatar) {
      setUploadedAvatar(avatar)
    }
  }, [avatar])

  return (
    <Flex p="20px">
      <>
        <Box
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
            imageInputRef.current?.click()
          }}
          cursor={'pointer'}
        >
          <Avatar src={isAvatarUploading ? imagePlaceholderAnimated : uploadedAvatar} width="60px" height="60px" />
        </Box>
        <Input
          ref={imageInputRef}
          accept={`${strings.SERVER_IMAGE_FORMATS}`}
          type="file"
          multiple={false}
          id="upload-image-button"
          style={{ display: 'none' }}
          onClick={(e) => {
            e.stopPropagation()
          }}
          onChange={handleChangeAvatar}
        />
      </>

      <Box mx="10px">
        <Box fontSize="18px" fontWeight="600">
          {fullName}
        </Box>
        <Box fontSize="13px" color={colors.greyMain}>
          <LocaleText text={keys.USER_ROLES[user_role]} />
        </Box>
      </Box>
    </Flex>
  )
}
