// eslint-disable-next-nine @typescript-eslint/no-use-before-define
import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import styled from '@emotion/styled'
import { DEVICE_TYPES } from 'constants/UI'
import useWindowDimensions from 'hooks/useWindowDimensions'
import Header from 'components/Header'
import { Outlet, useLocation } from 'react-router-dom'
import Sidebar from 'components/Sidebar'
import CreateTaskSidebar from 'components/Sidebar/CreateTaskSidebar'
import TaskOverviewSidebar from 'components/Sidebar/TaskOverviewSidebar'
import TaskSidebar from 'components/Sidebar/TaskSidebar'
import { ReportSideBar } from '../Sidebar/ReportSidebar'
import keys from 'constants/keys'

const Content = styled(Box)`
  margin-top: 90px;
  flex: 1;
`

const MainLayout = ({ children, sidebarComponent, ...props }: any) => {
  const { windowWidth } = useWindowDimensions()
  const location = useLocation()

  return (
    <>
      <Flex className="main-layout" m="auto" position="relative">
        {(DEVICE_TYPES.DESKTOP(windowWidth) || location.pathname.includes(keys.ROUTE_NAMES.USERS_TABLE)) && (
          <>{sidebarComponent}</>
        )}
        <Content
          as="main"
          width={{
            lg: '75%',
            base: '100%',
          }}
          {...props}
        >
          {children}
        </Content>
      </Flex>
    </>
  )
}

const Layout = ({ children }: { children: JSX.Element }) => (
  <MainLayout sidebarComponent={children}>
    <Header />
    <Outlet />
  </MainLayout>
)
export const LayoutWithoutSidebar = () => (
  <MainLayout>
    <Header />
    <Outlet />
  </MainLayout>
)

export const LayoutWithMainSidebar = () => (
  <Layout>
    <Sidebar />
  </Layout>
)

export const LayoutWithCreateTaskSidebar = () => (
  <Layout>
    <CreateTaskSidebar />
  </Layout>
)

export const LayoutWithTaskOverviewSidebar = () => (
  <Layout>
    <TaskOverviewSidebar />
  </Layout>
)

export const LayoutWithTasksSidebar = () => (
  <Layout>
    <TaskSidebar />
  </Layout>
)
type Props = {
  isShowTitle?: boolean
}

export const LayoutWithTitleSideBar = ({ isShowTitle }: Props) => (
  <Layout>
    <ReportSideBar isShowTitle={isShowTitle} />
  </Layout>
)
