import { Flex } from '@chakra-ui/core'
import { IComment } from 'constants/interfaces'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { ChatItem } from './ChatItem'
import { getCurrentEpoch } from 'utils'
import { IFetchComments } from 'hooks/useComments'
import isSolid from 'is-solid'
import { Post } from 'redux/reducers/feed'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from 'components/CommonComponents/Loader'
import { css } from '@emotion/core'
import { useUniqueMessages } from 'hooks/useUniqueMessages'

interface ChatBodyProps {
  currentChat: Post
  fetchCommentsLoading: boolean
  chatId: string
  fetchComments: (body: IFetchComments) => void
  comments: IComment[]
  commentsCounter: number
  messages:
    | {
        items: IComment[]
        hasMore: boolean
      }
    | undefined
}

export const ChatBody = memo(
  ({ currentChat, messages, comments, fetchComments, chatId, fetchCommentsLoading }: ChatBodyProps) => {
    const scrollableDivRef = useRef<HTMLDivElement>(null)
    const [flexDirection, setFlexDirection] = useState<any>('column-reverse')
    const [lastMessageCreatedAt, setLastMessageCreatedAt] = useState<number | null>(null)
    const uniqueMessages = useUniqueMessages(messages?.items)
    const getComments = useCallback(() => {
      if (fetchCommentsLoading) return
      try {
        let timestampFromToGetComments = getCurrentEpoch()

        if (comments.length > 0) {
          timestampFromToGetComments = comments[0].created_at_ts
        }
        const commentsFromOrigin = fetchComments({
          limit: 12,
          created_ts: timestampFromToGetComments,
          isManuallyTriggered: true,
          id: chatId,
        })
        if (!isSolid(commentsFromOrigin)) {
          return
        }
      } catch (error) {
        console.log(error)
      }
    }, [chatId, comments, fetchComments, fetchCommentsLoading])

    useEffect(() => {
      if (scrollableDivRef.current) {
        const container = scrollableDivRef.current
        const shouldUseColumn = container.scrollHeight <= container.clientHeight
        setFlexDirection(shouldUseColumn ? 'column' : 'column-reverse')
      }
    }, [messages])

    useEffect(() => {
      if (messages?.items.length && messages?.items.length > 0) {
        const lastMessage = messages.items[messages.items.length - 1]
        setLastMessageCreatedAt(lastMessage.created_at_ts)
      }
    }, [messages])

    useEffect(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
      }
    }, [lastMessageCreatedAt])

    return (
      <Flex
        id="scrollableDiv"
        ref={scrollableDivRef}
        flexDir={flexDirection}
        h="100%"
        w="100%"
        overflowY="auto"
        p="20px"
        dir="ltr"
        css={css`
          &::-webkit-scrollbar-thumb {
            background-color: grey !important;
            border-radius: 20px !important;
            border: 3px solid white !important;
          }

          &::-webkit-scrollbar-track {
            background: transparent !important;
          }
          &::-webkit-scrollbar {
            width: 12px !important;
          }
        `}
      >
        <InfiniteScroll
          dataLength={messages?.items.length ?? 0}
          next={getComments}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
          hasMore={messages?.hasMore || false}
          loader={<Loader maxWidth="100%" />}
          scrollableTarget="scrollableDiv"
        >
          {uniqueMessages?.map((message: IComment) => (
            <ChatItem key={message.cid} message={message} currentChat={currentChat} />
          ))}
        </InfiniteScroll>
      </Flex>
    )
  }
)
