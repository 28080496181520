import { useState } from 'react'

interface IUsePostTextContent {
  postTitle?: string
}
export const usePostTextContent = ({ postTitle }: IUsePostTextContent) => {
  const [editorValue, setEditorValue] = useState(postTitle ?? '')
  const [title, setTitle] = useState(postTitle ?? '')
  const [message, setMessage] = useState('')

  const clearTextContentState = () => {
    setEditorValue('')
    setTitle('')
    setMessage('')
  }

  return {
    editorValue,
    setEditorValue,
    title,
    setTitle,
    message,
    setMessage,
    clearTextContentState,
  }
}
