import { Flex } from '@chakra-ui/core'
import { IComment } from 'constants/interfaces'
import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { getCurrentEpoch } from 'utils'
import { IFetchComments } from 'hooks/useComments'
import isSolid from 'is-solid'
import { css } from '@emotion/core'
import { AnnouncementChatItem } from './AnnouncementChatItem'
import { useUniqueMessages } from 'hooks/useUniqueMessages'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Loader } from 'components/CommonComponents/Loader'

interface AnnouncementChatBody {
  fetchCommentsLoading: boolean
  chatId: string
  fetchReplies: (body: IFetchComments) => void
  replies: IComment[]
  repliesCounter: number
  isReply: boolean
  parentCID: string
  replyObject:
    | {
        items: IComment[]
        hasMore: boolean
      }
    | undefined
}
export const AnnouncementChatBody = memo(
  ({
    replyObject,
    replies,
    fetchReplies,
    chatId,
    fetchCommentsLoading,
    parentCID,
    repliesCounter,
  }: AnnouncementChatBody) => {
    const scrollableDivRef = useRef<HTMLDivElement>(null)
    const [flexDirection, setFlexDirection] = useState<any>('column-reverse')
    const [lastMessageCreatedAt, setLastMessageCreatedAt] = useState<number | null>(null)
    const uniqueMessages = useUniqueMessages(replies)

    const getReplies = useCallback(() => {
      if (fetchCommentsLoading) return
      try {
        let timestampFromToGetComments = getCurrentEpoch()

        if (replies.length > 0) {
          timestampFromToGetComments = replies[0].created_at_ts
        }
        const commentsFromOrigin = fetchReplies({
          limit: 12,
          created_ts: timestampFromToGetComments,
          parentCID: parentCID,
          isManuallyTriggered: false,
          id: chatId!,
        })
        if (!isSolid(commentsFromOrigin)) {
          return
        }
      } catch (error) {
        console.log(error)
      }
    }, [chatId, fetchCommentsLoading, fetchReplies, parentCID, replies])

    useEffect(() => {
      if (!repliesCounter) return
      getReplies()
    }, [])

    useEffect(() => {
      if (scrollableDivRef.current) {
        const container = scrollableDivRef.current
        const shouldUseColumn = container.scrollHeight <= container.clientHeight
        setFlexDirection(shouldUseColumn ? 'column' : 'column-reverse')
      }
    }, [replies])

    useEffect(() => {
      if (replies.length && replies.length > 0) {
        const lastMessage = replies[replies.length - 1]
        setLastMessageCreatedAt(lastMessage.created_at_ts)
      }
    }, [replies])

    useEffect(() => {
      if (scrollableDivRef.current) {
        scrollableDivRef.current.scrollTop = scrollableDivRef.current.scrollHeight
      }
    }, [lastMessageCreatedAt])

    return (
      <Flex
        id="scrollableDiv"
        ref={scrollableDivRef}
        flexDir={flexDirection}
        h="100%"
        w="100%"
        overflowY="auto"
        overflowX="hidden"
        bg="white"
        p="20px"
        dir="ltr"
        css={css`
          &::-webkit-scrollbar-thumb {
            background-color: grey !important;
            border-radius: 20px !important;
            border: 3px solid white !important;
          }

          &::-webkit-scrollbar-track {
            background: transparent !important;
          }
          &::-webkit-scrollbar {
            width: 12px !important;
          }
        `}
      >
        <InfiniteScroll
          dataLength={replies.length ?? 0}
          next={getReplies}
          style={{ display: 'flex', flexDirection: 'column-reverse' }}
          inverse={true}
          hasMore={replyObject?.hasMore || false}
          loader={<Loader maxWidth="100%" />}
          scrollableTarget="scrollableDiv"
        >
          {uniqueMessages?.map((message: IComment) => (
            <AnnouncementChatItem key={message.cid} message={message} />
          ))}
        </InfiniteScroll>
      </Flex>
    )
  }
)
