import React from 'react'
import { Input, Menu, Button, MenuList, MenuButton, MenuItem, Flex } from '@chakra-ui/core'
import { frequencyLabel, FrequencyType } from './utils'
import useLocaleText from 'components/useLocaleText'
import LocaleText from 'components/LocaleText'

const inputStyles = {
  borderRadius: '10px',
  bg: 'white',
  border: '1px solid #E2E8F0',
  fontWeight: '500',
  fontSize: '14px',
  px: '5px',
  h: '30px',
}

interface IProps {
  setRecurrenceInterval: (value: number | null) => void
  setRecurrenceFrequency: (value: number | null) => void
  recurrenceInterval: number | null
  recurrenceFrequency: number | null
  isDisabled: boolean
}
export const RecurringOptions = ({
  setRecurrenceInterval,
  setRecurrenceFrequency,
  recurrenceInterval,
  recurrenceFrequency,
  isDisabled,
}: IProps) => {
  const t_day = useLocaleText('t_day')
  const t_week = useLocaleText('t_week')
  const t_month = useLocaleText('t_month')
  const handleIntervalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (value.length <= 3) {
      setRecurrenceInterval(value ? parseInt(value) : null)
    }
  }

  const handleFrequencyChange = (value: number) => {
    setRecurrenceFrequency(value)
  }

  return (
    <Flex direction="row" style={{ columnGap: '10px' }} mt="20px">
      <Input
        isDisabled={isDisabled}
        variant="outline"
        type="number"
        value={recurrenceInterval ?? ''}
        onChange={handleIntervalChange}
        min={1}
        w="40px"
        textAlign={'center'}
        {...inputStyles}
      />
      {isDisabled ? (
        <Button {...inputStyles} w={'80px'} isDisabled>
          <LocaleText text={frequencyLabel(recurrenceFrequency)} />
        </Button>
      ) : (
        <Menu>
          <MenuButton as={Button} w={'80px'} {...inputStyles}>
            <LocaleText text={frequencyLabel(recurrenceFrequency)} />
          </MenuButton>
          <MenuList minW="80px">
            <MenuItem onClick={() => handleFrequencyChange(FrequencyType.DAY)}>
              <LocaleText text={t_day} />
            </MenuItem>
            <MenuItem onClick={() => handleFrequencyChange(FrequencyType.WEEK)}>
              <LocaleText text={t_week} />
            </MenuItem>
            <MenuItem onClick={() => handleFrequencyChange(FrequencyType.MONTH)}>
              <LocaleText text={t_month} />
            </MenuItem>
          </MenuList>
        </Menu>
      )}
    </Flex>
  )
}
