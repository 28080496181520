// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Flex, Input, Text, Collapse, Divider } from '@chakra-ui/core'
import styled from '@emotion/styled'
import Select from 'react-select'
import { TimezoneSelect } from 'timezone-select-js'
import 'react-datepicker/dist/react-datepicker.css'

import { ReactComponent as ArrowIcon } from 'assets/filter-arrow.svg'
import { RootState, ITaskName, IGroupCheckbox, ITimezone } from 'constants/interfaces'
import {
  setEndDate,
  setIsProductCamera,
  setIsProductNumbered,
  setIsRoll,
  setStartDate,
  setTaskOwners,
  setTimezone,
  setTaskName,
  setTaskView,
  startCreateTask,
  setIsNotifyMembers,
  setNotifyGroup,
  setIsSendImmediatePush,
  setIsSendSchedulePush,
  setDeadLineDate,
  setAutoRemovalTask,
} from 'redux/actions/createTask'
import colors from 'constants/colors'
import LocaleText from 'components/LocaleText'
import CustomSwitcher from 'components/CommonComponents/CustomSwitcher'
import { resetSecondsAndMS, spreadOutOfDate } from 'utils'
import useLocaleText from 'components/useLocaleText'
import BaseModal from 'components/CommonComponents/BaseModal'
import keys from 'constants/keys'
import CustomDatePicker from 'components/CommonComponents/CustomDatePicker'
import { taskViewsSelector } from 'redux/selectors/config'
import { OTHER_TASK_VIEW_ID } from '../../constants/baseValues'
import { isEmpty, isNil } from 'lodash'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import strings from 'constants/strings'
import { CustomTooltip } from '../helperStyles'
import dayjs from 'dayjs'
import { useValidateData } from 'hooks/useValidateDate'
import { setHours, setMinutes } from 'date-fns'
import AudiencePublicationsModal from 'components/CommonComponents/AudiencePublicationModal'
import { UsersList } from 'components/CommonComponents/UsersListModal'

const TimezonesWrapper = styled(Box)`
  & > div {
    width: 100%;
  }
`

const AssignItem = styled(Flex)<{ hoverColor?: string }>`
  &:hover,
  &:focus {
    outline: none;
    background-color: ${({ hoverColor }) => hoverColor || colors.greyLight};
  }
`
interface IProps {
  onClose: () => void
  isOpen: boolean
}

interface IGroups {
  variants: IGroupCheckbox[]
  isAll: boolean
  values: string[]
  totalVariants: number
}

interface IFormErrors {
  view: boolean
  taskOwners: boolean
  fromTs: boolean
  toTs: boolean
  isNotifyUsers: boolean
  deadlineField: boolean
}

const CreateTaskModal = ({ isOpen, onClose }: IProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { uid } = useSelector((state: RootState) => state.auth)
  const { locale, theme } = useSelector((state: RootState) => state.general)
  const {
    userWriteTaskPermissions: { groups: taskPermittedGroups, users: taskPermittedUsers },
    config: { retail_users, retailUsersObject },
  } = useSelector((state: RootState) => state.config)

  const {
    config: { groups, user_groups },
  } = useSelector((state: RootState) => state.config)
  const {
    view,
    taskOwners,
    from_ts,
    to_ts,
    roll,
    is_st_product_numbered,
    is_st_product_camera_enabled,
    timezone,
    isNotifyMembers,
    notifyGroup,
    deadlineDate,
    isSendImmediatePush,
    isSendSchedulePush,
    isAutoRemovalTask,
  } = useSelector((state: RootState) => state.createTask)
  const taskViews = useSelector(taskViewsSelector)

  const taskViewsSelect = useMemo(() => {
    return taskViews
      .filter((taskView) => taskView.task_view_id !== OTHER_TASK_VIEW_ID)
      .map((taskView) => ({
        value: taskView.task_view_id,
        label: <LocaleText text={taskView?.literal || taskView.task_view_name} />,
      }))
  }, [taskViews])

  const selectedView = useMemo(() => {
    return taskViewsSelect.find((taskView) => taskView.value === view) || null
  }, [view, taskViewsSelect])

  const create_task_name_placeholder = useLocaleText('create_task_name_placeholder')
  const create_task_start_date_placeholder = useLocaleText('create_task_start_date_placeholder')
  const create_task_removal_date_placeholder = useLocaleText('create_task_removal_date_placeholder')
  const create_task_removal_after_default_days = useLocaleText('create_task_removal_after_default_days')
  const create_task_end_date_placeholder = useLocaleText('create_task_end_date_placeholder')
  const create_task_start_date_notification = useLocaleText('create_task_start_date_notification')
  const notify_my_team_members_placeholder = useLocaleText('notify_my_team_members')
  const notify_group_menu_placeholder = useLocaleText('notify_group_menu')
  const create_task_reminders_notification = useLocaleText('create_task_reminders_notification')
  const create_task_tooltip_removal_date = useLocaleText('create_task_tooltip_removal_date')
  const create_task_tooltip_start_notification = useLocaleText('create_task_tooltip_start_notification')
  const create_task_tooltip_reminders_notification = useLocaleText('create_task_tooltip_reminders_notification')
  const create_task_end_date_error_placeholder = useLocaleText('to_ts_less_than_from_ts_warning_msg')

  const { validateEndDate, validateStartDate, validateDueDate } = useValidateData()
  const [isEndDateOpen, setIsEndDateOpen] = useState<boolean>(false)
  const [isStartDateOpen, setIsStartDateOpen] = useState<boolean>(false)
  const [isDeadLineOpen, setIsDeadLineOpen] = useState<boolean>(false)

  const handleCloseDedLineDatePicker = () => {
    setIsDeadLineOpen(false)
    const isValid = validateDueDate(deadlineDate, from_ts, to_ts, create_task_end_date_error_placeholder)
    if (!isValid) {
      dispatch(setDeadLineDate(null))
    }
  }

  const handleOpenEndDatePicker = () => {
    if (deadlineDate) setIsEndDateOpen(true)
  }

  const handleOpenDeadLineDatePicker = () => {
    if (from_ts) {
      setIsDeadLineOpen(true)
      if (!deadlineDate) {
        const currentDate = new Date()
        handleSetDeadlineDate(setMinutes(setHours(from_ts, currentDate.getHours()), currentDate.getMinutes()))
      }
    }
  }
  const handleEndDate = (date: Date | null) =>
    validateEndDate(date, deadlineDate, create_task_end_date_error_placeholder, (date) => dispatch(setEndDate(date)))

  const closeEndDatePicker = () => {
    const isValid = handleEndDate(to_ts)
    if (!isValid) {
      dispatch(setEndDate(null))
    }
    setIsEndDateOpen(false)
  }

  const handleOpenStartDatePicker = () => {
    setIsStartDateOpen(true)
    if (!from_ts) {
      dispatch(setStartDate(resetSecondsAndMS(new Date())))
    }
  }
  const handleStartDate = (date: Date | null) =>
    validateStartDate(date, deadlineDate, create_task_end_date_error_placeholder, (date) =>
      dispatch(setStartDate(date))
    )

  const closeStartDatePicker = () => {
    const isValid = handleStartDate(from_ts)
    if (!isValid) {
      dispatch(setStartDate(null))
    }
    if (deadlineDate && new Date(deadlineDate) < new Date(from_ts)) {
      dispatch(setDeadLineDate(null))
    }
    setIsStartDateOpen(false)
  }
  const handleSetStartDate = (date: Date) => {
    const { date: currentDay, hours, minutes } = spreadOutOfDate() // today
    const { date: selectedDay, fullYear, month } = spreadOutOfDate(date) // selected date
    if (selectedDay === currentDay && !from_ts) {
      const dateWithCurrentTime = new Date(fullYear, month, selectedDay, hours, minutes)
      dispatch(setStartDate(dateWithCurrentTime))
    } else {
      dispatch(setStartDate(resetSecondsAndMS(date)))
    }
  }

  const handleSetEndDate = (date: Date) => {
    dispatch(setEndDate(resetSecondsAndMS(date)))
  }

  const handleSetDeadlineDate = (date: Date) => {
    const nextWeekDade = new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000)
    const diffInHours = dayjs(to_ts).diff(date, 'hours')
    dispatch(setDeadLineDate(resetSecondsAndMS(date)))
    if (to_ts === null || diffInHours < 168) dispatch(setEndDate(resetSecondsAndMS(nextWeekDade)))
  }

  const [showGroupList, setShowGroupList] = useState(false)
  const [selectedGroupIDs, setSelectedGroupIDs] = useState<string[]>([])
  const [selectedUsersUids, setSelectedUsersUids] = useState<string[]>([])
  const [taskNameData, setTaskNameData] = useState<ITaskName>({
    value: '',
    length: 0,
    isError: false,
  })
  const [formErrors, setFormErrors] = useState<IFormErrors>({
    view: true,
    taskOwners: true,
    fromTs: true,
    toTs: true,
    isNotifyUsers: true,
    deadlineField: true,
  })
  const [isAdvanceMode, setIsAdvanceMode] = useState<boolean>(false)

  const filteredRerailUsersByTreeUsers = useMemo(() => {
    const sortedUsers = retail_users.sort((a: any, b: any) => {
      const firstNameComparison = a?.first_name?.localeCompare(b?.first_name)
      if (firstNameComparison === 0) {
        return a?.last_name?.localeCompare(b?.last_name)
      }

      return firstNameComparison
    })

    return sortedUsers
  }, [retail_users])

  const namesForTooltip = useMemo(() => {
    if (!isEmpty(taskPermittedGroups) && retailUsersObject) {
      let selectedGroupNames = selectedGroupIDs.map((key) => taskPermittedGroups[key].name) ?? []
      let selectedUserNames = selectedUsersUids.map(
        (uid) =>
          retailUsersObject?.[uid]?.first_name +
          ' ' +
          (retailUsersObject?.[uid]?.last_name ? retailUsersObject?.[uid].last_name : '')
      )

      return [...selectedGroupNames, ...selectedUserNames]
    }
    return []
  }, [retailUsersObject, selectedGroupIDs, selectedUsersUids, taskPermittedGroups])

  const groupNames = useMemo(() => {
    if (namesForTooltip && namesForTooltip?.length > 0)
      return namesForTooltip.length < 2 ? namesForTooltip[0] : namesForTooltip[0] + ` + ${namesForTooltip.length - 1}`
  }, [namesForTooltip])

  const autoRemovalTaskHandler = () => {
    if (deadlineDate) {
      const nextWeekDade = new Date(deadlineDate.getTime() + 7 * 24 * 60 * 60 * 1000)
      dispatch(setEndDate(resetSecondsAndMS(nextWeekDade)))
      dispatch(setAutoRemovalTask(!isAutoRemovalTask))
    }
  }

  useEffect(() => {
    dispatch(startCreateTask())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (taskPermittedGroups) {
      const groups = selectedGroupIDs?.map((id: string) => {
        return {
          label: taskPermittedGroups?.[id]?.name,
          value: id,
        }
      })
      const users = selectedUsersUids?.map((id: string) => {
        return {
          label: retailUsersObject?.[id]?.first_name + ' ' + retailUsersObject?.[id]?.last_name ?? '',
          value: id,
        }
      })
      const taskOwners = {
        groups: groups,
        users: users,
      }
      if (taskOwners) {
        dispatch(setTaskOwners(taskOwners))
      }
    }
    // eslint-disable-next-line
  }, [selectedGroupIDs, selectedUsersUids])

  const resetValidation = () => {
    if (taskOwners && (taskOwners.groups.length || taskOwners.users.length)) {
      setFormErrors((formError) => ({ ...formError, taskOwners: true }))
    }
    if (from_ts) {
      setFormErrors((formError) => ({ ...formError, fromTs: true }))
    }
    if (to_ts) {
      setFormErrors((formError) => ({ ...formError, toTs: true }))
    }
    if (notifyGroup && notifyGroup.length) {
      setFormErrors((formError) => ({ ...formError, isNotifyUsers: true }))
    }
  }

  useEffect(resetValidation, [taskOwners, from_ts, to_ts, notifyGroup, isNotifyMembers])

  const validateForm = (): boolean => {
    let isValid = true
    let viewValid = false
    let ownerValid = false
    let fromTsValid = false
    let toTsValid = false
    let isNotifyUsersValid = true
    let deadlineField = false

    if (deadlineDate) {
      deadlineField = true
      isValid = true && isValid
    } else {
      isValid = false && isValid
    }

    if (taskNameData.length > 0) {
      setTaskNameData((prev) => ({
        ...prev,
        isError: false,
      }))
      isValid = true && isValid
    } else {
      setTaskNameData((prev) => ({
        ...prev,
        isError: true,
      }))
      isValid = false && isValid
    }
    if (!isNil(view)) {
      viewValid = true
      isValid = true && isValid
    } else {
      isValid = false && isValid
    }

    if (taskOwners && (taskOwners.groups.length > 0 || (taskOwners && taskOwners.users.length > 0))) {
      ownerValid = true
      isValid = true && isValid
    } else {
      isValid = false && isValid
    }

    if (from_ts) {
      fromTsValid = true
      isValid = true && isValid
    } else {
      isValid = false && isValid
    }

    if (to_ts) {
      toTsValid = true
      isValid = true && isValid
    } else {
      isValid = false && isValid
    }

    if (isNotifyMembers) {
      isNotifyUsersValid = !!notifyGroup && !!notifyGroup.length
      isValid = isNotifyUsersValid && isValid
    }

    setFormErrors((prev: any) => ({
      ...prev,
      view: viewValid,
      taskOwners: ownerValid,
      fromTs: fromTsValid,
      toTs: toTsValid,
      isNotifyUsers: isNotifyUsersValid,
      deadlineField,
    }))

    return isValid
  }

  const handleCreateTaskPage = () => {
    if (validateForm()) {
      dispatch(setTaskName(taskNameData.value))
      navigate(keys.ROUTE_NAMES.CREATE_TASK, { state: { isCancelExit: true } })
      onClose()
    }
  }

  const clearDeadLineDatePicker = () => {
    dispatch(setDeadLineDate(null))
  }

  const handleCloseModal = () => {
    onClose()
    dispatch(startCreateTask(false))
    dispatch(setNotifyGroup([]))
    dispatch(setIsNotifyMembers(false))
    dispatch(setStartDate(null))
    dispatch(setEndDate(null))
    clearDeadLineDatePicker()
    dispatch(setAutoRemovalTask(true))
  }

  const handleIsRoll = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsRoll(e.target.checked))
  }

  const handleIsProductNumbered = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsProductNumbered(e.target.checked))
  }

  const handleIsProductCamera = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsProductCamera(e.target.checked))
  }

  const handleTaskName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = e.target.value
    const valueLength: number = value.length
    const errorCondition = valueLength === 0 || valueLength >= keys.CREATE_TASK_CONSTANTS.MAX_TASK_NAME_LENGTH
    setTaskNameData((prev) => ({
      ...prev,
      value: valueLength >= keys.CREATE_TASK_CONSTANTS.MAX_TASK_NAME_LENGTH ? prev.value : value,
      length: valueLength,
      isError: errorCondition,
    }))
  }

  const handleTaskView = (view: any) => {
    dispatch(setTaskView(view.value))
    setFormErrors((formError) => ({ ...formError, view: true }))
  }

  const handleTimezone = (timezone: ITimezone) => {
    dispatch(setTimezone(timezone))
  }

  const handleAdvanceMode = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setIsAdvanceMode((prev) => !prev)
  }

  const handleIsNotify = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setIsNotifyMembers(e.target.checked))
  }

  const notifyGroupHandler = (groups: any) => {
    dispatch(setNotifyGroup(groups))
  }

  const onToggleIsImmediatePush = () => {
    dispatch(setIsSendImmediatePush(!isSendImmediatePush))
  }

  const onToggleIsSchedulePush = () => {
    dispatch(setIsSendSchedulePush(!isSendSchedulePush))
  }
  const isUidsOfSelectedGroupsInSelectedUsers = useMemo(() => {
    return selectedGroupIDs.some((id) => {
      return taskPermittedGroups[id].uids.some((uid) => selectedUsersUids.includes(uid))
    })
  }, [selectedGroupIDs, selectedUsersUids, taskPermittedGroups])

  const isSelectedUidsInGroups = useMemo(() => {
    return selectedUsersUids.some((uid) => {
      return selectedGroupIDs.some((id) => {
        return taskPermittedGroups[id].uids.includes(uid)
      })
    })
  }, [selectedGroupIDs, selectedUsersUids, taskPermittedGroups])

  const renderViewOptionModal = useCallback(
    (viewOption: number, setViewOption: React.Dispatch<React.SetStateAction<number>> | any) => {
      switch (viewOption) {
        case 0:
          return (
            <GroupsTree
              isOpen={isOpen}
              selectedGroupID={selectedGroupIDs}
              mode={strings.PARTIAL_MULTILPE_MODE}
              setViewOption={setViewOption}
              setSelectedGroupID={setSelectedGroupIDs}
              isAllowSubmitWhithoutGroups={true}
              treeGroups={taskPermittedGroups}
            />
          )
        case 1:
          return (
            <UsersList
              isOpen={isOpen}
              onClose={onClose}
              setViewOption={setViewOption}
              selectedUsersUids={selectedUsersUids}
              setSelectedUsersUids={setSelectedUsersUids}
              treeUsers={filteredRerailUsersByTreeUsers}
            />
          )

        default:
          break
      }
    },
    [filteredRerailUsersByTreeUsers, isOpen, onClose, selectedGroupIDs, selectedUsersUids, taskPermittedGroups]
  )

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        hideCloseButton={showGroupList}
        headerContent={<>{!showGroupList && <LocaleText text={'create_task_modal_title'} />}</>}
        bodyContent={
          <Box px="6">
            {!showGroupList ? (
              <>
                <Box mb="4" mt="5px">
                  <Select
                    placeholder={<LocaleText text="create_task_view_placeholder" />}
                    value={selectedView}
                    styles={{
                      control: (base: any, state) => ({
                        ...base,
                        borderColor: !formErrors.view && 'red',
                        boxShadow: state.isFocused ? `0 0 0 1px ${colors.blackLight}` : base.boxShadow,
                        '&:hover': { borderColor: !formErrors.view && 'red' },
                      }),
                      option: (base: any, { isSelected }) => ({
                        ...base,
                        backgroundColor: isSelected ? `${theme?.backgroundMedium}` : 'white',
                        color: colors.blackLight,
                        '&:hover': {
                          backgroundColor: theme?.backgroundLight,
                          color: colors.blackLight,
                        },
                      }),
                    }}
                    onChange={handleTaskView}
                    options={taskViewsSelect}
                  />
                </Box>
                <Box mb="8" position="relative">
                  <Input
                    onChange={handleTaskName}
                    value={taskNameData.value}
                    variant="flushed"
                    placeholder={create_task_name_placeholder}
                    size="lg"
                    pr="45px"
                    isInvalid={taskNameData.isError}
                    _focus={{ borderColor: colors.blackLight }}
                  />
                  <Box position="absolute" top="10px" right="0px">
                    <Text>
                      {taskNameData.length}/{keys.CREATE_TASK_CONSTANTS.MAX_TASK_NAME_LENGTH}
                    </Text>
                  </Box>
                </Box>
                <AssignItem
                  width={{
                    md: 'calc(100% + 3rem)',
                    base: '100%',
                  }}
                  justifyContent="space-between"
                  alignItems="center"
                  cursor="pointer"
                  mb="4"
                  mx={{
                    md: '-1.5rem',
                    base: '0',
                  }}
                  px={{
                    md: '6',
                    base: '0',
                  }}
                  onClick={() => setShowGroupList(true)}
                  hoverColor={theme?.backgroundLight}
                >
                  <Flex
                    px={{
                      md: '2',
                      base: '0',
                    }}
                    py="4"
                    border={!formErrors.taskOwners ? '2px solid red' : '2px solid transparent'}
                    borderRadius="6px"
                    height="100%"
                    width="100%"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {(selectedGroupIDs !== null && selectedGroupIDs.length > 0) ||
                    (selectedUsersUids !== null && selectedUsersUids.length > 0) ? (
                      <>
                        <Flex flexDir="column">
                          <Text>
                            <LocaleText text="assigned_groups" />
                            &nbsp; ({selectedGroupIDs.length}/{Object.keys(taskPermittedGroups).length})
                          </Text>
                          <Text>
                            <LocaleText text="assigned_users" />
                            &nbsp; ({selectedUsersUids.length}/{filteredRerailUsersByTreeUsers.length})
                          </Text>
                        </Flex>
                      </>
                    ) : (
                      <LocaleText text="create_task_assignee_placeholder" />
                    )}
                    {locale === 'he' ? <ArrowIcon style={{ transform: 'rotate(180deg)' }} /> : <ArrowIcon />}
                  </Flex>
                </AssignItem>
                <Flex border={!formErrors.fromTs ? '2px solid red' : '2px solid transparent'} p="2" alignItems="center">
                  <CustomDatePicker
                    selected={from_ts && new Date(from_ts)}
                    onChange={handleSetStartDate}
                    placeholderText={create_task_start_date_placeholder}
                    minDate={new Date()}
                    selectsStart
                    showTimeInput={true}
                    onInputClick={handleOpenStartDatePicker}
                    onClickOutside={closeStartDatePicker}
                    open={isStartDateOpen}
                    isShowYearPicker={true}
                    isShowMonthPicker={true}
                  />
                </Flex>
                <Flex
                  border={!formErrors.deadlineField ? '2px solid red' : '2px solid transparent'}
                  p="2"
                  alignItems="center"
                  mb="0.5rem"
                >
                  <CustomDatePicker
                    selected={deadlineDate && new Date(deadlineDate)}
                    onChange={handleSetDeadlineDate}
                    placeholderText={create_task_end_date_placeholder}
                    selectsEnd
                    minDate={from_ts && new Date(from_ts)}
                    popperPlacement="top"
                    disabled={!from_ts}
                    showTimeInput={true}
                    open={isDeadLineOpen}
                    onInputClick={handleOpenDeadLineDatePicker}
                    onClickOutside={handleCloseDedLineDatePicker}
                    isShowYearPicker={true}
                    isShowMonthPicker={true}
                  />
                </Flex>
                <Flex px="0.5rem">
                  <CustomSwitcher
                    mb="0.5rem"
                    isChecked={isAutoRemovalTask}
                    onChange={autoRemovalTaskHandler}
                    labelText={create_task_removal_after_default_days}
                    switchColor={colors.blueLight}
                  />
                </Flex>
                {!isAutoRemovalTask && (
                  <Flex border={!formErrors.toTs ? '2px solid red' : '2px solid transparent'} p="2" alignItems="center">
                    <CustomDatePicker
                      selected={to_ts && new Date(to_ts)}
                      onChange={handleSetEndDate}
                      placeholderText={create_task_removal_date_placeholder}
                      selectsEnd
                      minDate={deadlineDate && new Date(deadlineDate)}
                      showTimeInput={true}
                      disabled={!deadlineDate}
                      open={isEndDateOpen}
                      onInputClick={handleOpenEndDatePicker}
                      onClickOutside={closeEndDatePicker}
                      tooltipLabel={create_task_tooltip_removal_date}
                    />
                  </Flex>
                )}
                <Divider />

                <Flex padding="0 0.5rem" mb="1rem" justifyContent="space-between" alignItems="center" height="30px">
                  <CustomSwitcher
                    isChecked={isNotifyMembers}
                    onChange={handleIsNotify}
                    labelText={notify_my_team_members_placeholder}
                    mb="0"
                    switchColor={colors.blueLight}
                  />
                </Flex>
                {isNotifyMembers && groups && (
                  <Select
                    placeholder={notify_group_menu_placeholder}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
                      container: (base: any) => ({ ...base, width: '100%', fontSize: '14px', marginBottom: '1rem' }),
                      control: (base: any) => ({
                        ...base,
                        borderColor: formErrors.isNotifyUsers ? 'inherit' : 'red',
                        borderWidth: '2px',
                      }),
                    }}
                    options={user_groups?.map((groupId) => ({
                      value: groupId,
                      label: groups[groupId].name,
                    }))}
                    isMulti
                    onChange={notifyGroupHandler}
                    value={notifyGroup}
                  />
                )}
                <Collapse isOpen={isAdvanceMode}>
                  <Divider />
                  <Text fontFamily="Arial" mt="24px" mb="15px" fontSize="12px" color={colors.greyDark2}>
                    <LocaleText text="create_task_subtitle_notifications" />
                  </Text>
                  <CustomTooltip
                    aria-label={create_task_tooltip_start_notification}
                    label={create_task_tooltip_start_notification}
                    placement="bottom-start"
                  >
                    <Box px="0.5rem">
                      <CustomSwitcher
                        mb="1rem"
                        isChecked={isSendImmediatePush}
                        onChange={onToggleIsImmediatePush}
                        labelText={create_task_start_date_notification}
                        switchColor={colors.blueLight}
                      />
                    </Box>
                  </CustomTooltip>
                  <CustomTooltip
                    aria-label={create_task_tooltip_reminders_notification}
                    label={create_task_tooltip_reminders_notification}
                    placement="bottom-start"
                  >
                    <Box px="0.5rem">
                      <CustomSwitcher
                        mb="1rem"
                        isChecked={isSendSchedulePush}
                        onChange={onToggleIsSchedulePush}
                        labelText={create_task_reminders_notification}
                        switchColor={colors.blueLight}
                      />
                    </Box>
                  </CustomTooltip>
                  <Divider />
                  <Flex mb="2rem" flexDir="column" flexWrap="wrap" padding="0.5rem">
                    <CustomSwitcher
                      isChecked={roll}
                      onChange={handleIsRoll}
                      labelText="is_roll_toggale"
                      mb="1rem"
                      switchColor={colors.blueLight}
                    />
                    <CustomSwitcher
                      isChecked={is_st_product_numbered}
                      onChange={handleIsProductNumbered}
                      labelText="is_st_product_numbered"
                      mb="1rem"
                      switchColor={colors.blueLight}
                    />
                    <CustomSwitcher
                      isChecked={is_st_product_camera_enabled}
                      onChange={handleIsProductCamera}
                      labelText="is_st_product_camera_enabled"
                      mb="0"
                      switchColor={colors.blueLight}
                    />
                  </Flex>
                  <TimezonesWrapper mb="2rem" width="100%">
                    <TimezoneSelect value={timezone} onChange={handleTimezone} />
                  </TimezonesWrapper>
                </Collapse>
              </>
            ) : (
              <AudiencePublicationsModal
                groups={taskPermittedGroups}
                users={taskPermittedUsers}
                isOpen={isOpen}
                onClose={onClose}
                setShowOptionsList={setShowGroupList}
                renderViewOptionModal={renderViewOptionModal}
                selectedUsersUids={selectedUsersUids}
                groupsToPayload={selectedGroupIDs}
                title="task_audience"
                isShowWarning={isUidsOfSelectedGroupsInSelectedUsers || isSelectedUidsInGroups}
              />
            )}
          </Box>
        }
        footerContent={
          <>
            {!showGroupList && (
              <Flex width="100%" mb="4" justifyContent="space-between">
                <Button
                  borderRadius="20px"
                  color={isAdvanceMode ? theme?.elementsColor : 'white'}
                  background={isAdvanceMode ? 'transparent' : theme?.elementsColor}
                  variant={isAdvanceMode ? 'outline' : 'solid'}
                  onClick={handleAdvanceMode}
                  _hover={{ bg: 'none' }}
                  borderColor={theme?.elementsColor}
                  borderWidth="1px"
                  _focus={{ outline: 'none' }}
                >
                  <LocaleText text="advance_mode_btn" />
                </Button>
                <Button
                  variant="ghost"
                  onClick={handleCreateTaskPage}
                  color={theme?.elementsColor}
                  textTransform="uppercase"
                  textDecor="underline"
                  _focus={{ outline: 'none' }}
                  _hover={{ bg: 'none' }}
                >
                  <LocaleText text="header_button_next" />
                </Button>
              </Flex>
            )}
          </>
        }
      />
    </>
  )
}

export default CreateTaskModal
