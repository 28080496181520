import { AxiosError } from 'axios'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { RootState } from 'constants/interfaces'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { IUserDetailedObject } from 'redux/reducers/admin'
import { setFullUserName, setRetailUsers, setRetailUsersObject } from './config'
import keys from 'constants/keys'

export const actionTypes = {
  SET_USERS_LIST: '[ADMIN] SET_USERS_LIST',
  SET_CURRENT_USER: '[ADMIN] SET_CURRENT_USER',
  SET_IS_ADMIN_PAGE_LOADING: '[ADMIN] SET_IS_ADMIN_PAGE_LOADING',
  SET_IS_FETCHING: '[ADMIN] SET_IS_FETCHING',
  SET_IS_ACTION_SUCCESSFUL: '[ADMIN] SET_IS_ACTION_SUCCESSFUL',
  SET_IS_ERROR: '[ADMIN] SET_IS_ERROR',
  SET_DETAILED_USER: '[ADMIN] SET_DETAILED_USER',
  SET_REGISTERED_USER: '[ADMIN] SET_REGISTERED_USER',
}

export const setRegisteredUser = (user: IUserDetailedObject | null) => ({
  type: actionTypes.SET_REGISTERED_USER,
  payload: user,
})
export const setDetailedUser = (data: IUserDetailedObject | null) => ({
  type: actionTypes.SET_DETAILED_USER,
  payload: data,
})
export const setIsError = (isError: boolean) => ({
  type: actionTypes.SET_IS_ERROR,
  payload: isError,
})
export const setIsActionSuccessful = (isSuccessful: boolean) => ({
  type: actionTypes.SET_IS_ACTION_SUCCESSFUL,
  payload: isSuccessful,
})

export const setUsersList = (usersList: any) => ({
  type: actionTypes.SET_USERS_LIST,
  payload: usersList,
})

export const setIsAdminPageLoading = (isLoading: boolean) => ({
  type: actionTypes.SET_IS_ADMIN_PAGE_LOADING,
  payload: isLoading,
})
export const setIsFetching = (isFetching: boolean) => ({
  type: actionTypes.SET_IS_FETCHING,
  payload: isFetching,
})

export const deleteUser =
  (uids: string[]): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      admin: {
        userTable: { usersList },
      },
    } = getState()
    try {
      const updatedUsersList = usersList?.filter((user) => !uids.includes(user.uid!))
      if (updatedUsersList) {
        await httpAuth.delete(endpoints.users, {
          data: {
            uids: uids,
            auth_context: 1,
          },
        })
        dispatch(setIsActionSuccessful(true))
        const retailUsersObject = {}
        dispatch(setUsersList(updatedUsersList))
        updatedUsersList.forEach((user: any) => {
          retailUsersObject[user.uid] = user
        })
        dispatch(setRetailUsersObject(retailUsersObject))
      }
    } catch (error) {
      console.log(`deleteUser failed due ${error}`)
      dispatch(setIsError(true))
    } finally {
      dispatch(setIsFetching(false))
    }
  }

export const createUser =
  (userObject: IUserDetailedObject): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      config: {
        config: { rid },
      },
      admin: {
        userTable: { usersList },
      },
    } = getState()

    try {
      dispatch(setIsFetching(true))
      await httpAuth.post(endpoints.users, { ...userObject, rid, auth_context: 1 })
      if (usersList) {
        const newUser = {
          last_name: userObject.last_name,
          uid: userObject.uid,
          first_name: userObject.first_name,
          locale: userObject.locale,
          desc: userObject.desc || '',
          sec_uid: userObject.sec_uid || '',
          user_group_role: userObject.user_group_role,
        }
        const updatedUsersList = [newUser, ...usersList]
        dispatch(setUsersList(updatedUsersList))
        dispatch(setRetailUsers(updatedUsersList))
        const retailUsersObject = {}
        updatedUsersList.forEach((user: any) => {
          retailUsersObject[user.uid] = user
        })
        dispatch(setRetailUsersObject(retailUsersObject))
      }
      dispatch(setIsActionSuccessful(true))
    } catch (err) {
      const error: AxiosError | any = err
      console.log(`createUser failed due ${error}`)
      dispatch(setIsError(true))
      console.log(error)
      if (error?.response?.status === 409) {
        console.log('Server responded with error 409: Conflict')
      }
    } finally {
      dispatch(setIsFetching(false))
    }
  }

export const getUser =
  (uid: string): ThunkAction<any, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const { data } = await httpAuth.get(endpoints.getUser(keys.AUTH_CONTEXT, uid))
      if (data.user) dispatch(setDetailedUser({ ...data.user, password: '' }))
    } catch (error) {
      console.log(`createUser failed due ${error}`)
      dispatch(setIsError(true))
    } finally {
      dispatch(setIsFetching(false))
    }
  }

export const updateUser =
  (
    usersObject: IUserDetailedObject | null,
    usersObjectToPayload: any,
    isSelfAvatarChange?: boolean
  ): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      config: {
        config: { rid, retail_users },
      },
      auth: { uid },
      admin: {
        userTable: { usersList },
      },
    } = getState()

    try {
      dispatch(setIsFetching(true))
      if (!usersList || !retail_users) return false

      const payloadUid = usersObjectToPayload.uid
      const targetUser = usersObject !== null ? usersObject : retail_users.find((user) => user.uid === payloadUid)

      if (targetUser) {
        const newUser = {
          last_name: targetUser.last_name,
          uid: targetUser.uid,
          first_name: targetUser.first_name,
          locale: targetUser.locale,
          desc: targetUser.desc || '',
          sec_uid: targetUser.sec_uid || '',
          user_group_role: targetUser.user_group_role,
          ...(usersObjectToPayload.profile_img_url ? { profile_img_url: usersObjectToPayload.profile_img_url } : {}),
        }

        const updatedUsers = usersList.map((user) => {
          if (user.uid === newUser.uid) {
            return { ...user, ...newUser }
          }
          return user
        })
        const payload = {
          ...usersObjectToPayload,
          ...(isSelfAvatarChange ? { rid, auth_context: 1 } : {}),
        }
        await httpAuth.put(endpoints.users, { ...payload })

        dispatch(setIsActionSuccessful(true))
        dispatch(setUsersList(updatedUsers))
        dispatch(setRetailUsers(updatedUsers))

        const retailUsersObject = {}
        updatedUsers.forEach((user: any) => {
          retailUsersObject[user.uid] = user
        })
        dispatch(setRetailUsersObject(retailUsersObject))
        if (uid === newUser.uid)
          dispatch(setFullUserName({ firstName: newUser.first_name, lastName: newUser.last_name }))
      }
      return true
    } catch (error) {
      console.log(`updateUser failed due ${error}`)
      dispatch(setIsError(true))
      return false
    } finally {
      dispatch(setIsFetching(false))
    }
  }

interface IUpdateGroup {
  groupId: string
  added_users_ids?: string[]
  deleted_users_ids?: string[]
}
export const updateGroup =
  ({ groupId, added_users_ids, deleted_users_ids }: IUpdateGroup): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      config: {
        config: { rid },
      },
    } = getState()
    try {
      const payload = {
        auth_context: 1,
        rid,
        oid: groupId,
        group_data: {},
        ...(deleted_users_ids ? { deleted_users_ids } : {}),
        ...(added_users_ids ? { added_users_ids } : {}),
      }
      await httpAuth.put(endpoints.updateGroup, payload)
      dispatch(setIsActionSuccessful(true))
    } catch (error) {
      console.log(`updateGroup failed due ${error}`)
      dispatch(setIsError(true))
    } finally {
      dispatch(setIsFetching(false))
    }
  }
