import { IconButton, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface PasswordEyeToggleProps {
  isShow: boolean
  setShow: (isShow: boolean) => void
  color?: string
}
export const PasswordEyeToggle = ({ isShow, setShow, color }: PasswordEyeToggleProps) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <>
      {!isRtl ? (
        <InputRightElement width="2.5rem" color={color}>
          <IconButton
            backgroundColor="transparent"
            minW="0"
            icon={!isShow ? 'view' : 'view-off'}
            onClick={() => setShow(!isShow)}
            aria-label={'view password'}
            _hover={{ background: 'none' }}
            _focus={{ boxShadow: 'none' }}
            _active={{ background: 'none' }}
          />
        </InputRightElement>
      ) : (
        <InputLeftElement width="2.5rem" color={color}>
          <IconButton
            backgroundColor="transparent"
            minW="0"
            icon={!isShow ? 'view' : 'view-off'}
            aria-label={'view password'}
            onClick={() => setShow(!isShow)}
            _hover={{ background: 'none' }}
            _focus={{ boxShadow: 'none' }}
            _active={{ background: 'none' }}
          />
        </InputLeftElement>
      )}
    </>
  )
}
