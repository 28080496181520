import React from 'react'
import { DateRange, Range, RangeFocus, RangeKeyDict } from 'react-date-range'
import { Button, ButtonProps, Divider, Flex, PopoverContent } from '@chakra-ui/core'

import colors from 'constants/colors'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface IProps {
  onChange: (value: RangeKeyDict) => void
  ranges: Range[]
  rangeFocusChange: (focusedDates: RangeFocus) => void
  handleFilter: VoidFunction
  minDateRange?: Date
  maxDateRange?: Date
  selectButtonLiteral: string
  resetButtonLiteral?: string
  resetFilter?: VoidFunction
  className?: string
  applyButtonStyles?: Omit<ButtonProps, 'children'>
  style?: any
}

export const DateRangeContent = ({
  onChange,
  ranges,
  rangeFocusChange,
  handleFilter,
  maxDateRange,
  minDateRange,
  resetButtonLiteral,
  selectButtonLiteral,
  resetFilter,
  className,
  applyButtonStyles,
  style,
}: IProps) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <PopoverContent
      position="absolute"
      zIndex={9999}
      right={style ? style.right : 'inherit'}
      left={style ? style.left : 'inherit'}
      top={style ? style.top : 'inherit'}
      maxWidth="500px"
      dir="ltr"
      _focus={{ border: 'none' }}
    >
      <DateRange
        onChange={onChange}
        moveRangeOnFirstSelection={true}
        months={2}
        ranges={ranges}
        color={theme?.elementsColor}
        direction="horizontal"
        rangeColors={[theme?.backgroundLight!]}
        minDate={minDateRange}
        maxDate={maxDateRange}
        onRangeFocusChange={rangeFocusChange}
        className={className}
      />
      <Divider mx="2.5%" />
      <Flex mb="2" justifyContent="space-between" flexDirection="row-reverse" px="2.5%" width="60%" ml="40%">
        <Button
          onClick={handleFilter}
          background={theme?.elementsColor}
          color={colors.tealLight}
          _hover={{ background: theme?.backgroundLight }}
          width="45%"
          {...applyButtonStyles}
        >
          <LocaleText text={selectButtonLiteral} />
        </Button>
        {!!resetFilter && !!resetButtonLiteral && (
          <Button onClick={resetFilter} width="45%">
            <LocaleText text={resetButtonLiteral} />
          </Button>
        )}
      </Flex>
    </PopoverContent>
  )
}
