import React from 'react'
import { Flex, Text, Divider, Tag, Box } from '@chakra-ui/core'
import { CustomCheckBox } from '../GroupsTree/style'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { ITagNode } from './types'

export const TagNode = ({ title, id, checkedIds, tagColor, handleCheckIds }: ITagNode) => {
  const theme = useSelector((state: RootState) => state?.general?.theme)
  return (
    <>
      <Flex width="100%" justifyContent="space-between">
        <Flex
          bg="transparent"
          alignItems="center"
          w="max-content"
          color="black"
          fontSize="18px"
          style={{ columnGap: '10px' }}
          fontWeight={500}
        >
          <Box w="20px" h="20px" bg={tagColor} borderRadius="full" />
          <Text id={id} fontSize="18px">
            {title}
          </Text>
        </Flex>
        <CustomCheckBox
          backgroundImage="none"
          isChecked={checkedIds?.includes(id!)}
          onChange={() => {
            handleCheckIds(id!)
          }}
          color={theme?.elementsColor}
          hoverColor={theme?.backgroundLight}
        />
      </Flex>
      <Divider my="10px" color={colors.greyMedium} />
    </>
  )
}
