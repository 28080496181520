import React from 'react'
import { Box, BoxProps, Flex } from '@chakra-ui/core'
import { IconWrapper, UserMenuItemWrapper } from 'components/helperStyles'
import LocaleText from 'components/LocaleText'

interface IProps extends BoxProps {
  onClick?: VoidFunction
  icon: React.ReactNode
  children?: React.ReactNode
  literal: string
  textColor?: string
}

export const UserMenuItem = ({ icon, onClick, children, literal, textColor, ...props }: IProps) => {
  return (
    <UserMenuItemWrapper alignItems="center" cursor="pointer" onClick={onClick} width="100%" {...props}>
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Flex alignItems="center" py="10px">
          <IconWrapper>{icon}</IconWrapper>
          <Box mx="12px" color={textColor}>
            <LocaleText text={literal} />
          </Box>
        </Flex>
        {children}
      </Flex>
    </UserMenuItemWrapper>
  )
}
