import { Box, Flex } from '@chakra-ui/core'
import colors from 'constants/colors'
import React from 'react'
export const RadioCheckbox: React.FC<{ value: string; isChecked: boolean; onChange: () => void }> = ({
  value,
  isChecked,
  onChange,
  children,
}) => {
  return (
    <Flex alignItems="center" style={{ columnGap: '10px' }}>
      <Flex as="label" display="flex" alignItems="center" cursor="pointer" position="relative" mb="0px">
        <input type="checkbox" value={value} checked={isChecked} onChange={onChange} style={{ display: 'none' }} />
        <Box
          width="16px"
          height="16px"
          borderRadius="full"
          borderWidth="1px"
          borderStyle="solid"
          borderColor={isChecked ? colors.greenPoll : 'gray.400'}
          backgroundColor={isChecked ? colors.greenPoll : 'white'}
          alignItems="center"
          justifyContent="center"
        />
      </Flex>

      {children}
    </Flex>
  )
}
