import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/core'
import { Loader } from 'components/CommonComponents/Loader'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'

const ExternalSite = () => {
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { uid } = useSelector((state: RootState) => state.auth)
  const { rid } = useSelector((state: RootState) => state.config.config)

  useEffect(() => {
    if (rid && rid !== 'maxstock') {
      navigate(keys.ROUTE_NAMES.NEWS_FEED)
    }
  }, [navigate, rid])

  const handleIframeLoad = () => {
    setIsLoading(false)
  }
  const getUrl = () => {
    switch (uid) {
      case '033750035':
      case '302632377':
        return 'https://forms.storee.ai/tables/233154210546043'
      case '039267885':
        return 'https://forms.storee.ai/tables/233153548991969'
      case '200928497':
        return 'https://forms.storee.ai/tables/240631650749963'
      case '032817942':
        return 'https://forms.storee.ai/tables/233153747157964'
      case '204110902':
        return 'https://forms.storee.ai/tables/233153648088967'
      case '032472268':
        return 'https://forms.storee.ai/tables/233153366336960'
      case '040044406':
        return 'https://forms.storee.ai/tables/233333205127950'
      case '040490039':
      case '305035560':
        return 'https://forms.storee.ai/tables/241852186636059'
      case '300095973':
        return 'https://forms.storee.ai/tables/241282775895067'
      case '037811171':
      case '206016438':
      case '306016478':
      case '203893151':
        return 'https://forms.storee.ai/tables/241084325778967'
      case '039727433':
      case '300170503':
        return 'https://forms.storee.ai/tables/241611926514958'
      default:
        return 'https://forms.storee.ai'
    }
  }

  return (
    <Box p="2rem" h="92vh" w="100%">
      {isLoading && <Loader />}
      <iframe src={getUrl()} width="100%" height="100%" title="jotform" onLoad={handleIframeLoad} />
    </Box>
  )
}

export default ExternalSite
