import React, { memo, useCallback, useEffect, useState } from 'react'
import { Box, Flex, Text, Button, Image } from '@chakra-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { isNil } from 'lodash'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import { UploadedFileList } from 'components/UploadedFileList'
import { PostAudioList } from 'components/NewsFeed/PostAudioList'
import { PostMediaGrid } from '../NewsFeed/PostMediaGrid'
import { FbGridVideoPreview } from '../CommonComponents/FbGrid/FbGridVideoPreview'
import { LikeButton } from './LikeButton'
import { CommentButton } from './CommentButton'
import { Post } from 'redux/reducers/feed'
import keys from 'constants/keys'
import colors from '../../constants/colors'
import { ViewButton } from './ViewButton'
import { useLinkedPostTasks } from './postHooks/useLinkedPostsTasks'
import 'video-react/dist/video-react.css'
import PostCommentsContainer from 'components/Comments/PostCommentsContainer'
import { setViewedCommentsIds, updateViews } from 'redux/actions/comments'
import { LikeButtonSquare } from './LikeButtonSquare'
import { PostTitle } from './PostTitle'
import { useLinkedChatPost } from './postHooks/useLinkedChatPost'
import { SmartAvatar } from 'components/CommonComponents/SmartAvatar'

interface ExpandedPost extends Post {
  commentsCounter: number
  commentsCounterLoading: boolean
  isShowLikeButton: boolean
  isLikedPost: boolean
  onViewModalOpen: () => void
  requireToUpdateViews: string[]
  likeHandler: VoidFunction
  isAllPostCollapsed: boolean
  selectedPostID?: string
  isDoublePost?: boolean
  handleLikesModal?: () => void
}
export const ExpandedPost = memo(
  ({
    onViewModalOpen,
    commentsCounter,
    commentsCounterLoading,
    isShowLikeButton,
    isLikedPost,
    likeHandler,
    requireToUpdateViews,
    isDoublePost,
    isAllPostCollapsed,
    selectedPostID,
    handleLikesModal,
    ...post
  }: ExpandedPost) => {
    const dispatch = useDispatch()
    const uid = useSelector((state: RootState) => state.auth.uid)
    const [showCommentsSection, setShowCommentsSection] = useState<boolean>(true)

    const [isInputFocus, setIsInputFocus] = useState<boolean>(false)
    const comments = useSelector((state: RootState) => state.comments.commentsState?.[post.post_id]) || []
    const { retailUsersObject } = useSelector((state: RootState) => state.config.config)

    const {
      isValidRefId,
      currentLinkedTask,
      linkedToTaskPage,
      handleLinkedPost,
      isPostLinkedToTaskPage,
      taskViewNames,
    } = useLinkedPostTasks({ ref_id: post.ref_id })

    const { onChatClick, linkedChat, isValidChatRefId, isPrefetching } = useLinkedChatPost({ ref_id: post.ref_id })

    const showComments = useCallback(() => {
      setShowCommentsSection(!showCommentsSection)
      setIsInputFocus(!showCommentsSection)
    }, [showCommentsSection])

    useEffect(() => {
      if (comments || (isDoublePost && selectedPostID === post.post_id)) {
        const idsPayload = comments?.items?.map((item) => item.cid) || []
        dispatch(
          updateViews({
            postID: post.post_id,
            viewedIds: idsPayload,
          })
        )

        dispatch(
          setViewedCommentsIds({
            key: post.post_id,
            ids: idsPayload,
          })
        )
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, isDoublePost, post.post_id, selectedPostID])

    return (
      <Box>
        {post?.title && (
          <Flex alignItems="center">
            <Box px="50px" w="100%" wordBreak={'break-word'}>
              <PostTitle post={post} retailUsersObject={retailUsersObject} isDoublePost={isDoublePost!} />
            </Box>
          </Flex>
        )}
        {post.audios && post.audios.length > 0 && (
          <Box px="50px" py="10px">
            <PostAudioList audios={post.audios} />
          </Box>
        )}

        {post.files && post.files.length > 0 && (
          <Box px="50px" marginBottom="15px">
            <UploadedFileList fileList={post.files} />
          </Box>
        )}

        {post.images && post.images.length > 0 && (
          <PostMediaGrid media={post.images} isEditable={false}>
            {post.images !== null &&
              post.images?.map((imageSrc) => <Image key={imageSrc} src={imageSrc} width="100%" />)}
          </PostMediaGrid>
        )}

        {post.videos && post.videos.length > 0 && (
          <PostMediaGrid media={post.videos} isEditable={false}>
            {post.videos !== null &&
              post.videos?.map((videoSrc) => <FbGridVideoPreview key={videoSrc} src={videoSrc} />)}
          </PostMediaGrid>
        )}

        <Box className="card-bottom" color={colors.greyMain} fontWeight={500} fontSize="16px">
          {isValidRefId && post.type === keys.POST_TYPE.LINKED && (
            <Flex justifyContent="space-between" alignItems="center" my="10px">
              <Box>
                <Text fontSize="13px" color={colors.greyDark}>
                  <LocaleText text="linked_tasks_title" />
                </Text>
                <Text color={colors.blackLight} fontWeight="bold">
                  {isPostLinkedToTaskPage && !isNil(linkedToTaskPage) ? (
                    <LocaleText text={taskViewNames[linkedToTaskPage]} />
                  ) : (
                    currentLinkedTask.title
                  )}
                </Text>
              </Box>
              <Box>
                <Button
                  onClick={handleLinkedPost}
                  color={colors.blackLight}
                  fontSize="12px"
                  variant="outline"
                  borderRadius="8px"
                  borderColor={colors.blackLight}
                >
                  <LocaleText text="view_task" />
                </Button>
              </Box>
            </Flex>
          )}

          {isValidChatRefId && post.type === keys.POST_TYPE.LINKED && linkedChat && (
            <Flex justifyContent="space-between" alignItems="flex-end" my="10px">
              <Box>
                <Text fontSize="13px" color={colors.greyDark}>
                  <LocaleText text="linked_chat_title" />
                </Text>
                <Box>
                  <Flex flexDir="row" alignItems="center" color={colors.blackLight}>
                    <SmartAvatar
                      image={linkedChat.images?.[0]}
                      size="40px"
                      backgroundColor={colors.greyLight4}
                      iconColor={colors.tealLight}
                    />
                    <Text mx="10px">{linkedChat.title}</Text>
                  </Flex>
                </Box>
              </Box>
              <Box>
                <Button
                  isDisabled={isPrefetching || !linkedChat}
                  isLoading={isPrefetching}
                  onClick={onChatClick}
                  color={colors.blackLight}
                  fontSize="12px"
                  variant="outline"
                  borderRadius="8px"
                  borderColor={colors.blackLight}
                >
                  <LocaleText text="view_chat" />
                </Button>
              </Box>
            </Flex>
          )}

          {post.type !== keys.POST_TYPE.GROUP_POST ? (
            <Flex alignItems="center" style={{ columnGap: '20px' }} justifyContent="flex-end" w="100%" px="4px">
              <LikeButton
                created_by={post.created_by}
                likes_counter={post.likes_counter}
                liked={post.liked}
                isShowLikeButton={isShowLikeButton}
                isLikedPost={isLikedPost}
                isDoublePost={isDoublePost}
                isAllPostCollapsed={isAllPostCollapsed}
                handleLikesModal={handleLikesModal}
              />
              {post.viewed !== undefined && post.views_counter !== undefined && (
                <ViewButton
                  isDoublePost={isDoublePost}
                  isAllPostCollapsed={isAllPostCollapsed}
                  viewed={post.viewed}
                  views_counter={post.views_counter}
                  created_by={post.created_by}
                  openModal={onViewModalOpen}
                  isShowViewIcon={requireToUpdateViews.includes(uid!) && post.created_by !== uid}
                  isViewed={requireToUpdateViews.includes(uid!) && post.viewed[uid!]}
                />
              )}

              <CommentButton
                isDoublePost={isDoublePost}
                isAllPostCollapsed={isAllPostCollapsed}
                postId={post.post_id}
                commentsCounterLoading={commentsCounterLoading}
                showComments={showComments}
                showCommentsSection={showCommentsSection}
                commentsCounter={commentsCounter}
                isViewed={
                  post.viewed[uid!] ||
                  (requireToUpdateViews.includes(uid!) && post.created_by === uid!) ||
                  !requireToUpdateViews.includes(uid!)
                }
              />
            </Flex>
          ) : (
            <Box h="25px" />
          )}
        </Box>
        {isShowLikeButton && post.type !== keys.POST_TYPE.GROUP_POST && (
          <LikeButtonSquare liked={post.liked[uid!]} likeHandler={likeHandler} />
        )}
        <Box px="50px">
          <PostCommentsContainer
            postID={post.post_id}
            postAuthorID={post.created_by}
            rasID={post.ras_id}
            isInputFocus={isInputFocus}
            showCommentsSection={showCommentsSection}
            updatedCommentsCounter={commentsCounter}
            isDoublePost={isDoublePost}
            postType={post.type}
          />
        </Box>
      </Box>
    )
  }
)
