import { actionTypes } from 'redux/actions/admin'

export interface IUserObject {
  expo_push_token?: string
  first_name: string
  last_name: string
  uid: string
  locale: string
  profile_img_url?: string
}
export interface IUserDetailedObject {
  uid: string
  first_name: string
  last_name: string
  password: string
  email: string
  phone_number: string
  country_code: string
  enable_mfa: boolean
  locale: string
  permission: number | null
  user_group_role: number
  groups?: string[]
  desc?: string
  sec_uid?: string
}
export interface IUserEditObject {
  uid: string
  first_name?: string
  last_name?: string
  password?: string
  email?: string
  phone_number?: string
  country_code?: string
  enable_mfa?: boolean
  locale?: string
  permission?: number | null
  role?: number
}
export interface IAdminState {
  userTable: {
    usersList: IUserObject[] | null
    currentUser: any
    isLoading: boolean
    isFetching: boolean
    isActionSuccessful: boolean
    isError: boolean
    detailedUser: IUserDetailedObject | null
    registeredUser: IUserDetailedObject | null
  }
}
export const initialState: IAdminState = {
  userTable: {
    usersList: null,
    currentUser: null,
    isLoading: false,
    isFetching: false,
    isActionSuccessful: false,
    isError: false,
    detailedUser: null,
    registeredUser: null,
  },
}

const AdminReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_REGISTERED_USER:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          registeredUser: action.payload,
        },
      }
    case actionTypes.SET_DETAILED_USER:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          detailedUser: action.payload,
        },
      }
    case actionTypes.SET_IS_ERROR:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          isError: action.payload,
        },
      }
    case actionTypes.SET_IS_ACTION_SUCCESSFUL:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          isActionSuccessful: action.payload,
        },
      }
    case actionTypes.SET_IS_FETCHING:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          isFetching: action.payload,
        },
      }
    case actionTypes.SET_IS_ADMIN_PAGE_LOADING:
      return {
        ...state,
        userTable: {
          ...state.userTable,
          isLoading: action.payload,
        },
      }
    case actionTypes.SET_USERS_LIST: {
      return {
        ...state,
        userTable: {
          ...state.userTable,
          usersList: action.payload,
        },
      }
    }
    default:
      return state
  }
}

export default AdminReducer
