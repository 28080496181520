import { Flex, Text } from '@chakra-ui/core'
import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IDataFilterValue, ITag } from 'constants/interfaces'
import keys from 'constants/keys'
import { SelectDropdown } from 'components/CommonComponents/Select/Control'
import { CustomMenu } from './CustomMenu'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { isRtlSelector } from 'redux/selectors/general'
import { IReportFiltersProps } from './filter.types'

export const ReportFilters = memo(
  ({
    handleGetReportData,
    handleDeepDiveReportData,
    selectedUsers,
    permittedUsersList,
    isReportLoading,
    setSelectedUsers,
    setSelectedTag,
    tagsList,
    selectedTag,
    selectedDataFilter,
    setSelectedDataFilter,
    dataFiltersList,
  }: IReportFiltersProps) => {
    const dispatch = useDispatch()
    const isRtl = useSelector(isRtlSelector)
    const tags_filter_t = useLocaleText('ranking_filter')

    const handleChangeUserLevelSelection = useCallback(
      (selectedUsersIds: string[]) => {
        dispatch(setSelectedUsers(selectedUsersIds))
        handleGetReportData?.({ argSelectedUsersIds: selectedUsersIds })
        handleDeepDiveReportData?.({ selectedUsers: selectedUsersIds })
      },
      [dispatch, handleDeepDiveReportData, handleGetReportData, setSelectedUsers]
    )

    const getReportByTag = useCallback(
      (tag: ITag) => {
        dispatch(setSelectedTag(tag))
        handleGetReportData?.({ argSelectedTag: tag })
        handleDeepDiveReportData?.({ argTag: tag })
      },
      [dispatch, handleDeepDiveReportData, handleGetReportData, setSelectedTag]
    )
    const getReportByDataFilter = useCallback(
      (dataFilter: IDataFilterValue) => {
        dispatch(setSelectedDataFilter?.(dataFilter))
        handleGetReportData?.({ argDataFilter: dataFilter })
        // handleDeepDiveReportData?.({ argDataFilter: dataFilter })
      },
      [dispatch, handleGetReportData, setSelectedDataFilter]
    )

    return (
      <>
        <Flex alignItems="center" style={{ columnGap: '10px' }}>
          {permittedUsersList.length > 0 && (
            <SelectDropdown
              itemsDataArray={permittedUsersList}
              isLoading={isReportLoading}
              mode={keys.SELECT_MODES.MULTIPLE}
              isShowSearch={true}
              selectedIds={selectedUsers}
              onSubmit={handleChangeUserLevelSelection}
            />
          )}
          {tagsList && tagsList.length > 0 && (
            <CustomMenu
              isShowClearButton={true}
              buttonLabel={
                <Text as={'span'} fontFamily='"Asap Condensed", sans-serif'>
                  <LocaleText text={selectedTag?.name || tags_filter_t} />
                </Text>
              }
              menuItems={tagsList.map((tag, index) => ({
                key: index,
                label: <LocaleText text={tag.name} />,
                value: tag,
                isChecked: selectedTag ? tag.sk === selectedTag.sk : false,
              }))}
              onMenuItemClick={(selectedTag) => {
                getReportByTag(selectedTag)
              }}
              isReportLoading={isReportLoading}
              isRtl={isRtl}
            />
          )}
          {dataFiltersList && selectedDataFilter && (
            <CustomMenu
              buttonLabel={
                <Text as={'span'} fontFamily='"Asap Condensed", sans-serif'>
                  <LocaleText text={selectedDataFilter.name} />
                </Text>
              }
              menuItems={dataFiltersList[0].values.map((filter, index) => ({
                key: index,
                label: <LocaleText text={filter.name} />,
                value: filter,
                isChecked: selectedDataFilter ? filter.value === selectedDataFilter.value : false,
              }))}
              onMenuItemClick={(dataFilter) => {
                getReportByDataFilter(dataFilter)
              }}
              isReportLoading={isReportLoading}
              isRtl={isRtl}
            />
          )}
        </Flex>
      </>
    )
  }
)
