import { Button } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

export const PingedButton = () => {
  const pingedLiteral = useLocaleText('pinged')
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <Button
      variant="outline"
      color="white"
      bg={theme?.elementsColor}
      fontWeight="500"
      borderRadius="16px"
      isDisabled={true}
      height="32px"
      pl="24px"
      pr="24px"
      cursor="default"
      _focus={{
        outline: 'none',
      }}
      _hover={{ bg: theme?.elementsColor }}
    >
      {pingedLiteral}
    </Button>
  )
}
