import { Box, Button, Divider, Flex, IconButton, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import { IConfigRetailUser, RootState } from 'constants/interfaces'
import React, { useMemo, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import BaseModal from '../BaseModal'
import InputSearch from '../GroupsTree/InputSearch'
import { NoResults } from '../GroupsTree/NoResults'
import UserNode from './UserNode'
import { SelectedItemsCount } from './SelectedCountOfUsers'

interface IUsersList {
  isOpen: boolean
  onClose: () => void
  setShowUsersList?: (arg: boolean) => void
  setSelectedUsersUids: (arg: string[]) => void
  selectedUsersUids: string[]
  setViewOption?: React.Dispatch<React.SetStateAction<number | null>>
  treeUsers: IConfigRetailUser[]
}

export const UsersList = ({
  isOpen,
  onClose,
  setShowUsersList,
  setViewOption,
  setSelectedUsersUids,
  selectedUsersUids,
  treeUsers,
}: IUsersList) => {
  const { theme, locale } = useSelector((state: RootState) => state?.general)
  const [checkedUids, setCheckedUids] = useState<string[]>(selectedUsersUids || [])
  const [searchValue, setSearchValue] = useState<string>('')
  const isHebrew = locale === 'he'
  const isRtl = isHebrew ? 'rtl' : 'ltr'
  const searchPlaceholder = useLocaleText('post_select_users_search')

  const handleCheckUids = (uid: string) => {
    if (checkedUids.includes(uid)) {
      setCheckedUids(checkedUids.filter((id: any) => id !== uid))
    } else {
      setCheckedUids([...checkedUids, uid])
    }
  }
  const searchResults = useMemo(() => {
    return treeUsers?.filter((user) =>
      `${user.first_name} ${user.last_name} ${user.desc} ${user.sec_uid}`
        ?.toLocaleLowerCase()
        .includes(searchValue.toLocaleLowerCase())
    )
  }, [searchValue, treeUsers])

  const onSubmit = () => {
    if (searchValue) {
      setSearchValue('')
    } else {
      setSelectedUsersUids(checkedUids)
      setShowUsersList?.(false)
      setViewOption?.(null)
    }
  }
  const handleSelectAll = () => {
    if (checkedUids.length === treeUsers?.length) {
      setCheckedUids([])
    } else {
      const allUids = treeUsers?.map((user) => user.uid)
      setCheckedUids(allUids)
    }
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      headerContent={
        <>
          <Flex alignItems="center" justifyContent="space-between" dir={isRtl}>
            <Box
              onClick={() => {
                setShowUsersList?.(false)
                setViewOption?.(null)
              }}
              mx="4"
              position="absolute"
            >
              <IconButton
                aria-label="close groups"
                icon={BsArrowLeftShort}
                borderRadius="50%"
                style={{ color: colors.greyMain }}
                fontSize="32px"
                transform={`rotate(${+isHebrew * 180}deg)`}
              />
            </Box>
            <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
              <LocaleText text="post_select_users_main" />
            </Text>
          </Flex>
          <Divider />
          <Flex flexDirection="column" px={6} mt="20px">
            <InputSearch searchValue={searchValue} setSearchValue={setSearchValue} placeholder={searchPlaceholder} />
            {searchValue ? (
              <Text color={colors.greyDark} fontSize="16px" fontWeight="700" my="17px" textAlign="start">
                <LocaleText text="select_group_search_results" />
              </Text>
            ) : (
              <Flex justifyContent="space-between" alignItems="center" mt="17px">
                <SelectedItemsCount data={treeUsers} checkedIds={checkedUids} />
                <Button
                  variant="outline"
                  borderRadius="16px"
                  py="6px"
                  height="auto"
                  onClick={handleSelectAll}
                  minW="90px"
                >
                  {checkedUids.length === treeUsers.length ? (
                    <LocaleText text="create_task_post_assignee_clear_button" />
                  ) : (
                    <LocaleText text="create_task_post_assignee_select_all_button" />
                  )}
                </Button>
              </Flex>
            )}
          </Flex>
        </>
      }
      bodyContent={
        <>
          {treeUsers && (
            <Flex width="100%" flexDirection="column" paddingRight="50px" paddingLeft="50px" minHeight="300px">
              {searchValue ? (
                <>
                  {searchResults?.length ? (
                    searchResults.map((result) => {
                      return (
                        <Box key={result.uid}>
                          <UserNode
                            id={result.uid}
                            desc={result.desc}
                            secondaryUID={result.sec_uid}
                            title={`${result.first_name} ${result.last_name ? result.last_name : ''}`}
                            profileImg={result.profile_img_url}
                            checkedUids={checkedUids}
                            handleCheckUids={handleCheckUids}
                          />
                        </Box>
                      )
                    })
                  ) : (
                    <NoResults />
                  )}
                </>
              ) : (
                <>
                  {treeUsers.map((user: any) => {
                    return (
                      <Box key={user.uid}>
                        <UserNode
                          id={user.uid}
                          desc={user.desc}
                          secondaryUID={user.sec_uid}
                          title={`${user.first_name} ${user.last_name ? user.last_name : ''}`}
                          profileImg={user.profile_img_url}
                          checkedUids={checkedUids}
                          handleCheckUids={handleCheckUids}
                        />
                      </Box>
                    )
                  })}
                </>
              )}
            </Flex>
          )}
        </>
      }
      footerContent={
        <Flex justifyContent="flex-end" background="white" px="6">
          {searchValue && searchResults.length === 0 ? (
            <></>
          ) : (
            <Button
              borderRadius="20px"
              onClick={onSubmit}
              color="white"
              textTransform="uppercase"
              background={theme?.elementsColor}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="select_group_select_button" />
            </Button>
          )}
        </Flex>
      }
    />
  )
}
