import { IPops } from 'constants/interfaces'
import { actionTypes } from '../actions/pops'

const initialState: IPops = {
  pops: [],
  dummyPop: null,
  isLoading: false,
}
export const popsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_DUMMY_POP:
      return {
        ...state,
        dummyPop: action.payload,
      }
    case actionTypes.SET_POPS:
      return {
        ...state,
        pops: action.payload,
      }
    case actionTypes.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    default:
      return state
  }
}
