import React from 'react'
import { Avatar as StyledAvatar, AvatarProps } from '@chakra-ui/core'
import avatarPlaceholder from 'assets/avatar-placeholder.svg'

interface CustomAvatarProps extends AvatarProps {
  width: string
  height: string
}

const Avatar = ({ src, width, height }: CustomAvatarProps) => {
  return <StyledAvatar src={src} size="sm" style={{ width: width, height: height }} />
}

Avatar.defaultProps = {
  src: avatarPlaceholder,
  width: '34px',
  height: '34px',
}
export default Avatar
