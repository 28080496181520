import { Box, Flex, Image } from '@chakra-ui/core'
import { LinkPreview } from 'components/LinkPreview'
import React, { memo } from 'react'
import { PostAudioList } from '../PostAudioList'
import { PostMediaGrid } from '../PostMediaGrid'
import { FbGridVideoPreview } from 'components/CommonComponents/FbGrid/FbGridVideoPreview'
import { UploadedFileList } from 'components/UploadedFileList'
import { OptionType } from '../constants'

interface IProps {
  validLinks: {
    type: string
    value: any
    isLink: boolean
    href: string
    start: number
    end: number
  }[]
  audioRecords: string[]
  uploadedFilesURL: string[]
  uploadedImagesURL: string[]
  uploadedVideosURL: string[]
  media: string[]
  selectedOptionType: string
  addMediaHandler: () => void
  handlerDeleteAllMedia: () => void
  handleDeleteMedia: (mediaIdx: number) => void
  removeAudioRecord: (audioSrc: string) => void
  deleteFileHandler: (url: string) => void
}

export const MediaContentBlock = memo(
  ({
    validLinks,
    audioRecords,
    uploadedFilesURL,
    uploadedImagesURL,
    uploadedVideosURL,
    media,
    addMediaHandler,
    handlerDeleteAllMedia,
    handleDeleteMedia,
    removeAudioRecord,
    deleteFileHandler,
    selectedOptionType,
  }: IProps) => {
    return (
      <>
        {selectedOptionType === OptionType.POST && (
          <>
            {!!validLinks && validLinks.length > 0 && (
              <LinkPreview width="100%" url={validLinks[validLinks.length - 1].href} />
            )}
            {!!audioRecords.length && (
              <Box my="10px">
                <PostAudioList audios={audioRecords} onDelete={removeAudioRecord} />
              </Box>
            )}
            {!!uploadedFilesURL.length && (
              <UploadedFileList
                fileList={uploadedFilesURL}
                deleteFileHandler={deleteFileHandler}
                isFileOpened={false}
              />
            )}
            {!!uploadedImagesURL.length && (
              <PostMediaGrid
                media={media}
                addMedia={addMediaHandler}
                deleteAll={handlerDeleteAllMedia}
                onDeleteMedia={handleDeleteMedia}
              >
                {uploadedImagesURL.map((imageSrc) => (
                  <Image objectFit="contain" key={imageSrc} src={imageSrc} width="100%" />
                ))}
              </PostMediaGrid>
            )}
            {!!uploadedVideosURL.length && (
              <PostMediaGrid
                media={media}
                addMedia={addMediaHandler}
                deleteAll={handlerDeleteAllMedia}
                onDeleteMedia={handleDeleteMedia}
              >
                {uploadedVideosURL.map((videoSrc) => (
                  <FbGridVideoPreview key={videoSrc} src={videoSrc} />
                ))}
              </PostMediaGrid>
            )}
          </>
        )}

        {selectedOptionType === OptionType.POP && (
          <Flex justifyContent="center">
            {!!uploadedImagesURL.length && (
              <PostMediaGrid
                media={media}
                addMedia={addMediaHandler}
                deleteAll={handlerDeleteAllMedia}
                onDeleteMedia={handleDeleteMedia}
                isPopImage={true}
              >
                <Image objectFit="contain" key={uploadedImagesURL[0]} src={uploadedImagesURL[0]} />
              </PostMediaGrid>
            )}
          </Flex>
        )}
      </>
    )
  }
)
