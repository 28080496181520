import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'
import { ReactComponent as PinIcon } from 'assets/pin-angle-fill.svg'
import { ReactComponent as PinIconPink } from 'assets/pin-angle-fill-pink.svg'
import { Box } from '@chakra-ui/core'

interface IProps {
  post_id: string
  is_manager_pin?: boolean
  isNewPost?: boolean
  pinedPostsObject: { [key: string]: string[] }
}

export const PostPin = memo(({ post_id, is_manager_pin, isNewPost, pinedPostsObject }: IProps) => {
  const myUID = useSelector((state: RootState) => state.auth.uid)
  const theme = useSelector((state: RootState) => state.general.theme)
  const isRtl = useSelector(isRtlSelector)
  return (
    <>
      {(pinedPostsObject[myUID!]?.includes(post_id) || is_manager_pin) && isNewPost && (
        <Box w="70px">
          <Box pos="absolute" top="15px" right={isRtl ? 'unset' : '50px'} left={isRtl ? '50px' : 'unset'}>
            {is_manager_pin ? (
              <PinIconPink
                width="23px"
                height="23px"
                style={{ '--svg-color': theme?.elementsColor } as React.CSSProperties}
              />
            ) : (
              <PinIcon width="23px" height="23px" />
            )}
          </Box>
        </Box>
      )}
    </>
  )
})
