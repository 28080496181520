import { Divider, Flex } from '@chakra-ui/core'
import { ChatBody } from 'components/Chat/ChatBody'
import { ChatHeader } from 'components/Chat/ChatHeader'
import { CommentInput } from 'components/Comments'
import { IHandleCreateComment, RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import useComments from 'hooks/useComments'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Params, useLocation, useNavigate, useParams } from 'react-router-dom'
import { setCommentsCounter, setViewedCommentsIds, updateViews } from 'redux/actions/comments'
import { getCurrentEpoch, trimAndCountExtraGroups } from 'utils'

export default function GroupChat() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { id } = useParams<Params<string>>()
  const query = new URLSearchParams(useLocation().search)
  const isPrefetched = query.get('prefetched')

  const {
    sendComment,
    commentsCounter,
    sendCommentLoading,
    fetchComments,
    fetchCommentsLoading,
    fetchCommentsCounter,
  } = useComments(id!)
  const { uid } = useSelector((state: RootState) => state.auth)
  const {
    userWritePermittedContext: { groups },
    config: { retailUsersObject },
  } = useSelector((state: RootState) => state.config)
  const comments = useSelector((state: RootState) => state.comments.commentsState?.[id!]?.items ?? [])
  const messages = useSelector((state: RootState) => (id ? state.comments.commentsState?.[id] : undefined))
  const chats = useSelector((state: RootState) => (!isPrefetched ? state.feed.chats : state.feed.prefetchedChats))
  const { isConfigReady } = useSelector((state: RootState) => state.config)
  const currentChat = chats.find((chat) => chat.post_id === id)

  const rasID = currentChat?.ras_id || ''
  const groupsInChat = currentChat?.groups ?? []
  const usersInChat = currentChat?.post_origin_users ?? []
  const groupUsersInChat = currentChat?.group_users ?? []
  const extractedUidsFromGroups = groupsInChat.flatMap((groupId) => groups[groupId]?.uids)
  const isCurrentUserIsChatCreator = currentChat?.created_by === uid
  const isCurrentChatUserIsAdmin = currentChat?.admins?.includes(uid!)

  const isShowCommentInput =
    currentChat?.type === keys.POST_TYPE.GROUP_POST ||
    ((isCurrentUserIsChatCreator || isCurrentChatUserIsAdmin) &&
      currentChat?.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT)

  const uniqUids = isCurrentUserIsChatCreator
    ? Array.from(new Set([...usersInChat, ...extractedUidsFromGroups]))
    : Array.from(new Set([...groupUsersInChat, ...usersInChat]))

  const usersNames =
    uniqUids.map(
      (u) =>
        retailUsersObject?.[u]?.first_name +
        ' ' +
        (retailUsersObject?.[u]?.last_name ? retailUsersObject?.[u]?.last_name : '')
    ) ?? []

  const chatAudience = usersNames.join(', ')
  const trimmedAudience = trimAndCountExtraGroups({ items: chatAudience, sliceLimit: 5 })

  if (!currentChat) {
    navigate('/')
  }

  const handleCreateMessage = async ({ comment, images, videos, audios, file }: IHandleCreateComment) => {
    const commentID = await sendComment({
      id: id!,
      comment,
      rasID,
      createdByUserId: currentChat?.created_by,
      images,
      videos,
      audios,
      objectType:
        currentChat?.type === keys.POST_TYPE.GROUP_POST
          ? keys.COMMENT_OBJECT_TYPE.MESSAGE_ON_GROUP
          : keys.COMMENT_OBJECT_TYPE.MESSAGE_ON_ANNOUNCEMENT_GROUP,
      file,
      is_update_post: true,
      isChat: true,
    })

    if (commentID) dispatch(setCommentsCounter({ count: commentsCounter + 1, key: id! }))
  }

  useEffect(
    () => {
      if (currentChat) {
        const idsPayload = comments?.map((item) => item.cid) || []
        dispatch(
          updateViews({
            postID: currentChat.post_id,
            viewedIds: idsPayload,
          })
        )

        dispatch(
          setViewedCommentsIds({
            key: currentChat.post_id,
            ids: idsPayload,
          })
        )
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, currentChat]
  )

  const getComments = useCallback(
    async (isManuallyTriggered?: boolean, id?: string) => {
      try {
        let timestampFromToGetComments = getCurrentEpoch()

        const comments = await fetchComments({
          limit: 12,
          created_ts: timestampFromToGetComments,
          isManuallyTriggered,
          id,
        })
        if (!comments.length) {
          return
        }
      } catch (error) {
        console.log(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (!isPrefetched) return
    if (comments && comments.length > 0) return
    getComments()
    fetchCommentsCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, id, isPrefetched])

  if (!currentChat && !fetchCommentsLoading && !isConfigReady) {
    navigate(keys.ROUTE_NAMES.NEWS_FEED)
  }

  return (
    <>
      {currentChat && (
        <Flex
          direction="column"
          alignItems="center"
          m="auto"
          h={'calc(95vh - 94px)'}
          width="100%"
          maxW={{ base: '80%', md: '80%', lg: 'calc(60% - 8vw)', xl: 'calc(60% - 8vw)' }}
          mt="20px"
          bg="white"
        >
          <ChatHeader currentChat={currentChat} chatAudience={chatAudience} trimmedAudience={trimmedAudience} />
          <Divider my="0" w="100%" borderBottom="2px solid" />
          <ChatBody
            chatId={id!}
            messages={messages}
            commentsCounter={commentsCounter}
            comments={comments}
            fetchComments={fetchComments}
            fetchCommentsLoading={fetchCommentsLoading}
            currentChat={currentChat}
          />

          <Divider my="0" w="100%" borderBottom="2px solid" />
          {isShowCommentInput && (
            <CommentInput
              postID={id!}
              isChat
              handleCreateComment={handleCreateMessage}
              sendCommentLoading={sendCommentLoading}
              isFocused={false}
            />
          )}
        </Flex>
      )}
    </>
  )
}
