/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useCallback, useReducer, useMemo } from 'react'
import isSolid from 'is-solid'
import { httpAuth } from 'config/apiClient'
import endpoints from '../constants/endpoints'
import { getFileExtension, getUrlParamsString } from '../utils'
import useUploadService from './useUploadService'
import { useDispatch, useSelector } from 'react-redux'
import { IComment, RootState } from 'constants/interfaces'
import { v4 as uuid } from 'uuid'
import { PreviewFile } from 'config/types'
import { allTasksSelector } from 'redux/selectors/tasks'
import { useFileValidation } from './useFileValidation'
import {
  setComments,
  setCommentsCounter,
  setIsFetchRepliesCounter,
  setIsFetching,
  setViewedCommentsIds,
  updateViews,
} from 'redux/actions/comments'

export const useForceUpdate = () => (useReducer as any)(() => ({}))[1] as () => void

interface ISendComment {
  oid: string
  message: string
  object_type: number
  rg_id: string
  parent_cid?: string
  reply_cid?: string
  object_created_by?: string
  task_id?: string
  subtask_type?: number
  images?: string[]
  videos?: string[]
  notify_groups_ids?: string[]
  audios?: string[]
  files?: string[]
  is_update_post?: boolean
  groups?: string[]
  users?: string[]
}

export interface ISendCommentParams {
  id: string
  comment: string
  rasID: string
  objectType: number
  parentCID?: string
  replyCID?: string
  createdByUserId?: string
  taskId?: string
  subtaskType?: number
  images?: PreviewFile
  videos?: any
  audios?: PreviewFile
  file?: PreviewFile
  is_update_post?: boolean
  isPostCommnet?: boolean
  isChat?: boolean
}
export interface IFetchComments {
  offset?: number | null
  limit: number | null
  created_ts: number
  parentCID?: string
  isManuallyTriggered?: boolean
  id?: string
}

const useComments = (oid: string, isReply?: boolean) => {
  const dispatch = useDispatch()
  const repliesCounter = useSelector((state: RootState) => state.comments.repliesCounter?.[oid])
  const commentsCounter = useSelector((state: RootState) => state.comments.commentsCounter?.[oid])
  const commentsState = useSelector((state: RootState) => state.comments.commentsState[oid])
  const repliesState = useSelector((state: RootState) => state.comments.repliesState[oid])
  const posts = useSelector((state: RootState) => state.feed.posts)
  const chats = useSelector((state: RootState) => state.feed.chats)

  const targetCommentsState = useMemo(
    () => (isReply ? repliesState?.items || [] : commentsState?.items || []),
    [commentsState, repliesState, isReply]
  )
  const targetCounterState = useMemo(
    () => (isReply ? repliesCounter || 0 : commentsCounter || 0),
    [isReply, repliesCounter, commentsCounter]
  )

  const isLoadMore = useMemo(() => {
    return isReply ? repliesState?.hasMore || false : commentsState?.hasMore || false
  }, [repliesState?.hasMore, commentsState?.hasMore, isReply])

  const { videoFileHandler } = useUploadService(true)

  const [fetchCommentsLoading, setFetchCommentsLoading] = useState<boolean>(false)
  const [commentsCounterLoading, setCommentsCounterLoading] = useState<boolean>(true)
  const [sendCommentLoading, setSendCommentLoading] = useState<boolean>(false)
  const [deleteCommentLoading, setDeleteCommentLoading] = useState<boolean>(false)
  const { imageFileUpload, uploadFile } = useUploadService()
  const { fileSizeValidation } = useFileValidation()
  const allTasks = useSelector(allTasksSelector)

  const fetchComments = useCallback(
    async ({ offset = null, limit = null, created_ts, parentCID, isManuallyTriggered, id }: IFetchComments) => {
      setFetchCommentsLoading(true)
      const url = endpoints.comments
      const paramsObj = {
        oid,
        created_at_ts: created_ts,
        include_created_at_timestamp: false,
        offset,
        limit,
        parent_cid: parentCID,
      }

      const params = getUrlParamsString(paramsObj)

      try {
        const { data } = await httpAuth.get(url + params)
        if (isSolid(data.comments)) {
          // Reverse use for set date from older to new
          dispatch(
            setComments({
              items: [...data.comments.reverse(), ...targetCommentsState],
              hasMore: data.more,
              key: oid,
              isReply: isReply,
            })
          )
          if (isManuallyTriggered && id) {
            const viewedIds = data.comments.map((comment: IComment) => comment.cid)
            dispatch(
              updateViews({
                postID: id,
                viewedIds: viewedIds,
              })
            )
            dispatch(
              setViewedCommentsIds({
                key: id,
                ids: viewedIds,
              })
            )
          }
        }
        setFetchCommentsLoading(false)
        return Promise.resolve(data.comments)
      } catch (error) {
        setFetchCommentsLoading(false)
        console.log('Failed to fetch comments')
        Promise.reject(error)
      }
    },
    [oid, targetCommentsState]
  )
  const fetchCommentsCounter = useCallback(
    async (parent_cid?: string, id?: string) => {
      const key = id ? id : oid
      setCommentsCounterLoading(true)
      try {
        const url = endpoints.commentsCounter
        const params = getUrlParamsString({ oid: key, parent_cid })
        const { data } = await httpAuth.get(url + params)

        if (key in data) {
          dispatch(
            setCommentsCounter({
              count: data[key].num_of_comments,
              key: parent_cid ? parent_cid : key,
              isReply: isReply,
            })
          )
          dispatch(setViewedCommentsIds({ key: key, ids: data[key].viewed_cids }))
          if (parent_cid) {
            dispatch(setViewedCommentsIds({ key: parent_cid, ids: data[key].viewed_cids }))
          }
        }
      } catch (error) {
        console.log(error)
      } finally {
        setCommentsCounterLoading(false)
      }
    },
    [oid, commentsState?.[oid]?.items]
  )

  const sendComment = useCallback(
    async ({
      id,
      comment,
      rasID,
      objectType,
      parentCID,
      replyCID,
      createdByUserId,
      taskId,
      subtaskType,
      images,
      videos,
      audios,
      file,
      is_update_post,
      isPostCommnet = true,
      isChat = false,
    }: ISendCommentParams) => {
      const temporaryCommentId: string = uuid()
      dispatch(setIsFetching(true))
      setSendCommentLoading(true)
      dispatch(setIsFetchRepliesCounter(false))
      try {
        const targetPostsOrChats = isChat ? chats : posts
        const postGroupsAndUsers = isPostCommnet
          ? {
              groups: targetPostsOrChats.filter((post) => post.post_id === id)[0].post_origin_groups,
              users: targetPostsOrChats.filter((post) => post.post_id === id)[0].post_origin_users,
            }
          : {}
        const url = endpoints.comments
        let body: ISendComment = {
          oid: id,
          message: comment || '',
          object_type: objectType,
          rg_id: rasID,
          is_update_post,
          ...postGroupsAndUsers,
        }

        const timeCreation = Date.now() / 1000

        const temporaryComment: IComment = {
          ...body,
          isDummy: true,
          cid: temporaryCommentId,
          created_at_ts: timeCreation,
          parent_cid: parentCID || oid,
          object_type: objectType,
          created_by: createdByUserId || '',
          updated_at_ts: timeCreation,
          reply_to: '',
          reply_cid: '',
        }

        if (taskId) {
          const task = allTasks?.find((task) => task.tid === taskId)
          body.notify_groups_ids = task ? task.notify_groups_ids : []
        }

        if (parentCID) {
          body.parent_cid = parentCID
        }
        if (replyCID) {
          body.reply_cid = replyCID
        }
        if (createdByUserId) {
          body.object_created_by = createdByUserId
        }
        if (taskId) {
          body.task_id = taskId
        }

        if (videos) {
          let uploadedVideos: string[] = []

          for (let video of videos) {
            try {
              const isValid = fileSizeValidation(video.file)
              if (!isValid) {
                setSendCommentLoading(false)
                return
              }
              const uploadedLink = await videoFileHandler(video.file)
              uploadedVideos = [...uploadedVideos, uploadedLink!]
            } catch (error) {
              console.log(error)
            }
          }

          body.videos = uploadedVideos
        }

        if (Number(subtaskType) >= 0) {
          body.subtask_type = subtaskType
        }

        const mediaPromises = {}

        if (images) {
          temporaryComment.images = [images.previewUrl]
          const isValid = fileSizeValidation(images.file)
          if (!isValid) {
            setSendCommentLoading(false)
            return
          }
          const imagePromise = imageFileUpload(images.file)
          mediaPromises['images'] = imagePromise
        }
        if (audios) {
          temporaryComment.audios = [audios.previewUrl]
          const isValid = fileSizeValidation(audios.file)
          if (!isValid) {
            setSendCommentLoading(false)
            return
          }
          const extention = getFileExtension(audios.file.name)
          const audioPromise = uploadFile(audios.file, extention)
          mediaPromises['audios'] = audioPromise
        }
        if (file) {
          temporaryComment.files = [file.previewUrl]
          const isValid = fileSizeValidation(file.file)
          if (!isValid) {
            setSendCommentLoading(false)
            return
          }
          const extention = getFileExtension(file.file.name)
          const filePromise = uploadFile(file.file, extention)
          mediaPromises['files'] = filePromise
        }
        dispatch(setComments({ items: [...targetCommentsState, temporaryComment], key: oid, isReply: isReply }))
        const commentsWithoutTemporary = targetCommentsState.filter((comment) => comment.cid !== temporaryCommentId)

        const response = Promise.all(Object.values(mediaPromises))
          .then((data) => {
            const keys = Object.keys(mediaPromises)
            keys.forEach((key, index) => {
              body[key] = [data[index]]
            })
            return httpAuth.post(url, body)
          })

          .then(({ data }) => {
            const commentsWithoutTemporary = targetCommentsState.filter((comment) => comment.cid !== temporaryCommentId)
            dispatch(setComments({ items: [...commentsWithoutTemporary, data], key: oid, isReply: isReply }))

            dispatch(
              setViewedCommentsIds({
                key: id,
                ids: [data.cid],
              })
            )
            dispatch(setIsFetchRepliesCounter(true))
            return data.cid
          })
          .catch((error) => {
            console.log(error)
            dispatch(setComments({ items: commentsWithoutTemporary, key: oid, isReply: isReply }))
            return false
          })
          .finally(() => {
            setSendCommentLoading(() => false)
            dispatch(setIsFetching(false))
          })
        return Promise.resolve(response)
      } catch (error) {
        console.log(error)
      }
    },
    [oid, targetCommentsState, commentsCounter]
  )

  const deleteComment = useCallback(
    async ({ id, cid, reply_cid, isReplyed }: { id: string; cid: string; reply_cid?: string; isReplyed?: boolean }) => {
      setDeleteCommentLoading(true)
      try {
        const c_cid = reply_cid ? reply_cid : cid
        const url = endpoints.comments
        httpAuth.delete(url, {
          data: { oid: id, cid: c_cid },
        })
        const filteredComments = targetCommentsState.filter((comment) => comment.cid !== c_cid)
        if (isReplyed) {
          dispatch(setComments({ items: filteredComments, key: cid, isReply: true }))
        } else {
          dispatch(setComments({ items: filteredComments, key: oid, isReply: false }))
        }
      } catch (error) {
        console.log(error)
      } finally {
        setDeleteCommentLoading(false)
      }
    },
    [targetCommentsState, oid]
  )

  return {
    fetchComments,
    fetchCommentsCounter,
    sendComment,
    deleteComment,
    deleteCommentLoading,
    comments: targetCommentsState,
    setComments,
    commentsCounter: targetCounterState,
    fetchCommentsLoading,
    commentsCounterLoading,
    sendCommentLoading,
    isLoadMore,
  }
}

export default useComments
