import { Avatar, Box, Button, Divider, Flex, Text, useDisclosure } from '@chakra-ui/core'
import { ConfirmActionContent } from 'components/AdminSettings/Modals/ConfirmActionContent'
import BaseModal from 'components/CommonComponents/BaseModal'
import InputSearch from 'components/CommonComponents/GroupsTree/InputSearch'
import { NoResults } from 'components/CommonComponents/GroupsTree/NoResults'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { AppDispatch } from 'config/redux'
import { AppTheme, RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'
import { useUserName } from 'hooks/useUserName'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleUpdatePost } from 'redux/actions/feed'
import { Post } from 'redux/reducers/feed'
import { getTextDirection } from 'utils'

interface IPostAdminsModal {
  isOpen: boolean
  onClose: () => void
  post: Post
}

interface IPropsListItem {
  uid: string
  isSelectedAsAdmin: boolean
  handleSelectAdmins: (selectedAdmins: string) => void
  theme: AppTheme
}
interface ISearchResults {
  searchQuery: string
  searchResults: string[]
  potentialAdmins: string[]
  handleSelectAdmins: (selectedAdmins: string) => void
  theme: AppTheme
  selectedAdmins: string[]
}

const SearchResults = ({
  searchQuery,
  searchResults,
  potentialAdmins,
  handleSelectAdmins,
  theme,
  selectedAdmins,
}: ISearchResults) => {
  if (searchQuery && !searchResults.length) {
    return <NoResults />
  }
  const items = searchQuery ? searchResults : potentialAdmins
  return (
    <>
      {items.map((uid) => (
        <ListItem
          key={uid}
          uid={uid}
          isSelectedAsAdmin={selectedAdmins.includes(uid)}
          handleSelectAdmins={handleSelectAdmins}
          theme={theme!}
        />
      ))}
    </>
  )
}

const ListItem = memo(({ uid, isSelectedAsAdmin, handleSelectAdmins, theme }: IPropsListItem) => {
  const avatar = useAvatar(uid)
  const fullName = useUserName(uid)

  const t_set_as_admin = useLocaleText('posts_set_as_admin')
  const t_unset_as_admin = useLocaleText('posts_unset_as_admin')

  const setAdminLiteral = isSelectedAsAdmin ? t_unset_as_admin : t_set_as_admin

  return (
    <>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Box position="relative">
            <Avatar src={avatar} size="sm" style={{ width: '40px', height: '40px' }} />
          </Box>
          <Text fontWeight="600" mx="20px" isTruncated maxW="200px" dir={getTextDirection(fullName)}>
            {fullName}
          </Text>
        </Flex>
        <Button
          variantColor="white"
          fontWeight="500"
          borderRadius="16px"
          opacity={isSelectedAsAdmin ? 0.4 : 1}
          background={theme?.elementsColor}
          height="32px"
          pl="24px"
          pr="24px"
          onClick={() => handleSelectAdmins(uid)}
          _focus={{
            outline: 'none',
          }}
        >
          {setAdminLiteral}
        </Button>
      </Flex>
      <Divider />
    </>
  )
})
export const PostAdminsModal = ({ isOpen, onClose, post }: IPostAdminsModal) => {
  const dispatch: AppDispatch = useDispatch()

  const t_set_admins = useLocaleText('posts_set_admins')
  const searchPlaceholder = useLocaleText('post_select_users_search')
  const { retail_users } = useSelector((state: RootState) => state.config.config)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { isOpen: isOpenConfirmation, onOpen: onOpenConfirmation, onClose: onCloseConfirmation } = useDisclosure()

  const [selectedAdmins, setSelectedAdmins] = useState<string[]>(post?.admins ?? [])
  const [searchQuery, setSearchQuery] = useState('')

  const sortedPotentialAdmins = useMemo(() => {
    const adminsSet = new Set(selectedAdmins)
    const potentialAdmins = Array.from(new Set([...post?.users, ...post?.group_users, ...selectedAdmins])).filter(
      (userID) => userID !== post.created_by
    )
    return potentialAdmins.sort((a, b) => {
      const aIsAdmin = adminsSet.has(a)
      const bIsAdmin = adminsSet.has(b)

      if (aIsAdmin && !bIsAdmin) return -1
      if (!aIsAdmin && bIsAdmin) return 1

      return 0
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [post])

  const filteredRetailUsers = retail_users.filter((user) => sortedPotentialAdmins.includes(user.uid))

  const searchResults = useMemo(() => {
    const results = filteredRetailUsers?.filter((user) =>
      `${user.first_name} ${user.last_name} ${user.desc} ${user.sec_uid}`
        ?.toLocaleLowerCase()
        .includes(searchQuery.toLocaleLowerCase())
    )
    return results.map((user) => user.uid)
  }, [filteredRetailUsers, searchQuery])

  const handleSelectAdmins = useCallback((uid: string) => {
    setSelectedAdmins((prevSelectedAdmins) => {
      return prevSelectedAdmins.includes(uid)
        ? prevSelectedAdmins.filter((adminUid) => adminUid !== uid)
        : [...prevSelectedAdmins, uid]
    })
  }, [])

  const onSubmit = async () => {
    const postData = {
      postType: post.type,
      title: post.title,
      postId: post.post_id,
      taskRefId: post.ref_id,
      users: post.users,
      createdAtTs: post.created_at_ts,
      isSearchPage: false,
      groupsToPayload: post.groups,
      isChat: true,
      admins: selectedAdmins,
      images: post.images,
    }

    try {
      const res = await dispatch(handleUpdatePost(postData))
      if (res) {
        onClose()
      }
    } catch (e) {
      console.log('Failed to set admins:', e)
    }
  }

  return (
    <>
      <BaseModal
        display={isOpenConfirmation ? 'none' : 'block'}
        isOpen={isOpen}
        isShowAnimation
        onClose={onClose}
        headerContent={
          <Box pb="15px">
            <Text py="15px">{t_set_admins}</Text>
            <InputSearch searchValue={searchQuery} setSearchValue={setSearchQuery} placeholder={searchPlaceholder} />
          </Box>
        }
        bodyContent={
          <Box px="6">
            <Divider />
            <SearchResults
              searchQuery={searchQuery}
              searchResults={searchResults}
              potentialAdmins={sortedPotentialAdmins}
              handleSelectAdmins={handleSelectAdmins}
              theme={theme!}
              selectedAdmins={selectedAdmins}
            />
          </Box>
        }
        footerContent={
          <Flex justifyContent="center" background="white" px="6" py="15px">
            {searchQuery && !searchResults.length ? null : (
              <Button
                borderRadius="20px"
                onClick={onOpenConfirmation}
                color="white"
                textTransform="uppercase"
                background={theme?.elementsColor}
                _hover={{ backgroundColor: 'none' }}
              >
                <LocaleText text="select_group_select_button" />
              </Button>
            )}
          </Flex>
        }
      />

      {isOpenConfirmation && (
        <BaseModal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          isShowAnimation
          onClose={onClose}
          borderRadius="20px"
          bodyContent={
            <ConfirmActionContent onClose={onCloseConfirmation} handleAction={onSubmit} literal="Are you sure" />
          }
        />
      )}
    </>
  )
}
