// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, Heading } from '@chakra-ui/core'
import useLocaleText from './useLocaleText'
import { useDispatch, useSelector } from 'react-redux'
import { logoutHandler } from 'redux/actions/auth'
import { RootState } from 'constants/interfaces'
import { ReactComponent as CloseIcon } from 'assets/icon-input-clear.svg'

interface IProps {
  firstStageTime: number
  secondStageTime: number
  intervalTime: number
}

export const Inactions = ({ firstStageTime, secondStageTime, intervalTime }: IProps) => {
  let Interval: any = useRef<any>()
  const inActiveMessage = useLocaleText('session_not_used_pop_up_message')
  const sessionIsOutMessage = useLocaleText('session_is_out')
  const dispatch = useDispatch()
  const [inActiveTime, setInActiveTime] = useState<number>(0)
  const [showFirstMessage, setShowFirstMessage] = useState<boolean>(false)
  const [showSecondMessage, setShowSecondMessage] = useState<boolean>(false)
  const { isSessionClosed } = useSelector((state: RootState) => state.auth)
  const showNotification = showFirstMessage || showSecondMessage || isSessionClosed
  const { retailConfig } = useSelector((state: RootState) => state.config)
  const { isLoggedIn } = useSelector((state: RootState) => state.auth)

  const resetInterval = () => {
    setInActiveTime(0)
    setShowFirstMessage(false)
    setShowSecondMessage(false)
  }

  const disableTimer = () => {
    clearInterval(Interval.current)
    document.removeEventListener('mousemove', resetInterval)
    document.removeEventListener('keypress', resetInterval)
  }

  useEffect(() => {
    if (isLoggedIn && retailConfig?.local_authentication) {
      document.addEventListener('mousemove', resetInterval)
      document.addEventListener('keypress', resetInterval)
      Interval.current = setInterval(() => {
        setInActiveTime((prevState) => (prevState += intervalTime))
      }, intervalTime)
    } else {
      disableTimer()
    }
    return () => {
      clearInterval(Interval.current)
      document.removeEventListener('mousemove', resetInterval)
      document.removeEventListener('keypress', resetInterval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailConfig, isLoggedIn])

  useEffect(() => {
    if (inActiveTime >= firstStageTime && inActiveTime < secondStageTime) {
      setShowFirstMessage(true)
    }

    if (inActiveTime >= secondStageTime) {
      setShowFirstMessage(false)
      setShowSecondMessage(true)
      closeSession()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveTime])

  const closeSession = () => {
    dispatch(logoutHandler())
  }

  return (
    <Flex
      position="absolute"
      background="#63B3ED"
      width="40%"
      height="50px"
      bottom="5px"
      left="50%"
      transform="translateX(-50%)"
      borderRadius={2}
      justifyContent="center"
      alignItems="center"
      display={showNotification ? 'flex' : 'none'}
    >
      {inActiveTime >= firstStageTime && inActiveTime < secondStageTime && (
        <Heading size="md">{inActiveMessage}</Heading>
      )}
      {(inActiveTime >= secondStageTime || isSessionClosed) && (
        <>
          <Heading size="md">{sessionIsOutMessage}</Heading>
          <Button variant="ghost" onClick={resetInterval} position="absolute" right="5px">
            <CloseIcon />
          </Button>
        </>
      )}
    </Flex>
  )
}
