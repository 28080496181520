import BaseModal from 'components/CommonComponents/BaseModal'
import React from 'react'
import { IPop } from 'constants/interfaces'
import { Box, Flex, Image } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import PopAvatar from './PopAvatar'
import PopSubmitBtn from './PopSubmitBtn'
import PopBody from './PopBody'

interface IProps {
  isOpen: boolean
  onClose: () => void
  firstPopInStack: IPop
  handleSubmit: (popType: number | undefined) => void
}
export const DefaultPop = ({ isOpen, onClose, firstPopInStack, handleSubmit }: IProps) => {
  const ack_btn_text = useLocaleText(firstPopInStack?.ack_btn_text)
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      marginTop="7%"
      borderRadius="30px"
      closeOnEsc={false}
      maxWidth="29rem"
      overflowY="unset"
      hideCloseButton={true}
      isShowAnimation={true}
      headerContent={
        <Flex minH="100px">
          <PopAvatar {...firstPopInStack} />
          {firstPopInStack?.pop_icon_url && (
            <Box
              width="50px"
              height="50px"
              pos="absolute"
              borderRadius="120px"
              left="30px"
              top="45px"
              background={colors.greyLight3}
            >
              <Image src={firstPopInStack.pop_icon_url} width="40px" height="30px" m="9px auto" />
            </Box>
          )}
        </Flex>
      }
      bodyContent={<PopBody {...firstPopInStack} />}
      footerContent={
        <Flex w="100%" justifyContent="center" alignItems="center" flexDir="column">
          <PopSubmitBtn
            onClose={handleSubmit}
            btnText={ack_btn_text}
            pop_initiator={firstPopInStack.pop_initiator}
            popType={firstPopInStack.pop_type}
          />
        </Flex>
      }
    />
  )
}
