import { IRankingState } from 'constants/interfaces'
import { actionTypes } from '../actions/ranking'

export const initialState: IRankingState = {
  isRankingLoading: false,
  rankingReports: {},
  rankingSortMode: 1,
  rankingFilterMode: 0,
  isRankingBlocked: false,
  rankingFilterList: [],
  kpisList: [],
  kpisMediaData: {},
  kpiListHeaderView: [],
  kpiListDropDownView: [],
  kpisDates: '',
  isEmptyData: false,
  wasDynamicKpiDateUpdated: false,
}

export const rankingReducer = (state = initialState, action: any): IRankingState => {
  switch (action.type) {
    case actionTypes.SET_WAS_DYNAMIC_KPI_DATE_UPDATED:
      return {
        ...state,
        wasDynamicKpiDateUpdated: action.payload,
      }
    case actionTypes.SET_IS_EMPTY_DATA:
      return {
        ...state,
        isEmptyData: action.payload,
      }
    case actionTypes.SET_KPIS_DATES:
      return {
        ...state,
        kpisDates: action.payload,
      }
    case actionTypes.SET_KPIS_LIST_HEADER_VIEW:
      return {
        ...state,
        kpiListHeaderView: action.payload,
      }
    case actionTypes.SET_KPIS_LIST_DROPDOWN_VIEW:
      return {
        ...state,
        kpiListDropDownView: action.payload,
      }
    case actionTypes.SET_KPIS_MEDIA_DATA:
      return {
        ...state,
        kpisMediaData: { ...state.kpisMediaData, ...action.payload },
      }
    case actionTypes.SET_KPIS_LIST:
      return {
        ...state,
        kpisList: action.payload,
      }
    case actionTypes.SET_RANKING_FILTER_LIST:
      return {
        ...state,
        rankingFilterList: action.payload,
      }
    case actionTypes.SET_RANKING_REPORTS:
      return {
        ...state,
        rankingReports: action.payload,
      }
    case actionTypes.SET_RANKING_FILTER:
      return {
        ...state,
        rankingFilterMode: action.payload,
      }
    case actionTypes.SET_RANKING_SORT:
      return {
        ...state,
        rankingSortMode: action.payload,
        isRankingBlocked: false,
      }
    case actionTypes.SET_IS_REPORT_BLOCKED:
      return {
        ...state,
        isRankingBlocked: action.payload,
      }
    case actionTypes.SET_IS_RANKING_LOADING:
      return {
        ...state,
        isRankingLoading: action.payload,
      }
    default:
      return state
  }
}
