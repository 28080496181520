import { IconButton } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { generatePassword } from 'utils'

export type IResetFieldButton = {
  handleResetField?: (field: string) => void
  field: string
}

export const ResetFieldButton = ({ handleResetField, field }: IResetFieldButton) => {
  return (
    <IconButton
      aria-label="clear"
      variant="ghost"
      icon="delete"
      size="sm"
      m="4px"
      color="lightgray"
      borderRadius="full"
      _focus={{ boxShadow: 'none' }}
      _hover={{ background: 'none', color: 'dimgray' }}
      _active={{ background: 'none' }}
      onClick={() => handleResetField?.(field)}
    />
  )
}
interface IGeneratePasswordButton {
  handleSetValue?: (field: string, val: string | number) => void
  field: string
}

export const GeneratePasswordButton = ({ handleSetValue, field }: IGeneratePasswordButton) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  const handleClick = () => {
    const generatedPassword = generatePassword()
    handleSetValue?.(field, generatedPassword)
  }
  return (
    <IconButton
      aria-label="clear"
      variant="ghost"
      icon="repeat"
      size="md"
      m="4px"
      color={theme?.backgroundMedium}
      borderRadius="full"
      _focus={{ boxShadow: 'none' }}
      _hover={{ background: 'none', color: theme?.elementsColor }}
      _active={{ background: 'none' }}
      onClick={() => handleClick()}
    />
  )
}
