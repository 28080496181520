import React, { useEffect, useState } from 'react'
import { Range, RangeFocus, RangeKeyDict } from 'react-date-range'
import { Button, Heading, Popover, PopoverTrigger, useDisclosure } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { format, addDays, subDays } from 'date-fns'
import LocaleText from 'components/LocaleText'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRangeContent } from './DateRangeContent'
import { getDateFromKpi } from 'utils'

interface IProps {
  onSelectDate: (fromDate: Date, toDate: Date) => void
  onResetFilter: VoidFunction
  maxRange?: number
  openPopoverTriggerLiteral: string
  bgOfTriggerPopover?: string
  selectButtonLiteral?: string
  resetButtonLiteral?: string
  open?: boolean
  close?: () => void
  style?: any
  minDate?: Date
  maxDate?: Date
  initialValues?: string[]
}

export const DateRangePopover = ({
  open,
  close,
  style,
  onSelectDate,
  onResetFilter,
  maxRange,
  bgOfTriggerPopover,
  openPopoverTriggerLiteral,
  resetButtonLiteral = 'archive_current_tasks',
  selectButtonLiteral = 'archive_select_date',
  maxDate,
  minDate,
  initialValues,
}: IProps) => {
  const { isOpen, onClose, onToggle } = useDisclosure()
  const [isDefaultValueChanged, setIsDefaultValueChanged] = useState(false)
  const [dateRange, setDateRange] = useState<string>('')
  const [maxDateRange, setMaxDateRange] = useState<undefined | Date>(undefined)
  const [minDateRange, setMinDateRange] = useState<undefined | Date>(undefined)
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const [state, setState] = useState<Range[]>([
    {
      startDate: minDate ? minDate : new Date(),

      endDate: maxDate ? maxDate : new Date(),
      key: 'selection',
    },
  ])

  useEffect(() => {
    if (initialValues && initialValues?.length > 0) {
      setState(() => [
        { ...state[0], startDate: getDateFromKpi(initialValues[0]), endDate: getDateFromKpi(initialValues[1]) },
      ])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  const formatDate = (date: Date) => format(date, 'd LLL yyyy')

  const handleFilter = () => {
    onSelectDate(state[0].startDate!, state[0].endDate!)
    setDateRange(`${formatDate(state[0].startDate!)} - ${formatDate(state[0].endDate!)}`)
    onClose()
    close?.()
  }

  const resetRange = () => {
    setMinDateRange(() => undefined)
    setMaxDateRange(() => undefined)
  }

  const resetRangeState = () => {
    setDateRange('')
    setState(() => [
      { ...state[0], startDate: minDate ? minDate : new Date(), endDate: maxDate ? maxDate : new Date() },
    ])
    resetRange()
    setIsDefaultValueChanged(false)
    onClose()
    close?.()
  }

  const resetFilter = () => {
    onResetFilter()
    resetRangeState()
  }

  const rangeFocusChangeHandler = (focusedDates: RangeFocus) => {
    if (!focusedDates[0] && focusedDates[1]) {
      setState((prevState: Range[]) => [{ ...prevState[0], endDate: prevState[0].startDate }])
    }
    if (!focusedDates[0] && !focusedDates[1]) {
      resetRange()
    }
  }

  const changeDateHandler = (value: RangeKeyDict) => {
    setState(() => [value.selection])
    setIsDefaultValueChanged(true)
  }

  useEffect(() => {
    resetRangeState()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeGroupID])

  useEffect(() => {
    if (isDefaultValueChanged && maxRange) {
      setMaxDateRange(() => addDays(state[0].startDate!, maxRange))
      setMinDateRange(() => subDays(state[0].startDate!, maxRange))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state[0].startDate, isDefaultValueChanged])

  const popoverOpen = isOpen || open

  useEffect(() => {
    function clickOutside(e: any) {
      if (!e.target.closest('[role=dialog]')) {
        close?.()
        onClose()
      }
    }

    if (popoverOpen) {
      document.addEventListener('click', clickOutside)
    } else {
      document.removeEventListener('click', clickOutside)
    }

    return () => document.removeEventListener('click', clickOutside)
  }, [popoverOpen, close, onClose])

  return (
    <Popover closeOnBlur={true} isOpen={popoverOpen}>
      {open === undefined && (
        <PopoverTrigger>
          <Button
            maxWidth="220px"
            onClick={onToggle}
            dir="ltr"
            background={bgOfTriggerPopover}
            _focus={{ outline: 'none' }}
          >
            <Heading as="p" fontSize="16px">
              {dateRange || <LocaleText text={openPopoverTriggerLiteral} />}
            </Heading>
          </Button>
        </PopoverTrigger>
      )}
      <DateRangeContent
        style={style}
        onChange={changeDateHandler}
        ranges={state}
        className="byDateSelect"
        rangeFocusChange={rangeFocusChangeHandler}
        handleFilter={handleFilter}
        selectButtonLiteral={selectButtonLiteral}
        maxDateRange={maxDate ? maxDate : maxDateRange}
        minDateRange={minDate ? minDate : minDateRange}
        resetButtonLiteral={resetButtonLiteral}
        resetFilter={resetFilter}
      />
    </Popover>
  )
}
