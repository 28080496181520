import React from 'react'
import { ValueType } from 'react-select'

import LocaleText from 'components/LocaleText'
import { SelectOption } from './SelectOption'
import { SelectControl } from './SelectControl'
import { BaseSelect } from './BaseSelect'
import { SelectMenuList } from './SelectMenuList'

interface IOption {
  id: string
  name: string
  isSelected: boolean
}

interface IProps {
  options: IOption[]
  value?: IOption
  onChange: (data: ValueType<IOption, false>) => void
  onDeleteSelection: VoidFunction
}

export const MediaSelect = ({ options, value, onChange, onDeleteSelection }: IProps) => {
  return (
    <BaseSelect
      options={options}
      onChange={onChange}
      value={value}
      components={{
        Option: SelectOption,
        Control: (props) => (
          <SelectControl {...props}>
            <LocaleText text="search_feed_attachments_filter" />
          </SelectControl>
        ),
        MenuList: ({ innerRef, children, ...props }) => (
          <SelectMenuList ref={innerRef} onDeleteSelection={onDeleteSelection} {...props}>
            {children}
          </SelectMenuList>
        ),
      }}
    />
  )
}
