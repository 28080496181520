import { IComment } from 'constants/interfaces'

export const useUniqueMessages = (items: IComment[] = []): IComment[] => {
  return items.reduceRight(
    (acc: { messages: IComment[]; uniqueIds: Set<string> }, item: IComment) => {
      if (!acc.uniqueIds.has(item.cid)) {
        acc.uniqueIds.add(item.cid)
        acc.messages.push(item)
      }
      return acc
    },
    { messages: [], uniqueIds: new Set<string>() }
  ).messages
}
