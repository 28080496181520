import React, { memo, useMemo } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { generateIcons } from './utils'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  position?: any
  audios?: string[]
  images?: string[]
  videos?: string[]
  files?: string[]
  margin?: string
  w?: string
  h?: string
  containerWidth?: string
}
export const IconList = memo(
  ({
    audios,
    images,
    videos,
    files,
    position = 'relative',
    margin = '0',
    w = '17px',
    h = '17px',
    containerWidth = ' 35%',
  }: IProps) => {
    const { cdn } = useSelector((state: RootState) => state.config.config)
    const isRtl = useSelector(isRtlSelector)
    const mediaTypes = [
      { type: audios, iconPath: 'audio.svg', text: 'Audio' },
      { type: images, iconPath: 'image.svg', text: 'Image' },
      { type: videos, iconPath: 'video.svg', text: 'Video' },
      { type: files, iconPath: 'link.svg', text: 'Link' },
    ]
    const mediaIcons = useMemo(() => generateIcons(mediaTypes, cdn, w, h), [mediaTypes, cdn, w, h])
    return (
      <Flex
        style={{ columnGap: '10px' }}
        cursor="pointer"
        justifyContent="center"
        w={containerWidth}
        marginLeft={!isRtl ? margin : '0'}
        marginRight={isRtl ? margin : '0'}
        pos={position ? position : 'relative'}
        right={position === 'absolute' ? '10%' : 'unset'}
        top={position === 'absolute' ? '30px' : 'unset'}
      >
        {mediaIcons.map((icon, i) => (
          <Box key={i}>{icon.icon}</Box>
        ))}
      </Flex>
    )
  }
)
