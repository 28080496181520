import React, { useEffect, useRef } from 'react'
import Select, { createFilter } from 'react-select'
import { Config } from 'react-select/src/filters'
import { SelectComponents } from 'react-select/src/components'
import StateManager from 'react-select'
import { IOption } from './SelectOption'

interface IProps {
  filterConfig?: Config | null
  options: IOption[]
  value?: IOption
  onChange: (value: any) => void
  components?: Partial<SelectComponents<IOption, false>>
  register?: any
  field: string
  requiredPlaceholder?: string
  inInvalid?: boolean
}

const customStyles = {
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    minWidth: 'max-content',
    width: '100%',
    maxWidth: '265px',
    background: '#fff',
    borderRadius: '10px',
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    width: '200px',
  }),
  menuPortal: (base: React.CSSProperties) => ({
    ...base,
    zIndex: 9999,
  }),
}

export const SelectMain = ({
  filterConfig = null,
  options,
  value,
  onChange,
  components,
  field,
  register,
  requiredPlaceholder,
}: IProps) => {
  const refSelect = useRef<StateManager<IOption, false>>(null)

  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (
        // @ts-ignore
        !refSelect.current?.select?.menuListRef?.contains(event.target) &&
        // @ts-ignore
        !refSelect?.current?.select?.controlRef?.contains(event.target)
      ) {
        refSelect?.current?.onMenuClose()
      }
    })
  }, [])

  return (
    <>
      <Select
        ref={refSelect}
        defaultValue={value}
        styles={customStyles}
        options={options}
        components={components}
        onChange={onChange}
        value={value}
        filterOption={createFilter(filterConfig)}
        menuPosition="absolute"
        menuPlacement="bottom"
        menuPortalTarget={document.body}
      />
      {register && (
        <input
          type="hidden"
          id={field}
          name={field}
          ref={register({
            required: field !== 'default_group' && requiredPlaceholder ? 'admin_reqiured_field' : false,
          })}
        />
      )}
    </>
  )
}
