import { splitString } from 'components/NewsFeed/PostModal/utils'
import { AppDispatch } from 'config/redux'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { handleGetSinglePost } from 'redux/actions/feed'

export const useLinkedChatPost = ({ ref_id }: { ref_id?: string | null }) => {
  const dispatch: AppDispatch = useDispatch()
  const navigate = useNavigate()
  const { chats, prefetchedChats } = useSelector((state: RootState) => state.feed)
  const [isPrefetching, setIsPrefetching] = useState(false)

  const { splittedRefId, chatId, isValidChatRefId } = useMemo(() => {
    const splitted = ref_id ? splitString(ref_id) : null
    return {
      splittedRefId: splitted,
      chatId: splitted?.value,
      isValidChatRefId: splitted && splitted.key === 'posts',
    }
  }, [ref_id])

  const linkedChatExistedInChats = useMemo(() => {
    return isValidChatRefId ? chats.find((chat) => chat.post_id === chatId) : null
  }, [isValidChatRefId, chatId, chats])

  const linkedChatExistedInPrefetchedChats = useMemo(() => {
    return isValidChatRefId ? prefetchedChats.find((chat) => chat.post_id === chatId) : null
  }, [isValidChatRefId, chatId, prefetchedChats])

  let linkedChat = linkedChatExistedInChats || linkedChatExistedInPrefetchedChats

  const getPrefetchedChat = useCallback(async () => {
    if (!splittedRefId || !isValidChatRefId) return null
    setIsPrefetching(true)
    const post = await dispatch(handleGetSinglePost({ postId: splittedRefId.value, type: keys.POST_TYPE.GROUP_POST }))
    setIsPrefetching(false)
    return post
  }, [dispatch, isValidChatRefId, splittedRefId])

  const onChatClick = useCallback(async () => {
    if (!isValidChatRefId || !splittedRefId) return
    const isPrefetched = !linkedChatExistedInChats && !!linkedChatExistedInPrefetchedChats
    if (isPrefetched) {
      navigate(`${keys.ROUTE_NAMES.GROUP_CHAT(chatId)}?prefetched=true`)
      return
    } else {
      navigate(`${keys.ROUTE_NAMES.GROUP_CHAT(chatId)}`)
      return
    }
  }, [chatId, isValidChatRefId, linkedChatExistedInChats, linkedChatExistedInPrefetchedChats, navigate, splittedRefId])

  useEffect(() => {
    if (isValidChatRefId && !linkedChatExistedInChats && !linkedChatExistedInPrefetchedChats) {
      getPrefetchedChat()
    }
  }, [getPrefetchedChat, isValidChatRefId, linkedChatExistedInChats, linkedChatExistedInPrefetchedChats])

  return {
    onChatClick,
    linkedChat,
    isValidChatRefId,
    chatId,
    isPrefetching,
    linkedChatExistedInChats,
    linkedChatExistedInPrefetchedChats,
    getPrefetchedChat,
  }
}
