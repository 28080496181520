import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../constants/interfaces'

interface LocaleTextProps {
  text: string
}

const LocaleText = ({ text }: LocaleTextProps) => {
  const locale = useSelector((state: RootState) => state.general.locale)
  const translations = useSelector((state: RootState) => state.config.translations)
  return <>{translations[locale!] && translations[locale!][text] ? translations[locale!][text] : text}</>
}


export default LocaleText
