import createLinkifyPlugin, { extractLinks } from '@draft-js-plugins/linkify'
import { regExLinkRecognizer } from 'constants/baseValues'
import { ContentState, EditorState } from 'draft-js'
import React, { useMemo, useState } from 'react'
import editorStyles from './editorStyles.module.css'
import 'draft-js/dist/Draft.css'
import Editor from '@draft-js-plugins/editor'

interface IStoreeEditor {
  editorValue: string
  setEditorValue: (value: string) => void
  placeholder: string
}

export const StoreeEditor: React.FC<IStoreeEditor> = ({ editorValue, setEditorValue, placeholder }) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(editorValue ?? ''))
  )

  const linkifyPlugin = useMemo(
    () =>
      createLinkifyPlugin({
        rel: '',
        target: 'blank',
        customExtractLinks: (text) => {
          return extractLinks(text)?.filter((el) => el.raw.match(regExLinkRecognizer)) || null
        },
      }),
    []
  )

  return (
    <div className={editorStyles.editor}>
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        plugins={[linkifyPlugin]}
        onChange={(newState) => {
          setEditorState(newState)
          setEditorValue(newState.getCurrentContent().getPlainText())
        }}
      />
    </div>
  )
}
