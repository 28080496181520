import { Avatar, Stack } from '@chakra-ui/core'
import { getAvatarSrc } from 'components/Tasks/utils'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'

type Size = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

interface IProps {
  participants: {
    id: string
    date: string
  }[]
  size?: Size
}
export const VotedParticipantsAvatars = ({ participants, size = 'xs' }: IProps) => {
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  return (
    <Stack position="relative" direction="row" spacing={1}>
      {participants.splice(0, 4).map(({ id }, index) => {
        const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
        return <Avatar zIndex={index} size={size} key={`id-${index}`} src={avatar} />
      })}
    </Stack>
  )
}
