import { Item } from './Control'

type handleIds = {
  checkedIds: string[]
  currentId: string
  resettingId?: string
  itemsDataArray?: Item[]
}

export const multipleIdsSelection = ({ checkedIds, currentId, resettingId, itemsDataArray }: handleIds) => {
  if (itemsDataArray && resettingId) {
    if (checkedIds.length === itemsDataArray.length) {
      if (currentId === resettingId) {
        return []
      }
      return checkedIds.filter((id) => id !== resettingId && id !== currentId)
    } else if (!checkedIds.includes(resettingId) && currentId === resettingId) {
      return itemsDataArray.map((item) => item.id)
    } else if (
      checkedIds.length === itemsDataArray.filter((item) => item.id !== resettingId).length - 1 &&
      currentId !== resettingId &&
      !checkedIds.includes(currentId)
    ) {
      return itemsDataArray.map((item) => item.id)
    }
  }

  return checkedIds.includes(currentId) ? checkedIds.filter((id) => id !== currentId) : [...checkedIds, currentId]
}
export const singleIdsSelection = ({ checkedIds, currentId }: handleIds) => {
  if (checkedIds.includes(currentId)) {
    return checkedIds.filter((id) => id !== currentId)
  } else {
    return [currentId]
  }
}
