import { Box } from '@chakra-ui/core'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import { RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleGroupChange } from 'redux/actions/config'
import { handleTasks } from 'redux/actions/tasks'

interface IProps {
  onClose: () => void
  isOpen: boolean
}

export const ChooseContext = ({ isOpen, onClose }: IProps) => {
  const dispatch = useDispatch()
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const [selectedGroupID, setSelectedGroupID] = useState<string[]>([activeGroupID!])
  const [showGroupList, setGroupList] = useState(isOpen)
  const { groups } = useSelector((state: RootState) => state.config.config)

  const handleMenuItem = useCallback(
    (groupID: string) => {
      if (selectedGroupID[0] !== activeGroupID) {
        dispatch(handleGroupChange(groupID))
        handleTasks(groupID)
        onClose!()

        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      } else if (showGroupList === false) {
        onClose()
      }
    },
    [activeGroupID, dispatch, onClose, showGroupList, selectedGroupID]
  )
  useEffect(() => {
    handleMenuItem(selectedGroupID[0])
  }, [activeGroupID, handleMenuItem, isOpen, selectedGroupID])

  return (
    <Box px="6">
      <GroupsTree
        isOpen={showGroupList}
        onClose={onClose}
        mode={strings.SINGLE_MODE_CONTEXT}
        setGroupList={setGroupList}
        selectedGroupID={selectedGroupID}
        setSelectedGroupID={setSelectedGroupID}
        treeGroups={groups}
      />
    </Box>
  )
}
