import React from 'react'
import { Button, useDisclosure } from '@chakra-ui/core'
import { BiPencil } from 'react-icons/bi'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { PostModal } from './PostModal'

export const ComposeButton = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <>
      <Button
        onClick={onOpen}
        h="50px"
        w="170px"
        color={theme?.elementsColor}
        borderRadius="12px"
        bg="white"
        boxShadow="0px 2px 2px -2px #616770;"
        fontSize="18px"
        leftIcon={BiPencil}
        dir="ltr"
        _active={{ bg: theme?.backgroundLight }}
        _hover={{ bg: theme?.backgroundLight }}
        _focus={{ boxShadow: 'none' }}
      >
        <LocaleText text="componse_post" />
      </Button>
      {isOpen && <PostModal isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
