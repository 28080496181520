import React, { memo, useCallback, useEffect, useState } from 'react'
import { Box, Collapse, Flex, Spinner, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import isSolid from 'is-solid'

import colors from 'constants/colors'
import useComments from 'hooks/useComments'
import { getCurrentEpoch, wasBrowserRefreshed } from 'utils'
import { Comment, CommentInput } from '.'
import LocaleText from 'components/LocaleText'
import { IComment, IHandleCreateComment } from 'constants/interfaces'
import keys from 'constants/keys'
import { useDispatch, useSelector } from 'react-redux'
import { userIdSelector } from 'redux/selectors/config'
import { setCommentsCounter } from 'redux/actions/comments'

const CommentsWrapper = styled(Box)``

interface IProps {
  postID: string
  postAuthorID: string
  rasID: string
  showCommentsSection: boolean
  isInputFocus: boolean
  updatedCommentsCounter: number
  isDoublePost?: boolean
  postType?: number
}

const PostCommentsContainer: React.FC<IProps> = ({
  postID,
  postAuthorID,
  rasID,
  showCommentsSection,
  isInputFocus,
  isDoublePost,
  postType,
}: IProps) => {
  const userId = useSelector(userIdSelector)
  const dispatch = useDispatch()
  const {
    fetchComments,
    sendComment,
    sendCommentLoading,
    fetchCommentsLoading,
    isLoadMore,
    fetchCommentsCounter,
    comments,
    commentsCounter,
  } = useComments(postID)
  const [showAllComments, setShowAllComments] = useState<boolean>(true)
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(2)
  const [isFocused, setFocused] = useState<boolean>(false)

  const getComments = useCallback(
    async (isManuallyTriggered?: boolean, id?: string) => {
      try {
        let timestampFromToGetComments = getCurrentEpoch()

        if (comments.length > 0) {
          timestampFromToGetComments = comments[0].created_at_ts
        }
        const commentsFromOrigin = await fetchComments({
          offset,
          limit,
          created_ts: timestampFromToGetComments,
          isManuallyTriggered,
          id,
        })
        if (!isSolid(commentsFromOrigin)) {
          return
        }

        setOffset(offset + limit)
        setLimit(5)
      } catch (error) {
        console.log(error)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [comments, limit, offset]
  )

  const handleGetMoreComments = useCallback(async () => {
    getComments(true, postID)
  }, [getComments, postID])

  const handleCreateComment = async ({ comment, images, videos, audios, file }: IHandleCreateComment) => {
    const isResponseOk = await sendComment({
      id: postID,
      comment,
      rasID,
      createdByUserId: postAuthorID,
      images,
      videos,
      audios,
      objectType: keys.COMMENT_OBJECT_TYPE.COMMNET_ON_POST,
      file,
      ...(postType === keys.POST_TYPE.GROUP_POST ? { is_update_post: true } : {}),
    })
    if (isResponseOk) dispatch(setCommentsCounter({ count: commentsCounter + 1, key: postID }))
  }

  useEffect(() => {
    if (comments.length > 0 || isDoublePost || !wasBrowserRefreshed()) return
    getComments()
    return () => {
      sessionStorage.removeItem('wasRefreshed')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postID])

  useEffect(() => {
    if (isDoublePost || !wasBrowserRefreshed()) return
    fetchCommentsCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postID])

  useEffect(() => {
    setFocused(isInputFocus)
  }, [isInputFocus])

  return (
    <Collapse className="post-comments-collapse" isOpen={showCommentsSection}>
      <CommentsWrapper className="post-comments-container">
        {comments.length > 0 && (
          <>
            {isLoadMore && (
              <Flex onClick={handleGetMoreComments} color={colors.greyDark} cursor="pointer" mb="5px">
                <Text>
                  <LocaleText text="fetch_more_comments_post_button" />
                </Text>{' '}
                {fetchCommentsLoading && <Spinner size="md" mx="20px" />}
              </Flex>
            )}

            {!isLoadMore && comments.length > 2 && (
              <Box
                onClick={() => setShowAllComments(!showAllComments)}
                color={colors.greyDark}
                cursor="pointer"
                mb="5px"
              >
                {showAllComments ? (
                  <Text>
                    <LocaleText text="hide_comments_post_button" />
                  </Text>
                ) : (
                  <Text>View all {comments.length} comments</Text>
                )}
              </Box>
            )}

            {isSolid(comments) && (
              <Collapse startingHeight={90} isOpen={showAllComments}>
                {comments.map((comment: IComment) => (
                  <Comment
                    key={comment.cid}
                    {...comment}
                    postID={postID}
                    authorID={postAuthorID}
                    rasID={rasID}
                    isDoublePost={isDoublePost}
                    postType={postType}
                  />
                ))}
              </Collapse>
            )}
          </>
        )}

        <CommentInput
          isFocused={isFocused}
          postID={postID}
          handleCreateComment={handleCreateComment}
          sendCommentLoading={sendCommentLoading}
        />
      </CommentsWrapper>
    </Collapse>
  )
}

export default memo(PostCommentsContainer)
