import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import avatarPlaceholder from 'assets/avatar-placeholder.svg'

const useAvatar = (id: string | undefined) => {
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const avatar = useMemo(() => {
    const userAvatar = id && retailUsersObject ? retailUsersObject?.[id]?.profile_img_url : null
    return userAvatar || avatarPlaceholder
  }, [id, retailUsersObject])
  return avatar
}

export default useAvatar
