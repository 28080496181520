// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react'
import { Button, Flex, Textarea, useToast, Text } from '@chakra-ui/core'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { RootState } from 'constants/interfaces'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, useNavigate } from 'react-router-dom'
import { resetPassword } from 'redux/actions/auth'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'

const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const userNameText = useLocaleText('login_username')
  const forgot_password_verification = useLocaleText('forgot_password_verification')
  const forgot_password = useLocaleText('forgot_password')
  const reset_password_title = useLocaleText('reset_password_title')
  const forgot_password_return_login_page = useLocaleText('forgot_password_return_login_page')
  const { register, handleSubmit } = useForm()
  const { isLoginLoading, isError, isLoggedIn, errorMessage } = useSelector((state: RootState) => state.auth)

  const submitConfirmUserName = (data: any) => {
    dispatch(resetPassword(data.username, navigate))
  }

  useEffect(() => {
    if (isError && errorMessage !== '') {
      toast({
        title: errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <LoginWrapper
      isChangingPassword={true}
      header={
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize="24px" marginBottom="20px">
            {forgot_password}
          </Text>
          <Text fontSize="16px">{forgot_password_verification}</Text>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit(submitConfirmUserName)} autoComplete="nope">
        <Textarea
          id="username"
          name="username"
          resize={'none'}
          borderRadius="20px"
          style={{ minHeight: '0px', height: '2.5rem' }}
          placeholder={userNameText}
          ref={register}
        />
        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          type="submit"
          isLoading={isLoginLoading}
        >
          {reset_password_title}
        </Button>
        <Button
          onClick={() => navigate(keys.ROUTE_NAMES.LOGIN_PAGE)}
          width="100%"
          mt={4}
          style={{ background: 'none', color: '#d53f8c', borderRadius: '20px' }}
        >
          {forgot_password_return_login_page}
        </Button>
      </form>
    </LoginWrapper>
  )
}
export default ForgotPassword
