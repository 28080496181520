/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React from 'react'
import {
  Box,
  Flex,
  Text,
  Spinner,
  Icon,
  ModalCloseButton,
  Button,
  Tooltip,
  CloseButtonProps,
  SkeletonProps,
  Skeleton,
} from '@chakra-ui/core'
import { css } from '@emotion/core'
import { GrRotateRight } from 'react-icons/gr'
import { BiExport } from 'react-icons/bi'

import { ReactComponent as ArrowIcon } from 'assets/filter-arrow.svg'
import { ReactComponent as FilterIcon } from 'assets/filter-icon.svg'
import { ReactComponent as CameraIcon } from 'assets/icon-photo-add.svg'
import colors from 'constants/colors'
import { useSelector } from 'react-redux'
import LocaleText from './LocaleText'
import { ICheckbox, IPosition, RootState } from 'constants/interfaces'
import { FilterItemWrapper, StyledModalClose } from './helperStyles'
import useLocaleText from './useLocaleText'

export const RotateButton = ({ onClick }: any) => (
  <Box onClick={onClick} mb="40px" color="white" textAlign="center" fontSize="13px" cursor="pointer">
    <Flex justifyContent="center">
      <GrRotateRight size="22px" color="white" />
    </Flex>
    <Text fontSize="11px" mt="5px">
      <LocaleText text="rotate" />
    </Text>
  </Box>
)

export const DeleteButton = ({ onClick, isLoading }: any) => (
  <Box
    onClick={onClick}
    mb="40px"
    color="white"
    textAlign="center"
    cursor="pointer"
    css={css`
      transition: all 350ms ease-in-out;
      &:hover {
        opacity: 0.8;
      }
    `}
  >
    {isLoading ? <Spinner color="white" size="lg" /> : <Icon name="delete" fontSize="25px" color="white" />}

    <Text fontSize="11px" mt="5px">
      <LocaleText text="delete" />
    </Text>
  </Box>
)

export const CameraButton = ({ onClick, isLoading }: any) => (
  <Box
    onClick={onClick}
    mb="40px"
    color="white"
    textAlign="center"
    cursor="pointer"
    css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      transition: all 350ms ease-in-out;
      &:hover {
        opacity: 0.8;
      }
    `}
  >
    {isLoading ? <Spinner color="white" size="lg" /> : <CameraIcon />}

    <Text fontSize="11px" mt="5px">
      <LocaleText text="add" />
    </Text>
  </Box>
)

export const AddFile = ({ isLoading }: any) =>
  isLoading ? (
    <Spinner color="grey" size="sm" />
  ) : (
    <Icon
      aria-label="Upload file"
      name="small-add"
      borderRadius="50%"
      cursor="pointer"
      color="white"
      size="18px"
      backgroundColor={colors.greenLight3}
    />
  )

export const CloseButton: React.FC<CloseButtonProps> = (props) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)
  return (
    <ModalCloseButton
      right={locale === 'he' ? 'auto' : '0'}
      left={locale === 'he' ? '0' : 'auto'}
      bg="white"
      borderRadius="50%"
      m="10px"
      outline="none"
      zIndex={1900}
      _focus={{ boxShadow: 'none' }}
      _hover={{ background: 'white', opacity: 0.8 }}
      {...props}
    />
  )
}

export const RequiredProductIcon = ({ top = '10px', bottom = null, left = '10px', right = '10px' }: IPosition) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)

  const product_is_required = useLocaleText('product_is_required')

  return (
    <Box
      position="absolute"
      top={top}
      left={locale === 'he' ? left : 'auto'}
      right={locale !== 'he' ? right : 'auto'}
      bottom={bottom}
      css={css`
        g {
          fill: ${colors.red} !important;
        }
      `}
    >
      <Tooltip aria-label={product_is_required} label={product_is_required} placement="bottom">
        <Text fontWeight="bold" fontSize="1.25rem" color={colors.red}>
          *
        </Text>
      </Tooltip>
    </Box>
  )
}

export const CustomSkeleton: React.FC<SkeletonProps> = (props) => {
  return <Skeleton colorStart="#f6f7f8" colorEnd="#edeef1" {...props} />
}
interface IDateText {
  children: React.ReactChild
}

export const DateText: React.FC<IDateText> = ({ children }: IDateText) => {
  return (
    <Text fontSize="14px" fontWeight="400" color={colors.greyDark}>
      {children}
    </Text>
  )
}

export const PageTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Text fontFamily="Asap" as="h1" fontSize="1.25rem" fontWeight="600" lineHeight="1.5rem" color={colors.blackLight}>
      <LocaleText text={title} />
    </Text>
  )
}

interface IFilterButtonProps {
  onClick: () => void
}

export const FilterButton: React.FC<IFilterButtonProps> = ({ onClick }) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)

  return (
    <Button
      mr={{
        base: locale === 'he' ? '3' : '0',
      }}
      ml={{
        base: locale === 'he' ? '0' : '3',
      }}
      px="6"
      py="3"
      borderRadius="7px"
      backgroundColor="#fff"
      border="1px solid rgba(170, 176, 188, 0.5)"
      onClick={onClick}
      _focus={{ outline: 'none' }}
    >
      <FilterIcon />
      <Text
        fontSize=".875rem"
        fontFamily="Asap, sans-serif"
        fontWeight="400"
        color={colors.blackLight}
        ml={locale === 'he' ? '0' : '3'}
        mr={locale === 'he' ? '3' : '0'}
      >
        <LocaleText text="filter_btn" />
      </Text>
    </Button>
  )
}
export const DownloadButton: React.FC<IFilterButtonProps> = ({ onClick }) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)
  const { isFetching, currentSubtasks } = useSelector((state: RootState) => state.tasksOverviewDetail)
  const dashboard_export_excel_button_loading = useLocaleText('dashboard_export_excel_button_loading')
  return (
    <Button
      mr={{
        base: locale === 'he' ? '3' : '0',
      }}
      ml={{
        base: locale === 'he' ? '0' : '3',
      }}
      px="6"
      py="3"
      borderRadius="7px"
      backgroundColor="#fff"
      border="1px solid rgba(170, 176, 188, 0.5)"
      onClick={onClick}
      // isDisabled={Object.keys(currentSubtasks).length === 0}
      isLoading={isFetching}
      loadingText={dashboard_export_excel_button_loading}
      _focus={{ outline: 'none' }}
    >
      <Icon as={BiExport} size="24px" color={colors.greyMain} />
      <Text
        fontSize=".875rem"
        fontFamily="Asap, sans-serif"
        fontWeight="400"
        color={colors.blackLight}
        ml={locale === 'he' ? '0' : '2'}
        mr={locale === 'he' ? '2' : '0'}
      >
        <LocaleText text="dashboard_export_excel_button" />
      </Text>
    </Button>
  )
}

interface IFilterItemProps<T> {
  title: string
  value: string[]
  onClick: () => void
}

export const FilterItem: React.FC<IFilterItemProps<ICheckbox | string>> = ({ title, value, onClick }) => {
  const locale = useSelector((state: RootState) => state?.general?.locale)
  return (
    <FilterItemWrapper
      justifyContent="space-between"
      alignItems="center"
      px="6"
      py="3"
      borderBottom={`1px solid ${colors.greyLight3}`}
      tabIndex={0}
      onClick={onClick}
    >
      <Text fontSize="1rem" color={colors.blackLight}>
        <LocaleText text={title} />{' '}
        {Array.isArray(value) ? (
          <>
            (
            {value.map((curValue: string | ICheckbox, index: number) => {
              if (typeof curValue !== 'string') {
                return (
                  <Box as="span" display="inline" key={curValue.title}>
                    <Text as="span" color={colors.blueLight}>
                      <LocaleText text={curValue.title} />
                    </Text>
                    {index + 1 !== value.length && ', '}
                  </Box>
                )
              }

              return (
                <Box as="span" display="inline" key={curValue}>
                  <Text as="span" color={colors.blueLight}>
                    <LocaleText text={curValue} />
                  </Text>
                  {index === 0 && index + 1 !== value.length && <>,&nbsp;</>}
                  {index !== 0 && index + 1 !== value.length && <>&nbsp;,</>}
                </Box>
              )
            })}
            )
          </>
        ) : (
          <Text as="span" color={colors.blueLight}>
            <LocaleText text={value || ''} />
          </Text>
        )}
      </Text>
      <Box transform={locale === 'he' ? 'rotate(180deg)' : ''}>
        <ArrowIcon />
      </Box>
    </FilterItemWrapper>
  )
}

interface IPropsCustomModalClose extends CloseButtonProps {}

export const CustomModalClose: React.FC<IPropsCustomModalClose> = (props) => {
  const { locale } = useSelector((state: RootState) => state.general)
  return (
    <>
      <StyledModalClose right={locale !== 'he' ? '10px' : 'auto'} left={locale === 'he' ? '10px' : 'auto'} {...props} />
    </>
  )
}
