export const dummyStores = {
  north: [
    'store 1',
    'store 2',
    'store 3',
    'store 4',
    'store 5',
    'store 6',
    'store 7',
    'store 8',
    'store 9',
    'store 10',
    'store 11',
    'store 12',
  ],
  south: [
    'store 1',
    'store 2',
    'store 3',
    'store 4',
    'store 5',
    'store 6',
    'store 7',
    'store 8',
    'store 9',
    'store 10',
  ],
  center: [
    'store 1',
    'store 2',
    'store 3',
    'store 4',
    'store 5',
    'store 6',
    'store 7',
    'store 8',
    'store 9',
    'store 10',
    'store 11',
    'store 12',
    'store 13',
    'store 14',
  ],
  west: [
    'store 1',
    'store 2',
    'store 3',
    'store 4',
    'store 5',
    'store 6',
    'store 7',
    'store 8',
    'store 9',
    'store 10',
    'store 11',
    'store 12',
  ],
  moon: ['store 1', 'store 2', 'store 3'],
  mars: ['store 1', 'store 2', 'store 3', 'store 4', 'store 5', 'store 6', 'store 7', 'store 8'],
  jupiter: ['store 1', 'store 2', 'store 3'],
}

export const dummyTaskOverview = [
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd48-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300e-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "8be8a3d6-29be-11eb-8969-3e03eb883a24",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee09-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a2-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3dc-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd4c-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7e57fc86-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd4a-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b9-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a6-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300e-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b7-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a6-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b8-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee0c-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b6-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b8-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d264-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3dc-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a2-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a9-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a7-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d262-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5be-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bb-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a3-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b3-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032aa-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "8c3bd62e-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b2-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b3-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b6-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "78484a5d-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee06-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee05-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd4a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a8-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b9-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7e57fc86-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a4-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e2-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b7-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b7-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bc-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d266-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e3-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee0c-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "87bff544-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e1-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d267-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e1-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 1
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee09-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 1
    },
    {
      retail_name: "carolina_lemke",
      group_id: "93d0afcc-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "78484a5a-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b6-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "87bff544-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a5-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b8-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a8-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300d-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bc-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee05-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bd-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b1-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b2-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d262-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bb-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd48-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032aa-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b7-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a5-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a4-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "78484a5a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300d-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d260-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d266-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e2-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee08-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee06-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd4c-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300b-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "78484a5d-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "710df3e3-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a9-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "75e4bd46-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "93d0afcc-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5bd-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d263-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "9933ae56-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "8c3bd62e-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b4-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5be-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee08-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300b-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d264-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d267-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "9933ae56-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b4-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a3-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "74a606b1-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d260-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "737032a7-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee07-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "76f9ee07-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "8be8a3d6-29be-11eb-8969-3e03eb883a24",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7aa4300a-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 2
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b8-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7245d263-28b4-11eb-a52b-0c96e6407585",
      tid: "2681e8f5-5af1-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b18-5aee-11eb-80f1-0673d75d92d5",
      title: "בסטים שמש",
      view: 0,
      status: 0
    },
    {
      retail_name: "carolina_lemke",
      group_id: "7973b5b6-28b4-11eb-a52b-0c96e6407585",
      tid: "1e6d40af-5aef-11eb-80f1-0673d75d92d5",
      ref_id: "6c6c5b19-5aee-11eb-80f1-0673d75d92d5",
      title: "מוצרים שחשוב להתמקד בהם",
      view: 0,
      status: 0
    }
]
