import React from 'react'
import { BoxProps, Text } from '@chakra-ui/core'

interface TitleProps extends BoxProps {
  title: string
}

export const Title: React.FC<TitleProps> = ({ title, ...props }) => {
  return (
    <Text fontWeight="800" {...props}>
      {title}
    </Text>
  )
}
