import React from 'react'
import { Box, Flex } from '@chakra-ui/core'
import { AiOutlinePlayCircle } from 'react-icons/all'

interface IProps {
  src: string
}

export const FbGridVideoPreview = ({ src }: IProps) => (
  <Flex position="relative" justifyContent="center" width="100%" height="100%">
    <Box position="absolute" zIndex={2} top="50%" transform="translateY(-50%)">
      <AiOutlinePlayCircle size="50px" fill="#fff" />
    </Box>
    <video src={src} className="fb-video" />
  </Flex>
)
