import React from 'react'
import { Skeleton } from '@chakra-ui/core'

import colors from 'constants/colors'

interface IProps {
  children?: React.ReactNode
  isLoaded?: boolean
  height?: string
  width?: string
  colorStart?: string
  colorEnd?: string
  borderRadius?: string
  mr?: string
  maxW?: string
  mb?: string
}

export const SkeletonText = ({
  children,
  isLoaded,
  height = '15px',
  width = '100%',
  colorStart,
  colorEnd,
  borderRadius = '10px',
  maxW,
  mr,
  mb,
}: IProps) => (
  <Skeleton
    isLoaded={isLoaded}
    height={height}
    width={width}
    mr={mr}
    mb={mb}
    maxW={maxW}
    borderRadius={borderRadius}
    colorStart={colorStart || colors.tealLight}
    colorEnd={colorEnd || colors.greyDark}
  >
    {children}
  </Skeleton>
)
