// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect } from 'react'
import { Box, Button, Flex, FormControl, Input, useToast } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { RootState } from 'constants/interfaces'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { handleConfirmCode, setCandidateToLogin } from 'redux/actions/auth'
import { useTimer } from 'react-timer-hook'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import { refactorPartOfTime } from 'utils'
import { isRtlSelector } from 'redux/selectors/general'

export const LoginConfirm = () => {
  const navigate = useNavigate()
  const timerTime = new Date(new Date().getTime() + 3 * 60000)
  const dispatch = useDispatch()
  const toast = useToast()
  const isRtl = useSelector(isRtlSelector)
  const { register, handleSubmit, setError, errors } = useForm()
  const { isLoginLoading, isError, candidateToLogin } = useSelector((state: RootState) => state.auth)

  const onExpire = () => {
    navigate(keys.ROUTE_NAMES.LOGIN_PAGE)
    dispatch(setCandidateToLogin(null))
  }

  const { minutes, seconds } = useTimer({ autoStart: true, expiryTimestamp: timerTime, onExpire })

  const submitConfirmCode = (data: any) => {
    if (!data.confirm_code) {
      return setError('confirm_code', {
        type: '',
        message: 'The code field should not be empty',
      })
    }
    dispatch(handleConfirmCode(data.confirm_code, navigate))
  }
  useEffect(() => {
    if (isError || errors?.confirm_code?.types?.message) {
      toast({
        title: errors?.confirm_code?.types?.message || 'The code is wrong',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [isError, errors, toast])

  return (
    <LoginWrapper
      isChangingPassword={true}
      header={
        <Flex flexDirection="column" alignItems={isRtl ? 'right' : 'left'}>
          <Box>
            <LocaleText text="mfa_message_1" />
          </Box>
          <Box>{candidateToLogin?.challengeParam?.CODE_DELIVERY_DESTINATION}</Box>
          <Box>
            <LocaleText text="mfa_message_2" />
          </Box>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit(submitConfirmCode)}>
        <FormControl>
          <Input
            id="confirm_code"
            name="confirm_code"
            borderRadius="20px"
            type="confirm_code"
            placeholder="confirm code"
            ref={register}
          />
        </FormControl>
        <Flex justifyContent="center" mt={4}>
          {refactorPartOfTime(minutes)}:{refactorPartOfTime(seconds)}
        </Flex>
        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          type="submit"
          isLoading={isLoginLoading}
        >
          <LocaleText text="login" />
        </Button>
      </form>
    </LoginWrapper>
  )
}
