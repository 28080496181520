import { ComponentsTypes } from 'components/Reports/enums'
import {
  getReportDataLength,
  getReportValues,
  isNumberOrNull,
  isString,
  isStringOrNull,
} from 'components/Reports/utils'
import { RootState } from 'constants/interfaces'
import { getFormattedStringValueWithSymbol } from 'hooks/useFormattedStringValue'
import { isNumber } from 'lodash'
import { useSelector } from 'react-redux'
import { ReportConfig } from 'redux/reducers/reports'

interface IReportResponse {
  reportConfig: ReportConfig | null
  reportData: any
}
interface IGetReportRequestString {
  subComponent: ReportConfig
  index?: number
}
const BLACK_LIST_REPORTS = ['retail_vs_store_income_percentage_by_segment']

export const useReportResponse = ({ reportConfig, reportData }: IReportResponse) => {
  const { locale } = useSelector((state: RootState) => state.general)
  const { translations } = useSelector((state: RootState) => state.config)
  if (!reportConfig || !reportData) return

  const lastUpdate = reportData.last_update ? translations[locale]['last_update'] + reportData.last_update : ''
  const title = reportData.title
  let initialSrting = `Report: ${title}\n${lastUpdate}\n\n`

  const getReportRequestString = ({
    subComponent,
    index = 0,
    reportRequestStr = '',
  }: IGetReportRequestString & { reportRequestStr?: string }): string => {
    const { type } = subComponent

    switch (type) {
      case ComponentsTypes.component:
        const { properties, sub_components: innerSubComponents } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        if (
          data_source &&
          (!reportData[data_source] || !reportData[data_source].length || BLACK_LIST_REPORTS.includes(data_source))
        )
          break

        Array.from({ length: reportDataLength }).forEach((_, reportIndex) => {
          innerSubComponents.forEach((innerSubComponent) => {
            reportRequestStr += getReportRequestString({
              subComponent: innerSubComponent,
              index: reportIndex,
            })
          })
        })
        if (data_source && reportData[data_source] && reportData[data_source].length) reportRequestStr += '\n'

        break

      case ComponentsTypes.rectangle: {
        const { properties, sub_components: innerSubComponents } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        if (data_source && (!reportData[data_source] || !reportData[data_source].length)) break

        Array.from({ length: reportDataLength }).forEach((_, reportIndex) => {
          innerSubComponents.forEach((innerSubComponent) => {
            reportRequestStr += getReportRequestString({
              subComponent: innerSubComponent,
              index: reportIndex,
            })
          })
        })

        reportRequestStr += ',' + '\n'
        break
      }

      case ComponentsTypes.title: {
        const { properties } = subComponent
        const isRepeatable = properties?.is_repeatable ?? false
        const dataSource = properties?.data_source

        const reportDataLength = getReportDataLength(isRepeatable, dataSource, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const { text_val: text } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (text) reportRequestStr += translations[locale][text] ? translations[locale][text] + '\n' : text + '\n'
        })
        break
      }
      case ComponentsTypes.sectionTitle: {
        const { properties } = subComponent
        const isRepeatable = properties?.is_repeatable ?? false
        const dataSource = properties?.data_source

        const reportDataLength = getReportDataLength(isRepeatable, dataSource, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const { text_val: text } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (text) reportRequestStr += translations[locale][text] ? translations[locale][text] + '\n' : text + '\n'
        })
        break
      }
      case ComponentsTypes.numericLine: {
        const { properties } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const { num_val, num_curr_symbol, num_symbol_type, num_is_round } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (!isNumber(num_val)) return null

          const formattedValue = getFormattedStringValueWithSymbol(
            num_val,
            num_curr_symbol,
            num_symbol_type,
            num_is_round,
            translations,
            locale
          )

          if (formattedValue) reportRequestStr += formattedValue + '\n'
        })
        break
      }
      case ComponentsTypes.comparisonLine: {
        const { properties } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const {
            text_val: text,
            num_val: numVal,
            num_curr_symbol: numValueCurrSymbol,
            num_symbol_type: numSymbolType,
            num_is_round: isNumRound,
          } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })
          if (
            !isString(text, 'text_val') ||
            !isNumber(numVal) ||
            !isStringOrNull(numValueCurrSymbol, 'num_curr_symbol') ||
            !isNumberOrNull(numSymbolType, 'num_symbol_type')
          ) {
            return null
          }
          const formattedNumValue = getFormattedStringValueWithSymbol(
            numVal,
            numValueCurrSymbol,
            numSymbolType,
            isNumRound,
            translations,
            locale
          )
          reportRequestStr += `${translations[locale][text]}: ${formattedNumValue}\n`
        })
        break
      }
      case ComponentsTypes.detailLine: {
        const { properties } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const {
            text_val: text,
            num_val: numVal,
            num_curr_symbol: numValueCurrSymbol,
            num_symbol_type: numSymbolType,
            num_is_round: isNumRound,
          } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (
            !isString(text, 'text_val') ||
            !isNumber(numVal) ||
            !isStringOrNull(numValueCurrSymbol, 'num_curr_symbol') ||
            !isNumberOrNull(numSymbolType, 'num_symbol_type')
          ) {
            return null
          }

          const formattedNumValue = getFormattedStringValueWithSymbol(
            numVal,
            numValueCurrSymbol,
            numSymbolType,
            isNumRound,
            translations,
            locale
          )

          reportRequestStr += `${translations[locale][text]}: ${formattedNumValue}\n`
        })

        break
      }
      case ComponentsTypes.productLine: {
        const { properties } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const {
            title,
            num_val_val: numVal,
            num_val_curr_symbol: numValueCurrSymbol,
            num_val_symbol_type: numSymbolType,
            num_val_is_round: isNumRound,
            num_val_qty_curr_symbol: numValueQtyCurrSymbol,
            num_val_qty_symbol_type: numValueQtySymbolType,
            num_val_qty_is_round: isNumValueQtyRound,
            num_val_qty_val: numValueQty,
          } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (
            !isNumber(numVal) ||
            !isStringOrNull(numValueCurrSymbol, 'num_val_curr_symbol') ||
            !isNumberOrNull(numSymbolType, 'num_val_symbol_type') ||
            !isStringOrNull(numValueQtyCurrSymbol, 'num_val_qty_curr_symbol') ||
            !isNumber(numValueQty) ||
            !isNumberOrNull(numValueQtySymbolType, 'num_val_qty_symbol_type')
          ) {
            return null
          }
          const text = translations[locale][title] ? translations[locale][title] + ':' : title + ':'
          const formattedQTYValue = getFormattedStringValueWithSymbol(
            numVal,
            numValueCurrSymbol,
            numSymbolType,
            isNumRound,
            translations,
            locale
          )
          const formattedNumValue = getFormattedStringValueWithSymbol(
            numValueQty,
            numValueQtyCurrSymbol,
            numValueQtySymbolType,
            isNumValueQtyRound,
            translations,
            locale
          )
          reportRequestStr += `${text}${formattedQTYValue}\n${formattedNumValue}\n`
        })
        break
      }
      case ComponentsTypes.salesDoubleBar: {
        const { properties } = subComponent
        const { is_repeatable, data_source } = properties
        const isRepeatable = is_repeatable ?? false
        const reportDataLength = getReportDataLength(isRepeatable, data_source, reportData)

        Array.from({ length: reportDataLength }).map((_, reportIndex: number) => {
          const {
            text1_val: globalTargetTitle,
            text2_val: idealPosistionlTitle,
            text3_val: gapTitle,
            num1_val: globalTargetValue,
            num2_val: idealPositionValue,
            num3_val: gapValue,
            num1_symbol_type: globalTargetSymbolType,
            num2_symbol_type: idealPositionSymbolType,
            num3_symbol_type: gapSymbolType,
            num1_is_round: isGlobalTargetValueRound,
            num2_is_round: isIdealPositionValueRound,
            num3_is_round: isGapValueRound,
            num1_curr_symbol: globalTargetCurrSymbol,
            num2_curr_symbol: idealPositionCurrSymbol,
            num3_curr_symbol: gapCurrSymbol,
          } = getReportValues({
            properties,
            reportData,
            index,
            isRepeatable,
            reportIndex,
          })

          if (
            !isStringOrNull(globalTargetTitle, 'text1_val') ||
            !isString(idealPosistionlTitle, 'text2_val') ||
            !isString(gapTitle, 'text3_val') ||
            !isNumberOrNull(globalTargetValue, 'num1_val') ||
            !isNumber(idealPositionValue) ||
            !isStringOrNull(globalTargetCurrSymbol, 'num1_curr_symbol') ||
            !isStringOrNull(idealPositionCurrSymbol, 'num2_curr_symbol') ||
            !isStringOrNull(gapCurrSymbol, 'num3_curr_symbol') ||
            !isNumberOrNull(globalTargetSymbolType, 'num1_symbol_type') ||
            !isNumberOrNull(idealPositionSymbolType, 'num2_symbol_type') ||
            !isNumberOrNull(gapSymbolType, 'num3_symbol_type')
          ) {
            return null
          }
          const globalTargetText = translations[locale][globalTargetTitle]
            ? translations[locale][globalTargetTitle]
            : globalTargetTitle

          const idealPositionText = translations[locale][idealPosistionlTitle]
            ? translations[locale][globalTargetTitle]
            : idealPosistionlTitle

          const gapText = translations[locale][gapTitle] ? translations[locale][gapTitle] : gapTitle

          const formattedGlobalTargetValue = getFormattedStringValueWithSymbol(
            globalTargetValue,
            globalTargetCurrSymbol,
            globalTargetSymbolType,
            isGlobalTargetValueRound,
            translations,
            locale
          )

          const formattedIdealPositionValue = getFormattedStringValueWithSymbol(
            idealPositionValue,
            idealPositionCurrSymbol,
            idealPositionSymbolType,
            isIdealPositionValueRound,
            translations,
            locale
          )

          const formattedGapValue = getFormattedStringValueWithSymbol(
            gapValue,
            gapCurrSymbol,
            gapSymbolType,
            isGapValueRound,
            translations,
            locale
          )

          if (formattedGlobalTargetValue !== null) {
            reportRequestStr += `${globalTargetText}: ${formattedGlobalTargetValue}\n`
          }

          if (formattedIdealPositionValue !== null) {
            reportRequestStr += `${idealPositionText}: ${formattedIdealPositionValue}\n`
          }

          if (formattedGapValue !== null) {
            reportRequestStr += `${gapText}: ${formattedGapValue}\n`
          }
        })

        break
      }

      case ComponentsTypes.globalReport:
      case ComponentsTypes.timeless:
      case ComponentsTypes.timeDependent: {
        const { sub_components: innerSubComponents } = subComponent

        if (!innerSubComponents.length) break
        return innerSubComponents.reduce((acc, subComponent) => {
          return getReportRequestString({ subComponent, reportRequestStr: acc })
        }, reportRequestStr)
      }

      default:
        break
    }
    return reportRequestStr
  }
  return reportConfig?.sub_components.reduce((acc, subComponent) => {
    return getReportRequestString({ subComponent, reportRequestStr: acc })
  }, initialSrting)
}
