import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { Button, Box, Flex, Text, Divider, IconButton } from '@chakra-ui/core'
import { BsArrowLeftShort } from 'react-icons/bs'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { IConfigGroup, RootState } from 'constants/interfaces'
import GroupsTreeNode from './GroupTreeNode'
import arrayToTree from 'array-to-tree'
import { SearchList } from './SearchList'
import {
  getParentsFromGroups,
  getTreeNodesIds,
  multipleModeSettingUI,
  usersMode,
  handleMuplipleIds,
  patrialMultipleModeSetting,
} from './utils'
import { ICurrentGroup, IRecurciveGroupsArray, IGroupsTree } from './interfaces'
import SelectAllButton from './SelectAllButton'
import InputSearch from './InputSearch'
import strings from 'constants/strings'
import { NoResults } from './NoResults'
import BaseModal from '../BaseModal'
import { findArrayDifference, trimAndCountExtraGroups } from 'utils'
import useLocaleText from 'components/useLocaleText'

const GroupsTree = ({
  setGroupList,
  setSelectedGroupID,
  selectedGroupID,
  setIntermediateCheckedIds,
  mode,
  isOpen,
  onClose,
  handleAddGroup,
  setViewOption,
  isAllowSubmitWhithoutGroups,
  setGroupsToPayload,
  groupsToPayload,
  disabledIds,
  handleChangeContext,
  treeGroups,
  isShowAnimation = false,
}: IGroupsTree) => {
  const { theme, locale } = useSelector((state: RootState) => state?.general)
  const [recursiveGroups, setRecursiveGroups] = useState<IRecurciveGroupsArray[]>()
  const [checkedIds, setCheckedIds] = useState<string[]>(selectedGroupID !== undefined ? selectedGroupID : [])
  const [groupElementId, setGroupElementId] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchResults, setSearchResults] = useState<ICurrentGroup[]>([])
  const [newCheckboxId, setNewCheckboxId] = useState<string | null>(null)
  const [intermidateGroupsToPayload, setIntermidateGroupsToPayload] = useState(groupsToPayload ? groupsToPayload : [])
  const isHebrew = locale === 'he'
  const isRtl = isHebrew ? 'rtl' : 'ltr'
  const search_placeholder = useLocaleText('select_group_search')
  const intermidateIds = useMemo(() => {
    const set = new Set<string>()
    for (const id of checkedIds) {
      getParentsFromGroups(id, treeGroups).forEach((parent) => set.add(parent.parent))
    }
    return Array.from(set)
  }, [checkedIds, treeGroups])

  const groupsObjectToArray = useMemo(
    () =>
      treeGroups !== null
        ? Object.entries(treeGroups)
            .map(([id, { name, parent }]) => ({
              id: id,
              title: name,
              path: getParentsFromGroups(id, treeGroups)
                .map((parent: IConfigGroup) => parent.name)
                .join(' → '),
              parentId: parent,
            }))
            .sort((a: any, b: any) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()))
        : [],
    [treeGroups]
  )

  const setInitialGroups = useCallback(
    (groups: object | null) => {
      if (groups) {
        setRecursiveGroups(
          arrayToTree(groupsObjectToArray, {
            parentProperty: 'parentId',
            customID: 'id',
          })
        )
      }
    },
    [groupsObjectToArray]
  )

  const handleSelectAll = () => {
    const groupsIdsArray = treeGroups !== null ? Object.keys(treeGroups) : []
    setCheckedIds((prevState) => {
      return prevState.length === groupsIdsArray.length ? [] : groupsIdsArray
    })
    if (mode === strings.PARTIAL_MULTILPE_MODE || mode === strings.MULTIPLE_MODE)
      setCheckedIds(checkedIds.length === groupsIdsArray.length ? [] : groupsIdsArray)
  }

  const handleTaskOwnerGroupUI = (currentId: string, isOpen?: boolean) => {
    const ids = getTreeNodesIds(treeGroups, currentId)
    const parentIds = getParentsFromGroups(currentId, treeGroups)
      .map(({ parent }) => parent)
      .filter((parentId) => parentId !== null)
    if (mode === strings.MULTIPLE_MODE) {
      setCheckedIds(multipleModeSettingUI(treeGroups, checkedIds, currentId, parentIds, ids))
    } else if (mode === strings.PARTIAL_MULTILPE_MODE) {
      setCheckedIds(patrialMultipleModeSetting(checkedIds, currentId, isOpen!, ids))
    } else if (mode === strings.SINGLE_MODE_POSTS || mode === strings.SINGLE_MODE_CONTEXT) {
      return setCheckedIds([currentId])
    } else if (mode === strings.USERS_MODE) {
      setCheckedIds(usersMode(treeGroups, checkedIds, currentId, parentIds, ids, newCheckboxId, setNewCheckboxId))
    }
  }

  const onSubmit = () => {
    if (mode === strings.USERS_MODE) {
      const diff = findArrayDifference(checkedIds, selectedGroupID!)
      handleAddGroup?.(diff[0])
      setIntermediateCheckedIds?.(checkedIds)
    }
    setSelectedGroupID(checkedIds)
    setGroupList?.(false)
    setViewOption?.(null)
    setGroupsToPayload?.(handleMuplipleIds(treeGroups, checkedIds))
    handleChangeContext?.(checkedIds[0])
  }

  useEffect(() => {
    if (searchValue) {
      const results = groupsObjectToArray.filter((object) =>
        object.title.toLowerCase().includes(searchValue.toLocaleLowerCase())
      )
      setSearchResults(results)
    }
  }, [groupsObjectToArray, searchValue])

  useEffect(() => {
    if (treeGroups) {
      setInitialGroups(treeGroups!)
    }
  }, [treeGroups, setInitialGroups])

  useEffect(() => {
    if (mode !== strings.SINGLE_MODE_CONTEXT && mode !== strings.PARTIAL_MULTILPE_MODE)
      setIntermidateGroupsToPayload(handleMuplipleIds(treeGroups, checkedIds))
  }, [checkedIds, treeGroups, mode, setGroupsToPayload])

  const groupNamesToShow = trimAndCountExtraGroups({
    items: intermidateGroupsToPayload.map((id) => treeGroups?.[id]?.name).join(', '),
    sliceLimit: 2,
  })

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      hideCloseButton={true}
      isShowAnimation={isShowAnimation}
      headerContent={
        <>
          <Flex alignItems="center" justifyContent="space-between" dir={isRtl}>
            <Box
              onClick={() => {
                setGroupList?.(false)
                setViewOption?.(null)
                onClose?.()
              }}
              mx="4"
              position="absolute"
            >
              <IconButton
                aria-label="close groups"
                icon={BsArrowLeftShort}
                borderRadius="50%"
                style={{ color: colors.greyMain }}
                fontSize="32px"
                transform={`rotate(${+isHebrew * 180}deg)`}
                _focus={{ outline: 'none' }}
              />
            </Box>
            <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
              {mode === strings.SINGLE_MODE_POSTS || mode === strings.PARTIAL_MULTILPE_MODE ? (
                <LocaleText text="create_task_assignee_title" />
              ) : (
                <LocaleText text="select_context_title" />
              )}
            </Text>
          </Flex>
          <Divider />
          <Flex flexDirection="column" px={6} mt="20px">
            <InputSearch searchValue={searchValue} setSearchValue={setSearchValue} placeholder={search_placeholder} />
            {searchValue ? (
              <Flex color={colors.greyDark} fontSize="18px" marginTop="17px" mr="20px" ml="20px">
                <LocaleText text="select_group_search_results" />
              </Flex>
            ) : mode === strings.SINGLE_MODE_CONTEXT || mode === strings.USERS_MODE ? (
              <>
                <Flex mt="17px" width="100%" justifyContent="space-between" flexDir="column" style={{ rowGap: '10px' }}>
                  <Text
                    fontSize="18px"
                    color={colors.greyDark}
                    fontWeight="700"
                    textAlign={isHebrew ? 'right' : 'left'}
                  >
                    <LocaleText text="select_context_current_group" />
                    {`:  ${treeGroups?.[checkedIds[0]]?.name ?? ''}`}
                  </Text>
                </Flex>
                <Divider mt="10px" mb="2.5px" color={colors.greyMedium} />
              </>
            ) : (
              <SelectAllButton
                mode={mode}
                groupNames={groupNamesToShow}
                userWritePermittedGroups={treeGroups}
                groupsToPayload={intermidateGroupsToPayload}
                handleSelectAll={handleSelectAll}
                groupsObjectToArray={groupsObjectToArray}
                checkedIds={checkedIds}
              />
            )}
          </Flex>
        </>
      }
      bodyContent={
        <>
          {recursiveGroups && (
            <Flex width="100%" flexDirection="column" paddingRight="50px" paddingLeft="50px" minHeight="300px">
              {searchValue ? (
                <>
                  {searchResults.length > 0 ? (
                    searchResults.map((result, index) => {
                      return (
                        <SearchList
                          key={index}
                          locale={locale}
                          result={result}
                          setSearchValue={setSearchValue}
                          setGroupElementId={setGroupElementId}
                          handleTaskOwnerGroupUI={handleTaskOwnerGroupUI}
                          treeGroups={treeGroups}
                        />
                      )
                    })
                  ) : (
                    <NoResults />
                  )}
                </>
              ) : (
                <>
                  {recursiveGroups.map((group: any) => {
                    return (
                      <Box key={group.id!}>
                        <GroupsTreeNode
                          id={group.id!}
                          title={group.title}
                          checkedIds={checkedIds}
                          locale={locale}
                          childs={group.children}
                          handleTaskOwnerGroupUI={handleTaskOwnerGroupUI}
                          groupElementId={groupElementId}
                          intermidateIds={intermidateIds}
                          setGroupElementId={setGroupElementId}
                          disabledIds={disabledIds}
                          mode={mode}
                        />
                      </Box>
                    )
                  })}
                </>
              )}
            </Flex>
          )}
        </>
      }
      footerContent={
        <Flex justifyContent="flex-end" background="white" px="6">
          {!searchValue && (
            <Button
              borderRadius="20px"
              isDisabled={isAllowSubmitWhithoutGroups ? false : !(checkedIds.length > 0)}
              onClick={onSubmit}
              color="white"
              textTransform="uppercase"
              background={theme?.elementsColor}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="select_group_select_button" />
            </Button>
          )}
        </Flex>
      }
    />
  )
}

export default GroupsTree
