// eslint-disable-next-nine @typescript-eslint/no-use-before-define
import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Box, Text } from '@chakra-ui/core'

import colors from '../../constants/colors'
import { RootState } from 'constants/interfaces'
import { setCurrentCategory, resetGroups } from 'redux/actions/tasksOverviewDetail'
import { NavTitleItem, BackArrow, NavItem, SidebarContent, StyledSidebar } from './SidebarStyles'
import keys from 'constants/keys'
import { isEmpty } from 'lodash'

const TaskOverviewSidebar = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const locale = useSelector((state: RootState) => state.general.locale)
  const detailData = useSelector((state: RootState) => state.tasksOverviewDetail)
  const { isFetching } = useSelector((state: RootState) => state.tasksOverviewDetail)
  const { overviewData } = useSelector((state: RootState) => state.tasksOverview)

  const { groups } = useSelector((state: RootState) => state.config.config)

  const { refId, groupId, category } = useParams()
  const [currentStore, setCurrentStore] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const isHebrew = locale === 'he'

  useEffect(() => {
    setIsLoading(true)
    if (detailData.currentTask && !category) {
      setIsLoading(false)
    }
    // eslint-disable-next-line
  }, [detailData.currentTask, refId, groupId])

  useEffect(() => {
    if (category && groups && groupId && groups[groupId]) {
      setCurrentStore(groups[groupId].name)
    } else {
      setCurrentStore('')
    }
  }, [groupId, groups, category])

  const categoriesList = useMemo(() => {
    const currentTask = overviewData?.tasks.find((task) => task.ref_id === refId)

    if (!isEmpty(currentTask?.statusesByCategory)) {
      const keys = Object.keys(currentTask?.statusesByCategory!) as string[]
      return keys
    }
    return []
  }, [overviewData, refId])

  const handleCategory = (e: React.MouseEvent, category: string) => {
    e.preventDefault()
    dispatch(setCurrentCategory(category))
    dispatch(resetGroups())
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleBackButton = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    if (category) {
      navigate(keys.ROUTE_NAMES.DASHBOARD_DETAIL(refId, groupId))
    } else {
      navigate(keys.ROUTE_NAMES.DASHBOARD)
    }
  }

  return (
    <StyledSidebar
      as="aside"
      bg={colors.greyMedium}
      w="100%"
      overflow="visible"
      p={{
        base: 0,
        lg: 4,
      }}
      isHebrew={isHebrew}
      position="relative"
      pl={{
        lg: locale === 'he' ? '0' : '70px',
        base: 0,
      }}
      pr={{
        lg: locale === 'he' ? '70px' : '0',
        base: 0,
      }}
    >
      <SidebarContent justifyContent="space-between" flexDirection="column">
        <Box
          as="nav"
          mt={{
            lg: 6,
            base: 0,
          }}
        >
          {currentStore ? (
            <NavTitleItem onClick={() => navigate(-1)}>
              <BackArrow />
              <Text fontWeight="bold" pl={locale === 'he' ? '0' : '20px'} pr={locale === 'he' ? '20px' : '0'}>
                {currentStore}
              </Text>
            </NavTitleItem>
          ) : (
            <>
              <NavTitleItem onClick={handleBackButton}>
                <BackArrow left="350px" right="350px" position="absolute" />
                <Text fontWeight="bold" pl={locale === 'he' ? '0' : '18px'} pr={locale === 'he' ? '20px' : '0'}>
                  {detailData.currentTask?.title}
                </Text>
              </NavTitleItem>
              {!isLoading && (
                <>
                  <Box
                    display="flex"
                    overflowX="auto"
                    flexDirection={{
                      lg: 'column',
                      base: 'row',
                    }}
                    py={{
                      base: 2,
                      lg: 0,
                    }}
                  >
                    {categoriesList.map((item: string) => {
                      return (
                        <NavItem
                          to=""
                          key={item}
                          className={detailData.currentCategory === item ? 'active' : isFetching ? 'disabled' : ''}
                          onClick={(e) => handleCategory(e, item)}
                        >
                          {item}
                        </NavItem>
                      )
                    })}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      </SidebarContent>
    </StyledSidebar>
  )
}

export default TaskOverviewSidebar
