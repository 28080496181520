import { actionTypes } from '../actions/general'
import { GeneralState } from '../../constants/interfaces'
import keys from '../../constants/keys'

export const initialState: GeneralState = {
  data: {},
  locale: keys.DEFAULT_LOCALE,
  selectedRoute: keys.ROUTE_NAMES.NEWS_FEED,
  isResponseSuccess: null,
  theme: null,
}

const generalReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_RESPONSE_STATUS:
      return {
        ...state,
        isResponseSuccess: action.payload,
      }
    case actionTypes.SET_DATA:
      return {
        ...state,
        data: action.data,
      }
    case actionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }

    case actionTypes.SET_SELECTED_ROUTE:
      return {
        ...state,
        selectedRoute: action.selectedRoute,
      }
    case actionTypes.SET_THEME_CONFIG:
      return {
        ...state,
        theme: action.payload,
      }

    default:
      return state
  }
}

export default generalReducer
