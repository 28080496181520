import { actionTypes } from '../actions/tasks'
import { filterObject } from '../../utils'
import { ITasksState } from 'constants/interfaces'
import keys from 'constants/keys'
import { SubTask } from 'config/tasksTypes'

export const initialState: ITasksState = {
  allTasks: {},
  reportTasks: [],
  customReports: [],
  rollTasks: [],
  tasksData: {},
  subTasks: {},
  productSubTasks: {},
  textSubTasks: {},
  imageSubTasks: {},
  uploadFileSubTasks: {},
  linkSubTasks: {},
  imageTaskImages: {},
  videoSubTasks: {},
  pickedReportIdx: 0,
  activeStoreId: null,
  isTasksLoading: false,
}

const tasksReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_ALL_TASKS:
      return {
        ...state,
        allTasks: action.allTasks,
      }
    case actionTypes.SET_TASKS:
      return {
        ...state,
        allTasks: action.tasks.allTasks,
        reportTasks: action.tasks.reportTasks,
        rollTasks: action.tasks.rollTasks,
      }

    case actionTypes.SET_ACTIVE_STORE_ID:
      return {
        ...state,
        activeStoreId: action.sid,
      }

    case actionTypes.SET_SUBTASKS:
      return {
        ...state,
        subTasks: action.subTasks,
        productSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.PRODUCT_SUBTASK
        ),
        textSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.TEXT_SUBTASK
        ),
        imageSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.IMAGE_SUBTASK
        ),
        uploadFileSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK
        ),
        linkSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.LINK_SUBTASK
        ),
        videoSubTasks: filterObject(
          action.subTasks,
          (subtask: SubTask) => subtask.type === keys.SUBTASK_TYPES.VIDEO_SUBTASKS
        ),
      }

    case actionTypes.SET_REPORT_INDEX_IN_STATE:
      return {
        ...state,
        pickedReportIdx: action.pickedReportIdx,
      }
    case actionTypes.SET_TASKS_DATA:
      return {
        ...state,
        tasksData: action.tasksData,
      }

    case actionTypes.SET_IS_TASKS_LOADING:
      return {
        ...state,
        isTasksLoading: action.isTasksLoading,
      }
    default:
      return state
  }
}

export default tasksReducer
