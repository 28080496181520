import { Box, Button, Flex, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { BsXCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { setIsError } from 'redux/actions/admin'

interface ModalActionFailedContent {
  onClose: () => void
}
export const ActionFailedContent = ({ onClose }: ModalActionFailedContent) => {
  const dispatch = useDispatch()
  const theme = useSelector((state: RootState) => state.general.theme)
  const something_wrong_literal = useLocaleText('Something went wrong...')
  const try_again_literal = useLocaleText('Try again')

  const handleTryAgain = () => {
    dispatch(setIsError(false))
    onClose()
  }
  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      <Box as={BsXCircle} size="100px" color="red.400" />
      <Flex flexDir="column" my="20px" fontWeight="600" color="#a2a2b6" fontSize="24px">
        <Text>{something_wrong_literal}</Text>
      </Flex>
      <Flex flexDir="row" mt="40px" width="60%" justifyContent="space-around">
        <Button
          _hover={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
          _focus={{ boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
          backgroundColor="red.400"
          color="white"
          onClick={() => handleTryAgain()}
        >
          {try_again_literal}
        </Button>
      </Flex>
    </Flex>
  )
}
