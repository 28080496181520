import React from 'react'
import { Divider, Flex, Text } from '@chakra-ui/core'
import { OptionProps } from 'react-select'
import styled from '@emotion/styled'

import Avatar from 'components/CommonComponents/Avatar'
import colors from 'constants/colors'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { RoundedCheckbox } from 'components/helperStyles'

interface IOption {
  id: string
  name: string
  avatar?: string
  isSelected: boolean
}

const CustomOptionWrapper = styled(Flex)`
  min-height: 50px;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  &:hover {
    background: ${colors.greyLight};
  }
`

export const SelectOption = ({ data, ...props }: OptionProps<IOption, false>) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <>
      <CustomOptionWrapper {...props.innerProps}>
        <RoundedCheckbox
          defaultIsChecked={data.isSelected}
          borderRadius="50%"
          mb="0"
          size="lg"
          color={theme?.elementsColor}
        />
        {data?.avatar && <Avatar src={data.avatar} size="md" />}
        <Flex flexDir="column">
          <Text fontSize="16px">
            <LocaleText text={data.name} />
          </Text>
          <Text fontSize="14px" color="#90949C">
            <LocaleText text={data.desc} />
          </Text>
          <Text fontSize="14px" color="#90949C">
            <LocaleText text={data.sec_uid} />
          </Text>
        </Flex>
      </CustomOptionWrapper>
      <Divider my="0" />
    </>
  )
}
