import { DiffTag } from 'components/Reports/ui/styledComponents'
import colors from 'constants/colors'
import { Avatar, Flex, Text } from '@chakra-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { isNumber } from 'lodash'
import { RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'

type IProps = {
  value: string
  isTag: boolean
  groupId: string
  index: number
}
export const Cell = ({ value, isTag, groupId, index }: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  const {
    retailConfig,
    config: { mappedRetailUsersByGroups },
  } = useSelector((state: RootState) => state.config)

  const avatar = useAvatar(mappedRetailUsersByGroups[groupId][0])

  let number
  if (isTag) {
    number = parseFloat(value.replace('%', ''))
  }
  const tagColor =
    isTag && isNumber(number) ? (number > 0 ? colors.greenDark : number === 0 ? 'white' : colors.red) : 'transparent'

  return (
    <>
      {isTag ? (
        <DiffTag color={tagColor} w="max-content" borderColor={number === 0 ? 'black' : 'transparent'} dir={'ltr'}>
          <Text
            fontFamily="inherit"
            as={'span'}
            lineHeight={'normal'}
            fontSize="12px"
            fontWeight="500"
            w="max-content"
            textAlign={isRtl ? 'right' : 'left'}
            color={number === 0 ? colors.greyMain : 'white'}
          >
            {value}
          </Text>
        </DiffTag>
      ) : index === 0 && retailConfig?.is_personalization_mode ? (
        <Flex alignItems="center">
          <Avatar src={avatar} mx="10px" />
          <Text
            dir={isRtl ? 'rtl' : 'ltr'}
            lineHeight={'normal'}
            fontFamily="inherit"
            fontSize="16px"
            minW="40px"
            textAlign={isRtl ? 'right' : 'left'}
            isTruncated
            maxW="150px"
          >
            {value ? value : '—'}
          </Text>
        </Flex>
      ) : (
        <Text
          dir={isRtl ? 'rtl' : 'ltr'}
          lineHeight={'normal'}
          fontFamily="inherit"
          fontSize="16px"
          minW="40px"
          textAlign={isRtl ? 'right' : 'left'}
        >
          {value ? value : '—'}
        </Text>
      )}
    </>
  )
}
