export enum SymbolType {
  percentage = 0,
  currency = 1,
  items = 2,
  currency_per_hour = 3,
}

export enum ComponentsTypes {
  globalReport = 100,
  timeless = 101,
  timeDependent = 102,

  component = 0,
  rectangle = 1,
  title = 2,
  sectionTitle = 3,
  numericLine = 4,
  comparisonLine = 5,
  detailLine = 6,
  barLine = 7,
  horizontalChartLine = 8,
  productLine = 9,
  tableCell = 10,
  table = 11,
  separatorLine = 12,
  salesDoubleBar = 13,
  detailedTitle = 14,
}
