import { Box } from '@chakra-ui/core'
import { CustomTextareaAutosize } from 'components/helperStyles'
import useLocaleText from 'components/useLocaleText'
import React, { useRef, useState } from 'react'
import { OptionType } from './constants'

interface MessageInput {
  message: string
  setMessage: (val: string) => void
  selectedOptionType: string
  maxLines?: number
}
export const MessageInput = ({ message, setMessage, selectedOptionType, maxLines = 7 }: MessageInput) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const hiddenDivRef = useRef<HTMLDivElement>(null)
  const create_pop_placeholder = useLocaleText('create_pop_placeholder')
  const create_group_placeholder = useLocaleText('create_group_placeholder')
  const [textValue, setTextValue] = useState(message ?? '')
  const handleTextareaChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value

    if (textareaRef.current && hiddenDivRef.current) {
      hiddenDivRef.current.textContent = newValue

      const lineHeight = parseFloat(window.getComputedStyle(textareaRef.current).lineHeight)
      const hiddenDivHeight = hiddenDivRef.current.offsetHeight

      if (hiddenDivHeight / lineHeight <= maxLines) {
        setTextValue(newValue)
        setMessage(newValue)
      }
    } else {
      setTextValue(newValue)
      setMessage(newValue)
    }
  }

  return (
    <>
      <CustomTextareaAutosize
        ref={textareaRef}
        value={textValue}
        onChange={handleTextareaChange}
        placeholder={selectedOptionType === OptionType.CHAT ? create_group_placeholder : create_pop_placeholder}
        borderTopLeftRadius="0"
        borderTopRightRadius="0"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        boxShadow="none"
        minRows={5}
        style={{ marginBottom: '3px', padding: '10px 0' }}
        color="#333333"
        onBlur={() => setMessage(textValue)}
      />
      <Box
        ref={hiddenDivRef}
        style={{
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
          position: 'absolute',
          visibility: 'hidden',
          height: 'auto',
          width: textareaRef.current?.offsetWidth || 'auto',
          fontSize: textareaRef.current?.style.fontSize || 'inherit',
          fontFamily: textareaRef.current?.style.fontFamily || 'inherit',
          padding: textareaRef.current?.style.padding || 'inherit',
          border: 'none',
          outline: 'none',
          resize: 'none',
          lineHeight: textareaRef.current?.style.lineHeight || 'inherit',
        }}
      >
        {textValue}
      </Box>
    </>
  )
}
