import { IAllUserTaskPermittedGroup, IConfigStateGroups } from 'constants/interfaces'

export const getTreeNodesIds = (groups: object | null, rootId: string): string[] => {
  if (groups![rootId]?.child) {
    return [rootId, ...groups![rootId]?.child?.flatMap((id: any) => getTreeNodesIds(groups, id))]
  } else {
    return [rootId]
  }
}

export const addCheckedElements = (
  groups: object | null,
  checkedGroups: string[],
  selectedId: string | null
): string[] => {
  const newCheckedIds: string[] = []

  while (selectedId !== null) {
    newCheckedIds.push(selectedId)
    const { parent } = groups![selectedId]
    if (parent === null || !Object.keys(groups!).includes(parent)) {
      break
    }
    // eslint-disable-next-line
    const parentsChildrenWithoutCurrent = groups![parent].child.filter((id: string) => id !== selectedId)
    const allChildrenWasSelected = parentsChildrenWithoutCurrent.every((id: string) => checkedGroups.includes(id))
    if (!allChildrenWasSelected) {
      break
    }
    selectedId = groups![selectedId].parent
  }
  return [...checkedGroups, ...newCheckedIds]
}

export const getParentsFromGroups = (id: string, groups: object | null) => {
  const parentsArr = []
  let currId: string | null = id
  if (groups![currId]?.parent)
    while (currId !== null && groups![currId] !== undefined) {
      parentsArr.unshift(groups![currId])
      currId = groups![currId].parent
    }
  return parentsArr
}

export const patrialMultipleModeSetting = (checkedIds: string[], currentId: string, isOpen: boolean, ids: string[]) => {
  if (checkedIds.includes(currentId)) {
    if (isOpen) {
      return checkedIds.filter((id) => id !== currentId)
    }
    return checkedIds.filter((id) => !ids.includes(id))
  } else {
    if (isOpen) {
      return [...checkedIds, currentId]
    }
  }
  const mergedUniqIds = new Set([...checkedIds, ...ids])
  return Array.from(mergedUniqIds)
}

export const multipleModeSettingUI = (
  groups: object | null,
  checkedIds: string[],
  currentId: string,
  parentIds: string[],
  ids: string[]
) => {
  if (checkedIds.includes(currentId)) {
    return checkedIds.filter((id) => !ids.includes(id) && !parentIds.includes(id))
  } else {
    const childrenIds = ids.filter((id) => id !== currentId)
    return [...addCheckedElements(groups, checkedIds, currentId), ...childrenIds]
  }
}

export const usersMode = (
  groups: object | null,
  checkedIds: string[],
  currentId: string,
  parentIds: string[],
  ids: string[],
  newCheckboxId: string | null,
  setNewCheckboxId: (id: string | null) => void
) => {
  if (checkedIds.includes(currentId)) {
    setNewCheckboxId(null)
    return checkedIds.filter((id) => id !== currentId)
  } else {
    const isParent = groups?.[currentId].child.length > 0 && !checkedIds.some((id) => parentIds.includes(id))
    if (isParent) {
      setNewCheckboxId(currentId)
      return [...checkedIds.filter((id) => !ids.includes(id)), currentId].filter((id) => id !== newCheckboxId)
    } else if (!isParent) {
      setNewCheckboxId(currentId)
      return [...checkedIds, currentId].filter((id) => !parentIds.includes(id)).filter((id) => id !== newCheckboxId)
    }
    return checkedIds
  }
}

export const handleMuplipleIds = (groups: IConfigStateGroups | IAllUserTaskPermittedGroup, checkedIds: string[]) => {
  let newCheckedIds: string[] = checkedIds?.filter((id) => !checkedIds?.includes(groups[id]?.parent))

  return newCheckedIds
}
