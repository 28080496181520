import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { BsExclamationCircle } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { StyledConfirmButton } from '../UI/CustomComponents'
import LocaleText from 'components/LocaleText'

interface IConfirmActionContent {
  onClose: () => void
  literal: string
  handleAction: () => void
}
export const ConfirmActionContent = ({ onClose, literal, handleAction }: IConfirmActionContent) => {
  const { isFetching } = useSelector((state: RootState) => state.admin.userTable)
  const { fetching } = useSelector((state: RootState) => state.feed)
  const theme = useSelector((state: RootState) => state.general.theme)
  const admin_yes_action = useLocaleText('admin_yes_action')
  const admin_no_cancel = useLocaleText('admin_no_cancel')

  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      {isFetching || fetching ? (
        <Spinner
          thickness="8px"
          speed="0.65s"
          emptyColor="gray.200"
          color={theme?.backgroundMedium}
          w="100px"
          h="100px"
        />
      ) : (
        <Box as={BsExclamationCircle} size="100px" color={theme?.backgroundMedium} />
      )}
      <Text my="18px" fontWeight="600" color="#a2a2b6" fontSize="22px" textAlign="center">
        <LocaleText text={literal} />
      </Text>
      <Flex flexDir="row" mt="40px" width="70%" justifyContent="space-around" alignItems="center">
        <Button
          _hover={{ backgroundColor: 'none', color: '#4299e199' }}
          _focus={{ boxShadow: 'none' }}
          backgroundColor="none"
          color="#a2a2b6"
          onClick={() => onClose()}
        >
          {admin_no_cancel}
        </Button>
        <StyledConfirmButton isDisabled={isFetching} onClick={() => handleAction()} themeColor={theme?.elementsColor}>
          {admin_yes_action}
        </StyledConfirmButton>
      </Flex>
    </Flex>
  )
}
