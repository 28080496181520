import { Divider, Flex, Text } from '@chakra-ui/core'
import React from 'react'

export const DividerWithText = ({ text }: { text: string }) => {
  return (
    <Flex align="center" my={4}>
      <Divider w="100%" borderBottom="2px solid" borderColor="#d7d7d7" />
      <Text px={2} color="grey">
        {text}
      </Text>
      <Divider w="100%" borderBottom="2px solid" borderColor="#d7d7d7" />
    </Flex>
  )
}
