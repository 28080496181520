import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Box } from '@chakra-ui/core'
import { ValueType } from 'react-select'
import avatarPlaceholder from 'assets/avatar-placeholder.svg'

import { CreatorSelect } from './CreatorSelect'
import { ByDateSelect } from './ByDateSelect'
import { MediaSelect } from './MediaSelect'
import { IOption, RootState } from 'constants/interfaces'
import { mediaOptionsDefault } from 'constants/baseValues'

interface IProps {
  createdBy: string
  changeCreatedBy: (data: ValueType<IOption, false>) => void
  changeDate: (date: any) => void
  mediaFilter: string
  handleMediaFilter: (data: ValueType<IOption, false>) => void
  resetMediaFilter: VoidFunction
  resetPostCreator: VoidFunction
}

export const SearchFilterParams = ({
  createdBy,
  changeCreatedBy,
  mediaFilter,
  handleMediaFilter,
  changeDate,
  resetMediaFilter,
  resetPostCreator,
}: IProps) => {
  const { users } = useSelector((state: RootState) => state.config.userWritePermittedContext)
  const { retail_users } = useSelector((state: RootState) => state.config.config)
  const filteredRerailUsersByTreeUsers = useMemo(
    () => retail_users.filter((user) => users.includes(user.uid!)),
    [retail_users, users]
  )

  const creatorSelectUsers = useMemo(() => {
    return filteredRerailUsersByTreeUsers
      ?.map((user) => ({
        id: user.uid || '',
        name: `${user.first_name} ${user.last_name ? user.last_name : ''}`,
        desc: user.desc || '',
        sec_uid: user.sec_uid || '',
        avatar: user.profile_img_url || avatarPlaceholder,
        isSelected: createdBy === user.uid,
      }))
      .sort((option, nextOption) => Number(nextOption.isSelected) - Number(option.isSelected))
  }, [filteredRerailUsersByTreeUsers, createdBy])

  const mediaOptions = useMemo(
    () =>
      mediaOptionsDefault
        .map((option) => ({
          ...option,
          isSelected: option.id === mediaFilter,
        }))
        .sort((option, nextOption) => Number(nextOption.isSelected) - Number(option.isSelected)),
    [mediaFilter]
  )

  return (
    <>
      <CreatorSelect
        options={creatorSelectUsers || []}
        value={creatorSelectUsers?.find((user) => user.id === createdBy)}
        onChange={changeCreatedBy}
        onDeleteSelection={resetPostCreator}
      />
      <Box>
        <ByDateSelect onChange={changeDate} />
      </Box>
      <MediaSelect
        options={mediaOptions}
        value={mediaOptions.find((option) => option.id === mediaFilter)}
        onChange={handleMediaFilter}
        onDeleteSelection={resetMediaFilter}
      />
    </>
  )
}
