import React from 'react'
import { CloseButton, List, ListIcon, ListItem } from '@chakra-ui/core'
import { ReactComponent as LinkIcon } from 'assets/link-icon.svg'
import { FileLink } from './FileLink'
import colors from '../../constants/colors'

interface IProps {
  fileList: string[]
  deleteFileHandler?: (url: string) => void
  isFileOpened?: boolean
  color?: string
  fontSize?: string
}

export const UploadedFileList = ({
  fileList,
  deleteFileHandler,
  isFileOpened = true,
  color = colors.blueDark,
  fontSize = '24px',
}: IProps) => {
  return (
    <List>
      {fileList.map((url) => (
        <ListItem key={url} display="flex" alignItems="center" isTruncated color={color} fontSize={fontSize}>
          <ListIcon icon={LinkIcon} className="link-icon" />
          <FileLink url={url} isFileOpened={isFileOpened} />
          {deleteFileHandler && <CloseButton size="sm" onClick={() => deleteFileHandler(url)} />}
        </ListItem>
      ))}
    </List>
  )
}
