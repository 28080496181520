import React, { useMemo, useRef } from 'react'
import { Link } from '@chakra-ui/core'
import { extractFileNameFromS3Link, getFileExtension, getFileNameWithoutExtension, openInCloudViewer } from 'utils'
import { TruncatedFileName } from './TruncatedFileName'

interface IFileLinkProps {
  isFileOpened: boolean
  url: string
  maxWidth?: string
  children?: React.ReactNode
}

export const FileLink = ({ isFileOpened, url, maxWidth = '300px', children }: IFileLinkProps) => {
  const linkRef = useRef<HTMLAnchorElement | null>(null)

  const fileNameFromLink = useMemo(() => extractFileNameFromS3Link(url) || '', [url])
  const fileName = getFileNameWithoutExtension(fileNameFromLink) || ''
  const fileExtension = getFileExtension(fileNameFromLink)

  return isFileOpened ? (
    <Link target="_blank" ref={linkRef} href={openInCloudViewer(url)}>
      {children}
      <TruncatedFileName fileName={fileName} fileExtension={fileExtension} maxWidth={maxWidth} />
    </Link>
  ) : (
    <>
      {children}
      <TruncatedFileName fileName={fileName} fileExtension={fileExtension} maxWidth={maxWidth} />
    </>
  )
}
