import { httpAuth } from '../../config/apiClient'
import { IDummyPop, IPop, RootState } from 'constants/interfaces'
import { AnyAction } from 'redux'
import { ThunkAction } from 'redux-thunk'
import endpoints from 'constants/endpoints'
import { isNumber } from 'lodash'

export const actionTypes = {
  SET_POPS: 'SET_POPS',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_DUMMY_POP: 'SET_DUMMY_POP',
}

export const setPops = (pops: IPop[]) => ({
  type: actionTypes.SET_POPS,
  payload: pops,
})

export const setDummyPop = (pop: IDummyPop | null) => ({
  type: actionTypes.SET_DUMMY_POP,
  payload: pop,
})
export const setIsLoading = (loading: boolean) => ({
  type: actionTypes.SET_IS_LOADING,
  payload: loading,
})

export const handleGetPops = (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch, getState) => {
  const pop_config = getState().config.retailConfig?.pop_config
  if (pop_config?.quantity_limit && pop_config.time_limit)
    try {
      dispatch(setIsLoading(true))
      const params = {
        ...(isNumber(pop_config?.quantity_limit) ? { quantity_limit: pop_config?.quantity_limit } : {}),
        ...(isNumber(pop_config?.time_limit) ? { time_limit: pop_config?.time_limit } : {}),
        ...(isNumber(pop_config?.long_range_time_limit)
          ? { long_range_time_limit: pop_config?.long_range_time_limit }
          : {}),
      }
      const {
        data: { pops },
      } = await httpAuth.get(endpoints.pop, {
        params,
      })
      dispatch(setPops(pops))
    } catch (e) {
      dispatch(setIsLoading(false))
      console.log(e)
    } finally {
      dispatch(setIsLoading(false))
    }
}

export const handleSubmitPop =
  ({ pop_id, isRepop, pop_type }: { pop_id: string; isRepop: boolean, pop_type: number }): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { pops } = getState().pops
    try {
      dispatch(setIsLoading(true))
      const filteredPops = pops.filter((pop) => pop.pk !== pop_id)
      dispatch(setPops(filteredPops))
      if (isRepop) {
        await httpAuth.put(endpoints.submitRePop, { pop_id })
      } else {
        await httpAuth.put(endpoints.submitPop, { pop_id, state: '1', pop_type })
      }
    } catch (e) {
      dispatch(setIsLoading(false))
      console.log(e)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const editRepop =
  ({ body }: { body: any }): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true))
      await httpAuth.put(endpoints.pop, { ...body })
      return true
    } catch (e) {
      console.log(e)
      return false
    } finally {
      dispatch(setIsLoading(false))
    }
  }
