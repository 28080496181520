import React, { memo } from 'react'
import { SelectOption, IOption } from './SelectOption'
import { SelectMain } from './SelectMain'
import { SelectMenuList } from './SelectMenuList'
import { SelectControl } from './SelectControl'
import { Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'

export interface IProps {
  value?: IOption
  register?: any
  onChange: any
  options: IOption[]
  field: string
  placeholder?: string
  inInvalid?: boolean
  isShowSearch: boolean
  minWidth?: string
  onDeleteSelection?: (arg: object | null) => void
  requiredPlaceholder?: string
  watch?: any
  disabled: boolean
}

const SelectFieldComponent = memo(
  ({
    value,
    onChange,
    options,
    field,
    placeholder,
    inInvalid,
    register,
    isShowSearch,
    minWidth,
    onDeleteSelection,
    requiredPlaceholder,
    disabled,
  }: IProps) => {
    const filterConfig = {
      ignoreAccents: false,
      trim: false,
      ...(isShowSearch
        ? {
            ignoreCase: true,
            stringify: (option: any) => {
              return `${option.data.id} ${option.data.name}`
            },
          }
        : {}),
    }
    const renderValue = () => {
      if (value && Object.keys(value).length > 0) {
        return (
          <>
            <LocaleText text={value?.code || value?.name} />
            &nbsp;
            <Text as={'span'}>{`${value?.avatar || ''} ${field === 'country_code' ? `(${value?.id})` : ''} `}</Text>
          </>
        )
      } else return !disabled ? placeholder : <LocaleText text="admin_admin_view_option" />
    }

    return (
      <SelectMain
        options={options}
        value={value}
        field={field}
        register={register}
        onChange={onChange}
        filterConfig={filterConfig}
        requiredPlaceholder={requiredPlaceholder}
        components={{
          Option: (props) => <SelectOption field={field} selectedValue={value && value.id} {...props} />,
          Control: (props) => (
            <SelectControl inInvalid={inInvalid} disabled={disabled} {...props}>
              {renderValue()}
            </SelectControl>
          ),
          MenuList: ({ innerRef, children, ...props }) => (
            <SelectMenuList
              value={value}
              ref={innerRef}
              onDeleteSelection={onDeleteSelection}
              field={field}
              isShowSearch={isShowSearch}
              {...props}
            >
              {children}
            </SelectMenuList>
          ),
        }}
      />
    )
  }
)
export default SelectFieldComponent
