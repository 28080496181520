import { Menu, MenuButton, MenuItem, MenuList, useToast } from '@chakra-ui/core'
import React, { memo } from 'react'
import { ReactComponent as OverflowMenu } from 'assets/overflow-menu.svg'
import { useDispatch, useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import useLocaleText from 'components/useLocaleText'
import { handleDeletePost } from 'redux/actions/feed'
import { PinType, PinedPostsObject, removeFromPinedPostsObject, updateLocalStorage } from '../utils'
import { useSearchPage } from 'hooks/useSearchPage'
import { RootState } from 'constants/interfaces'
import colors from 'constants/colors'

interface Props {
  groups: string[]
  users: string[]
  pinedPostsObject?: PinedPostsObject
  post_id: string
  is_manager_pin?: boolean
  handlePinPost?: (postId: string, pinType: PinType) => void
  onEditPost?: () => void
  editRole: boolean
  isAdminPostPermission: boolean
  isShowPinMenuItem?: boolean
  isShowPinManagerMenuItem?: boolean
  isGroup: boolean
  isShowAdminsModalMenuItem?: boolean
  onSetAdminModalOpen?: () => void
}

export const PostMenu = memo(
  ({
    pinedPostsObject,
    post_id,
    is_manager_pin,
    handlePinPost,
    onEditPost,
    editRole,
    isShowPinMenuItem,
    isAdminPostPermission,
    isShowPinManagerMenuItem,
    isGroup,
    onSetAdminModalOpen,
    isShowAdminsModalMenuItem = false,
  }: Props) => {
    const toast = useToast()
    const { isSearchPage } = useSearchPage()
    const dispatch = useDispatch()

    const isRtl = useSelector(isRtlSelector)
    const myUID = useSelector((state: RootState) => state.auth.uid)

    const t_edit = useLocaleText('edit')
    const t_delete = useLocaleText('delete')
    const t_set_admins = useLocaleText('posts_set_admins')
    const t_pin = useLocaleText('self_pin')
    const t_unpin = useLocaleText('self_unpin')
    const t_manager_pin = useLocaleText('manager_pin')
    const t_manager_unpin = useLocaleText('manager_unpin')

    const deletePost = async () => {
      if (window.confirm('Are you sure ?')) {
        try {
          dispatch(
            handleDeletePost({
              postId: post_id,
              isSearchPage,
              isChat: isGroup,
              isDeletedByAdmin: isAdminPostPermission,
            })
          )
          if (pinedPostsObject) {
            const isPostPinned = pinedPostsObject[myUID!]?.includes(post_id)
            if (isPostPinned) {
              const updatedPostsObject: PinedPostsObject = removeFromPinedPostsObject(pinedPostsObject, myUID!, post_id)
              updateLocalStorage(updatedPostsObject)
            }
          }
        } catch (err) {
          toast({
            title: 'Something went wrong',
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      }
    }

    return (
      <Menu>
        <MenuButton>
          <OverflowMenu style={{ transform: 'rotate(90deg)' }} />
        </MenuButton>
        <MenuList
          placement={isRtl ? 'bottom-start' : 'bottom-end'}
          borderRadius="12px"
          zIndex={5000}
          top="-15px !important"
        >
          <>
            {editRole && (
              <MenuItem onClick={onEditPost} pt="10px" pb="10px" textTransform="capitalize">
                {t_edit}
              </MenuItem>
            )}
            {(editRole || isAdminPostPermission) && (
              <MenuItem pt="10px" pb="10px" onClick={deletePost} color={colors.red}>
                {t_delete}
              </MenuItem>
            )}

            {editRole && isShowAdminsModalMenuItem && onSetAdminModalOpen && (
              <MenuItem pt="10px" pb="10px" onClick={onSetAdminModalOpen}>
                {t_set_admins}
              </MenuItem>
            )}
          </>

          {isShowPinMenuItem && pinedPostsObject && (
            <MenuItem pt="10px" pb="10px" onClick={() => handlePinPost?.(post_id, 'is_self_pin')}>
              {pinedPostsObject[myUID!]?.includes(post_id) ? t_unpin : t_pin}
            </MenuItem>
          )}
          {isShowPinManagerMenuItem && (
            <MenuItem pt="10px" pb="10px" onClick={() => handlePinPost?.(post_id, 'is_manager_pin')}>
              {is_manager_pin ? t_manager_unpin : t_manager_pin}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    )
  }
)
