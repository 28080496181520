import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { BsExclamationCircle } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { deleteUser, setIsFetching } from 'redux/actions/admin'
import useLocaleText from 'components/useLocaleText'
import { StyledConfirmButton } from '../UI/CustomComponents'

export const DeleteContent = ({ row, onClose }: any) => {
  const { isFetching } = useSelector((state: RootState) => state.admin.userTable)
  const theme = useSelector((state: RootState) => state.general.theme)
  const dispatch = useDispatch()
  const admin_delete_user_confirm = useLocaleText('admin_delete_user_confirm')
  const admin_delete_action = useLocaleText('admin_delete_action')
  const admin_no_cancel = useLocaleText('admin_no_cancel')

  const handleDeleteUser = (uid: string[]) => {
    dispatch(setIsFetching(true))
    dispatch(deleteUser(uid))
  }
  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      {isFetching ? (
        <Spinner thickness="8px" speed="0.65s" emptyColor="gray.200" color={theme?.elementsColor} w="100px" h="100px" />
      ) : (
        <Box as={BsExclamationCircle} size="100px" color={theme?.elementsColor} />
      )}
      <Text my="18px" fontWeight="600" color="#a2a2b6" fontSize="22px" textAlign="center">
        {admin_delete_user_confirm}
        {` ${row.original.uid} ?`}
      </Text>
      <Flex flexDir="row" mt="40px" width="70%" justifyContent="space-around" alignItems="center">
        <Button
          _hover={{ backgroundColor: 'none', color: '#4299e199' }}
          _focus={{ boxShadow: 'none' }}
          backgroundColor="none"
          color="#a2a2b6"
          onClick={() => onClose()}
        >
          {admin_no_cancel}
        </Button>
        <StyledConfirmButton
          isDisabled={isFetching}
          onClick={() => handleDeleteUser([row.original.uid])}
          themeColor={theme?.elementsColor}
        >
          {admin_delete_action}
        </StyledConfirmButton>
      </Flex>
    </Flex>
  )
}
