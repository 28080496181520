import React, { memo } from 'react'
import { Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import LocaleText from 'components/LocaleText'

interface ILastUpdateProps {
  lastUpdate: string
}
export const LastUpdate = memo(({ lastUpdate }: ILastUpdateProps) => {
  return (
    <Text minW="100px" color={colors.greyDark} fontSize="14px">
      <Text as={'span'} fontFamily='"Asap Condensed", sans-serif'>
        <LocaleText text={!lastUpdate ? '' : 'last_update'} />
        :&nbsp;
      </Text>
      <Text as={'span'} fontFamily='"Asap Condensed", sans-serif'>
        {lastUpdate}
      </Text>
    </Text>
  )
})
