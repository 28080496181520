import React from 'react'
import { useSelector } from 'react-redux'
import { Flex, Switch, FormLabel } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { v1 as uuidv1 } from 'uuid'
import styled from '@emotion/styled'
import { RootState } from 'constants/interfaces'

const CustomSwitch = styled(Switch)<{ switchColor?: string }>`
  > * {
    direction: ltr;
  }
  input[type='checkbox']:checked + div {
    background-color: ${(props) => props.switchColor};
    border-color: ${(props) => props.switchColor};
  }
`

interface IProps {
  isChecked: boolean
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  labelText: string
  mb?: string
  switchColor?: string
}

const CustomSwitcher = ({ isChecked, onChange, labelText, mb = '2rem', switchColor = 'blue' }: IProps) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  const uuid: string = uuidv1()
  const locale = useSelector((state: any) => state?.general?.locale)
  const isRtl = locale === 'he'

  return (
    <Flex align="center" mb={mb}>
      <CustomSwitch isChecked={isChecked} id={uuid} onChange={onChange} switchColor={theme?.elementsColor} />
      <FormLabel
        htmlFor={uuid}
        fontWeight="400"
        pr={isRtl ? '0' : '3'}
        pl={isRtl ? '3' : '0'}
        ml={+!isRtl && '3'}
        mr={+isRtl && '3'}
        textAlign={isRtl ? 'right' : 'left'}
      >
        <LocaleText text={labelText} />
      </FormLabel>
    </Flex>
  )
}

export default CustomSwitcher
