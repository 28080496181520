import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Flex, useDisclosure, Text } from '@chakra-ui/core'
import { AiOutlineUser } from 'react-icons/ai'
import { VscGear } from 'react-icons/vsc'
import { useDispatch, useSelector } from 'react-redux'
import { MdEmail } from 'react-icons/md'
import { BsTelephoneFill } from 'react-icons/bs'
import { FaUserCog } from 'react-icons/fa'
import { HiUserGroup } from 'react-icons/hi'
import { useParams } from 'react-router-dom'
import { RootState } from 'constants/interfaces'
import {
  createUser,
  getUser,
  setDetailedUser,
  setIsFetching,
  setRegisteredUser,
  updateGroup,
  updateUser,
} from 'redux/actions/admin'
import { GroupTagsList } from '../UserGroupManagement/GroupTagsList'
import GroupsTree from 'components/CommonComponents/GroupsTree'
import strings from 'constants/strings'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import { DefaultGroupSelect } from './DefaultGroupSelect'
import { getDifferenceOfObjects } from 'utils'
import permanentData from '../permanentData'
import { FormWrapper, StyledConfirmButton } from '../UI/CustomComponents'
import UsersActionModal from '../Modals'
import { IUserDetailedObject } from 'redux/reducers/admin'
import LocaleText from 'components/LocaleText'
import { FormInput } from './FormInput'
import { FormSelect } from './FormSelect'
import { FormCheckbox } from './FormCheckbox'
import { getParentsFromGroups, getTreeNodesIds } from 'components/CommonComponents/GroupsTree/utils'

export const DEFAULT_FORM_VALUES = {
  uid: '',
  first_name: '',
  last_name: '',
  password: '',
  email: '',
  phone_number: '',
  country_code: '',
  enable_mfa: false,
  locale: '',
  permission: null,
  default_group: '',
  user_group_role: 0,
  desc: '',
  sec_uid: '',
}

export type FormData = {
  uid: string
  first_name: string
  last_name: string
  password: string
  email: string
  phone_number: string
  country_code: string
  enable_mfa: boolean
  locale: string
  permission: number | null
  default_group?: string
  user_group_role: number
  desc?: string
  sec_uid?: string
}
interface IUserForm {
  handleNextStep?: () => void
}

export const Form = memo(({ handleNextStep }: IUserForm) => {
  const dispatch = useDispatch()
  const { uid } = useParams()
  const theme = useSelector((state: RootState) => state.general.theme)

  const { handleSubmit, errors, register, setValue, clearError, watch, reset } = useForm<FormData>({
    defaultValues: DEFAULT_FORM_VALUES,
  })

  const { detailedUser, isActionSuccessful, isError, isFetching } = useSelector(
    (state: RootState) => state.admin.userTable
  )
  const { groups } = useSelector((state: RootState) => state.config.config)
  const myUid = useSelector((state: RootState) => state.auth.uid)
  const { onClose: onGroupsTreeClose, isOpen: isGroupsTreeOpen, onOpen: onGroupsTreeOpen } = useDisclosure()
  const { onClose, isOpen, onOpen } = useDisclosure()

  const [selectedIds, setSelectedIds] = useState<string[] | undefined>([])
  const [groupToDelete, setGroupToDelete] = useState<string>('')
  const [groupToAdd, setGroupToAdd] = useState<string>('')
  const [intermidateCheckedIds, setIntermediateCheckedIds] = useState<string[]>([])

  const admin_required_field = useLocaleText('admin_reqiured_field')
  const admin_uid_placeholder = useLocaleText('admin_uid_placeholder')
  const admin_first_name_placeholder = useLocaleText('admin_first_name_placeholder')
  const admin_last_name_placeholder = useLocaleText('admin_last_name_placeholder')
  const admin_desc_placeholder = useLocaleText('admin_desc_placeholder')
  const admin_sec_uid_placeholder = useLocaleText('admin_sec_uid_placeholder')
  const admin_email_placeholder = useLocaleText('admin_email_placeholder')
  const admin_password_placeholder = useLocaleText('admin_password_placeholder')
  const admin_phone_placeholder = useLocaleText('admin_phone_placeholder')
  const admin_select_placeholder = useLocaleText('admin_select_placeholder')

  const admin_uid = useLocaleText('admin_uid')
  const admin_first_name = useLocaleText('admin_first_name')
  const admin_last_name = useLocaleText('admin_last_name')
  const admin_desc = useLocaleText('admin_desc')
  const admin_sec_uid = useLocaleText('admin_sec_uid')
  const admin_email = useLocaleText('admin_email')
  const admin_password = useLocaleText('admin_password')
  const admin_country_code = useLocaleText('admin_country_code')
  const admin_phone_number = useLocaleText('admin_phone_number')
  const admin_role = useLocaleText('admin_role')
  const admin_enable_mfa = useLocaleText('admin_enable_mfa')
  const admin_language = useLocaleText('admin_language')
  const admin_permission = useLocaleText('admin_permission')
  const admin_generate_password = useLocaleText('admin_generate_password')
  const admin_submit_button = useLocaleText('admin_submit_button')

  const admin_email_error_message = useLocaleText('admin_email_error_message')
  const admin_uid_error_message = useLocaleText('admin_uid_error_message')
  const admin_password_error_message = useLocaleText('admin_password_error_message')
  const admin_phone_number_error_message = useLocaleText('admin_phone_number_error_message')

  const admin_password_tooltip = useLocaleText('change_password_policy')
  const admin_mfa_tooltip = useLocaleText('admin_mfa_tooltip')
  const admin_uid_tooltip = useLocaleText('admin_uid_tooltip')
  const admin_email_tooltip = useLocaleText('admin_email_tooltip')
  const admin_phone_number_tooltip = useLocaleText('admin_phone_number_tooltip')

  const admin_create_user_success = useLocaleText('admin_create_user_success')
  const admin_edit_user_success = useLocaleText('admin_edit_user_success')
  const admin_delete_group_success = useLocaleText('admin_delete_group_success')
  const admin_add_group_success = useLocaleText('admin_add_group_success')

  const fields = watch([
    'uid',
    'password',
    'first_name',
    'last_name',
    'email',
    'country_code',
    'phone_number',
    'enable_mfa',
    'locale',
    'permission',
    'default_group',
    'user_group_role',
    'desc',
    'sec_uid',
  ])

  const onSubmit = () => {
    onOpen()
  }
  const handleAddUser = () => {
    const formData = {
      ...fields,
      ...{ permission: Number(fields.permission) },
      ...{ user_group_role: Number(fields.user_group_role) },
    }

    const objectToPayload: IUserDetailedObject = Object.entries(formData).reduce((acc, [key, value]) => {
      if (value !== '') {
        acc[key as keyof any] = value
      }
      return acc
    }, {} as IUserDetailedObject)

    dispatch(createUser(objectToPayload))
    dispatch(setRegisteredUser(objectToPayload))
  }

  const handleEditUser = () => {
    const formData = {
      ...fields,
      ...{ permission: Number(fields.permission) },
      ...{ user_group_role: Number(fields.user_group_role) },
    }

    const exceptions = ['email', 'country_code', 'phone_number']
    const objectToPayload = { ...getDifferenceOfObjects(formData, detailedUser, exceptions), uid: uid }
    //@ts-ignore
    dispatch(updateUser(formData, objectToPayload))
  }
  const handleResetField = useCallback(
    (fieldName: string) => {
      setValue(fieldName, '')
    },
    [setValue]
  )
  const handleSetValue = useCallback(
    (fieldName: string, val: string | number) => {
      setValue(fieldName, val)
    },
    [setValue]
  )

  const disabledIds = useMemo(() => {
    if (selectedIds && selectedIds?.length > 0) {
      const setParents = []
      const setChildren = []
      for (const id of selectedIds) {
        setParents.push(...getParentsFromGroups(id, groups).map((parent) => parent.parent))
        setChildren.push(...getTreeNodesIds(groups, id))
      }

      return Array.from(new Set([...setParents, ...setChildren]))
    }
  }, [groups, selectedIds])

  const handleAddGroup = (groupId: string) => {
    if (detailedUser) {
      setGroupToAdd(groupId)
      dispatch(setIsFetching(true))
      dispatch(updateGroup({ groupId, added_users_ids: [detailedUser?.uid] }))
    }
  }
  const onSuccessHandleAddGroup = () => {
    setSelectedIds(intermidateCheckedIds)
    setGroupToAdd('')
  }
  const onSuccessHandleDeleteGroup = () => {
    const updatedIds = selectedIds?.filter((id: any) => id !== groupToDelete)
    setSelectedIds?.(updatedIds!)
    setGroupToDelete('')
  }

  const getActionLiteral = () => {
    if (groupToDelete) return admin_delete_group_success
    if (uid && !groupToAdd && !groupToDelete) return admin_edit_user_success
    if (groupToAdd) return admin_add_group_success
    else return admin_create_user_success
  }
  const handleClose = () => {
    setGroupToAdd('')
    setGroupToDelete('')
    onClose()
  }

  useEffect(() => {
    if (isActionSuccessful || isError) onOpen()
  }, [isActionSuccessful, isError, onOpen])

  useEffect(() => {
    if (uid) {
      dispatch(setIsFetching(true))
      dispatch(getUser(uid))
    }
    return () => {
      dispatch(setDetailedUser(null))
    }
  }, [dispatch, uid])

  useEffect(() => {
    if (detailedUser) {
      setSelectedIds(detailedUser?.groups)
      reset(detailedUser)
    }
  }, [detailedUser, reset])

  return (
    <Flex w="90%" mx="auto">
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <FormWrapper flexDir="column" justifyContent="space-around">
          <Flex alignItems="center">
            <Box>
              <AiOutlineUser size="120px" fill={theme?.backgroundMedium} />
            </Box>

            <Flex flexDir="column" mx="20px" style={{ rowGap: '10px' }}>
              <FormInput
                tooltip={admin_uid_tooltip}
                label={admin_uid}
                inInvalid={!!errors.uid}
                field="uid"
                requiredPlaceholder={admin_required_field}
                id="uid"
                placeholder={admin_uid_placeholder}
                icon={<FaUserCog />}
                regEx={permanentData.UID_REG_EX}
                errorMessage={admin_uid_error_message}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
              />

              <FormInput
                inInvalid={!!errors.password}
                tooltip={admin_password_tooltip}
                label={uid ? admin_generate_password : admin_password}
                field="password"
                requiredPlaceholder={uid ? '' : admin_required_field}
                id="password"
                placeholder={uid ? admin_generate_password : admin_password_placeholder}
                regEx={permanentData.PASSWORD_REG_EX}
                errorMessage={admin_password_error_message}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
              />

              <FormInput
                inInvalid={!!errors.first_name}
                label={admin_first_name}
                field="first_name"
                requiredPlaceholder={admin_required_field}
                id="first_name"
                placeholder={admin_first_name_placeholder}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
              />

              <FormInput
                field="last_name"
                id="last_name"
                placeholder={admin_last_name_placeholder}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
                inInvalid={!!errors.last_name}
                label={admin_last_name}
              />
              <FormInput
                field="desc"
                id="desc"
                placeholder={admin_desc_placeholder}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
                inInvalid={!!errors.desc}
                label={admin_desc}
              />
              <FormInput
                field="sec_uid"
                id="sec_uid"
                placeholder={admin_sec_uid_placeholder}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={errors}
                register={register}
                inInvalid={!!errors.sec_uid}
                label={admin_sec_uid}
              />
            </Flex>
          </Flex>
          <Flex alignItems="center">
            <Box mt="60px">
              <VscGear size="120px" fill={theme?.backgroundMedium} />
            </Box>
            <Flex flexDir="column" style={{ rowGap: '10px' }} mx="20px" mt="30px">
              <FormSelect
                field="locale"
                id="locale"
                error={errors}
                register={register}
                inInvalid={!!errors.locale}
                placeholder={admin_select_placeholder}
                requiredPlaceholder={admin_required_field}
                watch={watch}
                handleSetValue={handleSetValue}
                clearError={clearError}
                options={permanentData.LOCALE}
                disabled={false}
                label={admin_language}
              />

              <Text color="#a2a2b6" fontWeight="500">
                *&nbsp;
                <LocaleText text="admin_email_phone_rules" />
              </Text>

              <FormInput
                field="email"
                requiredPlaceholder={
                  (fields.phone_number || fields.country_code) && !fields.email ? '' : admin_required_field
                }
                id="email"
                placeholder={admin_email_placeholder}
                regEx={permanentData.EMAIL_REG_EX}
                errorMessage={admin_email_error_message}
                handleSetValue={handleSetValue}
                handleResetField={handleResetField}
                error={(fields.phone_number || fields.country_code) && !fields.email ? {} : errors}
                icon={<MdEmail />}
                register={register}
                inInvalid={(fields.phone_number || fields.country_code) && !fields.email ? false : !!errors.email}
                tooltip={admin_email_tooltip}
                label={admin_email}
              />

              <Flex flexDir="row" style={{ columnGap: '15px' }}>
                <FormSelect
                  field="country_code"
                  id="country_code"
                  error={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa ? {} : errors
                  }
                  register={register}
                  inInvalid={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa
                      ? false
                      : !!errors.country_code
                  }
                  placeholder={admin_select_placeholder}
                  requiredPlaceholder={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa
                      ? ''
                      : admin_required_field
                  }
                  watch={watch}
                  handleSetValue={handleSetValue}
                  clearError={clearError}
                  options={permanentData.COUNTRY_CODES_LIST}
                  disabled={false}
                  label={admin_country_code}
                />

                <FormInput
                  field="phone_number"
                  requiredPlaceholder={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa
                      ? ''
                      : admin_required_field
                  }
                  id="phone_number"
                  placeholder={admin_phone_placeholder}
                  regEx={permanentData.PHONE_NUMBER_REG_EX}
                  errorMessage={admin_phone_number_error_message}
                  handleSetValue={handleSetValue}
                  handleResetField={handleResetField}
                  error={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa ? {} : errors
                  }
                  icon={<BsTelephoneFill />}
                  register={register}
                  tooltip={admin_phone_number_tooltip}
                  label={admin_phone_number}
                  inInvalid={
                    fields.email && !fields.country_code && !fields.phone_number && !fields.enable_mfa
                      ? false
                      : !!errors.phone_number
                  }
                />
              </Flex>
              <Flex flexDir="row" wrap={'wrap'} style={{ columnGap: '15px' }}>
                <FormSelect
                  field="permission"
                  id="permission"
                  error={errors}
                  register={register}
                  inInvalid={!!errors.permission}
                  placeholder={admin_select_placeholder}
                  requiredPlaceholder={admin_required_field}
                  watch={watch}
                  handleSetValue={handleSetValue}
                  clearError={clearError}
                  options={permanentData.PERMISSIONS}
                  disabled={uid === myUid}
                  label={admin_permission}
                />
                <FormSelect
                  field="user_group_role"
                  id="row"
                  error={errors}
                  register={register}
                  inInvalid={!!errors.user_group_role}
                  placeholder={admin_select_placeholder}
                  requiredPlaceholder={admin_required_field}
                  watch={watch}
                  handleSetValue={handleSetValue}
                  clearError={clearError}
                  options={permanentData.ROLES}
                  disabled={uid === myUid}
                  label={admin_role}
                  defaultValue={uid ? undefined : fields.user_group_role}
                />
                <FormCheckbox
                  field="enable_mfa"
                  id="enable_mfa"
                  register={register}
                  label={admin_enable_mfa}
                  inInvalid={!!errors.enable_mfa}
                  tooltip={admin_mfa_tooltip}
                  isDisabled={!fields.email && !fields.phone_number && !fields.country_code}
                />
              </Flex>
              {selectedIds && selectedIds?.length > 0 && (
                <DefaultGroupSelect
                  selectedIds={selectedIds}
                  handleSetValue={handleSetValue}
                  field={'default_group'}
                  register={register}
                  watch={watch}
                  disabled={false}
                />
              )}
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end">
            <StyledConfirmButton isDisabled={isFetching} type="submit" themeColor={theme?.elementsColor}>
              {admin_submit_button}
            </StyledConfirmButton>
          </Flex>
          {uid && (
            <Flex alignItems="flex-start" justifyContent="flex-start" mt="30px">
              <Flex flexDir="column" alignItems="center">
                <Box mt="30px" mx="20px">
                  <HiUserGroup size="120px" fill={theme?.backgroundMedium} />
                </Box>
              </Flex>
              <GroupTagsList
                selectedIds={selectedIds}
                register={register}
                isShowDeleteBtn={true}
                handleSetValue={handleSetValue}
                setGroupToDelete={setGroupToDelete}
                onGroupsTreeOpen={onGroupsTreeOpen}
                onOpen={onOpen}
                field="default_group"
              />
            </Flex>
          )}
        </FormWrapper>
      </form>
      {isOpen && (
        <UsersActionModal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={handleClose}
          selectedIds={selectedIds}
          handleNextStep={handleNextStep}
          handleAddUser={handleAddUser}
          handleEditUser={handleEditUser}
          groupToDelete={groupToDelete}
          actionLiteral={getActionLiteral()}
          mode={groupToDelete ? 'delete_group' : uid !== undefined ? 'edit' : 'add'}
          route={!groupToAdd && !groupToDelete && uid !== undefined ? keys.ROUTE_NAMES.USERS_TABLE : ''}
          {...(groupToAdd || groupToDelete
            ? groupToAdd
              ? { onSuccessGroupChange: onSuccessHandleAddGroup }
              : { onSuccessGroupChange: onSuccessHandleDeleteGroup }
            : {})}
        />
      )}
      {isGroupsTreeOpen && (
        <GroupsTree
          setSelectedGroupID={setSelectedIds}
          selectedGroupID={selectedIds!}
          setIntermediateCheckedIds={setIntermediateCheckedIds}
          isOpen={isGroupsTreeOpen}
          onClose={onGroupsTreeClose}
          mode={strings.USERS_MODE}
          setGroupList={onGroupsTreeClose}
          handleAddGroup={handleAddGroup}
          treeGroups={groups}
          disabledIds={disabledIds}
        />
      )}
    </Flex>
  )
})
