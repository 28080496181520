/* eslint-disable react-hooks/exhaustive-deps */
import { httpAuth } from 'config/apiClient'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useToast } from '@chakra-ui/core'
import Resizer from 'react-image-file-resizer'
import { RootState } from 'constants/interfaces'
import { getFileName } from 'utils'
import endpoints from 'constants/endpoints'

const useUploadService = (isBase64 = false) => {
  const {
    retailConfig,
    config: { rid },
  } = useSelector((state: RootState) => state.config)

  const [uploadedImageURL, setUploadedImageURL] = useState<any>(null)
  const [isUploadLoading, setIsUploadLoading] = useState(false)
  const toast = useToast()

  function b64toBlob(dataURI: any) {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/jpeg' })
  }

  const b64toFile = (dataUrl: any) => {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], 'convert', { type: mime })
  }

  const compressImage = async (file: any): Promise<any> => {
    const compressConfig = retailConfig?.media_compression.images.general
    const maxSize = compressConfig?.resize.max_px_size || 1000
    const format = compressConfig?.format.toUpperCase() || 'JPEG'
    const quality = compressConfig?.compress ? compressConfig?.compress * 100 : 80

    const base64Data: any = await new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxSize,
        maxSize,
        format,
        quality,
        0, // Rotation
        (uri) => {
          resolve(uri)
        },
        'base64'
      )
    })
    return b64toBlob(base64Data)
  }

  const getUrlForUploadingFile = async (filename: string, extension?: string, isAvatar?: boolean) => {
    const {
      data: { fields, cdn_url, url },
    } = await httpAuth.get(endpoints.signedUrl, {
      params: {
        fileExt: extension,
        bucket: isAvatar ? 'users_avatars' : 'user_uploads',
        rid,
        filename,
      },
    })

    const fileLink = `${cdn_url}${fields.key}`
    return { fields, cdn_url, url, fileLink }
  }

  interface IFieldsForUploading {
    AWSAccessKeyId: string
    policy: string
    signature: string
    key: string
    ['Content-Type']: string
  }

  interface IUploadFileParams {
    file: File | string
    contentType?: string
    fields: IFieldsForUploading
    url: string
  }

  const uploadFileToS3 = async ({ file, fields, url }: IUploadFileParams) => {
    try {
      const formData = new FormData()

      formData.append('AWSAccessKeyId', fields.AWSAccessKeyId)
      formData.append('policy', fields.policy)
      if (fields['Content-Type']) {
        formData.append('Content-Type', fields['Content-Type'])
      }
      formData.append('signature', fields.signature)
      formData.append('key', fields.key)
      formData.append('file', file)

      await fetch(url, {
        method: 'POST',
        body: formData,
        mode: 'no-cors',
      })
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsUploadLoading(false)
    }
  }

  const uploadFile = async (file: File | string, extension?: string, isAvatar?: boolean): Promise<string> => {
    setIsUploadLoading(true)
    let filename = ''
    if (file instanceof File) {
      const fileName = getFileName(file.name)?.split('.')[0]!
      filename = fileName
    }
    const { fields, fileLink, url } = await getUrlForUploadingFile(filename, extension, isAvatar)
    try {
      await uploadFileToS3({ file, fields, url })

      return Promise.resolve(fileLink)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      setIsUploadLoading(false)
    }
  }

  const videoFileHandler = async (videoBlob: string) => {
    setIsUploadLoading(true)

    try {
      return await uploadFile(videoBlob, 'mp4')
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } finally {
      setIsUploadLoading(false)
    }
  }

  const imageFileUpload = async (file: File | string) => {
    setIsUploadLoading(true)
    try {
      let uploadedImageURL: string = ''

      const preparedFile = isBase64 ? b64toFile(file) : file

      const compressedFile = await compressImage(preparedFile)
      uploadedImageURL = await uploadFile(compressedFile)
      setUploadedImageURL(uploadedImageURL)

      return uploadedImageURL
    } catch (error) {
      toast({
        title: 'Something went wrong',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
      return ''
    } finally {
      setIsUploadLoading(false)
    }
  }

  return {
    imageFileUpload,
    videoFileHandler,
    uploadFile,
    uploadedImageURL,
    isUploadLoading,
    getUrlForUploadingFile,
    uploadFileToS3,
    compressImage,
  }
}

export default useUploadService
