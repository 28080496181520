import { PreviewFile } from 'config/types'
import { useFileValidation } from 'hooks/useFileValidation'
import useUploadService from 'hooks/useUploadService'
import React, { useCallback, useRef, useState } from 'react'
import { getFileExtension } from 'utils'
import imagePlaceholderAnimated from 'assets/placeholder-640.svg'
import { useToast } from '@chakra-ui/core'
import strings from 'constants/strings'

interface IUsePostMediaState {
  audios?: string[]
  images?: string[]
  videos?: string[]
  files?: string[]
}
export const useMediaState = ({ audios = [], images = [], videos = [], files = [] }: IUsePostMediaState) => {
  const toast = useToast()
  const { uploadFile, isUploadLoading } = useUploadService()
  const { fileSizeValidation } = useFileValidation()

  const imageInputRef = useRef<HTMLInputElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const formFileRef = useRef<HTMLFormElement>(null)

  const [audioRecords, setAudioRecords] = useState<string[]>(audios ?? [])
  const [uploadedImagesURL, setUploadedImagesURL] = useState<string[]>(images ?? [])
  const [uploadedVideosURL, setUploadedVideosURL] = useState<string[]>(videos ?? [])
  const [uploadedFilesURL, setUploadedFilesURL] = useState<string[]>(files ?? [])
  const [loading, setLoading] = useState(false)

  const media = [...uploadedImagesURL, ...uploadedVideosURL]

  const clearMediaState = () => {
    setAudioRecords([])
    setUploadedImagesURL([])
    setUploadedVideosURL([])
    setUploadedFilesURL([])
  }

  const addMediaHandler = () => {
    imageInputRef?.current?.click()
  }

  const handlerDeleteAllMedia = () => {
    setUploadedImagesURL([])
    setUploadedVideosURL([])
  }

  const handleDeleteMedia = useCallback(
    (mediaIdx: number) => {
      const mediaUrl = media[mediaIdx]
      setUploadedImagesURL((prev) => prev.filter((imageUrl) => imageUrl !== mediaUrl))
      setUploadedVideosURL((prev) => prev.filter((videoUrl) => videoUrl !== mediaUrl))
    },
    [media]
  )

  const removeAudioRecord = (audioSrc: string) => {
    setAudioRecords((audios) => audios.filter((audio) => audio !== audioSrc))
  }

  const deleteFileHandler = useCallback(
    (url: string) => {
      const filteredFileList = uploadedFilesURL.filter((file) => file !== url)
      setUploadedFilesURL(() => filteredFileList)
    },
    [uploadedFilesURL]
  )

  const sendAudioRecord = useCallback(
    async (audio: PreviewFile) => {
      const isValid = fileSizeValidation(audio.file)
      if (!isValid) return
      const extension = getFileExtension(audio.file.name).toLowerCase()
      const audioLink = await uploadFile(audio.file, extension)
      setAudioRecords((audioRecords) => [...audioRecords, audioLink])
    },
    [fileSizeValidation, uploadFile]
  )

  const handleFileUpload = useCallback(
    async (e: React.SyntheticEvent<HTMLInputElement>) => {
      const files = e.currentTarget.files
      if (files) {
        for (let file of Object.values(files)) {
          const isValid = fileSizeValidation(file)
          if (!isValid) return
          const fileExtension = getFileExtension(file.name)
          const fileUrl = await uploadFile(file, fileExtension)
          setUploadedFilesURL((files) => {
            return [...files, fileUrl]
          })
        }
      }
      formFileRef.current?.reset()
    },
    [fileSizeValidation, uploadFile]
  )

  const handleMediaUploading = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      setLoading(true)
      const files = Array.from(e.target.files)
      const placeholders = Array.from({ length: files?.length }, () => imagePlaceholderAnimated)
      setUploadedImagesURL((prev) => [...prev, ...placeholders])
      try {
        const uploadedImages: string[] = []
        const uploadedVideos: string[] = []
        for (let file of files) {
          const isValid = fileSizeValidation(file)
          if (!isValid) return
          const extension = getFileExtension(file.name).toLowerCase()
          const fileUrl = await uploadFile(file, extension)
          if (strings.SERVER_VIDEO_FORMATS.includes(extension)) {
            uploadedVideos.push(fileUrl)
          } else {
            uploadedImages.push(fileUrl)
          }
        }

        setUploadedImagesURL((prev) => {
          const filteredImages = prev.filter((img) => img !== imagePlaceholderAnimated)
          return [...filteredImages, ...uploadedImages]
        })
        setUploadedVideosURL((prev) => [...prev, ...uploadedVideos])
        return { uploadedImages: uploadedImages, uploadedVideos: uploadedVideos }
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setUploadedImagesURL((prev) => {
          const filteredImages = prev.filter((img) => img !== imagePlaceholderAnimated)
          return [...filteredImages]
        })
      } finally {
        setLoading(false)
      }
      formFileRef.current?.reset()
    }
  }
  const handleSingleMediaUploading = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0]
      const placeholder = imagePlaceholderAnimated
      setUploadedImagesURL([placeholder])
      try {
        const isValid = fileSizeValidation(file)
        if (!isValid) return
        const extension = getFileExtension(file.name).toLowerCase()
        const fileUrl = await uploadFile(file, extension)

        if (strings.SERVER_VIDEO_FORMATS.includes(extension)) {
          setUploadedVideosURL([fileUrl])
        } else {
          setUploadedImagesURL([fileUrl])
        }
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        setUploadedImagesURL([])
      }
      formFileRef.current?.reset()
    }
  }
  return {
    media,
    uploadedFilesURL,
    uploadedImagesURL,
    uploadedVideosURL,
    setUploadedImagesURL,
    audioRecords,
    handleFileUpload,
    handleMediaUploading,
    deleteFileHandler,
    removeAudioRecord,
    sendAudioRecord,
    isUploadLoading,
    handlerDeleteAllMedia,
    handleDeleteMedia,
    addMediaHandler,
    fileInputRef,
    imageInputRef,
    formFileRef,
    clearMediaState,
    handleSingleMediaUploading,
    loading,
  }
}
