import { theme } from '@chakra-ui/core'

export default {
  ...theme,
  colors: {
    ...theme.colors,
    tomato: { 500: '#e9586a' },
    gray2: { 500: '#90949C' },
    grayMedium: { 500: '#BDC1C9' },
  },
}
