export const LIST_MODES = [
  {
    title: 'create_post_tab',
    value: 1,
  },
  {
    title: 'create_pop_tab',
    value: 2,
  },
  {
    title: 'create_group_tab',
    value: 3,
  },
]

export enum OptionType {
  POST = 'POST',
  POP = 'POP',
  CHAT = 'CHAT',
  POLL = 'POLL',
}
