import { Divider, Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import React, { memo } from 'react'

export const SelectedItemsCount = memo(({ checkedIds, data }: any) => {
  return (
    <>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Text fontSize="16px" color={colors.greyDark} fontWeight="700">
          <LocaleText text="create_task_post_assignee_selected_text" />
          {` ${checkedIds.length} `}
          <LocaleText text="create_task_post_assignee_of_text" />
          {` ${data.length}`}
        </Text>
      </Flex>
      <Divider mt="10px" mb="2.5px" color={colors.greyMedium} />
    </>
  )
})
