import { Avatar, Box } from '@chakra-ui/core'
import React from 'react'
import { IconType } from 'react-icons'
import { MdGroup } from 'react-icons/md'

interface IProps {
  image?: string
  icon?: IconType
  size: string
  borderRadius?: string
  backgroundColor: string
  iconColor: string
}

export const SmartAvatar: React.FC<IProps> = ({
  image,
  icon = MdGroup,
  size,
  borderRadius = 'full',
  backgroundColor,
  iconColor,
}) => {
  return (
    <>
      {image ? (
        <Avatar src={image} w={size} h={size} />
      ) : (
        <Box as={icon} size={size} borderRadius={borderRadius} background={backgroundColor} color={iconColor} p="5px" />
      )}
    </>
  )
}
