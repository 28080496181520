import { Flex, Text, useDisclosure } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import keys from 'constants/keys'
import React, { useMemo } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import UsersActionModal from '../Modals'
import { useLocation } from 'react-router-dom'
import { TextLink } from './CustomComponents'
import { RootState } from 'constants/interfaces'

type IPathComponent = {
  path: string
}
export const PathComponent = ({ path }: IPathComponent) => {
  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { onClose, isOpen, onOpen } = useDisclosure()
  const location = useLocation()

  const resolvedPath = useMemo(() => {
    const parts = path.split('/').filter(Boolean)
    const result = []
    const handleClick = () => {
      if (location.pathname !== keys.ROUTE_NAMES.USERS_TABLE) onOpen()
    }

    if (parts.includes('admin-settings')) {
      result.push(
        <Text textTransform="capitalize">
          <LocaleText text="admin_console" />
        </Text>
      )

      if (parts.includes('users')) {
        result.push(
          <TextLink onClick={handleClick} cursor="pointer" color={theme?.elementsColor}>
            <LocaleText text="admin_users_list" />
          </TextLink>
        )

        if (parts.includes('add-user')) {
          result.push(
            <Text>
              <LocaleText text="admin_create_user" />
            </Text>
          )
        } else if (parts.includes('edit-user')) {
          result.push(
            <Text>
              <LocaleText text="admin_edit_user" />
            </Text>
          )
        }
      }
    }

    const resultWithChevrons = result.map((element, index) => (
      <React.Fragment key={index}>
        {element}
        {index !== result.length - 1 && (
          <>
            &nbsp; <HiChevronDoubleRight style={{ transform: `rotate(${180 * +isRtl}deg)` }} />
            &nbsp;
          </>
        )}
      </React.Fragment>
    ))
    return resultWithChevrons
  }, [isRtl, location.pathname, onOpen, path, theme])
  return (
    <Flex
      flexDir="row"
      fontWeight="600"
      fontSize="20px"
      mb="20px"
      h="38px"
      color={theme?.elementsColor}
      alignItems="center"
    >
      {resolvedPath}
      {isOpen && (
        <UsersActionModal
          closeOnOverlayClick={false}
          isOpen={isOpen}
          onClose={onClose}
          mode={'cancel'}
          actionLiteral="Are you sure you want to cancel precess?"
        />
      )}
    </Flex>
  )
}
