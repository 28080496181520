import { Avatar, Box, Flex, IconButton, Tag, Text, useDisclosure } from '@chakra-ui/core'
import { TooltipList } from 'components/CommonComponents/TooltipList'
import LocaleText from 'components/LocaleText'
import { PostModal } from 'components/NewsFeed/PostModal'
import { PostAdminsModal } from 'components/Post/PostAdminsModal'
import { PostMenu } from 'components/Post/PostHeader/PostMenu'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import { useUserName } from 'hooks/useUserName'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Post } from 'redux/reducers/feed'
import { isRtlSelector } from 'redux/selectors/general'

interface IChatHeader {
  currentChat: Post
  chatAudience: string
  trimmedAudience: string
}

export const ChatHeader = memo(({ currentChat, chatAudience, trimmedAudience }: IChatHeader) => {
  const { images, title, groups, users, post_id: chatId, created_by } = currentChat
  const { uid } = useSelector((state: RootState) => state.auth)
  const navigate = useNavigate()
  const isRtl = useSelector(isRtlSelector)
  const { isOpen: isEditPostModalOpen, onOpen: onEditPostModalOpen, onClose: onCloseEditPostModal } = useDisclosure()
  const { isOpen: isSetAdminModalOpen, onOpen: onSetAdminModalOpen, onClose: onCloseSetAdminModal } = useDisclosure()
  const chatCreator = useUserName(created_by)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { permission } = useSelector((state: RootState) => state.config.config)
  const isAdminPostPermission = permission?.posts === keys.USER_ACTIONS_MAP.APP_ADMIN
  return (
    <>
      <Flex
        justifyContent="space-between"
        px="10px"
        py="20px"
        alignItems="center"
        style={{ columnGap: '10px' }}
        w="100%"
      >
        <Flex alignItems="center" style={{ columnGap: '10px' }} position="relative" mx="20px">
          <IconButton
            icon={!isRtl ? 'chevron-left' : 'chevron-right'}
            fontSize="30px"
            position="absolute"
            top="10px"
            left={isRtl ? 'unset' : '-125px'}
            right={isRtl ? '-125px' : 'unset'}
            h="35px"
            w="35px"
            bg="none"
            borderRadius="full"
            minW="unset"
            onClick={() => navigate(-1)}
            aria-label="back"
            mx="20px"
          />
          <Avatar src={images?.[0]} w="50px" h="50px" />
          <Flex flexDir="column">
            <Text fontWeight="bold">{title}</Text>
            <Flex alignItems="center" py="5px">
              <Tag bg={theme?.elementsColor} color="white" minH="unset" borderRadius="15px" fontWeight="bold">
                <LocaleText text="search_feed_creator_filter" />
              </Tag>
              <Text fontWeight="bold" isTruncated maxW="450px">
                &nbsp;
                {chatCreator}
              </Text>
            </Flex>

            <TooltipList items={chatAudience.split(',')}>
              <Text>{trimmedAudience}</Text>
            </TooltipList>
          </Flex>
        </Flex>
        {(currentChat.created_by === uid || isAdminPostPermission) && (
          <Box mx="20px">
            <PostMenu
              isGroup={
                currentChat.type === keys.POST_TYPE.GROUP_POST ||
                currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT
              }
              groups={groups}
              users={users}
              post_id={chatId}
              editRole={created_by === uid}
              onEditPost={onEditPostModalOpen}
              isShowAdminsModalMenuItem={currentChat.type === keys.POST_TYPE.GROUP_POST_ANNOUNCEMENT}
              onSetAdminModalOpen={onSetAdminModalOpen}
              isAdminPostPermission={isAdminPostPermission}
            />
          </Box>
        )}
      </Flex>
      {isEditPostModalOpen && (
        <PostModal
          isOpen={isEditPostModalOpen}
          onClose={onCloseEditPostModal}
          postType={currentChat.type}
          postTitle={currentChat.title}
          isChat={true}
          isEditing={true}
          {...currentChat}
        />
      )}
      {isSetAdminModalOpen && (
        <PostAdminsModal isOpen={isSetAdminModalOpen} onClose={onCloseSetAdminModal} post={currentChat} />
      )}
    </>
  )
})
