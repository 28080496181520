import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import axios, { AxiosError } from 'axios'
import { Button, Flex, Input, Text, useToast } from '@chakra-ui/core'

import { LoginWrapper } from 'components/Login/LoginWrapper'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import authService from 'services/authService'
import endpoints from 'constants/endpoints'

export const LoginSSO = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const forgot_password_return_login_page = useLocaleText('forgot_password_return_login_page')
  const signInSSO = useLocaleText('sign_in_with_sso')
  const sso_verification = useLocaleText('sso_verification')
  const email = useLocaleText('Email')
  const continue_sso = useLocaleText('continue_sso')
  const [fetching, setFetching] = useState(false)

  const { register, handleSubmit, watch } = useForm()
  const userEmail = watch('email')

  const toastHandler = (status: number) => {
    switch (status) {
      case 200:
        toast({
          title: 'Success',
          description: 'Account veryfied successfully',
          status: 'success',
          duration: 4000,
          isClosable: true,
        })
        break

      case 404:
        {
          toast({
            title: 'Error',
            description: 'Account not found with the provided email. Please check your email and try again.',
            status: 'error',
            duration: 4000,
            isClosable: true,
          })
        }
        break

      default: {
        toast({
          title: 'Error',
          description: 'Something went wrong. Please try again later.',
          status: 'error',
          duration: 4000,
          isClosable: true,
        })
      }
    }
  }

  const handleMicrosoftSignIn = async (responseProvider: string) => {
    try {
      const res = await authService.federatedSignInWithMicrosoftOIDC(responseProvider)
      console.log(res)
    } catch (error) {
      console.error('Error signing in with Microsoft OIDC:', error)
    }
  }
  const handleContinueSSO = async () => {
    const url = endpoints.getProvider(userEmail)
    setFetching(true)

    try {
      const res = await axios.get(url)
      if (res.data) {
        await handleMicrosoftSignIn(res.data.provider)
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        toastHandler(error.response.status)
      }
      return Promise.reject(error)
    } finally {
      setFetching(false)
    }
  }

  return (
    <LoginWrapper
      isChangingPassword={true}
      header={
        <Flex flexDirection="column" alignItems="center">
          <Text fontSize="24px" marginBottom="20px" textAlign="center">
            {signInSSO}
          </Text>
          <Text fontSize="16px">{sso_verification}</Text>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit(handleContinueSSO)} autoComplete="nope">
        <Input
          id="email"
          name="email"
          resize={'none'}
          type="email"
          borderRadius="20px"
          style={{ minHeight: '0px', height: '2.5rem' }}
          placeholder={email}
          ref={register}
        />
        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          type="submit"
          isLoading={fetching}
          isDisabled={!userEmail || userEmail.trim() === ''}
        >
          {continue_sso}
        </Button>
        <Button
          onClick={() => navigate(keys.ROUTE_NAMES.LOGIN_PAGE)}
          width="100%"
          mt={4}
          isDisabled={fetching}
          style={{ background: 'none', color: '#d53f8c', borderRadius: '20px' }}
        >
          {forgot_password_return_login_page}
        </Button>
      </form>
    </LoginWrapper>
  )
}
