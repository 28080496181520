import React from 'react'
import { FileModalHeader } from 'components/FileModal/FileModalHeader'
import { Modal, ModalContent } from '@chakra-ui/core'
import { FileModalBody } from './FileModalBody'
import { FileModalFooter } from './FileModalFooter'
import { getFileExtension } from 'utils'

interface IProps {
  isOpen: boolean
  onClose: VoidFunction
  file?: File
  onSubmitFile: VoidFunction
}

export const FileModal = ({ isOpen, onClose, file, onSubmitFile }: IProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnEsc returnFocusOnClose={false}>
      <ModalContent borderRadius="15px">
        <FileModalHeader fileName={file?.name || ''} onClose={onClose} />
        <FileModalBody fileSize={file?.size || 0} fileExtension={getFileExtension(file?.name || '')} />
        <FileModalFooter submitHandler={onSubmitFile} />
      </ModalContent>
    </Modal>
  )
}
