import { extractValueByIndexType } from 'components/Reports/utils'
import { getFormattedStringValueWithSymbol } from 'hooks/useFormattedStringValue'
import { isNumber } from 'lodash'
import { ReportConfig, TextValue } from 'redux/reducers/reports'

export const extractNumberFromString = (value: string) => {
  const numericPart = value.replace(/[^\d.-]/g, '')
  const number = parseFloat(numericPart)
  return isNaN(number) ? null : number
}

export const universalSort = (rowA: any, rowB: any, columnId: number) => {
  let firstValue = rowA.values[columnId].value
  let secondValue = rowB.values[columnId].value

  let firstNumber = extractNumberFromString(firstValue)
  let secondNumber = extractNumberFromString(secondValue)

  if (firstNumber !== null && secondNumber !== null) {
    return firstNumber > secondNumber ? 1 : firstNumber < secondNumber ? -1 : 0
  }

  return firstValue.replace(' ', '').localeCompare(secondValue.replace(' ', ''))
}

export const customGlobalFilter = (rows: any, collumnId: number, filterValue: string) => {
  return rows.filter((row: any) => {
    return row.cells.some((cell: any) => {
      return cell?.value?.value !== undefined
        ? String(cell.value.value).toLowerCase().includes(filterValue.toLowerCase())
        : false
    })
  })
}

export const transformDataForTable = (
  reportData: any,
  tableRows: ReportConfig[],
  locale: string,
  translations: object,
  groupId?: TextValue | null,
  subRetailId?: TextValue | null
): any[] => {
  const transformedRows = reportData.map((report: any) => {
    const row = tableRows.map((tableRow) => {
      const properties = tableRow.properties
      let cellData: {
        value: any
        isTag: boolean
        groupId?: string | null
        subRetailId?: string | null
      } = {
        value: null,
        isTag: false,
        groupId: null,
        subRetailId: null,
      }

      if (properties) {
        const text: string = extractValueByIndexType(properties.text_val, report)
        let numVal: number = extractValueByIndexType(properties.num_val, report)
        const numValCurrSymbol: string | null = extractValueByIndexType(properties.num_curr_symbol, report) ?? null
        const numValSymbolType: number | null = extractValueByIndexType(properties.num_symbol_type, report)
        const numValIsRound = properties?.num_is_round ?? false
        const compValue: number | null = extractValueByIndexType(properties.compare_val, report) ?? null
        const compValueCurrSymbol: string | null =
          extractValueByIndexType(properties.compare_curr_symbol, report) ?? null
        const compValueSymbolType: number | null = extractValueByIndexType(properties?.compare_symbol_type, report)
        const compValueOffset: number | null = extractValueByIndexType(properties?.compare_offset, report)
        const subRetailIdValue: string | null = extractValueByIndexType(subRetailId, report) ?? null
        const groupIdValue: string | null = extractValueByIndexType(groupId, report) ?? null

        if (text) {
          cellData.value = translations[locale][text] ? translations[locale][text] : text
          cellData.subRetailId = subRetailIdValue
          cellData.groupId = groupIdValue
        }

        if (isNumber(numVal)) {
          cellData.value = getFormattedStringValueWithSymbol(
            numVal,
            numValCurrSymbol,
            numValSymbolType,
            numValIsRound,
            translations,
            locale
          )
          cellData.subRetailId = subRetailIdValue
          cellData.groupId = groupIdValue
        }

        if (isNumber(compValue)) {
          cellData.value = getFormattedStringValueWithSymbol(
            compValue + (compValueOffset ?? 0),
            compValueCurrSymbol,
            compValueSymbolType,
            numValIsRound,
            translations,
            locale
          )
          cellData.isTag = true
          cellData.subRetailId = subRetailIdValue
          cellData.groupId = groupIdValue
        }

        if (!text && numVal === null && compValue === null) {
          cellData.value = ''
          cellData.subRetailId = subRetailIdValue
          cellData.groupId = groupIdValue
        }
      }

      return cellData
    })

    return row
  })

  return transformedRows
}
