import React from 'react'
import { Image, Flex, Spinner } from '@chakra-ui/core'

import LogoImage from 'assets/logo.svg'

interface IProps {
  isShow: boolean
}

const MainLoader: React.FC<IProps> = ({ isShow }) => {

  return (
    <Flex
      height='100vh'
      width="100%"
      alignItems="center"
      justifyContent="center"
      position="fixed"
      zIndex={1000000}
      transition={isShow ? "none" : ".5s ease-in-out"}
      top="0"
      left="0"
      backgroundColor="#fff"
      overflow="hidden"
      dir="ltr"
      visibility={isShow ? 'visible' : 'hidden'}
      opacity={isShow ? 1 : 0}
    >
      <Image mr="20px" height="50px" src={LogoImage} />
      <Spinner size="lg" />
    </Flex>
  )
}

export default MainLoader