import { calculateTaskStatus } from 'components/Tasks/utils'
import { RootState, Task } from 'constants/interfaces'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { extractSubstringAfterDelimiter } from 'utils'
import { CircleTask } from './CircleTask'
import { DraggableButton } from 'components/CommonComponents/DraggableButton'
import { isRtlSelector } from 'redux/selectors/general'
import { Box, useDisclosure } from '@chakra-ui/core'
import { PollSubTasksModals } from 'components/PollTask/PollTaskModal'
import { calculateContextID } from 'redux/actions/tasks_v2'
const TASK_Y_OFFSET = 100

export const DraggableTasks = () => {
  const { tasks, subtasks, userResults } = useSelector((state: RootState) => state.tasks_v2)
  const { isAllPostCollapsed } = useSelector((state: RootState) => state.feed)
  const { uid } = useSelector((state: RootState) => state.auth)
  const {
    config: { rid, retailUsersObject, tags },
    activeGroupID,
  } = useSelector((state: RootState) => state.config)
  const isRtl = useSelector(isRtlSelector)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedTask, setSelectedTask] = useState<Task | null>(null)
  const [maxItems, setMaxItems] = useState(8)

  const selectedTaskcontextId = useMemo(() => {
    if (!selectedTask) return null
    return calculateContextID({
      recipient_type: selectedTask?.recipient_type!,
      is_individual_results: selectedTask?.is_individual_results!,
      uid: uid!,
      groupId: activeGroupID!,
      tagId:
        selectedTask?.recipient_type === 2
          ? extractSubstringAfterDelimiter(selectedTask?.retail_id_context_id, rid)
          : null,
      retail_id_context_id: selectedTask?.retail_id_context_id!,
      rid,
      tags,
    })
  }, [activeGroupID, rid, selectedTask, tags, uid])

  const tasksDueInNext24Hours = useMemo(
    () =>
      tasks
        .map((task) => {
          const contextId = calculateContextID({
            recipient_type: task?.recipient_type!,
            is_individual_results: task?.is_individual_results!,
            uid: uid!,
            groupId: activeGroupID!,
            tagId: task?.recipient_type === 2 ? extractSubstringAfterDelimiter(task?.retail_id_context_id, rid) : null,
            retail_id_context_id: task?.retail_id_context_id!,
            rid,
            tags,
          })

          const taskStatus = calculateTaskStatus(task, subtasks, userResults, contextId)
          return {
            ...task,
            taskStatus: taskStatus,
          }
        })
        .filter((task) => task.show_task_bubble)
        .sort((a, b) => {
          const hoursUntilEndA = a.hours_until_end
          const hoursUntilEndB = b.hours_until_end
          return hoursUntilEndA - hoursUntilEndB
        })
        .slice(0, maxItems),
    [activeGroupID, maxItems, rid, subtasks, tags, tasks, uid, userResults]
  )

  const onCircleClick = (task: Task) => {
    setSelectedTask(task)
    onOpen()
  }
  const onCloseModal = () => {
    setSelectedTask(null)
    onClose()
  }

  useEffect(() => {
    const updateMaxItems = () => {
      const height = window.innerHeight
      if (height >= 1200) {
        setMaxItems(9)
      } else if (height > 992) {
        setMaxItems(6)
      } else if (height >= 768) {
        setMaxItems(4)
      } else {
        setMaxItems(3)
      }
    }
    updateMaxItems()

    window.addEventListener('resize', updateMaxItems)

    return () => window.removeEventListener('resize', updateMaxItems)
  }, [])

  return (
    <>
      {tasksDueInNext24Hours.map((task, index) => {
        const { taskStatus, hours_until_end } = task
        return (
          <Box key={task.tid} opacity={selectedTask ? (task.tid === selectedTask.tid ? 1 : 0.5) : 1}>
            <DraggableButton
              isRtl={isRtl}
              posY={(index * TASK_Y_OFFSET) / window.innerHeight}
              posX={isAllPostCollapsed ? 0.67 : 0.43}
              callback={() => onCircleClick(task)}
              isShowShadow={false}
            >
              <CircleTask
                status={taskStatus}
                houraUntilEnd={hours_until_end}
                avatar={retailUsersObject?.[task.created_by]?.profile_img_url ?? ''}
              />
            </DraggableButton>
          </Box>
        )
      })}
      {selectedTask && selectedTaskcontextId && isOpen && (
        <PollSubTasksModals
          isOpen={isOpen}
          onClose={onCloseModal}
          task={selectedTask}
          contextId={selectedTaskcontextId}
        />
      )}
    </>
  )
}
