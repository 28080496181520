// eslint-disable-next-nine @typescript-eslint/no-use-before-define
import React from 'react'
import { Box, Flex, Divider, useDisclosure, Button, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import colors from '../../constants/colors'
import NavMenu, { IconBlock } from './NavMenu'
import { NavItem } from './NavMenu'
import { useSelector } from 'react-redux'
import keys from 'constants/keys'
import { RootState } from 'constants/interfaces'
import { ChooseContext } from './ChooseContext'
import LocaleText from 'components/LocaleText'
import { useLocation } from 'react-router-dom'
import { ReactComponent as Settings } from 'assets/icon-admin-settings.svg'

const StyledSidebar = styled(Box)<{ isHebrew: boolean }>`
  width: 20%;
  position: sticky;
  top: 0;
  z-index: 3;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const SidebarContent = styled(Flex)`
  top: 4rem;
  position: relative;
`

const Sidebar = () => {
  const selectedRoute = useSelector((state: RootState) => state.general.selectedRoute)
  const { locale } = useSelector((state: RootState) => state.general)
  const { pathname } = useLocation()
  const { isReportLoading } = useSelector((state: RootState) => state.reports)
  const { activeGroupID } = useSelector((state: RootState) => state.config)
  const { groups, permission } = useSelector((state: RootState) => state.config.config)
  const { isOpen: isOpenGroupsTree, onOpen: onOpenGroupsTree, onClose: onCloseGroupsTree } = useDisclosure()
  const isHebrew = locale === 'he'

  return (
    <StyledSidebar as="aside" bg={colors.greyMedium} p="20px" isHebrew={isHebrew}>
      <SidebarContent justifyContent="space-between" flexDirection="column" h="calc(100vh - 90px)">
        <Flex flexDir="column">
          <NavMenu />
          <Divider mb="20px" />
          {selectedRoute !== keys.ROUTE_NAMES.DASHBOARD && (
            <>
              <Button
                borderRadius="20px"
                mx="4"
                fontWeight="600"
                color={colors.blackLight}
                borderColor={colors.greyLight}
                backgroundColor="white"
                width="max-content"
                minWidth="170px"
                variant="outline"
                onClick={onOpenGroupsTree}
                type="submit"
                isDisabled={pathname === keys.ROUTE_NAMES.OPEN_GROUPTREE || isReportLoading}
                _disabled={{
                  background: colors.greyLight,
                  color: colors.greyLight2,
                }}
              >
                <LocaleText text={groups?.[activeGroupID || '']?.name || ''} />
              </Button>
            </>
          )}
        </Flex>
        {permission?.users === 3 && (
          <NavItem to={`${keys.ROUTE_NAMES.ADMIN_SETTINGS}/users`}>
            <IconBlock>
              <Settings style={{ fill: colors.blackLight }} />
            </IconBlock>
            <Text mx="5" textTransform="capitalize">
              <LocaleText text="admin_console" />
            </Text>
          </NavItem>
        )}
        {isOpenGroupsTree && <ChooseContext isOpen={isOpenGroupsTree} onClose={onCloseGroupsTree} />}
      </SidebarContent>
    </StyledSidebar>
  )
}

export default Sidebar
