import React from 'react'
import { Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'

export const NothingToShow = () => {
  return (
    <Text fontFamily="inherit" fontWeight="600" fontSize="14px">
      <LocaleText text="reports_archive_empty" />
    </Text>
  )
}
