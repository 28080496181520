import { Button, Icon, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { BiExport } from 'react-icons/bi'
import { useSelector } from 'react-redux'

export default function ExportToExcellButton({
  handleExport,
  isLoading,
  isDisabled = false,
}: {
  handleExport: () => void
  isLoading: boolean
  isDisabled?: boolean
}) {
  const { locale } = useSelector((state: RootState) => state.general)
  return (
    <Button
      mr={{
        base: locale === 'he' ? '3' : '0',
      }}
      ml={{
        base: locale === 'he' ? '0' : '3',
      }}
      px="6"
      py="3"
      borderRadius="10px"
      backgroundColor="#fff"
      border="1px solid rgba(170, 176, 188, 0.5)"
      onClick={handleExport}
      isLoading={isLoading}
      isDisabled={isDisabled}
      _focus={{ outline: 'none' }}
    >
      <Icon as={BiExport} size="24px" color={colors.greyMain} />
      <Text
        fontSize=".875rem"
        fontFamily="Asap, sans-serif"
        fontWeight="400"
        color={colors.blackLight}
        ml={locale === 'he' ? '0' : '2'}
        mr={locale === 'he' ? '2' : '0'}
      >
        <LocaleText text="dashboard_export_excel_button" />
      </Text>
    </Button>
  )
}
