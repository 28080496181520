import { Box, Flex } from '@chakra-ui/core'
import React, { memo } from 'react'
import { ReactComponent as ViewedIconActive } from '../../assets/visibility_black_24dp_filled.svg'
import { ReactComponent as ViewedIcon } from '../../assets/visibility_black_24dp.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface IProps {
  views_counter: number
  viewed: { [key: string]: boolean }
  created_by: string
  openModal?: () => void
  isShowViewIcon: boolean
  isViewed: boolean
  isAllPostCollapsed?: boolean
  isDoublePost?: boolean
}
export const ViewButton = memo(
  ({
    views_counter,
    viewed,
    created_by,
    openModal,
    isShowViewIcon,
    isViewed,
    isAllPostCollapsed,
    isDoublePost,
  }: IProps) => {
    const { theme } = useSelector((state: RootState) => state.general)
    return (
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        cursor="pointer"
        style={{ columnGap: '3px' }}
        w={isAllPostCollapsed && !isDoublePost ? '30%' : '10%'}
        onClick={(e) => {
          e.stopPropagation()
          openModal?.()
        }}
      >
        <Box cursor="pointer" maxW="70%" minW="50%" textAlign="end" fontSize="13px">
          {views_counter}/{Object.keys(viewed).filter((userId) => userId !== created_by).length || 0}
        </Box>
        {isShowViewIcon ? (
          isViewed ? (
            <ViewedIconActive width="17px" height="17px" fill={theme?.elementsColor} />
          ) : (
            <ViewedIcon width="17px" height="17px" opacity={0.6} />
          )
        ) : (
          <ViewedIcon width="17px" height="17px" opacity={0.1} />
        )}
      </Flex>
    )
  }
)
