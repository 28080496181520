import { RootState } from 'constants/interfaces'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const useLocaleText = (text: string) => {
  const [translation, setTranslation] = useState(text)
  const translations = useSelector((state: RootState) => state.config.translations)
  const locale = useSelector((state: RootState) => state.general.locale)

  useEffect(() => {
    if (translations) {
      setTranslation(translations[locale!] && translations[locale!][text] ? translations[locale!][text] : text)
    }
  }, [locale, text, translations])
  return translation
}

export default useLocaleText
