import loadImage from 'blueimp-load-image'

export const imageToBase64 = (imageFile, is_canvas = true, orientation = true, maxWidth = 600, quality = 0.5) => {
  return new Promise((resolve) => {
    /*** accepts a File or Blob object or a simple image URL ***/
    loadImage(
      imageFile,
      (canvas) => {
        resolve(canvas.toDataURL('image/jpeg', quality))
        // const image = base64dataz.replace(/^data\:image\/\w+\;base64\,/, '')
      },
      { canvas: is_canvas, orientation: orientation, maxWidth: maxWidth }
    )
  })
}

export const dataURItoBlob = (dataURI) => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(',')[1])

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length)
  var ia = new Uint8Array(ab)
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  // write the ArrayBuffer to a blob, and you're done
  var bb = new Blob([ab])
  return bb
}

const IMAGE_FILE_TYPES = ['image/gif', 'image/jpeg', 'image/jpg', 'image/png']
const VIDEO_FILE_TYPES = ['video/mp4']
const BASE64 = 'base64'

class FileService {
  static isImageFile(file) {
    let res = false
    if (IMAGE_FILE_TYPES.includes(file['type'])) {
      res = true
    }
    return res
  }

  static imageToBase64(file) {
    if (this.isImageFile(file)) {
      return imageToBase64(file)
    } else {
      const errorMessage = 'not an image file'
      throw errorMessage
    }
  }

  static isBase64(file) {
    try {
      const fileSuffix = file.split(',')
      const tempFileSuffix = fileSuffix[0].split(';')
      return BASE64 === tempFileSuffix[1].trim()
    } catch (e) {
      return false
    }
  }

  static isVideoFile(file) {
    return VIDEO_FILE_TYPES.includes(file['type'])
  }

  static base64ToBytes(file) {
    if (this.isBase64(file)) {
      return dataURItoBlob(file)
    }
  }

  static dataURLtoFile = (dataUrl, filename) => {
    const arr = dataUrl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
}

export default FileService
