import React from 'react'
import { Link, LinkProps } from '@chakra-ui/core'
import { AiOutlineWhatsApp } from 'react-icons/ai'
import colors from 'constants/colors'
import { UserMenuItem } from 'components/CommonComponents/UserMenu/UserMenuItem'

export const WhatsAppButton = (props: LinkProps) => {
  return (
    <Link
      target="_blank"
      display="flex"
      alignItems="center"
      _hover={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      width="100%"
      {...props}
    >
      <UserMenuItem icon={<AiOutlineWhatsApp fill={colors.greenDark} />} literal="contact_button" />
    </Link>
  )
}
