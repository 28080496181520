import React from 'react'
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Box,
  Divider,
  Button,
  useDisclosure,
  Flex,
  Text,
} from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import NavMenu, { IconBlock, NavItem } from 'components/Sidebar/NavMenu'
import { CustomModalClose } from 'components/Elements'
import colors from 'constants/colors'
import keys from 'constants/keys'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { ChooseContext } from './ChooseContext'
import { ReactComponent as SettingsPink } from 'assets/icon-settings-circle.svg'

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const MobileMenu: React.FC<IProps> = ({ isOpen, onClose }) => {
  const {
    activeGroupID,
    config: { permission },
  } = useSelector((state: RootState) => state.config)
  const { groups } = useSelector((state: RootState) => state.config.userWritePermittedContext)
  const { pathname } = useLocation()
  const { isOpen: isOpenGroupsTree, onOpen: onOpenGroupsTree, onClose: onCloseGroupsTree } = useDisclosure()

  const handleOpenGroupTree = () => {
    onOpenGroupsTree()
  }
  return (
    <Drawer isOpen={isOpen} size="xs" placement="left" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent display="flex" flexDir="column" justifyContent="space-between">
        <Flex flexDir="column">
          <CustomModalClose onClick={onClose} />
          <DrawerHeader p="3">
            <LocaleText text="mobile_menu_header" />
          </DrawerHeader>
          <DrawerBody p="3" height="200px">
            <NavMenu />
            <Divider mt="10%" />
            <Box mt="10%" textAlign="center">
              <Button
                borderRadius="20px"
                mx="4"
                fontWeight="600"
                color={colors.blackLight}
                borderColor={colors.greyLight}
                backgroundColor="white"
                width="max-content"
                minWidth="170px"
                variant="outline"
                onClick={handleOpenGroupTree}
                type="submit"
                isDisabled={pathname === keys.ROUTE_NAMES.OPEN_GROUPTREE}
                _disabled={{
                  background: colors.greyLight,
                  color: colors.greyLight2,
                }}
              >
                <LocaleText text={groups?.[activeGroupID || '']?.name || ''} />
              </Button>
              {isOpenGroupsTree && <ChooseContext isOpen={isOpenGroupsTree} onClose={onCloseGroupsTree} />}
            </Box>
          </DrawerBody>
        </Flex>
        {permission?.users === 3 && (
          <NavItem to={`${keys.ROUTE_NAMES.ADMIN_SETTINGS}/users`} style={{ padding: '0.75rem' }}>
            <IconBlock>
              <SettingsPink />
            </IconBlock>
            <Text mx="5" textTransform="capitalize">
              <LocaleText text="admin_settings" />
            </Text>
          </NavItem>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default MobileMenu
