import { Text, Box, Flex } from '@chakra-ui/core'
import React from 'react'
import { ReactComponent as TasksLinkIcon } from 'assets/icon-tasks-link.svg'
import SubTaskImagePlaceholder from 'assets/icon-capture-white-fallback.png'
import UploadFileImage from 'assets/uploadfil.png'
import { PollTaskWrapper } from 'components/helperStyles'
import keys from 'constants/keys'
import { Result } from 'constants/interfaces'
import { ImageSlider } from '../ImageSlider'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { IoMdImages } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import colors from 'constants/colors'

interface Props {
  type: number
  title: string
  st_id: string
  index: number
  subTaskResult?: Result
  onSubTaskClick: (args: { st_id: string }) => void
}

const getSubTaskAvatar = (type: number, color: string = 'white') => {
  switch (type) {
    case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
      return <IoMdImages size={46} color={color} />
    case keys.SUBTASK_TYPES.UPLOAD_FILE_SUBTASK:
      return UploadFileImage
    case keys.SUBTASK_TYPES.LINK_SUBTASK:
      return <TasksLinkIcon width="40px" height="40px" />
    default:
      return SubTaskImagePlaceholder
  }
}

const DoneCicrle = () => <Box w="25px" h="25px" bg={colors.greenPoll} borderRadius="full" />

export const SubTaskV2 = ({ type, st_id, title, onSubTaskClick, subTaskResult, index }: Props) => {
  const isRtl = useSelector(isRtlSelector)
  const handleSubTaskClick = () => {
    onSubTaskClick({ st_id })
  }

  return (
    <>
      {!subTaskResult || subTaskResult?.status === 1 ? (
        <PollTaskWrapper
          flexDir="column"
          p={'10px'}
          mt={index === 0 ? '60px' : '90px'}
          padding="15px"
          pos="relative"
          style={{ overflow: 'unset !important' }}
          onClick={handleSubTaskClick}
          cursor="pointer"
        >
          <Flex
            justifyContent="center"
            alignItems="center"
            pos="absolute"
            w="90px"
            h="90px"
            top="-40px"
            left="40%"
            border="1px solid white"
            bg={type === keys.SUBTASK_TYPES.LINK_SUBTASK ? 'white' : 'black'}
            borderRadius="full"
            boxShadow="0px 2px 7px -2px grey"
          >
            {getSubTaskAvatar(type)}
          </Flex>

          <Text textAlign="center" fontSize="16px" mt="40px" isTruncated>
            {title}
          </Text>
        </PollTaskWrapper>
      ) : (
        <>
          {subTaskResult?.images && subTaskResult?.images.length > 0 && (
            <Flex flexDir="column" mt="60px">
              <Box borderRadius="20px" overflow="hidden" border="1px solid #f6f5f5">
                <ImageSlider
                  images={subTaskResult?.images ?? []}
                  callback={handleSubTaskClick}
                  height="300px"
                  objectFit="contain"
                />
              </Box>
            </Flex>
          )}
          {subTaskResult?.sub_task_type === keys.SUBTASK_TYPES.LINK_SUBTASK && (
            <PollTaskWrapper
              flexDir="column"
              p={'10px'}
              mt="90px"
              padding="15px"
              pos="relative"
              style={{ overflow: 'unset !important' }}
              onClick={handleSubTaskClick}
              cursor="pointer"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                pos="absolute"
                w="90px"
                h="90px"
                top="-40px"
                left="40%"
                border="1px solid white"
                bg="white"
                borderRadius="full"
                boxShadow="0px 2px 7px -2px grey"
              >
                {getSubTaskAvatar(type)}
                <Box pos="absolute" top="65px" right={isRtl ? 'unset' : '0px'} left={isRtl ? '0px' : 'unset'}>
                  <DoneCicrle />
                </Box>
              </Flex>

              <Flex mt="40px" alignItems="center" justifyContent="center" style={{ columnGap: '10px' }}>
                <Text textAlign="center" fontSize="16px" isTruncated>
                  {title}
                </Text>
              </Flex>
            </PollTaskWrapper>
          )}
        </>
      )}
    </>
  )
}
