import { Box, Text, FormErrorMessage } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import React from 'react'
import { FieldError } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

export const ErrorMessage = ({ error, id }: any) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <Box>
      {error[id] ? (
        <FormErrorMessage flexDir={isRtl ? 'row-reverse' : 'row'}>
          {<LocaleText text={error[id] && (error[id] as FieldError).message} />}
        </FormErrorMessage>
      ) : (
        <Text mt="25px"></Text>
      )}
    </Box>
  )
}
