import { Avatar, AvatarGroup, Flex, Text } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import { useUserName } from 'hooks/useUserName'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'

interface User {
  profile_img_url?: string
}

const AVATAR_SIZES = ['5rem', '4rem', '3rem', '2rem']
const Z_INDEXES = [500, 400, 300, 200]

const getUserAvatar = (
  user: User | undefined,
  size: string,
  zIndex: number,
  positionStyle: React.CSSProperties = {},
  borderWidth: string = '3px'
) => (
  <Avatar
    src={user?.profile_img_url || ''}
    w={size}
    h={size}
    zIndex={zIndex}
    border={`${borderWidth} solid #fff`}
    style={positionStyle}
    pos="absolute"
  />
)

interface GroupAvatarProps {
  users: string[]
  retailUsersObject: Record<string, User>
}

const GroupAvatar = ({ users, retailUsersObject }: GroupAvatarProps) => {
  switch (users.length) {
    case 1:
      return (
        <AvatarGroup w="5rem" h="5rem" pos="relative">
          {getUserAvatar(retailUsersObject[users[0]], AVATAR_SIZES[0], Z_INDEXES[0])}
        </AvatarGroup>
      )

    case 2:
      return (
        <AvatarGroup w="5rem" h="5rem" pos="relative">
          {getUserAvatar(retailUsersObject[users[0]], AVATAR_SIZES[0], Z_INDEXES[0])}
          {getUserAvatar(
            retailUsersObject[users[1]],
            AVATAR_SIZES[1],
            Z_INDEXES[2],
            { right: '55px', top: '19px' },
            '2px'
          )}
        </AvatarGroup>
      )

    case 3:
      return (
        <AvatarGroup w="5rem" h="5rem" pos="relative">
          {getUserAvatar(retailUsersObject[users[0]], AVATAR_SIZES[0], Z_INDEXES[0])}
          {getUserAvatar(
            retailUsersObject[users[1]],
            AVATAR_SIZES[1],
            Z_INDEXES[1],
            { right: '55px', top: '19px' },
            '2px'
          )}
          {getUserAvatar(
            retailUsersObject[users[2]],
            AVATAR_SIZES[2],
            Z_INDEXES[2],
            { left: '72px', top: '36px' },
            '1px'
          )}
        </AvatarGroup>
      )

    default:
      return (
        <AvatarGroup w="5rem" h="5rem" pos="relative">
          {getUserAvatar(retailUsersObject[users[0]], AVATAR_SIZES[0], Z_INDEXES[0])}
          {getUserAvatar(
            retailUsersObject[users[1]],
            AVATAR_SIZES[1],
            Z_INDEXES[1],
            { right: '55px', top: '19px' },
            '2px'
          )}
          {getUserAvatar(
            retailUsersObject[users[2]],
            AVATAR_SIZES[2],
            Z_INDEXES[2],
            { left: '72px', top: '36px' },
            '1px'
          )}
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
            zIndex={Z_INDEXES[3]}
            backgroundColor="gray.300"
            color="black"
            pos="absolute"
            left="108px"
            top="24px"
            dir="ltr"
            style={{ width: '40px', height: '40px' }}
          >
            <Text as="span" fontSize="12px">
              +{users.length - 3}
            </Text>
          </Flex>
        </AvatarGroup>
      )
  }
}

interface GroupManagersProps {
  group: string
}

// GroupManagers Component
export const GroupAvatars = memo(({ group }: GroupManagersProps) => {
  const { mappedRetailUsersByGroups, retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const usersInGroup = mappedRetailUsersByGroups?.[group] ?? []
  const managerFullName = useUserName(usersInGroup?.[0])

  if (!usersInGroup || usersInGroup.length === 0 || !retailUsersObject) return null

  return (
    <Flex flexDir="column" alignItems="center" px="40px">
      <GroupAvatar users={usersInGroup} retailUsersObject={retailUsersObject} />
      <Text fontSize="18px" mt={usersInGroup.length > 1 ? '10px' : '5px'}>
        {managerFullName}
      </Text>
    </Flex>
  )
})
