import keys from 'constants/keys'

export interface FormField {
  id: number
  placeholder: string
  label: string
  subTasks: SubTask[]
}
export interface SubTask {
  title: string
  is_required: boolean
  category: string
  type: number
  external_link?: string
  placeholder?: string
}

export const initialFormFields: FormField[] = [
  {
    id: keys.SUBTASK_TYPES.IMAGE_SUBTASK,
    label: 't_ask_for_pic',
    placeholder: 't_pls_add_picture',
    subTasks: [],
  },
  {
    id: keys.SUBTASK_TYPES.LINK_SUBTASK,
    placeholder: 't_link_title',
    label: 't_ask_for_link',
    subTasks: [],
  },
]

export const getCategoryById = (id: number) => {
  switch (id) {
    case keys.SUBTASK_TYPES.IMAGE_SUBTASK:
      return 'Image'
    case keys.SUBTASK_TYPES.VIDEO_SUBTASKS:
      return 'Video'
    case keys.SUBTASK_TYPES.LINK_SUBTASK:
      return 'Link'
    case keys.SUBTASK_TYPES.TEXT_SUBTASK:
      return 'Text'
    default:
      return ''
  }
}
