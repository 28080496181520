import { Avatar, Flex, Text } from '@chakra-ui/core'
import React from 'react'

export const CellAvatarBlock = ({ row }: any) => {
  const profileImage =
    row.original.profile_img_url ||
    'https://avatars.mds.yandex.net/i?id=02f4bfea70a77cfd866d4ed59b59f5008d967304-8209285-images-thumbs&n=13'
  const firstName = row.original.first_name
  const lastName = row.original.last_name

  return (
    <Flex flexDir="row" w="200px" alignItems="center" style={{ columnGap: '10px' }}>
      <Avatar
        src={profileImage}
        style={{
          borderRadius: '50%',
          width: '60px',
          height: '60px',
          objectFit: 'cover',
        }}
      />

      <Flex flexDir="column" fontWeight="600" color="#9a9ab4" style={{ columnGap: '10px' }}>
        <Text as="span">{firstName}</Text>
        <Text as="span">{lastName}</Text>
      </Flex>
    </Flex>
  )
}
