import React from 'react'
import { Flex, ModalHeader, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import { CustomModalClose } from 'components/Elements'
import { getFileExtension, getFileNameWithoutExtension } from 'utils'

interface IProps {
  fileName: string
  onClose: VoidFunction
}

export const FileModalHeader = ({ fileName, onClose }: IProps) => {
  return (
    <ModalHeader display="flex" justifyContent="space-between" background={colors.greyLight}>
      <Flex mx="auto" fontSize="16px" maxWidth="80%">
        <Text isTruncated>{getFileNameWithoutExtension(fileName)}</Text>
        <Text>.{getFileExtension(fileName)}</Text>
      </Flex>
      <CustomModalClose onClick={onClose} />
    </ModalHeader>
  )
}
