/* eslint-disable quotes */
export default {
  COUNTRY_CODES_LIST: [
    {
      name: 'Afghanistan',
      id: '93',
      code: 'AF',
      avatar: '🇦🇫',
    },
    {
      name: 'Albania',
      id: '355',
      code: 'AL',
      avatar: '🇦🇱',
    },
    {
      name: 'Algeria',
      id: '213',
      code: 'DZ',
      avatar: '🇩🇿',
    },
    {
      name: 'AmericanSamoa',
      id: '1684',
      code: 'AS',
      avatar: '🇦🇸',
    },
    {
      name: 'Andorra',
      id: '376',
      code: 'AD',
      avatar: '🇦🇩',
    },
    {
      name: 'Angola',
      id: '244',
      code: 'AO',
      avatar: '🇦🇴',
    },
    {
      name: 'Anguilla',
      id: '1264',
      code: 'AI',
      avatar: '🇦🇮',
    },
    {
      name: 'Antarctica',
      id: '672',
      code: 'AQ',
      avatar: '🇦🇶',
    },
    {
      name: 'Antigua and Barbuda',
      id: '1268',
      code: 'AG',
      avatar: '🇦🇬',
    },
    {
      name: 'Argentina',
      id: '54',
      code: 'AR',
      avatar: '🇦🇷',
    },
    {
      name: 'Armenia',
      id: '374',
      code: 'AM',
      avatar: '🇦🇲',
    },
    {
      name: 'Aruba',
      id: '297',
      code: 'AW',
      avatar: '🇦🇼',
    },
    {
      name: 'Australia',
      id: '61',
      code: 'AU',
      avatar: '🇦🇺',
    },
    {
      name: 'Austria',
      id: '43',
      code: 'AT',
      avatar: '🇦🇹',
    },
    {
      name: 'Azerbaijan',
      id: '994',
      code: 'AZ',
      avatar: '🇦🇿',
    },
    {
      name: 'Bahamas',
      id: '1242',
      code: 'BS',
      avatar: '🇧🇸',
    },
    {
      name: 'Bahrain',
      id: '973',
      code: 'BH',
      avatar: '🇧🇭',
    },
    {
      name: 'Bangladesh',
      id: '880',
      code: 'BD',
      avatar: '🇧🇩',
    },
    {
      name: 'Barbados',
      id: '1246',
      code: 'BB',
      avatar: '🇧🇧',
    },
    {
      name: 'Belarus',
      id: '375',
      code: 'BY',
      avatar: '🇧🇾',
    },
    {
      name: 'Belgium',
      id: '32',
      code: 'BE',
      avatar: '🇧🇪',
    },
    {
      name: 'Belize',
      id: '501',
      code: 'BZ',
      avatar: '🇧🇿',
    },
    {
      name: 'Benin',
      id: '229',
      code: 'BJ',
      avatar: '🇧🇯',
    },
    {
      name: 'Bermuda',
      id: '1441',
      code: 'BM',
      avatar: '🇧🇲',
    },
    {
      name: 'Bhutan',
      id: '975',
      code: 'BT',
      avatar: '🇧🇹',
    },
    {
      name: 'Bolivia, Plurinational State of',
      id: '591',
      code: 'BO',
      avatar: '🇧🇴',
    },
    {
      name: 'Bosnia and Herzegovina',
      id: '387',
      code: 'BA',
      avatar: '🇧🇦',
    },
    {
      name: 'Botswana',
      id: '267',
      code: 'BW',
      avatar: '🇧🇼',
    },
    {
      name: 'Brazil',
      id: '55',
      code: 'BR',
      avatar: '🇧🇷',
    },
    {
      name: 'British Indian Ocean Territory',
      id: '246',
      code: 'IO',
      avatar: '🇮🇴',
    },
    {
      name: 'Brunei Darussalam',
      id: '673',
      code: 'BN',
      avatar: '🇧🇳',
    },
    {
      name: 'Bulgaria',
      id: '359',
      code: 'BG',
      avatar: '🇧🇬',
    },
    {
      name: 'Burkina Faso',
      id: '226',
      code: 'BF',
      avatar: '🇧🇫',
    },
    {
      name: 'Burundi',
      id: '257',
      code: 'BI',
      avatar: '🇧🇮',
    },
    {
      name: 'Cambodia',
      id: '855',
      code: 'KH',
      avatar: '🇰🇭',
    },
    {
      name: 'Cameroon',
      id: '237',
      code: 'CM',
      avatar: '🇨🇲',
    },
    {
      name: 'Canada',
      id: '1',
      code: 'CA',
      avatar: '🇨🇦',
    },
    {
      name: 'Cape Verde',
      id: '238',
      code: 'CV',
      avatar: '🇨🇻',
    },
    {
      name: 'Cayman Islands',
      id: '345',
      code: 'KY',
      avatar: '🇰🇾',
    },
    {
      name: 'Central African Republic',
      id: '236',
      code: 'CF',
      avatar: '🇨🇫',
    },
    {
      name: 'Chad',
      id: '235',
      code: 'TD',
      avatar: '🇹🇩',
    },
    {
      name: 'Chile',
      id: '56',
      code: 'CL',
      avatar: '🇨🇱',
    },
    {
      name: 'China',
      id: '86',
      code: 'CN',
      avatar: '🇨🇳',
    },
    {
      name: 'Christmas Island',
      id: '61',
      code: 'CX',
      avatar: '🇨🇽',
    },
    {
      name: 'Cocos (Keeling) Islands',
      id: '61',
      code: 'CC',
      avatar: '🇨🇨',
    },
    {
      name: 'Colombia',
      id: '57',
      code: 'CO',
      avatar: '🇨🇴',
    },
    {
      name: 'Comoros',
      id: '269',
      code: 'KM',
      avatar: '🇰🇲',
    },
    {
      name: 'Congo',
      id: '242',
      code: 'CG',
      avatar: '🇨🇬',
    },
    {
      name: 'Congo, The Democratic Republic of the',
      id: '243',
      code: 'CD',
      avatar: '🇨🇩',
    },
    {
      name: 'Cook Islands',
      id: '682',
      code: 'CK',
      avatar: '🇨🇰',
    },
    {
      name: 'Costa Rica',
      id: '506',
      code: 'CR',
      avatar: '🇨🇷',
    },
    {
      name: "Cote d'Ivoire",
      id: '225',
      code: 'CI',
      avatar: '🇨🇮',
    },
    {
      name: 'Croatia',
      id: '385',
      code: 'HR',
      avatar: '🇭🇷',
    },
    {
      name: 'Cuba',
      id: '53',
      code: 'CU',
      avatar: '🇨🇺',
    },
    {
      name: 'Cyprus',
      id: '357',
      code: 'CY',
      avatar: '🇨🇾',
    },
    {
      name: 'Czech Republic',
      id: '420',
      code: 'CZ',
      avatar: '🇨🇿',
    },
    {
      name: 'Curaçao',
      id: '599',
      code: 'CW',
      avatar: '🇨🇼',
    },
    {
      name: 'Canary Islands',
      id: '34',
      code: 'IC',
      avatar: '🇮🇨',
    },
    {
      name: 'Denmark',
      id: '45',
      code: 'DK',
      avatar: '🇩🇰',
    },
    {
      name: 'Djibouti',
      id: '253',
      code: 'DJ',
      avatar: '🇩🇯',
    },
    {
      name: 'Dominica',
      id: '1767',
      code: 'DM',
      avatar: '🇩🇲',
    },
    {
      name: 'Dominican Republic',
      id: '1849',
      code: 'DO',
      avatar: '🇩🇴',
    },
    {
      name: 'Dominican Republic',
      id: '1829',
      code: 'DO',
      avatar: '🇩🇴',
    },
    {
      name: 'Dominican Republic',
      id: '1809',
      code: 'DO',
      avatar: '🇩🇴',
    },
    {
      name: 'Ecuador',
      id: '593',
      code: 'EC',
      avatar: '🇪🇨',
    },
    {
      name: 'Egypt',
      id: '20',
      code: 'EG',
      avatar: '🇪🇬',
    },
    {
      name: 'El Salvador',
      id: '503',
      code: 'SV',
      avatar: '🇸🇻',
    },
    {
      name: 'Equatorial Guinea',
      id: '240',
      code: 'GQ',
      avatar: '🇬🇶',
    },
    {
      name: 'Eritrea',
      id: '291',
      code: 'ER',
      avatar: '🇪🇷',
    },
    {
      name: 'Estonia',
      id: '372',
      code: 'EE',
      avatar: '🇪🇪',
    },
    {
      name: 'Ethiopia',
      id: '251',
      code: 'ET',
      avatar: '🇪🇹',
    },
    {
      name: 'Falkland Islands (Malvinas)',
      id: '500',
      code: 'FK',
      avatar: '🇫🇰',
    },
    {
      name: 'Faroe Islands',
      id: '298',
      code: 'FO',
      avatar: '🇫🇴',
    },
    {
      name: 'Fiji',
      id: '679',
      code: 'FJ',
      avatar: '🇫🇯',
    },
    {
      name: 'Finland',
      id: '358',
      code: 'FI',
      avatar: '🇫🇮',
    },
    {
      name: 'France',
      id: '33',
      code: 'FR',
      avatar: '🇫🇷',
    },
    {
      name: 'French Guiana',
      id: '594',
      code: 'GF',
      avatar: '🇬🇫',
    },
    {
      name: 'French Polynesia',
      id: '689',
      code: 'PF',
      avatar: '🇵🇫',
    },
    {
      name: 'Gabon',
      id: '241',
      code: 'GA',
      avatar: '🇬🇦',
    },
    {
      name: 'Gambia',
      id: '220',
      code: 'GM',
      avatar: '🇬🇲',
    },
    {
      name: 'Georgia',
      id: '995',
      code: 'GE',
      avatar: '🇬🇪',
    },
    {
      name: 'Germany',
      id: '49',
      code: 'DE',
      avatar: '🇩🇪',
    },
    {
      name: 'Ghana',
      id: '233',
      code: 'GH',
      avatar: '🇬🇭',
    },
    {
      name: 'Gibraltar',
      id: '350',
      code: 'GI',
      avatar: '🇬🇮',
    },
    {
      name: 'Greece',
      id: '30',
      code: 'GR',
      avatar: '🇬🇷',
    },
    {
      name: 'Greenland',
      id: '299',
      code: 'GL',
      avatar: '🇬🇱',
    },
    {
      name: 'Grenada',
      id: '1473',
      code: 'GD',
      avatar: '🇬🇩',
    },
    {
      name: 'Guadeloupe',
      id: '590',
      code: 'GP',
      avatar: '🇬🇵',
    },
    {
      name: 'Guam',
      id: '1671',
      code: 'GU',
      avatar: '🇬🇺',
    },
    {
      name: 'Guatemala',
      id: '502',
      code: 'GT',
      avatar: '🇬🇹',
    },
    {
      name: 'Guernsey',
      id: '44',
      code: 'GG',
      avatar: '🇬🇬',
    },
    {
      name: 'Guinea',
      id: '224',
      code: 'GN',
      avatar: '🇬🇳',
    },
    {
      name: 'Guinea-Bissau',
      id: '245',
      code: 'GW',
      avatar: '🇬🇼',
    },
    {
      name: 'Guyana',
      id: '592',
      code: 'GY',
      avatar: '🇬🇾',
    },
    {
      name: 'Haiti',
      id: '509',
      code: 'HT',
      avatar: '🇭🇹',
    },
    {
      name: 'Holy See (Vatican City State)',
      id: '379',
      code: 'VA',
      avatar: '🇻🇦',
    },
    {
      name: 'Honduras',
      id: '504',
      code: 'HN',
      avatar: '🇭🇳',
    },
    {
      name: 'Hong Kong',
      id: '852',
      code: 'HK',
      avatar: '🇭🇰',
    },
    {
      name: 'Hungary',
      id: '36',
      code: 'HU',
      avatar: '🇭🇺',
    },
    {
      name: 'Iceland',
      id: '354',
      code: 'IS',
      avatar: '🇮🇸',
    },
    {
      name: 'India',
      id: '91',
      code: 'IN',
      avatar: '🇮🇳',
    },
    {
      name: 'Indonesia',
      id: '62',
      code: 'ID',
      avatar: '🇮🇩',
    },
    {
      name: 'Iran, Islamic Republic of',
      id: '98',
      code: 'IR',
      avatar: '🇮🇷',
    },
    {
      name: 'Iraq',
      id: '964',
      code: 'IQ',
      avatar: '🇮🇶',
    },
    {
      name: 'Ireland',
      id: '353',
      code: 'IE',
      avatar: '🇮🇪',
    },
    {
      name: 'Isle of Man',
      id: '44',
      code: 'IM',
      avatar: '🇮🇲',
    },
    {
      name: 'Israel',
      id: '972',
      code: 'IL',
      avatar: '🇮🇱',
    },
    {
      name: 'Italy',
      id: '39',
      code: 'IT',
      avatar: '🇮🇹',
    },
    {
      name: 'Jamaica',
      id: '1876',
      code: 'JM',
      avatar: '🇯🇲',
    },
    {
      name: 'Jamaica',
      id: '1658',
      code: 'JM',
      avatar: '🇯🇲',
    },
    {
      name: 'Japan',
      id: '81',
      code: 'JP',
      avatar: '🇯🇵',
    },
    {
      name: 'Jersey',
      id: '44',
      code: 'JE',
      avatar: '🇯🇪',
    },
    {
      name: 'Jordan',
      id: '962',
      code: 'JO',
      avatar: '🇯🇴',
    },
    {
      name: 'Kazakhstan',
      id: '77',
      code: 'KZ',
      avatar: '🇰🇿',
    },
    {
      name: 'Kenya',
      id: '254',
      code: 'KE',
      avatar: '🇰🇪',
    },
    {
      name: 'Kiribati',
      id: '686',
      code: 'KI',
      avatar: '🇰🇮',
    },
    {
      name: "Korea, Democratic People's Republic of",
      id: '850',
      code: 'KP',
      avatar: '🇰🇵',
    },
    {
      name: 'Korea, Republic of',
      id: '82',
      code: 'KR',
      avatar: '🇰🇷',
    },
    {
      name: 'Kuwait',
      id: '965',
      code: 'KW',
      avatar: '🇰🇼',
    },
    {
      name: 'Kyrgyzstan',
      id: '996',
      code: 'KG',
      avatar: '🇰🇬',
    },
    {
      name: 'Kosovo',
      id: '383',
      code: 'XK',
      avatar: '🇽🇰',
    },
    {
      name: "Lao People's Democratic Republic",
      id: '856',
      code: 'LA',
      avatar: '🇱🇦',
    },
    {
      name: 'Latvia',
      id: '371',
      code: 'LV',
      avatar: '🇱🇻',
    },
    {
      name: 'Lebanon',
      id: '961',
      code: 'LB',
      avatar: '🇱🇧',
    },
    {
      name: 'Lesotho',
      id: '266',
      code: 'LS',
      avatar: '🇱🇸',
    },
    {
      name: 'Liberia',
      id: '231',
      code: 'LR',
      avatar: '🇱🇷',
    },
    {
      name: 'Libyan Arab Jamahiriya',
      id: '218',
      code: 'LY',
      avatar: '🇱🇾',
    },
    {
      name: 'Liechtenstein',
      id: '423',
      code: 'LI',
      avatar: '🇱🇮',
    },
    {
      name: 'Lithuania',
      id: '370',
      code: 'LT',
      avatar: '🇱🇹',
    },
    {
      name: 'Luxembourg',
      id: '352',
      code: 'LU',
      avatar: '🇱🇺',
    },
    {
      name: 'Macao',
      id: '853',
      code: 'MO',
      avatar: '🇲🇴',
    },
    {
      name: 'Macedonia, The Former Yugoslav Republic of',
      id: '389',
      code: 'MK',
      avatar: '🇲🇰',
    },
    {
      name: 'Madagascar',
      id: '261',
      code: 'MG',
      avatar: '🇲🇬',
    },
    {
      name: 'Malawi',
      id: '265',
      code: 'MW',
      avatar: '🇲🇼',
    },
    {
      name: 'Malaysia',
      id: '60',
      code: 'MY',
      avatar: '🇲🇾',
    },
    {
      name: 'Maldives',
      id: '960',
      code: 'MV',
      avatar: '🇲🇻',
    },
    {
      name: 'Mali',
      id: '223',
      code: 'ML',
      avatar: '🇲🇱',
    },
    {
      name: 'Malta',
      id: '356',
      code: 'MT',
      avatar: '🇲🇹',
    },
    {
      name: 'Marshall Islands',
      id: '692',
      code: 'MH',
      avatar: '🇲🇭',
    },
    {
      name: 'Martinique',
      id: '596',
      code: 'MQ',
      avatar: '🇲🇶',
    },
    {
      name: 'Mauritania',
      id: '222',
      code: 'MR',
      avatar: '🇲🇷',
    },
    {
      name: 'Mauritius',
      id: '230',
      code: 'MU',
      avatar: '🇲🇺',
    },
    {
      name: 'Mayotte',
      id: '262',
      code: 'YT',
      avatar: '🇾🇹',
    },
    {
      name: 'Mexico',
      id: '52',
      code: 'MX',
      avatar: '🇲🇽',
    },
    {
      name: 'Micronesia, Federated States of',
      id: '691',
      code: 'FM',
      avatar: '🇫🇲',
    },
    {
      name: 'Moldova, Republic of',
      id: '373',
      code: 'MD',
      avatar: '🇲🇩',
    },
    {
      name: 'Monaco',
      id: '377',
      code: 'MC',
      avatar: '🇲🇨',
    },
    {
      name: 'Mongolia',
      id: '976',
      code: 'MN',
      avatar: '🇲🇳',
    },
    {
      name: 'Montenegro',
      id: '382',
      code: 'ME',
      avatar: '🇲🇪',
    },
    {
      name: 'Montserrat',
      id: '1664',
      code: 'MS',
      avatar: '🇲🇸',
    },
    {
      name: 'Morocco',
      id: '212',
      code: 'MA',
      avatar: '🇲🇦',
    },
    {
      name: 'Mozambique',
      id: '258',
      code: 'MZ',
      avatar: '🇲🇿',
    },
    {
      name: 'Myanmar',
      id: '95',
      code: 'MM',
      avatar: '🇲🇲',
    },
    {
      name: 'Namibia',
      id: '264',
      code: 'NA',
      avatar: '🇳🇦',
    },
    {
      name: 'Nauru',
      id: '674',
      code: 'NR',
      avatar: '🇳🇷',
    },
    {
      name: 'Nepal',
      id: '977',
      code: 'NP',
      avatar: '🇳🇵',
    },
    {
      name: 'Netherlands',
      id: '31',
      code: 'NL',
      avatar: '🇳🇱',
    },
    {
      name: 'Caribbean Netherlands',
      id: '599',
      code: 'BQ',
      avatar: '🇧🇶',
    },
    {
      name: 'New Caledonia',
      id: '687',
      code: 'NC',
      avatar: '🇳🇨',
    },
    {
      name: 'New Zealand',
      id: '64',
      code: 'NZ',
      avatar: '🇳🇿',
    },
    {
      name: 'Nicaragua',
      id: '505',
      code: 'NI',
      avatar: '🇳🇮',
    },
    {
      name: 'Niger',
      id: '227',
      code: 'NE',
      avatar: '🇳🇪',
    },
    {
      name: 'Nigeria',
      id: '234',
      code: 'NG',
      avatar: '🇳🇬',
    },
    {
      name: 'Niue',
      id: '683',
      code: 'NU',
      avatar: '🇳🇺',
    },
    {
      name: 'Norfolk Island',
      id: '672',
      code: 'NF',
      avatar: '🇳🇫',
    },
    {
      name: 'Northern Mariana Islands',
      id: '1670',
      code: 'MP',
      avatar: '🇲🇵',
    },
    {
      name: 'Norway',
      id: '47',
      code: 'NO',
      avatar: '🇳🇴',
    },
    {
      name: 'Oman',
      id: '968',
      code: 'OM',
      avatar: '🇴🇲',
    },
    {
      name: 'Pakistan',
      id: '92',
      code: 'PK',
      avatar: '🇵🇰',
    },
    {
      name: 'Palau',
      id: '680',
      code: 'PW',
      avatar: '🇵🇼',
    },
    {
      name: 'Palestinian Territory, Occupied',
      id: '970',
      code: 'PS',
      avatar: '🇵🇸',
    },
    {
      name: 'Panama',
      id: '507',
      code: 'PA',
      avatar: '🇵🇦',
    },
    {
      name: 'Papua New Guinea',
      id: '675',
      code: 'PG',
      avatar: '🇵🇬',
    },
    {
      name: 'Paraguay',
      id: '595',
      code: 'PY',
      avatar: '🇵🇾',
    },
    {
      name: 'Peru',
      id: '51',
      code: 'PE',
      avatar: '🇵🇪',
    },
    {
      name: 'Philippines',
      id: '63',
      code: 'PH',
      avatar: '🇵🇭',
    },
    {
      name: 'Pitcairn',
      id: '872',
      code: 'PN',
      avatar: '🇵🇳',
    },
    {
      name: 'Poland',
      id: '48',
      code: 'PL',
      avatar: '🇵🇱',
    },
    {
      name: 'Portugal',
      id: '351',
      code: 'PT',
      avatar: '🇵🇹',
    },
    {
      name: 'Puerto Rico',
      id: '1939',
      code: 'PR',
      avatar: '🇵🇷',
    },
    {
      name: 'Puerto Rico',
      id: '1787',
      code: 'PR',
      avatar: '🇵🇷',
    },
    {
      name: 'Qatar',
      id: '974',
      code: 'QA',
      avatar: '🇶🇦',
    },
    {
      name: 'Romania',
      id: '40',
      code: 'RO',
      avatar: '🇷🇴',
    },
    {
      name: 'Russia',
      id: '7',
      code: 'RU',
      avatar: '🇷🇺',
    },
    {
      name: 'Rwanda',
      id: '250',
      code: 'RW',
      avatar: '🇷🇼',
    },
    {
      name: 'Réunion',
      id: '262',
      code: 'RE',
      avatar: '🇷🇪',
    },
    {
      name: 'Saint Barthélemy',
      id: '590',
      code: 'BL',
      avatar: '🇧🇱',
    },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      id: '290',
      code: 'SH',
      avatar: '🇸🇭',
    },
    {
      name: 'Saint Kitts and Nevis',
      id: '1869',
      code: 'KN',
      avatar: '🇰🇳',
    },
    {
      name: 'Saint Lucia',
      id: '1758',
      code: 'LC',
      avatar: '🇱🇨',
    },
    {
      name: 'Saint Martin',
      id: '590',
      code: 'MF',
      avatar: '🇲🇫',
    },
    {
      name: 'Saint Pierre and Miquelon',
      id: '508',
      code: 'PM',
      avatar: '🇵🇲',
    },
    {
      name: 'Saint Vincent and the Grenadines',
      id: '1784',
      code: 'VC',
      avatar: '🇻🇨',
    },
    {
      name: 'Samoa',
      id: '685',
      code: 'WS',
      avatar: '🇼🇸',
    },
    {
      name: 'San Marino',
      id: '378',
      code: 'SM',
      avatar: '🇸🇲',
    },
    {
      name: 'Sao Tome and Principe',
      id: '239',
      code: 'ST',
      avatar: '🇸🇹',
    },
    {
      name: 'Saudi Arabia',
      id: '966',
      code: 'SA',
      avatar: '🇸🇦',
    },
    {
      name: 'Senegal',
      id: '221',
      code: 'SN',
      avatar: '🇸🇳',
    },
    {
      name: 'Serbia',
      id: '381',
      code: 'RS',
      avatar: '🇷🇸',
    },
    {
      name: 'Seychelles',
      id: '248',
      code: 'SC',
      avatar: '🇸🇨',
    },
    {
      name: 'Sierra Leone',
      id: '232',
      code: 'SL',
      avatar: '🇸🇱',
    },
    {
      name: 'Singapore',
      id: '65',
      code: 'SG',
      avatar: '🇸🇬',
    },
    {
      name: 'Slovakia',
      id: '421',
      code: 'SK',
      avatar: '🇸🇰',
    },
    {
      name: 'Slovenia',
      id: '386',
      code: 'SI',
      avatar: '🇸🇮',
    },
    {
      name: 'Solomon Islands',
      id: '677',
      code: 'SB',
      avatar: '🇸🇧',
    },
    {
      name: 'Somalia',
      id: '252',
      code: 'SO',
      avatar: '🇸🇴',
    },
    {
      name: 'South Africa',
      id: '27',
      code: 'ZA',
      avatar: '🇿🇦',
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      id: '500',
      code: 'GS',
      avatar: '🇬🇸',
    },
    {
      name: 'Spain',
      id: '34',
      code: 'ES',
      avatar: '🇪🇸',
    },
    {
      name: 'Sri Lanka',
      id: '94',
      code: 'LK',
      avatar: '🇱🇰',
    },
    {
      name: 'Sudan',
      id: '249',
      code: 'SD',
      avatar: '🇸🇩',
    },
    {
      name: 'South Sudan',
      id: '211',
      code: 'SS',
      avatar: '🇸🇸',
    },
    {
      name: 'Suriname',
      id: '597',
      code: 'SR',
      avatar: '🇸🇷',
    },
    {
      name: 'Svalbard and Jan Mayen',
      id: '47',
      code: 'SJ',
      avatar: '🇸🇯',
    },
    {
      name: 'Swaziland',
      id: '268',
      code: 'SZ',
      avatar: '🇸🇿',
    },
    {
      name: 'Sweden',
      id: '46',
      code: 'SE',
      avatar: '🇸🇪',
    },
    {
      name: 'Switzerland',
      id: '41',
      code: 'CH',
      avatar: '🇨🇭',
    },
    {
      name: 'Syrian Arab Republic',
      id: '963',
      code: 'SY',
      avatar: '🇸🇾',
    },
    {
      name: 'Sint Maarten',
      id: '721',
      code: 'SX',
      avatar: '🇸🇽',
    },
    {
      name: 'Taiwan, Province of China',
      id: '886',
      code: 'TW',
      avatar: '🇹🇼',
    },
    {
      name: 'Tajikistan',
      id: '992',
      code: 'TJ',
      avatar: '🇹🇯',
    },
    {
      name: 'Tanzania, United Republic of',
      id: '255',
      code: 'TZ',
      avatar: '🇹🇿',
    },
    {
      name: 'Thailand',
      id: '66',
      code: 'TH',
      avatar: '🇹🇭',
    },
    {
      name: 'Timor-Leste',
      id: '670',
      code: 'TL',
      avatar: '🇹🇱',
    },
    {
      name: 'Togo',
      id: '228',
      code: 'TG',
      avatar: '🇹🇬',
    },
    {
      name: 'Tokelau',
      id: '690',
      code: 'TK',
      avatar: '🇹🇰',
    },
    {
      name: 'Tonga',
      id: '676',
      code: 'TO',
      avatar: '🇹🇴',
    },
    {
      name: 'Trinidad and Tobago',
      id: '1868',
      code: 'TT',
      avatar: '🇹🇹',
    },
    {
      name: 'Tunisia',
      id: '216',
      code: 'TN',
      avatar: '🇹🇳',
    },
    {
      name: 'Turkey',
      id: '90',
      code: 'TR',
      avatar: '🇹🇷',
    },
    {
      name: 'Turkmenistan',
      id: '993',
      code: 'TM',
      avatar: '🇹🇲',
    },
    {
      name: 'Turks and Caicos Islands',
      id: '1649',
      code: 'TC',
      avatar: '🇹🇨',
    },
    {
      name: 'Tuvalu',
      id: '688',
      code: 'TV',
      avatar: '🇹🇻',
    },
    {
      name: 'Uganda',
      id: '256',
      code: 'UG',
      avatar: '🇺🇬',
    },
    {
      name: 'Ukraine',
      id: '380',
      code: 'UA',
      avatar: '🇺🇦',
    },
    {
      name: 'United Arab Emirates',
      id: '971',
      code: 'AE',
      avatar: '🇦🇪',
    },
    {
      name: 'United Kingdom',
      id: '44',
      code: 'GB',
      avatar: '🇬🇧',
    },
    {
      name: 'United States',
      id: '1',
      code: 'US',
      avatar: '🇺🇸',
    },
    {
      name: 'Uruguay',
      id: '598',
      code: 'UY',
      avatar: '🇺🇾',
    },
    {
      name: 'Uzbekistan',
      id: '998',
      code: 'UZ',
      avatar: '🇺🇿',
    },
    {
      name: 'Vanuatu',
      id: '678',
      code: 'VU',
      avatar: '🇻🇺',
    },
    {
      name: 'Venezuela, Bolivarian Republic of',
      id: '58',
      code: 'VE',
      avatar: '🇻🇪',
    },
    {
      name: 'Viet Nam',
      id: '84',
      code: 'VN',
      avatar: '🇻🇳',
    },
    {
      name: 'Virgin Islands, British',
      id: '1284',
      code: 'VG',
      avatar: '🇻🇬',
    },
    {
      name: 'Virgin Islands, U.S.',
      id: '1340',
      code: 'VI',
      avatar: '🇻🇮',
    },
    {
      name: 'Wallis and Futuna',
      id: '681',
      code: 'WF',
      avatar: '🇼🇫',
    },
    {
      name: 'Western Sarah',
      id: '212',
      code: 'EH',
      avatar: '🇪🇭',
    },
    {
      name: 'Yemen',
      id: '967',
      code: 'YE',
      avatar: '🇾🇪',
    },
    {
      name: 'Zambia',
      id: '260',
      code: 'ZM',
      avatar: '🇿🇲',
    },
    {
      name: 'Zimbabwe',
      id: '263',
      code: 'ZW',
      avatar: '🇿🇼',
    },
    {
      name: 'Åland Islands',
      id: '358',
      code: 'AX',
      avatar: '🇦🇽',
    },
  ],

  LOCALE: [
    {
      name: 'English',
      avatar: '🇬🇧',
      id: 'en',
    },
    {
      name: 'עברית',
      avatar: '🇮🇱',
      id: 'he',
    },
    {
      name: 'Français',
      avatar: '🇫🇷',
      id: 'fr',
    },
    {
      name: 'Italiano',
      avatar: '🇮🇹',
      id: 'it',
    },
    {
      name: 'Português',
      avatar: '🇵🇹',
      id: 'pt',
    },
    {
      name: 'Lietuvių',
      avatar: '🇱🇹',
      id: 'lt',
    },
    {
      name: 'Latviešu',
      avatar: '🇱🇻',
      id: 'lv',
    },
    {
      name: 'Монгол',
      avatar: '🇲🇳',
      id: 'mn',
    },
    {
      name: 'Türkçe',
      avatar: '🇹🇷',
      id: 'tr',
    },
    {
      name: 'Български',
      avatar: '🇧🇬',
      id: 'bg',
    },
    {
      name: 'Română',
      avatar: '🇷🇴',
      id: 'ro',
    },
    {
      name: 'Hrvatski',
      avatar: '🇭🇷',
      id: 'hr',
    },
  ],
  PERMISSIONS: [
    {
      name: 'admin_view_option',
      id: 1,
    },
    {
      name: 'admin_edit_action',
      id: 2,
    },
  ],
  ROLES: [
    {
      name: 'admin_role_worker',
      id: 0,
    },
    {
      name: 'admin_role_manager',
      id: 1,
    },
  ],
  UID_REG_EX: /^([a-z0-9]{1,1}[a-z0-9_\-r"."]{0,24})$/,
  PHONE_NUMBER_REG_EX: /^([0-9]){4,25}$/,
  EMAIL_REG_EX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  PASSWORD_REG_EX: /^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*+.]){1,}).{8,26}$/,
}
