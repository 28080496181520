import { actionTypes } from '../actions/createTask'
import { CreateTaskState } from '../../constants/interfaces'
import { clientTz } from 'timezone-select-js'
import { timastampStringToDate } from 'utils'

export const initialState: CreateTaskState = {
  selectedCategory: '',
  isCreateTaskStarted: false,
  publishTaskIsLoading: false,
  rs_id: '',
  tid: '', // uuid v1
  data_oid: '', // (optional only if exist task data) - task_data["oid"]
  from_ts: null,
  to_ts: null,
  deadlineDate: null,
  title: '',
  type: 0, // 0-general, 2-external-link
  categories_order: [],
  categories: {
    // 'category_default_name 1': {
    //   sub_tasks_order: [],
    //   done: [],
    //   dialog_box: null,
    //   images: null,
    // },
  },
  view: null,
  order: 0,
  active: true,
  ref_id: '', // uuid v1
  roll: false,
  is_st_product_numbered: true,
  is_st_product_camera_enabled: true,
  is_opened: false,
  taskOwners: null,

  productSubTasks: {},
  textSubTasks: {},
  imageSubTasks: {},
  uploadFileSubTasks: {},
  linkSubTasks: {},
  videoSubTasks: {},
  subTasks: {},

  tasks_data: {
    oid: '', // uuid1 - auto generated
    data: {},
    rs_id: '', // retail_test-group_id
  },
  timezone: clientTz(),
  isNotifyMembers: false,
  notifyGroup: [],
  isSendImmediatePush: true,
  isSendSchedulePush: true,
  isAutoRemovalTask: true,
  isNewTask: true,
}

const createTaskReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_IS_NEW_TASK:
      return {
        ...state,
        isNewTask: action.payload,
      }
    case actionTypes.SET_EDITED_TASK:
      return {
        ...state,
        tid: action.payload.task.tid,
        rs_id: action.payload.task.rs_id,
        data_oid: action.payload.task.data_oid,
        from_ts: timastampStringToDate(action.payload.task.from_ts),
        to_ts: timastampStringToDate(action.payload.task.to_ts),
        deadlineDate: timastampStringToDate(action.payload.task.deadline),
        title: action.payload.task.title,
        type: action.payload.task.type,
        categories_order: action.payload.task.categories_order,
        categories: action.payload.task.categories,
        view: action.payload.task.view,
        order: action.payload.task.order,
        active: action.payload.task.active,
        ref_id: action.payload.task.ref_id,
        roll: action.payload.task.roll,
        is_st_product_numbered: action.payload.task.is_st_product_numbered,
        is_st_product_camera_enabled: action.payload.task.is_st_product_camera_enabled,
        is_opened: action.payload.task.is_opened,
        taskOwners: action.payload.task.taskOwners,
        timezone: action.payload.task.timezone,
        isNotifyMembers: action.payload.task.isNotifyMembers,
        notifyGroup: action.payload.task.notifyGroup,
        isSendImmediatePush: action.payload.task.isSendImmediatePush,
        isSendSchedulePush: action.payload.task.isSendSchedulePush,
        isAutoRemovalTask: action.payload.task.isAutoRemovalTask,
        productSubTasks: action.payload.productSubTasks,
        textSubTasks: action.payload.textSubTasks,
        imageSubTasks: action.payload.imageSubTasks,
        uploadFileSubTasks: action.payload.uploadFileSubTasks,
        linkSubTasks: action.payload.linkSubTasks,
        videoSubTasks: action.payload.videoSubTasks,
        subTasks: action.payload.subTasks,
        tasks_data: action.payload.tasksData,
      }
    case actionTypes.SET_AUTO_REMOVAL_TASK:
      return {
        ...state,
        isAutoRemovalTask: action.payload,
      }
    case actionTypes.SET_INITIAL_CATEGORY:
      return {
        ...state,
        categories: {
          [action.payload]: {
            sub_tasks_order: [],
            done: [],
            dialog_box: null,
            images: null,
          },
        },
      }
    case actionTypes.SET_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      }
    case actionTypes.START_CREATE_TASK:
      return {
        ...state,
        isCreateTaskStarted: action.payload,
      }
    case actionTypes.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      }
    case actionTypes.SET_INITIAL_TASK:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.SET_TASK_NAME:
      return {
        ...state,
        title: action.payload,
      }
    case actionTypes.SET_TASK_VIEW:
      return {
        ...state,
        view: action.payload,
      }
    case actionTypes.SET_TASK_OWNERS:
      return {
        ...state,
        taskOwners: action.payload,
      }

    case actionTypes.SET_START_DATE:
      return {
        ...state,
        from_ts: action.payload,
      }
    case actionTypes.SET_END_DATE:
      return {
        ...state,
        to_ts: action.payload,
      }
    case actionTypes.SET_DEADLINE_DATE:
      return {
        ...state,
        deadlineDate: action.payload,
      }
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      }
    case actionTypes.SET_CATEGORY_NAME:
      return {
        ...state,
        categories: action.payload,
      }
    case actionTypes.SET_CATEGORY_DESCRIPTION:
      return {
        ...state,
        categories: action.payload,
      }
    case actionTypes.SET_CATEGORY_IMAGE:
      return {
        ...state,
        categories: action.payload,
      }
    case actionTypes.DELETE_CATEGORY_IMAGE:
      return {
        ...state,
        categories: action.payload,
      }
    case actionTypes.SET_SUBTASK:
      return {
        ...state,
        ...action.payload,
      }

    case actionTypes.SET_TID:
      return {
        ...state,
        tid: action.payload,
      }
    case actionTypes.SET_CREATE_TASKS_DATA:
      return {
        ...state,
        tasks_data: action.payload,
      }
    case actionTypes.SET_ACTIVE:
      return {
        ...state,
        active: action.payload,
      }
    case actionTypes.SET_ROLL:
      return {
        ...state,
        roll: action.payload,
      }
    case actionTypes.SET_IS_PRODUCT_NUMBERED:
      return {
        ...state,
        is_st_product_numbered: action.payload,
      }
    case actionTypes.SET_IS_PRODUCT_CAMERA_ENABLED:
      return {
        ...state,
        is_st_product_camera_enabled: action.payload,
      }
    case actionTypes.SET_PUBLISH_TASK_IS_LOADING:
      return {
        ...state,
        publishTaskIsLoading: action.payload,
      }
    case actionTypes.RESET_TO_INITIAL_STATE:
      return initialState
    case actionTypes.SET_IS_NOTIFY_MEMBERS:
      return {
        ...state,
        isNotifyMembers: action.payload,
      }
    case actionTypes.SET_NOTIFY_GROUP:
      return {
        ...state,
        notifyGroup: action.payload,
      }
    case actionTypes.SET_IS_SEND_SCHEDULE_PUSH:
      return {
        ...state,
        isSendSchedulePush: action.payload,
      }
    case actionTypes.SET_IS_SEND_IMMEDIATE_PUSH:
      return {
        ...state,
        isSendImmediatePush: action.payload,
      }
    default:
      return state
  }
}

export default createTaskReducer
