import { Avatar, Collapse, Divider, Flex, Icon, Skeleton, Stack, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { getAvatarSrc } from 'components/Tasks/utils'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { IoMdImages } from 'react-icons/io'
import { MdOutlinePoll } from 'react-icons/md'
import { isRtlSelector } from 'redux/selectors/general'
import { ReactComponent as TasksLinkIcon } from 'assets/icon-tasks-link.svg'

interface IProps {
  participants: {
    id: string
    date?: string
    isClickable?: boolean
    insights?: {
      items: any[]
      typeCounts: {
        [type: number]: number
      }
    }
  }[]
  handleGetSpecificTaskResult: (contextId: string) => void
}
const typeIcons: { [key: number]: JSX.Element } = {
  2: <IoMdImages size="1.5em" />,
  3: <TasksLinkIcon width="21px" height="18px" filter="grayscale(1)" />,
  6: <MdOutlinePoll size="1.5em" />,
}

export const VotedParticipants = ({ participants, handleGetSpecificTaskResult }: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  const { groups } = useSelector((state: RootState) => state.config.config)
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const { isLoading } = useSelector((state: RootState) => state.tasks_v2)
  const [showAll, setShowAll] = useState(false)

  const visibleParticipants = participants.slice(0, 3)
  const collapedParticipants = participants.slice(3)

  const handleToggle = () => setShowAll(!showAll)

  return (
    <Stack direction="column" width="100%">
      {visibleParticipants.map(({ id, date, isClickable, insights }, index) => {
        const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
        const participantName = retailUsersObject?.[id]
          ? `${retailUsersObject?.[id].first_name} ${retailUsersObject?.[id].last_name ?? ''}`
          : groups[id]?.name ?? ''

        return (
          <React.Fragment key={`${id}-${index}`}>
            <Flex direction="row" alignItems="center" justifyContent="space-between">
              <Flex direction="row" alignItems="center" style={{ columnGap: '10px' }}>
                <Avatar src={avatar} />
                <Flex direction="column" alignItems="flex-start" justifyContent="center">
                  <Text fontWeight="bold">{participantName}</Text>
                  <Text>
                    <LocaleText text={date ?? ''} />
                  </Text>
                </Flex>
              </Flex>
              <Flex>
                {insights && Object.keys(insights.typeCounts).length > 0 && (
                  <>
                    {isLoading ? (
                      <Skeleton height="20px" width="100px" borderRadius="5px" />
                    ) : (
                      <Flex alignItems="center">
                        {Object.entries(insights.typeCounts).map(([type, count]) => (
                          <Flex key={type} alignItems="center" mx={2} style={{ columnGap: '3px' }} color="grey">
                            <Text fontSize="14px">{count}</Text>
                            {typeIcons[type]}
                          </Flex>
                        ))}
                      </Flex>
                    )}
                  </>
                )}

                {isClickable && (
                  <Icon
                    onClick={() => handleGetSpecificTaskResult(id)}
                    name={isRtl ? 'chevron-left' : 'chevron-right'}
                    size="25px"
                    cursor="pointer"
                  />
                )}
              </Flex>
            </Flex>
            <Divider />
          </React.Fragment>
        )
      })}
      {participants.length > 3 && (
        <>
          <Collapse isOpen={showAll}>
            {collapedParticipants.map(({ id, date, isClickable }, index) => {
              const avatar = getAvatarSrc({ id: id, groups: groups, retailUsersObject: retailUsersObject! })
              const participantName = retailUsersObject?.[id]
                ? `${retailUsersObject?.[id].first_name} ${retailUsersObject?.[id].last_name ?? ''}`
                : groups?.[id]?.name ?? ''
              return (
                <React.Fragment key={`${id}-${index}`}>
                  <Flex direction="row" alignItems="center" justifyContent="space-between">
                    <Flex direction="row" alignItems="center" style={{ columnGap: '10px' }}>
                      <Avatar src={avatar} />
                      <Flex direction="column" alignItems="flex-start" justifyContent="center">
                        <Text fontWeight="bold">{participantName}</Text>
                        <Text>
                          <LocaleText text={date ?? ''} />
                        </Text>
                      </Flex>
                    </Flex>
                    {isClickable && (
                      <Icon
                        onClick={() => handleGetSpecificTaskResult(id)}
                        name="chevron-right"
                        size="25px"
                        cursor="pointer"
                      />
                    )}
                  </Flex>
                  <Divider />
                </React.Fragment>
              )
            })}
          </Collapse>
          <Flex
            cursor="pointer"
            justifyContent="space-between"
            alignItems="center"
            onClick={handleToggle}
            width="100%"
            pt="3px"
          >
            <Text color={colors.greyMain} fontWeight="500">
              {showAll ? 'Show Less' : 'Show More'}
            </Text>
            <Icon name="chevron-right" />
          </Flex>
        </>
      )}
    </Stack>
  )
}
