import { Box, Flex } from '@chakra-ui/core'
import React, { useEffect, useState } from 'react'
import { MessageList } from './MessageList'
import { MessageInput } from './MessageInput'
import { BackArrow, NavTitleItem } from 'components/Sidebar/SidebarStyles'
import { useNavigate } from 'react-router-dom'
import { useWebSocket } from './useWebSocket'
import endpoints from 'constants/endpoints'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import Auth from 'services/authService'
import styled from '@emotion/styled'

export const MessageWrapper = styled(Box)`
  flex: 1;
  background: linear-gradient(to bottom, white, #f2f3f5);
`
interface ChatProps {
  reportsRequestStr: string
}
export const Chat = ({ reportsRequestStr }: ChatProps) => {
  const navigate = useNavigate()
  const isRtl = useSelector(isRtlSelector)
  const [refreshToken, setRefreshToken] = useState<string | null>(null)

  useEffect(() => {
    const fetchToken = async () => {
      try {
        const token = await Auth.getUpdatedToken()
        if (typeof token === 'string') {
          setRefreshToken(token)
        }
      } catch (error) {
        console.error('Error fetching the token:', error)
      }
    }

    fetchToken()
  }, [])

  const { messages, isBotTyping, handleSend } = useWebSocket({
    reportsRequestStr,
    socketAddress: endpoints.socketGPT(refreshToken),
    refreshToken,
  })

  return (
    <>
      <Flex direction="column" alignItems="center" pos="relative" m="auto" overflowY="auto" h={'calc(88vh - 94px)'}>
        <NavTitleItem
          onClick={() => navigate(-1)}
          pos="fixed"
          left={!isRtl ? '1vw' : 'unset !important'}
          right={isRtl ? '1vw' : 'unset'}
          top="10vh"
        >
          <BackArrow top="13px" />
        </NavTitleItem>
        <MessageWrapper
          width="100%"
          maxW={{ base: '80%', md: '600px', lg: '700px', xl: '700px', xxl: '900px' }}
          mt="30px"
        >
          <MessageList messages={messages} />
        </MessageWrapper>
      </Flex>
      <Flex
        width="100%"
        maxW={{ base: '60%', md: '510px', lg: '620px', xl: '620px' }}
        mx="auto"
        pos="fixed"
        bottom="5vh"
        left="0"
        right="0"
      >
        <MessageInput handleSend={handleSend} loading={isBotTyping} />
      </Flex>
    </>
  )
}
