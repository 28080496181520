// eslint-disable-next-nine @typescript-eslint/no-use-before-define
import React from 'react'
import { Box, Button, Flex } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import { ReactComponent as ArrowRightIcon } from '../../assets/shevron-right.svg'

export const StyledSidebar = styled(Box)<{ isHebrew: boolean }>`
  width: 100%;
  position: sticky;
  top: 5rem;
  z-index: 3;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-right: ${({ isHebrew }) => (isHebrew ? 'auto' : '2rem')};
  margin-left: ${({ isHebrew }) => (isHebrew ? '2rem' : 'auto')};

  @media (min-width: 1000px) {
    width: 25%;
    height: 100vh;
    overflow-y: auto;
    top: 0;
  }
`

export const SidebarContent = styled(Flex)`
  position: relative;

  @media (min-width: 1000px) {
    top: 4rem;
  }
`

export const NavItemBtn = styled(Button)<{ locale?: string }>`
font-size: 1rem;
font-family: Asap, sans-serif;
font-weight: 500;
padding: 5px 10px;
border-radius: 12px;
transition: .2s ease-in-out;
width: 100%;
color: #333333;
height: auto;

&:hover, &:focus {
  color: #333333;
  background-color: #e5e8eb;
  text-decoration: none;
}

&.active {
  font-weight: bold;
  background-color: #e5e8eb;
  text-decoration: none;
}

@media(max-width: 999px) {
  white-space: nowrap;
  margin-right: ${(props) => (props.locale && props.locale !== 'he' ? '5px' : '0px')}
  margin-left: ${(props) => (props.locale && props.locale === 'he' ? '5px' : '0px')}
}

@media(min-width: 1000px) {
  overflow-y: auto;
  padding: 18px 20px;
}
`

export const NavItem = styled(Link)<{ locale?: string }>`
font-family: Asap, sans-serif;
font-weight: 500;
padding: 5px 10px;
border-radius: 12px;
transition: .2s ease-in-out;
width: auto;
color: #333333;

&:hover, &:focus {
  color: #333333;
  background-color: #e5e8eb;
  text-decoration: none;
}

&.active {
  font-weight: bold;
  background-color: white;
  text-decoration: none;
}

&.disabled {
  cursor: progress;
}

@media(max-width: 999px) {
  white-space: nowrap;
  margin-right: ${(props) => (props.locale && props.locale !== 'he' ? '5px' : '0px')}
  margin-left: ${(props) => (props.locale && props.locale === 'he' ? '5px' : '0px')}
}

@media(min-width: 1000px) {
  overflow-y: auto;
  padding: 18px 20px;
}
`

export const NavTitleItem = styled(Box)`
  font-family: Asap, sans-serif;
  display: flex;
  font-weight: bold;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  color: inherit;

  &:hover,
  &:focus {
    outline: none;
    text-decoration: none;
    color: #333333;

    .arrow {
      background-color: #dadde1;
    }
  }

  @media (min-width: 1000px) {
    margin-bottom: 30px;
  }
`

const ArrowWrapper = styled(Box)`
  width: 36px;
  height: 36px;
  border-radius: 100%;
  padding: 8px;
  transition: 0.2s ease-in-out;
`

interface IProps {
  position?: 'static' | 'absolute' | 'relative'
  right?: string
  left?: string
  className?: string
  top?: string
}

export const BackArrow = ({
  position = 'relative',
  right = '-20px',
  left = '-20px',
  className = 'arrow',
  top,
}: IProps) => {
  const locale = useSelector((state: any) => state.general.locale)

  return (
    <ArrowWrapper
      position={position}
      right={locale === 'he' ? 'auto' : right}
      left={locale !== 'he' ? 'auto' : left}
      top={top}
      className={className}
    >
      <ArrowRightIcon style={{ marginLeft: '3px', transform: locale === 'he' ? 'rotate(180deg)' : 'none' }} />
    </ArrowWrapper>
  )
}
