import React, { memo, useEffect, useRef, useState } from 'react'
import './linkPreview.css'
import { httpAuth } from 'config/apiClient'
import endpoints from 'constants/endpoints'
import { Box, Flex, Image, Text } from '@chakra-ui/core'
import shave from 'shave'

export const placeholderImg = 'https://i.imgur.com/UeDNBNQ.jpeg'
const getCachedData = (url: string): APIResponse | null => {
  const linksPreview = JSON.parse(localStorage.getItem('linksPreview') || '{}')
  return linksPreview[url] || null
}

const setCachedData = (url: string, data: APIResponse) => {
  const linksPreview = JSON.parse(localStorage.getItem('linksPreview') || '{}')
  linksPreview[url] = data
  localStorage.setItem('linksPreview', JSON.stringify(linksPreview))
}

export interface LinkPreviewProps {
  url: string
  className?: string
  width?: string | number
  height?: string | number
  descriptionLength?: number
  borderRadius?: string | number
  imageHeight?: string | number
  textAlign?: 'left' | 'right' | 'center'
  margin?: string | number
  fallback?: JSX.Element[] | JSX.Element | null
  backgroundColor?: string
  primaryTextColor?: string
  secondaryTextColor?: string
  borderColor?: string
  showLoader?: boolean
  customLoader?: JSX.Element[] | JSX.Element | null
  openInNewTab?: boolean
  fallbackImageSrc?: string
  explicitImageSrc?: string
}

export interface APIResponse {
  title: string | null
  description: string | null
  image: string | null
  siteName: string | null
  hostname: string | null
}

export const LinkPreview: React.FC<LinkPreviewProps> = memo(
  ({
    url,
    width,
    height,
    borderRadius,
    textAlign,
    margin,
    fallback = null,
    backgroundColor = 'white',
    primaryTextColor = 'black',
    secondaryTextColor = 'rgb(100, 100, 100)',
    openInNewTab = true,
  }) => {
    const _isMounted = useRef(true)
    const [metadata, setMetadata] = useState<APIResponse | null>()
    const [loading, setLoading] = useState(true)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      const cacheKey = url

      _isMounted.current = true
      setLoading(true)

      const cachedData = getCachedData(cacheKey)
      if (cachedData) {
        setMetadata(cachedData)
        setLoading(false)
      } else {
        httpAuth
          .get(endpoints.getSiteMetaData(url))
          .then((res) => {
            if (_isMounted.current) {
              const data = res.data as unknown as APIResponse
              setMetadata(data)
              setCachedData(cacheKey, data)
              setLoading(false)
            }
          })
          .catch((err: Error) => {
            console.error(err)
            if (_isMounted.current) {
              setMetadata(null)
              setLoading(false)
            }
          })
      }

      return () => {
        _isMounted.current = false
      }
    }, [url])

    useEffect(() => {
      if (ref.current) {
        shave(ref.current, 90)
      }
    }, [metadata])

    if (!metadata) {
      return <>{fallback}</>
    }

    const { image, description, title, siteName } = metadata

    const onClick = () => {
      const browserTarget = openInNewTab ? '_blank' : '_self'
      window.open(url, browserTarget)
    }

    return (
      <Flex
        flexDir="row"
        cursor="pointer"
        onClick={onClick}
        style={{ width, height, borderRadius, textAlign, margin, backgroundColor }}
      >
        <>
          {image && (
            <Image
              src={image}
              style={{
                borderTopLeftRadius: borderRadius,
                borderTopRightRadius: borderRadius,
              }}
              className="Image"
            />
          )}
        </>

        {(!!title || !!description || !!siteName) && (
          <Box bg="#e7e7e7" w="100%" borderTopRightRadius="7px" borderBottomRightRadius="7px" ref={ref}>
            <Flex flexDir="column" p="5px" maxH="90px">
              <Text as="h3" className="Title" color={primaryTextColor}>
                {title}
              </Text>
              <>
                {description && (
                  <Box
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      color: secondaryTextColor,
                    }}
                  >
                    <Text>{description}</Text>
                  </Box>
                )}
                {siteName && (
                  <Box
                    className="Secondary SiteDetails"
                    style={{
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                      color: secondaryTextColor,
                    }}
                  >
                    {siteName && <Text as="span">{siteName} • </Text>}
                  </Box>
                )}
              </>
            </Flex>
          </Box>
        )}
      </Flex>
    )
  }
)
