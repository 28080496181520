import React, { memo, useMemo, useState } from 'react'
import { Flex } from '@chakra-ui/core'
import { MultiStepProgressBar } from '../UI/MultiStepProgressBar'
import { useParams } from 'react-router-dom'
import { ContentWrapper } from '../UI/ContentWrapper'
import { Form } from './Form'
import UsersGroupsManagment from '../UserGroupManagement'

export type Step = {
  label: string
  component: React.FC
}

const UserFormPage: React.FC = memo(() => {
  const [step, setStep] = useState<number>(0)
  const { uid } = useParams()

  const handleNextStep = (): void => {
    setStep((prevStep) => prevStep + 1)
  }

  const steps: Step[] = useMemo(() => {
    return [
      {
        label: 'Step 1',

        component: () => <Form handleNextStep={handleNextStep} />,
      },
      {
        label: 'Step 2',
        component: () => <UsersGroupsManagment />,
      },
    ]
  }, [])

  return (
    <ContentWrapper>
      {uid ? (
        <Form handleNextStep={handleNextStep} />
      ) : (
        <Flex flexDir="column" padding="2rem">
          <MultiStepProgressBar step={step} steps={steps} />
          {React.createElement(steps[step].component)}
        </Flex>
      )}
    </ContentWrapper>
  )
})

export default UserFormPage
