import { Flex, FormControl, FormLabel, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import { CustomTooltip } from 'components/helperStyles'
import React, { useMemo } from 'react'
import { BsFillQuestionCircleFill } from 'react-icons/bs'

interface FormInputWrapperProps {
  children: any
  field: string
  id: string
  tooltip?: string
  label: string
  requiredPlaceholder?: string
  required?: boolean
  inInvalid?: boolean
  watch?: any
}
export const FormInputWrapper = ({
  children,
  field,
  id,
  tooltip,
  label,
  requiredPlaceholder,
  inInvalid,
}: FormInputWrapperProps) => {
  const renderTooltip = useMemo(() => {
    if (tooltip)
      return (
        <Text>
          {tooltip
            .split('\n')
            .filter(Boolean)
            .map((paragraph) => (
              <span key={paragraph}>
                <Text as="span" fontSize="16px" my="10px" dangerouslySetInnerHTML={{ __html: paragraph }} />
                <br />
              </span>
            ))}
        </Text>
      )
  }, [tooltip])
  return (
    <FormControl
      isInvalid={inInvalid}
      pos="relative"
      display="flex"
      flexDir={field === 'enable_mfa' ? 'row-reverse' : 'column'}
      alignItems={field === 'enable_mfa' ? 'center' : 'flex-start'}
      justifyContent="flex-end"
    >
      <FormLabel
        htmlFor={id}
        pos="relative"
        w="max-content"
        color="#a2a2b6"
        mb={field === 'enable_mfa' ? '0px' : 'inherit'}
      >
        {tooltip ? (
          //@ts-ignore
          <CustomTooltip hasArrow label={renderTooltip} placement="top" aria-label={''}>
            <Flex flexDir="row" alignItems="center" style={{ columnGap: '10px' }}>
              <Text>
                <LocaleText text={label} />
                {requiredPlaceholder && field !== 'country_code' && field !== 'email' && field !== 'phone_number' && (
                  <Text as="span"> *</Text>
                )}
              </Text>

              <BsFillQuestionCircleFill />
            </Flex>
          </CustomTooltip>
        ) : (
          <Text>
            <LocaleText text={label} />
            {requiredPlaceholder && field !== 'country_code' && field !== 'email' && field !== 'phone_number' && (
              <Text as="span"> *</Text>
            )}
          </Text>
        )}
      </FormLabel>
      {children}
    </FormControl>
  )
}
