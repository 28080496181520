import { Box, DarkMode, Popover, PopoverContent, PopoverTrigger } from '@chakra-ui/core'
import React from 'react'

interface ITooltipList {
  items?: string[] | undefined
  defaultValue?: string | undefined
  children: React.ReactNode
}
export const TooltipList = ({ items, defaultValue, children }: ITooltipList) => {
  const renderTooltip = () => {
    return items && items.length > 0 ? (
      items.map((item, i) => {
        return (
          <li style={{ listStyle: 'none' }} key={i}>
            {item}
          </li>
        )
      })
    ) : (
      <span>{defaultValue}</span>
    )
  }

  return (
    <Popover trigger="hover" placement="bottom" usePortal>
      <PopoverTrigger>
        <Box>{children}</Box>
      </PopoverTrigger>
      <DarkMode>
        <PopoverContent
          border="none"
          maxH="300px"
          overflowY="auto"
          color="white"
          padding="15px"
          borderRadius="10px"
          w="max-content"
          zIndex={5000}
        >
          {renderTooltip()}
        </PopoverContent>
      </DarkMode>
    </Popover>
  )
}
