import { Box, Button, Flex, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsActionSuccessful } from 'redux/actions/admin'
import { BsCheckCircle } from 'react-icons/bs'
import { setRetailUsers } from 'redux/actions/config'
import { useNavigate } from 'react-router-dom'
import { RootState } from 'constants/interfaces'

type IProcessModalContent = {
  onClose: () => void
  handleNextStep?: () => void
  actionLiteral: string
  route?: string
  onSuccessGroupChange?: () => void
}
export const ActionSuccessContent = ({
  onClose,
  handleNextStep,
  actionLiteral,
  route,
  onSuccessGroupChange,
}: IProcessModalContent) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const admin_next_button = useLocaleText('admin_next_button')
  const success_literal = useLocaleText(actionLiteral)
  const { usersList } = useSelector((state: RootState) => state.admin.userTable)

  const hanldeCloseModal = () => {
    onSuccessGroupChange?.()
    onClose()
    dispatch(setIsActionSuccessful(false))
    handleNextStep?.()
    dispatch(setRetailUsers(usersList!))
    if (route) {
      navigate(`${route}`)
    }
  }

  return (
    <Flex flexDir="column" alignItems="center" p="30px">
      <Box as={BsCheckCircle} size="100px" color="green.400" />
      <Text my="20px" fontWeight="600" color="#a2a2b6" fontSize="24px" textAlign="center">
        {success_literal}
      </Text>
      <Flex flexDir="row" mt="40px" width="60%" justifyContent="space-around">
        <Button
          _hover={{ boxShadow: '0 0 0 1px green' }}
          _focus={{ boxShadow: '0 0 0 1px green' }}
          backgroundColor="green.400"
          color="white"
          minW="150px"
          fontWeight="600"
          p="5px"
          onClick={() => hanldeCloseModal()}
        >
          {admin_next_button}
        </Button>
      </Flex>
    </Flex>
  )
}
