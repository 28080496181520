import { Button, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { IDynamicTaskView } from 'constants/interfaces'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { taskViewsSelector } from 'redux/selectors/config'

const inputStyles = {
  borderRadius: '10px',
  bg: 'white',
  border: '1px solid #E2E8F0',
  fontWeight: '500',
  fontSize: '14px',
  px: '7px',
  h: '30px',
}
export const TaskViewsMenu = ({
  setTaskView,
  taskView,
}: {
  setTaskView: (taskView: number) => void
  taskView: number
}) => {
  const taskViews = useSelector(taskViewsSelector)
  const sortedTaskViews = taskViews?.sort((type, nextType) => type.order - nextType.order) ?? []

  const [selectedTaskView, setSelectedTaskView] = useState<IDynamicTaskView>(
    sortedTaskViews.find((view) => view.task_view_id === taskView)!
  )

  const t_choose_task_view = useLocaleText('t_choose_task_view')
  const handleClickOnTaskView = (taskViewId: number) => {
    const foundTaskView = sortedTaskViews.find((view) => view.task_view_id === taskViewId)!
    setSelectedTaskView(foundTaskView)
    setTaskView((foundTaskView?.task_view_id as number) ?? null)
  }

  return (
    <Menu>
      <MenuButton as={Button} w="max-content" {...inputStyles}>
        <LocaleText text={selectedTaskView?.literal || selectedTaskView?.task_view_name || t_choose_task_view} />
        <Icon name="triangle-down" mx="5px" w="8px" h="8px" />
      </MenuButton>
      <MenuList minW="80px" fontSize="14px" fontWeight="500">
        {sortedTaskViews.map((type) => (
          <MenuItem key={type.task_view_id} onClick={() => handleClickOnTaskView(type.task_view_id as number)}>
            <LocaleText text={type.literal || type.task_view_name || t_choose_task_view} />
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
