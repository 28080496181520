import React, { memo } from 'react'
import { Box, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import keys from 'constants/keys'

interface IProps {
  firstName: string
  lastName: string
  userRole: number
}
export const CreatorDetails = memo(({ firstName, lastName, userRole }: IProps) => {
  const fullName = `${firstName} ${lastName ? lastName : ''}`

  return (
    <Box textAlign={'left'}>
      <Text fontWeight={600} fontSize="14px">
        {fullName}
      </Text>
      <Text fontWeight={400} fontSize="14px">
        <LocaleText text={keys.USER_ROLES[userRole]} />
      </Text>
    </Box>
  )
})
