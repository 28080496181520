import React from 'react'
import { useSelector } from 'react-redux'
import { Button, Link, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/core'
import { BsWhatsapp } from 'react-icons/all'
import colors from 'constants/colors'
import LocaleText from '../LocaleText'
import { contactUsOptionsSelector } from 'redux/selectors/config'
import { contactUsOnWhatsApp } from 'constants/baseValues'

export const ContactUsWhatsApp = () => {
  const contactUsOptions = useSelector(contactUsOptionsSelector)

  return (
    <Menu>
      <MenuButton
        backgroundColor="transparent"
        as={Button}
        color={colors.greenDark}
        alignItems="center"
        _focus={{ outline: 'none' }}
        _hover={{ backgroundColor: 'transparent' }}
      >
        <BsWhatsapp size="25px" fill={colors.greenDark} />
        &nbsp;
        <Text>
          <LocaleText text="contact_button" />
        </Text>
      </MenuButton>
      <MenuList placement="bottom">
        {contactUsOptions.map((contact) => (
          <MenuItem key={contact.name} _focus={{ backgroundColor: 'transparent' }}>
            <Link
              textDecoration="none"
              width="100%"
              height="100%"
              href={contactUsOnWhatsApp + contact.phone}
              _hover={{ textDecoration: 'none' }}
              target="_blank"
            >
              <LocaleText text={contact.name} />
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}
