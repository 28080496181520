import React, { forwardRef } from 'react'
import { Box, Flex } from '@chakra-ui/core'
import DatePicker, { ReactDatePickerProps } from 'react-datepicker'

import { ReactComponent as DateIcon } from 'assets/icon-calendar.svg'
import colors from 'constants/colors'
import { CustomTooltip } from '../helperStyles'

interface IProps extends ReactDatePickerProps {
  tooltipLabel?: string
  isShowYearPicker?: boolean
  isShowMonthPicker?: boolean
}
interface ICustomTrigger {
  onClick?: VoidFunction
  value?: string
  label?: string
  placeholderText?: string
}

const CustomTrigger = forwardRef<HTMLElement, ICustomTrigger>(({ label, value, placeholderText, onClick }, ref) => (
  <CustomTooltip
    display={label ? 'block' : 'none'}
    aria-label={label || ''}
    label={label || ''}
    placement="bottom-start"
  >
    <Flex onClick={onClick} ref={ref} alignItems="center" cursor="pointer">
      <Box border="1px dashed" borderColor={colors.greyMain} borderRadius="100%" p="4px" alignItems="center" mr="10px">
        <DateIcon />
      </Box>
      {value || placeholderText}
    </Flex>
  </CustomTooltip>
))

const CustomDatePicker: React.FC<IProps> = ({
  tooltipLabel,
  placeholderText,
  isShowMonthPicker = false,
  isShowYearPicker = false,
  ...props
}: IProps) => {
  return (
    <DatePicker
      dateFormat={props.dateFormat || 'd/MM/yyyy hh:mm aa'}
      className="datePicker"
      popperPlacement="top"
      timeFormat="HH:mm"
      showMonthDropdown={isShowMonthPicker}
      showYearDropdown={isShowYearPicker}
      customInput={<CustomTrigger placeholderText={placeholderText} label={tooltipLabel} />}
      {...props}
    />
  )
}

export default CustomDatePicker
