import React from 'react'
import { Flex, Text } from '@chakra-ui/core'

interface IProps {
  fileName: string
  fileExtension?: string
  maxWidth: string
}

export const TruncatedFileName = ({ fileName, maxWidth, fileExtension }: IProps) => {
  return (
    <Flex maxWidth={maxWidth} justifyContent="center">
      <Text isTruncated>{fileName}</Text>
      <Text>.{fileExtension}</Text>
    </Flex>
  )
}
