import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Flex, Text, Collapse, Box, Divider, CheckboxGroup } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import { ArrowLeft, ArrowRight, ArrowDown, CustomCheckBox } from './style'
import { useSelector } from 'react-redux'
import { getTreeNodesIds } from './utils'
import { IGroupsTreeNode } from './interfaces'
import colors from 'constants/colors'
import { CustomTooltip } from 'components/helperStyles'
import strings from 'constants/strings'

const GroupsTreeNode = ({
  title,
  locale,
  childs,
  handleTaskOwnerGroupUI,
  id,
  checkedIds,
  groupElementId,
  intermidateIds,
  setGroupElementId,
  disabledIds,
  mode,
}: IGroupsTreeNode) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  const groups = useSelector((state: RootState) => state.config.config.groups)
  const [isOpen, setIsOpen] = useState<boolean>(getTreeNodesIds(groups, id).some((el) => el === groupElementId))
  const handleDropdown = () => {
    setIsOpen((prev) => !prev)
  }
  const scrollToElement = useCallback(() => {
    let element = document.getElementById(groupElementId)
    element?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [groupElementId])

  const tooltip = useMemo(() => {
    if (mode && mode === strings.USERS_MODE) {
      return intermidateIds.includes(id) ? 'admin_tooltip_disabled_1' : 'admin_tooltip_disabled_2'
    }
  }, [id, intermidateIds, mode])

  useEffect(() => {
    if (groupElementId === id) {
      scrollToElement()
    }
  }, [groupElementId, id, scrollToElement])

  return (
    <>
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" flexGrow={1} onClick={handleDropdown} cursor="pointer">
          {childs !== undefined &&
            (!isOpen ? (
              locale !== 'he' ? (
                <ArrowRight mr="5px" />
              ) : (
                <ArrowLeft ml="5px" />
              )
            ) : (
              <ArrowDown mr={locale !== 'he' ? '5px' : '0'} ml={locale === 'he' ? '5px' : '0'} />
            ))}
          <Flex justifyContent="center">
            <Text
              id={id}
              lineHeight="2.5"
              fontSize="18px"
              fontWeight={groupElementId === id ? '700' : '500'}
              ml="5px"
              mr="5px"
              color="black"
            >
              {title}
            </Text>

            <Text lineHeight="2.5" fontSize="18px" color="black">
              {getTreeNodesIds(groups, id).length > 1 ? `${' '}(${getTreeNodesIds(groups, id).length})` : ''}
            </Text>
          </Flex>
        </Flex>
        {(disabledIds?.includes(id) || intermidateIds.includes(id)) && mode && mode === strings.USERS_MODE ? (
          <CheckboxGroup pos="relative">
            <CustomTooltip hasArrow label={tooltip} pos="absolute" placement="top" aria-label={''}>
              <CustomCheckBox
                isIndeterminate={intermidateIds.includes(id)}
                backgroundImage="none"
                isDisabled={disabledIds?.includes(id)}
                isChecked={checkedIds?.includes(id)}
                onChange={() => {
                  handleTaskOwnerGroupUI!(id, isOpen)
                }}
                color={theme?.elementsColor}
                hoverColor={theme?.backgroundLight}
              />
            </CustomTooltip>
          </CheckboxGroup>
        ) : (
          <>
            <CustomCheckBox
              isIndeterminate={intermidateIds.includes(id)}
              backgroundImage="none"
              isDisabled={disabledIds?.includes(id)}
              isChecked={checkedIds?.includes(id)}
              onChange={() => {
                handleTaskOwnerGroupUI!(id, isOpen)
              }}
              color={theme?.elementsColor}
              hoverColor={theme?.backgroundLight}
            />
          </>
        )}
      </Flex>
      <Divider my="5px" color={colors.greyMedium} />
      <Collapse
        width="100%"
        isOpen={isOpen}
        paddingLeft={locale === 'he' ? '0' : '5'}
        paddingRight={locale === 'he' ? '5' : '0'}
      >
        {childs?.map((child) => {
          return (
            <Box key={child.id!}>
              <GroupsTreeNode
                id={child.id!}
                title={child.title}
                locale={locale}
                childs={child.children}
                handleTaskOwnerGroupUI={handleTaskOwnerGroupUI}
                checkedIds={checkedIds}
                groupElementId={groupElementId}
                intermidateIds={intermidateIds}
                setGroupElementId={setGroupElementId}
                disabledIds={disabledIds}
                mode={mode}
              />
            </Box>
          )
        })}
      </Collapse>
    </>
  )
}

export default GroupsTreeNode
