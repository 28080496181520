import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Loader } from 'components/CommonComponents/Loader'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface InfiniteScrollProps {
  loadMore: () => void
  hasMore: boolean
  threshold: number
  isUpwardScroll: boolean
  children: React.ReactNode
  scrollRef: React.RefObject<HTMLDivElement>
  isLoading?: boolean
  isHorizontalScroll?: boolean
}

export const AdvancedInfiniteScroll: React.FC<InfiniteScrollProps> = ({
  loadMore,
  hasMore,
  threshold,
  isUpwardScroll,
  children,
  scrollRef,
  isLoading,
  isHorizontalScroll = false,
}) => {
  const isRtl = useSelector(isRtlSelector)
  const [loading, setLoading] = useState(false)
  const lastScrollTop = useRef(0)

  const checkScrollPosition = useCallback(async () => {
    if (!scrollRef.current || loading) return

    const { scrollTop, scrollLeft, scrollWidth, clientWidth } = scrollRef.current

    const isScrollingUp = lastScrollTop.current > scrollTop
    lastScrollTop.current = scrollTop

    const isNearVerticalStart = isUpwardScroll && scrollTop < threshold
    let isNearHorizontalEnd
    if (isRtl) {
      const normalizedScrollLeft = scrollWidth - clientWidth - Math.abs(scrollLeft)
      isNearHorizontalEnd = normalizedScrollLeft < threshold
    } else {
      isNearHorizontalEnd = scrollWidth - scrollLeft - clientWidth < threshold
    }

    if (hasMore && isUpwardScroll && (isNearVerticalStart || isScrollingUp)) {
      setLoading(true)
      await loadMore()
      setLoading(false)
    }

    if (hasMore && isHorizontalScroll && isNearHorizontalEnd) {
      setLoading(true)
      await loadMore()
      setLoading(false)
    }
  }, [scrollRef, loading, isUpwardScroll, threshold, isRtl, hasMore, isHorizontalScroll, loadMore])

  useEffect(() => {
    checkScrollPosition()
  }, [checkScrollPosition])

  useEffect(() => {
    const scrollContainer = scrollRef.current

    const resizeObserver = new ResizeObserver(() => {
      checkScrollPosition()
    })

    if (scrollContainer) {
      resizeObserver.observe(scrollContainer)
      scrollContainer.addEventListener('scroll', checkScrollPosition)
    }

    return () => {
      if (scrollContainer) {
        resizeObserver.unobserve(scrollContainer)
        scrollContainer.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [scrollRef, checkScrollPosition])

  return (
    <>
      {isLoading && <Loader maxWidth="100%" />}
      {children}
    </>
  )
}
