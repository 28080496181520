import { useToast } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { checkKeyExists, getFileExtension } from 'utils'

export const useFileValidation = () => {
  const retailConfig = useSelector((state: RootState) => state.config.retailConfig)
  const toast = useToast()
  const fileSizeAndExtensionMap = retailConfig?.file_extension_to_max_size_map || {}
  const warningMessage =
    'The file you selected is too large, or the file has an extantion that is not supported. This file can not be uploaded!'

  const fileSizeValidation = (file: File) => {
    const fileExtension = getFileExtension(file.name).toLowerCase()

    if (!checkKeyExists(fileSizeAndExtensionMap, fileExtension) || file.size > fileSizeAndExtensionMap[fileExtension]) {
      toast({ title: warningMessage, duration: 5000, isClosable: true })
      return false
    }

    return true
  }

  return {
    fileSizeValidation,
  }
}
