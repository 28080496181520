import { Box, Flex, Icon, Text } from '@chakra-ui/core'
import React, { memo } from 'react'
import keys from 'constants/keys'
import useLocaleText from 'components/useLocaleText'
import { BsExclamationLg } from 'react-icons/bs'

interface IProps {
  avatar: string
  status: number
  houraUntilEnd: number
}
export const CircleTask = memo(({ avatar, status, houraUntilEnd }: IProps) => {
  const t_delay = useLocaleText('t_delay')
  const t_hours = useLocaleText('t_hours')

  let displayText
  let bgColor
  let textColor = 'black'

  if (status === keys.TASK_STATUSES.TODO.value || status === keys.TASK_STATUSES.INPROGRESS.value) {
    if (houraUntilEnd < 0) {
      displayText = t_delay
    } else {
      displayText = t_hours
      bgColor = 'rgb(253 233 46 / 87%)'
    }
  } else if (status === keys.TASK_STATUSES.DONE.value) {
    bgColor = 'rgb(76 175 80 / 90%)'
    displayText = null
  }

  return (
    <Box
      position="relative"
      w="90px"
      h="90px"
      borderRadius="50%"
      overflow="hidden"
      backgroundImage={avatar ? `url(${avatar})` : 'none'}
      bg={avatar ? 'white' : 'grey'}
      backgroundSize="cover"
      border="2px solid white"
      backgroundPosition="top"
      boxShadow="0px 0px 2px 0px grey"
    >
      {status === keys.TASK_STATUSES.DONE.value && (
        <Box
          position="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          bg={bgColor}
        >
          <Icon name="check" w="36px" h="36px" color="white" />
        </Box>
      )}

      {(status === keys.TASK_STATUSES.TODO.value || status === keys.TASK_STATUSES.INPROGRESS.value) &&
        houraUntilEnd < 0 && (
          <Box
            position="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bg="rgb(224 22 22 / 81%)"
          >
            <Box as={BsExclamationLg} size="36px" color="white" />
          </Box>
        )}

      {(status === keys.TASK_STATUSES.TODO.value || status === keys.TASK_STATUSES.INPROGRESS.value) &&
        houraUntilEnd >= 0 && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            w="100%"
            h="52%"
            style={{ backgroundColor: bgColor }}
            display="flex"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            color={textColor}
          >
            <Flex flexDir="column" color="black" lineHeight="normal" fontWeight="400" pb="2px">
              <Text fontSize="16px">{houraUntilEnd}</Text>
              <Text fontSize="11px">{displayText}</Text>
            </Flex>
          </Box>
        )}
    </Box>
  )
})
