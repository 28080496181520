import { Flex, Image, Text, Box } from '@chakra-ui/core'
import colors from 'constants/colors'
import dayjs from 'dayjs'
import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import strings from 'constants/strings'
import { MarkUpText } from './MarkUpText'
import { AudioPlayer } from 'components/AudioPlayer'
import { PostMediaGrid } from 'components/NewsFeed/PostMediaGrid'
import { FbGridVideoPreview } from '../FbGrid/FbGridVideoPreview'
interface IProps {
  created_at_ts: number
  pop_title: string
  pop_body: string
  pop_image_url: string
  pop_initiator: number
  created_by: string
  audio?: string
  video?: string
}
export default function PopBody({
  created_by,
  created_at_ts,
  pop_title,
  pop_body,
  pop_image_url,
  pop_initiator,
  audio,
  video,
}: IProps) {
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const date = dayjs.unix(created_at_ts)
  const formattedDate = date.locale('en').format('DD/MM/YYYY, HH:mm')
  const creatorsName = `${retailUsersObject?.[created_by]?.first_name ?? ''} ${
    retailUsersObject?.[created_by]?.last_name ?? ''
  }`

  return (
    <Flex minH="100px" flexDir="column" alignItems="center" px="55px" textAlign="center">
      <Text dir="auto" fontWeight="600">
        {creatorsName.trim() ? creatorsName : strings.COMPANY_NAME}
      </Text>

      <Text dir="auto" color={colors.greyMain}>
        {formattedDate}
      </Text>
      <Box
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'inherit',
          paddingTop: '15px',
        }}
      >
        <MarkUpText
          text={pop_title}
          initiator={pop_initiator}
          fontFamily="Arial"
          lineHeight="25px"
          fontSize="24px"
          fontWeight="600"
        />
      </Box>
      <Box
        style={{
          wordBreak: 'break-word',
          whiteSpace: 'inherit',
          paddingTop: '10px',
        }}
      >
        <MarkUpText text={pop_body} initiator={pop_initiator} fontFamily="Arial" lineHeight="25px" fontSize="20px" />
      </Box>
      {pop_image_url && (
        <Image src={pop_image_url} w="140pt" height={pop_image_url ? '140pt' : 'auto'} maxH="160pt" mt={'15pt'} />
      )}
      {audio && (
        <Box mt={'15pt'} width="100%">
          <AudioPlayer src={audio} />
        </Box>
      )}
      {video && (
        <PostMediaGrid media={[video]} isEditable={false} px="0">
          {[video].map((videoSrc) => (
            <FbGridVideoPreview key={videoSrc} src={videoSrc} />
          ))}
        </PostMediaGrid>
      )}
    </Flex>
  )
}
