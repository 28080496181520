import { RootState } from './../../constants/interfaces'
/* eslint-disable no-useless-catch */
import endpoints from '../../constants/endpoints'
import { httpAuth } from '../../config/apiClient'
import { buildTasks, handleError, joinTasksViews } from '../../utils'
import { CleanTask, UpdateSubtaskProps } from '../../config/tasksTypes'
import produce from 'immer'
import isSolid from 'is-solid'
import { logoutHandler } from './auth'
import { AxiosError } from 'axios'
import { ThunkAction } from 'redux-thunk'
import { AnyAction } from 'redux'

export const actionTypes = {
  SET_ALL_TASKS: '[TASKS] SET_ALL_TASKS',
  SET_TASKS: '[TASKS] SET_TASKS',
  SET_TASKS_DATA: '[TASKS] SET_TASKS_DATA',
  SET_SUBTASKS: '[TASKS] SET_SUBTASKS',
  SET_IMAGE_FOR_TASK: '[TASKS] SET_IMAGE_FOR_TASK',
  SET_STORES_TO_FETCH: '[TASKS] SET_STORES_TO_FETCH',
  SET_REPORT_INDEX_IN_STATE: '[TASKS] SET_REPORT_INDEX_IN_STATE',
  SET_ACTIVE_STORE_ID: '[TASKS] SET_ACTIVE_STORE_ID',
  SET_IS_TASKS_LOADING: '[TASKS] SET_IS_TASKS_LOADING',
}

export const setTasksData = (tasksData: any) => ({
  type: actionTypes.SET_TASKS_DATA,
  tasksData,
})

export const setSubtasks = (subTasks: any) => ({
  type: actionTypes.SET_SUBTASKS,
  subTasks,
})

export const setTasks = (tasks: any) => ({
  type: actionTypes.SET_TASKS,
  tasks,
})

export const setImageForTask = (imagePath: any, taskId: any) => ({
  type: actionTypes.SET_IMAGE_FOR_TASK,
  payload: {
    imagePath,
    taskId,
  },
})

const filterTasks = (tasks: CleanTask[], tid: string) => {
  return tasks.filter((task) => task.tid !== tid)
}

export const deleteTask =
  (groupId: string, tid: string, rid: string, ref_id?: string): any =>
  async (dispatch: any, getState: () => RootState) => {
    try {
      const {
        tasks: { allTasks, subTasks, tasksData },
        config: { retailConfig },
      } = getState()
      const updatedAllTasks = filterTasks(joinTasksViews(allTasks), tid)

      const defaultTaskViews = retailConfig?.default_task_views || []
      const dynamicTaskViews = retailConfig?.dynamic_task_views || []
      const taskViewIds = [...defaultTaskViews, ...dynamicTaskViews].map((view) => view.task_view_id.toString())

      let deleteData: any = {
        rid,
        group: groupId,
        tid,
      }

      if (ref_id) {
        deleteData.ref_id = ref_id
      }

      httpAuth.delete(endpoints.deleteTask, {
        data: deleteData,
      })

      dispatch(
        setTasks(
          buildTasks(
            {
              groups_tasks: updatedAllTasks,
              sub_tasks: subTasks,
              tasks_data: tasksData,
            },
            taskViewIds
          )
        )
      )
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error?.response.status, () => dispatch(logoutHandler()))
      }
    }
  }

export const handleTasks =
  (sid: any = null, isNewsFeed: boolean = false) =>
  async (dispatch: any, getState: any) => {
    try {
      dispatch({
        type: actionTypes.SET_IS_TASKS_LOADING,
        isTasksLoading: true,
      })
      const {
        config: {
          config: { rid, stores_details, user_groups },
          activeGroupID,
          retailConfig,
        },
        tasks,
      } = getState()

      const defaultTaskViews = retailConfig?.default_task_views || []
      const dynamicTaskViews = retailConfig?.dynamic_task_views || []
      const taskViewIds = [...defaultTaskViews, ...dynamicTaskViews].map((view) => view.task_view_id.toString())

      const activeStoreId = tasks.activeStoreId

      if (sid === null) {
        sid =
          activeStoreId !== null ? activeStoreId : stores_details && stores_details[0] ? stores_details[0]?.value : null
      }

      dispatch({
        type: actionTypes.SET_ACTIVE_STORE_ID,
        sid,
      })

      if (rid && activeGroupID) {
        const { data } = isNewsFeed
          ? await httpAuth.get(endpoints.tasks(rid, user_groups))
          : await httpAuth.get(endpoints.tasks(rid, activeGroupID))
        dispatch({
          type: actionTypes.SET_ALL_TASKS,
          allTasks: data.groups_tasks,
        })
        dispatch(setTasks(buildTasks(data, taskViewIds)))
        dispatch(setTasksData(data.tasks_data))
        if (isSolid(data.sub_tasks)) {
          dispatch(setSubtasks(data.sub_tasks))
        }
      }
    } catch (err) {
      const error: AxiosError | any = err
      if (error.response) {
        handleError(error.response.status, () => dispatch(logoutHandler()))
      }
      return Promise.reject(error)
    } finally {
      dispatch({
        type: actionTypes.SET_IS_TASKS_LOADING,
        isTasksLoading: false,
      })
    }
  }

const updateTaskStatuses = (taskList: CleanTask[], updateTid: string): CleanTask[] => {
  const updatedTasks: CleanTask[] = taskList.map((task) => {
    if (task.tid === updateTid) {
      return {
        ...task,
        is_opened: true,
      }
    }

    return task
  })
  return updatedTasks
}

export const handleUpdateTask =
  (groupId: string, tid: string, type: number) => async (dispatch: any, getState: any) => {
    try {
      const {
        tasks: { allTasks, subTasks, tasksData },
        config: { retailConfig },
      } = getState()
      const updateData = {
        context: groupId,
        tid: tid,
        type: type,
        data: {
          is_opened: true,
        },
      }

      const defaultTaskViews = retailConfig?.default_task_views || []
      const dynamicTaskViews = retailConfig?.dynamic_task_views || []
      const taskViewIds = [...defaultTaskViews, ...dynamicTaskViews].map((view) => view.task_view_id.toString())

      const updatedAllTasks = updateTaskStatuses(joinTasksViews(allTasks), tid)

      dispatch(
        setTasks(
          buildTasks(
            {
              groups_tasks: updatedAllTasks,
              sub_tasks: subTasks,
              tasks_data: tasksData,
            },
            taskViewIds
          )
        )
      )
      await httpAuth.post(endpoints.createTask, updateData)
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error?.response.status, () => dispatch(logoutHandler()))
      }
    }
  }

export const handleUpdateSubtask =
  ({
    subTaskId,
    subTaskType,
    data,
  }: UpdateSubtaskProps): ThunkAction<Promise<boolean>, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      tasks: { allTasks, subTasks, tasksData },
      config: { retailConfig },
    } = getState()

    const defaultTaskViews = retailConfig?.default_task_views || []
    const dynamicTaskViews = retailConfig?.dynamic_task_views || []
    const taskViewIds = [...defaultTaskViews, ...dynamicTaskViews].map((view) => view.task_view_id.toString())

    try {
      const updatedSubTask = await httpAuth.post(endpoints.updateSubtask, {
        st_id: subTaskId,
        type: subTaskType,
        data,
      })
      const nextSubTasks = produce(subTasks, (draftSubTasks: any) => {
        draftSubTasks[subTaskId] = { ...subTasks[subTaskId], ...data }
      })
      const updatedSubtasks = {
        ...subTasks,
        [subTaskId]: {
          ...subTasks[subTaskId],
          ...updatedSubTask.data,
        },
      }

      dispatch(
        setTasks(
          buildTasks(
            {
              groups_tasks: joinTasksViews(allTasks),
              sub_tasks: updatedSubtasks,
              tasks_data: tasksData,
            },
            taskViewIds
          )
        )
      )
      dispatch(setSubtasks(nextSubTasks))
      return true
    } catch (err) {
      const error: AxiosError | any = err
      if (error?.response?.status) {
        handleError(error?.response.status, () => dispatch(logoutHandler()))
      }
      return false
    }
  }
