import { Box, Flex, Image } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import keys from 'constants/keys'
import styled from '@emotion/styled'
import StoreeManGif from 'assets/7eohzy.gif'
import Draggable from 'react-draggable'

const DraggableContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  height: 70px;
  width: 70px;
  position: fixed;
  border-radius: 100%;
  background: transparent;
  cursor: pointer;
  z-index: 4000;
`
const AvatarContainer = styled(Box)<{ isShowShadow?: boolean }>`
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 100%;
  box-shadow: ${({ isShowShadow }) => (isShowShadow ? '0px 2px 4px 0px #ea90be' : 'none')} 0px 2px 4px #ea90be;
  &:hover {
    transform: scale(1.1);
  }
`
interface IProps {
  isRtl: boolean
  posX?: number
  posY?: number
  children?: React.ReactNode
  callback?: () => void
  isShowShadow?: boolean
}

export const DraggableButton = ({ isRtl, posX = 0.1, posY = 0.2, children, callback, isShowShadow = true }: IProps) => {
  const [clickStartTime, setClickStartTime] = useState(0)
  const [clickEndTime, setClickEndTime] = useState(0)
  const navigate = useNavigate()

  const handleAvatarClick = () => {
    if (clickEndTime - clickStartTime < 200) {
      if (callback) {
        callback()
      } else navigate(keys.ROUTE_NAMES.CHAT_PAGE)
    }
  }

  const handleNavLinkDragStart = () => {
    setClickStartTime(new Date().getTime())
  }

  const handleNavLinkDragEnd = () => {
    setClickEndTime(new Date().getTime())
  }

  return (
    <>
      <Draggable
        key={`draggable-${isRtl}-${posX}-${posY}`}
        defaultPosition={{
          x: isRtl ? window.innerWidth * -posX : window.innerWidth * posX,
          y: window.innerHeight * posY,
        }}
        onStart={handleNavLinkDragStart}
        onStop={handleNavLinkDragEnd}
      >
        <DraggableContainer>
          <AvatarContainer isShowShadow={isShowShadow}>
            <Box onClick={handleAvatarClick}>
              {children ? (
                children
              ) : (
                <Image
                  w="74px"
                  h="74px"
                  draggable="false"
                  src={StoreeManGif}
                  borderRadius="100px"
                  background="transparent"
                />
              )}
            </Box>
          </AvatarContainer>
        </DraggableContainer>
      </Draggable>
    </>
  )
}
