import { Box, Flex, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

export const SelectionPrompt = memo(({ isMuptiple }: { isMuptiple: boolean }) => {
  const isRtl = useSelector(isRtlSelector)
  const t_select_one = useLocaleText('t_select_one')
  const t_select_one_or_more = useLocaleText('t_select_one_or_more')
  const promtTitle = isMuptiple ? t_select_one_or_more : t_select_one
  return (
    <Flex alignItems="center">
      <Box pos="relative">
        <Box h="12px" w="12px" pos={'absolute'} top={'-6px'} borderRadius="full" bg="grey" border="1px solid white" />
        {isMuptiple && (
          <Box
            h="12px"
            w="12px"
            pos={'absolute'}
            top={'-6px'}
            borderRadius="full"
            bg="grey"
            border="1px solid white"
            {...(isRtl ? { right: '6px' } : { left: '6px' })}
          />
        )}
      </Box>
      <Text fontSize="12px" color="grey" mx={isMuptiple ? '20px' : '14px'}>
        {promtTitle}
      </Text>
    </Flex>
  )
})
