import { Chat } from 'components/ChatOpenAI'
import { useReportResponse } from 'hooks/useReportResponse'
import { RootState } from 'constants/interfaces'
import keys from 'constants/keys'
import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAndSetReportData } from 'redux/actions/reports'

export const ChatPage = () => {
  const dispatch = useDispatch()
  const { activeGroupID, retailConfig, isConfigLoading, isConfigReady } = useSelector(
    (state: RootState) => state.config
  )
  const { reports } = useSelector((state: RootState) => state.reports)
  const response = useReportResponse({
    reportConfig: retailConfig?.report_config_new ?? null,
    reportData: reports[activeGroupID!]?.[0] ?? null,
  })

  const handleGetReportData = useCallback(async () => {
    if (activeGroupID === null) return
    const queryParams = {
      reportDataKey: activeGroupID,
      groupId: activeGroupID,
      report_range_type: keys.REPORTS_DATE_OPTIONS[0].type,
      sub_retail_id: retailConfig?.sub_retail_id ?? undefined,
    }
    dispatch(fetchAndSetReportData(queryParams))
  }, [activeGroupID, dispatch, retailConfig])

  useEffect(() => {
    if (!isConfigLoading && isConfigReady) handleGetReportData()
  }, [handleGetReportData, isConfigLoading, isConfigReady])

  if (!response) return null

  return <Chat reportsRequestStr={response} />
}
