// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState } from 'react'
import { Button, Flex, FormControl, Input, useToast, Text, InputGroup } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import useLocaleText from 'components/useLocaleText'
import { LoginWrapper } from 'components/Login/LoginWrapper'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { handleChangePassword } from 'redux/actions/auth'
import { RootState } from 'constants/interfaces'
import { useNavigate } from 'react-router'
import { isRtlSelector } from 'redux/selectors/general'
import { PasswordEyeToggle } from 'components/Login/PasswordEyeToggle'

export const ChangePassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toast = useToast()
  const isRtl = useSelector(isRtlSelector)
  const [isShow, setShow] = useState(false)
  const [isShowConfirm, setShowConfirm] = useState(false)
  const newPasswordText = useLocaleText('change_password_input1_place_holder')
  const repeatNewPasswordText = useLocaleText('change_password_input2_place_holder')
  const { isLoginLoading, isError, errorMessage } = useSelector((state: RootState) => state.auth)

  const { handleSubmit, errors, register, setError } = useForm()
  const change_password_policy = useLocaleText('change_password_policy')
  const submitNewPassword = (data: any) => {
    if (data.repeat_password !== data.password) {
      return setError('password', {
        type: '',
        message: 'password and repeat password should be the same',
      })
    }
    dispatch(handleChangePassword(data.password, navigate))
  }

  useEffect(() => {
    if (errors?.password?.types?.message || (isError && errorMessage !== '')) {
      toast({
        title: errors?.password?.types?.message || errorMessage,
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [errorMessage, errors, isError, toast])
  return (
    <LoginWrapper
      isChangingPassword={true}
      header={
        <Flex flexDirection="column" textAlign={isRtl ? 'right' : 'left'}>
          <Text my="10px">
            <LocaleText text="change_password" />
          </Text>
          <Text>
            {change_password_policy
              .split('\n')
              .filter(Boolean)
              .map((paragraph) => (
                <>
                  <Text
                    as="span"
                    key={paragraph}
                    fontSize="16px"
                    my="10px"
                    dangerouslySetInnerHTML={{ __html: paragraph }}
                  />
                  <br />
                </>
              ))}
          </Text>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit(submitNewPassword)}>
        <FormControl>
          <InputGroup>
            <Input
              id="password"
              name="password"
              placeholder={newPasswordText}
              ref={register}
              borderRadius="20px"
              borderBottom="none"
              borderBottomLeftRadius={0}
              borderBottomRightRadius={0}
              type={!isShow ? 'password' : 'text'}
              isRequired
            />
            <PasswordEyeToggle isShow={isShow} setShow={setShow} />
          </InputGroup>
          <InputGroup>
            <Input
              id="repeat_password"
              name="repeat_password"
              placeholder={repeatNewPasswordText}
              ref={register}
              borderRadius="20px"
              borderTopLeftRadius={0}
              borderTopRightRadius={0}
              type={!isShowConfirm ? 'password' : 'text'}
              isRequired
            />
            <PasswordEyeToggle isShow={isShowConfirm} setShow={setShowConfirm} />
          </InputGroup>
        </FormControl>

        <Button
          width="100%"
          borderRadius="20px"
          mt={4}
          variantColor="pink"
          variant="outline"
          isLoading={isLoginLoading}
          type="submit"
        >
          <LocaleText text="login" />
        </Button>
      </form>
    </LoginWrapper>
  )
}
