import { IComment } from 'constants/interfaces'
import { ICommentActivity, actionTypes } from 'redux/actions/comments'

export interface ICommentsState<T> {
  [key: string]: {
    items: T
    hasMore: boolean
  }
}
export interface ICommentsCounter {
  [key: string]: number
}
export interface CommentsInitialState {
  commentsState: ICommentsState<IComment[]>
  repliesState: ICommentsState<IComment[]>
  commentsCounter: ICommentsCounter
  repliesCounter: ICommentsCounter
  commentsActivity: {
    [key: string]: ICommentActivity
  }
  viewedCommentsIds: {
    [key: string]: string[]
  }
  isFetchReliesCounter: boolean
  isFetching: boolean
}

export const initialState: CommentsInitialState = {
  commentsState: {},
  repliesState: {},
  commentsCounter: {},
  repliesCounter: {},
  viewedCommentsIds: {},
  commentsActivity: {},
  isFetchReliesCounter: true,
  isFetching: false,
}

const updateItemsState = (
  state: ICommentsState<IComment[]>,
  key: string,
  items: IComment[],
  hasMore?: boolean
): ICommentsState<IComment[]> => {
  const existingHasMore = state[key]?.hasMore || false
  const updatedState = { ...state }

  if (items.length === 0) {
    delete updatedState[key]
  } else {
    updatedState[key] = {
      items: items,
      hasMore: hasMore !== undefined ? hasMore : existingHasMore,
    }
  }

  return updatedState
}

const commentsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.UPDATE_COMMENTS_LIKES: {
      const { oid, uids } = action.payload
      const newState = { ...state }

      const currentLikes = newState.commentsActivity[oid]?.likes || {}
      const currentViews = newState.commentsActivity[oid]?.views || {}

      const updatedLikes = { ...currentLikes }
      const updatedViews = { ...currentViews }
      uids.forEach((uid: string) => {
        updatedLikes[uid] = true
      })

      newState.commentsActivity[oid] = {
        likes: updatedLikes,
        views: updatedViews,
      }

      return newState
    }
    case actionTypes.UPDATE_COMMENTS_VIEWS: {
      const { oid, uids } = action.payload
      const newState = { ...state }

      const currentLikes = newState.commentsActivity[oid]?.likes || {}
      const currentViews = newState.commentsActivity[oid]?.views || {}

      const updatedLikes = { ...currentLikes }
      const updatedViews = { ...currentViews }
      uids.forEach((uid: string) => {
        updatedViews[uid] = true
      })

      newState.commentsActivity[oid] = {
        likes: updatedLikes,
        views: updatedViews,
      }

      return newState
    }

    case actionTypes.SET_COMMENT_ACTIVITY:
      return {
        ...state,
        commentsActivity: {
          ...state.commentsActivity,
          [action.payload.oid]: action.payload.data,
        },
      }
    case actionTypes.SET_VIEWED_COMMENTS_IDS:
      return {
        ...state,
        viewedCommentsIds: {
          ...state.viewedCommentsIds,
          [action.payload.key]: Array.from(
            new Set([...(state.viewedCommentsIds[action.payload.key] || []), ...action.payload.ids])
          ),
        },
      }
    case actionTypes.SET_IS_FETCH_REPLIES_COUNTER:
      return {
        ...state,
        isFetchReliesCounter: action.payload,
      }
    case actionTypes.SET_COMMENTS:
      const targetCommentsState = action.payload.isReply ? 'repliesState' : 'commentsState'
      return {
        ...state,
        [targetCommentsState]: updateItemsState(
          state[targetCommentsState],
          action.payload.key,
          action.payload.items,
          action.payload?.hasMore
        ),
      }
    case actionTypes.SET_COMMENTS_COUNTER:
      const targetCounterState = action.payload.isReply ? 'repliesCounter' : 'commentsCounter'
      const updatedCounterState = { ...state[targetCounterState] }

      updatedCounterState[action.payload.key] = action.payload.count

      return {
        ...state,
        [targetCounterState]: updatedCounterState,
      }

    default:
      return state
  }
}

export default commentsReducer
