import React, { memo } from 'react'
import { ITitleLineProps } from './titleLine.types'
import { Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'

export const TitleLine = memo(({ text, color, ...props }: ITitleLineProps) => {
  if (!text) return <></>
  return (
    <Text color={color || colors.greyMain} fontFamily="inherit" {...props}>
      <LocaleText text={text} />
    </Text>
  )
})
