import React, { memo } from 'react'
import { IMessageList } from './types'
import { MessageItem } from './MessageItem'

export const MessageList = memo(({ messages }: IMessageList) => {
  return (
    <>
      {messages.map((message, index) => (
        <MessageItem key={index} text={message.text} isUserMessage={message.isUserMessage} />
      ))}
    </>
  )
})
