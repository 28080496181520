import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import React from 'react'
import keys from 'constants/keys'
import { RootState } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'
import LocaleText from 'components/LocaleText'
import { CustomNavLink } from '../UI/CustomComponents'
import useLocaleText from 'components/useLocaleText'

export const CellActionMenu = ({ row, setSelectedRow }: any) => {
  const { uid } = useSelector((state: RootState) => state.auth)
  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const admin_actions_button = 'admin_actions'
  const admin_delete_action = 'admin_delete_action'
  const admin_edit_action = useLocaleText('admin_edit_action')
  return (
    <>
      {row.original.uid === uid ? (
        <CustomNavLink to={keys.ROUTE_NAMES.EDIT_USER(row.original.uid)}>
          <Button
            w="max-content"
            maxW="250px"
            borderRadius="10px"
            _hover={{ color: theme?.elementsColor, boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
            _focus={{ color: theme?.elementsColor, boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
            backgroundColor="#f9f9f9"
          >
            {admin_edit_action}
          </Button>
        </CustomNavLink>
      ) : (
        <>
          <Menu>
            <MenuButton
              as={Button}
              w="max-content"
              borderRadius="10px"
              _hover={{ color: theme?.elementsColor, boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
              _focus={{ color: theme?.elementsColor, boxShadow: `0 0 0 1px ${theme?.elementsColor}` }}
              backgroundColor="#f9f9f9"
              flexDir={isRtl ? 'row-reverse' : 'row'}
              //@ts-ignore
              rightIcon="chevron-down"
            >
              <LocaleText text={admin_actions_button} />
            </MenuButton>
            <MenuList minW={'130px'} borderRadius="10px">
              <CustomNavLink to={keys.ROUTE_NAMES.EDIT_USER(row.original.uid)}>
                <MenuItem
                  background="none"
                  _hover={{ background: theme?.backgroundMedium, color: 'white' }}
                  _active={{ backgroundColor: theme?.backgroundMedium }}
                  _focus={{ backgroundColor: theme?.backgroundMedium, color: 'white' }}
                >
                  {admin_edit_action}
                </MenuItem>
              </CustomNavLink>
              <MenuItem
                background="none"
                _hover={{ background: theme?.backgroundMedium, color: 'white' }}
                _active={{ backgroundColor: theme?.backgroundMedium, color: 'white' }}
                _focus={{ backgroundColor: theme?.backgroundMedium, color: 'white' }}
                onClick={() => {
                  setSelectedRow(row)
                }}
              >
                <LocaleText text={admin_delete_action} />
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      )}
    </>
  )
}
