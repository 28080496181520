import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { ValueType } from 'react-select'
import { Range, RangeFocus, RangeKeyDict } from 'react-date-range'
import { Popover, useDisclosure } from '@chakra-ui/core'
import { subDays, getUnixTime, startOfDay, format, fromUnixTime, endOfDay } from 'date-fns'
import { IOption } from 'constants/interfaces'
import { DateRangeContent } from 'components/DateRange/DateRangeContent'
import { BaseSelect } from './BaseSelect'
import { SelectControl } from './SelectControl'
import { SelectOption } from './SelectOption'
import { SelectMenuList } from './SelectMenuList'
import { dateOptionsDefault } from 'constants/baseValues'
import { initialDate } from 'utils'

const defaultRange = {
  key: 'selection',
  startDate: subDays(startOfDay(new Date()), 90),
  endDate: new Date(),
}

interface IProps {
  onChange: (value: { from_ts?: number; to_ts?: number; dateMode: string }) => void
}

export const ByDateSelect = ({ onChange }: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [dateMode, setDateMode] = useState('')
  const [maxDateRange, setMaxDateRange] = useState<undefined | Date>(undefined)
  const [minDateRange, setMinDateRange] = useState<undefined | Date>(undefined)
  const [params] = useSearchParams()
  const [range, setRange] = useState<Range[]>([defaultRange])

  useEffect(() => {
    const dateMode = params.get('dateMode')
    const from_ts = params.get('from_ts')
    const to_ts = params.get('to_ts')

    if (dateMode) {
      setDateMode(dateMode)
      setRange((range) => [
        {
          ...range[0],
          ...(from_ts ? { startDate: fromUnixTime(+from_ts) } : {}),
          ...(to_ts ? { endDate: fromUnixTime(+to_ts) } : {}),
        },
      ])
    }
  }, [params])

  const dateOptions = useMemo(
    () =>
      dateOptionsDefault
        .map((option) => ({
          ...option,
          isSelected: dateMode === option.id,
        }))
        .sort((option, nextOption) => Number(nextOption.isSelected) - Number(option.isSelected)),
    [dateMode]
  )
  const getFromDate = (amountOfSubDays: number) => {
    return getUnixTime(subDays(startOfDay(new Date()), amountOfSubDays))
  }

  const handleDate = (data: ValueType<IOption, false>) => {
    if (data) {
      if (data.id !== '4') {
        setDateMode(data.id)
      }
      switch (data.id) {
        case '4':
          onOpen()
          break
        case '3':
          setRange((prev) => [{ ...prev[0], startDate: subDays(startOfDay(new Date()), 30), endDate: new Date() }])
          onChange({ to_ts: getUnixTime(new Date()), from_ts: getFromDate(30), dateMode: data.id })
          break
        case '2':
          setRange((prev) => [{ ...prev[0], startDate: subDays(startOfDay(new Date()), 7), endDate: new Date() }])
          onChange({ to_ts: getUnixTime(new Date()), from_ts: getFromDate(7), dateMode: data.id })
          break
        case '1':
          const yesterdayDate = subDays(startOfDay(new Date()), 1)
          setRange((prev) => [{ ...prev[0], startDate: yesterdayDate, endDate: yesterdayDate }])
          onChange({ to_ts: getUnixTime(subDays(endOfDay(new Date()), 1)), from_ts: getFromDate(1), dateMode: data.id })
          break
        case '0':
          setRange((prev) => [{ ...prev[0], startDate: new Date(), endDate: new Date(), dateMode: data.id }])
          onChange({ to_ts: getUnixTime(new Date()), from_ts: getUnixTime(startOfDay(new Date())), dateMode: data.id })
          break
      }
    }
  }

  const resetRange = () => {
    setMinDateRange(() => undefined)
    setMaxDateRange(() => undefined)
  }

  const changeDateHandler = (value: RangeKeyDict) => {
    setRange(() => [value.selection])
    setDateMode('4')
  }

  const rangeFocusChangeHandler = (focusedDates: RangeFocus) => {
    if (!focusedDates[0] && focusedDates[1]) {
      setRange((prevState: Range[]) => [{ ...prevState[0], endDate: prevState[0].startDate }])
    }
    if (!focusedDates[0] && !focusedDates[1]) {
      resetRange()
    }
  }

  const handleChange = () => {
    if (range[0].startDate && range[0].endDate) {
      onChange({
        from_ts: getUnixTime(new Date(range[0]?.startDate)),
        to_ts: getUnixTime(new Date(range[0].endDate)),
        dateMode,
      })
      onClose()
    }
  }

  const formatDate = (date: Date) => format(date, 'dd.MM.yy')

  const clearRange = () => {
    setRange([defaultRange])
    setDateMode('')
    onChange({ dateMode: '', ...initialDate })
    onClose()
  }

  return (
    <>
      <BaseSelect
        options={dateOptions}
        onChange={handleDate}
        value={dateOptions.find((option) => option.id === dateMode)}
        components={{
          Option: SelectOption,
          Control: (props) => (
            <SelectControl {...props} onCloseDatePicker={onClose}>
              {range[0].startDate &&
                range[0].endDate &&
                `${formatDate(range[0].startDate)}-${formatDate(range[0].endDate)}`}
            </SelectControl>
          ),
          MenuList: ({ innerRef, children, ...props }) => (
            <SelectMenuList ref={innerRef} onDeleteSelection={clearRange} {...props}>
              {children}
            </SelectMenuList>
          ),
        }}
      />
      <Popover isOpen={isOpen}>
        <DateRangeContent
          onChange={changeDateHandler}
          ranges={range}
          rangeFocusChange={rangeFocusChangeHandler}
          handleFilter={handleChange}
          selectButtonLiteral="search_feed_date_filter_calendar_apply_button"
          minDateRange={minDateRange}
          maxDateRange={maxDateRange}
          className="byDateSelect"
        />
      </Popover>
    </>
  )
}
