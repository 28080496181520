import { useCallback, useEffect, useState } from 'react'
import { IMessage } from './types'
import { RootState } from 'constants/interfaces'
import { useDispatch, useSelector } from 'react-redux'
import { resetReports } from 'redux/actions/reports'
import useLocaleText from 'components/useLocaleText'

interface IWebSocketProps {
  reportsRequestStr: string | undefined
  socketAddress: string
  refreshToken?: string | null
  shouldTypeFirtMessage?: boolean
  shouldFetchData?: boolean
  referrer?: number | null
}
export const useWebSocket = ({
  reportsRequestStr,
  socketAddress,
  refreshToken,
  shouldTypeFirtMessage = true,
  shouldFetchData = true,
  referrer = null,
}: IWebSocketProps) => {
  const {
    translations,
    config: { first_name, last_name },
  } = useSelector((state: RootState) => state.config)
  const { locale } = useSelector((state: RootState) => state.general)
  const dispatch = useDispatch()
  const { isReportLoading } = useSelector((state: RootState) => state.reports)
  const [ws, setWs] = useState<WebSocket | null>(null)
  const [messages, setMessages] = useState<IMessage[]>([])
  const [isBotTyping, setIsBotTyping] = useState(false)
  const [typingCompleted, setTypingCompleted] = useState(false)
  const { isConfigReady, activeGroupID } = useSelector((state: RootState) => state.config)

  const gpt_pop_error_msg = useLocaleText('gpt_pop_error_msg')

  const initialMessages: IMessage[] = shouldTypeFirtMessage
    ? [
        {
          isUserMessage: false,
          text: translations[locale].gpt_literal_1.replace(
            '${name}',
            `${first_name} ${last_name ? ' ' + last_name : ''}`
          ),
        },
      ]
    : []

  useEffect(() => {
    if (!shouldTypeFirtMessage) return
    let currentMessageIndex = 0
    let wordIndex = 0
    let interval: NodeJS.Timeout

    function typeMessageEffect() {
      if (isConfigReady) {
        if (currentMessageIndex < initialMessages.length) {
          const words = initialMessages[currentMessageIndex].text.split(' ')

          if (wordIndex < words.length) {
            setMessages((prevMessages) => {
              if (prevMessages[currentMessageIndex]) {
                const newMessages = [...prevMessages]
                newMessages[currentMessageIndex] = {
                  ...newMessages[currentMessageIndex],
                  text: newMessages[currentMessageIndex].text + (wordIndex > 0 ? ' ' : '') + words[wordIndex],
                }
                return newMessages
              } else {
                return [...prevMessages, { ...initialMessages[currentMessageIndex], text: words[wordIndex] }]
              }
            })

            wordIndex++
          } else {
            wordIndex = 0
            currentMessageIndex++
          }
          if (currentMessageIndex === initialMessages.length - 1 && wordIndex === words.length) {
            setTypingCompleted(true)
          }
        } else {
          clearInterval(interval)
        }
      }
    }

    interval = setInterval(typeMessageEffect, 150)

    return () => {
      clearInterval(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConfigReady, shouldTypeFirtMessage])

  useEffect(() => {
    if (!refreshToken || !shouldFetchData) return
    const websocket = new WebSocket(socketAddress)
    websocket.onopen = () => {
      setWs(websocket)
    }
    websocket.onerror = (error) => {
      console.log('Error WebSocket:', error)
      setMessages((prevMessages) => {
        return [...prevMessages, { text: gpt_pop_error_msg, isUserMessage: false }]
      })
    }
    websocket.onclose = (event) => {
      if (event.wasClean) {
        console.log('Connection closed')
      } else {
        console.log('Connection failed')
      }
    }

    return () => {
      websocket.close()
      dispatch(resetReports())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, refreshToken, socketAddress, shouldFetchData])

  useEffect(() => {
    if ((shouldTypeFirtMessage && !typingCompleted) || !shouldFetchData) return
    if (!isReportLoading && ws) {
      if (!reportsRequestStr) {
        setMessages((prevMessages) => {
          return [...prevMessages, { text: gpt_pop_error_msg, isUserMessage: false }]
        })
      }
      if (reportsRequestStr) {
        ws.send(
          JSON.stringify({
            action: 'ws_conversation',
            message: reportsRequestStr,
            group_id: activeGroupID,
            is_first_msg: true,
            ...(referrer !== null ? { referrer: 1 } : {}),
          })
        )
      }

      ws.onmessage = (event) => {
        const data = JSON.parse(event.data)
        if (data.res_complete) {
          setIsBotTyping(false)
          return
        }
        if (!isBotTyping) {
          setIsBotTyping(true)
        }
        if (!Object.prototype.hasOwnProperty.call(data, 'res_msg')) {
          setIsBotTyping(false)
          setMessages((prevMessages) => {
            return [...prevMessages, { text: gpt_pop_error_msg, isUserMessage: false }]
          })
        }
        if (data.res_msg)
          setMessages((prevMessages) => {
            // Check if the last message is from the bot
            const isLastMessageFromBot =
              prevMessages.length > initialMessages.length &&
              prevMessages[prevMessages.length - 1].isUserMessage === false

            if (isLastMessageFromBot) {
              // Append the new text to the last message
              const updatedMessages = [...prevMessages]
              const latestBotMessage = updatedMessages[updatedMessages.length - 1].text
              updatedMessages[updatedMessages.length - 1].text = latestBotMessage + data.res_msg
              return updatedMessages
            } else {
              // Add the new message to the array
              return [...prevMessages, { text: data.res_msg, isUserMessage: false }]
            }
          })
      }

      ws.onerror = (error) => {
        console.log('Error WebSocket:', error)
        setMessages((prevMessages) => {
          return [...prevMessages, { text: gpt_pop_error_msg, isUserMessage: false }]
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReportLoading, reportsRequestStr, typingCompleted, ws, shouldFetchData, referrer])

  const handleSend = useCallback(
    (inputText: string) => {
      if (inputText) {
        setMessages((prev) => [...prev, { text: inputText, isUserMessage: true }])
        if (ws) {
          ws.send(JSON.stringify({ action: 'ws_conversation', message: inputText, is_first_msg: false }))
        }
      }
    },
    [ws]
  )
  return {
    messages,
    handleSend,
    isBotTyping,
  }
}
