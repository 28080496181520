import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Box, Flex, Text, Button, Menu, MenuButton, MenuList, MenuItem, Divider, Link } from '@chakra-ui/core'
import { useNavigate } from 'react-router-dom'
import { RootState, GeneralState, AuthState } from 'constants/interfaces'
import colors from 'constants/colors'
import keys from 'constants/keys'
import { setLocale } from 'redux/actions/general'
import { logoutHandler } from 'redux/actions/auth'
import Avatar from '../CommonComponents/Avatar'
import LocaleText from '../LocaleText'
import { ReactComponent as IconMenuBack } from 'assets/icon-usermenu-back.svg'
import { ReactComponent as IconLocale } from 'assets/icon-usermenu-language.svg'
import { ReactComponent as IconNotification } from 'assets/icon-usermenu-notifications.svg'
import { ReactComponent as IconLogout } from 'assets/icon-usermenu-logout.svg'
import { ReactComponent as IconMore } from 'assets/icon-usermenu-more.svg'
import { ReactComponent as IconReports } from 'assets/icon-usermenu-repots.svg'
import { BsPuzzle } from 'react-icons/bs'
import { IconWrapper, MiniDrawer } from 'components/helperStyles'
import { privacyPolicy, termsOfUse } from 'constants/endpoints'
import { WhatsAppButton } from 'components/Header/WhatsAppButton'
import { whatsAppUrlSelector } from 'redux/selectors/config'
import useAvatar from 'hooks/useAvatar'
import { UserMenuItem } from '../CommonComponents/UserMenu/UserMenuItem'
import { UserDetails } from 'components/Header/UserDetails'

const DEPLOY_SIGNATURE = `${process.env.REACT_APP_DEPLOY_SIGNATURE}`

interface UserDropDownProps extends GeneralState, Omit<AuthState, 'isSessionClosed'> {
  setLocale: (locale: any) => void
  logoutHandler: () => void
  firstName: string
  lastName: string
  userRole: number
  translationsKeys: string[]
}

const UserDropDown = ({ setLocale, logoutHandler, locale, translationsKeys }: UserDropDownProps) => {
  const navigate = useNavigate()

  const {
    retailConfig,
    config: { myUID },
  } = useSelector((state: RootState) => state.config)

  const avatar = useAvatar(myUID)

  const whatsAppUrl = useSelector(whatsAppUrlSelector)
  const [showLocaleDrawer, setLocaleDrawer] = useState(false)
  const [showSettingsDrawer, setSettingsDrawer] = useState(false)

  const handleLocale = (item: string) => {
    setLocale(item)
  }

  return (
    <Menu>
      <MenuButton
        as={Button}
        borderRadius="20px"
        px={{
          lg: 5,
        }}
        textTransform="capitalize"
        bg="red"
        style={{ backgroundColor: 'none' }}
        _hover={{ background: 'transparent' }}
        _focus={{ background: 'transparent' }}
        _active={{ background: 'transparent' }}
      >
        <Avatar src={avatar} />
      </MenuButton>
      <MenuList
        style={{
          width: '300px',
          overflow: 'hidden',
          border: 'none',
          borderRadius: '12px',
          boxShadow: '0 2px 14px 0 rgba(0,0,0,0.05)',
        }}
        placement="top-end"
      >
        <MiniDrawer className="locale-drawer locale-user-dropdown" isShow={showLocaleDrawer} style={{ zIndex: 9999 }}>
          <Flex alignItems="center" cursor="pointer" pb="20px" px="20px" onClick={() => setLocaleDrawer(false)}>
            <IconMenuBack />
            <Box fontSize="20px" fontWeight="900" mx="20px">
              <LocaleText text="language" />
            </Box>
          </Flex>
          {translationsKeys.map((item: string, index: number) => (
            <MenuItem
              key={index}
              onClick={() => handleLocale(item)}
              textTransform="capitalize"
              cursor="pointer"
              style={{
                fontWeight: locale === item ? 'bold' : 'inherit',
              }}
            >
              <LocaleText text={item} />
            </MenuItem>
          ))}
        </MiniDrawer>
        <MiniDrawer className="locale-drawer" isShow={showSettingsDrawer}>
          <Flex alignItems="center" cursor="pointer" pb="20px" px="20px" onClick={() => setSettingsDrawer(false)}>
            <IconMenuBack />
          </Flex>
          <MenuItem onClick={() => navigate('/custom-reports')} textTransform="capitalize" cursor="pointer">
            <IconWrapper>
              <IconReports />
            </IconWrapper>
            <Text mx="20px"> Custom Reports</Text>
          </MenuItem>
        </MiniDrawer>

        <UserDetails />

        <Divider borderColor="#e2e8f0" mx="12px" />

        {whatsAppUrl && <WhatsAppButton px="1rem" href={whatsAppUrl} />}
        <UserMenuItem px="1rem" literal="language" onClick={() => setLocaleDrawer(true)} icon={<IconLocale />}>
          <IconMore style={{ transform: locale === 'he' ? 'rotate(180deg)' : 'none' }} />
        </UserMenuItem>
        <UserMenuItem px="1rem" icon={<IconNotification />} literal="enable_notification" />
        <UserMenuItem px="1rem" icon={<IconLogout />} literal="logout" onClick={logoutHandler} textColor={colors.red} />
        {!!retailConfig?.integrations?.length && (
          <UserMenuItem
            px="1rem"
            icon={<BsPuzzle />}
            literal="integrations"
            onClick={() => navigate(keys.ROUTE_NAMES.INTEGRATIONS)}
          />
        )}
        <Box px="1rem" pt="20px" pb="10px" fontSize="12px" color={colors.greyDark}>
          <Link href={privacyPolicy}>
            <LocaleText text="legal_privacy" />
          </Link>
          &nbsp;·&nbsp;
          <Link href={termsOfUse}>
            <LocaleText text="legal_terms" />
          </Link>
          &nbsp;·&nbsp; <br />
          {DEPLOY_SIGNATURE}
        </Box>
      </MenuList>
    </Menu>
  )
}
const mapStateToProps = ({ general, config }: RootState) => ({
  locale: general.locale,
  firstName: config.config.first_name,
  lastName: config.config?.last_name,
  userId: config.config.myUID,
  userRole: config.config.user_role,
  translationsKeys: config.translationsKeys,
})

export default connect(mapStateToProps, { setLocale, logoutHandler })(UserDropDown)
