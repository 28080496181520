import React, { forwardRef } from 'react'
import { Button, Spinner } from '@chakra-ui/core'
import colors from 'constants/colors'

interface IProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  isLoading?: boolean
  children: React.ReactNode
}

export const AudioRecordingButton = forwardRef<HTMLButtonElement, IProps>(({ children, onClick, isLoading }, ref) => (
  <Button
    ref={ref}
    minWidth="auto"
    width="40px"
    height="40px"
    cursor="pointer"
    justifyContent="center"
    alignItems="center"
    borderRadius="50%"
    p="0"
    type="submit"
    onClick={onClick}
  >
    {isLoading ? <Spinner size="sm" color={colors.greyDark} /> : children}
  </Button>
))
