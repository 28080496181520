import React from 'react'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { isLoggedInSelector } from 'redux/selectors/auth'
import keys from 'constants/keys'

interface IProps {
  children: JSX.Element
}

export const RequireAuth = ({ children }: IProps) => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  let location = useLocation()
  if (!isLoggedIn) {
    return <Navigate to={keys.ROUTE_NAMES.LOGIN_PAGE} state={{ from: location }} />
  }

  return children
}

export const RequireMfa = ({ children }: IProps) => {
  const { candidateToLogin } = useSelector((state: RootState) => state.auth)
  if (!candidateToLogin) {
    return <Navigate to={keys.ROUTE_NAMES.LOGIN_PAGE} />
  }

  return children
}

export const IntegrationGuard = ({ children }: IProps) => {
  const retailConfig = useSelector((state: RootState) => state.config.retailConfig)
  if (!retailConfig?.integrations?.length) {
    return <Navigate to={keys.ROUTE_NAMES.NEWS_FEED} />
  }

  return children
}
