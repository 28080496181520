import React from 'react'
import { CustomCheckBox } from 'components/CommonComponents/GroupsTree/style'
import { SkeletonText } from 'components/CommonComponents/SkeletonText'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import { FormInputWrapper } from './FormInputWrapper'

interface FormCheckboxProps {
  field: string
  inInvalid: boolean
  id: string
  label: string
  tooltip?: string
  register: any
  isDisabled: boolean
}
export const FormCheckbox = ({ field, id, label, inInvalid, tooltip, register, isDisabled }: FormCheckboxProps) => {
  const { isFetching } = useSelector((state: RootState) => state.admin.userTable)
  const theme = useSelector((state: RootState) => state.general.theme)

  return (
    <FormInputWrapper field={field} id={id} tooltip={tooltip} label={label} inInvalid={inInvalid}>
      <SkeletonText width="18px" isLoaded={!isFetching} height="18px" borderRadius="6px" mr="10px">
        <CustomCheckBox
          id="enable_mfa"
          name="enable_mfa"
          ref={register}
          isDisabled={isDisabled}
          defaultIsChecked={false}
          mr="10px"
          color={theme?.elementsColor}
        />
      </SkeletonText>
    </FormInputWrapper>
  )
}
