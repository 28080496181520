import { Box, Checkbox } from '@chakra-ui/core'
import styled from '@emotion/styled'

import colors from 'constants/colors'

export const ArrowRight = styled(Box)`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 7px solid ${colors.greyMain};
`
export const ArrowLeft = styled(Box)`
  width: 0;
  height: 0;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-right: 7px solid ${colors.greyMain};
`
export const ArrowDown = styled(Box)`
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;

  border-top: 7px solid ${colors.greyMain};
`
export const CustomCheckBox = styled(Checkbox)<{
  mt?: string
  mode?: string
  color?: string
  hoverColor?: string
}>`
  margin-top: ${(props) => (props.mt ? props.mt : '0')};
  margin-bottom: 0 !important;
  position: relative;
  input[type='checkbox']:checked + .css-nlndso {
    border: 0px solid white;
    background-color: ${({ color }) => (color ? color : '#d5158c')};
    border-radius: ${(props) => (props.mode === 'single' ? '10px' : '6px')};
    box-shadow: none;
    width: 18px;
    height: 18px;
  }
  input[type='checkbox']:checked:disabled + .css-nlndso {
    border: 0px solid white;
    background-color: #a4a5a6;
    border-radius: 6px;
    box-shadow: none;
    width: 18px;
    height: 18px;
  }

  .css-1vhqk79 g {
    display: none;
  }
  input[type='checkbox']: not(: checked) + .css-nlndso {
    box-shadow: none;
    border-radius: ${(props) => (props.mode === 'single' ? '10px' : '6px')};
    width: 18px;
    height: 18px;
    border-color: #d2d7df;
    &:hover,
    &:focus {
      background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#fff5f7')};
    }
  }
  input[type='checkbox'][aria-checked='mixed']: not(: checked) + .css-nlndso {
    border-color: ${({ color }) => (color ? color : '#d5158c')};
    background-color: white;
    &:hover,
    &:focus {
      background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#fff5f7')};
    }
  }
  input[type='checkbox'][aria-checked='mixed']: not(: checked):disabled + .css-nlndso {
    border-color: black;
    background-color: white;
    &:hover,
    &:focus {
      background-color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#fff5f7')};
    }
  }

  label {
    position: relative;
  }

  input {
    left: 0;
    top: 0;
  }
`
