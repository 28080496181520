import { Box, Text, Flex, Spinner, Button } from '@chakra-ui/core'
import { ImageSlider } from 'components/PollTask/ImageSlider'
import { AppDispatch } from 'config/redux'
import colors from 'constants/colors'
import { RootState, Subtask } from 'constants/interfaces'
import keys from 'constants/keys'
import { useMediaState } from 'hooks/useMediaState'
import React, { memo, useState } from 'react'
import { IoMdImages } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { updateResults } from 'redux/actions/tasks_v2'
import { FaRegTrashAlt } from 'react-icons/fa'

interface IProps {
  images: string[]
  subTask: Subtask
  contextId: string | null
}

export const ImageSubtask = memo(({ images, subTask, contextId }: IProps) => {
  const { tid, st_id } = subTask
  const dispatch: AppDispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.tasks_v2)
  const { uploadedImagesURL, handleMediaUploading, addMediaHandler, imageInputRef, loading, handleDeleteMedia } =
    useMediaState({
      images,
    })

  const [currentIndex, setCurrentIndex] = useState(0)

  const handleUploadImages = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const data = await handleMediaUploading(e)
    if (!data || !data.uploadedImages.length) return

    const newImages = [...uploadedImagesURL, ...data.uploadedImages]

    dispatch(
      updateResults({
        images: newImages,
        tid,
        st_id,
        type: keys.SUBTASK_TYPES.IMAGE_SUBTASK,
        specificContextId: contextId,
        status: 2,
      })
    )
  }

  const handleDeleteImage = () => {
    handleDeleteMedia(currentIndex)
    const newImages = uploadedImagesURL.filter((_, i) => i !== currentIndex)
    const status = newImages.length > 0 ? 2 : 1
    dispatch(
      updateResults({
        images: newImages,
        tid,
        st_id,
        type: keys.SUBTASK_TYPES.IMAGE_SUBTASK,
        specificContextId: contextId,
        status: status,
      })
    )
  }

  if (loading || isLoading) return <Spinner color="black" size="xl" />

  return (
    <>
      {uploadedImagesURL.length === 0 ? (
        <Flex flexDir="column" alignItems="center" py={'20px'}>
          <Text color={colors.greyDark} pb="10px" fontWeight="600">
            Add images
          </Text>
          <Button
            borderRadius="full"
            p="20px"
            m="auto"
            h="auto"
            bg="black"
            onClick={addMediaHandler}
            cursor="pointer"
            transition={'0.2s ease-in-out'}
            _hover={{ bg: 'black', transform: 'scale(1.1)' }}
            _focus={{ outline: 'none' }}
          >
            <IoMdImages size={46} color="white" />
          </Button>
          <input
            type="file"
            accept="image/*"
            multiple
            ref={imageInputRef}
            style={{ display: 'none' }}
            onChange={handleUploadImages}
          />
        </Flex>
      ) : (
        <Flex w="100%" flexDir="column">
          <Box w="80%" my="20px" borderRadius="20px" mx="auto" overflow="hidden" border="1px solid #f6f5f5">
            <ImageSlider images={uploadedImagesURL} height="400px" onSlideChange={setCurrentIndex} />
          </Box>
          <Flex w="80%" mx="auto" p="10px" justifyContent="center" style={{ columnGap: '25px' }}>
            <Button
              cursor="pointer"
              m="auto"
              h="auto"
              p="10px"
              borderRadius="full"
              transition={'0.2s ease-in-out'}
              _hover={{ transform: 'scale(1.1)' }}
              _focus={{ outline: 'none' }}
              onClick={addMediaHandler}
            >
              <IoMdImages size="23" cursor="pointer" />
            </Button>
            <input
              type="file"
              accept="image/*"
              multiple
              ref={imageInputRef}
              style={{ display: 'none' }}
              onChange={handleUploadImages}
            />

            <Button
              cursor="pointer"
              m="auto"
              h="auto"
              p="10px"
              borderRadius="full"
              transition={'0.2s ease-in-out'}
              _hover={{ transform: 'scale(1.1)' }}
              _focus={{ outline: 'none' }}
              onClick={handleDeleteImage}
            >
              <FaRegTrashAlt size="20" cursor="pointer" />
            </Button>
          </Flex>
        </Flex>
      )}
    </>
  )
})
