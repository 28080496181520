import { Flex, IconButton } from '@chakra-ui/core'
import React, { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setIsReportError } from 'redux/actions/reports'
import { TitleLine } from './TitleLine'
import { isRtlSelector } from 'redux/selectors/general'

interface IBackButtonProps {
  isReportError: boolean
  title: string
}
export const BackButton = memo(({ isReportError, title }: IBackButtonProps) => {
  const isRtl = useSelector(isRtlSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  return (
    <Flex pos="absolute" top="5px" alignItems="center" style={{ columnGap: '5px' }}>
      <IconButton
        aria-label="back"
        icon="chevron-left"
        onClick={() => {
          navigate(-1)
          if (isReportError) dispatch(setIsReportError(false))
        }}
        transform={`rotate(${+isRtl * 180}deg)`}
        fontSize="20px"
        h="20px"
        minW="unset"
        borderRadius="full"
        bg="transparent"
        _focus={{ boxShadow: 'none' }}
        _active={{ background: 'none' }}
      />
      <TitleLine fontSize="20px" text={title} />
    </Flex>
  )
})
