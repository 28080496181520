import { Box, Flex, useDisclosure } from '@chakra-ui/core'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PathComponent } from './PathComponent'
import UsersActionModal from '../Modals'
import keys from 'constants/keys'
import { BackArrow, NavTitleItem } from 'components/Sidebar/SidebarStyles'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { RootState } from 'constants/interfaces'

interface IContentWrapper {
  children: React.ReactNode
}

export const ContentWrapper = ({ children, ...props }: IContentWrapper) => {
  const location = useLocation()
  const navigate = useNavigate()
  const isRtl = useSelector(isRtlSelector)
  const {
    config: { permission },
    isConfigReady,
    isConfigLoading,
  } = useSelector((state: RootState) => state.config)

  const { onClose, isOpen, onOpen } = useDisclosure()
  const goToMainPage = () => {
    if (location.pathname === keys.ROUTE_NAMES.USERS_TABLE) {
      navigate(keys.ROUTE_NAMES.NEWS_FEED)
    } else {
      onOpen()
    }
  }

  useEffect(() => {
    if (permission?.users !== 3 && isConfigReady && !isConfigLoading) {
      navigate(keys.ROUTE_NAMES.NEWS_FEED)
    }
  }, [isConfigLoading, isConfigReady, navigate, permission])

  return (
    <>
      <Box
        alignItems="center"
        height="36px"
        position="absolute"
        top="140px"
        left={isRtl ? 'unset' : '10px'}
        right={isRtl ? '10px' : 'unset'}
      >
        <NavTitleItem onClick={goToMainPage}>
          <BackArrow right="unset" left="unset" />
        </NavTitleItem>
      </Box>
      <Flex
        w={{ base: '90%', md: '90%', lg: '900px', xl: '1000px' }}
        mt="3rem"
        mb="6rem"
        mx={'auto'}
        flexDir="column"
        alignItems="center"
        justifyContent="center"
        {...props}
      >
        <Flex justifySelf="start" width="100%" pos="relative">
          <PathComponent path={location.pathname} />
        </Flex>
        <Flex flexDir="column" background="white" padding="2rem" borderRadius="10px" width="100%" overflowX={'auto'}>
          {children}
        </Flex>
        {isOpen && (
          <UsersActionModal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            onClose={onClose}
            mode={'cancel'}
            actionLiteral="Are you sure you want to cancel precess?"
          />
        )}
      </Flex>
    </>
  )
}
