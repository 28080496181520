import React from 'react'
import { connect } from 'react-redux'
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/core'
import { RootState } from '../../constants/interfaces'
import { setLocale } from '../../redux/actions/general'
import keys from '../../constants/keys'

interface LocaleDropDownProps {
  setLocale: (locale: any) => void
  translationsKeys: string[]
  locale: string
}

const LocaleDropDown = ({ locale, setLocale, translationsKeys }: LocaleDropDownProps): JSX.Element => {
  return (
    <Menu>
      <MenuButton as={Button} borderRadius="20px" px={5} textTransform="capitalize">
        {keys.LOCALE_MAP[locale]}
      </MenuButton>
      <MenuList>
        {translationsKeys.map((item: string, index: number) => (
          <MenuItem
            key={index}
            onClick={() => setLocale(item)}
            textTransform="capitalize"
            style={{
              fontWeight: locale === item ? 'bold' : 'inherit',
            }}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

const mapStateToProps = ({ general, config }: RootState) => ({
  locale: general.locale,
  translationsKeys: config.translationsKeys,
})

export default connect(mapStateToProps, { setLocale })(LocaleDropDown)
