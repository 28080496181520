import { Avatar, Divider, Flex, Radio, Text } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import React, { memo, useMemo } from 'react'
import { IAudienceOptionItem } from './types'
import styled from '@emotion/styled'
import { TooltipList } from '../TooltipList'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

export const CustomAvatar = styled(Avatar)`
  background-color: #e2e8f0;
  .css-6qjm3j {
    width: 75%;
    height: 75%;
  }
`
export const CustomRadio = styled(Radio)<{ color?: string }>`
  input[type='radio']:checked + .css-1rwftkl {
    background-color: ${({ color }) => (color ? color : '#d5158c')};
    border-color: ${({ color }) => (color ? color : '#d5158c')};
  }
`

export const AudienceOptionItem = memo(
  ({
    title,
    icon,
    index,
    setOptionValue,
    optionValue,
    setViewOption,
    selectedUsersUids,
    groupsToPayload,
    groups,
    users,
    selectedTagsIds,
  }: IAudienceOptionItem) => {
    const theme = useSelector((state: RootState) => state?.general?.theme)
    const { retail_users, tags } = useSelector((state: RootState) => state.config.config)
    const post_audience_select_users_description = useLocaleText('post_audience_select_users_description')
    const post_audience_select_groups_description = useLocaleText('post_audience_select_groups_description')
    const post_audience_select_tags_description = useLocaleText('post_audience_select_tags_description')

    const slicedUserNames = useMemo(() => {
      const permittedUsers = retail_users.filter((user) => users.includes(user.uid!))
      let userNames = permittedUsers
        .filter((user) => selectedUsersUids.includes(user.uid!))
        .map((user) => `${user.first_name} ${user.last_name ? user.last_name : ''}`)
      if (userNames)
        return userNames.length === 0
          ? post_audience_select_users_description
          : userNames.length > 4
          ? `${userNames.slice(0, 4).join(', ')} + ${selectedUsersUids.length - 4}`
          : userNames.join(', ')
    }, [post_audience_select_users_description, retail_users, selectedUsersUids, users])

    const slicedGroupNames = useMemo(() => {
      if (!isEmpty(groups)) {
        let groupNames = groupsToPayload.map((key) => groups[key]?.name)
        if (groupNames)
          return groupNames.length === 0
            ? post_audience_select_groups_description
            : groupNames.length > 4
            ? `${groupNames.slice(0, 4).join(', ')} + ${groupNames.length - 4}`
            : groupNames.join(', ')
      }
    }, [groups, groupsToPayload, post_audience_select_groups_description])

    const slicedTagsNames = useMemo(() => {
      if (!isEmpty(tags)) {
        let tagNames = tags.filter((tag) => selectedTagsIds?.includes(tag.sk)).map((tag) => tag.name)
        if (tagNames)
          return selectedTagsIds?.length === 0
            ? post_audience_select_tags_description
            : tagNames.length > 4
            ? `${tagNames.slice(0, 4).join(', ')} + ${tagNames.length - 4}`
            : tagNames.join(', ')
      }
    }, [tags, selectedTagsIds, post_audience_select_tags_description])

    const renderSelectedItems = (index: number) => {
      switch (index) {
        case 0:
          return <Text fontSize="14px">{slicedGroupNames}</Text>
        case 1:
          return <Text fontSize="14px">{slicedUserNames}</Text>
        case 2:
          return <Text fontSize="14px">{slicedTagsNames}</Text>
        default:
          break
      }
    }
    const renderTooltipValue = (index: number) => {
      let value: string[] = []
      let defaultValue = ''

      switch (index) {
        case 0:
          value = groupsToPayload.length > 0 ? groupsToPayload.map((key) => groups[key]?.name) : []
          defaultValue = post_audience_select_groups_description
          return { value, defaultValue }

        case 1:
          const permittedUsers = retail_users.filter((user) => users.includes(user.uid!))
          value = permittedUsers
            .filter((user) => selectedUsersUids.includes(user.uid!))
            .map((user) => `${user.first_name} ${user.last_name ? user.last_name : ''}`)
          defaultValue = post_audience_select_users_description

          return { value, defaultValue }

        default:
          break
      }
    }

    return (
      <>
        <Flex width="100%" justifyContent="space-between" style={{ columnGap: '10px' }}>
          <Flex
            alignItems="baseline"
            flexGrow={1}
            cursor="pointer"
            onMouseUp={() => setViewOption(Number(index))}
            onMouseDown={() => {
              setOptionValue(String(index))
            }}
          >
            <Flex justifyContent="center" flexDirection="row" alignItems="center" style={{ columnGap: '10px' }}>
              <CustomAvatar src={icon} size="md" />
              <Flex flexDirection="column" ml="5px" mr="5px">
                <Text lineHeight="1.5" fontSize="18px" fontWeight={'500'} color="black">
                  {title}
                </Text>
                <TooltipList
                  items={renderTooltipValue(index)?.value}
                  defaultValue={renderTooltipValue(index)?.defaultValue}
                >
                  {renderSelectedItems(index)}
                </TooltipList>
              </Flex>
            </Flex>
          </Flex>
          <CustomRadio
            isChecked={Number(optionValue) === index}
            size="lg"
            value={index}
            onChange={() => setViewOption(Number(index))}
            onMouseUp={() => setViewOption(Number(index))}
            onMouseDown={() => {
              setOptionValue(String(index))
            }}
            color={theme?.elementsColor}
          />
        </Flex>
        <Divider my="5px" color={colors.greyMedium} />
      </>
    )
  }
)
