import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth as AmplifyAuth } from 'aws-amplify'
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js'
import { StorageHelper } from '@aws-amplify/core'
import { jwtDecode } from 'jwt-decode'

const UserPoolId = process.env.REACT_APP_COGNITO_USER_POOL_ID as string
const ClientId = process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID as string

const storage = new StorageHelper().getStorage()

AmplifyAuth.configure({ storage })

const AWSConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    expires: 365,
    cookie: true,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'phone', 'profile'],
    redirectSignIn: process.env.REACT_APP_OAUTH_DOMAIN_REDIRECTSIGNIN,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECTSIGNOUT,
    responseType: process.env.REACT_APP_OAUTH_TOKEN, // or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  storage,
}

//@ts-ignore
export interface IUser extends CognitoUser {
  challengeName?: string
  challengeParam?: { [key: string]: any }
  username: string
}

type CognitoIdToken = string | null
interface IConfig {
  [key: string]: any
}

class Auth {
  private token: string | null = null
  private user: AmazonCognitoIdentity.CognitoUser | null = null
  constructor(config: IConfig) {
    this.configure(config)
  }

  async signIn(userId: string, password: string): Promise<IUser> {
    const user: IUser = await AmplifyAuth.signIn(userId, password)
    return user
  }

  async newPassword(user: IUser, password: string) {
    const loggedUser = await AmplifyAuth.completeNewPassword(user, password)
    return loggedUser
  }

  async confirmLogin(user: IUser, code: string) {
    const loggedUser = await AmplifyAuth.confirmSignIn(user, code)
    return loggedUser
  }

  async federatedSignInWithMicrosoftOIDC(provider: string) {
    try {
      await AmplifyAuth.federatedSignIn({ customProvider: provider })
    } catch (e) {
      console.log('federatedSignInWithMicrosoftOIDC error: ', e)
    }
  }

  async handleAuthenticatedUserManually(tokensObject: any) {
    try {
      // create a CognitoAccessToken using the response accessToken
      const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({
        AccessToken: tokensObject.access_token,
      })
      // create a CognitoIdToken using the response idToken
      const IdToken = new AmazonCognitoIdentity.CognitoIdToken({
        IdToken: tokensObject.id_token,
      })
      // create a RefreshToken using the response refreshToken
      const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({
        RefreshToken: tokensObject.refresh_token,
      })
      // create a session object with all the tokens
      const sessionData = { IdToken, AccessToken, RefreshToken }
      // create the CognitoUserSession using the sessionData
      const session = new AmazonCognitoIdentity.CognitoUserSession(sessionData)
      // create an object with the UserPoolId and ClientId
      const poolData = { UserPoolId, ClientId }
      // pass the poolData object to CognitoUserPool
      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData)
      // create an object containing the username and user pool.
      const userData = { Username: AccessToken.payload.username, Pool: userPool }
      // create a cognito user using the userData object
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData)

      const calculateClockDrift = (iatAccessToken: any, iatIdToken: any) => {
        const now = Math.floor(new Date().getTime() / 1000)
        const iat = Math.min(iatAccessToken, iatIdToken)
        return now - iat
      }
      const idTokenData = jwtDecode(tokensObject.id_token)
      const accessTokenData = jwtDecode(tokensObject.access_token)
      cognitoUser.setSignInUserSession(session)

      this.user = cognitoUser
      this.token = tokensObject.id_token

      storage.setItem(`CognitoIdentityServiceProvider.${ClientId}.LastAuthUser`, idTokenData['cognito:username'])
      storage.setItem(
        `CognitoIdentityServiceProvider.${ClientId}.${idTokenData['cognito:username']}.idToken`,
        tokensObject.id_token
      )
      storage.setItem(
        `CognitoIdentityServiceProvider.${ClientId}.${idTokenData['cognito:username']}.accessToken`,
        tokensObject.access_token
      )
      storage.setItem(
        `CognitoIdentityServiceProvider.${ClientId}.${idTokenData['cognito:username']}.refreshToken`,
        tokensObject.refresh_token
      )
      storage.setItem(
        `CognitoIdentityServiceProvider.${ClientId}.${idTokenData['cognito:username']}.clockDrift`,
        `${calculateClockDrift(accessTokenData.iat, idTokenData.iat)}`
      )
      await AmplifyAuth.currentAuthenticatedUser({
        bypassCache: true, // If set to true, this call will send a request to Cognito to get the latest user data
      })
    } catch (error) {
      console.log(error)
    }
  }

  getToken() {
    return this.token
  }
  getUser() {
    return this.user
  }

  async signOut() {
    try {
      await AmplifyAuth.signOut()
      this.removeToken()
      return true
    } catch (error) {
      console.log('error signing out: ', error)
      return error
    }
  }

  async getUpdatedToken(): Promise<CognitoIdToken | { error: any; token: string | null }> {
    try {
      const data = await AmplifyAuth.currentSession()
      const token = data.getIdToken().getJwtToken()
      document.cookie = `token=${token}; path=/;`
      this.token = token
      return token
    } catch (error) {
      this.removeToken()
      return {
        error,
        token: this.token,
      }
    }
  }

  private removeToken() {
    this.token = null
  }

  private configure(config: any) {
    AmplifyAuth.configure(config)
  }
}

export default new Auth(AWSConfig)
