import { FormControl, FormLabel, Text } from '@chakra-ui/core'
import React, { useMemo, useState } from 'react'
import Select from './Select'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import useLocaleText from 'components/useLocaleText'

interface IDefaultGroupSelect {
  selectedIds: string[]
  setDefaultGroup?: (group: string) => void
  handleSetValue?: (field: string, val: string | number) => void
  field: string
  register: any
  watch?: any
  disabled: boolean
}
export const DefaultGroupSelect = ({
  selectedIds,
  setDefaultGroup,
  handleSetValue,
  field,
  register,
  watch,
  disabled,
}: IDefaultGroupSelect) => {
  const { detailedUser } = useSelector((state: RootState) => state.admin.userTable)
  const { groups } = useSelector((state: RootState) => state.config.config)
  const [selectedOption, setSelectedOption] = useState<any>({})
  const admin_default_group = useLocaleText('admin_default_group')
  const admin_select_placeholder = useLocaleText('admin_select_placeholder')

  const defaultGroupValue = useMemo(() => {
    if (watch) {
      const value = watch('default_group')
      return value
    }
  }, [watch])

  const selectOptions = useMemo(() => {
    if (selectedIds && selectedIds.length > 0 && groups)
      return selectedIds.map((id) => ({
        name: groups[id]?.name,
        id,
      }))
  }, [groups, selectedIds])

  const selectedValue = useMemo(() => {
    if (detailedUser?.['default_group'] && Object.keys(selectedOption).length === 0 && defaultGroupValue) {
      return selectOptions?.find((option) => option.id === detailedUser['default_group'])
    }

    return selectedOption
  }, [defaultGroupValue, detailedUser, selectOptions, selectedOption])

  const onChangeOption = (object: any) => {
    setDefaultGroup?.(object.id)
    setSelectedOption(object)
    if (field) handleSetValue?.(field, object.id)
  }
  const onDeleteSelection = () => {
    setDefaultGroup?.('')
    setSelectedOption({})
    handleSetValue?.('default_group', '')
  }

  return (
    <FormControl pos="relative" display="flex" flexDir={'column'} alignSelf="flex-start">
      <FormLabel htmlFor="default_group" pos="relative" color="#a2a2b6" px="0">
        <Text pb="5px">{admin_default_group}</Text>
        <Select
          disabled={disabled}
          register={register}
          isShowSearch={true}
          field={'default_group'}
          placeholder={admin_select_placeholder}
          onChange={onChangeOption}
          onDeleteSelection={onDeleteSelection}
          options={selectOptions!}
          value={selectedValue}
          minWidth="250px"
        />
      </FormLabel>
    </FormControl>
  )
}
