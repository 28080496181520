import { Flex, Text } from '@chakra-ui/core'
import styled from '@emotion/styled'
import LocaleText from 'components/LocaleText'
import { RootState } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { OptionProps } from 'react-select'

const CustomOptionWrapper = styled(Flex)<{ data: any; selectedValue?: string | number | null; color?: string }>`
  height: 50px;
  align-items: center;
  padding: 0 15px;
  gap: 10px;
  color: black;
  border-radius: 10px;
  background-color: ${({ data, selectedValue, color }) =>
    selectedValue !== undefined && data.id === selectedValue ? color : 'none'};
  &:hover {
    background: ${({ color }) => color};
  }
`

export interface IOption {
  name: string
  id: string | number
  code?: string
  avatar?: string
  isSelected?: boolean
}

interface ISelectionOptionProps extends OptionProps<IOption, false> {
  selectedValue?: string | number
  field: string
}

export const SelectOption = ({ selectedValue, field, data, ...props }: ISelectionOptionProps) => {
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <CustomOptionWrapper
      data={data}
      selectedValue={selectedValue}
      color={theme?.backgroundMedium}
      {...props.innerProps}
    >
      <Text>
        {data.avatar || ''} <LocaleText text={data.name || ''} />{' '}
        {`${field !== 'permission' && field !== 'default_group' && field !== 'user_group_role' ? `(${data.id})` : ''}`}
      </Text>
    </CustomOptionWrapper>
  )
}
