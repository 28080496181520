/* eslint-disable no-case-declarations */
import { ITasksOverviewDetailState } from 'constants/interfaces'
import keys from 'constants/keys'
import { actionTypes } from '../actions/tasksOverviewDetail'

export const initialState: ITasksOverviewDetailState = {
  currentStore: '',
  refId: '',
  groupId: '',
  currentAnalytic: [],
  currentTask: null,
  currentCategory: '',
  allGroupKeys: [],
  currentGroupKeys: [],
  availableGroups: [],
  hasMoreGroups: false,
  isFetching: false,
  isPageReady: false,
  statusFilter: [
    {
      counter: 0,
      title: 'filter_status_todo',
      value: keys.TASK_STATUSES.TODO.value,
      isChecked: true,
    },
    {
      counter: 0,
      title: 'filter_status_inprogress',
      value: keys.TASK_STATUSES.INPROGRESS.value,
      isChecked: true,
    },
    {
      counter: 0,
      title: 'filter_status_done',
      value: keys.TASK_STATUSES.DONE.value,
      isChecked: true,
    },
  ],
  isTileMode: false,
  subtasks: {},
  isLoadingSubtasks: false,
  isOpenStatusModal: false,
  activeStatusFilter: [0, 1, 2],
  currentSubtasks: {},
}

const tasksOverviewDetailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.payload,
      }
    case actionTypes.SET_INITIAL_DATA:
      // eslint-disable-next-line no-case-declarations
      const updatedFilter = state.statusFilter.map((item) => {
        item.counter = action.payload.statusFilter[item!.value!]
        return item
      })
      return {
        ...state,
        ...action.payload,
        statusFilter: updatedFilter,
      }

    case actionTypes.UPDATE_FILTER:
      return {
        ...state,
        ...action.payload,
      }
    case actionTypes.SET_CATEGORY:
      return {
        ...state,
        currentCategory: action.payload,
      }
    case actionTypes.SET_GROUP_KEYS:
      return {
        ...state,
        allGroupKeys: [...action.payload],
      }
    case actionTypes.SET_CURRENT_KEYS:
      return {
        ...state,
        currentGroupKeys: [...action.payload],
      }
    case actionTypes.SET_TASK_ANALYTIC:
      return {
        ...state,
        currentAnalytic: [...action.payload],
      }
    case actionTypes.SET_TASK_GROUPS:
      return {
        ...state,
        availableGroups: action.payload,
      }
    case actionTypes.SET_HAS_MORE:
      return {
        ...state,
        hasMoreGroups: action.payload,
      }
    case actionTypes.SET_IS_READY:
      return {
        ...state,
        isPageReady: action.payload,
      }
    case actionTypes.SET_CURRENT_TASK:
      return {
        ...state,
        currentTask: action.payload,
      }
    case actionTypes.RESET_FILTER:
      return {
        ...state,
        statusFilter: [...initialState.statusFilter],
      }
    case actionTypes.RESET_STATE:
      return {
        ...initialState,
      }
    case actionTypes.RESET_GROUPS:
      return {
        ...state,
        availableGroups: [],
      }
    case actionTypes.SET_IS_TILE_MODE:
      return {
        ...state,
        isTileMode: action.payload,
      }
    case actionTypes.SET_SUBTASKS:
      return {
        ...state,
        subtasks: action.payload,
      }
    case actionTypes.SET_SUBTASKS_OF_CURRENT_TASK:
      return {
        ...state,
        currentSubtasks: { ...state.currentSubtasks, ...action.payload },
      }
    case actionTypes.SET_SUBTASKS_IS_LOADING:
      return {
        ...state,
        isLoadingSubtasks: action.payload,
      }
    case actionTypes.SET_IS_STATUS_MODAL_OPEN:
      return {
        ...state,
        isOpenStatusModal: action.payload,
      }
    case actionTypes.SET_ACTIVE_FILTER_STATUSES:
      return {
        ...state,
        activeStatusFilter: action.payload,
      }
    default:
      return state
  }
}

export default tasksOverviewDetailReducer
