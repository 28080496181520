import { formatRelativeDateTime } from 'components/Tasks/utils'
import { IConfigRetailUser, IConfigStateGroups, ITag, Result, Results, Task } from 'constants/interfaces'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import { calculateContextID, TransformedDataWithCounts } from 'redux/actions/tasks_v2'
import { calculateRecurrenceTimestamp, extractSubstringAfterDelimiter, getTokenFromCookie } from 'utils'

interface IBuildPollOptions {
  options: { [key: string]: string }
  pollResults: Result[]
  permittedUsers: string[]
  permittedGroups: IConfigStateGroups
  insights: TransformedDataWithCounts
  groups: IConfigStateGroups
  retailUsersObject: { [key: string]: IConfigRetailUser }
}
export const buildPollOptions = ({
  options,
  pollResults,
  permittedUsers,
  permittedGroups,
  insights,
  groups,
  retailUsersObject,
}: IBuildPollOptions) => {
  return Object.entries(options).map(([key, label]) => {
    const currentQuestionResults = pollResults.filter(
      (result) =>
        result?.poll_selections?.includes(parseInt(key, 10)) &&
        (Object.keys(retailUsersObject).includes(result.context_id) || Object.keys(groups).includes(result.context_id))
    )

    const count = currentQuestionResults.length

    const currentQuestionParticipants = currentQuestionResults.map((result) => {
      return {
        id: result.context_id,
        date: formatRelativeDateTime(result.updated_at_ts as number),
        isClickable:
          permittedUsers.includes(result.context_id) || Object.keys(permittedGroups).includes(result.context_id),
        insights: insights?.[result.context_id],
      }
    })

    return { label, count, currentQuestionParticipants }
  })
}

export const isExternalLink = (link: string) =>
  link.includes(keys.EXTERNAL_LINK_PHRASE) || link.includes(keys.FORMS_STOREE)

export const isSubtaskAlreadyProcessed = ({
  tid,
  st_id,
  contextId,
  userResults,
}: {
  tid: string
  st_id: string
  userResults: Results
  contextId?: string | null
}) => {
  const subtasks = userResults[tid]
  if (!subtasks) return false

  return subtasks.some((subtask) => {
    if (contextId) {
      return subtask.st_id === st_id && subtask.context_id === contextId
    }
    return subtask.st_id === st_id
  })
}
type getFormattedLinkProps = {
  link: string
  tasks: Task[]
  tid: string
  st_id: string
  contextId: string
  uid: string
  userResults: Results
  rid: string
  tags: ITag[]
  first_name: string
  last_name: string
  default_group: string | null
  groups: IConfigStateGroups
  activeGroupID: string
}
export const getFormattedLink = ({
  link,
  tasks,
  tid,
  st_id,
  contextId,
  uid,
  userResults,
  rid,
  tags,
  first_name,
  last_name,
  default_group,
  groups,
  activeGroupID,
}: getFormattedLinkProps) => {
  if (!isExternalLink(link)) {
    return link
  }
  const task = tasks.find((task) => task.tid === tid)
  if (!task) {
    throw new Error('Task not found')
  }
  const token = getTokenFromCookie()
  const version = 2
  const utc_offset = 3
  const { recipient_type, recurrence_start_time, is_individual_results, retail_id_context_id } = task
  const taskContextId = calculateContextID({
    recipient_type,
    is_individual_results,
    uid,
    groupId: activeGroupID,
    tagId: recipient_type === 2 ? extractSubstringAfterDelimiter(retail_id_context_id, rid) : null,
    retail_id_context_id,
    rid,
    tags,
  })
  const userResultOfCurrentSubtask = userResults?.[tid]?.find(
    (result) => result.st_id === st_id && result.context_id === contextId
  )
  const dateNow = dayjs().format('YYYYMMDDHHmmss')
  const recurrenseTimestamp = calculateRecurrenceTimestamp({
    dateNow,
    startTime: task?.is_recurring ? recurrence_start_time : dateNow,
    utc_offset: 3,
  })
  const task_context_id = extractSubstringAfterDelimiter(task.retail_id_context_id, rid)

  const isFirstResult = !userResultOfCurrentSubtask
  const recurrence_ts = isFirstResult
    ? recurrenseTimestamp
    : extractSubstringAfterDelimiter(userResultOfCurrentSubtask?.context_id_recurrence_ts!, taskContextId)

  const params = {
    token,
    subtask_id: st_id,
    version,
    is_individual_results,
    context_id: taskContextId,
    is_first_result: isFirstResult,
    recurrence_ts: recurrence_ts,
    recurrence_start_time: recurrence_start_time,
    utc_offset,
    recipient_type,
    first_name: first_name,
    task_context_id,
    last_name: last_name ?? '',
    branch_name: default_group ? groups?.[default_group].name : '',
  }

  const queryString = Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined) // Filter out null or undefined
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`) // Encode and map to key=value
    .join('&')

  return `${link}?${queryString}`
}
