import { Box, Flex } from '@chakra-ui/core'
import React, { memo } from 'react'
import { ReactComponent as LikeIcon } from '../../assets/like.svg'
import { ReactComponent as LikeIconActive } from '../../assets/like-active.svg'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface ILikeButton {
  likes_counter: number
  liked: {
    [key: string]: boolean
  }

  created_by: string
  isShowLikeButton: boolean
  isLikedPost: boolean
  handleLikesModal?: () => void
  isAllPostCollapsed?: boolean
  isDoublePost?: boolean
}

export const LikeButton = memo(
  ({
    likes_counter,
    liked,
    created_by,
    isShowLikeButton,
    handleLikesModal,
    isLikedPost,
    isAllPostCollapsed,
    isDoublePost,
  }: ILikeButton) => {
    const filteredLikesCount = Object.keys(liked).filter((userId) => userId !== created_by).length
    const { theme } = useSelector((state: RootState) => state.general)

    return (
      <Flex
        alignItems="center"
        cursor="pointer"
        w={isAllPostCollapsed && !isDoublePost ? '30%' : '10%'}
        justifyContent="flex-end"
      >
        <Box
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation()
            handleLikesModal?.()
          }}
          maxW="70%"
          minW="50%"
          textAlign="end"
          fontSize="13px"
        >
          {likes_counter}/{filteredLikesCount}
        </Box>
        {isShowLikeButton ? (
          <Flex px="0" mb="-2px">
            {isLikedPost ? (
              <LikeIconActive
                width="18px"
                height="18px"
                style={{ '--svg-color': theme?.elementsColor } as React.CSSProperties}
              />
            ) : (
              <LikeIcon width="18px" height="18px" />
            )}
          </Flex>
        ) : (
          <Flex px="0" mb="-2px">
            <LikeIcon width="18px" height="18px" opacity={0.1} />
          </Flex>
        )}
      </Flex>
    )
  }
)
