import { getTranslationsKeys } from 'utils'
import { IConfigState } from 'constants/interfaces'
import { actionTypes } from '../actions/config'
import keys from '../../constants/keys'
import translations from 'config/translations'
import strings from 'constants/strings'

export const initialState: IConfigState = {
  config: {
    maxGroupLevel: strings.DEFAULT_MAX_GROUP_LEVEL,
    myUID: '',
    force_update: null,
    default_group: null,
    first_name: '',
    groups: {},
    last_name: '',
    is_cover_image_contain: false,
    max_simultaneously_get_tasks_groups_ids: null,
    read_permitted_groups_ids: [],
    user_group_role: null,
    rid: '',
    user_role: 0,
    user_groups: null,
    retail_users: [],
    mappedRetailUsersByGroups: {},
    profile_img_url: '',
    cdn: '',
    avatars_path: '',
    user_tags: [],
    tags: [],
    retailUsersObject: {},
    permission: null,
  },
  retailConfig: null,
  statusMenus: null,
  locale: keys.DEFAULT_LOCALE,
  selectedStore: null,
  translations: translations,
  translationsKeys: getTranslationsKeys(translations),
  activeGroupID: null,
  userWritePermittedContext: { groups: {}, users: [] },
  isConfigReady: false,
  userWriteTaskPermissions: { groups: {}, users: [] },
  isConfigLoading: false,
  encodedUID: '',
  configErrorStatus: null,
}

const configReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_FULL_USER_NAME:
      return {
        ...state,
        config: {
          ...state.config,
          first_name: action.payload.firstName,
          last_name: action.payload.lastName,
        },
      }
    case actionTypes.SET_RETAIL_USERS_OBJECT:
      return {
        ...state,
        config: {
          ...state.config,
          retailUsersObject: action.payload,
        },
      }
    case actionTypes.SET_RETAIL_USERS:
      return {
        ...state,
        config: {
          ...state.config,
          retail_users: action.payload,
        },
      }
    case actionTypes.SET_IS_CONFIG_LOADING:
      return {
        ...state,
        isConfigLoading: action.payload,
      }
    case actionTypes.SET_CONFIG:
      return {
        ...state,
        config: {
          ...action.config,
          force_update: action.config.force_update || state.config.force_update,
        },
        isConfigReady: true,
      }

    case actionTypes.SET_RETAIL_CONFIG:
      return {
        ...state,
        retailConfig: {
          ...action.payload,
        },
      }

    case actionTypes.SET_STATUS_MENUS:
      return {
        ...state,
        statusMenus: action.menus,
      }
    case actionTypes.SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
        translationsKeys: getTranslationsKeys(action.payload),
      }
    case actionTypes.SET_ACTIVE_GROUP_ID:
      return {
        ...state,
        activeGroupID: action.payload,
      }
    case actionTypes.SET_USER_WRITE_PERMITTED_CONTEXT:
      return {
        ...state,
        userWritePermittedContext: action.payload,
      }
    case actionTypes.SET_USER_WRITE_TASK_PERMISSIONS:
      return {
        ...state,
        userWriteTaskPermissions: action.payload,
      }
    case actionTypes.SET_ENCODED_UID:
      return {
        ...state,
        encodedUID: action.payload,
      }
    case actionTypes.SET_MAPPED_RETAIL_USERS_BY_GROUPS:
      return {
        ...state,
        config: {
          ...state.config,
          mappedRetailUsersByGroups: action.payload,
        },
      }
    case actionTypes.SET_CONFIG_ERROR_STATUS:
      return {
        ...state,
        configErrorStatus: action.payload,
      }
    default:
      return state
  }
}

export default configReducer
