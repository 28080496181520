import { Box, Button, Divider, Flex, IconButton, RadioGroup, Text } from '@chakra-ui/core'
import { CustomRadio } from 'components/CommonComponents/AudiencePublicationModal/AudienceOptionItem'
import BaseModal from 'components/CommonComponents/BaseModal'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { Fragment, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps {
  isOpen: boolean
  closeModal: () => void
  selectedTaskLink: string | null
  setSelectedTaskLink: (value: string) => void
  setSelectedChatLink: (value: string) => void
  allTasks: {
    task_view_id?: string | number
    task_view_name: string
    color?: string
    icon?: string
    order?: number
    literal?: string
  }[]
}
export const TasksLinksModal = ({
  isOpen,
  closeModal,
  selectedTaskLink,
  setSelectedTaskLink,
  setSelectedChatLink,
  allTasks,
}: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)

  const [value, setValue] = useState(selectedTaskLink ?? '')

  const handleSubmit = () => {
    closeModal()
    setSelectedTaskLink(value)
    setSelectedChatLink('')
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.toString())
  }

  const cancelTaskSelection = () => {
    setValue('')
  }
  return (
    <BaseModal
      isShowAnimation={true}
      isOpen={isOpen}
      onClose={closeModal}
      hideCloseButton={true}
      overflowY="clip"
      headerContent={
        <>
          <Flex alignItems="center" justifyContent="space-between" dir={isRtl ? 'rtl' : 'ltr'}>
            <Box onClick={closeModal} mx="4" position="absolute">
              <IconButton
                aria-label="close groups"
                icon={BsArrowLeftShort}
                borderRadius="50%"
                style={{ color: colors.greyMain }}
                fontSize="32px"
                transform={`rotate(${+isRtl * 180}deg)`}
              />
            </Box>

            <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
              <LocaleText text="link_task" />
            </Text>
          </Flex>
          <Divider pt="5px" />
        </>
      }
      bodyContent={
        <RadioGroup value={value} px="25px" mt="20px">
          {allTasks &&
            allTasks.map((task, index: number) => (
              <Fragment key={index}>
                <CustomRadio
                  onChange={handleSelect}
                  isChecked={value === task?.task_view_id?.toString()}
                  w="100%"
                  justifyContent="space-between"
                  flexDir="row-reverse"
                  value={task?.task_view_id?.toString()}
                  size="lg"
                  color={theme?.elementsColor}
                  key={index}
                >
                  <Flex flexDir="row" alignItems="center" color={colors.blackLight}>
                    <Text mx="10px">
                      <LocaleText text={task.task_view_name ? task.task_view_name : task.literal ?? ''} />
                    </Text>
                  </Flex>
                </CustomRadio>
                <Divider pt="5px" />
              </Fragment>
            ))}
        </RadioGroup>
      }
      footerContent={
        <>
          <Flex justifyContent="space-between" background="white" px="6" py={5} w="full">
            <Button
              borderRadius="20px"
              onClick={handleSubmit}
              color="white"
              textTransform="uppercase"
              background={theme?.elementsColor}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="post_audience_done" />
            </Button>
            <Button
              variant="outline"
              borderRadius="20px"
              onClick={cancelTaskSelection}
              color={colors.tomato}
              textTransform="uppercase"
              background={colors.tealLight}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="cancel" />
            </Button>
          </Flex>
        </>
      }
    />
  )
}
