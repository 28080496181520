import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'

export const REACT_FIREBASE_API_KEY: any = process.env.REACT_APP_FIREBASE_API_KEY
export const REACT_FIREBASE_AUTH_DOMAIN: any = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN
export const REACT_FIREBASE_PROJECT_ID: any = process.env.REACT_APP_FIREBASE_PROJECT_ID
export const REACT_FIREBASE_STORAGE_BUCKET: any = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
export const REACT_FIREBASE_MESSAGING_SENDER_ID: any = process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
export const REACT_FIREBASE_APP_ID: any = process.env.REACT_APP_FIREBASE_APP_ID
export const REACT_FIREBASE_MEASUREMENT_ID: any = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

const firebaseConfig = {
  apiKey: REACT_FIREBASE_API_KEY,
  authDomain: REACT_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_FIREBASE_PROJECT_ID,
  storageBucket: REACT_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_FIREBASE_APP_ID,
  measurementId: REACT_FIREBASE_MEASUREMENT_ID,
}
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
