// eslint-disable-next-line @typescript-eslint/no-use-before-define
import React, { useEffect, useState, useRef, useMemo } from 'react'
import {
  Box,
  Flex,
  Tooltip,
  Avatar,
  InputGroup,
  InputRightElement,
  Spinner,
  Button,
  Text,
  Input,
  useDisclosure,
  Image,
} from '@chakra-ui/core'
import { useSelector } from 'react-redux'
import isSolid from 'is-solid'
import { ReactComponent as MicrophoneIcon } from 'assets/microphone-icon.svg'
import paperClipImg from 'assets/paper-clip.png'

import colors from 'constants/colors'
import strings from 'constants/strings'
import { ReactComponent as CameraIcon } from 'assets/icon-capture.svg'
import { ReactComponent as IconSend } from 'assets/icon-send.svg'
import useAvatar from 'hooks/useAvatar'
import useLocaleText from 'components/useLocaleText'
import LocaleText from 'components/LocaleText'
import { AudioRecorder } from 'components/AudioRecorder'
import CameraModal from 'components/CommonComponents/CameraModal'
import { PreviewFile } from 'config/types'
import { FileModal } from 'components/FileModal'
import { IHandleCreateComment } from 'constants/interfaces'
import { isRtlSelector } from 'redux/selectors/general'
import { CustomFieldWrapper, CustomText, CustomTextArea } from 'components/helperStyles'
import { InputTooltip } from './InputTooltip'
import { AudioRecordingButton } from './AudioRecordingButton'
import { userIdSelector } from 'redux/selectors/config'
import { isValidDomain } from 'utils'
import * as linkify from 'linkifyjs'
import { LinkPreview } from 'components/LinkPreview'

type Media = PreviewFile | null

interface IProps {
  postID: string
  handleCreateComment: (data: IHandleCreateComment) => Promise<void>
  sendCommentLoading: boolean
  isFocused: boolean
  replyCID?: string
  userTag?: string
  closeCallback?: () => void
  isReply?: boolean
  isChat?: boolean
}

const CommentInput = ({
  postID,
  handleCreateComment,
  sendCommentLoading,
  userTag,
  isFocused = false,
  closeCallback,
  isReply,
  isChat = false,
}: IProps) => {
  const fileFormRef = useRef<HTMLFormElement | null>(null)
  const commentInputRef = useRef<HTMLTextAreaElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const [isRecording, setIsRecording] = useState(false)
  const [commentText, setCommentText] = useState<string>('')
  const [image, setImage] = useState<Media>(null)
  const [video, setVideo] = useState<Media>(null)
  const [file, setFile] = useState<Media>(null)
  const [isFormValid, setIsFormValid] = useState<boolean>(false)

  const isAvailableAttachingMedia = useMemo(() => !!commentText, [commentText])

  const { onClose: onCloseCameraModal, onOpen: onOpenCameraModal, isOpen: isOpenCameraModal } = useDisclosure()
  const { isOpen: isOpenFileModal, onOpen: onOpenFileModal, onClose: onCloseFileModal } = useDisclosure()

  const userId = useSelector(userIdSelector)
  const isRtl = useSelector(isRtlSelector)
  const avatar = useAvatar(userId)

  const t_write_a_comment = useLocaleText('write_a_comment')
  const attachFileLiteral = useLocaleText('post_attach_file_web_hover')
  const attachPhotoLiteral = useLocaleText('post_attach_photo_web_hover')
  const attachRecordLiteral = useLocaleText('post_attach_record_web_hover')

  const TEXTAREA_ID = postID + userTag

  const foundLinks = linkify.find(commentText)
  const validLinks = foundLinks.filter((link) => isValidDomain(link.href))

  const onSubmit = async (e?: any, audioLink?: PreviewFile) => {
    if (e) {
      e.preventDefault()
    }
    if (!checkForm() && !audioLink) return
    await handleCreateComment({ comment: commentText, audios: audioLink })
    setVideo(null)
    setImage(null)
    setCommentText('')

    if (commentInputRef.current) commentInputRef.current.blur()

    if (closeCallback) {
      closeCallback()
    }
  }

  const onEnter = (e: React.KeyboardEvent) => {
    if (document.activeElement && document.activeElement.id === TEXTAREA_ID && e.key === 'Enter' && !e.shiftKey) {
      onSubmit()
      return true
    }
  }

  const onResizeTextareaHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentText(e.target.value)
  }

  const checkForm = (): boolean => {
    let isValid = !!commentText.length

    return isValid
  }

  useEffect(() => {
    setIsFormValid(checkForm())
    // eslint-disable-next-line
  }, [commentText])

  useEffect(() => {
    if (isFocused) {
      if (commentInputRef.current) {
        commentInputRef.current.setSelectionRange(
          commentInputRef.current.value.length,
          commentInputRef.current.value.length
        )
        commentInputRef.current.click()
      }
    }
  }, [isFocused])

  const handleFileInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files)
      const file: Media = {
        file: files[0],
        previewUrl: URL.createObjectURL(files[0]),
      }
      setFile(() => file)
      onOpenFileModal()
      fileFormRef.current?.reset()
    }
  }

  const sendRecord = async (audioLink: PreviewFile) => {
    if (audioLink) {
      onSubmit(null, audioLink)
    }
    setIsRecording(false)
  }

  const cameraModalHandler = async (data: PreviewFile) => {
    await handleCreateComment({ comment: '', images: data })
  }

  const fileModalHandler = async () => {
    if (file) {
      await handleCreateComment({ comment: '', file })
      onCloseFileModal()
    }
  }

  const onChangeHeightHandler = () => {
    if (!commentText && commentInputRef.current) {
      commentInputRef.current.style.height = '40px'
    }
  }

  const attachFileHandler = () => {
    fileInputRef.current?.click()
  }

  const stopRecording = () => setIsRecording(false)

  const startRecording = () => {
    setIsRecording(true)
  }

  return (
    <Box w="90%">
      {!!validLinks && validLinks.length > 0 && (
        <LinkPreview width="100%" url={validLinks[validLinks.length - 1].href} />
      )}
      <Flex mt="10px" mb={isChat ? '10px' : '5px'} alignItems="center">
        <Box>
          <Avatar src={avatar} size="sm" style={{ width: '32px', height: '32px' }} />
        </Box>
        <Flex width={isReply ? '50%' : isChat ? '100%' : '75%'} minW="290px" flexDirection="column">
          <Flex width="100%">
            {!isRecording && (
              <CustomFieldWrapper flexWrap="wrap" mx="3" flex={1}>
                <InputGroup size="sm" flex={1}>
                  {isSolid(userTag) && (
                    <CustomText
                      position="absolute"
                      top={-18}
                      left={isRtl ? 'auto' : 2}
                      right={isRtl ? 2 : 'auto'}
                      fontSize="xs"
                    >
                      <LocaleText text="reply_to_title" />{' '}
                      <Text as="span" fontWeight={700} mb="10px">
                        {userTag}
                      </Text>
                    </CustomText>
                  )}
                  <CustomTextArea
                    placeholder={t_write_a_comment}
                    name="comment"
                    id={TEXTAREA_ID}
                    value={commentText}
                    disabled={sendCommentLoading || isRecording}
                    ref={commentInputRef}
                    onChange={onResizeTextareaHandler}
                    onKeyPress={onEnter}
                    onHeightChange={onChangeHeightHandler}
                    maxRows={5}
                    height="40px"
                    style={{
                      paddingRight: isRtl ? '8px' : '85px',
                      paddingLeft: isRtl ? '85px' : '8px',
                    }}
                  />
                  <InputRightElement
                    width="5rem"
                    top="5px"
                    right={isRtl ? 'auto' : '5px'}
                    left={isRtl ? '5px' : 'auto'}
                  >
                    <InputTooltip
                      tooltipText={attachPhotoLiteral}
                      isDisabled={isAvailableAttachingMedia}
                      onClick={onOpenCameraModal}
                    >
                      <CameraIcon />
                    </InputTooltip>
                    <InputTooltip
                      tooltipText={attachFileLiteral}
                      isDisabled={isAvailableAttachingMedia}
                      onClick={attachFileHandler}
                    >
                      <Image src={paperClipImg} />
                    </InputTooltip>
                  </InputRightElement>
                </InputGroup>
              </CustomFieldWrapper>
            )}
            {isFormValid ? (
              <Button
                onClick={onSubmit}
                bg={sendCommentLoading ? 'transparent' : colors.greenLight2}
                minWidth="auto"
                width="40px"
                height="40px"
                cursor="pointer"
                justifyContent="center"
                alignItems="center"
                borderRadius="50%"
                p="0"
                type="submit"
              >
                {sendCommentLoading ? (
                  <Spinner size="sm" color={colors.greyDark} />
                ) : (
                  <IconSend width="25px" height="25px" />
                )}
              </Button>
            ) : isRecording ? (
              <AudioRecorder isRecording={isRecording} onSuccess={sendRecord} onStopRecording={stopRecording} />
            ) : (
              <Tooltip
                aria-label={attachRecordLiteral}
                label={attachRecordLiteral}
                zIndex={100}
                fontSize="14px"
                p="10px"
                placement="top"
                closeOnClick
              >
                <AudioRecordingButton onClick={startRecording} isLoading={sendCommentLoading}>
                  <MicrophoneIcon width="25px" height="25px" />
                </AudioRecordingButton>
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </Flex>
      <form ref={fileFormRef}>
        <Input
          position="absolute"
          overflow="hidden"
          visibility="hidden"
          width="0px"
          height="0px"
          zIndex={-1300}
          type="file"
          accept={strings.POST_FILE_ACCEPT_EXTENSIONS}
          ref={fileInputRef}
          onChange={handleFileInput}
        />
      </form>
      <CameraModal isOpen={isOpenCameraModal} onClose={onCloseCameraModal} sendImage={cameraModalHandler} />
      <FileModal
        isOpen={isOpenFileModal}
        onClose={onCloseFileModal}
        onSubmitFile={fileModalHandler}
        file={image?.file || video?.file || file?.file}
      />
    </Box>
  )
}

export default CommentInput
