import { Button, Flex, Text, useDisclosure } from '@chakra-ui/core'
import React, { memo, useCallback, useState } from 'react'
import { useTable, usePagination, useRowSelect, useGlobalFilter } from 'react-table'
import { useSelector } from 'react-redux'
import { IConfigRetailUser, RootState } from 'constants/interfaces'
import { Loader } from 'components/CommonComponents/Loader'
import { CellActionMenu } from './CellActionMenu'
import { NavItem } from 'components/helperStyles'
import UsersActionModal from '../Modals'
import useLocaleText from 'components/useLocaleText'
import { isRtlSelector } from 'redux/selectors/general'
import { ContentWrapper } from '../UI/ContentWrapper'
import LocaleText from 'components/LocaleText'
import { CellAvatarBlock } from './CellAvatarBlock'
import { SearchUsers } from '../UI/SearchUsers'
import { TablePagination } from './TablePagination'
import { CellRoleBlock } from './CellRoleBlock'

const UserTable = memo(() => {
  const { isLoading, usersList } = useSelector((state: RootState) => state.admin.userTable)
  const theme = useSelector((state: RootState) => state.general.theme)
  const isRtl = useSelector(isRtlSelector)
  const [users] = useState<IConfigRetailUser[]>(usersList && usersList?.length > 0 ? usersList : [])
  const { onClose, isOpen, onOpen } = useDisclosure()
  const [selectedRow, setSelectedRow] = useState(null)
  const admin_user_info = useLocaleText('admin_user_info')
  const admin_uid = useLocaleText('admin_uid')
  const admin_desc = useLocaleText('admin_desc')
  const admin_sec_uid = useLocaleText('admin_sec_uid')
  const admin_role = useLocaleText('admin_role')
  const admin_actions = useLocaleText('admin_actions')
  const admin_create_user = useLocaleText('admin_create_user')

  const handleOpen = useCallback(
    (row: any) => {
      setSelectedRow(row)
      onOpen()
    },
    [onOpen]
  )
  const admin_delete_user_success = useLocaleText('admin_delete_user_success')

  const columns = React.useMemo(
    () => [
      {
        Header: admin_user_info,
        accessor: 'first_name',
        Cell: ({ row }: any) => <CellAvatarBlock row={row} />,
      },
      {
        Header: admin_uid,
        accessor: 'uid',
      },

      {
        Header: admin_role,
        accessor: 'user_group_role',
        Cell: ({ row }: any) => <CellRoleBlock row={row} />,
      },
      {
        Header: admin_desc,
        accessor: 'desc',
      },
      {
        Header: admin_sec_uid,
        accessor: 'sec_uid',
      },
      {
        Header: admin_actions,
        accessor: 'actions',
        Cell: ({ row }) => <CellActionMenu row={row} setSelectedRow={handleOpen} />,
      },
    ],

    [admin_actions, admin_desc, admin_role, admin_sec_uid, admin_uid, admin_user_info, handleOpen]
  )
  const initialState = {
    pageSize: 10,
    pageIndex: 0,
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    gotoPage,
    prepareRow,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns,
      data: usersList ? usersList : users,
      initialState,
    },
    useGlobalFilter,
    usePagination,
    useRowSelect
  )

  return (
    <ContentWrapper>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={'space-between'} alignItems="center" mb={'20px'} borderBottom="1px solid #fcf4f4">
            <Text fontWeight="600" color="#9a9ab4" fontSize="18px">
              <LocaleText text="admin_number_of_users" /> {usersList?.length}
            </Text>
            <Flex flexDir="row" alignItems="center" justifyContent="space-between" style={{ columnGap: '20px' }}>
              <SearchUsers value={globalFilter} setFilter={setGlobalFilter} />
              <NavItem to="add-user">
                <Button
                  color={theme?.elementsColor}
                  variant="outline"
                  borderRadius="10px"
                  _focus={{ borderColor: 'red' }}
                >
                  {admin_create_user}
                </Button>
              </NavItem>
            </Flex>
          </Flex>

          <table {...getTableProps()} width="100%" style={{ color: '#a2a2b6' }}>
            <thead style={{ height: '55px' }}>
              {headerGroups.map((headerGroup: any, i: number) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={i} style={{ borderBottom: '1px solid #fcf4f4' }}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps()}
                      key={column.id}
                      style={{
                        minWidth: '100px',
                        textAlign: isRtl ? 'right' : 'left',
                        padding: '10px',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page?.map((row: any) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} key={row.id} style={{ width: '100%' }}>
                    {row.cells.map((cell: any, index: number) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          style={{
                            padding: '10px',
                            borderBottom: '1px solid #fcf4f4',
                            textAlign: isRtl ? 'right' : 'left',
                            width: index === row.cells.length - 1 ? '150px' : '200px',
                          }}
                        >
                          {cell.render('Cell')}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
            </tbody>
          </table>

          <TablePagination
            previousPage={previousPage}
            nextPage={nextPage}
            gotoPage={gotoPage}
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            pageIndex={pageIndex}
            pageOptions={pageOptions}
          />
        </>
      )}
      {isOpen && (
        <UsersActionModal
          isOpen={isOpen}
          onClose={onClose}
          row={selectedRow}
          actionLiteral={admin_delete_user_success}
          mode="delete"
        />
      )}
    </ContentWrapper>
  )
})

export default UserTable
