import { useEffect, useRef } from 'react'
import { Text } from '@chakra-ui/core'
import React from 'react'
import { IMessage } from './types'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

export const MessageText = ({ text, isUserMessage }: IMessage) => {
  const isRtl = useSelector(isRtlSelector)
  const messageEndRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageEndRef.current?.offsetHeight])

  return (
    <Text
      bg={isUserMessage ? 'blue.200' : '#ffe6f2'}
      p="1rem"
      dir={isRtl ? 'rtl' : 'ltr'}
      ref={messageEndRef}
      mt={'20px'}
      borderBottomRightRadius="20px"
      borderBottomLeftRadius="20px"
      borderTopLeftRadius={isUserMessage ? '20px' : '0'}
      borderTopRightRadius={isUserMessage ? '0' : '20px'}
      wordBreak={'break-word'}
      fontFamily="inherit"
      fontSize="18px"
      minW="40px"
      textAlign={isRtl ? 'right' : 'left'}
    >
      {text}
    </Text>
  )
}
