import { Flex, Text } from '@chakra-ui/core'
import { CustomSwitch } from 'components/CommonComponents/CustomSwitch'
import LocaleText from 'components/LocaleText'
import React from 'react'

export const PostModalSwitcher = ({
  isSwitchActive,
  onToggleSwitch,
  literal,
  isDisabled,
  size,
  ...props
}: {
  isSwitchActive: boolean
  onToggleSwitch: () => void
  literal: string
  isDisabled?: boolean
  size?: 'sm' | 'md' | 'lg'
}) => {
  return (
    <Flex alignItems="center" h="100%">
      <Text fontWeight="600" mx="10px" fontSize="12px" w="100%" {...props}>
        <LocaleText text={literal} />
      </Text>
      <CustomSwitch
        isChecked={isSwitchActive}
        isDisabled={isDisabled}
        onToggle={onToggleSwitch}
        size={size}
        color="#d53f8c"
      />
    </Flex>
  )
}
