import React, { memo, useEffect } from 'react'
import { Avatar, Flex, useToast } from '@chakra-ui/core'
import { IComment, RootState } from 'constants/interfaces'
import useAvatar from 'hooks/useAvatar'
import { useDispatch, useSelector } from 'react-redux'
import useComments from 'hooks/useComments'
import { Params, useParams } from 'react-router-dom'
import { setCommentsCounter, setViewedCommentsIds, updateViews } from 'redux/actions/comments'
import { setIsLoading } from 'redux/actions/pops'
import { ChatMessage } from 'components/Chat/ChatMessage'

interface IAnnouncementChatItem {
  message: IComment
}
export const AnnouncementChatItem = memo(({ message }: IAnnouncementChatItem) => {
  const { id, commentId: cid } = useParams<Params<string>>()
  const dispatch = useDispatch()
  const toast = useToast()
  const avatar = useAvatar(message.created_by)
  const { uid } = useSelector((state: RootState) => state.auth)
  const { commentsCounter } = useComments(id!)
  const { commentsCounter: repliesCounter, deleteComment: deleteReply, comments: replies } = useComments(cid!, true)
  const viewedIds = useSelector((state: RootState) => state.comments.viewedCommentsIds[id!] || [])
  const isUserMessage = message.created_by === uid

  const deleteReplyHandler = async (reply_cid: string) => {
    if (window.confirm('Are you sure ?')) {
      setIsLoading(true)
      try {
        deleteReply({ id: id!, cid: cid!, reply_cid: reply_cid, isReplyed: true })
        toast({
          title: 'Comment deleted',
          status: 'success',
          duration: 3000,
          isClosable: true,
        })
        dispatch(
          setCommentsCounter({
            count: commentsCounter - 1,
            key: id!,
            isReply: false,
          })
        )
        dispatch(
          setCommentsCounter({
            count: repliesCounter - 1,
            key: cid!,
            isReply: true,
          })
        )
      } catch (error) {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      } finally {
        setIsLoading(false)
      }
    }
  }
  useEffect(
    () => {
      if (cid && !message.isDummy) {
        const idsPayload = replies?.map((item) => item.cid) || []
        dispatch(
          updateViews({
            postID: cid,
            viewedIds: idsPayload,
          })
        )

        dispatch(
          setViewedCommentsIds({
            key: cid,
            ids: idsPayload,
          })
        )
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, cid]
  )

  return (
    <Flex
      flexDirection={isUserMessage ? 'row-reverse' : 'row'}
      justifyContent="space-between"
      mx="10px"
      mb="30px"
      h="max-content"
      pos="relative"
    >
      <Flex maxW={'70%'} flexDir={isUserMessage ? 'row-reverse' : 'row'} style={{ columnGap: '15px' }}>
        <Avatar w="50px" h="50px" src={avatar} />
        <ChatMessage
          message={message}
          isUserMessage={isUserMessage}
          deleteCommentHandler={deleteReplyHandler}
          viewedIds={viewedIds}
        />
      </Flex>
    </Flex>
  )
})
