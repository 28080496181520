import React from 'react'
import {
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement,
  Icon,
  InputGroupProps,
  InputProps,
} from '@chakra-ui/core'
import colors from 'constants/colors'
import { searchGA } from 'constants/googleAnalytics'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

interface IProps extends Omit<InputGroupProps, 'children' | 'width'>, Pick<InputProps, '_focus' | 'width'> {
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  value?: string
  autoFocus?: boolean
  placeholder?: string
}

const Search = ({
  onClick,
  width,
  onChange,
  value,
  autoFocus = false,
  placeholder = 'Search',
  _focus,
  ...props
}: IProps) => {
  const isRtl = useSelector(isRtlSelector)

  const SearchIcon = isRtl ? InputRightElement : InputLeftElement

  return (
    <InputGroup onClick={onClick} {...props} mb="20px">
      <SearchIcon>
        <Icon name="search" color={colors.greyDark} />
      </SearchIcon>
      <Input
        id={searchGA}
        type="text"
        placeholder={placeholder}
        bg="white"
        borderRadius="20px"
        size="sm"
        border="none"
        onChange={onChange}
        value={value}
        autoFocus={autoFocus}
        boxShadow="0 2px 4px 0 rgb(187 187 187 / 50%)"
        autoComplete="off"
        transition="all .2s ease-out"
        width={width || '100%'}
        _focus={_focus}
      />
    </InputGroup>
  )
}

export default Search
