import React from 'react'
import { IGroupsSelection } from './GroupTagsList'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'
import { StyledGropTagWrapper } from '../UI/CustomComponents'
import { Flex, Text, IconButton } from '@chakra-ui/core'

interface IGroupTag extends IGroupsSelection {
  groupId: string
  isShowDeleteBtn?: boolean
  onOpen?: () => void
  setGroupToDelete?: (groupId: string) => void
}

export const GroupTag = ({ groupId, isShowDeleteBtn = false, onOpen, setGroupToDelete }: IGroupTag) => {
  const { groups } = useSelector((state: RootState) => state.config.config)
  const theme = useSelector((state: RootState) => state.general.theme)

  return (
    <StyledGropTagWrapper
      as={Flex}
      flexDir="row"
      border={`1px solid ${theme?.elementsColor}`}
      bg={theme?.elementsColor}
      m="5px"
      borderRadius="30px"
      px="10px"
      alignItems="center"
      cursor="pointer"
    >
      {groups !== null && (
        <Text color="white" fontWeight="800">
          {groups[groupId]?.name}
        </Text>
      )}
      {isShowDeleteBtn && (
        <IconButton
          aria-label="clear"
          variant="ghost"
          icon="delete"
          size="md"
          color="white"
          borderRadius="full"
          _focus={{ boxShadow: 'none' }}
          _hover={{ background: 'none' }}
          _active={{ background: 'none' }}
          onClick={() => {
            setGroupToDelete?.(groupId)
            onOpen?.()
          }}
        />
      )}
    </StyledGropTagWrapper>
  )
}
