import React from 'react'

import { IOption } from 'constants/interfaces'
import LocaleText from 'components/LocaleText'
import { SelectOption } from './SelectOption'
import { SelectControl } from './SelectControl'
import { SelectMenuList } from './SelectMenuList'
import { BaseSelect } from './BaseSelect'

interface IProps {
  options: IOption[]
  value?: IOption
  onChange: (data: any) => void
  onDeleteSelection: VoidFunction
}

export const CreatorSelect = ({ options, value, onChange, onDeleteSelection }: IProps) => {
  const filterConfig = {
    ignoreCase: false,
    ignoreAccents: false,
    trim: false,
    stringify: (option: any) => {
      return `${option.data.id} ${option.data.name} ${option.data.desc} ${option.data.sec_uid}`
    },
  }

  return (
    <BaseSelect
      options={options}
      value={value}
      onChange={onChange}
      filterConfig={filterConfig}
      components={{
        Option: SelectOption,
        Control: (props) => (
          <SelectControl {...props}>{value?.name || <LocaleText text="search_feed_creator_filter" />}</SelectControl>
        ),
        MenuList: ({ innerRef, children, ...props }) => (
          <SelectMenuList ref={innerRef} onDeleteSelection={onDeleteSelection} isShowSearch={true} {...props}>
            {children}
          </SelectMenuList>
        ),
      }}
    />
  )
}
