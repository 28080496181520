export enum FrequencyType {
  DAY = 0,
  WEEK = 1,
  MONTH = 2,
}

export enum RecipientType {
  USER = 0,
  GROUP = 1,
  TAG = 2,
}

export const frequencyLabel = (value: number | null) => {
  switch (value) {
    case FrequencyType.DAY:
      return 't_day'
    case FrequencyType.WEEK:
      return 't_week'
    case FrequencyType.MONTH:
      return 't_month'
    default:
      return 'Frequency'
  }
}

export const REMOVAL_OFFSET = 8
