import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Button, Flex } from '@chakra-ui/core'
import { ValueType } from 'react-select'

import { IOption, RootState } from 'constants/interfaces'
import LocaleText from 'components/LocaleText'
import { getSearchPost } from 'redux/actions/feed'
import { SearchFilterParams } from './SearchFilterParams'

export const SearchFilter = () => {
  const dispatch = useDispatch()
  const [creator, setCreator] = useState('')
  const [mediaFilter, setMediaFilter] = useState('')
  const [params, setParams] = useSearchParams()
  const [dateMode, setDateMode] = useState('')
  const [date, setDate] = useState<{ from_ts?: number; to_ts?: number }>({})
  const theme = useSelector((state: RootState) => state.general.theme)

  useEffect(() => {
    const search = params.get('query')
    setParams({
      ...(search ? { query: search } : {}),
      ...(creator ? { created_by: creator } : {}),
      ...(mediaFilter ? { media: mediaFilter } : {}),
      ...(date?.from_ts ? { from_ts: date.from_ts.toString() } : {}),
      ...(date?.to_ts ? { to_ts: date.to_ts.toString() } : {}),
      ...(dateMode ? { dateMode } : {}),
    })
  }, [creator, date, mediaFilter, dateMode, params, setParams])

  useEffect(() => {
    const creator = params.get('created_by') || ''
    const media = params.get('media') || ''
    const from_ts = params.get('from_ts') || 0
    const to_ts = params.get('to_ts') || 0
    const dateMode = params.get('dateMode') || ''

    if (creator) {
      setCreator(creator)
    }
    if (media) {
      setMediaFilter(media)
    }
    if (from_ts && to_ts && dateMode) {
      setDate({ from_ts: +from_ts, to_ts: +to_ts })
      setDateMode(dateMode)
    }
  }, [params])

  const handleCreator = (data: ValueType<IOption, false>) => {
    if (data) {
      setCreator((prevUser) => (prevUser === data.id ? '' : data.id))
    }
  }

  const handleMedia = (data: ValueType<IOption, false>) => {
    if (data) {
      setMediaFilter((prevFilter) => (prevFilter === data.id ? '' : data.id))
    }
  }

  const handleDate = (date: { from_ts?: number; to_ts?: number; dateMode: string }) => {
    setDate({ from_ts: date.from_ts, to_ts: date.to_ts })
    setDateMode(date.dateMode)
  }

  const handlePosts = () => {
    dispatch(
      getSearchPost({
        created_by: creator,
        date,
        is_contain_media: mediaFilter === '1',
        search: params.get('query') || '',
      })
    )
  }

  const resetPostCreator = () => {
    setCreator('')
  }

  const resetMediaFilter = () => {
    setMediaFilter('')
  }

  const search = useMemo(() => {
    return params.get('query')
  }, [params])

  const isDisabledPublishButton = useMemo(() => {
    return !creator && !mediaFilter && !dateMode && !date.from_ts && !date.to_ts && !search
  }, [creator, mediaFilter, dateMode, date, search])

  return (
    <Flex maxWidth="600px" alignItems="flex-start">
      <Flex flexWrap="wrap" style={{ gap: '10px' }}>
        <SearchFilterParams
          createdBy={creator}
          changeCreatedBy={handleCreator}
          changeDate={handleDate}
          mediaFilter={mediaFilter}
          handleMediaFilter={handleMedia}
          resetPostCreator={resetPostCreator}
          resetMediaFilter={resetMediaFilter}
        />
      </Flex>
      <Button
        width="125px"
        variant="outline"
        borderRadius="20px"
        background={theme?.elementsColor}
        color="white"
        onClick={handlePosts}
        height="30px"
        isDisabled={isDisabledPublishButton}
        mx="10px"
      >
        <LocaleText text="search_feed_search_button" />
      </Button>
    </Flex>
  )
}
