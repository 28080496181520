import React, { useEffect, useRef, useState } from 'react'
import { Button, Flex, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text } from '@chakra-ui/core'
import { format, addSeconds, subSeconds } from 'date-fns'
import { FaPause, FaPlay } from 'react-icons/fa'

import colors from 'constants/colors'
import { getAudioDuration } from 'utils'

interface IProps {
  src: string
}

export const AudioPlayer = ({ src }: IProps) => {
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const [duration, setDuration] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)

  const togglePlayer = () => {
    setIsPlaying((isPlaying) => !isPlaying)
  }

  useEffect(() => {
    getAudioDuration(src, (duration) => {
      setDuration(duration)
    })
    audioRef.current?.addEventListener('timeupdate', () => {
      const currentTime = audioRef.current?.currentTime || 0
      setCurrentTime(currentTime)
      if (audioRef?.current?.currentTime && currentTime && currentTime >= audioRef.current?.duration) {
        currentTimeHandler(0)
        setIsPlaying(false)
      }
    })
  }, [src])

  useEffect(() => {
    if (isPlaying) {
      audioRef.current?.play()
    } else {
      audioRef.current?.pause()
    }
  }, [isPlaying])

  const currentTimeHandler = (value: number) => {
    audioRef.current!.currentTime = value
    setCurrentTime(value)
  }

  return (
    <Flex width="100%" background={colors.greyLight} height="50px" borderRadius="10px" alignItems="center" dir="ltr">
      <Button
        background="transparent"
        onClick={togglePlayer}
        _active={{ background: 'none' }}
        _hover={{ background: 'none' }}
        _focus={{ boxShadow: 'none' }}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </Button>
      <Slider
        aria-label="slider-ex-2"
        defaultValue={0}
        value={currentTime}
        max={duration}
        onChange={currentTimeHandler}
      >
        <SliderTrack bg={colors.greyLight2} height="2px">
          <SliderFilledTrack bg={colors.greyLight2} />
        </SliderTrack>
        <SliderThumb background={colors.greenLight3} />
      </Slider>
      <Text mx={2}>{format(subSeconds(addSeconds(new Date(0), duration), currentTime), 'mm:ss')}</Text>
      <audio ref={audioRef} preload="metadata">
        <source src={src} type="audio/mp3" />
      </audio>
    </Flex>
  )
}
