import { actionTypes } from '../actions/customReports'
import { dummyStores } from 'config/dummyData'

export const initialState: any = {
  data: dummyStores,
  totalSelectedStores: [],
  customReportTitle: '',
}

const customReportsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_STORES:
      return {
        ...state,
        totalSelectedStores: action.payload,
      }
    case actionTypes.SET_TITLE:
      return {
        ...state,
        customReportTitle: action.payload,
      }
    default:
      return state
  }
}

export default customReportsReducer
