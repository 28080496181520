import { useDisclosure } from '@chakra-ui/core'
import { RootState } from 'constants/interfaces'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleSubmitPop, setDummyPop } from 'redux/actions/pops'
import { PopType } from './utils'
import { GPTPop } from './GPTPop'
import { DefaultPop } from './DefaultPOP'
import { RePop } from './RePop'

export default function PopModal() {
  const dispatch = useDispatch()
  const { pops, dummyPop, isLoading } = useSelector((state: RootState) => state.pops)
  const { retailConfig, isConfigReady } = useSelector((state: RootState) => state.config)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const firstPopInStack = pops[0] ?? dummyPop

  useEffect(() => {
    if (pops.length > 0 && !isLoading) {
      onOpen()
      const interval = retailConfig?.pop_config.interval
      const timeoutId = setTimeout(onOpen, interval)
      return () => clearTimeout(timeoutId)
    } else if (dummyPop !== null && !isLoading) {
      onOpen()
    }
  }, [dummyPop, isConfigReady, isLoading, onClose, onOpen, pops.length, retailConfig])

  const handleSubmit = (popType: number | undefined) => {
    if (popType === PopType.DUMMY_POP) {
      dispatch(setDummyPop(null))
    } else {
      const pop_type = firstPopInStack.pop_type
      const isRepop = popType === PopType.RE_POP
      dispatch(handleSubmitPop({ pop_id: firstPopInStack.pk, isRepop, pop_type }))
    }
    onClose()
  }
  return (
    <>
      {firstPopInStack && (
        <>
          {(firstPopInStack.pop_type === PopType.DEFAULT_POP || firstPopInStack.pop_type === PopType.DUMMY_POP) &&
            isOpen && (
              <DefaultPop
                isOpen={isOpen}
                onClose={onClose}
                firstPopInStack={firstPopInStack}
                handleSubmit={handleSubmit}
              />
            )}

          {(firstPopInStack.pop_type === PopType.RE_POP || firstPopInStack.pop_type === PopType.FAKE_POP) && isOpen && (
            <RePop isOpen={isOpen} onClose={onClose} firstPopInStack={firstPopInStack} handleSubmit={handleSubmit} />
          )}

          {firstPopInStack.pop_type === PopType.CHAT_GPT_POP && isOpen && (
            <GPTPop
              isOpen={isOpen}
              popText={firstPopInStack.pop_body}
              popInitiator={firstPopInStack.pop_initiator}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  )
}
