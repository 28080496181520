import { TitleLine } from 'components/Reports/ui/TitleLine'
import React, { useCallback, useMemo } from 'react'
import { DeepDiveProperties } from 'redux/reducers/reports'
import { useTable, useGlobalFilter, useSortBy } from 'react-table'
import { useDispatch, useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import './style.css'
import { Flex, IconButton } from '@chakra-ui/core'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import { SearchUsers } from 'components/AdminSettings/UI/SearchUsers'
import { Cell } from './Cell'
import { customGlobalFilter, transformDataForTable, universalSort } from './utils'
import {
  setStoreDataFilter,
  setStoreReportRangeType,
  setStoreSelectedTag,
  setStoreSelectedUsersIds,
} from 'redux/actions/reports'
import keys from 'constants/keys'
import { useNavigate } from 'react-router-dom'
import { exportToExcel, extractValueByIndexType } from 'components/Reports/utils'

import useLocaleText from 'components/useLocaleText'
import ExportToExcellButton from '../../ExportToExcellButton'

interface ITableProps {
  properties: DeepDiveProperties
  reports: any
}

const generateColumns = (properties: DeepDiveProperties, reports: any) => {
  const { table_header: tableHeader } = properties

  return tableHeader
    .map((header, index) => {
      const text: string = extractValueByIndexType(header.text_val, reports)

      if (text === null) {
        return null
      }
      return {
        Header: ({ column }: any) => (
          <Flex alignItems="center" style={{ columnGap: '5px' }}>
            <IconButton
              {...column.getSortByToggleProps()}
              icon={column.isSorted ? (column.isSortedDesc ? 'triangle-down' : 'triangle-up') : 'arrow-up-down'}
              background="transparent"
              variant="ghost"
              minW="unset"
              h="18px"
              w="18px"
              _focus={{ outline: 'none' }}
            />
            <TitleLine text={text} color={colors.greyMain} fontWeight="500" fontSize="16px" />
          </Flex>
        ),
        accessor: `${index}`,
        Cell: ({ row }: any) => {
          const value = row.original[index]
          return <Cell value={value.value} isTag={value.isTag} groupId={value.groupId} index={index} />
        },
        sortType: universalSort,
      }
    })
    .filter((column) => column !== null)
}

export const TableComponent = ({ properties, reports }: ITableProps) => {
  const isRtl = useSelector(isRtlSelector)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    translations,
    config: { groups },
  } = useSelector((state: RootState) => state.config)
  const { locale, theme } = useSelector((state: RootState) => state.general)
  const { selectedDeepDiveReportRangeType, deepDiveSelectedTag, deepDiveSelectedUsersIds, selectedDeepDiveDataFilter } =
    useSelector((state: RootState) => state.reports)
  const topRankStoresIds = reports.slice(0, 3).map((item: any) => item[0])
  const t_sid = useLocaleText('t_sid')

  const data = useMemo(
    () =>
      transformDataForTable(
        reports,
        properties.table_row,
        locale,
        translations,
        properties.group_id,
        properties.sub_retail_id
      ),
    [locale, properties.group_id, properties.sub_retail_id, properties.table_row, reports, translations]
  )

  const columns = useMemo(() => generateColumns(properties, reports), [properties, reports])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable({ columns, data, globalFilter: customGlobalFilter }, useGlobalFilter, useSortBy)

  const titleText: string | null = extractValueByIndexType(properties?.title?.properties?.text_val, reports[0]) ?? ''
  const translatedTitleText = useLocaleText(titleText)

  const handleRowClick = (groupId: string | null, subRetailId: string | null) => {
    if (groupId) {
      if (selectedDeepDiveReportRangeType) {
        dispatch(setStoreReportRangeType(selectedDeepDiveReportRangeType.type, selectedDeepDiveReportRangeType.title))
      }
      if (deepDiveSelectedTag) {
        dispatch(setStoreSelectedTag(deepDiveSelectedTag))
      }
      if (deepDiveSelectedUsersIds) {
        dispatch(setStoreSelectedUsersIds(deepDiveSelectedUsersIds))
      }
      if (selectedDeepDiveDataFilter) {
        dispatch(setStoreDataFilter(selectedDeepDiveDataFilter))
      }
      navigate(`${keys.ROUTE_NAMES.REPORTS}/${groupId}?groupId=${groupId}&subRetailId=${subRetailId}`)
    }
  }

  const dataForExcell = useMemo(
    () => data.map((item) => [...item, { value: groups[item[0].groupId]?.sid?.[0] ?? '-', isTag: false }]),
    [data, groups]
  )

  const headersForExcell = useMemo(
    () => [
      ...properties.table_header
        .filter((header) => header.text_val !== null)
        .map((header) => {
          return {
            ...header,
            text_val: extractValueByIndexType(header.text_val, reports) as string | null,
          }
        }),
      {
        text_val: t_sid,
        text_is_literal: true,
      },
    ],
    [properties.table_header, reports, t_sid]
  )

  const handleExportToCSV = useCallback(() => {
    exportToExcel(dataForExcell, headersForExcell, locale, translations, translatedTitleText)
  }, [dataForExcell, headersForExcell, locale, translations, translatedTitleText])

  return (
    <Flex flexDir="column">
      <Flex alignItems="center" justifyContent="space-between" flexDirection={'row-reverse'}>
        <ExportToExcellButton handleExport={handleExportToCSV} isLoading={false} />

        <SearchUsers value={globalFilter} setFilter={setGlobalFilter} width="40%" minWidth="400px" />
      </Flex>
      <div className={`scrollableTable ${properties?.group_id ? 'withHoverEffects' : ''}`}>
        <div className="scrollableTableBody">
          <table
            {...getTableProps()}
            width={'100%'}
            style={{
              background: 'transparent',
              borderRadius: '10px',
              cursor: properties?.group_id ? 'pointer' : 'default',
            }}
          >
            <thead>
              {headerGroups.map((headerGroup: any, headerGroupIndex: number) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroupIndex}>
                  {headerGroup.headers.map((column: any, columnIndex: number) => (
                    <th
                      {...column.getHeaderProps()}
                      key={columnIndex}
                      style={{
                        padding: '16px',
                        textAlign: isRtl ? 'right' : 'left',
                        width: columnIndex === 0 ? '220px' : 'auto',
                        minWidth: '100px',
                      }}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row: any, rowIndex: number) => {
                prepareRow(row)
                const groupId = row.original[0].groupId
                const subRetailId = row.original[0].subRetailId

                return (
                  <tr
                    {...row.getRowProps()}
                    key={rowIndex}
                    style={{ borderWidthBottom: '1px' }}
                    onClick={() => handleRowClick(groupId, subRetailId)}
                  >
                    {row.cells.map((cell: any, cellIndex: number) => (
                      <td
                        {...cell.getCellProps()}
                        key={cellIndex}
                        style={{
                          padding: '15px',
                          background: topRankStoresIds.includes(row.original[0].groupId)
                            ? theme?.backgroundLight2
                            : 'white',
                          width: cellIndex === 0 ? '220px' : 'auto',
                          minWidth: '100px',
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Flex>
  )
}
