import { Button, Divider, Flex, Text } from '@chakra-ui/core'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import strings from 'constants/strings'
import React, { memo, useMemo } from 'react'
import { TooltipList } from '../TooltipList'
import { ISelectButton } from './interfaces'

const SelectAllButton = ({
  checkedIds,
  userWritePermittedGroups,
  groupsObjectToArray,
  groupsToPayload,
  groupNames,
  mode,
  handleSelectAll,
}: ISelectButton) => {
  const groupNamesForTooltip = useMemo(() => {
    return groupsToPayload?.map((group) => userWritePermittedGroups[group]?.name)
  }, [groupsToPayload, userWritePermittedGroups])
  return (
    <>
      {mode === strings.MULTIPLE_MODE ? (
        <Flex mt="17px" width="100%" justifyContent="space-between" alignItems="center">
          {groupsToPayload?.length !== 0 ? (
            <TooltipList items={groupNamesForTooltip}>
              <Text fontSize="16px" color={colors.greyDark} fontWeight="700">
                <LocaleText text="post_choosed_groups" />
                {`: ${groupNames} `}
              </Text>
            </TooltipList>
          ) : (
            <Text mt="23px" />
          )}
        </Flex>
      ) : (
        <>
          <Flex mt="17px" width="100%" justifyContent="space-between" alignItems="center">
            <Text fontSize="16px" color={colors.greyDark} fontWeight="700">
              <LocaleText text="create_task_post_assignee_selected_text" />
              {` ${checkedIds.length} `}
              <LocaleText text="create_task_post_assignee_of_text" />
              {` ${groupsObjectToArray.length}`}
            </Text>
            <Button variant="outline" borderRadius="16px" py="6px" height="auto" onClick={handleSelectAll}>
              {checkedIds.length === groupsObjectToArray.length ? (
                <LocaleText text="create_task_post_assignee_clear_button" />
              ) : (
                <LocaleText text="create_task_post_assignee_select_all_button" />
              )}
            </Button>
          </Flex>
          <Divider mt="10px" mb="2.5px" color={colors.greyMedium} />
        </>
      )}
    </>
  )
}
export default memo(SelectAllButton)
