import { Icon, Input, InputGroup, InputLeftElement, InputRightElement } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import colors from 'constants/colors'
import React from 'react'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'

export const SearchUsers = ({ value, setFilter, width = '100%', minWidth = 'unset' }: any) => {
  const isRtl = useSelector(isRtlSelector)
  const searchPlaceholder = useLocaleText('search_feed_web_placeholder')
  const inputLeftElementToggle = () => {
    if (isRtl) {
      if (!value) {
        return
      } else {
        return <Icon name="small-close" cursor="pointer" color={'rgb(162, 162, 182)'} />
      }
    }
    return <Icon name="search" color={'rgb(162, 162, 182)'} />
  }

  const inputRightElementToggle = () => {
    if (isRtl) {
      return <Icon name="search" color={'rgb(162, 162, 182)'} />
    } else if (!value) {
      return
    }
    return <Icon name="small-close" cursor="pointer" color={'rgb(162, 162, 182)'} />
  }
  return (
    <InputGroup width={width} minWidth={minWidth}>
      <InputLeftElement fontSize="1em" color={colors.blackLight} onClick={() => (isRtl ? setFilter('') : '')}>
        {inputLeftElementToggle()}
      </InputLeftElement>
      <Input
        placeholder={searchPlaceholder}
        backgroundColor="white"
        _focus={{ color: 'rgb(162, 162, 182)' }}
        _hover={{ borderColor: 'rgb(162, 162, 182)' }}
        _active={{ borderColor: 'rgb(162, 162, 182)' }}
        borderRadius="10px"
        value={value || ''}
        onChange={(e: any) => setFilter(e.target.value)}
        fontWeight="500"
        color={'rgb(162, 162, 182)'}
        paddingRight="2.5rem"
        paddingLeft="2.5rem"
      />
      <InputRightElement fontSize="1em" color={colors.blackLight} onClick={() => (isRtl ? '' : setFilter(''))}>
        {inputRightElementToggle()}
      </InputRightElement>
    </InputGroup>
  )
}
