import React from 'react'
import { Flex, Image, ModalBody, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import fileImage from 'assets/file.png'
import { formatBytes } from 'utils'

interface IProps {
  fileSize: number
  fileExtension: string
}

export const FileModalBody = ({ fileExtension, fileSize }: IProps) => {
  return (
    <ModalBody>
      <Flex
        background={colors.greyLight}
        flexDirection="column"
        alignItems="center"
        width="250px"
        height="250px"
        justifyContent="center"
        mx="auto"
        my="15px"
        borderRadius="5px"
        dir="ltr"
      >
        <Image src={fileImage} height="150px" />
        <Flex justifyContent="center">
          <Text>{formatBytes(fileSize)}</Text> &#183; <Text>{fileExtension}</Text>
        </Flex>
      </Flex>
    </ModalBody>
  )
}
