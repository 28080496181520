import { Button } from '@chakra-ui/core'
import React from 'react'
import { MarkUpText } from './MarkUpText'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { MdReplyAll } from 'react-icons/md'
interface IProps {
  onClose: (pop_type: number) => void
  btnText: string
  pop_initiator: number
  hasIcon?: boolean
  popType: number
  isDisabled?: boolean
}

export default function PopSubmitBtn({
  onClose,
  btnText,
  pop_initiator,
  hasIcon = false,
  popType,
  isDisabled = false,
}: IProps) {
  const isRtl = useSelector(isRtlSelector)
  const rotation = isRtl ? 'none' : 'scaleX(-1)'
  return (
    <Button
      dir="auto"
      background="#879AF2"
      pos="relative"
      isDisabled={isDisabled}
      _active={{ transform: 'scale(1.05)' }}
      _focus={{ boxShadow: 'none' }}
      _hover={{ opacity: 0.8 }}
      style={{
        fontSize: '14pt',
        fontFamily: 'Arial',
        marginBottom: '10pt',
        marginTop: '10pt',
        borderRadius: '20.5pt',
        height: '32pt',
        width: '179pt',
        color: 'white',
        paddingTop: '13pt',
        paddingBottom: '13pt',
        textTransform: 'none',
      }}
      onClick={() => onClose(popType)}
    >
      <MarkUpText text={btnText} initiator={pop_initiator} />
      {hasIcon && (
        <MdReplyAll
          style={{
            transform: rotation,
            marginLeft: '10px',
            marginRight: '10px',
            position: 'absolute',
            right: isRtl ? '65%' : '15%',
          }}
        />
      )}
    </Button>
  )
}
