import React from 'react'
import Loadable from 'react-loadable'

export const LoadableLogin = Loadable({
  loader: () => import(/* webpackChunkName: "/Login" */ 'pages/Login'),
  loading() {
    return <div>Loading...</div>
  },
})

export const LoadableNewsFeed = Loadable({
  loader: () => import(/* webpackChunkName: "/NewsFeed" */ 'pages/NewsFeed'),
  loading() {
    return <div>Loading...</div>
  },
})

export const LoadableImprovingSales = Loadable({
  loader: () => import(/* webpackChunkName: "/ImprovingSales" */ 'pages/ImprovingSales'),
  loading() {
    return <></>
  },
})

export const LoadableTasksList = Loadable({
  loader: () => import(/* webpackChunkName: "/TasksList" */ 'pages/TasksList'),
  loading() {
    return <div>Loading...</div>
  },
})

export const LoadableTask = Loadable({
  loader: () => import(/* webpackChunkName: "/Task" */ 'components/Tasks/Task'),
  loading() {
    return <div>Loading...</div>
  },
})

export const LoadableCustomReports = Loadable({
  loader: () => import(/* webpackChunkName: "/CustomReports" */ 'pages/CustomReports'),
  loading() {
    return <div>Loading...</div>
  },
})
export const LoadableReports = Loadable({
  loader: () => import(/* webpackChunkName: "/Reports" */ 'pages/Reports'),
  loading() {
    return <div>Loading...</div>
  },
})

export const LoadableTasksOverview = Loadable({
  loader: () => import(/* webpackChunkName: "/TasksOverview" */ 'pages/TasksOverview'),
  loading() {
    return <></>
  },
})

export const LoadableTasksOverviewDetail = Loadable({
  loader: () => import(/* webpackChunkName: "/TasksOverviewDetail" */ 'pages/TasksOverviewDetail'),
  loading() {
    return <></>
  },
})

export const LoadableTasksOverviewSubtasks = Loadable({
  loader: () => import(/* webpackChunkName: "/TasksOverviewSubtasks" */ 'pages/TasksOverviewSubtasks'),
  loading() {
    return <></>
  },
})

export const LoadableCreateTask = Loadable({
  loader: () => import(/* webpackChunkName: "/CreateTask" */ 'pages/CreateTask'),
  loading() {
    return <></>
  },
})

export const LoadableTaskViews = Loadable({
  loader: () => import(/* webpackChunkName: "/TaskViews" */ 'pages/TaskViews'),
  loading() {
    return <></>
  },
})

export const LoadableRanking = Loadable({
  loader: () => import(/* webpackChunkName: "/Ranking"  */ 'pages/Ranking'),
  loading() {
    return <></>
  },
})
export const LoadableAdminSettings = Loadable({
  loader: () => import(/* webpackChunkName: "/AdminSettings"  */ 'pages/AdminSettings'),
  loading() {
    return <></>
  },
})

export const LoadableIntegrations = Loadable({
  loader: () => import(/* webpackChunkName: "/Ranking"  */ 'pages/Integrations'),
  loading() {
    return <></>
  },
})
