import { Box, Flex, Text, IconButton, Divider, RadioGroup, Button } from '@chakra-ui/core'
import { CustomRadio } from 'components/CommonComponents/AudiencePublicationModal/AudienceOptionItem'
import BaseModal from 'components/CommonComponents/BaseModal'
import LocaleText from 'components/LocaleText'
import colors from 'constants/colors'
import { RootState } from 'constants/interfaces'
import React, { Fragment, useCallback, useState } from 'react'
import { BsArrowLeftShort } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { Post } from 'redux/reducers/feed'
import { isRtlSelector } from 'redux/selectors/general'
import { Loader } from 'components/CommonComponents/Loader'
import { handleGetPosts } from 'redux/actions/feed'
import keys from 'constants/keys'
import { SmartAvatar } from 'components/CommonComponents/SmartAvatar'

interface IProps {
  isOpen: boolean
  closeModal: () => void
  selectedChatLink: string | null
  setSelectedChatLink: (value: string) => void
  setSelectedTaskLink: (value: string) => void
  chats: Post[]
}
export const ChatsLinksModal = ({
  isOpen,
  closeModal,
  selectedChatLink,
  setSelectedChatLink,
  setSelectedTaskLink,
  chats,
}: IProps) => {
  const dispatch = useDispatch()
  const isRtl = useSelector(isRtlSelector)
  const theme = useSelector((state: RootState) => state.general.theme)
  const { hasMoreChats, chatsFetching } = useSelector((state: RootState) => state.feed)

  const [value, setValue] = useState(selectedChatLink ?? '')

  const handleSubmit = () => {
    closeModal()
    setSelectedChatLink(value)
    setSelectedTaskLink('')
  }

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.toString())
  }

  const cancelTaskSelection = () => {
    setValue('')
  }
  const fetchMoreChats = useCallback(() => {
    if (chatsFetching || !chats.length || !hasMoreChats) return
    const lastChatItem = chats?.[chats.length - 1]
    const { updated_at_ts } = lastChatItem
    const handleGetPostsParams = {
      type: keys.POST_TYPE.GROUP_POST,
      ts: updated_at_ts?.toString(),
      isRefresh: false,
    }

    try {
      dispatch(handleGetPosts(handleGetPostsParams))
    } catch (err) {
      console.log(err)
    }
  }, [chatsFetching, chats, hasMoreChats, dispatch])

  return (
    <BaseModal
      isShowAnimation={true}
      isOpen={isOpen}
      onClose={closeModal}
      hideCloseButton={true}
      overflowY="clip"
      headerContent={
        <>
          <Flex alignItems="center" justifyContent="space-between" dir={isRtl ? 'rtl' : 'ltr'}>
            <Box onClick={closeModal} mx="4" position="absolute">
              <IconButton
                aria-label="close groups"
                icon={BsArrowLeftShort}
                borderRadius="50%"
                style={{ color: colors.greyMain }}
                fontSize="32px"
                transform={`rotate(${+isRtl * 180}deg)`}
              />
            </Box>

            <Text m="0" textAlign="center" fontWeight="bold" flexGrow={1}>
              <LocaleText text="link_chat" />
            </Text>
          </Flex>
          <Divider pt="5px" />
        </>
      }
      bodyContent={
        <Box>
          <RadioGroup value={value} px="25px" mt="10px">
            {chats &&
              chats.map((chat, index: number) => (
                <Fragment key={index}>
                  <CustomRadio
                    onChange={handleSelect}
                    isChecked={value === chat.post_id.toString()}
                    w="100%"
                    justifyContent="space-between"
                    flexDir="row-reverse"
                    value={chat.post_id.toString()}
                    size="lg"
                    color={theme?.elementsColor}
                    key={index}
                  >
                    <Flex flexDir="row" alignItems="center" color={colors.blackLight}>
                      <SmartAvatar
                        image={chat?.images?.[0]}
                        size="40px"
                        backgroundColor={colors.greyLight4}
                        iconColor={colors.tealLight}
                      />
                      <Text mx="10px">{chat.title}</Text>
                    </Flex>
                  </CustomRadio>
                  <Divider pt="5px" />
                </Fragment>
              ))}
            {chatsFetching && <Loader />}
            {hasMoreChats && !chatsFetching && (
              <Button
                variant="unstyled"
                color={colors.blueDark}
                fontSize="17px"
                onClick={fetchMoreChats}
                _focus={{ boxShadow: 'none' }}
              >
                <LocaleText text="see_more_sidebar" />
              </Button>
            )}
            <Divider pt="5px" />
          </RadioGroup>
        </Box>
      }
      footerContent={
        <>
          <Flex justifyContent="space-between" background="white" px="6" py={5} w="full">
            <Button
              borderRadius="20px"
              onClick={handleSubmit}
              color="white"
              textTransform="uppercase"
              background={theme?.elementsColor}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="post_audience_done" />
            </Button>
            <Button
              variant="outline"
              borderRadius="20px"
              onClick={cancelTaskSelection}
              color={colors.tomato}
              textTransform="uppercase"
              background={colors.tealLight}
              _hover={{ backgroundColor: 'none' }}
            >
              <LocaleText text="cancel" />
            </Button>
          </Flex>
        </>
      }
    />
  )
}
