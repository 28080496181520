import { Flex } from '@chakra-ui/core'
import { RootState, Subtask } from 'constants/interfaces'
import React from 'react'
import { useSelector } from 'react-redux'
import { Votes } from './Votes'

interface Props {
  pollSubtask: Subtask
  contextId: string
  isSpecificResult?: boolean
}
export const Poll = ({ pollSubtask, contextId, isSpecificResult = false }: Props) => {
  const { options, is_multi_choice, tid, st_id } = pollSubtask
  const pollResults = useSelector((state: RootState) => state.tasks_v2.allResults?.[tid] ?? [])
  if (!options) return null
  return (
    <Flex flexDir="column" background="white">
      <Votes
        options={options}
        isMultiple={is_multi_choice ?? false}
        pollResults={pollResults}
        st_id={st_id}
        tid={tid}
        contextId={contextId}
        isSpecificResult={isSpecificResult}
      />
    </Flex>
  )
}
