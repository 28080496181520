import React, { useEffect, useRef } from 'react'
import Select, { createFilter } from 'react-select'
import { Config } from 'react-select/src/filters'
import { IOption } from 'constants/interfaces'
import { SelectComponents } from 'react-select/src/components'
import StateManager from 'react-select'

interface IProps {
  filterConfig?: Config | null
  options: any
  value?: IOption
  onChange: (value: any) => void
  components?: Partial<SelectComponents<IOption, false>>
}

const customStyles = {
  menu: (provided: React.CSSProperties) => ({
    ...provided,
    minWidth: '300px',
    background: '#fff',
  }),
  control: (provided: React.CSSProperties) => ({
    ...provided,
    width: '200px',
  }),
  menuPortal: (base: React.CSSProperties) => ({ ...base, zIndex: 9999 }),
}

export const BaseSelect = ({ filterConfig = null, options, value, onChange, components }: IProps) => {
  const ref = useRef<StateManager<IOption, false>>(null)
  useEffect(() => {
    document.addEventListener('click', (event) => {
      if (
        // @ts-ignore
        !ref.current?.select?.menuListRef?.contains(event.target) &&
        // @ts-ignore
        !ref?.current?.select?.controlRef?.contains(event.target)
      ) {
        ref?.current?.onMenuClose()
      }
    })
  }, [])

  return (
    <Select
      ref={ref}
      styles={customStyles}
      options={options}
      components={components}
      onChange={onChange}
      value={value}
      filterOption={createFilter(filterConfig)}
      menuPosition="fixed"
      menuPlacement="bottom"
      menuPortalTarget={document.body}
    />
  )
}
