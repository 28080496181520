import { useToast } from '@chakra-ui/core'

export const useValidateData = () => {
  const toast = useToast()
  const validateEndDate = (
    date: Date | null,
    startDate: Date,
    messageText: string,
    handler: (validDate: Date | null) => void
  ): boolean => {
    if (date !== null && new Date(startDate).getTime() <= new Date(date).getTime()) {
      handler(date)
      return true
    } else {
      toast({
        variant: 'solid',
        title: messageText,
        isClosable: true,
        status: 'warning',
        duration: null,
      })
      return false
    }
  }

  const validateStartDate = (
    date: Date | null,
    endDate: Date,
    messageText: string,
    handler: (validDate: Date | null) => void
  ): boolean => {
    if ((date !== null && new Date(endDate).getTime() >= new Date(date).getTime()) || !endDate) {
      handler(date)
      return true
    } else {
      toast({
        variant: 'solid',
        title: messageText,
        isClosable: true,
        status: 'warning',
        duration: null,
      })
      return false
    }
  }

  const validateDueDate = (
    date: Date | null,
    startDate: Date,
    endDate: Date,
    messageText: string,
    handler?: (validDate: Date | null) => void
  ) => {
    if (!date) return true
    if (
      new Date(date).getTime() <= new Date(endDate).getTime() &&
      new Date(date).getTime() >= new Date(startDate).getTime()
    ) {
      handler && handler(date)
      return true
    } else {
      toast({
        variant: 'solid',
        title: messageText,
        isClosable: true,
        status: 'warning',
        duration: null,
      })
      return false
    }
  }
  return {
    validateEndDate,
    validateStartDate,
    validateDueDate,
  }
}
