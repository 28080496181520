import {STORE_RANKING_FILTER, TASK_SORTING} from './enums'
import { IMenuItem } from './interfaces'
import keys from './keys'

export const defaultTaskStatuses = {
  [keys.TASK_STATUSES.TODO.value]: 0,
  [keys.TASK_STATUSES.INPROGRESS.value]: 0,
  [keys.TASK_STATUSES.DONE.value]: 0,
}

export const TASK_SORTING_VARIANTS: IMenuItem[] = [
  { id: 0, literal: 'task_start_date', value: TASK_SORTING.STARTING_DATE.toString() },
]
// eslint-disable-next-line
export const regExLinkRecognizer =  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

// count of tasks which will be rendered on notification page
export const TASKS_NOTIFICATION_COUNT = 7
export const OTHER_TASK_VIEW_ID = 'other'

export const OTHER_TASK_VIEW = {
  task_view_id: 'other',
  task_view_name: 'task_type_others',
  icon: 'https://sales-media.apps-checkout.com/default/icons/others.svg',
  order: 1000,
  color: '#ccc',
}

export const defaultStoreFilter = {
  id: STORE_RANKING_FILTER.ALL_CHAIN,
  name: 'ranking_filter_all_chain',
  value: STORE_RANKING_FILTER.ALL_CHAIN,
}

export const contactUsOnWhatsApp = 'https://api.whatsapp.com/send/?phone='

export const dateOptionsDefault = [
  { id: '0', name: 'search_feed_date_filter_today', isSelected: false },
  { id: '1', name: 'search_feed_date_filter_yesterday', isSelected: false },
  { id: '2', name: 'search_feed_date_filter_seven_days', isSelected: false },
  { id: '3', name: 'search_feed_date_filter_thirty_days', isSelected: false },
  { id: '4', name: 'search_feed_date_filter_range', isSelected: false },
]

export const mediaOptionsDefault = [
  {
    id: '1',
    name: 'search_feed_filter_with_audio',
    isSelected: false,
  },
]
