import React from 'react'
import { Flex, Tag, Text } from '@chakra-ui/core'
import colors from 'constants/colors'
import { useSelector } from 'react-redux'
import { isRtlSelector } from 'redux/selectors/general'
import { ArrowRankingDown, ArrowRankingUp } from '../../helperStyles'
import LocaleText from 'components/LocaleText'
import strings from 'constants/strings'

interface IProps {
  diff: number | string
}

export const RankingDiff = ({ diff }: IProps) => {
  const isRtl = useSelector(isRtlSelector)
  return (
    <>
      {typeof diff === 'string' && diff === strings.RANK_DIFF_STRING ? (
        <Tag
          background={colors.blueMedium2}
          borderRadius="20px"
          isTruncated
          fontSize="15px"
          color={colors.tealLight}
          justifyContent="center"
          minHeight="17px"
          width="52px"
          px="0"
        >
          <LocaleText text="ranking_new_store_tag" />
        </Tag>
      ) : (
        <>
          {typeof diff === 'number' && diff > 0 && (
            <Flex alignItems="center" justifyContent="flex-start" width="max-content" flexDirection={'row'}>
              <ArrowRankingUp />
              <Text mx="5px" fontSize="14px" color={colors.greenDark}>
                {Math.abs(diff)}
              </Text>
            </Flex>
          )}
          {typeof diff === 'number' && diff < 0 && (
            <Flex alignItems="center" justifyContent="flex-start" width="max-content" flexDirection={'row'}>
              <ArrowRankingDown />
              <Text mx="5px" fontSize="14px" color={colors.red}>
                {Math.abs(diff)}
              </Text>
            </Flex>
          )}
          {diff === 0 && (
            <Text fontSize="14px" mr={isRtl ? '0' : '5px'} ml={isRtl ? '5px' : '0'} color={colors.yellow}>
              {diff}
            </Text>
          )}
        </>
      )}
    </>
  )
}
