import { useToast } from '@chakra-ui/core'
import { httpAuth } from 'config/apiClient'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getTaskBodyForCreatingTask,
  getTaskBodyForEditingTask,
  resetCreateTaskState,
  setIsPublishLoading,
} from 'redux/actions/createTask'
import { RootState } from 'constants/interfaces'
import endpoints from 'constants/endpoints'
import { ToastStatus } from 'config/types'
import useLocaleText from 'components/useLocaleText'

export const useTask = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const dispatch = useDispatch()

  const createTask = useSelector((state: RootState) => state.createTask)
  const {
    activeGroupID,
    config: { rid },
  } = useSelector((state: RootState) => state.config)

  const successTaskCreation = useLocaleText('create_task_st_success_created_message')
  const failedTaskCreation = useLocaleText('create_task_st_failed_created_message')

  const showToastMessage = (message: string, status: ToastStatus = 'success') => {
    toast({
      title: message,
      status: status,
      duration: 5000,
      isClosable: true,
    })
  }

  const createTaskHandler = async () => {
    dispatch(setIsPublishLoading(true))
    try {
      const newTaskBody = createTask.isNewTask
        ? getTaskBodyForCreatingTask(createTask, rid)
        : getTaskBodyForEditingTask(createTask, rid, [activeGroupID!])

      const { data } = await httpAuth.put(endpoints.createTask, newTaskBody)

      showToastMessage(successTaskCreation)
      navigate('/', { state: { isCreated: true } })
      dispatch(resetCreateTaskState())

      return data
    } catch (error) {
      console.log(error)
      showToastMessage(failedTaskCreation, 'error')
    } finally {
      dispatch(setIsPublishLoading(false))
    }
  }

  return { createTaskHandler }
}
