import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Button, Text, Flex, Box, Icon } from '@chakra-ui/core'
import useLocaleText from 'components/useLocaleText'
import keys from 'constants/keys'
import dayjs from 'dayjs'
import { CustomCheckBox } from 'components/CommonComponents/GroupsTree/style'
import { RecurringOptions } from './RecurringOptions'
import { REMOVAL_OFFSET } from './utils'

interface IProps {
  setIsRecurring: (value: boolean) => void
  setRecurrenceInterval: (value: number | null) => void
  setRecurrenceFrequency: (value: number | null) => void
  recurrenceInterval: number | null
  recurrenceFrequency: number | null
  setFromTs: (fromTs: string) => void
  setDeadline: (toTs: string) => void
  isDisabled?: boolean
}

export const DateSelection = memo(
  ({
    setFromTs,
    setDeadline,
    isDisabled = false,
    setIsRecurring,
    setRecurrenceInterval,
    setRecurrenceFrequency,
    recurrenceInterval,
    recurrenceFrequency,
  }: IProps) => {
    const [startDate, setStartDate] = useState(() => {
      const date = new Date()
      date.setSeconds(0, 0)
      return date
    })

    const [endDate, setEndDate] = useState<Date | null>(() => {
      const date = new Date()
      date.setSeconds(0, 0)
      date.setHours(date.getHours() + REMOVAL_OFFSET)
      return date
    })

    const [isRecurringEnable, setIsRecurringEnable] = useState(false)
    const datePickerRef = useRef<HTMLDivElement | null>(null)

    const [isStartDatePickerOpen, setStartDatePickerOpen] = useState(false)
    const [isEndDatePickerOpen, setEndDatePickerOpen] = useState(false)
    const selectDatesLiteral = useLocaleText('select_dates')
    const t_start_date = useLocaleText('t_start_date')
    const t_end_date = useLocaleText('t_end_date')
    const t_recurring = useLocaleText('t_recurring')

    const handleOpenStartDatePicker = () => {
      setStartDatePickerOpen(!isStartDatePickerOpen)
      setEndDatePickerOpen(false)
    }

    const handleOpenEndDatePicker = () => {
      setEndDatePickerOpen(!isEndDatePickerOpen)
      setStartDatePickerOpen(false)
    }

    const handleStartDateChange = (date: Date) => {
      setStartDate(date)
    }

    const handleEndDateChange = (date: Date) => {
      setEndDate(date)
    }

    const onClickStartOutside = useCallback(() => {
      const startDateString = dayjs(startDate).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP)
      setFromTs(startDateString)
      setStartDatePickerOpen(false)
    }, [startDate, setFromTs])

    const onClickEndOutside = useCallback(() => {
      const endDateString = endDate ? dayjs(endDate).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP) : ''
      setDeadline(endDateString)
      setEndDatePickerOpen(false)
    }, [endDate, setDeadline])

    const handleCheckboxChange = () => {
      setIsRecurringEnable(!isRecurringEnable)
      setIsRecurring(!isRecurringEnable)
    }

    const getEndDatePickerMinTime = () => {
      if (startDate && endDate && dayjs(startDate).isSame(dayjs(endDate), 'day')) {
        return startDate
      }
      return dayjs().startOf('day').toDate()
    }

    useEffect(() => {
      setFromTs(dayjs(startDate).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP))
      setDeadline(dayjs(endDate).format(keys.DEFAULT_STRING_FORMAT_TIMESTAMP))
    }, [startDate, endDate, setFromTs, setDeadline])

    return (
      <Flex flexDir="row" width="100%" alignItems="center" justifyContent="space-between" mb="20px">
        <Flex flexDir="column" alignItems="flex-start" style={{ rowGap: '5px' }}>
          <Text fontSize="14px" fontWeight="600" px="10px">
            {t_start_date}
          </Text>
          <Button
            variant="outline"
            onClick={handleOpenStartDatePicker}
            display="flex"
            alignItems="center"
            borderRadius="10px"
            marginBottom="0px !important"
            fontSize="14px"
            px="9px"
            h="30px"
            style={{ columnGap: '10px' }}
            pos={'relative'}
            isDisabled={isDisabled}
          >
            <Text>{startDate ? dayjs(startDate).format('DD/MM/YYYY HH:mm') : selectDatesLiteral}</Text>
            <Icon name="calendar" />
          </Button>
          {isStartDatePickerOpen && (
            <Box pos="absolute" zIndex={99999} top="245px" ref={datePickerRef}>
              <DatePicker
                dateFormat={'d/MM/yy HH:mm'}
                className="datePicker"
                popperPlacement="top"
                showTimeInput
                timeFormat="HH:mm"
                showMonthDropdown
                showYearDropdown
                selected={startDate}
                onClickOutside={onClickStartOutside}
                onChange={handleStartDateChange}
                minDate={new Date()}
                inline
              />
            </Box>
          )}
        </Flex>
        <Flex flexDir="column" alignItems="flex-start" style={{ rowGap: '5px' }}>
          <Text fontSize="14px" fontWeight="600" px="10px">
            {t_end_date}
          </Text>
          <Button
            variant="outline"
            onClick={handleOpenEndDatePicker}
            display="flex"
            alignItems="center"
            borderRadius="10px"
            fontSize="14px"
            px="9px"
            h="30px"
            style={{ columnGap: '10px' }}
            pos={'relative'}
            isDisabled={isDisabled}
          >
            <Text>{endDate ? dayjs(endDate).format('DD/MM/YYYY HH:mm') : selectDatesLiteral}</Text>
            <Icon name="calendar" />
          </Button>
          {isEndDatePickerOpen && (
            <Box pos="absolute" zIndex={99999} top="345px" ref={datePickerRef}>
              <DatePicker
                dateFormat={'d/MM/yy HH:mm'}
                className="datePicker"
                popperPlacement="top"
                timeFormat="HH:mm"
                showMonthDropdown
                showYearDropdown
                showTimeInput
                selected={endDate}
                onClickOutside={onClickEndOutside}
                onChange={handleEndDateChange}
                minDate={startDate}
                minTime={getEndDatePickerMinTime()}
                inline
              />
            </Box>
          )}
        </Flex>
        <Flex alignItems="center" style={{ columnGap: '5px' }} mt="20px">
          <CustomCheckBox isChecked={isRecurringEnable} onChange={handleCheckboxChange} />
          <Text>{t_recurring}</Text>
        </Flex>
        <RecurringOptions
          isDisabled={!isRecurringEnable}
          setRecurrenceInterval={setRecurrenceInterval}
          setRecurrenceFrequency={setRecurrenceFrequency}
          recurrenceInterval={recurrenceInterval}
          recurrenceFrequency={recurrenceFrequency}
        />
      </Flex>
    )
  }
)
