import React, { forwardRef } from 'react'
import { Button } from '@chakra-ui/core'
import colors from 'constants/colors'

import { ReactComponent as IconSend } from 'assets/icon-send.svg'

interface IProps {
  isLoading?: boolean
  onClick?: VoidFunction
  tabIndex?: number
}

// eslint-disable-next-line react/prop-types
export const PublishButton = forwardRef<HTMLButtonElement, IProps>(({ isLoading, onClick, tabIndex }, ref) => {
  return (
    <Button
      ref={ref}
      borderRadius="full"
      bg={isLoading ? 'transparent' : colors.greenLight2}
      minWidth="auto"
      width="44px"
      height="44px"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      p="0"
      background={colors.greenDark}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <IconSend />
    </Button>
  )
})
