export enum DEVICE_TYPES_ENUM {
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  TABLET = 'tablet',
}
export enum REPORT_COMPONENT_TYPES {
  SALES = 0,
  TRAFFIC = 1,
  AVERAGE_CART = 2,
  COMPETITION = 3,
  HOURS = 4,
  CUSTOMER_CLUB = 5,
  HORIZONTAL_CHARTS = 6,
  HORIZONTAL_CHARTS_MEN = 'report_general_best_sellers_man',
  HORIZONTAL_CHARTS_WOMEN = 'report_general_best_sellers_women',
  HORIZONTAL_CHARTS_GENERAL = 'report_general_best_sellers',
  STORES_PERFORMANCE = 7,
  RETAIL_CONFIG = 8,
  WORKFORCE_PRODUCTIVITY = 13,
  AUTONOMOUS_POS = 15,
  SALES_GOAL = 18,
  SALES_SMALL_CARD = 19,
  NEW_SALES = 20,
  DAILY_TARGET = 21,
  SALES_VS_TARGET_NEW = 22,
  MOUNTHLY_TARGET = 23,
  HEADER_TARGET_GROUP = 30,
}

export enum REPORT_DATA_RANGE {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
}

export enum REPORT_DATA_TYPE {
  LOOKUP = 'lookup',
  CONST = 'const',
  BY_ROW = 'byRow',
}

export enum REPORT_ACTION_TYPES {
  DELETE = 'delete',
  ADD = 'add',
  SET = 'set',
}

export enum TASK_OVERVIEW_FILTER_TYPES {
  ASSIGNED_BY = 'assignBy',
  GROUPS = 'groups',
  LEVEL = 'level',
}

export enum TASK_DASHBOARD_LEVELS {
  DEPARTMENT = 0,
  STORE = 1,
  AREA = 2,
  RETAIL = 3,
}

export enum IMAGE_SIZES {
  FULL_SCREEN_IMAGE = 0,
}

export enum TASK_SORTING {
  STARTING_DATE = 0,
  CREATED_BY = 1,
}

export enum STORE_RANKING_SORTING {
  HIGH_RANKING = 1,
  LOW_RANKING = 2,
  HIGH_DIFF = 3,
  LOW_DIFF = 4,
}

export enum STORE_RANKING_FILTER {
  ALL_CHAIN = 'allChain',
}

export enum INTEGRATION_TYPE {
  EZ_TIME = 'ez_time',
}
