import { Avatar, Flex } from '@chakra-ui/core'
import React, { memo } from 'react'
import { RootState } from 'constants/interfaces'
import { useSelector } from 'react-redux'
import StoreeDoll from 'assets/doll_icon.png'
import { MessageText } from './MessageText'
import { IMessage } from './types'

export const MessageItem = memo(({ text, isUserMessage }: IMessage) => {
  const { retailUsersObject } = useSelector((state: RootState) => state.config.config)
  const { uid } = useSelector((state: RootState) => state.auth)
  return (
    <Flex justifyContent={isUserMessage ? 'flex-end' : 'flex-start'} mx="10px" mb="10px">
      <Flex maxW={'70%'} flexDir={isUserMessage ? 'row-reverse' : 'row'} style={{ columnGap: '15px' }}>
        <Avatar
          w="4rem"
          h="3.4rem"
          background="transparent"
          src={isUserMessage ? retailUsersObject?.[uid!]?.profile_img_url : StoreeDoll}
        />
        <MessageText text={text} isUserMessage={isUserMessage} />
      </Flex>
    </Flex>
  )
})
