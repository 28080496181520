import { Properties } from 'redux/reducers/reports'
import ExcelJS from 'exceljs'
import { saveAs } from 'file-saver'
import { IDataFilterValue, ITag } from 'constants/interfaces'

interface CellData {
  value: string
  isTag: boolean
  groupId: string
  subRetailId: null
}

type ExtractValueResult = string | number | null | string[] | number[] | boolean

export const extractValueBySearchType = (data: any, rawReport: any) => {
  if (data === null || data === undefined) return null
  if (typeof data === 'string') return data
  if (typeof data === 'number') return data
  if (typeof data === 'boolean') return data

  try {
    switch (data.type) {
      case 'lookup':
        return rawReport[data.queryName] ? rawReport[data.queryName][data.index[0]][data.index[1]] : null
      case 'byRow':
        return rawReport[data.queryName]
          ? rawReport[data.queryName].slice(data.rowStartingIndex).map((row: any) => row[data.attributeColumnIndex])
          : null
      default:
        return null
    }
  } catch (error) {
    console.log({ extractValueBySearchType: error, data })
    return null
  }
}
export const extractValueByIndexType = <T extends ExtractValueResult>(data: any, rawReport: any): T => {
  if (data === undefined) {
    return null as T
  }
  if (data === null) return null as T
  if (typeof data === 'string') return data as T
  if (typeof data === 'number') return data as T

  try {
    switch (data.type) {
      case 'lookup':
        return rawReport[data.index[0]][data.index[1]] ?? (null as T)
      case 'byRow':
        return rawReport[data.attributeColumnIndex] ?? (null as T)
      default:
        return null as T
    }
  } catch (error) {
    console.log({ extractValueBySearchType: error, data })
    return null as T
  }
}

export const isStringOrNull = (value: unknown, variableName: string): value is string | null => {
  const isValid = typeof value === 'string' || value === null
  if (!isValid) {
    console.warn(`${variableName} string or null was expected but received :`, value)
  }
  return isValid
}

export const isNumberOrNull = (value: unknown, variableName: string): value is number | null => {
  const isValid = typeof value === 'number' || value === null
  if (!isValid) {
    console.log(`${variableName} --- number or null was expected but received :`, value)
  }
  return isValid
}

export const isNumber = (value: unknown, variableName: string): value is number => {
  const isValid = typeof value === 'number'
  if (!isValid) {
    console.log(`${variableName} --- number was expected but received :`, value)
  }
  return isValid
}

export const isString = (value: unknown, variableName: string): value is string => {
  const isValid = typeof value === 'string'
  if (!isValid) {
    console.log(`${variableName} --- string was expected but received :`, value)
  }
  return isValid
}

export const getReportDataLength = (isRepeatable?: boolean, dataSource?: string | null, reportData?: any) => {
  return isRepeatable && dataSource && reportData[dataSource] ? reportData[dataSource].length : 1
}

export const getTranslateX = (value: number, isRtl: boolean) => {
  if (value > 5) {
    return isRtl ? 'translateX(100%)' : 'translateX(-100%)'
  } else if (value < 5) {
    return isRtl ? 'translateX(20%)' : 'translateX(-20%)'
  }
}

export const countDigits = (number: number): number => {
  const numberStr = number.toString().replace('.', '').replace('-', '')
  return numberStr.length
}

export const getLineBarWidth = (value: number) => `${Math.min(value, 100)}%`

export const getReportValues = ({
  properties,
  reportData,
  index,
  isRepeatable,
  reportIndex,
}: {
  properties: Properties
  reportData: any
  index: number
  isRepeatable: boolean
  reportIndex: number
}): Partial<Record<keyof Properties, any>> => {
  const currentIndex = isRepeatable ? reportIndex : index
  const extractedValues = Object.entries(properties).reduce((acc, [key, propertyValue]) => {
    if (propertyValue !== undefined) {
      const value = extractValueBySearchType(propertyValue, reportData)

      if (Array.isArray(value)) {
        acc[key] = value[currentIndex] ?? null
      } else {
        acc[key] = value
      }
    }

    return acc
  }, {} as Partial<Record<keyof Properties, any>>)

  return extractedValues
}

export const normalizeNewlines = (input: string): string => {
  const regex = /,\n+/g
  return input.replace(regex, ',\n\n')
}

export const exportToExcel = async (
  data: CellData[][],
  headers: { text_val: string | null }[],
  locale: string,
  translations: object,
  fileName: string | null
) => {
  const filteredHeaders = headers.filter((header) => header.text_val !== null)

  const validHeaderIndices = filteredHeaders.map((header) => headers.indexOf(header))

  const filteredData = data.map((row) => row.filter((_, index) => validHeaderIndices.includes(index)))

  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('My Worksheet')

  const headerRow = worksheet.addRow(
    filteredHeaders.map((header) => translations[locale][header.text_val] || header.text_val)
  )
  headerRow.eachCell((cell) => {
    cell.font = { bold: true, size: 14 }
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '90949C' },
    }
    cell.alignment = { horizontal: 'center' }
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }
  })

  filteredData.forEach((row, rowIndex) => {
    const newRow = worksheet.addRow(row.map((cell) => cell.value))

    const firstCell = newRow.getCell(1)
    firstCell.font = { bold: true }
    firstCell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '90949C' },
    }
    firstCell.alignment = { horizontal: 'left' }
    firstCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    }
  })

  worksheet.columns.forEach((column) => {
    let maxLength = 0
    column.eachCell?.({ includeEmpty: true }, (cell) => {
      let textLength = cell.value?.toString().length || 0
      textLength += 2
      maxLength = Math.max(maxLength, textLength)
    })
    column.width = maxLength
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveAs(blob, `${fileName ?? 'export'}.xlsx`)
}

export const exportToExcelWithTabs = async (
  finalResults: any[],
  headers: { text_val: string | null }[][],
  locale: string,
  translations: object,
  tabsNames: string[],
  fileName: string
) => {
  const workbook = new ExcelJS.Workbook()

  finalResults.forEach((result, index) => {
    const tabName = translations[locale][tabsNames[index]] || tabsNames[index]
    const worksheet = workbook.addWorksheet(`${tabName}`)
    const sheetHeaders = headers[index] || []

    const filteredHeaders = sheetHeaders.filter((header) => header.text_val !== null)

    const validHeaderIndices = filteredHeaders.map((header) => sheetHeaders.indexOf(header))

    const filteredData = result.map((row: any) => row.filter((_: any, idx: number) => validHeaderIndices.includes(idx)))

    const headerRow = worksheet.addRow(
      filteredHeaders.map((header) => translations[locale][header.text_val] || header.text_val)
    )

    headerRow.eachCell((cell) => {
      cell.font = { bold: true, size: 14 }
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '90949C' },
      }
      cell.alignment = { horizontal: 'center' }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    })

    filteredData.forEach((row: any) => {
      const newRow = worksheet.addRow(row.map((cell: any) => cell.value))

      const firstCell = newRow.getCell(1)
      firstCell.font = { bold: true }
      firstCell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '90949C' },
      }
      firstCell.alignment = { horizontal: 'left' }
      firstCell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
    })

    worksheet.columns.forEach((column) => {
      let maxLength = 0
      column.eachCell?.({ includeEmpty: true }, (cell) => {
        let textLength = cell.value?.toString().length || 0
        textLength += 2
        maxLength = Math.max(maxLength, textLength)
      })
      column.width = maxLength
    })
  })

  const buffer = await workbook.xlsx.writeBuffer()
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
  saveAs(blob, `${fileName}.xlsx`)
}
interface IReportKeyParams {
  targetGroupId?: string
  groupId?: string | null
  subRetailId?: string | null
  rangeType?: string | number
  componentId?: string
  subComponentId?: string
  componentFilter?: string
  tag?: ITag | null
  selectedUsersIds?: string[]
  deepDiveSelectedUsersIds?: string[]
  deepDiveSelectedTag?: ITag | null
  targetDataFilter?: IDataFilterValue | null
  selectedDeepDiveDataFilter?: IDataFilterValue | null
}

export const generateReportKey = ({
  targetGroupId,
  groupId,
  subRetailId,
  rangeType,
  componentId,
  subComponentId,
  componentFilter,
  tag,
  selectedUsersIds,
  deepDiveSelectedUsersIds,
  deepDiveSelectedTag,
  targetDataFilter,
  selectedDeepDiveDataFilter,
}: IReportKeyParams) => {
  const usersIds =
    selectedUsersIds && selectedUsersIds.length
      ? selectedUsersIds.join(',')
      : deepDiveSelectedUsersIds && deepDiveSelectedUsersIds.length
      ? deepDiveSelectedUsersIds.join(',')
      : ''

  const tagSk = tag?.sk || deepDiveSelectedTag?.sk || ''

  const dataFilter = targetDataFilter?.value || selectedDeepDiveDataFilter?.value || ''

  return `${targetGroupId || groupId || ''}${subRetailId || ''}${rangeType || ''}${componentId || ''}${
    subComponentId || ''
  }${componentFilter || ''}${usersIds}${tagSk}${dataFilter}`
}
