import { Avatar, Box, Button, Flex, Heading, IconButton, Text, useDisclosure } from '@chakra-ui/core'
import BaseModal from 'components/CommonComponents/BaseModal'
import React, { memo, useMemo, useState } from 'react'
import { Title } from '../ui/Title'
import { SelectionPrompt } from '../ui/SelectionPrompt'
import { Poll } from '../PollSubTask'
import { PollVotersList } from '../PollSubTask/VotersList'
import { useDispatch, useSelector } from 'react-redux'
import { RootState, Subtask, Task } from 'constants/interfaces'
import useLocaleText from 'components/useLocaleText'
import { SubTaskV2 } from '../SubTasks'
import colors from 'constants/colors'
import { isRtlSelector } from 'redux/selectors/general'
import { ModalMediaSection } from '../SubTasksModal/MediaSection'
import keys from 'constants/keys'
import { AppDispatch } from 'config/redux'
import { getTaskResultSpecificContext, getTasksInsights, updateResults } from 'redux/actions/tasks_v2'
import { isRTL } from 'utils'
import { renderTextWithLineBreaks } from 'components/Post/PostTitle'
import { getFormattedLink, isExternalLink, isSubtaskAlreadyProcessed } from '../utils'

interface IProps {
  task: Task
  contextId: string
  isOpen: boolean
  onClose: () => void
  isSpecificResult?: boolean
}
export const PollSubTasksModals = memo(({ isOpen, onClose, task, contextId, isSpecificResult = false }: IProps) => {
  const { title, created_by, tid, hours_until_end, poll_ref } = task
  const dispatch: AppDispatch = useDispatch()
  const { isOpen: isViewVotesOpen, onToggle: onToggleViewVotes, onClose: onCloseViewVotes } = useDisclosure()
  const { isOpen: isSubTaskOpen, onOpen: onOpenSubTask, onClose: onCloseSubTask } = useDisclosure()
  const { isOpen: isOpenSpecificResult, onOpen: onOpenSpecificResult, onClose: onCloseSpecificResult } = useDisclosure()
  const t_view_votes = useLocaleText('t_view_votes')
  const t_hours = useLocaleText('t_hours')
  const t_delay = useLocaleText('t_delay')

  const isRtl = useSelector(isRtlSelector)
  const { isLoading, pollSubtasks, subtasks, userResults, tasks } = useSelector((state: RootState) => state.tasks_v2)
  const {
    config: { retailUsersObject, tags, first_name, last_name, default_group, groups, rid },
    activeGroupID,
  } = useSelector((state: RootState) => state.config)
  const { uid } = useSelector((state: RootState) => state.auth)
  const { theme } = useSelector((state: RootState) => state.general)

  const [selectedContextId, setSelectedContextId] = useState<string | null>(null)
  const [selectedSubtask, setSelectedSubtask] = useState<Subtask | null>(null)

  const subTaskResult = useMemo(() => {
    if (!selectedSubtask) {
      return
    }

    return userResults?.[tid]?.find(
      (subtask) => subtask.st_id === selectedSubtask.st_id && subtask.context_id === contextId
    )
  }, [contextId, selectedSubtask, tid, userResults])

  const isUserVoted = useMemo(() => {
    return userResults?.[tid]?.find(
      (subtask) =>
        subtask.sub_task_type === keys.SUBTASK_TYPES.POLL_SUBTASK &&
        subtask?.poll_selections &&
        subtask.poll_selections.length > 0 &&
        subtask.context_id === contextId
    )
  }, [contextId, tid, userResults])

  const isHtml = (text: string) => /<\/?[a-z][\s\S]*>/i.test(text)

  const onSubTaskClick = ({ st_id }: { st_id: string }) => {
    const subTask = subtasks?.[tid]?.find((subtask) => subtask.st_id === st_id)
    if (!subTask) {
      return
    }
    if (subTask.type === keys.SUBTASK_TYPES.LINK_SUBTASK) {
      const { external_link } = subTask
      const formattedLink = getFormattedLink({
        link: external_link!,
        tasks,
        tid,
        st_id,
        contextId,
        uid: uid!,
        userResults,
        tags,
        first_name,
        last_name,
        default_group,
        groups,
        activeGroupID: activeGroupID!,
        rid,
      })
      if (!isSubtaskAlreadyProcessed({ tid, st_id, contextId, userResults })) {
        dispatch(
          updateResults({
            tid,
            st_id,
            type: keys.SUBTASK_TYPES.LINK_SUBTASK,
            specificContextId: contextId,
            status: isExternalLink(external_link!)
              ? keys.TASK_STATUSES.INPROGRESS.value
              : keys.TASK_STATUSES.DONE.value,
          })
        )
      }

      window.open(formattedLink, '_blank')
      return
    }
    setSelectedSubtask(subTask)
    onOpenSubTask()
  }

  const handleGetSpecificTaskResult = async (contextId: string) => {
    if (!tid) return
    onOpenSpecificResult()
    setSelectedContextId(contextId)
    await dispatch(getTaskResultSpecificContext({ tid: tid, contextId: contextId }))
  }

  const onViewVotesClick = () => {
    onToggleViewVotes()
    const pollSubtaskId = pollSubtasks?.[tid]?.find((subtask) => subtask.st_id === poll_ref)?.st_id
    if (!pollSubtaskId) return
    dispatch(getTasksInsights({ tid, st_id: pollSubtaskId }))
  }

  return (
    <>
      {isOpen && pollSubtasks?.[tid]?.[0] && (
        <BaseModal
          isOpen={isOpen}
          onClose={onClose}
          hideCloseButton={true}
          footerJustifyment="center"
          display={isViewVotesOpen || isSubTaskOpen ? 'none' : 'block'}
          headerContent={
            <>
              {!isSpecificResult ? (
                <Button
                  w="max-content"
                  marginRight="71%"
                  textAlign="center"
                  color="white"
                  variant="outline"
                  bg={theme?.elementsColor}
                  h="35px"
                  isDisabled={isLoading}
                  borderColor={theme?.elementsColor}
                  borderRadius="10px"
                  _focus={{ boxShadow: 'none' }}
                  _hover={{ bg: theme?.elementsColor, color: 'white' }}
                  onClick={onViewVotesClick}
                >
                  {t_view_votes}
                </Button>
              ) : (
                <Box h="35px" />
              )}

              <Box pos="relative" px="15px" pt="50px">
                <Box pos="absolute" top="-130px" left="35%">
                  <Avatar src={retailUsersObject?.[created_by]?.profile_img_url} w="150px" h="150px" />
                  <Text pt="5px" fontSize="18px" color="grey">
                    {Math.abs(hours_until_end)} {t_hours} {hours_until_end < 0 ? t_delay : ''}
                  </Text>
                </Box>
                <Flex dir={isRtl ? 'rtl' : 'ltr'} flexDir="column" style={{ rowGap: '10px' }} mt="10px">
                  <Title
                    title={title}
                    fontSize="26px"
                    fontFamily="Arial"
                    textAlign={isRtl ? 'right' : 'left'}
                    fontWeight="400"
                  />
                  <SelectionPrompt isMuptiple={pollSubtasks?.[tid][0]?.is_multi_choice ?? false} />
                </Flex>

                <IconButton
                  top="-35px"
                  right="-80px"
                  onClick={onClose}
                  w="32px"
                  minW="unset"
                  h="32px"
                  pos="absolute"
                  borderRadius="full"
                  fontSize="22px"
                  background="white"
                  icon={!isSpecificResult ? 'small-close' : isRtl ? 'chevron-right' : 'chevron-left'}
                  _hover={{ bg: '#d8d8d8' }}
                  aria-label="back"
                />
              </Box>
            </>
          }
          borderRadius="40px"
          bodyContent={
            <Box py="15px" px="40px">
              <Poll pollSubtask={pollSubtasks?.[tid]?.[0]} contextId={contextId} isSpecificResult={isSpecificResult} />
              {isUserVoted && (
                <>
                  {task.desc && (
                    <Box
                      textAlign={isRTL(task.desc) ? 'right' : 'left'}
                      bg="white"
                      p="20px"
                      borderRadius="12px"
                      boxShadow=" 0 2px 14px 0 rgba(0, 0, 0, 0.05)"
                      maxWidth="40vw"
                      mt="25px"
                    >
                      {isHtml(task.desc) ? (
                        <>
                          {task.desc
                            .split('\n')
                            .filter(Boolean)
                            .map((paragraph: string, idx) => (
                              <Heading
                                as="p"
                                key={`${paragraph}-${idx}`}
                                fontSize="16px"
                                my="15px"
                                dangerouslySetInnerHTML={{ __html: paragraph }}
                              />
                            ))}
                        </>
                      ) : (
                        <>{renderTextWithLineBreaks({ text: task.desc, isShowPreview: true })}</>
                      )}
                    </Box>
                  )}
                  {subtasks?.[tid]?.length > 0 &&
                    subtasks?.[tid]?.map((subtask, index) => {
                      const subTaskResult = userResults?.[tid]?.find(
                        ({ st_id, context_id }) => st_id === subtask.st_id && context_id === contextId
                      )
                      return (
                        <SubTaskV2
                          key={subtask.st_id}
                          onSubTaskClick={onSubTaskClick}
                          subTaskResult={subTaskResult}
                          index={index}
                          {...subtask}
                        />
                      )
                    })}
                </>
              )}
            </Box>
          }
          footerContent={
            <Flex flexDir="column" width="100%" pb="15px" pt="5px" px="16px">
              <Button
                onClick={onClose}
                isDisabled={isLoading}
                borderRadius="10px"
                width="100%"
                bg="white"
                color={theme?.elementsColor}
                borderColor={theme?.elementsColor}
                border="1px solid"
                h="40px"
                _focus={{ boxShadow: 'none' }}
                _hover={{ bg: theme?.elementsColor, color: 'white' }}
              >
                Done
              </Button>
            </Flex>
          }
        />
      )}
      {isViewVotesOpen && pollSubtasks?.[tid]?.[0] && (
        <BaseModal
          isOpen={isViewVotesOpen}
          onClose={onCloseViewVotes}
          backGroundModal="none"
          backGroundModalContent={colors.greyModal}
          display={isOpenSpecificResult ? 'none' : 'block'}
          hideCloseButton={true}
          headerContent={
            <Box pos="relative" pt="15px">
              <Box p="10px" borderRadius="10px" bg="white">
                <Title
                  title={title}
                  fontSize="26px"
                  fontFamily="Arial"
                  fontWeight="400"
                  textAlign={isRtl ? 'right' : 'left'}
                  isTruncated
                />
              </Box>

              <IconButton
                top="0px"
                right="-80px"
                w="32px"
                minW="unset"
                h="32px"
                onClick={onCloseViewVotes}
                pos="absolute"
                borderRadius="full"
                fontSize="22px"
                background="white"
                icon={isRtl ? 'chevron-right' : 'chevron-left'}
                _hover={{ bg: '#d8d8d8' }}
                aria-label="back"
              />
            </Box>
          }
          bodyContent={
            <Box pt="20px" px="25px">
              <PollVotersList
                pollSubtask={pollSubtasks?.[tid]?.[0]}
                contextId={contextId}
                handleGetSpecificTaskResult={handleGetSpecificTaskResult}
              />
            </Box>
          }
          footerContent={<Flex flexDir="column" width="100%" pb="15px" px="16px" />}
        />
      )}
      {isSubTaskOpen && selectedSubtask && (
        <BaseModal
          isOpen={isSubTaskOpen}
          onClose={onCloseSubTask}
          backGroundModal="none"
          hideCloseButton={true}
          headerPaddingTop="0"
          headerContent={
            <Box pos="relative">
              <Text fontSize="18px" fontWeight="bold" p="10px">
                {selectedSubtask?.title}
              </Text>
              <IconButton
                top="10px"
                right="-80px"
                w="32px"
                minW="unset"
                h="32px"
                isDisabled={isLoading}
                onClick={onCloseSubTask}
                pos="absolute"
                borderRadius="full"
                fontSize="22px"
                background="white"
                icon={isRtl ? 'chevron-right' : 'chevron-left'}
                _hover={{ bg: '#d8d8d8' }}
                aria-label="back"
              />
            </Box>
          }
          bodyContent={
            <Flex w="100%" flexDir="column" minH="300px" justifyContent="center" pos="relative">
              <ModalMediaSection
                subTask={selectedSubtask}
                subTaskResult={subTaskResult}
                contextId={isSpecificResult ? contextId : null}
              />
            </Flex>
          }
          footerContent={
            <Flex flexDir="column" width="100%" pb="15px" pt="5px" px="16px">
              <Button
                onClick={onCloseSubTask}
                isDisabled={isLoading}
                borderRadius="10px"
                width="100%"
                bg="white"
                color={theme?.elementsColor}
                borderColor={theme?.elementsColor}
                border="1px solid"
                h="40px"
                _focus={{ boxShadow: 'none' }}
                _hover={{ bg: theme?.elementsColor, color: 'white' }}
              >
                Done
              </Button>
            </Flex>
          }
        />
      )}
      {isOpenSpecificResult && selectedContextId && (
        <PollSubTasksModals
          isOpen={isOpenSpecificResult}
          onClose={onCloseSpecificResult}
          task={task}
          contextId={selectedContextId}
          isSpecificResult={true}
        />
      )}
    </>
  )
})
