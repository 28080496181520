import { Box, Flex, Progress, Stack, Text } from '@chakra-ui/core'
import React from 'react'
import { Step } from '../UserForm'
import LocaleText from 'components/LocaleText'
import { useSelector } from 'react-redux'
import { RootState } from 'constants/interfaces'

interface IProps {
  step: number
  steps: Step[]
}
export const MultiStepProgressBar = ({ step, steps }: IProps) => {
  const admin_users_detail = 'admin_users_detail'
  const admin_set_permissions = 'admin_set_permissions'
  const theme = useSelector((state: RootState) => state.general.theme)
  return (
    <Box margin="inherit" position="relative" textAlign="center">
      <Progress
        value={(step / (steps.length - 1)) * 100}
        height="5px"
        mx="10%"
        marginBottom="70px"
        color={theme?.elementsColor}
        bg={theme?.backgroundMedium}
      />
      <Stack
        direction="row"
        spacing="0"
        justify="space-between"
        alignItems="center"
        position="absolute"
        top="-26px"
        left="0"
        right="0"
      >
        {steps.map((_, index) => (
          <Flex key={index} flexDir="column" alignItems="center">
            <Box
              border={`2px solid ${theme?.backgroundMedium}`}
              boxShadow={`0 0 0 2px ${theme?.backgroundMedium}`}
              bg={index === step || index === step - 1 || index === step - 2 ? theme?.elementsColor : 'white'}
              color={index === step || index === step - 1 || index === step - 2 ? 'white' : '#a2a2b6'}
              borderRadius="full"
              width="55px"
              height="55px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              fontWeight="bold"
            >
              {index + 1}
            </Box>
            <Text width="150px" color={step >= 0 ? theme?.elementsColor : 'gray.500'} fontWeight="bold">
              <LocaleText text={index === 0 ? admin_users_detail : admin_set_permissions} />
            </Text>
          </Flex>
        ))}
      </Stack>
    </Box>
  )
}
