import { SymbolType } from 'components/Reports/enums'
import { customToFixed } from 'utils'

export const getFormattedStringValueWithSymbol = (
  value: number | null,
  currencySymbol: string | null,
  symbolType: number | null,
  isNumRound: boolean,
  translations: object,
  locale: string
) => {
  if (value === null || value === undefined) return null

  const roundedValue = isNumRound ? customToFixed(value, 0) : customToFixed(value, 2)
  const formattedValue = Number(roundedValue).toLocaleString()
  const items_literal = translations[locale]['general_units']
  const hours_literal = translations[locale]['report_workforce_hour_symbol']

  switch (symbolType) {
    case SymbolType.percentage: {
      return `${formattedValue}%`
    }
    case SymbolType.currency: {
      const displaySymbol = currencySymbol || ''
      const value = locale === 'he' ? `${displaySymbol} ${formattedValue}` : `${formattedValue} ${displaySymbol} `
      return `\u200E${value}`
    }

    case SymbolType.currency_per_hour: {
      const displaySymbol = currencySymbol || ''

      return locale === 'he'
        ? `${formattedValue} ${hours_literal}/${displaySymbol}`
        : `${formattedValue} ${displaySymbol}/${hours_literal}`
    }
    case SymbolType.items: {
      return `${formattedValue} ${items_literal}`
    }
    default:
      return formattedValue
  }
}
