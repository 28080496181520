export const GPT_MODES = [
  {
    title: 'AI Chat',
    value: 1,
  },
  {
    title: 'Expert',
    value: 2,
  },
]
